import { yupObjectWithId } from 'utils/validation/yupObjectWithId';
import * as yup from 'yup';
import { WorkloadStepData } from '.';

export const validateWorkloadData = async (
  workloadData: WorkloadStepData,
): Promise<void> => {
  const schema = yup.object().shape({
    workloadType: yupObjectWithId.required('Campo obrigatório'),
    vacancyLocation: yupObjectWithId.nullable(true),
    workSchedule: yup.string().optional(),
  });

  await schema.validate(workloadData, { abortEarly: false });
};
