/* eslint-disable react-hooks/rules-of-hooks */
import timerIcon from 'assets/img/timer.svg';
import closeIcon from 'assets/img/close.svg';
import simpleArrowRight from 'assets/img/simpleArrowRight.svg';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { PrimaryButton, SimpleButton } from 'components/Buttons';
import { Modal } from 'components/Modal';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import {
  routeCandidateTests,
  routeTestResults,
} from 'routes/candidatesRoutes/candidatesRoutesAddresses';
import { routeDashboard } from 'routes/routesAddresses';
import { CandidateTest } from 'types/candidate';
import { translateCandidateTest } from 'utils/conversion/translateCandidateTest';
import { useWindowWidth } from 'hooks/windowWidth';
import { PunctuationObject, TESTS_PROPERTIES } from '../TestsProperties';
import {
  Answer,
  AnswersContainer,
  Container,
  Footer,
  Header,
  MobileExitButton,
  QuestionContainer,
  TestRemainingTime,
} from './styles';
import { TimeExpiredModal } from './TimeExpiredModal';
import { QuitTestModal } from './QuitTestModal';

interface LocationType {
  state: { testType: CandidateTest } | undefined;
}

export const PerformTestPage: React.FC = () => {
  const { state } = useLocation() as LocationType;
  if (!state) return <Redirect to={routeDashboard} />;

  const history = useHistory();
  const { isMobileScreen } = useWindowWidth();
  const {
    InitialScreen,
    generatePunctuationObject,
    updatePunctuationObject,
    generateTestResult,
    questions,
    timeInMinutes,
  } = TESTS_PROPERTIES[state.testType];

  const [remainingTimeInSeconds, setRemainingTimeInSeconds] = useState(
    timeInMinutes ? timeInMinutes * 60 : undefined,
  );
  const [timeExpiredModalIsVisible, setTimeExpiredModalIsVisible] =
    useState(false);
  const [quitTestModalIsVisible, setQuitTestModalIsVisible] = useState(false);

  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState<number | null>(
    null,
  );
  const [questionIndex, setQuestionIndex] = useState<number | null>(null);
  const [punctuationObject, setPunctuationObject] = useState<PunctuationObject>(
    generatePunctuationObject(),
  );

  const isLastQuestion = questionIndex === questions.length - 1;

  const shuffledQuestions = useMemo(() => {
    const shuffledArray = [...questions];
    const { length } = shuffledArray;

    shuffledArray.forEach((question, index) => {
      const randomIndex = Math.floor(Math.random() * length);
      const parsedRandomIndex =
        randomIndex === length ? randomIndex - 1 : randomIndex;

      shuffledArray[index] = shuffledArray[parsedRandomIndex];
      shuffledArray[parsedRandomIndex] = question;
    });

    return shuffledArray;
  }, [questions]);

  useEffect(() => {
    const preventDefaultEvent = (event: MouseEvent) => event.preventDefault();
    document.addEventListener('contextmenu', preventDefaultEvent);

    return () => {
      document.removeEventListener('contextmenu', preventDefaultEvent);
    };
  }, []);

  useEffect(() => {
    let timerInterval: NodeJS.Timeout;

    if (timeInMinutes !== undefined && (questionIndex ?? -1) >= 0) {
      timerInterval = setInterval(
        () =>
          setRemainingTimeInSeconds(previousTime => {
            if (previousTime === 0) {
              setTimeExpiredModalIsVisible(true);
              return previousTime;
            }

            return Number(previousTime) - 1;
          }),
        1000,
      );
    }

    return () => {
      clearInterval(timerInterval);
    };
  }, [questionIndex, remainingTimeInSeconds, timeInMinutes]);

  const handleRedirectToResultsPage = useCallback(() => {
    history.push(routeTestResults, {
      testType: state.testType,
      testResult: generateTestResult(punctuationObject),
    });
  }, [generateTestResult, history, punctuationObject, state.testType]);

  useEffect(() => {
    if (selectedAnswerIndex === -1) handleRedirectToResultsPage();
  }, [handleRedirectToResultsPage, selectedAnswerIndex]);

  const handleGoToNextQuestion = useCallback(() => {
    if (questionIndex === null || selectedAnswerIndex === null) return;

    setTimeout(() => window.scrollTo({ top: 0 }), 100);
    setPunctuationObject(previousObject => {
      const { correctAnswerIndex, answers } = shuffledQuestions[questionIndex];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const answerType = answers[selectedAnswerIndex].answerType as any;

      return updatePunctuationObject(
        previousObject,
        answerType,
        correctAnswerIndex === selectedAnswerIndex,
      );
    });

    if (!isLastQuestion) {
      setSelectedAnswerIndex(null);
      setQuestionIndex(previousIndex => Number(previousIndex) + 1);
    } else setSelectedAnswerIndex(-1);
  }, [
    isLastQuestion,
    questionIndex,
    selectedAnswerIndex,
    shuffledQuestions,
    updatePunctuationObject,
  ]);

  const convertSecondsToMinutesString = (seconds: number) => {
    const minutesValue = String(Math.floor(seconds / 60)).padStart(2, '0');
    const secondsValue = String(seconds % 60).padStart(2, '0');

    return `${minutesValue}:${secondsValue}`;
  };

  return (
    <Container>
      <Modal
        isVisible={quitTestModalIsVisible}
        style={
          isMobileScreen
            ? {
                paddingTop: '25vh',
              }
            : {}
        }
      >
        <QuitTestModal
          handleQuitTest={() => history.push(routeCandidateTests)}
          handleCloseModal={() => setQuitTestModalIsVisible(false)}
        />
      </Modal>

      <Modal
        isVisible={timeExpiredModalIsVisible}
        style={
          isMobileScreen
            ? {
                paddingTop: '25vh',
              }
            : {}
        }
      >
        <TimeExpiredModal redirectToResultsPage={handleRedirectToResultsPage} />
      </Modal>

      {questionIndex === null ? (
        <InitialScreen />
      ) : (
        <>
          <Header>
            <h2>Teste de {translateCandidateTest(state.testType)}</h2>

            {remainingTimeInSeconds !== undefined && (
              <div>
                <span>Tempo restante do teste:</span>
                <TestRemainingTime>
                  <img src={timerIcon} alt="Timer" />
                  <span>
                    {convertSecondsToMinutesString(remainingTimeInSeconds)}
                  </span>
                </TestRemainingTime>
              </div>
            )}
          </Header>

          <QuestionContainer>
            <span>Questão {questionIndex + 1}</span>
            <div className="question">
              {shuffledQuestions[questionIndex].question}
            </div>
          </QuestionContainer>

          <AnswersContainer>
            {shuffledQuestions[questionIndex].answers.map(({ text }, index) => (
              <Answer
                key={text}
                type="button"
                onClick={() =>
                  setSelectedAnswerIndex(previousIndex =>
                    previousIndex === index ? null : index,
                  )
                }
                isSelected={index === selectedAnswerIndex}
              >
                <div />
                <span>{text}</span>
              </Answer>
            ))}
          </AnswersContainer>
        </>
      )}

      <Footer>
        {isMobileScreen ? (
          <MobileExitButton
            type="button"
            onClick={() => setQuitTestModalIsVisible(true)}
          >
            {questionIndex === null ? (
              <span>Sair</span>
            ) : (
              <img src={closeIcon} alt="Sair" />
            )}
          </MobileExitButton>
        ) : (
          <SimpleButton
            type="button"
            onClick={() => setQuitTestModalIsVisible(true)}
          >
            Sair do teste
          </SimpleButton>
        )}

        {questionIndex === null ? (
          <PrimaryButton type="button" onClick={() => setQuestionIndex(0)}>
            Iniciar teste
          </PrimaryButton>
        ) : (
          <>
            <div className="test-step">
              {questionIndex + 1}
              <span> de </span>
              {shuffledQuestions.length}
            </div>

            <PrimaryButton
              type="button"
              disabled={selectedAnswerIndex === null}
              onClick={handleGoToNextQuestion}
              style={
                isMobileScreen
                  ? {
                      width: 44,
                      height: 44,
                      padding: 0,
                    }
                  : {}
              }
            >
              {isMobileScreen ? (
                <img src={simpleArrowRight} alt="Próximo" />
              ) : (
                <>{isLastQuestion ? 'Finalizar teste' : 'Próximo'}</>
              )}
            </PrimaryButton>
          </>
        )}
      </Footer>
    </Container>
  );
};
