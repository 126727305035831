import {
  BackgroundColor,
  HighEmphasis,
  MediumEmphasis,
  OutlineColor,
} from 'assets/colors/palette';
import {
  Bold32Styles,
  Medium16Styles,
  Regular14Styles,
} from 'assets/fonts/fontStyles';
import styled from 'styled-components';

export const ModalSide = styled.div`
  width: 320px;
  text-align: center;

  h3 {
    ${Bold32Styles}
    color: ${HighEmphasis};
    font-size: 24px;
    margin-bottom: 8px;
  }

  p {
    ${Regular14Styles}
    color: ${HighEmphasis};
    line-height: 24px;
    margin-bottom: 20px;
  }
`;

export const Container = styled.div`
  width: 400px;
  height: 492px;
  background-color: ${BackgroundColor};

  border-radius: 8px;
  position: relative;
  gap: 6%;

  &,
  ${ModalSide} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 1100px) {
    width: 90vw;
  }
`;

export const TopContainer = styled.div`
  width: 100%;
  height: 60px;

  border-bottom: 1px solid ${OutlineColor};
  padding: 6px 18px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  span,
  button {
    ${Medium16Styles}
    color: ${HighEmphasis};
  }

  button {
    display: flex;
    align-items: center;
    background-color: transparent;
    gap: 6px;

    img {
      width: 25px;
      height: 25px;
    }
  }
`;

export const CloseButton = styled.button`
  background-color: transparent;
`;

export const Divider = styled.div`
  ${Regular14Styles}
  color: ${MediumEmphasis};
  line-height: 24px;

  width: 320px;
  position: relative;
  text-align: center;

  &::after {
    content: '';

    height: 1px;
    position: absolute;

    top: 12px;
    left: 0;
    right: 0;
    margin-inline: auto;

    border-left: 144px solid ${OutlineColor};
    border-right: 144px solid ${OutlineColor};
  }
`;
