import openOptions from 'assets/img/openOptions.svg';
import {
  useVacanciesCustomSteps,
  VacancyStepWithColor,
} from 'hooks/vacanciesCustomSteps';
import { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { EntityWithNameDTO } from 'types/EntityWithNameDTO';
import {
  Step,
  DragButtonContainer,
  LeftSideContainer,
  StepName,
  Container,
  DeleteStepButton,
  StepOptionsContainer,
  OpenStepOptionsButton,
} from './styles';

interface DraggableStepProps {
  vacancyId: number;
  step: VacancyStepWithColor;
  index: number;
  showDeleteModalForCustomStep: (vacancyCustomStepId: number) => void;
  handleUpdateCandidateStep: (updatedStep: EntityWithNameDTO) => Promise<void>;
}

interface ItemType {
  id: number;
  index: number;
}

export const DraggableStep: React.FC<DraggableStepProps> = ({
  vacancyId,
  step: { id, name, color },
  index,
  showDeleteModalForCustomStep,
  handleUpdateCandidateStep,
}) => {
  const { changeVacancyCustomStepPosition } = useVacanciesCustomSteps();
  const stepRef = useRef<HTMLButtonElement>(null);
  const [isAbleToDrag, setIsAbleToDrag] = useState(false);

  const [isDeleteStepContainerVisible, setIsDeleteStepContainerVisible] =
    useState(false);

  const [{ isDragging }, dragRef] = useDrag(
    () => ({
      type: 'step',
      item: { id, index, isAbleToDrag },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
      canDrag: isAbleToDrag,
    }),
    [isAbleToDrag],
  );

  const [, dropRef] = useDrop({
    accept: 'step',
    hover(item: ItemType, monitor) {
      const draggedIndex = item.index;
      const targetIndex = index;

      const { current } = stepRef;
      if (draggedIndex === targetIndex || !current) return;

      const targetSize = current.getBoundingClientRect();
      const targetCenter = (targetSize.bottom - targetSize.top) / 2;

      const draggedOffSet = monitor.getClientOffset()!;
      const draggedTop = draggedOffSet.y - targetSize.top;

      if (draggedIndex < targetIndex && draggedTop < targetCenter) {
        return;
      }

      if (draggedIndex > targetIndex && draggedTop > targetCenter) {
        return;
      }

      changeVacancyCustomStepPosition(vacancyId, draggedIndex, targetIndex);
      // eslint-disable-next-line no-param-reassign
      item.index = targetIndex;
    },
  });
  dragRef(dropRef(stepRef));

  return (
    <Container stepIsDragging={isDragging}>
      <StepOptionsContainer
        isVisible={isDeleteStepContainerVisible}
        onMouseLeave={() => setIsDeleteStepContainerVisible(false)}
        onBlur={() => setIsDeleteStepContainerVisible(false)}
      >
        <p>{name}</p>

        <DeleteStepButton
          type="button"
          onClick={() => showDeleteModalForCustomStep(id)}
        >
          Excluir etapa
        </DeleteStepButton>
      </StepOptionsContainer>

      <Step
        ref={stepRef}
        type="button"
        onClick={() => handleUpdateCandidateStep({ id, name })}
      >
        <LeftSideContainer>
          <DragButtonContainer
            onMouseOver={() => setIsAbleToDrag(true)}
            onMouseLeave={() => !isDragging && setIsAbleToDrag(false)}
          >
            <img src={openOptions} alt="Três pontos verticais" />
            <img src={openOptions} alt="Três pontos verticais" />
          </DragButtonContainer>

          <StepName backgroundColor={color}>{name}</StepName>
        </LeftSideContainer>
      </Step>

      <OpenStepOptionsButton
        type="button"
        onClick={() => setIsDeleteStepContainerVisible(true)}
      >
        <img src={openOptions} alt="Três pontos horizontais" />
      </OpenStepOptionsButton>
    </Container>
  );
};
