import {
  BackgroundColor,
  OutlineColor,
  PrimaryColor,
} from 'assets/colors/palette';
import { Medium16Styles, Regular14Styles } from 'assets/fonts/fontStyles';
import styled from 'styled-components';

export const Container = styled.div`
  width: 438px;
  height: 280px;
  background-color: ${BackgroundColor};

  border: 1px solid ${OutlineColor};
  border-radius: 8px;

  p {
    ${Regular14Styles}
    line-height: 24px;
    margin: 20px 20px 24px;
  }

  button {
    ${Medium16Styles}

    width: calc(100% - 40px);
    height: 48px;
    margin-inline: 20px;
  }

  button + button {
    margin-top: 12px;
  }

  @media (max-width: 1100px) {
    width: 90vw;
    height: 308px;
  }
`;

export const TopContainer = styled.div`
  ${Medium16Styles}
  line-height: 48px;

  padding: 6px 20px;
  border-bottom: 1px solid ${OutlineColor};
`;

export const CancelButton = styled.button`
  background-color: ${BackgroundColor};
  border: 1px solid ${OutlineColor};
  border-radius: 100px;
  transition: 0.2s;

  &:hover {
    border-color: ${PrimaryColor};
  }
`;
