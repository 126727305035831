import { api } from './api';

const servicesPrefix = 'accountActivation';

export const sendActivationEmail = async (userEmail: string): Promise<void> => {
  await api.post(`${servicesPrefix}/sendEmail/${userEmail}`);
};

export const activateAccount = async (
  activationToken: string,
): Promise<void> => {
  await api.post(`${servicesPrefix}/activate/${activationToken}`);
};
