import { StartupsDashboardHeader } from 'pages/Dashboards/StartupsDashboard/StartupsDashboardHeader';
import { StartupVacanciesTab } from 'pages/Dashboards/StartupsDashboard/Tabs/StartupVacanciesTab';
import { VacancyFormWithStepsTab } from 'pages/Dashboards/StartupsDashboard/Tabs/VacancyFormWithStepsTab';
import { VacancyDetailsTab } from 'pages/Dashboards/StartupsDashboard/Tabs/VacancyDetailsTab';
import { Loader } from 'components/Loader';

import { COMPANY_NAME } from 'constants/company';
import { useStartup } from 'hooks/startup';
import React, { useCallback, useEffect, useState } from 'react';

import { Route, useHistory, useLocation } from 'react-router-dom';
import { AccountConfigurationTab } from 'pages/Dashboards/CommonTabs/AccountConfigurationTab';
import { findOneStartupByEmployerId } from 'services/entitiesServices/startupServices';
import { useAuth } from 'hooks/auth';
import { StartupFormWithStepsTab } from 'pages/Dashboards/StartupsDashboard/Tabs/StartupFormWithStepsTab';
import { useRedirectToEmailSentPageForAccountCreation } from 'utils/customHooks/redirects';
import { UserAccountType } from 'types/user';
import { TalentBankTab } from 'pages/Dashboards/StartupsDashboard/Tabs/TalentBankTab';
import { StartupFolderCandidatesTab } from 'pages/Dashboards/StartupsDashboard/Tabs/StartupFolderCandidatesTab';
import { routeAccountConfiguration, routeDashboard } from '../routesAddresses';
import {
  routeCreateStartup,
  routeCreateVacancy,
  routeEditStartup,
  routeEditVacancy,
  routeStartupFolderCandidates,
  routeStartupVacancies,
  routeTalentBank,
  routeVacancyDetails,
} from './startupsRoutesAddresses';

const STARTUPS_DASHBOARD_ROUTES = {
  createAndEditStartup: {
    Component: StartupFormWithStepsTab,
    path: [routeCreateStartup, routeEditStartup],
  },
  vacancies: {
    Component: StartupVacanciesTab,
    path: routeStartupVacancies,
  },
  createAndEditVacancy: {
    Component: VacancyFormWithStepsTab,
    path: [routeCreateVacancy, routeEditVacancy],
  },
  vacancyDetails: {
    Component: VacancyDetailsTab,
    path: routeVacancyDetails,
  },
  talentBank: {
    Component: TalentBankTab,
    path: routeTalentBank,
  },
  folderCandidates: {
    Component: StartupFolderCandidatesTab,
    path: routeStartupFolderCandidates,
  },
  accountConfiguration: {
    Component: AccountConfigurationTab,
    path: routeAccountConfiguration,
  },
};

export const StartupsRoutes: React.FC = () => {
  const history = useHistory();
  const { state } = useLocation();

  const { user } = useAuth();
  const isActivated = user?.activated;
  const isActivationEmailSent = user?.activationEmailSent;

  const { startup, updateStartupState, updateIsShowingSubscribeModal } =
    useStartup();
  const { name: startupName } = startup ?? {};

  document.title = `${COMPANY_NAME} ${startupName ? `| ${startupName}` : ''}`;

  const redirectToEmailSentPage = useRedirectToEmailSentPageForAccountCreation(
    'none',
    UserAccountType.EMPLOYER,
  );
  const [isLoadingStartup, setIsLoadingStartup] = useState(!startup);

  useEffect(() => {
    if (!user || !isActivated || !!startupName) return;

    findOneStartupByEmployerId(user.id)
      .then(
        async findedStartup =>
          findedStartup && updateStartupState(findedStartup),
      )
      .finally(() => setIsLoadingStartup(false));
  }, [isActivated, startupName, updateStartupState, user]);

  const handleMainRouteRedirect = useCallback(() => {
    if (isActivated) {
      history.push(routeStartupVacancies);
      if (state) updateIsShowingSubscribeModal(true);
      return;
    }

    if (isActivationEmailSent) {
      redirectToEmailSentPage();
      return;
    }

    history.push(routeCreateStartup);
  }, [
    history,
    isActivated,
    isActivationEmailSent,
    redirectToEmailSentPage,
    state,
    updateIsShowingSubscribeModal,
  ]);

  useEffect(() => {
    const { pathname } = history.location;
    const isMainRoute = pathname === routeDashboard;
    if (isMainRoute) handleMainRouteRedirect();
  }, [handleMainRouteRedirect, history.location]);

  if (isLoadingStartup && isActivated) return <Loader />;
  return (
    <>
      {isActivated && <StartupsDashboardHeader />}

      {Object.entries(STARTUPS_DASHBOARD_ROUTES).map(
        ([key, { Component, path }]) => (
          <Route key={key} exact path={path} component={Component} />
        ),
      )}
    </>
  );
};
