import { TextGradientPrimaryBackground } from 'assets/colors/background';
import { HighEmphasis } from 'assets/colors/palette';
import { Bold32Styles, Regular16Styles } from 'assets/fonts/fontStyles';
import { PrimaryButton } from 'components/Buttons';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 400px;
  align-items: center;
  gap: 2px;

  min-height: 746px;
  margin-inline: auto;
  padding-block: 8%;

  ${PrimaryButton} {
    width: 400px;
    margin-block: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    & > div {
      margin-top: 4px;
    }
  }

  a {
    font-size: 16px;
    ${TextGradientPrimaryBackground}
  }

  #keepConnectedAndForgotPassword {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    label {
      font-size: 16px;
    }
  }

  @media (max-width: 1100px) {
    width: 100vw;
    max-width: 400px;
    padding: 48px 18px 0;

    input {
      max-width: 100%;
    }

    ${PrimaryButton} {
      width: 100%;
      margin-block: 20px 28px;
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 16px;

  margin-bottom: 50px;
  color: ${HighEmphasis};
`;

export const SubTitle = styled.p`
  &,
  button {
    ${Regular16Styles}
  }

  button {
    ${TextGradientPrimaryBackground}
  }

  @media (max-width: 1100px) {
    &,
    button {
      font-size: 14px;
    }
  }
`;

export const Title = styled.h2`
  ${Bold32Styles}
`;
