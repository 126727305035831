import { OmitId } from 'types';
import { EntityWithNameDTO } from 'types/EntityWithNameDTO';
import { ILanguage } from 'types/languageEntity';
import { IVacancySkill } from 'types/vacancy/IVacancySkill';

interface RequirementsType {
  competencies?: OmitId<EntityWithNameDTO>[];
  skills?: OmitId<IVacancySkill>[];
  languages?: OmitId<ILanguage>[];
  certifications?: OmitId<EntityWithNameDTO>[];
  otherRequirements?: string;
}

export const verifyIfVacancyHasRequirements = ({
  otherRequirements,
  competencies,
  skills,
  languages,
  certifications,
}: RequirementsType): boolean =>
  !!otherRequirements ||
  (competencies ?? [])?.length > 0 ||
  (skills ?? [])?.length > 0 ||
  (languages ?? [])?.length > 0 ||
  (certifications ?? [])?.length > 0;
