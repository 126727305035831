import { Depth4 } from 'assets/colors/boxShadows';
import { BackgroundColor, OutlineColor } from 'assets/colors/palette';
import { Medium16Styles, Regular14Styles } from 'assets/fonts/fontStyles';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 20px;
  gap: 32px;
`;

export const NoTestsContainer = styled.div`
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.75px;
  font-weight: 500;

  margin-top: 193px;
  padding-inline: 16px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid ${OutlineColor};
  border-radius: 100px;
  background-color: ${BackgroundColor};
`;

export const TestContainer = styled.div`
  width: 406px;
  background-color: ${BackgroundColor};
  border: 1px solid ${OutlineColor};
  border-radius: 8px;
`;

export const TopContainer = styled.div`
  width: 100%;
  height: 60px;
  border-radius: 8px 8px 0 0;
  padding-inline: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${OutlineColor};
  margin-bottom: 20px;

  span {
    ${Medium16Styles}
  }
`;

export const InfoContainer = styled.div`
  position: relative;

  img {
    filter: invert(1) brightness(0.7);
  }

  div {
    ${Regular14Styles}
    line-height: 24px;

    pointer-events: none;
    visibility: 0;
    opacity: 0;
    transition: 0.3s;

    position: absolute;
    top: 130%;
    right: 0;
    z-index: 2;

    width: 364px;
    height: max-content;
    padding: 12px;

    border-radius: 8px;
    background-color: ${BackgroundColor};
    border: 1px solid ${OutlineColor};

    box-shadow: ${Depth4};
  }

  &:hover div {
    visibility: 1;
    opacity: 1;
    top: 140%;
  }
`;
