import styled from 'styled-components';
import { HighEmphasis } from 'assets/colors/palette';
import { Bold32Styles, Regular14Styles } from 'assets/fonts/fontStyles';
import { PrimaryButton } from 'components/Buttons';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-inline: auto;

  width: 1200px;
  margin-top: 12%;

  ${PrimaryButton} {
    width: 400px;
    margin-top: 30px;
  }

  @media (max-width: 1100px) {
    width: 100vw;
    max-width: 400px;
    padding: 48px 18px 0;
    margin-top: 0;

    input {
      max-width: 100%;
    }

    ${PrimaryButton} {
      width: 100%;
      margin-block: 20px 28px;
    }
  }
`;

export const Title = styled.div`
  ${Bold32Styles};
  line-height: 34px;
  color: ${HighEmphasis};
  margin-bottom: 10px;
  text-align: center;
`;

export const Description = styled.div`
  ${Regular14Styles};
  color: ${HighEmphasis};
  line-height: 24px;
  text-align: center;
  margin-bottom: 30px;

  max-width: 387px;
`;
