import { FormWithStepsContainer } from 'assets/styles/formWithStepsStyles/formWithStepsContainerStyles';
import {
  StepSubTitle,
  StepTitle,
  TitleContainer,
} from 'assets/styles/formWithStepsStyles/formWithStepsTitlesStyles';
import { ERROR_MODAL_CONFIGURATION, useAlertModal } from 'hooks/alertModal';
import { useEffect, useState } from 'react';
import { EntityWithIdDTO, IFormStepProps } from 'types';
import { businessModelOptions } from 'utils/typeOptions/businessModelOptions';
import { BusinessModel, BusinessModelsContainer } from './styles';

export { validateBusinessModelData } from './validateBusinessModelData';

export interface BusinessModelStepData {
  businessModel: EntityWithIdDTO;
}

export const BusinessModelStep: React.FC<
  IFormStepProps<BusinessModelStepData>
> = ({ initialData = {}, setStepDataFunction, errors }) => {
  const { businessModel: initialBusinessModel } = initialData;
  const { showModal } = useAlertModal();

  const [businessModel, setBusinessModel] = useState<EntityWithIdDTO>(
    initialBusinessModel ?? ({} as EntityWithIdDTO),
  );

  useEffect(() => {
    if (!errors) return;

    const { message } = errors;
    showModal({ ...ERROR_MODAL_CONFIGURATION, message });
  }, [errors, showModal]);

  useEffect(() => {
    if (setStepDataFunction) {
      setStepDataFunction({
        businessModel,
      });
    }
  }, [businessModel, setStepDataFunction]);

  return (
    <FormWithStepsContainer>
      <TitleContainer>
        <StepTitle>Modelo de negócio</StepTitle>
        <StepSubTitle>
          Qual o principal modelo de negócio da sua empresa?
        </StepSubTitle>
      </TitleContainer>

      <BusinessModelsContainer>
        {businessModelOptions.map(({ id, model }) => (
          <BusinessModel
            key={id}
            type="button"
            isSelected={id === businessModel.id}
            onClick={() => setBusinessModel({ id })}
          >
            {model}
          </BusinessModel>
        ))}
      </BusinessModelsContainer>
    </FormWithStepsContainer>
  );
};
