import { ITestProperties, PunctuationObject } from '..';
import { LogicalReasoningResultScreen } from './LogicalReasoningResultScreen';
import { LogicalReasoningInitialScreen } from './LogicalReasoningInitialScreen';
import { LOGICAL_REASONING_QUESTIONS } from './questions';

const generatePunctuationObject = (): PunctuationObject => ({
  correctAnswers: 0,
});

const updatePunctuationObject = (
  punctuationObject: PunctuationObject,
  _: undefined,
  isCorrectAnswer?: boolean,
): PunctuationObject => {
  if (!isCorrectAnswer) return punctuationObject;
  return { correctAnswers: punctuationObject.correctAnswers + 1 };
};

const generateTestResult = (punctuationObject: PunctuationObject): string => {
  const eachQuestionPunctuation = 100 / LOGICAL_REASONING_QUESTIONS.length;
  const totalPunctuation =
    punctuationObject.correctAnswers * eachQuestionPunctuation;

  return String(Math.ceil(totalPunctuation));
};

export const LogicalReasoningProperties: ITestProperties<undefined> = {
  timeInMinutes: 30,
  questions: LOGICAL_REASONING_QUESTIONS,
  generatePunctuationObject,
  updatePunctuationObject,
  generateTestResult,
  InitialScreen: LogicalReasoningInitialScreen,
  ResultScreen: LogicalReasoningResultScreen,
};
