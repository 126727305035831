import { ModalProps } from 'hooks/alertModal';
import { Container, SubContainer } from './styles';

interface AlertModalProps {
  modalProps: ModalProps;
}

export const AlertModal: React.FC<AlertModalProps> = ({
  modalProps: {
    backgroundColor,
    icon,
    isVisible,
    message,
    marginLeft,
    marginTop,
    forceIconWhiteColor,
  },
}) => {
  return (
    <Container isVisible={isVisible}>
      <SubContainer
        forceIconWhiteColor={forceIconWhiteColor}
        style={{
          backgroundColor,
          marginLeft,
          marginTop,
        }}
      >
        {icon && <img src={icon} alt="Ícone modal" />}
        <span>{message}</span>
      </SubContainer>
    </Container>
  );
};
