import {
  IVacancyDTOForMultipleVacanciesWithoutStartup,
  IVacancyResponse,
} from 'types/vacancy';
import { formatNumberToBRCurrency } from './formatNumberToBRCurrency';

export const getParsedVacancySalaryInformation = ({
  salaryToNegotiate,
  salary = '',
  paymentType,
}:
  | IVacancyResponse
  | IVacancyDTOForMultipleVacanciesWithoutStartup): string => {
  if (salaryToNegotiate) return 'Salário a negociar';
  return `${formatNumberToBRCurrency(salary!)} ${paymentType?.type}`;
};
