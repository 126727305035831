import { FormWithStepsContainer } from 'assets/styles/formWithStepsStyles/formWithStepsContainerStyles';
import { formStepInputStyles } from 'assets/styles/formWithStepsStyles/formWithStepsInputStyles';
import {
  StepSubTitle,
  StepTitle,
  TitleContainer,
} from 'assets/styles/formWithStepsStyles/formWithStepsTitlesStyles';
import { SecondaryButton } from 'components/Buttons';
import { Input } from 'components/Input';
import { ImageInput } from 'components/Input/ImageInput';
import { InputErrorMessageStyle } from 'components/Input/styles';
import { TextAreaInput } from 'components/Input/TextAreaInput';
import { Select } from 'components/Select';
import { useFormError } from 'errors/useFormError';
import { InputsContainer } from 'pages/Dashboards/StartupsDashboard/Tabs/VacancyFormWithStepsTab/Steps/VacancyDefinitionStep/styles';
import { useCallback, useEffect, useRef } from 'react';
import { mask } from 'remask';
import { IFormStepProps } from 'types';
import { cnpjPattern, yearPattern } from 'types/maskPatterns';
import { StartupEmployeesQuantity } from 'types/startup';
import { getInputStateValue } from 'utils';
import { useInputStates } from 'utils/customHooks';
import { employeesQuantityOptions } from 'utils/typeOptions/employeesQuantityOptions';
import { ImageDescriptionContainer, ImageInputContainer } from './styles';

export { validatePresentationData } from './validatePresentationData';

export interface PresentationStepData {
  name: string;
  cnpj: string;
  foundationYear: number;
  employeesQuantity: StartupEmployeesQuantity;
  about: string;
  logo: string;
}

export const PresentationStep: React.FC<
  IFormStepProps<PresentationStepData>
> = ({
  initialData = {} as PresentationStepData,
  setStepDataFunction,
  errors,
}) => {
  const { name, about, cnpj, employeesQuantity, foundationYear, logo } =
    initialData;
  const { handleFormError } = useFormError();

  const nameStates = useInputStates('name', name);
  const aboutStates = useInputStates('about', about);
  const cnpjStates = useInputStates('cnpj', cnpj);
  const employeesQuantityStates = useInputStates(
    'employeesQuantity',
    employeesQuantity
      ? employeesQuantityOptions.findIndex(
          ({ label }) => label === employeesQuantity,
        ) + 1
      : '',
  );
  const foundationYearStates = useInputStates('foundationYear', foundationYear);

  const logoStates = useInputStates('logo', logo);
  const logoInputRef = useRef<HTMLInputElement>(null);

  const getUpdatedStepData = useCallback(() => {
    const employeesQuantityInputStateValue = getInputStateValue(
      employeesQuantityStates,
    );
    const employeesQuantityValue = Number(employeesQuantityInputStateValue);
    const parsedEmployeesQuantity = employeesQuantityValue
      ? employeesQuantityOptions[employeesQuantityValue - 1].enumValue
      : employeesQuantityInputStateValue;

    const updatedStatesObject = {
      name: getInputStateValue(nameStates),
      cnpj: getInputStateValue(cnpjStates),
      foundationYear: Number(getInputStateValue(foundationYearStates)),
      employeesQuantity: parsedEmployeesQuantity,
      about: getInputStateValue(aboutStates),
      logo: getInputStateValue(logoStates),
    };

    return updatedStatesObject as PresentationStepData;
  }, [
    aboutStates,
    cnpjStates,
    employeesQuantityStates,
    foundationYearStates,
    logoStates,
    nameStates,
  ]);

  useEffect(() => {
    if (!errors) return;

    const stepStates = [
      nameStates,
      aboutStates,
      cnpjStates,
      employeesQuantityStates,
      foundationYearStates,
      logoStates,
    ];
    handleFormError(errors, stepStates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    if (!setStepDataFunction) return;

    const updatedData = getUpdatedStepData();
    setStepDataFunction(updatedData);
  }, [getUpdatedStepData, setStepDataFunction]);

  const handleImageContainerButtonClick = useCallback(() => {
    if (!logoStates.mainState.value) logoInputRef.current?.click();
    else logoStates.mainState.setFunction('');
  }, [logoStates.mainState]);

  return (
    <FormWithStepsContainer>
      <TitleContainer>
        <StepTitle>Apresente-se aos candidatos</StepTitle>
        <StepSubTitle>
          Vamos começar adicionando um logotipo de alta qualidade, nome da sua
          empresa e uma descrição curta e nítida do que vocês fazem.
        </StepSubTitle>
      </TitleContainer>

      <InputsContainer>
        <ImageInputContainer>
          <ImageInput
            minimumImageDimensions={{ width: 100, height: 100 }}
            states={logoStates}
            alternativeRef={logoInputRef}
          />

          <ImageDescriptionContainer>
            <span id="description-title">Logotipo da empresa</span>
            <span id="description-content">
              Escolha uma imagem quadrada com uma cor de fundo sólida de pelo
              menos 100x100px
            </span>
            <SecondaryButton
              style={{ width: 'fit-content', height: 48 }}
              onClick={handleImageContainerButtonClick}
            >
              {!logoStates.mainState.value ? 'Adicionar foto' : 'Remover'}
            </SecondaryButton>
          </ImageDescriptionContainer>
        </ImageInputContainer>

        <InputErrorMessageStyle className="error-message">
          {logoStates.errorMessageState.value}
        </InputErrorMessageStyle>

        <Input
          name="Nome da empresa"
          placeholder="inserir nome da empresa"
          states={nameStates}
          style={formStepInputStyles}
          onChange={({ target: { value } }) =>
            nameStates.mainState.setFunction(value.replace('_', ''))
          }
        />

        <Input
          name="CNPJ"
          placeholder="00.000.000/0000-00"
          states={cnpjStates}
          style={formStepInputStyles}
          maxLength={18}
          onBlur={() =>
            cnpjStates.mainState.setFunction(
              mask(cnpjStates.mainState.value as string, cnpjPattern),
            )
          }
        />

        <Input
          name="Ano de fundação"
          placeholder="inserir ano de fundação"
          states={foundationYearStates}
          style={formStepInputStyles}
          maxLength={4}
          onChange={({ target: { value } }) => {
            foundationYearStates.mainState.setFunction(
              mask(value, yearPattern),
            );
          }}
        />

        <Select
          name="Número de funcionários"
          placeholder="selecionar número de funcionários"
          selectOptions={employeesQuantityOptions}
          states={employeesQuantityStates}
          style={formStepInputStyles}
          startWithoutValue
        />

        <TextAreaInput
          name="Descreva sua empresa, o que vocês fazem e porquê"
          placeholder={
            '(seu nome de inicialização) está se desenvolvendo ' +
            '(seu produto/serviço principal) para ajudar ' +
            '(seu cliente-alvo) a resolver (problema que você está resolvendo) ' +
            'com (definir solução utilizada).'
          }
          states={aboutStates}
          style={{ ...formStepInputStyles, minHeight: 200 }}
          descriptionText="Adicione uma descrição de 100 a 4000 caracteres"
          charactersLimitLength={4000}
        />
      </InputsContainer>
    </FormWithStepsContainer>
  );
};
