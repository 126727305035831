import styled from 'styled-components';
import { LowEmphasis, OutlineColor } from 'assets/colors/palette';

interface ContainerProps {
  fillPercentage: number;
}

export const Container = styled.div<ContainerProps>`
  width: 160px;
  height: 160px;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    position: absolute;
    transform: rotate(146deg);

    top: 0;
    left: 0;

    circle {
      fill: none;
      stroke-dasharray: 472;
      stroke-width: 16px;
    }
  }

  @keyframes fillAnimation {
    100% {
      stroke-dashoffset: ${({ fillPercentage }) => 472 - 302 * fillPercentage};
    }
  }

  .filled-circle circle {
    stroke: url(#GradientColor);
    stroke-dashoffset: 472;
    animation: fillAnimation 0.5s linear forwards;
  }

  .unfilled-circle circle {
    stroke: ${OutlineColor};
    stroke-dashoffset: 170;
  }
`;

export const PunctuationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;

  text-align: center;
  letter-spacing: 0.5px;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;

  h2 {
    font-size: 48px;
    line-height: 48px;
  }

  small {
    color: ${LowEmphasis};
  }
`;
