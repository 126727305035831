import { Depth16 } from 'assets/colors/boxShadows';
import { BackgroundColor, OutlineColor } from 'assets/colors/palette';
import styled, { css } from 'styled-components';

interface OptionsProps {
  isVisible: boolean;
}

export const Container = styled.div`
  position: absolute;
  right: 10px;
  bottom: 0;
  height: 100%;

  display: flex;
  align-items: center;
`;

export const OptionsContainer = styled.div<OptionsProps>`
  position: absolute;
  width: 296px;
  height: 88px;

  background-color: ${BackgroundColor};
  border: 1px solid ${OutlineColor};
  border-radius: 8px;
  box-shadow: ${Depth16};

  right: 0;
  bottom: 0;
  z-index: 1;
  transition: 0.4s;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  ${({ isVisible }) =>
    isVisible
      ? css`
          opacity: 1;
          visibility: visible;
        `
      : css`
          visibility: hidden;
          opacity: 0;
        `}
`;

export const OpenOptionsButton = styled.button`
  background: transparent;
  outline: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;
