import React from 'react';
import { AuthContext } from './auth';
import { CandidateContext } from './candidate';
import { VacanciesCustomStepsContext } from './vacanciesCustomSteps';
import { StartupContext } from './startup';
import { AlertModalContext } from './alertModal';
import { WindowWidthContext } from './windowWidth';

export const ContextsProvider: React.FC = ({ children }) => (
  <WindowWidthContext>
    <AlertModalContext>
      <VacanciesCustomStepsContext>
        <StartupContext>
          <CandidateContext>
            <AuthContext>{children}</AuthContext>
          </CandidateContext>
        </StartupContext>
      </VacanciesCustomStepsContext>
    </AlertModalContext>
  </WindowWidthContext>
);
