import { CandidateEvaluation } from 'types/CandidateEvaluation';

export const translateCandidateEvaluation = (
  evaluation: CandidateEvaluation,
): string => {
  switch (evaluation) {
    case CandidateEvaluation.NOT_EVALUATED:
    case CandidateEvaluation.NEW_CANDIDATE:
      return 'Sem avaliação';

    case CandidateEvaluation.POSITIVE:
      return 'Gostei';
    case CandidateEvaluation.NEGATIVE:
      return 'Não gostei';
    case CandidateEvaluation.FAVORITE:
      return 'Favorito';
    case CandidateEvaluation.LOVED:
      return 'Amei';

    default:
      return 'Sem avaliação';
  }
};
