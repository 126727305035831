import { ErrorDefaut, GrayLine, PrimaryColor } from 'assets/colors/palette';
import {
  TextareaHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ReactInputState } from 'types/reactInputState';
import { convertTextWithHyphenListToCircleList } from 'utils/conversion';
import { InputErrorMessageStyle, InputNameStyle } from '../styles';
import { TextAreaContainer, TextArea, TextAreaDescription } from './styles';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  states: ReactInputState;
  charactersLimitLength: number;
  descriptionText?: string;
  notHasError?: boolean;
  isOptional?: boolean;
}

export const TextAreaInput: React.FC<TextAreaProps> = ({
  name,
  states: {
    mainState: { setFunction: mainFunction, value: mainValue },
    errorMessageState: { setFunction: errorFunction, value: errorValue },
  },
  onChange,
  disabled,
  descriptionText,
  charactersLimitLength,
  notHasError,
  style,
  isOptional,
  ...props
}) => {
  const defaultStyles = { width: style?.width ?? 400, maxWidth: '100%' };
  const textAreaContainerRef = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [previousTextAreaHeight, setPreviousTextAreaHeight] = useState(
    style?.minHeight ?? 144,
  );

  const handleScrollOnTextChange = useCallback(
    (updatedContent: string) => {
      const { current: textAreaContainerCurrent } = textAreaContainerRef;
      if (!textAreaContainerCurrent) return;
      textAreaContainerCurrent.dataset.replicatedValue = updatedContent;

      const { current: textAreaCurrent } = textAreaRef;
      if (!textAreaCurrent) return;

      const { scrollHeight } = textAreaCurrent;
      if (scrollHeight > previousTextAreaHeight) {
        textAreaCurrent.scrollIntoView();
      }

      setPreviousTextAreaHeight(scrollHeight);
    },
    [previousTextAreaHeight],
  );

  useEffect(() => {
    handleScrollOnTextChange(mainValue as string);
  }, [handleScrollOnTextChange, mainValue]);

  const handleTextChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const { current } = textAreaRef;
      if (!current) return;

      const { selectionStart } = current;
      const {
        target: { value: textAreaValue },
      } = event;

      const { updatedText, selectionExtraUpdate } =
        convertTextWithHyphenListToCircleList(
          textAreaValue,
          selectionStart,
          (mainValue as string).length > textAreaValue.length,
        );

      if (onChange) {
        const parsedEvent = event;
        parsedEvent.target.value = updatedText;
        onChange(parsedEvent);
      } else mainFunction(updatedText);

      if (errorValue) errorFunction('');

      const updatedSelection = selectionStart + selectionExtraUpdate;
      setTimeout(
        () => current.setSelectionRange(updatedSelection, updatedSelection),
        1,
      );
    },
    [errorFunction, errorValue, mainFunction, mainValue, onChange],
  );

  return (
    <div className="input" style={defaultStyles}>
      <InputNameStyle disabled={disabled}>
        {name} {!!name && isOptional && <span>(Opcional)</span>}
      </InputNameStyle>

      <TextAreaContainer
        ref={textAreaContainerRef}
        style={{ ...style, height: 'auto' }}
      >
        <TextArea
          ref={textAreaRef}
          className="input"
          borderColor={errorValue ? ErrorDefaut : GrayLine}
          hoverAndFocusColor={errorValue ? ErrorDefaut : PrimaryColor}
          autoComplete="off"
          onFocus={() => {
            if (mainValue) return;

            const { current } = textAreaRef;
            if (!current) return;

            current.scrollIntoView();
          }}
          onChange={handleTextChange}
          value={mainValue as string}
          disabled={disabled}
          maxLength={charactersLimitLength}
          style={{ ...style, height: 'auto' }}
          {...props}
        />
      </TextAreaContainer>

      <TextAreaDescription style={defaultStyles}>
        <span className="input-description">{descriptionText}</span>
        <span className="characters-counter">{`${
          (mainValue as string).length
        } / ${charactersLimitLength}`}</span>
      </TextAreaDescription>

      {!notHasError && (
        <InputErrorMessageStyle style={defaultStyles}>
          {errorValue}
        </InputErrorMessageStyle>
      )}
    </div>
  );
};
