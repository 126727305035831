import { localStorageKeys } from 'constants/localStorageKeys';
import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import { routeExpiration } from 'routes/routesAddresses';
import { api } from 'services/api';
import {
  findUpdatedUserById,
  renewToken,
  updateUser,
  updateWantsEmailNotifications,
} from 'services/entitiesServices/userServices';
import { IServerError } from 'types';
import {
  IAuthenticateUserResponse,
  IUpdateUserRequest,
  IUserResponse,
  UserAccountType,
} from 'types/user';
import { convertMinutesToMilliseconds } from 'utils/conversion/convertMinutesToMilliseconds';
import { useCandidate } from './candidate';
import { useStartup } from './startup';

interface AuthData {
  user: IUserResponse;
  token: string;
  keepConnected: boolean;
}

interface IAuthContext extends Omit<IAuthenticateUserResponse, 'user'> {
  user?: IUserResponse;
  isLoadingUser: boolean;
  updateUserData(updatedUser: IUpdateUserRequest): Promise<void>;
  updateUserWantsEmailNotifications(
    wantsEmailNotifications: boolean,
    updateJustLocally?: boolean,
  ): Promise<void>;
  setAuthenticatedUserLocalData(
    updatedUser: IUserResponse,
    updatedToken?: string,
    updatedKeepConnected?: boolean,
  ): void;
  signOut(): void;
}

const TIME_TO_RENEW_TOKEN = convertMinutesToMilliseconds(28);

const authContext = createContext<IAuthContext>({} as IAuthContext);

const AuthContext: React.FC = ({ children }) => {
  const history = useHistory();
  const { clearStartupState } = useStartup();
  const { clearCandidateState } = useCandidate();

  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [authData, setAuthData] = useState<AuthData>({} as AuthData);

  const clearApiConfigurationForAuthenticatedUser = useCallback(() => {
    api.interceptors.response.use(
      response => response,
      error => {
        throw error;
      },
    );

    api.defaults.headers.authorization = undefined;
  }, []);

  const signOut = useCallback(() => {
    Object.values(localStorageKeys).forEach(key =>
      localStorage.removeItem(key),
    );

    clearApiConfigurationForAuthenticatedUser();
    setAuthData({} as AuthData);

    clearStartupState();
    clearCandidateState();
  }, [
    clearApiConfigurationForAuthenticatedUser,
    clearCandidateState,
    clearStartupState,
  ]);

  const axiosErrorRequiresSignOut = ({
    response: {
      data: { message, status },
    },
  }: IServerError) => {
    if (status !== 403) return false;
    return message === 'Access Denied' || message.startsWith('JWT expired at');
  };

  const signOutWithRedirect = useCallback(() => {
    setIsLoadingUser(false);
    signOut();

    const {
      location: { pathname },
    } = history;
    const needsRedirect =
      pathname.startsWith('/dashboard') || pathname.startsWith('/admin');

    if (needsRedirect) history.push(routeExpiration);
  }, [history, signOut]);

  const setApiConfigurationForAuthenticatedUser = useCallback(
    (token: string) => {
      const alreadySetInterceptors = !!api.defaults.headers.authorization;
      api.defaults.headers.authorization = `Bearer ${token}`;
      if (alreadySetInterceptors) return;

      // Signout when 403 error.
      api.interceptors.response.use(
        response => response,
        (error: IServerError) => {
          if (axiosErrorRequiresSignOut(error)) {
            return new Promise(() => signOutWithRedirect());
          }

          throw error;
        },
      );
    },
    [signOutWithRedirect],
  );

  const getUpdatedUserBasedOnStorageUser = useCallback(
    async (storageUser: IUserResponse) => {
      try {
        const findedUser = await findUpdatedUserById(
          storageUser.id,
          storageUser.updatedAt,
        );

        return findedUser ?? storageUser;
      } catch {
        return storageUser;
      }
    },
    [],
  );

  const getUpdatedToken = useCallback(
    async (previousToken?: string) => {
      if (previousToken) setApiConfigurationForAuthenticatedUser(previousToken);

      const { token } = await renewToken();
      return token;
    },
    [setApiConfigurationForAuthenticatedUser],
  );

  const setTimeoutForRenewToken = useCallback(() => {
    setTimeout(async () => {
      const token = await getUpdatedToken();

      setApiConfigurationForAuthenticatedUser(token);
      localStorage.setItem(localStorageKeys.token, token);
      setAuthData(previousData => ({ ...previousData, token }));

      setTimeoutForRenewToken();
    }, TIME_TO_RENEW_TOKEN);
  }, [getUpdatedToken, setApiConfigurationForAuthenticatedUser]);

  const setAuthenticatedUserLocalData = useCallback(
    (
      updatedUser: IUserResponse,
      updatedToken: string,
      updatedKeepConnected: boolean,
      needsToUpdateUserOnLocalStorage = true,
    ) => {
      if (needsToUpdateUserOnLocalStorage) {
        localStorage.setItem(
          localStorageKeys.user,
          JSON.stringify(updatedUser),
        );
      }

      localStorage.setItem(localStorageKeys.token, updatedToken);
      localStorage.setItem(
        localStorageKeys.keepConnected,
        String(updatedKeepConnected),
      );

      setApiConfigurationForAuthenticatedUser(updatedToken);
      if (!updatedKeepConnected) setTimeoutForRenewToken();

      setAuthData({
        user: updatedUser,
        token: updatedToken,
        keepConnected: updatedKeepConnected,
      });
    },
    [setApiConfigurationForAuthenticatedUser, setTimeoutForRenewToken],
  );

  useEffect(() => {
    const executeValidations = async () => {
      const user = localStorage.getItem(localStorageKeys.user);
      const storageToken = localStorage.getItem(localStorageKeys.token);
      if (!user || !storageToken) return;

      const parsedUser = JSON.parse(user) as IUserResponse;
      if (parsedUser.accountType === UserAccountType.ADMIN) {
        signOutWithRedirect();
        return;
      }

      const keepConnected =
        localStorage.getItem(localStorageKeys.keepConnected) === 'true';
      const updatedToken = keepConnected
        ? storageToken
        : await getUpdatedToken(storageToken);

      const updatedUser = await getUpdatedUserBasedOnStorageUser(parsedUser);

      setAuthenticatedUserLocalData(
        updatedUser,
        updatedToken,
        keepConnected,
        updatedUser !== parsedUser,
      );
    };

    executeValidations().finally(() => setIsLoadingUser(false));
  }, [
    getUpdatedToken,
    getUpdatedUserBasedOnStorageUser,
    setAuthenticatedUserLocalData,
    signOutWithRedirect,
  ]);

  const updateUserWantsEmailNotifications = useCallback(
    async (wantsEmailNotifications: boolean, updateJustLocally = false) => {
      if (!updateJustLocally) {
        await updateWantsEmailNotifications(wantsEmailNotifications);
      }

      localStorage.setItem(
        localStorageKeys.user,
        JSON.stringify({ ...authData.user, wantsEmailNotifications }),
      );

      setAuthData(previousData => ({
        ...previousData,
        user: { ...previousData.user, wantsEmailNotifications },
      }));
    },
    [authData.user],
  );

  const updateUserData = useCallback(
    async (updatedUserData: IUpdateUserRequest) => {
      const updatedUser = await updateUser(updatedUserData);

      localStorage.setItem(localStorageKeys.user, JSON.stringify(updatedUser));
      setAuthData(previousData => ({ ...previousData, user: updatedUser }));
    },
    [],
  );

  return (
    <authContext.Provider
      value={{
        ...authData,
        updateUserData,
        updateUserWantsEmailNotifications,
        setAuthenticatedUserLocalData,
        isLoadingUser,
        signOut,
      }}
    >
      {children}
    </authContext.Provider>
  );
};

const useAuth = (): IAuthContext => useContext(authContext);

export { AuthContext, useAuth };
