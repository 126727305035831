import { differenceInCalendarDays } from 'date-fns';

const ACTUAL_DATE = new Date();

export const getVacancyDateGapFromActualDate = (vacancyDate: Date): string => {
  const differenceFromActualDate = Math.abs(
    differenceInCalendarDays(new Date(vacancyDate), ACTUAL_DATE),
  );
  if (differenceFromActualDate === 0) return 'hoje';

  if (differenceFromActualDate >= 30) {
    const monthGap = Math.floor(differenceFromActualDate / 30);
    return `há ${monthGap} ${monthGap === 1 ? 'mês' : 'meses'}`;
  }

  return `há ${differenceFromActualDate} dia${
    differenceFromActualDate > 1 ? 's' : ''
  }`;
};
