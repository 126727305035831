import styled from 'styled-components';
import { Regular14Styles } from 'assets/fonts/fontStyles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    ${Regular14Styles}
    line-height: 24px;
    margin-top: 32px;
  }
`;
