import styled, { css } from 'styled-components';
import {
  GradientPrimary,
  GrayBackground,
  GrayBody,
  MediumEmphasis,
  GrayLine,
  PrimaryColor,
  BackgroundColor,
  HighEmphasis,
  OutlineColor,
  LowEmphasis,
} from 'assets/colors/palette';
import { PrimaryButton, SecondaryButton } from 'components/Buttons';
import { Link } from 'react-router-dom';
import { Depth4, Depth8 } from 'assets/colors/boxShadows';
import {
  Bold14Styles,
  Bold18Styles,
  Medium16Styles,
  Regular14Styles,
  Regular16Styles,
} from 'assets/fonts/fontStyles';

interface VacancyJobButtonProps {
  isActivated: boolean;
}

interface SalaryFilterProps {
  barPercentage: number;
}

interface FiltersContainerProps {
  isFiltersVisibleOnMobile: boolean;
}

export const VacancyJobButton = styled(SecondaryButton)<VacancyJobButtonProps>`
  ${Regular14Styles}

  min-width: max-content;
  padding: 16px 24px;
  height: 46px;

  ${({ isActivated }) =>
    isActivated
      ? css`
          border: 1px solid ${PrimaryColor};
          background-clip: content-box;
          webkit-background-clip: content-box;
          color: ${GrayBackground};
          -webkit-text-fill-color: ${GrayBackground};
          background: ${GradientPrimary};

          &:after {
            display: none;
          }
        `
      : css`
          background-clip: border-box;
          webkit-background-clip: border-box;
          -webkit-text-fill-color: ${MediumEmphasis};

          background: ${BackgroundColor};
          border-color: ${GrayLine};
          color: ${MediumEmphasis};
          transition: none;
        `}
`;

export const VacancyJobTypesContainer = styled.div`
  margin-inline: auto;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding-block: 46px 30px;

  @media (max-width: 1100px) {
    justify-content: flex-start;
    max-width: 100vw;
    overflow-x: scroll;

    padding-inline: 5vw;
    padding-block: 32px 0px;
    margin-top: 28px;
    border-top: 1px solid ${OutlineColor};

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const SearchVacancyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  canvas {
    width: 100%;
    height: 84px;
    background-color: ${OutlineColor};
    opacity: 0.25;
  }

  .title {
    margin-top: 40px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 10px;

    h1 {
      color: ${GrayBody};
      line-height: 34px;
      letter-spacing: 1px;
    }
    p {
      color: ${MediumEmphasis};
      line-height: 28px;
      letter-spacing: 0.75px;
    }
  }

  @media (max-width: 1100px) {
    canvas {
      width: 100%;
      height: 186px;
    }
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 64px;
  overflow: hidden;

  width: 1000px;
  height: 64px;
  margin-top: -32px;
  background: ${BackgroundColor};
  box-shadow: ${Depth8};
  z-index: 1;

  ${PrimaryButton} {
    height: 100%;
    min-width: 164px;
    outline: 0;
  }

  @media (max-width: 1100px) {
    width: 100%;
    height: auto;
    margin-top: -154px;
    background: transparent;
    border-radius: 8px;

    box-shadow: none;
    flex-direction: column;

    ${PrimaryButton} {
      width: 90vw;
      height: 64px;

      border-radius: 0px 0px 8px 8px;

      span {
        margin-left: -57px;
      }
    }
  }
`;

export const SearchInput = styled.div`
  display: flex;
  align-items: center;
  padding-left: 20px;

  flex: 1;
  height: 76%;

  position: relative;
  background: ${BackgroundColor};
  cursor: text;

  &:first-child {
    min-width: 500px;
  }

  & + & {
    border-left: 1px solid ${OutlineColor};
  }

  & + & > img,
  button img {
    filter: invert(92%) sepia(0%) saturate(1%) hue-rotate(161deg)
      brightness(81%) contrast(82%);
  }

  img {
    width: 24px;
    height: 24px;
  }

  input {
    ${Bold14Styles}
    color: ${HighEmphasis};

    outline: 0;
    flex: 1;
    height: 90%;
    margin-left: 12px;

    &::placeholder {
      color: ${LowEmphasis};
    }
  }

  button {
    position: absolute;
    right: 16px;

    width: 24px;
    height: 24px;
    background: transparent;
    outline: 0;
  }

  @media (max-width: 1100px) {
    min-width: 90vw;
    width: 90vw;

    min-height: 64px;
    height: 64px;
    box-shadow: ${Depth4};

    &:first-child {
      min-width: 90vw;
      width: 90vw;
      border-radius: 8px 8px 0px 0px;
    }

    & + & {
      border-left: none;
      border-top: 1px solid ${OutlineColor};
    }
  }
`;

export const OpenMobileFilterOptionsButton = styled.button`
  position: absolute;
  background-color: transparent;
  height: 44px;

  display: flex;
  align-items: center;
  padding-inline: 16px;
  border-left: 1px solid ${BackgroundColor};

  top: 10px;
  right: 0;
`;

export const MainContainer = styled.main`
  width: 1248px;
  margin-inline: auto;

  display: flex;
  justify-content: center;
  gap: 80px;
  padding-block: 40px 70px;

  @media (max-width: 1100px) {
    width: 90vw;
    padding-block: 30px;
  }
`;

export const FiltersContainer = styled.section<FiltersContainerProps>`
  width: 256px;
  display: flex;
  flex-direction: column;
  gap: 36px;

  .container-name {
    ${Medium16Styles}
    color: ${HighEmphasis};
  }

  p {
    ${Regular16Styles}
    color: ${HighEmphasis};
  }

  @media (max-width: 1100px) {
    position: fixed;
    gap: 36px;
    transition: 0.4s;
    bottom: ${({ isFiltersVisibleOnMobile }) =>
      isFiltersVisibleOnMobile ? 0 : '-100%'};

    background-color: ${BackgroundColor};
    width: 100vw;
    z-index: 1;
    padding-inline: 5vw;
    padding-bottom: 30px;

    border-radius: 8px 8px 0px 0px;
    filter: drop-shadow(1.2px 0px 3.6px rgba(0, 0, 0, 0.1))
      drop-shadow(6.4px 0px 14.4px rgba(0, 0, 0, 0.1));

    .container-name {
      height: 60px;
      justify-content: space-between;
      position: relative;
      margin-bottom: -18px;

      &,
      button {
        display: flex;
        align-items: center;
      }

      button {
        justify-content: center;
        background-color: transparent;
      }

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: -6.75vw;

        width: 100vw;
        height: 1px;
        background-color: ${OutlineColor};
      }
    }
  }
`;

export const SalaryFilter = styled.div<SalaryFilterProps>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 8px;

  input {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 4px;
    border-radius: 100px;
    background: ${GrayLine};
    outline: none;
    margin-top: 8px;
    position: relative;
    cursor: pointer;

    &:after {
      content: '';
      width: ${({ barPercentage }) => 0.91 * barPercentage}%;
      height: 4px;
      background: ${GrayBody};
      position: absolute;
      left: 0;
    }
  }

  input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 24px;
    height: 24px;
    background: ${GrayBody};
    border-radius: 50%;
  }

  input::-moz-range-thumb {
    width: 24px;
    height: 24px;
    background: ${GrayBody};
    border-radius: 50%;
  }

  .value-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: 24px;
      line-height: 38px;
    }
  }
`;

export const OtherFilters = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const VacanciesContainer = styled.section`
  width: 600px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: 1100px) {
    width: 100%;
    gap: 16px;
  }
`;

export const ClearSearchResultsButton = styled.button`
  width: max-content;
  background: transparent;
  outline: 0;
  cursor: pointer;

  display: flex;
  align-items: center;
  margin-bottom: 16px;
  gap: 10px;

  p {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.75px;
    color: ${PrimaryColor};
  }
`;

export const VacancyTopData = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .company-data {
    ${Bold14Styles}
    color: ${HighEmphasis};

    display: flex;
    align-items: center;
    gap: 12px;

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }

  @media (max-width: 1100px) {
    .company-data {
      font-size: 12px;
    }
  }
`;

export const VacancyBottomData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .vacancy-title {
    ${Bold18Styles}
    color: ${HighEmphasis};
  }

  @media (max-width: 1100px) {
    .vacancy-title {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

export const Vacancy = styled(Link)`
  width: 100%;
  height: 148px;
  padding: 20px;
  cursor: pointer;
  outline: 0;

  text-decoration: none;
  background: ${BackgroundColor};
  border: 1px solid ${GrayLine};
  border-radius: 8px;
  transition: 0.2s;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    box-shadow: ${Depth4};
  }

  &:active {
    border-color: ${PrimaryColor};
  }

  @media (max-width: 1100px) {
    height: 124px;
    padding: 12px;
  }
`;

export const VacancyAspects = styled.div`
  ${Regular14Styles}
  line-height: 24px;
  color: ${MediumEmphasis};

  display: flex;
  gap: 20px;

  p + p {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background-color: ${MediumEmphasis};
      left: -10px;
      bottom: 44%;
    }
  }

  @media (max-width: 1100px) {
    font-size: 12px;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const VacancyExtraAspects = styled.div`
  display: flex;
  gap: 22px;
  margin-top: 4px;

  img {
    width: 16px;
    height: 16px;
  }
`;

export const CompaniesHiringContainer = styled.section`
  width: 256px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  p {
    ${Medium16Styles}
    margin-bottom: 24px;
  }

  @media (max-width: 1100px) {
    display: none;
  }
`;

export const CompanyHiring = styled.a`
  width: 256px;
  height: 48px;
  text-decoration: none;

  border: 1px solid ${OutlineColor};
  border-radius: 8px;
  padding: 12px;
  transition: 0.2s;

  ${Bold14Styles}
  color: ${HighEmphasis};
  font-size: 12px;

  &:hover {
    border-color: ${PrimaryColor};
  }

  &,
  .company-data {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .company-data {
    gap: 8px;

    img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }
  }

  .vacancies-quantity {
    color: ${MediumEmphasis};
    background-color: #d9d9d9;

    padding-inline: 12px;
    min-width: 32px;
    height: 32px;
    border-radius: 32px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
