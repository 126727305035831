import { TextGradientPrimaryBackground } from 'assets/colors/background';
import { BackgroundColor, OutlineColor } from 'assets/colors/palette';
import { Bold18Styles } from 'assets/fonts/fontStyles';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 20px 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  & > span {
    ${Bold18Styles}
    ${TextGradientPrimaryBackground}
    margin-right: auto;
  }

  & > button {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.75px;
    font-weight: 500;

    background-color: ${BackgroundColor};
    border: 1px solid ${OutlineColor};
    border-radius: 100px;

    padding-inline: 16px;
    height: 40px;
    transition: 0.2s;

    &:hover {
      background-color: rgba(220, 220, 220, 0.2);
    }
  }
`;

export const IconContainer = styled.div`
  position: relative;
  width: 68px;
  height: 68px;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 11px;

  .profile-icon {
    width: 24px;
    height: 24px;

    z-index: 1;
  }

  .polygon-icon {
    width: 68px;
    height: 68px;

    position: absolute;
    z-index: 0;
  }
`;
