import { Container, PunctuationContainer } from './styles';

interface TestPunctuationHighlightProps {
  punctuation: number;
  maxPunctuation?: number;
}

export const TestPunctuationHighlight: React.FC<
  TestPunctuationHighlightProps
> = ({ punctuation, maxPunctuation = 100 }) => {
  return (
    <Container fillPercentage={punctuation / maxPunctuation}>
      <PunctuationContainer>
        <span>Pontuação</span>
        <h2>{punctuation}</h2>
        <small>DE {maxPunctuation}</small>
      </PunctuationContainer>

      <svg
        className="unfilled-circle"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="160px"
        height="160px"
      >
        <circle cx="80" cy="80" r="70" strokeLinecap="round" />
      </svg>

      <svg
        className="filled-circle"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="160px"
        height="160px"
      >
        <defs>
          <linearGradient id="GradientColor">
            <stop offset="0%" stopColor="#FF4B00" />
            <stop offset="100%" stopColor="#EE2F23" />
          </linearGradient>
        </defs>
        <circle cx="80" cy="80" r="70" strokeLinecap="round" />
      </svg>
    </Container>
  );
};
