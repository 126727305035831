import { FormWithStepsContainer } from 'assets/styles/formWithStepsStyles/formWithStepsContainerStyles';
import {
  StepSubTitle,
  StepTitle,
  TitleContainer,
} from 'assets/styles/formWithStepsStyles/formWithStepsTitlesStyles';
import { PlusButton } from 'components/Buttons';
import { convertParsedValidationErrorsToArrayOfErrors } from 'errors/convertParsedValidationErrorsToArrayOfErrors';
import { getValidationErrors } from 'errors/getValidationErrors';
import { useWindowWidth } from 'hooks/windowWidth';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IFormStepProps, OmitId } from 'types';
import { ICandidateAcademicEducation } from 'types/candidate/ICandidateAcademicEducation';
import { ValidationError } from 'yup';
import { AcademicEducationSubForm } from './AcademicEducationSubForm';
import { InputsContainer } from './styles';

export { validateAcademicEducationsData } from './validateAcademicEducationsData';

type AcademicEducationWithoutId = OmitId<ICandidateAcademicEducation>;

export interface AcademicEducationsStepData {
  academicEducations: AcademicEducationWithoutId[];
}

export const AcademicEducationsStep: React.FC<
  IFormStepProps<AcademicEducationsStepData>
> = ({
  initialData = {} as AcademicEducationsStepData,
  setStepDataFunction,
  errors,
}) => {
  const { academicEducations: initialAcademicEducations } = initialData;
  const { isMobileScreen } = useWindowWidth();

  const [academicEducations, setAcademicEducations] = useState<
    AcademicEducationWithoutId[]
  >(initialAcademicEducations ?? [{} as AcademicEducationWithoutId]);

  const academicEducationsErrors = useMemo(() => {
    if (!errors) return [];
    if (!(errors instanceof ValidationError)) return [];

    return convertParsedValidationErrorsToArrayOfErrors(
      getValidationErrors(errors),
    );
  }, [errors]);

  useEffect(() => {
    if (setStepDataFunction) {
      setStepDataFunction({
        academicEducations,
      });
    }
  }, [academicEducations, setStepDataFunction]);

  const handleCreateAcademicEducation = useCallback(() => {
    setAcademicEducations(previousAcademicEducations => [
      ...previousAcademicEducations,
      {} as AcademicEducationWithoutId,
    ]);
  }, []);

  const handleUpdateAcademicEducation = useCallback(
    (updatedAcademicEducation: AcademicEducationWithoutId, index: number) => {
      setAcademicEducations(previousAcademicEducations =>
        previousAcademicEducations.map((previousAcademicEducation, ind) =>
          ind === index ? updatedAcademicEducation : previousAcademicEducation,
        ),
      );
    },
    [],
  );

  const handleDeleteAcademicEducation = useCallback(
    professionalExperienceIndex => {
      setAcademicEducations(previousAcademicEducations =>
        previousAcademicEducations.filter(
          (_, index) => index !== professionalExperienceIndex,
        ),
      );
    },
    [],
  );

  const AcademicEducationsComponents = useMemo(() => {
    return academicEducations.map((professionalExperience, index) => (
      <AcademicEducationSubForm
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        index={index}
        handleDeleteSubFormData={() => handleDeleteAcademicEducation(index)}
        handleUpdateSubFormData={updatedAcademicEducation =>
          handleUpdateAcademicEducation(updatedAcademicEducation, index)
        }
        initialData={professionalExperience}
        errors={academicEducationsErrors[index]}
      />
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    academicEducations.length,
    academicEducationsErrors,
    handleDeleteAcademicEducation,
    handleUpdateAcademicEducation,
  ]);

  return (
    <FormWithStepsContainer>
      <TitleContainer>
        <StepTitle>Formação acadêmica</StepTitle>
        <StepSubTitle>
          Por mais que seja simples, é interessante compartilhar essas
          informações para que o recrutador entenda o seu nível acadêmico.
        </StepSubTitle>
      </TitleContainer>

      <InputsContainer>
        {AcademicEducationsComponents.map(
          ProfessionalExperienceComponent => ProfessionalExperienceComponent,
        )}

        <PlusButton
          colorStyle="outline"
          onClick={handleCreateAcademicEducation}
          style={{
            marginTop: 12,
            paddingInline: 18,
            width: isMobileScreen ? '90vw' : 'auto',
          }}
        >
          Adicionar formação acadêmica
        </PlusButton>
      </InputsContainer>
    </FormWithStepsContainer>
  );
};
