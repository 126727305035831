import { TestInitialScreen } from '../../TestInitialScreen';

export const LogicalReasoningInitialScreen: React.FC = () => (
  <TestInitialScreen
    title="Teste de Raciocínio Lógico"
    mainText="O Teste de Raciocínio Lógico visa avaliar o seu potencial na resolução
de problemas que envolvem raciocínio lógico e quantitativo."
    instructions={[
      'O teste é composto por 12 perguntas. Para cada uma delas você deve marcar corretamente apenas uma opção.',
      '30 minutos. Faça o teste sem interrupções ou pausas.',
      'Não é possível refazer esse teste depois de concluído. O resultado desta avaliação será disponibilizado para as empresas quando você se candidatar para uma vaga.',
    ]}
  />
);
