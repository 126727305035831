import { useCallback } from 'react';
import { GetCriteriaComponentProps } from 'types/criteria';
import {
  Container,
  PunctuationInput,
  PunctuationContainer,
  TestContainer,
  TestsContainer,
} from './styles';

interface TestCriteriaType {
  minimumPortuguesePunctuation: number;
  minimumLogicalReasoningPunctuation: number;
}

const TESTS_PROPERTIES = {
  portuguese: {
    exhibitionName: 'Português',
    criteriaPropertyName:
      'minimumPortuguesePunctuation' as keyof TestCriteriaType,
  },
  logicalReasoning: {
    exhibitionName: 'Raciocínio Lógico',
    criteriaPropertyName:
      'minimumLogicalReasoningPunctuation' as keyof TestCriteriaType,
  },
};

export const GetTestsCriteria: React.FC<GetCriteriaComponentProps> = ({
  previousCriteria,
  handleUpdateCriteria,
  children,
}) => {
  const parsedPreviousCriteria = previousCriteria as TestCriteriaType;
  const handleUpdateTestPunctuation = useCallback(
    (
      updatedPunctuation: number,
      criteriaPropertyName: keyof TestCriteriaType,
    ) => {
      if (updatedPunctuation > 100) return;

      handleUpdateCriteria({
        ...parsedPreviousCriteria,
        [criteriaPropertyName]: updatedPunctuation,
      });
    },
    [handleUpdateCriteria, parsedPreviousCriteria],
  );

  return (
    <Container>
      <p className="placeholder">{children}</p>

      <TestsContainer>
        {Object.values(TESTS_PROPERTIES).map(
          ({ exhibitionName, criteriaPropertyName }) => (
            <TestContainer key={exhibitionName}>
              <span>Teste de {exhibitionName}</span>

              <PunctuationContainer>
                <span>Pontuação mínima:</span>
                <PunctuationInput
                  placeholder="0"
                  max={100}
                  type="number"
                  value={
                    parsedPreviousCriteria[criteriaPropertyName] === 0
                      ? ''
                      : parsedPreviousCriteria[criteriaPropertyName]
                  }
                  onChange={({ target: { value } }) =>
                    handleUpdateTestPunctuation(
                      Number(value),
                      criteriaPropertyName,
                    )
                  }
                />
              </PunctuationContainer>
            </TestContainer>
          ),
        )}
      </TestsContainer>
    </Container>
  );
};
