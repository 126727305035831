import { ICandidateBasicData } from 'types/candidate';
import { ICandidateRequest } from 'types/candidate/ICandidateRequest';
import { ICandidateResponse } from 'types/candidate/ICandidateResponse';
import { api } from '../api';

const servicesPrefix = 'candidate';

export const createCandidate = async (
  createCandidateData: ICandidateRequest,
): Promise<ICandidateBasicData> => {
  const { data } = await api.post<ICandidateBasicData>(
    `${servicesPrefix}`,
    createCandidateData,
  );

  return data;
};

export const findOneCandidateById = async (
  candidateId: number,
): Promise<ICandidateResponse | undefined> => {
  const { data } = await api.get<ICandidateResponse | undefined>(
    `${servicesPrefix}/findOneById/${candidateId}`,
  );

  return data;
};

export const findOneCandidateByUserId = async (
  userId: number,
): Promise<Omit<ICandidateResponse, 'user'> | undefined> => {
  const { data } = await api.get<Omit<ICandidateResponse, 'user'> | undefined>(
    `${servicesPrefix}/findOneByUserId/${userId}`,
  );

  return data;
};

export const updateCandidate = async (
  updatedCandidate: ICandidateRequest,
): Promise<ICandidateResponse> => {
  const { data } = await api.put<ICandidateResponse>(
    `${servicesPrefix}`,
    updatedCandidate,
  );

  return data;
};

export const deleteCandidate = async (): Promise<void> => {
  await api.delete(`${servicesPrefix}`);
};
