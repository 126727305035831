import * as yup from 'yup';

import { SecondaryButton } from 'components/Buttons';
import { useCallback, useState } from 'react';
import { Switch } from 'components/Switch';
import { yupRequiredStringField } from 'utils/validation';
import { generateFormObjectFromStates, getInputStateValue } from 'utils';
import { Input } from 'components/Input';
import { useAuth } from 'hooks/auth';
import { IUpdateUserRequest } from 'types/user';
import { Loader } from 'components/Loader';
import { useInputStates } from 'utils/customHooks';
import {
  ERROR_MODAL_CONFIGURATION,
  SUCCESS_MODAL_CONFIGURATION,
  useAlertModal,
} from 'hooks/alertModal';
import { useFormError } from 'errors/useFormError';
import {
  MainContainer,
  Header,
  TitleContainer,
  ConfigurationSection,
  ContentContainer,
} from './styles';

const ACCOUNT_CONFIGURATION_INPUT_STYLES = {
  width: '100%',
  height: 48,
  fontSize: 16,
};

export const AccountConfigurationTab: React.FC = () => {
  const { user, updateUserData, updateUserWantsEmailNotifications, signOut } =
    useAuth();
  const { showModal } = useAlertModal();
  const { handleFormError } = useFormError();

  const [isLoadingDataSave, setIsLoadingDataSave] = useState(false);
  const nameStates = useInputStates('name', user?.name);
  const lastNameStates = useInputStates('lastName', user?.lastName);

  const newEmailStates = useInputStates('newEmail');
  const passwordStates = useInputStates('password');
  const newPasswordStates = useInputStates('newPassword');
  const confirmNewPasswordStates = useInputStates('confirmNewPassword');

  const [wantsEmailNotifications, setWantsEmailNotifications] = useState(
    user?.wantsEmailNotifications ?? true,
  );
  const [
    isLoadingWantsEmailNotifications,
    setIsLoadingWantsEmailNotifications,
  ] = useState(false);

  const handleUpdateWantsEmailNotifications = useCallback(
    async (updatedWantsEmailNotifications: boolean) => {
      setIsLoadingWantsEmailNotifications(true);

      try {
        await updateUserWantsEmailNotifications(updatedWantsEmailNotifications);
        setWantsEmailNotifications(updatedWantsEmailNotifications);
      } catch {
        showModal({
          ...ERROR_MODAL_CONFIGURATION,
          message:
            'Erro ao salvar configurações, por favor, tente novamente mais tarde',
        });
      } finally {
        setIsLoadingWantsEmailNotifications(false);
      }
    },
    [showModal, updateUserWantsEmailNotifications],
  );

  const handleOnClickSaveUpdatedName = useCallback(async () => {
    setIsLoadingDataSave(true);
    const formStates = [nameStates, lastNameStates];
    const formObject = generateFormObjectFromStates(formStates);

    const schema = yup.object().shape({
      name: yupRequiredStringField,
      lastName: yupRequiredStringField,
    });

    try {
      await schema.validate(formObject, { abortEarly: false });
      await updateUserData(formObject);

      showModal({
        ...SUCCESS_MODAL_CONFIGURATION,
        message: 'Nome atualizado com sucesso',
      });
    } catch (error) {
      handleFormError(error as Error | yup.ValidationError, formStates);
    } finally {
      setIsLoadingDataSave(false);
    }
  }, [nameStates, lastNameStates, updateUserData, showModal, handleFormError]);

  const handleSaveUserData = useCallback(
    async (updatedUser: IUpdateUserRequest) => {
      try {
        setIsLoadingDataSave(true);
        await updateUserData(updatedUser);

        showModal({
          message: 'Será necessário fazer login novamente',
        });
        signOut();
      } catch {
        setIsLoadingDataSave(false);
        showModal({
          ...ERROR_MODAL_CONFIGURATION,
          message:
            'Erro ao salvar configurações, por favor, tente novamente mais tarde',
        });
      }
    },
    [showModal, signOut, updateUserData],
  );

  const handleOnClickSaveEmail = useCallback(async () => {
    const schema = yup.object().shape({
      newEmail: yupRequiredStringField
        .email('Insira um email válido')
        .notOneOf([user?.email], 'O novo email não pode ser igual ao atual'),
    });

    try {
      const newEmail = getInputStateValue(newEmailStates);
      await schema.validate({ newEmail }, { abortEarly: false });

      handleSaveUserData({ email: newEmail });
    } catch (error) {
      handleFormError(error as Error | yup.ValidationError, [newEmailStates]);
    }
  }, [handleFormError, handleSaveUserData, newEmailStates, user]);

  const handleOnClickSaveSecurity = useCallback(async () => {
    const formStates = [
      passwordStates,
      newPasswordStates,
      confirmNewPasswordStates,
    ];

    const formObject = generateFormObjectFromStates(formStates);
    const schema = yup.object().shape({
      password: yupRequiredStringField,
      newPassword: yupRequiredStringField
        .min(8, 'A senha precisa ter no mínimo 8 caracteres')
        .notOneOf(
          [yup.ref('password')],
          'A nova senha não pode ser igual à atual',
        ),
      confirmNewPassword: yupRequiredStringField.oneOf(
        [yup.ref('newPassword')],
        'As senhas inseridas não conferem',
      ),
    });

    try {
      await schema.validate(formObject, { abortEarly: false });
      handleSaveUserData(formObject);
    } catch (error) {
      formStates.forEach(({ mainState: { setFunction } }) => setFunction(''));
      if ((error as Error).message.includes('403')) {
        passwordStates.errorMessageState.setFunction('Senha incorreta');
        return;
      }

      handleFormError(error as Error | yup.ValidationError, formStates);
    }
  }, [
    passwordStates,
    newPasswordStates,
    confirmNewPasswordStates,
    handleSaveUserData,
    handleFormError,
  ]);

  return (
    <MainContainer>
      <Header>Configurações</Header>

      {isLoadingDataSave ? (
        <Loader />
      ) : (
        <>
          <ConfigurationSection>
            <TitleContainer>Nome de usuário</TitleContainer>

            <ContentContainer>
              <Input
                name="Nome atualizado"
                states={nameStates}
                placeholder="Digite o nome atualizado"
                autoComplete="off"
                style={ACCOUNT_CONFIGURATION_INPUT_STYLES}
              />

              <Input
                name="Sobrenome atualizado"
                states={lastNameStates}
                placeholder="Digite o sobrenome atualizado"
                autoComplete="off"
                style={ACCOUNT_CONFIGURATION_INPUT_STYLES}
              />

              <SecondaryButton
                type="submit"
                onClick={handleOnClickSaveUpdatedName}
              >
                Salvar
              </SecondaryButton>
            </ContentContainer>
          </ConfigurationSection>

          <ConfigurationSection>
            <TitleContainer>Email</TitleContainer>

            <ContentContainer>
              <p>Seu endereço de email atual é:</p>
              <p className="email">{user?.email}</p>

              <Input
                name="Novo endereço de email"
                states={newEmailStates}
                type="email"
                placeholder="Digite um novo endereço de email"
                autoComplete="off"
                style={ACCOUNT_CONFIGURATION_INPUT_STYLES}
              />

              <SecondaryButton type="submit" onClick={handleOnClickSaveEmail}>
                Salvar
              </SecondaryButton>
            </ContentContainer>
          </ConfigurationSection>

          <ConfigurationSection>
            <TitleContainer>Senha de acesso</TitleContainer>

            <ContentContainer>
              <Input
                name="Senha atual"
                states={passwordStates}
                placeholder="Digite a senha atual"
                type="password"
                style={ACCOUNT_CONFIGURATION_INPUT_STYLES}
              />

              <Input
                name="Nova senha"
                states={newPasswordStates}
                placeholder="Digite a nova senha"
                type="password"
                style={ACCOUNT_CONFIGURATION_INPUT_STYLES}
              />

              <Input
                name="Confirmação da nova senha"
                states={confirmNewPasswordStates}
                placeholder="Digite a confirmação da senha"
                type="password"
                style={ACCOUNT_CONFIGURATION_INPUT_STYLES}
              />

              <SecondaryButton
                type="submit"
                onClick={handleOnClickSaveSecurity}
              >
                Salvar
              </SecondaryButton>
            </ContentContainer>
          </ConfigurationSection>

          <ConfigurationSection>
            <TitleContainer>Notificações</TitleContainer>

            <ContentContainer className="notifications-container">
              <Switch
                name="Receber notificações no email"
                state={[wantsEmailNotifications, setWantsEmailNotifications]}
                disabled={isLoadingWantsEmailNotifications}
                onChange={handleUpdateWantsEmailNotifications}
              />
            </ContentContainer>
          </ConfigurationSection>
        </>
      )}
    </MainContainer>
  );
};
