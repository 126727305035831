import { HTMLAttributes } from 'react';
import { useHistory } from 'react-router-dom';
import { MainContainer } from './styles';

interface HeaderProps extends HTMLAttributes<HTMLElement> {
  logo: string;
  redirectRoute: string;
}

export const Header: React.FC<HeaderProps> = ({
  logo,
  redirectRoute,
  ...props
}) => {
  const history = useHistory();

  return (
    <MainContainer {...props}>
      <div className="subContainer">
        <button type="button" onClick={() => history.push(redirectRoute)}>
          <img src={logo} alt="Logo IN6" />
        </button>
      </div>
    </MainContainer>
  );
};
