import redirectIcon from 'assets/img/redirectIcon.svg';
import shareIcon from 'assets/img/shareIcon.svg';
import candidateIcon from 'assets/img/candidateIcon.svg';
import deleteIcon from 'assets/img/delete.svg';

import { convertCityAndStateFromObjectToString } from 'utils/conversion/convertCityAndStateFromObjectToString';
import { getParsedVacancySalaryInformation } from 'utils/conversion/getParsedVacancySalaryInformation';
import { getVacancyDateStatusString } from 'utils/conversion/getVacancyDateStatusString';
import {
  IVacancyDTOForMultipleVacanciesWithoutStartup,
  VacancyStatus,
} from 'types/vacancy';
import { candidatesUrlFrontend } from 'services/baseUrl';
import { useHistory } from 'react-router-dom';
import { routeVacancyDetails } from 'routes/startupsRoutes/startupsRoutesAddresses';
import { VACANCY_STATUS_COLORS } from 'utils/typeOptions';
import {
  Container,
  Vacancy,
  VacancyAspects,
  BottomContainer,
  VacancyCurrentSituation,
  SituationContainer,
} from './styles';

interface VacancyContainerProps {
  vacancy: IVacancyDTOForMultipleVacanciesWithoutStartup | undefined;
  handleDeleteVacancy: (vacancyId: number) => void;
  handleShareVacancy: (vacancyId: number) => void;
}

export const VacancyContainer: React.FC<VacancyContainerProps> = ({
  vacancy,
  handleDeleteVacancy,
  handleShareVacancy,
}) => {
  const history = useHistory();

  if (vacancy === undefined) return null;
  return (
    <Container>
      <Vacancy
        type="button"
        asideBarColor={VACANCY_STATUS_COLORS[vacancy.vacancyStatus].high}
        onClick={() =>
          history.push(routeVacancyDetails, {
            vacancyId: vacancy.id,
          })
        }
      >
        <span className="vacancy-name">{vacancy.name}</span>
        <span className="vacancy-location">
          {vacancy.vacancyLocation &&
            convertCityAndStateFromObjectToString(vacancy.vacancyLocation)}
        </span>

        <VacancyAspects>
          <p>{vacancy.jobType.type}</p>
          <p>{vacancy.workloadType.type}</p>
          <p>{getParsedVacancySalaryInformation(vacancy)}</p>
        </VacancyAspects>

        <BottomContainer>
          <VacancyCurrentSituation>
            <SituationContainer>
              {getVacancyDateStatusString(vacancy)}
            </SituationContainer>

            <SituationContainer>
              <img src={candidateIcon} alt="Candidato" />
              {vacancy.candidatesQuantity}
            </SituationContainer>
          </VacancyCurrentSituation>
        </BottomContainer>
      </Vacancy>

      {vacancy.vacancyStatus === VacancyStatus.CLOSED ? (
        <button
          type="button"
          className="action-button"
          onClick={() => handleDeleteVacancy(vacancy.id)}
        >
          <img src={deleteIcon} alt="Excluir" />
        </button>
      ) : (
        <>
          <button
            type="button"
            className="action-button"
            onClick={() => handleShareVacancy(vacancy.id)}
          >
            <img src={shareIcon} alt="Compartilhar" />
          </button>

          <a
            href={`${candidatesUrlFrontend}/${1000 + vacancy.id}`}
            target="_blank"
            rel="noreferrer"
            className="action-button"
          >
            <img src={redirectIcon} alt="Redirecionar" />
          </a>
        </>
      )}
    </Container>
  );
};
