import { LabelHTMLAttributes } from 'react';
import { Checkmark, Container, OptionInput } from './styles';

interface SearchFilterOptionProps
  extends LabelHTMLAttributes<HTMLLabelElement> {
  label: string;
  handleCheckboxUpdate: (checked: boolean) => void;
  defaultChecked?: boolean;
  checked?: boolean;
  disabled?: boolean;
}

export const CheckboxInput: React.FC<SearchFilterOptionProps> = ({
  label,
  handleCheckboxUpdate,
  defaultChecked,
  checked,
  disabled,
  ...props
}) => {
  return (
    <Container {...props} disabled={disabled}>
      {label}
      <OptionInput
        checked={checked}
        disabled={disabled}
        defaultChecked={defaultChecked}
        onChange={({ target: { checked: updatedChecked } }) => {
          handleCheckboxUpdate(updatedChecked);
        }}
        type="checkbox"
      />
      <Checkmark />
    </Container>
  );
};
