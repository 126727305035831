import loginIcon from 'assets/img/login.svg';

import { AppLogo } from 'components/AppLogo';
import { PrimaryButton, SecondaryButton } from 'components/Buttons';
import { useWindowWidth } from 'hooks/windowWidth';
import { useHistory } from 'react-router-dom';
import { routeLogin, routeSignUp } from 'routes/routesAddresses';
import { MobileOptionsButton } from './MobileOptionsButton';
import { HeaderMainContainer, HeaderSubContainer } from './styles';

export const CandidatesFreeRoutesHeader: React.FC = () => {
  const history = useHistory();
  const { isMobileScreen } = useWindowWidth();

  return (
    <HeaderMainContainer>
      <HeaderSubContainer>
        <AppLogo logoType="candidatos" />

        {isMobileScreen ? (
          <MobileOptionsButton />
        ) : (
          <div className="buttons-container">
            <PrimaryButton
              type="button"
              onClick={() =>
                history.push(routeSignUp, {
                  candidateAccountTypeSelected: true,
                })
              }
            >
              Cadastrar seu currículo
            </PrimaryButton>

            <SecondaryButton
              type="button"
              onClick={() => history.push(routeLogin)}
            >
              Entrar
              <img src={loginIcon} alt="Fazer Login" />
            </SecondaryButton>
          </div>
        )}
      </HeaderSubContainer>
    </HeaderMainContainer>
  );
};
