import { TextGradientPrimaryBackground } from 'assets/colors/background';
import {
  BackgroundColor,
  HighEmphasis,
  MediumEmphasis,
  OutlineColor,
  PrimaryColor,
} from 'assets/colors/palette';
import {
  Medium14Styles,
  Medium16Styles,
  Regular14Styles,
  Regular24Styles,
} from 'assets/fonts/fontStyles';
import { PrimaryButton, SecondaryButton } from 'components/Buttons';
import styled from 'styled-components';

interface ContainerMobileProps {
  isVisibleOnMobile: boolean;
}

export const Container = styled.div`
  display: flex;
  justify-content: space-between;

  width: 1248px;
  margin-inline: auto;

  @media (max-width: 1100px) {
    width: 90vw;
  }
`;

export const CandidatedVacancies = styled.div`
  border-right: 1px solid ${OutlineColor};
  min-width: 789px;
  width: 789px;

  @media (max-width: 1100px) {
    min-width: 100%;
    width: 100%;
    border-right: none;
  }
`;

export const MobileVacancyHighlightContainer = styled.div<ContainerMobileProps>`
  display: none;

  @media (max-width: 1100px) {
    position: fixed;
    display: block;
    overflow-y: scroll;
    max-height: calc(100vh - 64px);

    transition: 0.4s;
    left: 0;
    bottom: ${({ isVisibleOnMobile }) =>
      isVisibleOnMobile ? '-10%' : '-100%'};

    background-color: ${BackgroundColor};
    width: 100vw;
    z-index: 1;
    padding-inline: 5vw;
    padding-bottom: 90px;

    border-radius: 8px 8px 0px 0px;
    filter: drop-shadow(1.2px 0px 3.6px rgba(0, 0, 0, 0.1))
      drop-shadow(6.4px 0px 14.4px rgba(0, 0, 0, 0.1));

    &::-webkit-scrollbar {
      display: none;
    }

    ${SecondaryButton} {
      margin-top: 14px;
    }
  }
`;

export const CandidateResumeContainer = styled.div<ContainerMobileProps>`
  overflow-y: scroll;
  height: calc(100vh - 64px);
  max-height: calc(100vh - 64px);

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(217, 217, 217, 0.2);
  }

  &::-webkit-scrollbar-thumb {
    background: ${MediumEmphasis};
    border-radius: 100px;
  }

  ${SecondaryButton} {
    margin-bottom: 30px;
    width: 283px;
    height: 48px;
    padding: 16px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    img {
      filter: invert(44%) sepia(87%) saturate(4873%) hue-rotate(4deg)
        brightness(103%) contrast(108%);
    }
  }

  @media (max-width: 1100px) {
    position: fixed;
    transition: 0.4s;
    left: 0;
    bottom: ${({ isVisibleOnMobile }) =>
      isVisibleOnMobile ? '-10%' : '-100%'};

    background-color: ${BackgroundColor};
    width: 100vw;
    z-index: 1;
    padding-inline: 5vw;
    padding-bottom: 90px;

    border-radius: 8px 8px 0px 0px;
    filter: drop-shadow(1.2px 0px 3.6px rgba(0, 0, 0, 0.1))
      drop-shadow(6.4px 0px 14.4px rgba(0, 0, 0, 0.1));

    &::-webkit-scrollbar {
      display: none;
    }

    ${SecondaryButton} {
      margin-top: 14px;
    }
  }
`;

export const MobileResumeHeader = styled.div`
  display: none;

  @media (max-width: 1100px) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    position: sticky;
    z-index: 1;
    top: 0;

    height: 60px;
    background-color: ${BackgroundColor};

    span {
      ${Medium16Styles}
      color: ${HighEmphasis};
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
    }

    &::after {
      content: '';
      position: absolute;
      width: 100vw;
      height: 1px;

      background-color: ${OutlineColor};
      left: -5vw;
      bottom: 0;
      z-index: 1;
    }
  }
`;

export const Header = styled.header`
  width: 100%;
  height: 128px;

  display: flex;
  flex-direction: column;
  padding-block: 37px;
  gap: 16px;

  h3 {
    ${Regular24Styles}
    color: ${HighEmphasis};
  }

  span {
    ${Regular14Styles}
    color: ${MediumEmphasis};
  }

  @media (max-width: 1100px) {
    h3 {
      font-size: 18px;
      line-height: 18px;
    }

    span {
      font-size: 12px;
    }
  }
`;

export const OpenResumeButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${BackgroundColor};

  padding: 8px 24px;
  width: 182px;
  height: 48px;

  border: 1px solid ${OutlineColor};
  border-radius: 100px;
  transition: 0.2s;

  &:focus {
    border-color: ${PrimaryColor};
  }

  span {
    ${Medium14Styles}
    line-height: 24px;
    color: ${HighEmphasis};
  }
`;

export const MobileButtons = styled.div`
  display: none;

  @media (max-width: 1100px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;

    ${PrimaryButton} {
      width: 72px;
      height: 48px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const Main = styled.main`
  width: 696px;
  padding-block: 4.1% 12%;

  @media (max-width: 1100px) {
    width: 90vw;
    padding-block: 20% 12%;
  }
`;

export const VacanciesContainer = styled.div`
  display: flex;
  align-items: center;
  flex-flow: wrap;

  margin-top: 64px;
  gap: 48px;

  @media (max-width: 1100px) {
    margin-top: 48px;
    gap: 32px;
  }
`;

export const NoVacanciesContainer = styled.div`
  width: 100%;
  padding-top: 12%;
  padding-right: 16%;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  p {
    ${Regular14Styles}
    line-height: 28px;
    width: 450px;
    text-align: center;
    margin-bottom: 24px;
  }

  ${PrimaryButton} {
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 8px;
    height: 48px;
  }

  @media (max-width: 1100px) {
    width: 90vw;
    padding-right: 0;
    padding-top: 7vh;
    gap: 16px;

    p {
      width: 100%;
      font-size: 12px;
      margin-bottom: 16px;
    }
  }
`;

export const WelcomeMessage = styled.div`
  ${TextGradientPrimaryBackground}
  ${Medium16Styles}

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  height: 48px;
  padding: 16px 32px;
  border: 1px solid ${OutlineColor};
  border-radius: 100px;

  @media (max-width: 1100px) {
    font-size: 14px;
    line-height: 20px;
    height: 72px;
  }
`;
