import { OptionButton } from 'components/Buttons';
import { Loader } from 'components/Loader';
import { Container } from './styles';

interface DeleteModalProps {
  isLoadingDelete: boolean;
  modalTitle: string;
  deleteButtonText: string;
  hideDeleteModal: () => void;
  handleDelete: () => Promise<void>;
}

export const DeleteModal: React.FC<DeleteModalProps> = ({
  isLoadingDelete,
  modalTitle,
  deleteButtonText,
  hideDeleteModal,
  handleDelete,
}) => {
  return (
    <Container>
      {isLoadingDelete ? (
        <Loader style={{ background: 'transparent', marginTop: 70 }} />
      ) : (
        <>
          <p>{modalTitle}</p>

          <OptionButton type="button" onClick={handleDelete} isDeleteButton>
            {deleteButtonText}
          </OptionButton>
          <OptionButton type="button" onClick={hideDeleteModal}>
            Cancelar
          </OptionButton>
        </>
      )}
    </Container>
  );
};
