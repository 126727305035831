import { formStepInputStyles } from 'assets/styles/formWithStepsStyles/formWithStepsInputStyles';
import { Input } from 'components/Input';
import { CheckboxInput } from 'components/Input/CheckboxInput';
import { TextAreaInput } from 'components/Input/TextAreaInput';
import { Select } from 'components/Select';
import { useCallback, useEffect, useState } from 'react';
import { EntityWithIdDTO } from 'types';
import { IFormStepProps } from 'types/IFormStepProps';
import { getInputStateValue } from 'utils';
import { formatNumberToBRCurrency } from 'utils/conversion';
import { paymentTypeOptions } from 'utils/typeOptions';
import {
  StepSubTitle,
  StepTitle,
  TitleContainer,
} from 'assets/styles/formWithStepsStyles/formWithStepsTitlesStyles';
import { FormWithStepsContainer } from 'assets/styles/formWithStepsStyles/formWithStepsContainerStyles';
import { useInputStates } from 'utils/customHooks';
import { useFormError } from 'errors/useFormError';
import { InputsContainer, BaseSalaryInputsContainer } from './styles';

export { validateRemunerationData } from './validateRemunerationData';

export interface RemunerationStepData {
  paymentType: EntityWithIdDTO;
  salaryToNegotiate: boolean;
  salary?: string;
  benefits?: string;
}

export const RemunerationStep: React.FC<
  IFormStepProps<RemunerationStepData>
> = ({
  initialData = {} as RemunerationStepData,
  setStepDataFunction,
  errors,
}) => {
  const {
    salary,
    salaryToNegotiate: initialSalaryToNegotiate,
    paymentType,
    benefits,
  } = initialData;
  const { handleFormError } = useFormError();

  const salaryStates = useInputStates('salary', salary ?? '');
  const paymentTypeStates = useInputStates('paymentType', paymentType?.id);
  const benefitsStates = useInputStates('benefits', benefits);

  const [salaryToNegotiate, setSalaryToNegotiate] = useState(
    !!initialSalaryToNegotiate,
  );

  const getUpdatedStepData = useCallback(() => {
    if (salaryStates.errorMessageState.value && salaryToNegotiate) {
      salaryStates.errorMessageState.setFunction('');
    }

    const parsedPaymentType = salaryToNegotiate
      ? null
      : { id: Number(getInputStateValue(paymentTypeStates)) };

    const updatedStatesObject = {
      salary: salaryToNegotiate ? null : getInputStateValue(salaryStates),
      paymentType: parsedPaymentType,
      benefits: getInputStateValue(benefitsStates),
      salaryToNegotiate,
    };

    return updatedStatesObject as RemunerationStepData;
  }, [salaryStates, benefitsStates, salaryToNegotiate, paymentTypeStates]);

  useEffect(() => {
    if (!errors) return;

    const stepStates = [salaryStates, paymentTypeStates, benefitsStates];
    handleFormError(errors, stepStates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    if (!setStepDataFunction) return;

    const updatedData = getUpdatedStepData();
    setStepDataFunction(updatedData);
  }, [getUpdatedStepData, setStepDataFunction]);

  return (
    <FormWithStepsContainer>
      <TitleContainer>
        <StepTitle>Remuneração</StepTitle>
        <StepSubTitle>
          Especifique o tipo de carga horária e os horários de dedicação para
          tornar sua vaga mais atrativa aos candidatos
        </StepSubTitle>
      </TitleContainer>

      <InputsContainer>
        <CheckboxInput
          style={{ fontSize: 16 }}
          label="Salário a combinar"
          defaultChecked={salaryToNegotiate}
          handleCheckboxUpdate={checked => setSalaryToNegotiate(checked)}
        />

        <BaseSalaryInputsContainer>
          <Input
            name="Salário base"
            disabled={salaryToNegotiate}
            placeholder="1.000,00"
            states={salaryStates}
            style={{ ...formStepInputStyles, width: 274 }}
            maxLength={18}
            onBlur={() =>
              salaryStates.mainState.setFunction(
                formatNumberToBRCurrency(
                  salaryStates.mainState.value as string,
                ),
              )
            }
          />

          <Select
            disabled={salaryToNegotiate}
            loadingIndicatorDisabled
            states={paymentTypeStates}
            selectOptions={paymentTypeOptions}
            style={{ ...formStepInputStyles, width: 202 }}
          />
        </BaseSalaryInputsContainer>

        <TextAreaInput
          name="Benefícios para os funcionários"
          placeholder="por exemplo, vale transporte ou alimentação"
          isOptional
          states={benefitsStates}
          style={formStepInputStyles}
          descriptionText="Adicione uma descrição de até 1000 caracteres"
          charactersLimitLength={1000}
        />
      </InputsContainer>
    </FormWithStepsContainer>
  );
};
