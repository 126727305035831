import closeIcon from 'assets/img/close.svg';
import { formStepInputStyles } from 'assets/styles/formWithStepsStyles/formWithStepsInputStyles';

import { PlusButton } from 'components/Buttons';
import { Input } from 'components/Input';
import { useCallback, useEffect, useState } from 'react';
import { IFormStepProps } from 'types/IFormStepProps';
import {
  StepSubTitle,
  StepTitle,
  TitleContainer,
} from 'assets/styles/formWithStepsStyles/formWithStepsTitlesStyles';
import { FormWithStepsContainer } from 'assets/styles/formWithStepsStyles/formWithStepsContainerStyles';
import { useInputStates } from 'utils/customHooks';
import { EntityWithNameDTO } from 'types/EntityWithNameDTO';
import { OmitId } from 'types';
import { stringsAreEqual } from 'utils';
import { InputsContainer, CompetenciesContainer, Competency } from './styles';

export { validateCompetenciesData } from './validateCompetenciesData';

export interface CompetenciesStepData {
  competencies: OmitId<EntityWithNameDTO>[];
}

export const CompetenciesStep: React.FC<
  IFormStepProps<CompetenciesStepData>
> = ({ initialData = {} as CompetenciesStepData, setStepDataFunction }) => {
  const { competencies: initialCompetencies } = initialData;

  const competencyInputStates = useInputStates('competencyInput');
  const [competencies, setCompetencies] = useState<OmitId<EntityWithNameDTO>[]>(
    initialCompetencies ?? [],
  );

  const handleAddCompetency = useCallback(() => {
    const { value, setFunction } = competencyInputStates.mainState;
    if ((value as string).length === 0) return;

    const findedCompetency = competencies.find(({ name }) =>
      stringsAreEqual(name, value as string),
    );

    if (findedCompetency) {
      competencyInputStates.errorMessageState.setFunction(
        'Competência já adicionada',
      );
    } else {
      setCompetencies(previousCompetencies => [
        ...previousCompetencies,
        {
          name: value as string,
        },
      ]);
    }

    setFunction('');
  }, [
    competencyInputStates.errorMessageState,
    competencyInputStates.mainState,
    competencies,
  ]);

  const handleExcludeCompetency = useCallback((competency: string) => {
    setCompetencies(previousCompetencies =>
      previousCompetencies.filter(
        comparativeCompetency => comparativeCompetency.name !== competency,
      ),
    );
  }, []);

  useEffect(() => {
    if (setStepDataFunction) {
      setStepDataFunction({
        competencies,
      });
    }
  }, [competencies, setStepDataFunction]);

  return (
    <FormWithStepsContainer>
      <TitleContainer>
        <StepTitle>Competências técnicas</StepTitle>
        <StepSubTitle>
          Adicione até 4 softwares ou equipamentos que os candidatos precisam
          dominar para trabalhar nesta vaga
        </StepSubTitle>
      </TitleContainer>

      <InputsContainer>
        <span>{competencies?.length ?? 0}/4 competências selecionadas</span>

        <Input
          disabled={competencies?.length === 4}
          states={competencyInputStates}
          placeholder="por exemplo, Power BI"
          style={formStepInputStyles}
        />

        <PlusButton
          onClick={handleAddCompetency}
          disabled={
            !competencyInputStates.mainState.value || competencies?.length === 4
          }
          colorStyle="outline"
        >
          Adicionar
        </PlusButton>
      </InputsContainer>

      <CompetenciesContainer>
        {competencies.map(({ name }) => (
          <Competency key={name}>
            <span>{name}</span>

            <button type="button" onClick={() => handleExcludeCompetency(name)}>
              <img src={closeIcon} alt="Excluir competência" />
            </button>
          </Competency>
        ))}
      </CompetenciesContainer>
    </FormWithStepsContainer>
  );
};
