import searchIcon from 'assets/img/search.svg';
import idCardIcon from 'assets/img/idCard.svg';
import deleteIcon from 'assets/img/delete.svg';
import simpleArrowRightIcon from 'assets/img/simpleArrowRight.svg';
import connectIcon from 'assets/img/connect.svg';

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  findAllCandidatesByVacancyId,
  removeVacancyCandidateFromEvaluation,
  updateVacancyCandidate,
} from 'services/entitiesServices/vacancyCandidateServices';
import { CandidateResume } from 'components/CandidateResume';
import { formatDateToBrazilianString } from 'utils/conversion/formatDateToBrazilianString';
import {
  createVacancyCustomStep,
  deleteVacancyCustomStep,
  findAllCustomStepsByVacancyId,
} from 'services/entitiesServices/vacancyCustomStepServices';
import {
  STEP_COLORS,
  useVacanciesCustomSteps,
} from 'hooks/vacanciesCustomSteps';
import { IUpdateVacancyCandidateData } from 'types/vacancyCandidate/IUpdateVacancyCandidateData';
import { Loader } from 'components/Loader';
import { CandidatesTestsAverageType } from 'types/candidate';
import { ERROR_MODAL_CONFIGURATION, useAlertModal } from 'hooks/alertModal';
import { HighRed } from 'assets/colors/palette';
import { CandidateEvaluation } from 'types/CandidateEvaluation';
import {
  createStartupCandidatesFolder,
  findAllStartupCandidatesFoldersByStartupId,
} from 'services/entitiesServices/startupCandidatesFolderServices';
import { IStartupCandidatesFolderBasicData } from 'types/startupCandidatesFolder/IStartupCandidatesFolderBasicData';
import {
  createFiledStartupCandidate,
  createFiledStartupCandidateWithCandidateDirectly,
} from 'services/entitiesServices/filedStartupCandidateServices';
import {
  CandidateMeetsResearchFiltersType,
  DEFAULT_CANDIDATE_MEETS_RESEARCH_FILTERS,
  OpenFilterOptionsButton,
} from 'pages/Dashboards/StartupsDashboard/StartupCandidatesSearchComponents/OpenFilterOptionsButton';
import { SearchedCandidate } from 'pages/Dashboards/StartupsDashboard/StartupCandidatesSearchComponents/SearchedCandidate';
import { CandidateEvaluationContainer } from 'pages/Dashboards/StartupsDashboard/StartupCandidatesSearchComponents/CandidateEvaluationContainer';
import { OptionsColumnData } from 'pages/Dashboards/StartupsDashboard/Tabs/VacancyDetailsTab/VacancyCandidatesManagement/OptionsColumnData';
import { IVacancyCandidateWithCandidateData } from 'types/vacancyCandidate';
import { useStartup } from 'hooks/startup';
import {
  calculateCandidatesTestsAverage,
  calculateVacancyCandidateConnectionPunctuation,
  getCandidateLastUpdatedAt,
} from 'utils';
import { candidateMeetsSearchText } from 'utils/candidateMeetsSearchText';
import { Modal } from 'components/Modal';
import { IVacancyResponse } from 'types/vacancy';
import { CategoriesCriteria } from 'types/criteria';
import { CandidateStepColumnData } from './CandidateStepColumnData';
import {
  CandidatesSearch,
  SearchContainer,
  SearchBar,
  ColumnNamesContainer,
  SelectedCandidate,
  CandidatesTable,
  NoSelectedCandidateContainer,
  CandidateResumeLastUpdateDate,
  NoCandidatesContainer,
  CandidateStepSearchContainer,
  SelectStepButton,
  SearchedCandidatesContainer,
  ScrollStepsOptionsButton,
  StepSelectionContainer,
  SelectedCandidateTabs,
  CandidateTabButton,
  ColumnButton,
  CandidateConnection,
  ConnectionButton,
} from './styles';
import { SelectedCandidateTests } from '../../../SelectedCandidateTests';
import { DefineCriteriaModal } from './DefineCriteriaModal';

interface VacancyCandidatesManagementProps {
  vacancy: IVacancyResponse;
}

interface CustomStepOption {
  id: number;
  name: string;
  candidatesQuantity: number;
}

interface ISearchedCandidate {
  index: number;
  disregardingCustomStepOption: boolean;
}

type StepSearchContainerScrollPoint = 'start' | 'middle' | 'end';

export const VacancyCandidatesManagement: React.FC<
  VacancyCandidatesManagementProps
> = ({ vacancy }) => {
  const { showModal } = useAlertModal();
  const { startup } = useStartup();
  const {
    saveCustomStepsOfVacancy,
    deleteCustomStepOfVacancy,
    vacanciesCustomSteps,
  } = useVacanciesCustomSteps();

  const searchInputRef = useRef<HTMLInputElement>(null);
  const stepSelectionContainerRef = useRef<HTMLDivElement>(null);

  const [stepSearchContainerScrollPoint, setStepSearchContainerScrollPoint] =
    useState<StepSearchContainerScrollPoint | null>(null);

  const [searchedText, setSearchedText] = useState('');
  const [candidateMeetsResearchFilters, setCandidateMeetsResearchFilters] =
    useState<CandidateMeetsResearchFiltersType>(
      () => DEFAULT_CANDIDATE_MEETS_RESEARCH_FILTERS,
    );

  const [isDefineCriteriaModalVisible, setIsDefineCriteriaModalVisible] =
    useState(false);

  const [isLoadingVacancySteps, setIsLoadingVacancySteps] = useState(true);
  const [isLoadingVacancyCandidates, setIsLoadingVacancyCandidates] =
    useState(true);
  const [vacancyCandidates, setVacancyCandidates] = useState<
    (IVacancyCandidateWithCandidateData & { connectionPunctuation: number })[]
  >([]);

  const [searchedVacancyCandidates, setSearchedVacancyCandidates] = useState<
    ISearchedCandidate[]
  >([]);
  const [selectedCandidateIndex, setSelectedCandidateIndex] = useState<
    number | null
  >(null);
  const [selectedCandidateTab, setSelectedCandidateTab] = useState<
    'resume' | 'tests'
  >('resume');

  const [customStepOptions, setCustomStepOptions] = useState<
    CustomStepOption[]
  >([]);
  const [selectedCustomStepOptionId, setSelectedCustomStepOptionId] =
    useState(0);

  const [startupFolders, setStartupFolders] = useState<
    IStartupCandidatesFolderBasicData[] | null
  >(null);

  useEffect(() => {
    findAllCustomStepsByVacancyId(vacancy.id).then(findedSteps => {
      const parsedSteps = findedSteps.map((step, index) => ({
        ...step,
        color: STEP_COLORS[index],
      }));

      saveCustomStepsOfVacancy({
        vacancyId: vacancy.id,
        customSteps: parsedSteps,
      });
      setIsLoadingVacancySteps(false);
    });
  }, [saveCustomStepsOfVacancy, vacancy.id]);

  const vacancyCustomSteps = useMemo(() => {
    const findedVacancyCustomSteps = vacanciesCustomSteps.find(
      ({ vacancyId: customStepsVacancyId }) =>
        customStepsVacancyId === vacancy.id,
    );

    if (findedVacancyCustomSteps) return findedVacancyCustomSteps.customSteps;
    return [];
  }, [vacanciesCustomSteps, vacancy.id]);

  useEffect(() => {
    const updatedCustomStepOptions: CustomStepOption[] = [
      {
        id: 0,
        name: 'Inscritos',
        candidatesQuantity: searchedVacancyCandidates.length,
      },
    ];

    vacancyCustomSteps.forEach(({ id, name }) =>
      updatedCustomStepOptions.push({ id, name, candidatesQuantity: 0 }),
    );

    searchedVacancyCandidates.forEach(({ index }) => {
      const { candidateStep } = vacancyCandidates[index] ?? {};
      if (!candidateStep) return;

      const stepPositionOnOptions = updatedCustomStepOptions.findIndex(
        ({ id }) => id === candidateStep.id,
      );
      updatedCustomStepOptions[stepPositionOnOptions].candidatesQuantity++;
    });

    setCustomStepOptions(updatedCustomStepOptions);
  }, [
    searchedVacancyCandidates,
    vacancyCandidates,
    vacancyCustomSteps,
    vacanciesCustomSteps,
  ]);

  useEffect(() => {
    findAllCandidatesByVacancyId(vacancy.id).then(findedCandidates => {
      setVacancyCandidates(
        findedCandidates.map(candidate => ({
          ...candidate,
          connectionPunctuation: 0,
        })),
      );

      setSearchedVacancyCandidates(
        Array(findedCandidates.length)
          .fill(undefined)
          .map((_, index) => ({ index, disregardingCustomStepOption: true })),
      );
      setIsLoadingVacancyCandidates(false);
    });
  }, [vacancy.id]);

  useEffect(() => {
    if (startup === undefined) return;

    findAllStartupCandidatesFoldersByStartupId(startup.id).then(
      findedStartupFolders => setStartupFolders(findedStartupFolders),
    );
  }, [startup]);

  const selectedCandidateLastUpdatedAt = useMemo(
    () =>
      getCandidateLastUpdatedAt(
        selectedCandidateIndex === null
          ? undefined
          : vacancyCandidates[selectedCandidateIndex]?.candidate,
      ),
    [selectedCandidateIndex, vacancyCandidates],
  );

  const candidatesTestsAverage: CandidatesTestsAverageType = useMemo(
    () => calculateCandidatesTestsAverage(vacancyCandidates),
    [vacancyCandidates],
  );

  const candidateMeetsSearchedCustomStep = useCallback(
    ({ candidateStep }: IVacancyCandidateWithCandidateData) => {
      if (selectedCustomStepOptionId === 0) return true;
      if (candidateStep === null) return false;

      return selectedCustomStepOptionId === candidateStep.id;
    },
    [selectedCustomStepOptionId],
  );

  useEffect(() => {
    setSelectedCandidateIndex(null);
  }, [searchedVacancyCandidates.length]);

  useEffect(() => {
    setSelectedCandidateTab('resume');
  }, [selectedCandidateIndex]);

  useEffect(() => {
    const parsedSearchedCandidates: ISearchedCandidate[] = [];

    vacancyCandidates.forEach((vacancyCandidate, index) => {
      if (!candidateMeetsResearchFilters(vacancyCandidate)) return;
      if (!candidateMeetsSearchText(searchedText, vacancyCandidate.candidate)) {
        return;
      }

      const disregardingCustomStepOption =
        !candidateMeetsSearchedCustomStep(vacancyCandidate);
      parsedSearchedCandidates.push({ index, disregardingCustomStepOption });
    });

    setSearchedVacancyCandidates(parsedSearchedCandidates);
  }, [
    candidateMeetsResearchFilters,
    candidateMeetsSearchedCustomStep,
    searchedText,
    vacancyCandidates,
  ]);

  const handleUpdateCandidate = useCallback(
    async (candidateId: number, updatedData: IUpdateVacancyCandidateData) => {
      try {
        await updateVacancyCandidate(candidateId, updatedData);

        setVacancyCandidates(previousCandidates =>
          previousCandidates.map(previousCandidate =>
            previousCandidate.id === candidateId
              ? { ...previousCandidate, ...updatedData }
              : previousCandidate,
          ),
        );
      } catch (err) {
        showModal({
          ...ERROR_MODAL_CONFIGURATION,
          marginLeft: -448,
          message:
            'Erro ao atualizar o candidato, por favor, tente novamente mais tarde',
        });
      }
    },
    [showModal],
  );

  const handleSelectCandidate = useCallback(
    async (candidateIndex: number) => {
      const {
        id: candidateId,
        candidateEvaluation,
        candidateStep,
        candidateApproval,
      } = vacancyCandidates[candidateIndex];

      if (candidateEvaluation === CandidateEvaluation.NEW_CANDIDATE) {
        handleUpdateCandidate(candidateId, {
          candidateEvaluation: CandidateEvaluation.NOT_EVALUATED,
          candidateStep,
          candidateApproval,
        });
      }

      setSelectedCandidateIndex(candidateIndex);
    },
    [handleUpdateCandidate, vacancyCandidates],
  );

  const handleDeleteCandidate = useCallback(
    async ({
      id: vacancyCandidateId,
      candidate: {
        user: { name, lastName },
      },
    }: IVacancyCandidateWithCandidateData) => {
      await removeVacancyCandidateFromEvaluation(vacancyCandidateId);

      showModal({
        message: `O currículo de ${`${name} ${lastName}`} foi excluído`,
        backgroundColor: HighRed,
        icon: deleteIcon,
        marginLeft: -448,
        forceIconWhiteColor: true,
      });

      setVacancyCandidates(previousCandidates =>
        previousCandidates.filter(({ id }) => id !== vacancyCandidateId),
      );
      setSelectedCandidateIndex(null);
    },
    [showModal],
  );

  const handleResetStepSearchContainerScrollPoint = useCallback(() => {
    const { current } = stepSelectionContainerRef;
    if (!current) return;

    const { scrollWidth, clientWidth } = current;
    if (scrollWidth <= clientWidth + 2) setStepSearchContainerScrollPoint(null);
    else {
      current.scrollTo({ behavior: 'smooth', left: 0 });
      setStepSearchContainerScrollPoint('start');
    }
  }, []);

  const handleCreateCustomStep = useCallback(
    async (stepName: string, newStepColor: string) => {
      const createdCustomStep = await createVacancyCustomStep({
        name: stepName,
        vacancyId: vacancy.id,
      });

      const updatedCustomSteps = [
        ...vacancyCustomSteps,
        { ...createdCustomStep, color: newStepColor },
      ];

      saveCustomStepsOfVacancy({
        vacancyId: vacancy.id,
        customSteps: updatedCustomSteps,
      });
      setTimeout(() => handleResetStepSearchContainerScrollPoint(), 1);
    },
    [
      handleResetStepSearchContainerScrollPoint,
      saveCustomStepsOfVacancy,
      vacancyCustomSteps,
      vacancy.id,
    ],
  );

  const handleDeleteCustomStep = useCallback(
    async (vacancyCustomStepId: number) => {
      await deleteVacancyCustomStep(vacancyCustomStepId);

      setVacancyCandidates(previousVacancyCandidates => {
        const parsedVacancyCandidates = previousVacancyCandidates.map(
          vacancyCandidate => {
            const { candidateStep } = vacancyCandidate;

            const candidateStepWasDeletedOne =
              candidateStep?.id === vacancyCustomStepId;

            if (!candidateStepWasDeletedOne) return vacancyCandidate;
            return { ...vacancyCandidate, candidateStep: null };
          },
        );

        return parsedVacancyCandidates;
      });

      deleteCustomStepOfVacancy(vacancy.id, vacancyCustomStepId);
      setTimeout(() => handleResetStepSearchContainerScrollPoint(), 1);
    },
    [
      deleteCustomStepOfVacancy,
      handleResetStepSearchContainerScrollPoint,
      vacancy.id,
    ],
  );

  const handleCreateStartupFolder = useCallback(async (folderName: string) => {
    const createdFolder = await createStartupCandidatesFolder({
      name: folderName,
    });
    setStartupFolders(previousFolders => [...previousFolders!, createdFolder]);
  }, []);

  const handleCreateFiledStartupCandidate = useCallback(
    async (startupFoldersIds: number[], vacancyCandidateId: number) => {
      if (startupFoldersIds.length === 0) return;

      const {
        candidate: { id: candidateId },
      } = vacancyCandidates.find(({ id }) => id === vacancyCandidateId)!;

      const fileResumePromises = startupFoldersIds.map(
        (startupCandidatesFolderId, index) => {
          if (index === 0) {
            return createFiledStartupCandidate({
              vacancyCandidateId,
              startupCandidatesFolderId,
            });
          }

          return createFiledStartupCandidateWithCandidateDirectly({
            candidateId,
            startupCandidatesFolderId,
          });
        },
      );
      await Promise.all(fileResumePromises);

      setVacancyCandidates(previousVacancyCandidates =>
        previousVacancyCandidates.filter(({ id }) => id !== vacancyCandidateId),
      );
    },
    [vacancyCandidates],
  );

  const handleScrollStepSearchContainer = useCallback(
    (side: 'left' | 'right') => {
      const { current } = stepSelectionContainerRef;
      if (!current) return;

      const { scrollLeft, scrollWidth, clientWidth } = current;
      const maxScrollLeft = scrollWidth - clientWidth;
      const parsedScrollBy = clientWidth * (side === 'left' ? -0.5 : 0.5);
      current.scrollBy({
        behavior: 'smooth',
        left: parsedScrollBy,
      });

      setStepSearchContainerScrollPoint(() => {
        const comparsionScrollValue = scrollLeft + parsedScrollBy;

        if (comparsionScrollValue <= 0) return 'start';
        if (comparsionScrollValue >= maxScrollLeft) return 'end';
        return 'middle';
      });
    },
    [],
  );

  const handleVerifyStepSearchContainerScrollPoint = useCallback(() => {
    if (stepSearchContainerScrollPoint !== null) return;
    handleResetStepSearchContainerScrollPoint();
  }, [
    handleResetStepSearchContainerScrollPoint,
    stepSearchContainerScrollPoint,
  ]);

  const handleUpdateCandidatesConnectionPunctuation = useCallback(
    (categoriesCriteria: CategoriesCriteria) => {
      setVacancyCandidates(previousCandidates =>
        previousCandidates.map(candidate => {
          const connectionPunctuation =
            calculateVacancyCandidateConnectionPunctuation(
              categoriesCriteria,
              candidate,
            );

          return { ...candidate, connectionPunctuation };
        }),
      );
    },
    [],
  );

  return (
    <>
      <Modal isVisible={isDefineCriteriaModalVisible}>
        <DefineCriteriaModal
          vacancy={vacancy}
          isLoadingVacancyCandidates={isLoadingVacancyCandidates}
          handleUpdateCandidatesConnectionPunctuation={
            handleUpdateCandidatesConnectionPunctuation
          }
          handleCloseModal={() => setIsDefineCriteriaModalVisible(false)}
        />
      </Modal>

      <CandidatesSearch>
        <SearchContainer>
          <SearchBar onClick={() => searchInputRef.current?.focus()}>
            <img src={searchIcon} alt="Lupa de busca" />
            <input
              ref={searchInputRef}
              type="text"
              placeholder="Pesquise por nomes ou palavras-chaves"
              onChange={({ target: { value } }) =>
                setSearchedText(value.toLowerCase())
              }
            />
          </SearchBar>

          <ConnectionButton
            onClick={() => setIsDefineCriteriaModalVisible(true)}
          >
            <img src={connectIcon} alt="Conexão" />
            <span>Conexão</span>
          </ConnectionButton>

          <OpenFilterOptionsButton
            startupCandidates={vacancyCandidates}
            updateCandidateMeetsResearchFiltersFunction={updatedCandidateMeetsResearchFilters =>
              setCandidateMeetsResearchFilters(
                () => updatedCandidateMeetsResearchFilters,
              )
            }
          />
        </SearchContainer>

        {searchedVacancyCandidates.length === 0 ? (
          <>
            {isLoadingVacancyCandidates || isLoadingVacancySteps ? (
              <Loader style={{ marginTop: -100 }} />
            ) : (
              <NoCandidatesContainer>
                <span>
                  {vacancyCandidates.length === 0
                    ? 'Ainda não há candidatos nesta vaga'
                    : 'Nenhum candidato encontrado para a pesquisa informada'}
                </span>
              </NoCandidatesContainer>
            )}
          </>
        ) : (
          <>
            <CandidateStepSearchContainer>
              {stepSearchContainerScrollPoint !== null &&
                stepSearchContainerScrollPoint !== 'start' && (
                  <ScrollStepsOptionsButton
                    type="button"
                    side="left"
                    onClick={() => handleScrollStepSearchContainer('left')}
                  >
                    <img src={simpleArrowRightIcon} alt="Seta para esquerda" />
                  </ScrollStepsOptionsButton>
                )}

              <StepSelectionContainer
                ref={stepSelectionContainerRef}
                onMouseEnter={handleVerifyStepSearchContainerScrollPoint}
              >
                {customStepOptions.map(({ id, name, candidatesQuantity }) => (
                  <SelectStepButton
                    key={name}
                    type="button"
                    isActivated={id === selectedCustomStepOptionId}
                    onClick={() => setSelectedCustomStepOptionId(id)}
                    disabled={candidatesQuantity === 0}
                  >
                    <span>{name}</span>
                    <div className="candidates-quantity">
                      <span>{candidatesQuantity}</span>
                    </div>
                  </SelectStepButton>
                ))}
              </StepSelectionContainer>

              {stepSearchContainerScrollPoint !== null &&
                stepSearchContainerScrollPoint !== 'end' && (
                  <ScrollStepsOptionsButton
                    type="button"
                    side="right"
                    onClick={() => handleScrollStepSearchContainer('right')}
                  >
                    <img src={simpleArrowRightIcon} alt="Seta para direita" />
                  </ScrollStepsOptionsButton>
                )}
            </CandidateStepSearchContainer>

            <CandidatesTable>
              <ColumnNamesContainer>
                <ColumnButton type="button">
                  <span>Candidatos</span>
                  <img src={simpleArrowRightIcon} alt="Seta" />
                </ColumnButton>

                <ColumnButton type="button">
                  <span>Conexão</span>
                  <img src={simpleArrowRightIcon} alt="Seta" />
                </ColumnButton>

                <span>Etapa</span>
              </ColumnNamesContainer>

              <div className="sticky-div" />

              <SearchedCandidatesContainer>
                {searchedVacancyCandidates.map(
                  ({ index, disregardingCustomStepOption }) =>
                    disregardingCustomStepOption ||
                    vacancyCandidates[index] === undefined ? null : (
                      <SearchedCandidate
                        key={vacancyCandidates[index].id}
                        startupCandidate={vacancyCandidates[index]}
                        selectedCandidateIndex={selectedCandidateIndex}
                        candidateIndex={index}
                        handleSelectCandidate={handleSelectCandidate}
                      >
                        <CandidateEvaluationContainer
                          candidateEvaluation={
                            vacancyCandidates[index].candidateEvaluation
                          }
                          handleUpdateCandidateEvaluation={updatedEvaluation =>
                            handleUpdateCandidate(vacancyCandidates[index].id, {
                              ...vacancyCandidates[index],
                              candidateEvaluation: updatedEvaluation,
                            })
                          }
                        />

                        <CandidateConnection>
                          {vacancyCandidates[index].connectionPunctuation}
                        </CandidateConnection>

                        <CandidateStepColumnData
                          vacancyId={vacancy.id}
                          candidateStep={vacancyCandidates[index].candidateStep}
                          vacancyCustomSteps={vacancyCustomSteps}
                          handleCreateCustomStep={handleCreateCustomStep}
                          handleUpdateCandidateStep={updatedStep =>
                            handleUpdateCandidate(vacancyCandidates[index].id, {
                              ...vacancyCandidates[index],
                              candidateStep: updatedStep,
                            })
                          }
                          handleDeleteCustomStep={handleDeleteCustomStep}
                        />

                        <OptionsColumnData
                          handleCreateStartupFolder={handleCreateStartupFolder}
                          startupFolders={startupFolders}
                          handleDeleteCandidate={() =>
                            handleDeleteCandidate(vacancyCandidates[index])
                          }
                          handleCreateFiledStartupCandidate={startupFoldersIds =>
                            handleCreateFiledStartupCandidate(
                              startupFoldersIds,
                              vacancyCandidates[index].id,
                            )
                          }
                        />
                      </SearchedCandidate>
                    ),
                )}
              </SearchedCandidatesContainer>
            </CandidatesTable>
          </>
        )}
      </CandidatesSearch>

      <SelectedCandidate>
        {selectedCandidateIndex !== null &&
        vacancyCandidates[selectedCandidateIndex] !== undefined ? (
          <>
            <SelectedCandidateTabs>
              <CandidateTabButton
                type="button"
                isSelected={selectedCandidateTab === 'resume'}
                onClick={() => setSelectedCandidateTab('resume')}
              >
                <span>Currículo</span>
              </CandidateTabButton>

              <CandidateTabButton
                type="button"
                isSelected={selectedCandidateTab === 'tests'}
                onClick={() => setSelectedCandidateTab('tests')}
              >
                <span>Avaliações</span>
              </CandidateTabButton>
            </SelectedCandidateTabs>

            {selectedCandidateTab === 'resume' ? (
              <CandidateResume
                candidateData={
                  vacancyCandidates[selectedCandidateIndex].candidate
                }
                userData={
                  vacancyCandidates[selectedCandidateIndex].candidate.user
                }
                style={{
                  width: 440,
                  border: 'none',
                  paddingBlock: 24,
                  paddingInline: 17,
                }}
                ComponentToGoOnContainerStart={
                  <CandidateResumeLastUpdateDate>
                    <p>
                      Currículo atualizado em{' '}
                      {formatDateToBrazilianString(
                        selectedCandidateLastUpdatedAt,
                        'de',
                      )}
                    </p>
                  </CandidateResumeLastUpdateDate>
                }
              />
            ) : (
              <SelectedCandidateTests
                candidate={vacancyCandidates[selectedCandidateIndex].candidate}
                candidatesTestsAverage={candidatesTestsAverage}
              />
            )}
          </>
        ) : (
          <NoSelectedCandidateContainer>
            <img src={idCardIcon} alt="Card de usuário" />
            <p>Escolha algum candidato para ter acesso ao currículo</p>
          </NoSelectedCandidateContainer>
        )}
      </SelectedCandidate>
    </>
  );
};
