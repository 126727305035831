import { OptionTypeBase } from 'react-select';

export const generateYearOptions = (
  untilCurrentYear?: boolean,
): OptionTypeBase[] => {
  const yearOptions: OptionTypeBase[] = [];

  const OPTIONS_INITIAL_YEAR = 1940;
  const actualYear = new Date().getFullYear();
  const WORKER_LIMIT_BIRTH_YEAR = actualYear - 14;

  const OPTIONS_LIMIT_YEAR = untilCurrentYear
    ? actualYear
    : WORKER_LIMIT_BIRTH_YEAR;

  for (let year = OPTIONS_LIMIT_YEAR; year >= OPTIONS_INITIAL_YEAR; year--) {
    yearOptions.push({
      id: year,
      label: year,
    });
  }

  return yearOptions;
};
