import {
  BackgroundColor,
  HighEmphasis,
  OutlineColor,
} from 'assets/colors/palette';
import { Medium16Styles } from 'assets/fonts/fontStyles';
import styled from 'styled-components';

export const Container = styled.div`
  background: ${BackgroundColor};
  border: 1px solid ${OutlineColor};
  border-radius: 8px;

  width: 100%;
  padding: 19px;

  & + & {
    margin-top: 44px;
  }
`;

export const TopBar = styled.div`
  width: 100%;
  height: 48px;

  padding: 8px 12px;
  margin-bottom: 28px;
  border-radius: 4px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${HighEmphasis};

  span {
    ${Medium16Styles}
    color: #ffffff;
  }

  button {
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(327deg)
        brightness(102%) contrast(103%);
    }
  }
`;

export const FormInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
