import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: 50px;
  gap: 64px;

  @media (max-width: 1100px) {
    gap: 38px;
  }
`;
