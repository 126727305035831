import { Loader } from 'components/Loader';

import { COMPANY_NAME } from 'constants/company';
import React, { useCallback, useEffect, useState } from 'react';

import { Route, useHistory } from 'react-router-dom';
import { AccountConfigurationTab } from 'pages/Dashboards/CommonTabs/AccountConfigurationTab';
import { useAuth } from 'hooks/auth';
import { useRedirectToEmailSentPageForAccountCreation } from 'utils/customHooks/redirects';
import { useCandidate } from 'hooks/candidate';
import { findOneCandidateByUserId } from 'services/entitiesServices/candidateServices';
import { CandidateFormWithStepsTab } from 'pages/Dashboards/CandidatesDashboard/Tabs/CandidateFormWithStepsTab';
import { CandidatedVacanciesTab } from 'pages/Dashboards/CandidatesDashboard/Tabs/CandidatedVacanciesTab';
import { UserAccountType } from 'types/user';
import { CandidatesDashboardHeader } from 'pages/Dashboards/CandidatesDashboard/CandidatesDashboardHeader';
import { FeedVacancies } from 'pages/FeedVacancies';
import { VacancyApply } from 'pages/VacancyApply';
import { CandidateTestsTab } from 'pages/Dashboards/CandidatesDashboard/Tabs/CandidateTestsTab';
import { PerformTestPage } from 'pages/Dashboards/CandidatesDashboard/Tabs/CandidateTestsTab/PerformTestPage';
import { TestResultsPage } from 'pages/Dashboards/CandidatesDashboard/Tabs/CandidateTestsTab/TestResultsPage';
import { routeAccountConfiguration, routeDashboard } from '../routesAddresses';
import {
  routeCandidatedVacancies,
  routeCandidateTests,
  routeCreateCandidateResume,
  routeEditCandidateResume,
  routeFindVacancies,
  routePerformTest,
  routeTestResults,
  routeVacancyApply,
} from './candidatesRoutesAddresses';

const CANDIDATES_DASHBOARD_ROUTES = {
  createAndEditCandidateAccount: {
    Component: CandidateFormWithStepsTab,
    path: [routeCreateCandidateResume, routeEditCandidateResume],
  },
  candidatedVacancies: {
    Component: CandidatedVacanciesTab,
    path: routeCandidatedVacancies,
  },
  candidateTests: {
    Component: CandidateTestsTab,
    path: routeCandidateTests,
  },
  performTest: {
    Component: PerformTestPage,
    path: routePerformTest,
  },
  testResults: {
    Component: TestResultsPage,
    path: routeTestResults,
  },
  findVacancies: {
    Component: FeedVacancies,
    path: routeFindVacancies,
  },
  accountConfiguration: {
    Component: AccountConfigurationTab,
    path: routeAccountConfiguration,
  },
  vacancyApply: {
    Component: VacancyApply,
    path: `${routeVacancyApply}/:vacancyId`,
  },
};

export const CandidatesRoutes: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { candidate, updateCandidateState, clearCandidateState } =
    useCandidate();

  const isActivated = user?.activated;
  const isActivationEmailSent = user?.activationEmailSent;

  document.title = `${COMPANY_NAME} ${
    user?.name ? `| ${user.name} ${user.lastName}` : ''
  }`;

  const redirectToEmailSentPage = useRedirectToEmailSentPageForAccountCreation(
    'none',
    UserAccountType.CANDIDATE,
  );
  const [isLoadingCandidate, setIsLoadingCandidate] = useState(!candidate);

  useEffect(() => {
    if (!user || !isActivated || !!candidate) return;

    findOneCandidateByUserId(user.id)
      .then(
        async findedCandidate =>
          findedCandidate && updateCandidateState(findedCandidate),
      )
      .finally(() => setIsLoadingCandidate(false));
  }, [candidate, isActivated, updateCandidateState, user]);

  const handleMainRouteRedirect = useCallback(() => {
    if (isActivated) {
      history.push(routeCandidatedVacancies);
      return;
    }

    if (isActivationEmailSent) {
      redirectToEmailSentPage();
      return;
    }

    history.push(routeCreateCandidateResume);
  }, [history, isActivated, isActivationEmailSent, redirectToEmailSentPage]);

  useEffect(() => {
    const { pathname } = history.location;

    const isMainRoute = pathname === routeDashboard;
    if (isMainRoute) handleMainRouteRedirect();
  }, [clearCandidateState, handleMainRouteRedirect, history.location]);

  if (isLoadingCandidate && isActivated) return <Loader />;
  return (
    <>
      {isActivated && <CandidatesDashboardHeader />}

      {Object.entries(CANDIDATES_DASHBOARD_ROUTES).map(
        ([key, { Component, path }]) => (
          <Route key={key} exact path={path} component={Component} />
        ),
      )}
    </>
  );
};
