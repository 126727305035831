import simpleArrowRight from 'assets/img/simpleArrowRight.svg';
import { useState } from 'react';

import { IQuestion } from '..';
import { Container, TitleContainer } from './styles';

interface QuestionProps {
  question: IQuestion;
}

export const Question: React.FC<QuestionProps> = ({
  question: { title, answer },
}) => {
  const [isAnswerVisible, setIsAnswerVisible] = useState(false);

  return (
    <Container isAnswerVisible={isAnswerVisible}>
      <TitleContainer>
        <h2>{title}</h2>

        <button
          type="button"
          onClick={() => setIsAnswerVisible(value => !value)}
        >
          <img src={simpleArrowRight} alt="Seta" />
        </button>
      </TitleContainer>

      <p>{answer}</p>
    </Container>
  );
};
