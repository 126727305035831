import { OptionTypeBase } from 'react-select';
import { ICity, IState } from 'types/location';

export const convertLocationToOptionTypeBase = (
  { id, name, ...props }: IState | ICity,
  labelWithInitials = false,
): OptionTypeBase => {
  const label = (() => {
    const { initials } = props as IState;

    if (initials && labelWithInitials) return initials;
    return name;
  })();

  return {
    id,
    label,
  };
};
