import ReactSwitch from 'react-switch';
import { GrayLine, PrimaryColor } from 'assets/colors/palette';
import { Container } from './styles';

interface SwitchProps {
  state: [boolean, (updatedState: boolean) => void];
  name?: string;
  onColor?: string;
  offColor?: string;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
}

export const Switch: React.FC<SwitchProps> = ({
  name,
  state: [value, setValue],
  onColor,
  offColor,
  disabled,
  onChange,
}) => {
  return (
    <Container checked={value} className="switch">
      <p>{name}</p>

      <ReactSwitch
        checked={value}
        checkedIcon={false}
        uncheckedIcon={false}
        onColor={onColor ?? PrimaryColor}
        offColor={offColor ?? GrayLine}
        onChange={checked => {
          if (onChange) onChange(checked);
          else setValue(checked);
        }}
        width={56}
        height={32}
        disabled={disabled}
      />
    </Container>
  );
};
