import closeIcon from 'assets/img/close.svg';

import { ProfessionalProfileResultScreen } from 'pages/Dashboards/CandidatesDashboard/Tabs/CandidateTestsTab/TestsProperties/ProfessionalProfileProperties/ProfessionalProfileResultScreen';
import { Container, ModalHeader } from './styles';

interface ProfessionalProfileHighlightModalProps {
  testResult: string;
  handleCloseModal: () => void;
}

export const ProfessionalProfileHighlightModal: React.FC<
  ProfessionalProfileHighlightModalProps
> = ({ testResult, handleCloseModal }) => {
  return (
    <Container>
      <ModalHeader>
        <span>Detalhes do Teste de Perfil Profissional</span>
        <button type="button" onClick={handleCloseModal}>
          <img src={closeIcon} alt="X" />
        </button>
      </ModalHeader>

      <ProfessionalProfileResultScreen testResult={testResult} />
    </Container>
  );
};
