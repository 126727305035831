import { CategoriesCriteria } from 'types/criteria';
import { IVacancyResponse } from 'types/vacancy';

export const generateInitialCriteriaObject = ({
  vacancyLocation,
  certifications,
  competencies,
  skills,
  languages,
}: IVacancyResponse): CategoriesCriteria => {
  const criteriaObject: CategoriesCriteria = {
    academicEducations: [],
    certifications: [],
    competencies: [],
    professionalExperiences: [],
    languages: [],
    locations: [],
    professionalProfiles: [],
    skills: [],
    tests: {
      minimumLogicalReasoningPunctuation: 0,
      minimumPortuguesePunctuation: 0,
    },
  };

  if (vacancyLocation) {
    const {
      city: { name: cityName },
      state: { name: stateName },
    } = vacancyLocation;

    criteriaObject.locations.push(...[cityName, stateName]);
  }

  [
    { key: 'certifications', array: certifications },
    { key: 'competencies', array: competencies },
    { key: 'skills', array: skills },
    { key: 'languages', array: languages },
  ].forEach(({ key, array }) =>
    array.forEach(({ name }) => {
      const parsedKey = key as keyof CategoriesCriteria;
      (criteriaObject[parsedKey] as string[]).push(name);
    }),
  );

  return criteriaObject;
};
