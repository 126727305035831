import React from 'react';
import { CandidateTest } from 'types/candidate';
import { LogicalReasoningProperties } from './LogicalReasoningProperties';
import { PortugueseProperties } from './PortugueseProperties';
import { ProfessionalProfileProperties } from './ProfessionalProfileProperties';

export interface PunctuationObject {
  [key: string]: number;
}

export interface ITestAnswer<AnswerType> {
  text: string;
  answerType?: AnswerType;
}

export interface ITestQuestion<AnswerType> {
  question: JSX.Element;
  answers: ITestAnswer<AnswerType>[];
  correctAnswerIndex?: number;
}

export interface ITestProperties<AnswerType> {
  questions: ITestQuestion<AnswerType>[];
  InitialScreen: React.FC;
  ResultScreen: React.FC<{ testResult: string }>;
  generatePunctuationObject: () => PunctuationObject;
  generateTestResult: (
    punctuationObject: PunctuationObject,
  ) => string | undefined;
  updatePunctuationObject: (
    punctuationObject: PunctuationObject,
    answerType?: AnswerType,
    isCorrectAnswer?: boolean,
  ) => PunctuationObject;
  timeInMinutes?: number;
}

export const TESTS_PROPERTIES = {
  [CandidateTest.PROFESSIONAL_PROFILE]: ProfessionalProfileProperties,
  [CandidateTest.LOGICAL_REASONING]: LogicalReasoningProperties,
  [CandidateTest.PORTUGUESE]: PortugueseProperties,
};
