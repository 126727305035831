import * as yup from 'yup';
import { OtherRequirementsStepData } from '.';

export const validateOtherRequirementsData = async (
  otherRequirementsData: OtherRequirementsStepData,
): Promise<void> => {
  const schema = yup.object().shape({
    otherRequirements: yup.string().optional(),
  });

  await schema.validate(otherRequirementsData, { abortEarly: false });
};
