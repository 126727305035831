import closeIcon from 'assets/img/close.svg';
import { formStepInputStyles } from 'assets/styles/formWithStepsStyles/formWithStepsInputStyles';

import { PlusButton } from 'components/Buttons';
import { Input } from 'components/Input';
import { useCallback, useEffect, useState } from 'react';
import { IFormStepProps } from 'types/IFormStepProps';
import { useInputStates } from 'utils/customHooks';

import {
  StepSubTitle,
  StepTitle,
  TitleContainer,
} from 'assets/styles/formWithStepsStyles/formWithStepsTitlesStyles';
import { FormWithStepsContainer } from 'assets/styles/formWithStepsStyles/formWithStepsContainerStyles';
import { EntityWithNameDTO } from 'types/EntityWithNameDTO';
import { OmitId } from 'types';
import { stringsAreEqual } from 'utils';
import { OptionalStepMobileWarning } from 'components/FormWithSteps/OptionalStepMobileWarning';
import {
  Certification,
  CertificationsContainer,
  InputsContainer,
} from './styles';

export { validateCertificationsData } from './validateCertificationsData';

export interface CertificationsStepData {
  certifications: OmitId<EntityWithNameDTO>[];
}

export const CertificationsStep: React.FC<
  IFormStepProps<CertificationsStepData>
> = ({ initialData = {} as CertificationsStepData, setStepDataFunction }) => {
  const { certifications: initialCertifications } = initialData;

  const certificationInputStates = useInputStates('CertificationInput');
  const [certifications, setCertifications] = useState<
    OmitId<EntityWithNameDTO>[]
  >(initialCertifications ?? []);

  const handleAddCertification = useCallback(() => {
    const { value, setFunction } = certificationInputStates.mainState;
    if ((value as string).length === 0) return;

    const findedCertification = certifications.find(({ name }) =>
      stringsAreEqual(name, value as string),
    );

    if (findedCertification) {
      certificationInputStates.errorMessageState.setFunction(
        'Certificação já adicionada',
      );
    } else {
      setCertifications(previousCertifications => [
        ...previousCertifications,
        {
          name: value as string,
        },
      ]);
    }

    setFunction('');
  }, [
    certificationInputStates.mainState,
    certificationInputStates.errorMessageState,
    certifications,
  ]);

  const handleExcludeCertification = useCallback((certification: string) => {
    setCertifications(previousCertifications =>
      previousCertifications.filter(
        comparativeCertification =>
          comparativeCertification.name !== certification,
      ),
    );
  }, []);

  useEffect(() => {
    if (setStepDataFunction) {
      setStepDataFunction({
        certifications,
      });
    }
  }, [certifications, setStepDataFunction]);

  return (
    <FormWithStepsContainer>
      <TitleContainer>
        <OptionalStepMobileWarning />
        <StepTitle>Certificações</StepTitle>
        <StepSubTitle>
          Adicione as certificações que você possui, como habilitação
          automobilistica, ANBIMA ou de cursos específicos.
        </StepSubTitle>
      </TitleContainer>

      <InputsContainer>
        <span>{certifications?.length ?? 0}/8 certificações selecionadas</span>

        <Input
          disabled={certifications?.length === 8}
          states={certificationInputStates}
          placeholder="por exemplo, Habilitação tipo B"
          style={formStepInputStyles}
        />

        <PlusButton
          onClick={handleAddCertification}
          disabled={
            !certificationInputStates.mainState.value ||
            certifications?.length === 8
          }
          colorStyle="outline"
        >
          Adicionar
        </PlusButton>
      </InputsContainer>

      <CertificationsContainer>
        {certifications.map(({ name }) => (
          <Certification key={name}>
            <span>{name}</span>

            <button
              type="button"
              onClick={() => handleExcludeCertification(name)}
            >
              <img src={closeIcon} alt="Excluir competência" />
            </button>
          </Certification>
        ))}
      </CertificationsContainer>
    </FormWithStepsContainer>
  );
};
