import styled from 'styled-components';

import { Depth4 } from 'assets/colors/boxShadows';
import {
  BackgroundColor,
  HighEmphasis,
  MediumEmphasis,
  OutlineColor,
} from 'assets/colors/palette';
import {
  Bold18Styles,
  Medium14Styles,
  Regular14Styles,
} from 'assets/fonts/fontStyles';

interface VacancyProps {
  asideBarColor: string;
}

export const Container = styled.div`
  position: relative;
  transition: 0.2s;
  border-radius: 8px;

  &:hover {
    box-shadow: ${Depth4};
  }

  .action-button {
    position: absolute;
    bottom: 22px;
    right: 26px;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${BackgroundColor};
    border: 1px solid ${OutlineColor};
    border-radius: 50%;
    transition: 0.2s;

    &:hover {
      background: rgba(220, 220, 220, 0.2);
    }
  }

  .action-button + .action-button {
    right: 100px;
  }
`;

export const Vacancy = styled.button<VacancyProps>`
  width: 608px;
  height: 212px;

  padding: 22px 26px 20px 42px;
  background: ${BackgroundColor};
  border: 1px solid ${OutlineColor};
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  .vacancy-name {
    ${Bold18Styles}
    min-height: 20px;
    margin-bottom: -2px;
    color: ${HighEmphasis};
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .vacancy-location {
    ${Regular14Styles}
    color: ${MediumEmphasis};

    &:empty {
      display: none;
    }
  }

  &:after {
    content: '';
    position: absolute;
    background-color: ${({ asideBarColor }) => asideBarColor};
    border-radius: 2px;

    left: 18px;
    top: 12px;
    width: 4px;
    height: 188px;
  }
`;

export const VacancyAspects = styled.div`
  ${Regular14Styles}
  display: flex;
  gap: 40px;
  color: ${MediumEmphasis};
  font-size: 14px;

  p + p {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background-color: ${MediumEmphasis};
      left: -20px;
      bottom: 38%;
    }
  }
`;

export const BottomContainer = styled.div`
  display: flex;
  margin-top: 24px;
`;

export const VacancyCurrentSituation = styled.div`
  display: flex;
  align-items: center;
  gap: 26px;
`;

export const SituationContainer = styled.div`
  ${Medium14Styles}
  line-height: 24px;
  color: ${HighEmphasis};
  display: flex;
  padding: 12px 24px;
  gap: 8px;
  border: 1px solid ${OutlineColor};
  border-radius: 48px;
`;
