import { HighEmphasis, OutlineColor } from 'assets/colors/palette';
import { Medium16Styles, Regular16Styles } from 'assets/fonts/fontStyles';
import { OptionButton, SecondaryButton } from 'components/Buttons';
import styled from 'styled-components';

export const TopContainer = styled.div`
  width: 100%;
  height: 60px;

  border-bottom: 1px solid ${OutlineColor};
  padding: 6px 18px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  span,
  button {
    ${Medium16Styles}
    color: ${HighEmphasis};
  }

  button {
    display: flex;
    align-items: center;
    background-color: transparent;
    gap: 6px;

    img {
      width: 25px;
      height: 25px;
    }
  }
`;

export const CreationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  gap: 26px;
  padding: 10px 20px 22px;

  input {
    ${Regular16Styles}
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;

  ${OptionButton} {
    width: max-content;
    padding: 16px 32px;
  }

  ${SecondaryButton} {
    height: 48px;
  }
`;
