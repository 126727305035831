import { Depth16 } from 'assets/colors/boxShadows';
import { BackgroundColor } from 'assets/colors/palette';
import { Medium16Styles } from 'assets/fonts/fontStyles';
import styled, { css } from 'styled-components';

interface ContainerProps {
  isVisible: boolean;
}

interface SubContainerProps {
  forceIconWhiteColor?: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: fixed;
  width: 100%;
  z-index: 10;

  display: flex;
  justify-content: center;
  transition: 0.6s;

  ${({ isVisible }) =>
    isVisible
      ? css`
          bottom: 10%;
          visibility: visible;
          opacity: 1;
        `
      : css`
          bottom: 8%;
          visibility: hidden;
          opacity: 0;
        `}
`;

export const SubContainer = styled.div<SubContainerProps>`
  box-shadow: ${Depth16};

  height: 48px;
  padding: 12px 32px;
  background-color: red;
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  span {
    ${Medium16Styles}
    color: ${BackgroundColor};
  }

  ${({ forceIconWhiteColor }) =>
    forceIconWhiteColor &&
    css`
      img {
        filter: invert(100%) sepia(91%) saturate(0%) hue-rotate(291deg)
          brightness(109%) contrast(101%);
      }
    `}
`;
