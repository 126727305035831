import { OptionTypeBase } from 'react-select';

export const jobTypeOptions: OptionTypeBase[] = [
  { id: 1, label: 'CLT' },
  { id: 2, label: 'Terceirizado' },
  { id: 3, label: 'Estágio' },
  { id: 4, label: 'Jovem Aprendiz' },
  { id: 5, label: 'Trainee' },
  { id: 6, label: 'Temporário' },
  { id: 7, label: 'Pessoa Jurídica' },
];
