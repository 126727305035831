/* eslint-disable react-hooks/rules-of-hooks */
import linkedinIcon from 'assets/img/linkedin.svg';
import facebookIcon from 'assets/img/facebook.svg';
import instagramIcon from 'assets/img/instagram.svg';
import whiteArrowRight from 'assets/img/whiteArrowRight.svg';
import in6Logo from 'assets/img/in6Logo.svg';
import down from 'assets/img/down.svg';

import { PrimaryButton } from 'components/Buttons';
import { Loader } from 'components/Loader';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { routeBar } from 'routes/routesAddresses';
import { findOneStartupByName } from 'services/entitiesServices/startupServices';
import { findAllAvailableVacanciesByStartupId } from 'services/entitiesServices/vacancyServices';
import { IStartupResponse, IStartupSocialNetworks } from 'types/startup';
import { IVacancyDTOForMultipleVacancies } from 'types/vacancy';
import { convertCityAndStateFromObjectToString } from 'utils/conversion/convertCityAndStateFromObjectToString';
import { candidatesUrlFrontend } from 'services/baseUrl';
import {
  Container,
  StartupContainer,
  AboutContainer,
  AboutText,
  StartupData,
  StartupDataLine,
  GalleryImages,
  GalleryPassButton,
  VacanciesContainer,
  Vacancy,
  StartupCareerFooter,
  LogoContainer,
  RedirectContainer,
  SocialNetworksContainer,
  SocialNetwork,
} from './styles';

const INITIAL_GALLERY_EXTRA_SCROLL = 412;
const GALLERY_IMAGE_EXTRA_SCROLL = 626;

export const StartupCareer: React.FC = () => {
  const { startupName } = useParams() as { startupName: string };
  if (!startupName) return <Redirect to={routeBar} />;

  const history = useHistory();
  const galleryImagesRef = useRef<HTMLDivElement>(null);

  const [isShowingAboutExpandedText, setIsShowingAboutExpandedText] =
    useState(false);

  const [isLoadingStartup, setIsLoadingStartup] = useState(true);
  const [startup, setStartup] = useState<IStartupResponse>(
    {} as IStartupResponse,
  );
  const { socialNetworks } = startup;
  const { website, facebook, instagram, linkedin } =
    socialNetworks ?? ({} as IStartupSocialNetworks);

  const [startupVacancies, setStartupVacancies] = useState<
    Omit<IVacancyDTOForMultipleVacancies, 'startup'>[]
  >([]);
  const [isLoadingVacancies, setIsLoadingVacancies] = useState(true);

  const [
    { leftButtonVisible, rightButtonVisible },
    setGalleryImagesButtonsVisibility,
  ] = useState({
    leftButtonVisible: false,
    rightButtonVisible: true,
  });

  useEffect(() => {
    findOneStartupByName(startupName.toLowerCase())
      .then(findedStartup => {
        if (findedStartup) {
          setStartup(findedStartup);
          document.title = findedStartup.name;

          setGalleryImagesButtonsVisibility({
            leftButtonVisible: false,
            rightButtonVisible: findedStartup.images.length > 2,
          });
        }
      })
      .finally(() => setIsLoadingStartup(false));
  }, [startupName]);

  useEffect(() => {
    if (!startup.id) return;

    findAllAvailableVacanciesByStartupId(startup.id)
      .then(setStartupVacancies)
      .finally(() => setIsLoadingVacancies(false));
  }, [startup.id]);

  const MAX_GALLERY_IMAGES_SCROLL = useMemo(() => {
    if (!startup.images || startup.images.length < 3) return 0;
    const { length } = startup.images;

    return (
      INITIAL_GALLERY_EXTRA_SCROLL + (length - 3) * GALLERY_IMAGE_EXTRA_SCROLL
    );
  }, [startup.images]);

  const handleScrollGalleryImages = useCallback(
    (sideToScroll: 'left' | 'right') => {
      const { current } = galleryImagesRef;
      if (!current) return;

      const { scrollLeft } = current;
      const extraScroll =
        scrollLeft > 0
          ? GALLERY_IMAGE_EXTRA_SCROLL
          : INITIAL_GALLERY_EXTRA_SCROLL;

      if (sideToScroll === 'right') {
        const updatedScrollValue = scrollLeft + extraScroll;
        current.scrollTo({ left: updatedScrollValue });

        const hasReachedScrollLimit =
          updatedScrollValue >= MAX_GALLERY_IMAGES_SCROLL;
        setGalleryImagesButtonsVisibility({
          leftButtonVisible: true,
          rightButtonVisible: !hasReachedScrollLimit,
        });
        return;
      }

      const updatedScrollValue = scrollLeft - extraScroll;
      const hasReachedScrollStart = updatedScrollValue <= 0;
      current.scrollTo({ left: updatedScrollValue });

      setGalleryImagesButtonsVisibility({
        leftButtonVisible: !hasReachedScrollStart,
        rightButtonVisible: true,
      });
    },
    [MAX_GALLERY_IMAGES_SCROLL],
  );

  if (!isLoadingStartup && !startup.id) return <Redirect to={routeBar} />;
  return (
    <Container>
      {isLoadingStartup || isLoadingVacancies ? (
        <Loader />
      ) : (
        <StartupContainer>
          <img src={startup.logo} alt="Logo" />
          <h1 id="startup-name">{startup.name}</h1>
          <div id="open-vacancies-quantity">
            Vagas abertas ({startupVacancies.length})
          </div>

          <AboutContainer>
            <AboutText isShowingExpandedText={isShowingAboutExpandedText}>
              <p>{startup.about + startup.about}</p>

              <button
                type="button"
                onClick={() =>
                  setIsShowingAboutExpandedText(previousValue => !previousValue)
                }
              >
                {isShowingAboutExpandedText ? 'Mostrar menos' : 'Leia mais'}
                <img src={down} alt="Seta para baixo" />
              </button>
            </AboutText>

            <StartupData>
              <StartupDataLine>
                <span className="line-title">Localização</span>

                <div>
                  {startup.startupLocations.map(location => (
                    <p key={location.id} className="line-content">
                      {convertCityAndStateFromObjectToString(
                        location,
                        ', ',
                        true,
                      )}
                    </p>
                  ))}
                </div>
              </StartupDataLine>

              {website && (
                <StartupDataLine highlightedContent>
                  <span className="line-title">Site</span>
                  <a
                    href={website}
                    target="_blank"
                    rel="noreferrer"
                    className="line-content"
                  >
                    {website.split('https://')[1]}
                  </a>
                </StartupDataLine>
              )}

              <StartupDataLine>
                <span className="line-title">Fundação</span>
                <span className="line-content">{startup.foundationYear}</span>
              </StartupDataLine>

              <StartupDataLine>
                <span className="line-title">Funcionário</span>
                <span className="line-content">
                  {startup.employeesQuantity}
                </span>
              </StartupDataLine>

              <StartupDataLine>
                <span className="line-title">Setor de atuação</span>

                <div>
                  {startup.businessSectors.map(businessSector => (
                    <p key={businessSector.id} className="line-content">
                      {businessSector.sector}
                    </p>
                  ))}
                </div>
              </StartupDataLine>

              <StartupDataLine>
                <span className="line-title">Modelo de negócios</span>
                <span className="line-content">
                  {startup.businessModel.model}
                </span>
              </StartupDataLine>

              <SocialNetworksContainer>
                {linkedin && (
                  <SocialNetwork href={linkedin} target="_blank">
                    <img src={linkedinIcon} alt="Linkedin" />
                  </SocialNetwork>
                )}

                {facebook && (
                  <SocialNetwork href={facebook} target="_blank">
                    <img src={facebookIcon} alt="Facebook" />
                  </SocialNetwork>
                )}

                {instagram && (
                  <SocialNetwork href={instagram} target="_blank">
                    <img src={instagramIcon} alt="Instagram" />
                  </SocialNetwork>
                )}
              </SocialNetworksContainer>
            </StartupData>
          </AboutContainer>

          {startup.images.length > 0 && (
            <GalleryImages>
              {leftButtonVisible && (
                <GalleryPassButton
                  type="button"
                  side="left"
                  onClick={() => handleScrollGalleryImages('left')}
                >
                  <img src={whiteArrowRight} alt="Seta" />
                </GalleryPassButton>
              )}

              <div id="images-container" ref={galleryImagesRef}>
                {startup.images.map(({ id, image }) => (
                  <img key={id} src={image as string} alt="Foto da galeria" />
                ))}
              </div>

              {rightButtonVisible && (
                <GalleryPassButton
                  type="button"
                  side="right"
                  onClick={() => handleScrollGalleryImages('right')}
                >
                  <img src={whiteArrowRight} alt="Seta" />
                </GalleryPassButton>
              )}
            </GalleryImages>
          )}

          <VacanciesContainer>
            <h2>Vagas abertas ({startupVacancies.length})</h2>

            {startupVacancies.map(vacancy => (
              <Vacancy
                key={vacancy.id}
                href={`${candidatesUrlFrontend}/${vacancy.id + 1000}`}
                target="_blank"
              >
                <span className="vacancy-name">{vacancy.name}</span>

                <ul className="vacancy-informations">
                  {vacancy.vacancyLocation && (
                    <li>
                      {convertCityAndStateFromObjectToString(
                        vacancy.vacancyLocation,
                        ', ',
                        true,
                      )}
                    </li>
                  )}
                  <li>{vacancy.jobType.type}</li>
                  <li>{vacancy.workloadType.type}</li>
                </ul>
              </Vacancy>
            ))}
          </VacanciesContainer>
        </StartupContainer>
      )}

      <StartupCareerFooter
        style={{ position: isLoadingStartup ? 'absolute' : 'static' }}
      >
        <div id="sub-container">
          <LogoContainer>
            Página criada por
            <img src={in6Logo} alt="Logo IN6" />
          </LogoContainer>

          <RedirectContainer>
            <span>
              Sua empresa precisa de uma plataforma de recrutamento e seleção?
            </span>

            <PrimaryButton type="button" onClick={() => history.push(routeBar)}>
              Conheça a IN6
            </PrimaryButton>
          </RedirectContainer>
        </div>
      </StartupCareerFooter>
    </Container>
  );
};
