import { formStepInputStyles } from 'assets/styles/formWithStepsStyles/formWithStepsInputStyles';
import { Input } from 'components/Input';
import { Select } from 'components/Select';
import { useStartup } from 'hooks/startup';
import { useCallback, useEffect, useMemo } from 'react';
import { OptionTypeBase } from 'react-select';
import { EntityWithIdDTO } from 'types';
import { IFormStepProps } from 'types/IFormStepProps';
import { getInputStateValue } from 'utils';
import { convertCityAndStateFromObjectToString } from 'utils/conversion/convertCityAndStateFromObjectToString';
import { workloadTypeOptions } from 'utils/typeOptions';
import {
  StepSubTitle,
  StepTitle,
  TitleContainer,
} from 'assets/styles/formWithStepsStyles/formWithStepsTitlesStyles';
import { FormWithStepsContainer } from 'assets/styles/formWithStepsStyles/formWithStepsContainerStyles';
import { useInputStates } from 'utils/customHooks';
import { useFormError } from 'errors/useFormError';
import { InputsContainer } from './styles';

export { validateWorkloadData } from './validateWorkloadData';

export interface WorkloadStepData {
  workloadType: EntityWithIdDTO;
  vacancyLocation: EntityWithIdDTO | null;
  workSchedule?: string;
}

const WORKLOAD_REMOTE_TYPE_ID = '3';

export const WorkloadStep: React.FC<IFormStepProps<WorkloadStepData>> = ({
  initialData = {} as WorkloadStepData,
  setStepDataFunction,
  errors,
}) => {
  const { startup } = useStartup();
  const { startupLocations } = startup!;
  const { workloadType, vacancyLocation, workSchedule } = initialData;
  const { handleFormError } = useFormError();

  const workloadTypeStates = useInputStates('workloadType', workloadType?.id);
  const vacancyLocationStates = useInputStates(
    'vacancyLocation',
    vacancyLocation?.id,
  );
  const workScheduleStates = useInputStates('workSchedule', workSchedule);

  useEffect(() => {
    if (workloadTypeStates.mainState.value !== WORKLOAD_REMOTE_TYPE_ID) return;
    vacancyLocationStates.errorMessageState.setFunction('');
  }, [
    vacancyLocationStates.errorMessageState,
    workloadTypeStates.mainState.value,
  ]);

  const startupLocationsOptions: OptionTypeBase[] = useMemo(
    () =>
      startupLocations.map(({ id, ...startupLocation }) => ({
        id,
        label: convertCityAndStateFromObjectToString(
          startupLocation,
          ', ',
          true,
        ),
      })),
    [startupLocations],
  );

  const getUpdatedStepData = useCallback(() => {
    const workloadTypeId = Number(getInputStateValue(workloadTypeStates));
    const parsedVacancyLocation =
      workloadTypeId === 3
        ? null
        : {
            id: Number(getInputStateValue(vacancyLocationStates)),
          };

    const updatedStatesObject = {
      workloadType: { id: workloadTypeId },
      vacancyLocation: parsedVacancyLocation,
      workSchedule: getInputStateValue(workScheduleStates),
    };

    return updatedStatesObject as WorkloadStepData;
  }, [workloadTypeStates, vacancyLocationStates, workScheduleStates]);

  useEffect(() => {
    if (!errors) return;

    const stepStates = [
      workloadTypeStates,
      vacancyLocationStates,
      workScheduleStates,
    ];
    handleFormError(errors, stepStates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    if (!setStepDataFunction) return;

    const updatedData = getUpdatedStepData();
    setStepDataFunction(updatedData);
  }, [getUpdatedStepData, setStepDataFunction]);

  return (
    <FormWithStepsContainer>
      <TitleContainer>
        <StepTitle>Jornada de trabalho</StepTitle>
        <StepSubTitle>
          Especifique o tipo de carga horária e os horários de dedicação para
          tornar sua vaga mais atrativa aos candidatos
        </StepSubTitle>
      </TitleContainer>

      <InputsContainer>
        <Select
          name="Tipo de carga horária"
          placeholder="selecionar tipo de carga horária"
          states={workloadTypeStates}
          selectOptions={workloadTypeOptions}
          style={formStepInputStyles}
          startWithoutValue
        />

        <Select
          name="Local de trabalho"
          placeholder="selecionar local de trabalho"
          states={vacancyLocationStates}
          disabled={
            workloadTypeStates.mainState.value === WORKLOAD_REMOTE_TYPE_ID
          }
          loadingIndicatorDisabled
          selectOptions={startupLocationsOptions}
          style={formStepInputStyles}
          startWithoutValue
        />

        <Input
          name="Horário de trabalho"
          placeholder="por exemplo, segunda a sexta, 8:00-17:00"
          isOptional
          states={workScheduleStates}
          style={formStepInputStyles}
        />
      </InputsContainer>
    </FormWithStepsContainer>
  );
};
