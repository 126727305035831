import {
  IStartupBasicData,
  IStartupRequest,
  IStartupResponse,
  IUpdateStartupRequest,
  StartupExhibitionDataWithVacanciesQuantityDTO,
} from 'types/startup';
import { api } from '../api';

const servicesPrefix = 'startup';

export const createStartup = async (
  createStartupData: IStartupRequest,
): Promise<IStartupBasicData> => {
  const { data } = await api.post<IStartupBasicData>(
    `${servicesPrefix}`,
    createStartupData,
  );

  return data;
};

export const findOneStartupById = async (
  startupId: number,
): Promise<IStartupResponse | undefined> => {
  const { data } = await api.get<IStartupResponse | undefined>(
    `${servicesPrefix}/findOneById/${startupId}`,
  );

  return data;
};

export const findOneStartupByName = async (
  startupName: string,
): Promise<IStartupResponse | undefined> => {
  const { data } = await api.get<IStartupResponse | undefined>(
    `${servicesPrefix}/findOneByName/${startupName}`,
  );

  return data;
};

export const findOneStartupByEmployerId = async (
  employerId: number,
): Promise<IStartupResponse | undefined> => {
  const { data } = await api.get<IStartupResponse | undefined>(
    `${servicesPrefix}/findOneByEmployerId/${employerId}`,
  );

  return data;
};

export const getTenStartupsWithMostVacancies = async (): Promise<
  StartupExhibitionDataWithVacanciesQuantityDTO[]
> => {
  const { data } = await api.get<
    StartupExhibitionDataWithVacanciesQuantityDTO[]
  >(`${servicesPrefix}/getTenWithMostVacancies`);

  return data;
};

export const updateStartup = async (
  updatedStartup: IUpdateStartupRequest,
): Promise<IStartupResponse> => {
  const { data } = await api.put<IStartupResponse>(
    `${servicesPrefix}`,
    updatedStartup,
  );

  return data;
};

export const deleteStartup = async (): Promise<void> => {
  await api.delete(`${servicesPrefix}`);
};
