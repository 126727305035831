import { routeDashboard } from 'routes/routesAddresses';

export const routeCreateCandidateResume = `${routeDashboard}/createResume`;
export const routeEditCandidateResume = `${routeDashboard}/editResume`;

export const routeCandidateTests = `${routeDashboard}/candidateTests`;
export const routePerformTest = `${routeDashboard}/performTest`;
export const routeTestResults = `${routeDashboard}/testResults`;

export const routeCandidatedVacancies = `${routeDashboard}/candidatedVacancies`;
export const routeFindVacancies = `${routeDashboard}/findVacancies`;
export const routeVacancyApply = `${routeDashboard}/vacancyApply`;
