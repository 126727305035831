import { HighEmphasis, PrimaryColor } from 'assets/colors/palette';
import { PrimaryButton } from 'components/Buttons';
import styled from 'styled-components';

export const Container = styled.div`
  width: 1200px;
  min-height: calc(100vh - 133px);
  padding-block: 8%;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;

  margin-inline: auto;
  text-align: center;
  color: ${HighEmphasis};
  letter-spacing: 0.75px;

  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
  }

  h2 {
    font-size: 24px;
    line-height: 38px;
    font-weight: 700;
  }

  p {
    font-size: 14px;
  }

  .description {
    width: 440px;
    line-height: 24px;
  }
  .email {
    margin-top: 4px;
    color: ${PrimaryColor};
  }

  ${PrimaryButton} {
    margin-top: 40px;
    padding-inline: 20px;

    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
  }
`;

export const NumberIcon = styled.div`
  width: 28px;
  height: 28px;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;

  border: 1px solid ${HighEmphasis};
  border-radius: 50%;
`;
