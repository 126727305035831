import * as yup from 'yup';
import { yupRequiredStringField } from 'utils/validation';

import { yupObjectWithId } from 'utils/validation/yupObjectWithId';
import { VacancyDefinitionStepData } from '.';

export const validateVacancyDefinitionData = async (
  vacancyDefinitionData: VacancyDefinitionStepData,
): Promise<void> => {
  const schema = yup.object().shape({
    name: yupRequiredStringField,
    jobType: yupObjectWithId.required('Campo obrigatório'),
    description: yupRequiredStringField.min(100, 'Mínimo de 100 caracteres'),
  });

  await schema.validate(vacancyDefinitionData, { abortEarly: false });
};
