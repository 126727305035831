import styled from 'styled-components';

export const FormWithStepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 44px;

  @media (max-width: 1100px) {
    padding-inline: 18px;
  }
`;
