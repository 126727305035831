import styled, { css } from 'styled-components';
import {
  BackgroundColor,
  GrayBackground,
  HighEmphasis,
  MediumEmphasis,
  OutlineColor,
} from 'assets/colors/palette';
import {
  Bold16Styles,
  Bold32Styles,
  Medium14Styles,
  Medium16Styles,
  Regular14Styles,
} from 'assets/fonts/fontStyles';
import { TextGradientPrimaryBackground } from 'assets/colors/background';

interface VacancyPostContainerProps {
  disabled: boolean;
}

interface VacancyStatusBarProps {
  colors: {
    low: string;
    high: string;
    filter: string;
  };
}

interface VacancyPostProps {
  colors: {
    low: string;
    high: string;
  };
}

export const VacancyStatusBar = styled.div<VacancyStatusBarProps>`
  position: absolute;
  width: 1248px;
  height: 64px;
  padding-inline: 20px;
  border-radius: 8px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ colors: { low, high, filter } }) => css`
    background: ${low};
    color: ${high};

    img {
      width: 20px;
      height: 20px;
      filter: ${filter};
    }
  `}
`;

export const VacancyStatusInformation = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const CandidatesQuantityContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  width: 237px;
  height: 100%;
  border-left: 1px solid ${BackgroundColor};

  span {
    ${Medium14Styles}
    line-height: 24px;
  }
`;

export const MainContainer = styled.div`
  padding-top: 120px;
`;

export const LineTitle = styled.span`
  color: ${MediumEmphasis};
  min-width: 248px;
`;

export const VacancyDataContainer = styled.section`
  ${Regular14Styles}
  color: ${HighEmphasis};
  line-height: 28px;

  width: 752px;

  border: solid 1px ${OutlineColor};
  border-radius: 8px;
  padding: 20px 22px 8px;
  margin-bottom: 30px;

  p,
  ${LineTitle} {
    padding: 9px 0;
  }

  p {
    word-wrap: break-word;
    white-space: pre-line;
  }
`;

export const VacancyDataContainerTitle = styled.h3`
  ${Bold16Styles}
  margin-bottom: 14px;
`;

export const DataLine = styled.div`
  display: flex;

  p {
    word-wrap: break-word;
    max-width: 100%;
  }

  & + & {
    border-top: solid 1px ${OutlineColor};
  }
`;

export const RequirementsListContainer = styled.div`
  display: flex;
  gap: 14px;
  flex-flow: wrap;
  padding: 14px 0;
`;

export const RequirementDescription = styled.div`
  position: absolute;
  width: 300px;
  max-width: 300px;
  word-wrap: break-word;
  font-size: 12px;

  padding: 12px 16px;
  text-align: center;

  background: ${GrayBackground};
  border: 1px solid ${OutlineColor};
  border-radius: 8px;

  bottom: 120%;
  left: 50%;
  margin-left: -150px;

  visibility: hidden;
  opacity: 0;

  transition: 0.5s;

  &:hover {
    visibility: visible;
    opacity: 1;
  }

  &:empty {
    display: none;
  }
`;

export const Requirement = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid ${OutlineColor};
  border-radius: 8px;
  padding-inline: 16px;
  height: 50px;
  position: relative;

  &:hover {
    ${RequirementDescription} {
      visibility: visible;
      opacity: 1;
    }
  }

  span {
    ${Medium16Styles}
    ${TextGradientPrimaryBackground}
    line-height: 18px;
  }
`;

export const VacancySpecificationsContainer = styled.div`
  display: flex;
  flex-flow: wrap;
  gap: 12px;

  height: 76px;
  margin: 22px 0;
`;

export const VacancySpecification = styled.div`
  max-width: 282px;
  height: 32px;
  padding: 0 12px;
  border-radius: 4px;

  font-weight: 500;
  font-size: 10px;
  line-height: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LinkContainer = styled.div`
  margin-top: auto;

  p,
  #vacancy-link {
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.75px;
  }

  p + p {
    margin-top: 8px;
  }

  #vacancy-link {
    font-weight: 700;
  }
`;

export const VacancyPost = styled.div<VacancyPostProps>`
  width: 360px;
  height: 360px;
  padding: 26px 24px;

  border-radius: 8px;
  position: relative;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  letter-spacing: 0.75px;
  overflow: hidden;

  & > span {
    font-size: 12px;
    line-height: 12px;
    font-weight: 700;
    line-height: 24px;
  }

  & > small {
    font-size: 10px;
    line-height: 16px;
    font-weight: 500;
  }

  h1 {
    ${Bold32Styles}
    letter-spacing: 0.75px;
    line-height: 40px;
    margin-block: 2px 10px;
    max-width: 312px;
    max-height: 80px;
    word-wrap: break-word;

    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    overflow: hidden;
    -webkit-box-orient: vertical;
  }

  ${({ colors: { low, high } }) => css`
    background: ${low};
    color: ${high};

    ${VacancySpecification} {
      border: 1px solid ${high};
    }
  `}
`;

export const VacancyPostDescription = styled.p`
  width: 360px;
  font-weight: 400;
  font-size: 12px;
  line-height: 28px;
  letter-spacing: 0.75px;

  color: ${HighEmphasis};
  word-wrap: break-word;
`;

export const PostActionButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const VacancyPostContainer = styled.div<VacancyPostContainerProps>`
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  ${VacancyPost}, ${VacancyPostDescription} {
    opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};
  }

  ${PostActionButtonsContainer} {
    opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  }
`;
