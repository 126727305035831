import deleteIcon from 'assets/img/delete.svg';
import { Container, TopBar, FormInputs } from './styles';

interface FormWithStepsSubFormProps {
  name: string;
  handleDeleteSubFormData: () => void;
}

export const FormWithStepsSubForm: React.FC<FormWithStepsSubFormProps> = ({
  name,
  handleDeleteSubFormData,
  children,
}) => {
  return (
    <Container>
      <TopBar>
        <span>{name}</span>
        <button type="button" onClick={handleDeleteSubFormData}>
          <img src={deleteIcon} alt="Deletar" />
        </button>
      </TopBar>

      <FormInputs>{children}</FormInputs>
    </Container>
  );
};
