import styled from 'styled-components';

import {
  BackgroundColor,
  HighEmphasis,
  OutlineColor,
} from 'assets/colors/palette';
import { Medium16Styles, Regular16Styles } from 'assets/fonts/fontStyles';
import { OptionButton } from 'components/Buttons';
import { Checkmark } from 'components/Input/CheckboxInput/styles';

export const Container = styled.div`
  width: 438px;
  outline: 0;

  background-color: ${BackgroundColor};
  border: 1px solid ${OutlineColor};
  border-radius: 8px;
`;

export const TopContainer = styled.div`
  width: 100%;
  height: 60px;

  border-bottom: 1px solid ${OutlineColor};
  padding: 6px 18px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  span,
  button {
    ${Medium16Styles}
    color: ${HighEmphasis};
  }

  button {
    display: flex;
    align-items: center;
    background-color: transparent;
    gap: 6px;

    img {
      width: 25px;
      height: 25px;
    }
  }
`;

export const FoldersContainer = styled.div`
  width: 100%;
  min-height: 217px;
  padding: 30px 20px 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${OptionButton} {
    width: 398px;
  }

  label {
    ${Regular16Styles}
    color: ${HighEmphasis};
    padding-left: 32px;

    ${Checkmark} {
      border-radius: 3px;
      top: -1px;
    }
  }
`;

export const CheckboxesContainer = styled.div`
  padding-bottom: 30px;

  display: flex;
  flex-direction: column;
  gap: 24px;
`;
