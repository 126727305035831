import styled from 'styled-components';
import { BackgroundColor, OutlineColor } from 'assets/colors/palette';

export const Container = styled.div`
  width: 744px;
  margin-top: 60px;

  margin-inline: auto;
  padding-bottom: 140px;
  gap: 4%;

  @media (max-width: 1100px) {
    width: 90vw;
    margin-top: 32px;
  }
`;

export const ApplyFooter = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100px;
  background-color: ${BackgroundColor};
  border-top: 1px solid ${OutlineColor};

  position: fixed;
  bottom: 0;
  left: 0;
`;
