import infoIcon from 'assets/img/info.svg';
import { SecondaryButton } from 'components/Buttons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CategoriesCriteria, CriteriaType } from 'types/criteria';
import { IVacancyResponse } from 'types/vacancy';
import { generateInitialCriteriaObject, getCriteriaQuantity } from 'utils';
import { CATEGORIES_PROPS } from './categoriesProps';

import {
  ButtonsContainer,
  CancelButton,
  CategoriesContainer,
  Category,
  ConfigurationContainer,
  Container,
  CriteriaContainer,
  InformationContainer,
  ModalHeader,
} from './styles';

interface DefineCriteriaModalProps {
  vacancy: IVacancyResponse;
  isLoadingVacancyCandidates: boolean;
  handleUpdateCandidatesConnectionPunctuation: (
    allCriteria: CategoriesCriteria,
  ) => void;
  handleCloseModal: () => void;
}

export const DefineCriteriaModal: React.FC<DefineCriteriaModalProps> = ({
  vacancy,
  isLoadingVacancyCandidates,
  handleUpdateCandidatesConnectionPunctuation,
  handleCloseModal,
}) => {
  const [hasChangedCriteria, setHasChangedCriteria] = useState(false);

  const [selectedCriteria, setSelectedCriteria] =
    useState<keyof CategoriesCriteria>('locations');
  const [allCriteria, setAllCriteria] = useState<
    CategoriesCriteria | undefined
  >(undefined);

  useEffect(() => {
    if (allCriteria !== undefined || isLoadingVacancyCandidates) return;

    const initialCriteriaObject = generateInitialCriteriaObject(vacancy);
    handleUpdateCandidatesConnectionPunctuation(initialCriteriaObject);
    setAllCriteria(initialCriteriaObject);
  }, [
    allCriteria,
    handleUpdateCandidatesConnectionPunctuation,
    isLoadingVacancyCandidates,
    vacancy,
  ]);

  const SelectedGetCriteriaComponent = useMemo(
    () => CATEGORIES_PROPS[selectedCriteria].GetCriteriaComponent,
    [selectedCriteria],
  );

  const handleUpdateCriteria = useCallback(
    (updatedCriteria: CriteriaType) => {
      setAllCriteria(previousAllCriteria => {
        const parsedCriteria = { ...previousAllCriteria } as CategoriesCriteria;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        parsedCriteria[selectedCriteria] = updatedCriteria as any;
        return parsedCriteria;
      });
      setHasChangedCriteria(true);
    },
    [selectedCriteria],
  );

  return (
    <Container>
      <ModalHeader>
        <span>Configurar Indicador de Conexão</span>
      </ModalHeader>

      <InformationContainer>
        <div>
          <img src={infoIcon} alt="Informação" />
          <span>Indicador de Conexão</span>
        </div>

        <p>
          A Conexão é um indicador que mostra o nível de semelhança do currículo
          dos candidatos com a descrição, requisitos e qualificações exigidas
          para a vaga. O recrutador pode definir diferentes palavras-chave
          (critérios) que serão buscados automaticamente nos currículos
          recebidos e utilizados para calcular à Conexão.
        </p>
      </InformationContainer>

      {allCriteria !== undefined && (
        <ConfigurationContainer>
          <CategoriesContainer>
            <small>CATEGORIAS</small>

            {Object.entries(CATEGORIES_PROPS).map(
              ([key, { exhibitionName }]) => (
                <Category
                  key={key}
                  isSelected={selectedCriteria === key}
                  onClick={() =>
                    setSelectedCriteria(key as keyof CategoriesCriteria)
                  }
                >
                  <span>{exhibitionName}</span>
                  <div className="criteria-quantity">
                    {getCriteriaQuantity(
                      allCriteria,
                      key as keyof CategoriesCriteria,
                    )}
                  </div>
                </Category>
              ),
            )}
          </CategoriesContainer>

          <CriteriaContainer>
            <small>DEFINIR CRITÉRIOS</small>

            <SelectedGetCriteriaComponent
              previousCriteria={allCriteria[selectedCriteria]}
              handleUpdateCriteria={handleUpdateCriteria}
            >
              {CATEGORIES_PROPS[selectedCriteria].placeholder}
            </SelectedGetCriteriaComponent>
          </CriteriaContainer>
        </ConfigurationContainer>
      )}

      <ButtonsContainer>
        <CancelButton type="button" onClick={handleCloseModal}>
          Cancelar
        </CancelButton>
        <SecondaryButton
          type="button"
          disabled={!hasChangedCriteria}
          onClick={() => {
            if (allCriteria === undefined) return;

            handleUpdateCandidatesConnectionPunctuation(allCriteria);
            setHasChangedCriteria(false);
            handleCloseModal();
          }}
        >
          Aplicar critérios
        </SecondaryButton>
      </ButtonsContainer>
    </Container>
  );
};
