import searchIcon from 'assets/img/search.svg';
import closeIcon from 'assets/img/close.svg';

import { CandidateResume } from 'components/CandidateResume';
import { Loader } from 'components/Loader';
import { Modal } from 'components/Modal';

import { useCallback, useEffect, useRef, useState } from 'react';
import { adminFindAllCandidates } from 'services/entitiesServices/adminServices';
import { findOneCandidateById } from 'services/entitiesServices/candidateServices';
import {
  ICandidateResponse,
  ICandidateResponseForFindAll,
} from 'types/candidate';
import { convertCityAndStateFromObjectToString } from 'utils/conversion/convertCityAndStateFromObjectToString';
import {
  ColumnNamesContainer,
  ContentTable,
  SearchBar,
  SearchContainer,
  SearchedContentContainer,
} from '../styles';
import { CandidateRow } from './CandidateRow';
import { CloseButtonContainer, Container, ModalContainer } from './styles';
import { HandleGetCsvRowsFunctionType } from '..';

interface CandidatesTabProps {
  handleShowDeleteUserModal: (userId: number) => void;
  deletedUserId: number | null;
  setHandleGetCsvRows: (
    updatedHandleGetCsvRows: HandleGetCsvRowsFunctionType,
  ) => void;
  setRowsQuantity: (rowsQuantity: number) => void;
}

export const CandidatesTab: React.FC<CandidatesTabProps> = ({
  handleShowDeleteUserModal,
  deletedUserId,
  setHandleGetCsvRows,
  setRowsQuantity,
}) => {
  const searchInputRef = useRef<HTMLInputElement>(null);

  const [searchedText, setSearchedText] = useState('');
  const [isCandidateResumeModalVisible, setIsCandidateResumeModalVisible] =
    useState(false);
  const [candidateShowingOnModal, setCandidateShowingOnModal] =
    useState<ICandidateResponse | null>(null);

  const [isLoadingCandidates, setIsLoadingCandidates] = useState(true);
  const [candidates, setCandidates] = useState<ICandidateResponseForFindAll[]>(
    [],
  );
  const [searchedCandidatesIndexes, setSearchedCandidatesIndexes] = useState<
    number[]
  >([]);

  useEffect(() => {
    adminFindAllCandidates()
      .then(findedCandidates => setCandidates(findedCandidates))
      .finally(() => setIsLoadingCandidates(false));
  }, []);

  useEffect(() => {
    if (deletedUserId === null) return;

    setCandidates(previousCandidates =>
      previousCandidates.filter(({ user: { id } }) => id !== deletedUserId),
    );
  }, [deletedUserId]);

  const candidateMeetsSearchText = useCallback(
    ({
      user: { name, lastName },
      profession,
      city: { name: cityName },
      state: { name: stateName },
    }: ICandidateResponseForFindAll) => {
      const parsedName = `${name} ${lastName}`.toLowerCase();
      if (parsedName.includes(searchedText)) return true;

      if (profession.toLowerCase().includes(searchedText)) return true;
      if (cityName.toLowerCase().includes(searchedText)) return true;
      return stateName.toLowerCase().includes(searchedText);
    },
    [searchedText],
  );

  useEffect(() => {
    if (candidates.length === 0) return;

    const filteredCandidatesIndexes = candidates
      .map((candidate, index) => {
        if (candidateMeetsSearchText(candidate)) return index;
        return null;
      })
      .filter(indexValue => indexValue !== null) as number[];

    setRowsQuantity(filteredCandidatesIndexes.length);
    setSearchedCandidatesIndexes(filteredCandidatesIndexes);
  }, [candidateMeetsSearchText, candidates, setRowsQuantity]);

  const handleSetCandidateShowingOnModal = useCallback(
    async (candidateId: number) => {
      setIsCandidateResumeModalVisible(true);

      const findedCandidate = await findOneCandidateById(candidateId);
      if (!findedCandidate) return;

      setCandidateShowingOnModal(findedCandidate);
    },
    [],
  );

  const handleCloseResumeModal = useCallback(() => {
    setIsCandidateResumeModalVisible(false);
    setTimeout(() => setCandidateShowingOnModal(null), 500);
  }, []);

  const handleGetSearchedCandidatesCsvRows = useCallback(() => {
    const firstRow = ['CANDIDATO', 'PROFISSÃO', 'CIDADE', 'EMAIL', 'TELEFONE'];

    const candidatesRows = searchedCandidatesIndexes.map(index => {
      const {
        user: { name, lastName, email },
        profession,
        city,
        state,
        phoneNumber,
      } = candidates[index];

      const candidateCsvContent = [];
      candidateCsvContent.push(
        `${name} ${lastName}`,
        profession,
        convertCityAndStateFromObjectToString({ city, state }, ' - ', true),
        email,
        phoneNumber,
      );

      return candidateCsvContent;
    });

    return [firstRow, ...candidatesRows];
  }, [candidates, searchedCandidatesIndexes]);

  useEffect(() => {
    if (candidates.length === 0) setHandleGetCsvRows(null);
    else setHandleGetCsvRows(handleGetSearchedCandidatesCsvRows);
  }, [
    candidates.length,
    handleGetSearchedCandidatesCsvRows,
    setHandleGetCsvRows,
  ]);

  return (
    <Container>
      <Modal isVisible={isCandidateResumeModalVisible}>
        <ModalContainer>
          {candidateShowingOnModal === null ? (
            <Loader />
          ) : (
            <>
              <CloseButtonContainer>
                <button type="button" onClick={handleCloseResumeModal}>
                  <img src={closeIcon} alt="Fechar modal" />
                </button>
              </CloseButtonContainer>

              <CandidateResume
                candidateData={candidateShowingOnModal}
                userData={candidateShowingOnModal.user}
              />
            </>
          )}
        </ModalContainer>
      </Modal>

      <SearchContainer>
        <SearchBar onClick={() => searchInputRef.current?.focus()}>
          <img src={searchIcon} alt="Lupa de busca" />
          <input
            ref={searchInputRef}
            type="text"
            placeholder="Pesquise pelos candidatos"
            onChange={({ target: { value } }) =>
              setSearchedText(value.toLowerCase())
            }
          />
        </SearchBar>
      </SearchContainer>

      {isLoadingCandidates ? (
        <Loader />
      ) : (
        <ContentTable>
          <ColumnNamesContainer>
            <span className="column-title candidates-column">CANDIDATO</span>
            <span className="column-title profession-column">PROFISSÃO</span>
            <span className="column-title location-column">CIDADE</span>
            <span className="column-title email-column">EMAIL</span>
            <span className="column-title phone-column">TELEFONE</span>
            <span className="column-title options-column" />
          </ColumnNamesContainer>

          <SearchedContentContainer>
            {searchedCandidatesIndexes.map(index =>
              candidates[index] === undefined ? null : (
                <CandidateRow
                  key={candidates[index].id}
                  candidate={candidates[index]}
                  handleShowDeleteUserModal={handleShowDeleteUserModal}
                  handleShowResumeModal={handleSetCandidateShowingOnModal}
                />
              ),
            )}
          </SearchedContentContainer>
        </ContentTable>
      )}
    </Container>
  );
};
