import polygon from 'assets/img/polygon.svg';
import messageIcon from 'assets/img/message.svg';
import rocketIcon from 'assets/img/rocket.svg';
import brainIcon from 'assets/img/brain.svg';
import chartIcon from 'assets/img/chart.svg';

import { ProfessionalProfileType } from 'types/candidate';
import { convertTestResultToProfessionalProfileType } from 'utils/conversion';
import { Modal } from 'components/Modal';
import { useState } from 'react';
import { Container, IconContainer } from './styles';
import { ProfessionalProfileHighlightModal } from './ProfessionalProfileHighlightModal';

interface ProfessionalProfileResult {
  testResult: string;
}

const RESULT_ICONS = {
  [ProfessionalProfileType.COMMUNICATOR]: messageIcon,
  [ProfessionalProfileType.EXECUTOR]: rocketIcon,
  [ProfessionalProfileType.ANALYST]: chartIcon,
  [ProfessionalProfileType.IDEALIZER]: brainIcon,
};

export const ProfessionalProfileResult: React.FC<ProfessionalProfileResult> = ({
  testResult,
}) => {
  const [
    isProfessionalProfileHighlightModal,
    setIsProfessionalProfileHighlightModal,
  ] = useState(false);

  return (
    <Container>
      <Modal isVisible={isProfessionalProfileHighlightModal} notBlockScroll>
        <ProfessionalProfileHighlightModal
          testResult={testResult}
          handleCloseModal={() => setIsProfessionalProfileHighlightModal(false)}
        />
      </Modal>

      <IconContainer>
        <img className="polygon-icon" src={polygon} alt="Polígono" />
        <img
          className="profile-icon"
          src={
            RESULT_ICONS[convertTestResultToProfessionalProfileType(testResult)]
          }
          alt="Ícone de Perfil"
        />
      </IconContainer>

      <span>{testResult}</span>

      <button
        type="button"
        onClick={() => setIsProfessionalProfileHighlightModal(true)}
      >
        Detalhar
      </button>
    </Container>
  );
};
