import { useWindowWidth } from 'hooks/windowWidth';
import { HTMLAttributes, useEffect, useRef, useState } from 'react';
import { getScrollbarWidth } from 'utils/getScrollbarWidth';
import { Container } from './styles';

interface ModalProps extends HTMLAttributes<HTMLDivElement> {
  isVisible: boolean;
  updateData?: unknown;
  notBlockScroll?: boolean;
}

const STYLES_IF_BIGGER_THAN_SCREEN: React.CSSProperties = {
  width: '100%',
  height: 'auto',
  paddingBlock: '5%',
  position: 'absolute',
  alignItems: 'flex-start',
};

export const Modal: React.FC<ModalProps> = ({
  children,
  isVisible,
  updateData,
  notBlockScroll = false,
  ...props
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const { isMobileScreen } = useWindowWidth();
  const [pageExtraStyles, setPageExtraStyles] = useState<React.CSSProperties>(
    {},
  );

  useEffect(() => {
    const { current } = modalRef;
    if (!current) return;

    const { firstElementChild } = current;
    if (!firstElementChild) return;

    const { innerHeight: windowHeight } = window;
    const { scrollHeight: modalHeight } = firstElementChild;
    const {
      body: { scrollHeight },
    } = document;

    setPageExtraStyles(
      modalHeight > windowHeight || notBlockScroll
        ? {
            ...STYLES_IF_BIGGER_THAN_SCREEN,
            paddingTop: isMobileScreen ? 90 : 150,
            minHeight: scrollHeight,
          }
        : {},
    );
  }, [children, isMobileScreen, notBlockScroll, updateData]);

  useEffect(() => {
    const [htmlTag] = document.getElementsByTagName('html');

    if (!isVisible) {
      htmlTag.style.setProperty('overflow-y', 'scroll');
      htmlTag.style.setProperty('padding-right', '0px');
    } else if (!notBlockScroll) {
      htmlTag.style.setProperty('overflow-y', 'hidden');
      htmlTag.style.setProperty('padding-right', `${getScrollbarWidth()}px`);
    } else window.scrollTo({ top: 0, left: 0 });

    setTimeout(() => modalRef.current?.focus(), 100);
    return () => {
      htmlTag.style.setProperty('overflow-y', 'scroll');
      htmlTag.style.setProperty('padding-right', '0px');
    };
  }, [isVisible, notBlockScroll]);

  return (
    <Container
      ref={modalRef}
      isVisible={isVisible}
      style={pageExtraStyles}
      tabIndex={0}
      {...props}
    >
      {children}
    </Container>
  );
};
