import React, { createContext, useContext, useEffect, useState } from 'react';

interface IWindowWidthContext {
  windowWidth: number;
  isMobileScreen: boolean;
}

const windowWidthContext = createContext<IWindowWidthContext>(
  {} as IWindowWidthContext,
);

const WindowWidthContext: React.FC = ({ children }) => {
  const [windowWidth, setWindowWidth] = useState(0);
  const [isMobileScreen, setIsMobileScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const { innerWidth } = window;
      setWindowWidth(innerWidth);
      setIsMobileScreen(innerWidth <= 1100);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <windowWidthContext.Provider value={{ windowWidth, isMobileScreen }}>
      {children}
    </windowWidthContext.Provider>
  );
};

const useWindowWidth = (): IWindowWidthContext =>
  useContext(windowWidthContext);

export { WindowWidthContext, useWindowWidth };
