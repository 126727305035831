import { Bold14Styles, Regular24Styles } from 'assets/fonts/fontStyles';
import styled from 'styled-components';

export const MainContainer = styled.main`
  padding-top: 96px;
  width: 532px;
  margin-inline: auto;

  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (max-width: 1100px) {
    width: 90vw;
    padding-top: 64px;
  }
`;

export const Title = styled.h2`
  ${Regular24Styles}

  @media(max-width: 1100px) {
    font-size: 20px;
  }
`;

export const Text = styled.p`
  ${Regular24Styles}
  font-size: 14px;
`;

export const InstructionContainer = styled.div`
  div {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    gap: 12px;

    img {
      width: 24px;
      height: 24px;
    }

    span {
      ${Bold14Styles}
      line-height: 24px;
    }
  }

  ${Text} {
    margin-left: 36px;
  }

  @media (max-width: 1100px) {
    ${Text} {
      max-width: 288px;
    }
  }
`;
