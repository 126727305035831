import { yupRequiredStringField } from 'utils/validation';
import * as yup from 'yup';
import { CompetenciesStepData } from '.';

export const validateCompetenciesData = async (
  competenciesData: CompetenciesStepData,
): Promise<void> => {
  const schema = yup.object().shape({
    competencies: yup
      .array(
        yup.object().shape({
          name: yupRequiredStringField,
        }),
      )
      .optional(),
  });

  await schema.validate(competenciesData, { abortEarly: false });
};
