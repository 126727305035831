/* eslint-disable react-hooks/rules-of-hooks */
import { FormWithSteps } from 'components/FormWithSteps';
import { FormWithStepsSuccessModal } from 'components/FormWithSteps/FormWithStepsSuccessModal';
import { Modal } from 'components/Modal';
import { ERROR_MODAL_CONFIGURATION, useAlertModal } from 'hooks/alertModal';
import { useStartup } from 'hooks/startup';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { routeDashboard } from 'routes/routesAddresses';
import {
  routeEditVacancy,
  routeVacancyDetails,
} from 'routes/startupsRoutes/startupsRoutesAddresses';
import {
  createVacancy,
  updateVacancy,
} from 'services/entitiesServices/vacancyServices';
import { IServerError } from 'types';
import { IVacancyRequest, IVacancyResponse } from 'types/vacancy';
import { ValidationError } from 'yup';
import {
  VacancyDefinitionStep,
  validateVacancyDefinitionData,
  WorkloadStep,
  RemunerationStep,
  CompetenciesStep,
  SkillsStep,
  LanguagesStep,
  CertificationsStep,
  OtherRequirementsStep,
  FinishStep,
  VacancyAllStepsDataKeyType,
  VacancyAllStepsData,
  InitialVacancyStepsDataType,
  validateWorkloadData,
  validateRemunerationData,
  validateCompetenciesData,
  validateSkillsData,
  validateLanguagesData,
  validateCertificationsData,
  validateOtherRequirementsData,
} from './Steps';
import { Container } from './styles';

interface LocationType {
  state: { vacancy: IVacancyResponse } | undefined;
}

export const CREATE_VACANCY_STEPS = {
  vacancyDefinitionStep: {
    name: 'Definindo a vaga',
    ComponentFunction: VacancyDefinitionStep,
    validationFunction: validateVacancyDefinitionData,
  },
  workloadStep: {
    name: 'Jornada de trabalho',
    ComponentFunction: WorkloadStep,
    validationFunction: validateWorkloadData,
  },
  remunerationStep: {
    name: 'Remuneração',
    ComponentFunction: RemunerationStep,
    validationFunction: validateRemunerationData,
  },
  competenciesStep: {
    name: 'Competências (Opcional)',
    ComponentFunction: CompetenciesStep,
    validationFunction: validateCompetenciesData,
  },
  skillsStep: {
    name: 'Habilidades (Opcional)',
    ComponentFunction: SkillsStep,
    validationFunction: validateSkillsData,
  },
  languagesStep: {
    name: 'Idiomas (Opcional)',
    ComponentFunction: LanguagesStep,
    validationFunction: validateLanguagesData,
  },
  certificationsStep: {
    name: 'Certificações (Opcional)',
    ComponentFunction: CertificationsStep,
    validationFunction: validateCertificationsData,
  },
  otherRequirementsStep: {
    name: 'Outros requisitos (Opcional)',
    ComponentFunction: OtherRequirementsStep,
    validationFunction: validateOtherRequirementsData,
  },
  finishStep: {
    name: 'Finalizar',
    ComponentFunction: FinishStep,
  },
};

const STEPS_KEYS = Object.keys(
  CREATE_VACANCY_STEPS,
) as VacancyAllStepsDataKeyType[];

export const VacancyFormWithStepsTab: React.FC = () => {
  const history = useHistory();
  const isEditRoute = history.location.pathname === routeEditVacancy;

  const { state } = useLocation() as LocationType;
  if (isEditRoute && !state) return <Redirect to={routeDashboard} />;

  const { startup, updateStartupState } = useStartup();
  const { showModal } = useAlertModal();
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [stepsData, setStepsData] = useState<VacancyAllStepsData>(
    {} as VacancyAllStepsData,
  );
  const [selectedStep, setSelectedStep] =
    useState<VacancyAllStepsDataKeyType | null>(null);

  const [vacancyId, setVacancyId] = useState<number | null>(
    state?.vacancy.id ?? null,
  );
  const [isShowingSuccessModal, setIsShowingSuccessModal] = useState(false);
  const [SelectedComponent, setSelectedComponent] =
    useState<ReactElement | null>(null);

  const vacancyToEdit = useMemo(
    () => state?.vacancy ?? ({} as IVacancyResponse),
    [state?.vacancy],
  );

  const convertVacancyToStepsData = useCallback(() => {
    const {
      name,
      description,
      disabledPeople,
      forStudents,
      immediateHiring,
      needExperience,
      vacancyLocation,
      jobType,
      workloadType,
      workSchedule,
      salary,
      salaryToNegotiate,
      benefits,
      paymentType,
      competencies,
      skills,
      languages,
      certifications,
      otherRequirements,
    } = vacancyToEdit;

    return {
      vacancyDefinitionStep: {
        name,
        description,
        disabledPeople,
        forStudents,
        immediateHiring,
        needExperience,
        jobType,
      },
      workloadStep: {
        workloadType,
        vacancyLocation,
        workSchedule,
      },
      remunerationStep: {
        salary,
        salaryToNegotiate,
        benefits,
        paymentType,
      },
      competenciesStep: { competencies },
      skillsStep: { skills },
      languagesStep: { languages },
      certificationsStep: { certifications },
      otherRequirementsStep: { otherRequirements },
    } as VacancyAllStepsData;
  }, [vacancyToEdit]);

  useEffect(() => {
    if (!isEditRoute) {
      setSelectedStep('vacancyDefinitionStep');
      return;
    }

    const parsedStepsData = convertVacancyToStepsData();
    setStepsData(parsedStepsData);
    setSelectedStep('finishStep');
  }, [convertVacancyToStepsData, isEditRoute]);

  const updateStepData = useCallback(
    stepData => {
      if (selectedStep === null) return;

      setStepsData(previousStepsData => ({
        ...previousStepsData,
        [selectedStep]: stepData,
      }));
    },
    [selectedStep],
  );

  const convertStepsDataToVacancy = useCallback(() => {
    const {
      vacancyDefinitionStep,
      workloadStep,
      remunerationStep,
      competenciesStep: { competencies },
      skillsStep: { skills },
      languagesStep: { languages },
      certificationsStep: { certifications },
      otherRequirementsStep: { otherRequirements },
    } = stepsData;

    return {
      ...vacancyDefinitionStep,
      ...workloadStep,
      ...remunerationStep,
      competencies,
      skills,
      languages,
      certifications,
      otherRequirements,
    } as IVacancyRequest;
  }, [stepsData]);

  const handlePublishVacancy = useCallback(async () => {
    try {
      const { id } = await createVacancy(convertStepsDataToVacancy());
      if (!startup?.hasCreatedFirstVacancy) {
        updateStartupState({ ...startup!, hasCreatedFirstVacancy: true });
      }

      setVacancyId(id);
      setIsShowingSuccessModal(true);
    } catch (error) {
      const {
        response: {
          data: { status },
        },
      } = error as IServerError;

      if (status === 403) {
        showModal({
          ...ERROR_MODAL_CONFIGURATION,
          message: 'É necessário ser um assinante para criar outra vaga!',
        });
        return;
      }

      showModal({
        ...ERROR_MODAL_CONFIGURATION,
        message: 'Erro ao criar a vaga, por favor, tente novamente mais tarde',
      });
    }
  }, [convertStepsDataToVacancy, showModal, startup, updateStartupState]);

  const handleUpdateVacancy = useCallback(async () => {
    try {
      const vacancyFromSteps = convertStepsDataToVacancy();

      await updateVacancy(vacancyToEdit.id, vacancyFromSteps);
      setIsShowingSuccessModal(true);
    } catch (err) {
      showModal({
        ...ERROR_MODAL_CONFIGURATION,
        message:
          'Erro ao atualizar a vaga, por favor, tente novamente mais tarde',
      });
    }
  }, [convertStepsDataToVacancy, showModal, vacancyToEdit.id]);

  const handleRedirectToVacancy = useCallback(
    () => history.push(routeVacancyDetails, { vacancyId }),
    [history, vacancyId],
  );

  const handleUpdateSelectedStep = useCallback(
    async (
      updateType: 'throwback' | 'advance',
      selectedStepIndex: number,
      throwbackStepsQuantity = 1,
    ) => {
      if (updateType === 'throwback') {
        if (selectedStepIndex === 0) history.push(routeDashboard);
        else {
          setSelectedStep(
            STEPS_KEYS[selectedStepIndex - throwbackStepsQuantity],
          );
        }

        return;
      }

      if (selectedStep === 'finishStep') {
        setIsLoadingSubmit(true);
        await (isEditRoute ? handleUpdateVacancy : handlePublishVacancy)();
        setIsLoadingSubmit(false);
        return;
      }
      if (selectedStep === null) return;

      const { validationFunction, ComponentFunction } =
        CREATE_VACANCY_STEPS[selectedStep];
      const parsedStepData = stepsData[
        selectedStep
      ] as InitialVacancyStepsDataType;

      try {
        await validationFunction(parsedStepData);
        setSelectedStep(STEPS_KEYS[selectedStepIndex + 1]);
      } catch (error) {
        setSelectedComponent(
          <ComponentFunction
            initialData={parsedStepData}
            setStepDataFunction={updateStepData}
            errors={error as Error | ValidationError}
          />,
        );
      }
    },
    [
      handlePublishVacancy,
      handleUpdateVacancy,
      history,
      isEditRoute,
      selectedStep,
      stepsData,
      updateStepData,
    ],
  );

  useEffect(() => {
    if (selectedStep === null) return;

    const { ComponentFunction } = CREATE_VACANCY_STEPS[selectedStep];
    const isFinishStep = selectedStep === 'finishStep';
    const initialData = isFinishStep ? stepsData : stepsData[selectedStep];

    setSelectedComponent(
      <ComponentFunction
        initialData={initialData as InitialVacancyStepsDataType}
        setStepDataFunction={isFinishStep ? undefined : updateStepData}
      />,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStep, updateStepData]);

  return (
    <Container>
      <Modal isVisible={isShowingSuccessModal}>
        <FormWithStepsSuccessModal
          title={isEditRoute ? 'Vaga atualizada' : 'Sua vaga foi publicada'}
          description={
            isEditRoute
              ? 'Sua vaga foi atualizada e já exibirá as novas informações'
              : 'Está tudo pronto para receber o currículo dos candidatos.'
          }
          redirectButtonText="Abrir vaga"
          handleRedirect={handleRedirectToVacancy}
        />
      </Modal>

      {selectedStep !== null && (
        <FormWithSteps
          STEPS={CREATE_VACANCY_STEPS}
          handleUpdateSelectedStep={handleUpdateSelectedStep}
          headerTitle={isEditRoute ? 'Editar vaga' : 'Criar nova vaga'}
          selectedStep={selectedStep}
          isLoadingSubmit={isLoadingSubmit}
          lastStepAdvanceButtonText={
            isEditRoute ? 'Salvar alterações' : 'Publicar vaga'
          }
        >
          {SelectedComponent}
        </FormWithSteps>
      )}
    </Container>
  );
};
