import {
  BackgroundColor,
  HighEmphasis,
  OutlineColor,
  PrimaryColor,
} from 'assets/colors/palette';
import {
  Medium16Styles,
  Regular14Styles,
  Regular16Styles,
} from 'assets/fonts/fontStyles';
import styled from 'styled-components';

export const Container = styled.div`
  width: 459px;
  outline: 0;

  background-color: ${BackgroundColor};
  border: 1px solid ${OutlineColor};
  border-radius: 8px;
`;

export const TopContainer = styled.div`
  width: 100%;
  height: 60px;

  border-bottom: 1px solid ${OutlineColor};
  padding: 6px 18px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  span,
  button {
    ${Medium16Styles}
    color: ${HighEmphasis};
  }

  button {
    display: flex;
    align-items: center;
    background-color: transparent;
    gap: 6px;

    img {
      width: 25px;
      height: 25px;
    }
  }
`;

export const ShareContainer = styled.div`
  padding: 20px;
  color: ${HighEmphasis};

  & > p {
    ${Regular14Styles}
    margin-bottom: 18px;
  }

  & > span {
    ${Regular16Styles}
    display: block;
    margin-bottom: 12px;
  }
`;

export const SocialNetworksContainer = styled.div`
  display: flex;
  gap: 24px;
  width: 312px;
  margin-bottom: 30px;
`;

export const SocialNetwork = styled.button`
  width: 32px;
  height: 32px;
  background-color: ${OutlineColor};
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s;

  img {
    width: 20px;
    height: 20px;

    transition: 0.2s;
    filter: brightness(0.3);
  }

  &:hover {
    background-color: ${PrimaryColor};

    img {
      filter: brightness(0) invert(1);
    }
  }
`;

export const LinkContainer = styled.div`
  width: 100%;
  height: 48px;

  display: flex;
  align-items: center;
  justify-content: space-evenly;

  border: 1px solid ${OutlineColor};
  border-radius: 8px;

  span {
    ${Regular16Styles}
    color: ${HighEmphasis};

    margin-left: -8px;
    min-width: 336px;
    max-width: 336px;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  button {
    width: 24px;
    height: 24px;
    background-color: transparent;

    img {
      width: 24px;
      height: 24px;
      transition: 0.2s;
    }

    &:hover {
      img {
        width: 26px;
        height: 26px;
      }
    }
  }
`;
