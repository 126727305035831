import { useMemo } from 'react';
import {
  routeStartupVacancies,
  routeTalentBank,
} from 'routes/startupsRoutes/startupsRoutesAddresses';
import { useStartup } from 'hooks/startup';
import { convertNameToSnakeCase } from 'utils/conversion';
import { DashboardHeader } from 'components/DashboardHeader';
import { useAuth } from 'hooks/auth';
import { Container } from './styles';
import { StartupButtons } from './StartupButtons';
import { SubscribeHeader } from './SubscribeHeader';

export const StartupsDashboardHeader: React.FC = () => {
  const { startup } = useStartup();
  const { user } = useAuth();
  const { name: startupName } = startup!;

  const STARTUPS_HEADER_TABS = useMemo(
    () => [
      {
        name: 'Minhas vagas',
        path: routeStartupVacancies,
        toBlankPage: false,
      },
      {
        name: 'Banco de talentos',
        path: routeTalentBank,
        toBlankPage: false,
      },
      {
        name: 'Página de carreira',
        path: `/${convertNameToSnakeCase(startupName ?? '')}`,
        toBlankPage: true,
      },
    ],
    [startupName],
  );

  return (
    <>
      {!user?.subscriber && <SubscribeHeader />}

      <DashboardHeader dashboardType="startups" tabs={STARTUPS_HEADER_TABS}>
        <Container>
          <StartupButtons />
        </Container>
      </DashboardHeader>
    </>
  );
};
