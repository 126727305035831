import {
  BackgroundColor,
  HighEmphasis,
  OutlineColor,
} from 'assets/colors/palette';
import { Medium16Styles } from 'assets/fonts/fontStyles';
import styled, { css } from 'styled-components';

interface OptionButtonProps {
  isDeleteButton?: boolean;
}

export const OptionButton = styled.button<OptionButtonProps>`
  ${Medium16Styles}

  width: 256px;
  height: 48px;

  color: ${HighEmphasis};
  background-color: ${BackgroundColor};
  border: 1px solid ${OutlineColor};
  border-radius: 100px;

  ${({ isDeleteButton }) =>
    isDeleteButton &&
    css`
      border-color: rgba(224, 0, 37, 1);
      color: rgba(224, 0, 37, 1);
    `}
`;
