import { ProfessionalProfileType } from 'types/candidate';

export const translateProfessionalProfileType = (
  professionalProfileType: ProfessionalProfileType,
): string | undefined => {
  switch (professionalProfileType) {
    case ProfessionalProfileType.ANALYST:
      return 'Analista';
    case ProfessionalProfileType.EXECUTOR:
      return 'Executor';
    case ProfessionalProfileType.COMMUNICATOR:
      return 'Comunicador';
    case ProfessionalProfileType.IDEALIZER:
      return 'Idealizador';
    default:
      return undefined;
  }
};
