import { Depth4 } from 'assets/colors/boxShadows';
import {
  BackgroundColor,
  HighEmphasis,
  LowOrange,
  MediumEmphasis,
  OutlineColor,
} from 'assets/colors/palette';
import {
  Medium16Styles,
  Regular14Styles,
  Regular24Styles,
} from 'assets/fonts/fontStyles';
import styled from 'styled-components';

export const Container = styled.div`
  width: 1248px;
  margin-inline: auto;
`;

export const FolderModalContainer = styled.div`
  width: 438px;
  outline: 0;

  background-color: ${BackgroundColor};
  border: 1px solid ${OutlineColor};
  border-radius: 8px;
`;

export const Header = styled.header`
  width: 100%;
  height: 128px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CompanyMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;

  h3 {
    ${Regular24Styles}
    color: ${HighEmphasis};
  }

  span {
    ${Regular14Styles}
    color: ${MediumEmphasis};
  }
`;

export const Main = styled.main`
  padding-block: 5.5% 12%;
`;

export const FolderContainer = styled.div`
  position: relative;
  transition: 0.2s;
  border-radius: 8px;

  &:hover {
    box-shadow: ${Depth4};
  }
`;

export const Folder = styled.button`
  width: 395px;
  height: 176px;

  padding: 20px;
  background: ${BackgroundColor};
  border: 1px solid ${OutlineColor};
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  small {
    ${Regular14Styles}
    color: ${MediumEmphasis};
  }
`;

export const FolderIcon = styled.div`
  width: 48px;
  height: 100%;
  background-color: ${LowOrange};

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  img {
    width: 24px;
    height: 24px;
  }
`;

export const FolderDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;

  text-align: start;
  height: 100%;

  .folder-name {
    ${Medium16Styles}
    color: ${HighEmphasis};
  }

  .folder-creation-date {
    ${Regular14Styles}
    color: ${MediumEmphasis};
  }
`;

export const FolderTopContainer = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  gap: 16px;
`;

export const FoldersContainer = styled.section`
  display: flex;
  gap: 30px;
  flex-flow: wrap;
  position: relative;
`;

export const NoFoldersContainer = styled.div`
  width: 100%;
  padding-top: 6.5%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  p {
    ${Regular14Styles}
    line-height: 24px;
    width: 321px;
    margin-top: 20px;
    text-align: center;
    color: ${MediumEmphasis};
  }
`;

export const CandidatesContainer = styled.div`
  display: flex;

  i {
    border: 1px solid ${BackgroundColor};
  }

  i + i {
    margin-left: -8px;
  }

  i:last-of-type {
    margin-right: -8px;
  }
`;

export const ExtraCandidatesQuantity = styled.div`
  background-color: ${HighEmphasis};
  border: 1px solid ${BackgroundColor};
  border-radius: 8px;

  height: 32px;
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 12px;
  letter-spacing: 0.75px;
  text-align: center;
  font-weight: 700;
  color: ${BackgroundColor};

  &:empty {
    display: none;
  }
`;
