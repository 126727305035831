import { BackgroundColor, HighEmphasis } from 'assets/colors/palette';
import { Medium16Styles, Regular14Styles } from 'assets/fonts/fontStyles';
import { SecondaryButton } from 'components/Buttons';
import styled from 'styled-components';

export const Container = styled.div`
  width: 296px;
  height: 266px;
  padding: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  gap: 12px;
  background: ${BackgroundColor};
  border-radius: 8px;
  text-align: center;
  color: ${HighEmphasis};

  img {
    width: 64px;
    height: 64px;
    margin-bottom: 4px;
  }

  ${SecondaryButton} {
    width: 256px;
    margin-top: 8px;
  }

  #success-message-title {
    ${Medium16Styles}
  }

  #success-message-description {
    ${Regular14Styles}
    line-height: 24px;
  }
`;
