import { HighEmphasis } from 'assets/colors/palette';
import { useMemo } from 'react';
import { AverageEvaluation, Container, PunctuationContainer } from './styles';

interface DefaultTestResultProps {
  candidateName: string;
  candidatePunctuation: number;
  averagePunctuation: number;
}

export const DefaultTestResult: React.FC<DefaultTestResultProps> = ({
  candidateName,
  averagePunctuation,
  candidatePunctuation,
}) => {
  const averageEvaluationMessage = useMemo(() => {
    if (candidatePunctuation > averagePunctuation) {
      return 'Este candidato está acima da média.';
    }

    if (candidatePunctuation === averagePunctuation) {
      return 'Este candidato está na média.';
    }

    return 'Este candidato está abaixo da média.';
  }, [averagePunctuation, candidatePunctuation]);

  return (
    <Container>
      <PunctuationContainer barPercentage={candidatePunctuation}>
        <section>
          <span>{candidateName}</span>
          <span>{candidatePunctuation}</span>
        </section>

        <section className="punctuation-bar" />
      </PunctuationContainer>

      <PunctuationContainer
        barPercentage={averagePunctuation}
        barColor={HighEmphasis}
      >
        <section>
          <span>Média dos demais candidatos</span>
          <span>{averagePunctuation}</span>
        </section>

        <section className="punctuation-bar" />
      </PunctuationContainer>

      <AverageEvaluation>{averageEvaluationMessage}</AverageEvaluation>
    </Container>
  );
};
