import {
  BackgroundColor,
  HighEmphasis,
  OutlineColor,
  PrimaryColor,
} from 'assets/colors/palette';
import { Medium16Styles } from 'assets/fonts/fontStyles';
import styled from 'styled-components';

interface BusinessModelProps {
  isSelected: boolean;
}

export const BusinessModelsContainer = styled.div`
  display: flex;
  gap: 24px;
  flex-flow: wrap;

  width: 500px;
`;

export const BusinessModel = styled.button<BusinessModelProps>`
  width: 238px;
  height: 64px;
  padding: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: ${({ isSelected }) =>
    isSelected ? `2px solid ${PrimaryColor}` : `1px solid ${OutlineColor}`};
  border-radius: 8px;
  background-color: ${BackgroundColor};

  ${Medium16Styles}
  color: ${HighEmphasis};
  transition: ${({ isSelected }) => (isSelected ? 0 : 0.2)}s;

  &:hover {
    border-color: ${PrimaryColor};
  }
`;
