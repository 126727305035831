/* eslint-disable react-hooks/rules-of-hooks */
import in6Logo from 'assets/img/in6Logo.svg';
import { FormWithSteps } from 'components/FormWithSteps';
import { FormWithStepsSuccessModal } from 'components/FormWithSteps/FormWithStepsSuccessModal';
import { Header } from 'components/Header';
import { Modal } from 'components/Modal';
import { ERROR_MODAL_CONFIGURATION, useAlertModal } from 'hooks/alertModal';
import { useAuth } from 'hooks/auth';
import { useCandidate } from 'hooks/candidate';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { routeEditCandidateResume } from 'routes/candidatesRoutes/candidatesRoutesAddresses';
import { routeBar, routeDashboard, routeLogin } from 'routes/routesAddresses';
import { baseURLFrontend } from 'services/baseUrl';
import {
  createCandidate,
  updateCandidate,
} from 'services/entitiesServices/candidateServices';
import { ICandidateRequest } from 'types/candidate/ICandidateRequest';
import { UserAccountType } from 'types/user';
import { useRedirectToEmailSentPageForAccountCreation } from 'utils/customHooks/redirects';
import { ValidationError } from 'yup';
import {
  AcademicEducationsStep,
  CandidateAllStepsData,
  CandidateAllStepsDataKeyType,
  CertificationsStep,
  CompetenciesStep,
  FinishStep,
  InitialCandidateStepsDataType,
  LanguagesStep,
  PersonalInformationStep,
  ProfessionalExperiencesStep,
  SkillsStep,
  validateAcademicEducationsData,
  validateCertificationsData,
  validateCompetenciesData,
  validateLanguagesData,
  validatePersonalInformationData,
  validateProfessionalExperiencesData,
  validateSkillsData,
} from './Steps';
import { Container } from './styles';

export const CREATE_CANDIDATE_STEPS = {
  personalInformationStep: {
    name: 'Informações pessoais',
    ComponentFunction: PersonalInformationStep,
    validationFunction: validatePersonalInformationData,
  },
  professionalExperiencesStep: {
    name: 'Experiência profissional',
    ComponentFunction: ProfessionalExperiencesStep,
    validationFunction: validateProfessionalExperiencesData,
  },
  academicEducationsStep: {
    name: 'Formação acadêmica',
    ComponentFunction: AcademicEducationsStep,
    validationFunction: validateAcademicEducationsData,
  },
  competenciesStep: {
    name: 'Competências (Opcional)',
    ComponentFunction: CompetenciesStep,
    validationFunction: validateCompetenciesData,
  },
  skillsStep: {
    name: 'Habilidades (Opcional)',
    ComponentFunction: SkillsStep,
    validationFunction: validateSkillsData,
  },
  languagesStep: {
    name: 'Idiomas (Opcional)',
    ComponentFunction: LanguagesStep,
    validationFunction: validateLanguagesData,
  },
  certificationsStep: {
    name: 'Certificações (Opcional)',
    ComponentFunction: CertificationsStep,
    validationFunction: validateCertificationsData,
  },
  finishStep: {
    name: 'Revisar',
    ComponentFunction: FinishStep,
  },
};

const STEPS_KEYS = Object.keys(
  CREATE_CANDIDATE_STEPS,
) as CandidateAllStepsDataKeyType[];

export const CandidateFormWithStepsTab: React.FC = () => {
  const history = useHistory();
  const { candidate: candidateToEdit, updateCandidateState } = useCandidate();
  const isEditRoute = history.location.pathname === routeEditCandidateResume;

  if (isEditRoute && candidateToEdit === undefined) {
    return <Redirect to={routeDashboard} />;
  }

  const { showModal } = useAlertModal();
  const { signOut } = useAuth();
  const redirectToEmailSentPage = isEditRoute
    ? null
    : useRedirectToEmailSentPageForAccountCreation(
        'activation',
        UserAccountType.EMPLOYER,
      );

  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [stepsData, setStepsData] = useState<CandidateAllStepsData>(
    {} as CandidateAllStepsData,
  );
  const [selectedStep, setSelectedStep] =
    useState<CandidateAllStepsDataKeyType | null>(null);
  const [isShowingSuccessModal, setIsShowingSuccessModal] = useState(false);
  const [SelectedComponent, setSelectedComponent] =
    useState<ReactElement | null>(null);

  const convertCandidateToStepsData = useCallback(() => {
    const {
      academicEducations,
      birthDate,
      certifications,
      city,
      competencies,
      hasProfessionalExperience,
      languages,
      linkedin,
      phoneNumber,
      photo,
      profession,
      professionalExperiences,
      skills,
      state,
    } = candidateToEdit!;

    const personalInformationStep = {
      birthDate,
      profession,
      state,
      city,
      phoneNumber,
      linkedin,
      photo,
    };

    const professionalExperiencesStep = {
      hasProfessionalExperience,
      professionalExperiences,
    };
    const academicEducationsStep = { academicEducations };
    const competenciesStep = { competencies };
    const skillsStep = { skills };
    const languagesStep = { languages };
    const certificationsStep = { certifications };

    return {
      personalInformationStep,
      professionalExperiencesStep,
      academicEducationsStep,
      competenciesStep,
      skillsStep,
      languagesStep,
      certificationsStep,
    } as CandidateAllStepsData;
  }, [candidateToEdit]);

  useEffect(() => {
    if (!isEditRoute) {
      setSelectedStep('personalInformationStep');
      return;
    }

    const parsedStepsData = convertCandidateToStepsData();
    setStepsData(parsedStepsData);
    setSelectedStep('finishStep');
  }, [convertCandidateToStepsData, isEditRoute]);

  const updateStepData = useCallback(
    stepData => {
      if (selectedStep === null) return;

      setStepsData(previousStepsData => ({
        ...previousStepsData,
        [selectedStep]: stepData,
      }));
    },
    [selectedStep],
  );

  const convertStepsDataToCandidate = useCallback(() => {
    const {
      personalInformationStep,
      professionalExperiencesStep: {
        professionalExperiences,
        hasProfessionalExperience,
      },
      academicEducationsStep: { academicEducations },
      competenciesStep: { competencies },
      certificationsStep: { certifications },
      languagesStep: { languages },
      skillsStep: { skills },
    } = stepsData;

    return {
      ...personalInformationStep,
      professionalExperiences,
      hasProfessionalExperience,
      academicEducations,
      competencies,
      certifications,
      languages,
      skills,
    } as ICandidateRequest;
  }, [stepsData]);

  const handleCreateCandidate = useCallback(async () => {
    try {
      const candidateFromSteps = convertStepsDataToCandidate();
      await createCandidate(candidateFromSteps);
      if (redirectToEmailSentPage) redirectToEmailSentPage();
    } catch (err) {
      setIsLoadingSubmit(false);
      showModal({
        ...ERROR_MODAL_CONFIGURATION,
        message:
          'Erro ao criar o candidato, por favor, tente novamente mais tarde',
      });
    }
  }, [convertStepsDataToCandidate, redirectToEmailSentPage, showModal]);

  const handleUpdateCandidate = useCallback(async () => {
    try {
      const candidateFromSteps = convertStepsDataToCandidate();
      const updatedCandidate = await updateCandidate(candidateFromSteps);

      updateCandidateState(updatedCandidate);
      setIsShowingSuccessModal(true);
    } catch (err) {
      showModal({
        ...ERROR_MODAL_CONFIGURATION,
        message:
          'Erro ao atualizar o candidato, por favor, tente novamente mais tarde',
      });
    } finally {
      setIsLoadingSubmit(false);
    }
  }, [convertStepsDataToCandidate, showModal, updateCandidateState]);

  const handleThrowbackStep = useCallback(
    (selectedStepIndex: number, throwbackStepsQuantity: number) => {
      if (selectedStepIndex !== 0) {
        setSelectedStep(STEPS_KEYS[selectedStepIndex - throwbackStepsQuantity]);
        return;
      }

      if (isEditRoute) history.push(routeDashboard);
      else {
        signOut();
        window.location.replace(baseURLFrontend + routeLogin);
      }
    },
    [history, isEditRoute, signOut],
  );

  const handleUpdateSelectedStep = useCallback(
    async (
      updateType: 'throwback' | 'advance',
      selectedStepIndex: number,
      throwbackStepsQuantity = 1,
    ) => {
      if (updateType === 'throwback') {
        handleThrowbackStep(selectedStepIndex, throwbackStepsQuantity);
        return;
      }

      if (selectedStep === 'finishStep') {
        setIsLoadingSubmit(true);
        await (isEditRoute ? handleUpdateCandidate : handleCreateCandidate)();
        return;
      }
      if (selectedStep === null) return;

      const { validationFunction, ComponentFunction } =
        CREATE_CANDIDATE_STEPS[selectedStep];
      const parsedStepData = stepsData[
        selectedStep
      ] as InitialCandidateStepsDataType;

      try {
        await validationFunction(parsedStepData);
        setSelectedStep(STEPS_KEYS[selectedStepIndex + 1]);
      } catch (error) {
        setSelectedComponent(
          <ComponentFunction
            initialData={parsedStepData}
            setStepDataFunction={updateStepData}
            errors={error as Error | ValidationError}
          />,
        );
      }
    },
    [
      handleCreateCandidate,
      handleThrowbackStep,
      handleUpdateCandidate,
      isEditRoute,
      selectedStep,
      stepsData,
      updateStepData,
    ],
  );

  useEffect(() => {
    if (selectedStep === null) return;

    const { ComponentFunction } = CREATE_CANDIDATE_STEPS[selectedStep];
    const isFinishStep = selectedStep === 'finishStep';
    const initialData = isFinishStep ? stepsData : stepsData[selectedStep];

    setSelectedComponent(
      <ComponentFunction
        initialData={initialData as InitialCandidateStepsDataType}
        setStepDataFunction={isFinishStep ? undefined : updateStepData}
      />,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStep, updateStepData]);

  return (
    <>
      {!isEditRoute && (
        <Header
          style={{ position: 'fixed' }}
          logo={in6Logo}
          redirectRoute={routeBar}
        />
      )}

      <Container>
        <Modal isVisible={isShowingSuccessModal}>
          <FormWithStepsSuccessModal
            title="Candidato atualizado"
            description="Seu currículo foi atualizado e já exibirá as novas informações"
            redirectButtonText="Ir para minhas vagas"
            handleRedirect={() => history.push(routeDashboard)}
          />
        </Modal>

        {selectedStep !== null && (
          <FormWithSteps
            STEPS={CREATE_CANDIDATE_STEPS}
            handleUpdateSelectedStep={handleUpdateSelectedStep}
            headerTitle={
              isEditRoute ? 'Editar currículo' : 'Cadastrar currículo'
            }
            selectedStep={selectedStep}
            isLoadingSubmit={isLoadingSubmit}
            lastStepAdvanceButtonText={
              isEditRoute ? 'Salvar alterações' : 'Criar'
            }
          >
            {SelectedComponent}
          </FormWithSteps>
        )}
      </Container>
    </>
  );
};
