/* eslint-disable react-hooks/rules-of-hooks */
import { Loader } from 'components/Loader';
import { useAuth } from 'hooks/auth';
import { useEffect } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import {
  routeAdminDashboard,
  routeBar,
  routeDashboard,
} from 'routes/routesAddresses';
import { findOneUserById } from 'services/entitiesServices/userServices';
import { UserAccountType } from 'types/user';

export const ConfirmAuth: React.FC = () => {
  const history = useHistory();
  const { setAuthenticatedUserLocalData } = useAuth();

  const params = useParams() as
    | { userId: number; authenticationToken: string; keepConnected: string }
    | undefined;
  if (!params) return <Redirect to={routeBar} />;

  useEffect(() => {
    const { authenticationToken, userId, keepConnected } = params;

    findOneUserById(userId)
      .then(user => {
        if (!user) return;

        setAuthenticatedUserLocalData(
          user,
          authenticationToken,
          keepConnected === 'true',
        );

        if (user.accountType === UserAccountType.ADMIN)
          history.push(routeAdminDashboard);
        else history.push(routeDashboard);
      })
      .catch(() => history.push(routeBar));
  }, [history, params, setAuthenticatedUserLocalData]);

  return <Loader />;
};
