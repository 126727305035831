import notEvaluatedIcon from 'assets/img/notEvaluated.svg';
import positiveIcon from 'assets/img/positive.svg';
import negativeIcon from 'assets/img/negative.svg';
import favoriteIcon from 'assets/img/favorite.svg';
import lovedIcon from 'assets/img/loved.svg';

import { useState } from 'react';
import { translateCandidateEvaluation } from 'utils/conversion';
import { CandidateEvaluation } from 'types/CandidateEvaluation';
import {
  Container,
  EvaluationOptionsContainer,
  EvaluationOption,
  OpenEvaluationOptionsButton,
} from './styles';

interface CandidateEvaluationContainerProps {
  candidateEvaluation: CandidateEvaluation;
  handleUpdateCandidateEvaluation: (
    updatedEvaluation: CandidateEvaluation,
  ) => void;
}

export const EVALUATION_OPTIONS = {
  [CandidateEvaluation.NEW_CANDIDATE]: {
    name: '',
    icon: notEvaluatedIcon,
  },
  [CandidateEvaluation.NOT_EVALUATED]: {
    name: '',
    icon: notEvaluatedIcon,
  },
  [CandidateEvaluation.OUT_OF_EVALUATION]: {
    name: '',
    icon: notEvaluatedIcon,
  },
  [CandidateEvaluation.POSITIVE]: {
    name: translateCandidateEvaluation(CandidateEvaluation.POSITIVE),
    icon: positiveIcon,
  },
  [CandidateEvaluation.NEGATIVE]: {
    name: translateCandidateEvaluation(CandidateEvaluation.NEGATIVE),
    icon: negativeIcon,
  },
  [CandidateEvaluation.FAVORITE]: {
    name: translateCandidateEvaluation(CandidateEvaluation.FAVORITE),
    icon: favoriteIcon,
  },
  [CandidateEvaluation.LOVED]: {
    name: translateCandidateEvaluation(CandidateEvaluation.LOVED),
    icon: lovedIcon,
  },
};

export const CandidateEvaluationContainer: React.FC<
  CandidateEvaluationContainerProps
> = ({ candidateEvaluation, handleUpdateCandidateEvaluation }) => {
  const [evaluationOptionsIsVisible, setEvaluationOptionsIsVisible] =
    useState(false);

  return (
    <Container onMouseLeave={() => setEvaluationOptionsIsVisible(false)}>
      <EvaluationOptionsContainer isVisible={evaluationOptionsIsVisible}>
        {Object.entries(EVALUATION_OPTIONS).map(
          ([key, { name, icon }]) =>
            name && (
              <EvaluationOption
                key={key}
                title={name}
                type="button"
                onClick={() =>
                  handleUpdateCandidateEvaluation(key as CandidateEvaluation)
                }
              >
                <img src={icon} alt={key} />
              </EvaluationOption>
            ),
        )}
      </EvaluationOptionsContainer>
      <OpenEvaluationOptionsButton
        type="button"
        onClick={() =>
          handleUpdateCandidateEvaluation(
            candidateEvaluation !== CandidateEvaluation.NOT_EVALUATED
              ? CandidateEvaluation.NOT_EVALUATED
              : CandidateEvaluation.POSITIVE,
          )
        }
        onMouseOver={() => setEvaluationOptionsIsVisible(true)}
      >
        <img
          src={EVALUATION_OPTIONS[candidateEvaluation].icon}
          alt="Avaliação"
        />
      </OpenEvaluationOptionsButton>
    </Container>
  );
};
