import thinPlusIcon from 'assets/img/thinPlus.svg';
import correctIcon from 'assets/img/correct.svg';

import { CheckboxInput } from 'components/Input/CheckboxInput';
import { Loader } from 'components/Loader';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useInputStates } from 'utils/customHooks';
import { OptionButton } from 'components/Buttons';
import { ERROR_MODAL_CONFIGURATION, useAlertModal } from 'hooks/alertModal';
import { HighGreen } from 'assets/colors/palette';
import { FolderModal } from 'pages/Dashboards/StartupsDashboard/FolderModal';
import { IStartupCandidatesFolderBasicData } from 'types/startupCandidatesFolder/IStartupCandidatesFolderBasicData';
import {
  CheckboxesContainer,
  Container,
  FoldersContainer,
  TopContainer,
} from './styles';

interface FileResumeModalProps {
  startupFolders: IStartupCandidatesFolderBasicData[] | null;
  handleCreateStartupFolder: (folderName: string) => Promise<void>;
  handleCreateFiledStartupCandidate: (
    startupFoldersIds: number[],
  ) => Promise<void>;
}

export const FileResumeModal: React.FC<FileResumeModalProps> = ({
  startupFolders,
  handleCreateStartupFolder,
  handleCreateFiledStartupCandidate,
}) => {
  const fileResumeModalRef = useRef<HTMLDivElement>(null);
  const { showModal } = useAlertModal();

  const [
    isLoadingFiledVacancyCandidateCreation,
    setIsLoadingFiledVacancyCandidateCreation,
  ] = useState(false);
  const [isLoadingStartupFolderCreation, setIsLoadingStartupFolderCreation] =
    useState(false);
  const [isNewFolderModalSelected, setIsNewFolderModalSelected] =
    useState(false);

  const folderNameStates = useInputStates('folderName');
  const [selectedFoldersIds, setSelectedFoldersIds] = useState<number[]>([]);

  useEffect(() => {
    fileResumeModalRef.current?.focus();
  }, [isNewFolderModalSelected]);

  const handleCreateStartupFolderButtonClick = useCallback(async () => {
    try {
      setIsLoadingStartupFolderCreation(true);
      await handleCreateStartupFolder(
        folderNameStates.mainState.value as string,
      );

      setIsNewFolderModalSelected(false);
      folderNameStates.mainState.setFunction('');
    } catch {
      showModal({
        ...ERROR_MODAL_CONFIGURATION,
        message: 'Erro ao criar pasta, por favor, tente novamente mais tarde',
      });
    } finally {
      setIsLoadingStartupFolderCreation(false);
    }
  }, [folderNameStates.mainState, handleCreateStartupFolder, showModal]);

  const handleCheckBoxUpdate = useCallback(
    (checked: boolean, folderId: number) => {
      if (checked) {
        setSelectedFoldersIds(previousIds => [...previousIds, folderId]);
        return;
      }

      setSelectedFoldersIds(previousIds =>
        previousIds.filter(previousId => previousId !== folderId),
      );
    },
    [],
  );

  const getFileResumeMessage = useCallback(() => {
    const initialMessage = 'Currículo arquivado';

    if (selectedFoldersIds.length > 1) return `${initialMessage} com sucesso`;

    const findedStartupFolderName = startupFolders!.find(
      ({ id }) => id === selectedFoldersIds[0],
    )!.name;
    return `${initialMessage} em ${findedStartupFolderName}`;
  }, [selectedFoldersIds, startupFolders]);

  const handleFileResumeButtonClick = useCallback(async () => {
    if (selectedFoldersIds.length === 0) {
      showModal({
        ...ERROR_MODAL_CONFIGURATION,
        message: 'Nenhuma pasta selecionada',
      });
      return;
    }

    try {
      setIsLoadingFiledVacancyCandidateCreation(true);
      await handleCreateFiledStartupCandidate(selectedFoldersIds);

      showModal({
        message: getFileResumeMessage(),
        backgroundColor: HighGreen,
        icon: correctIcon,
        marginLeft: -448,
      });
    } catch {
      setIsLoadingFiledVacancyCandidateCreation(false);
      showModal({
        ...ERROR_MODAL_CONFIGURATION,
        message:
          'Erro ao arquivar candidato, por favor, tente novamente mais tarde',
      });
    }
  }, [
    getFileResumeMessage,
    handleCreateFiledStartupCandidate,
    selectedFoldersIds,
    showModal,
  ]);

  return (
    <Container
      tabIndex={0}
      ref={fileResumeModalRef}
      onClick={event => event.stopPropagation()}
    >
      {isNewFolderModalSelected ? (
        <FolderModal
          isLoading={isLoadingStartupFolderCreation}
          folderNameStates={folderNameStates}
          handleCancelOperation={() => setIsNewFolderModalSelected(false)}
          handleExecuteFolderOperation={handleCreateStartupFolderButtonClick}
        />
      ) : (
        <>
          <TopContainer>
            <span>Arquivar currículo em...</span>

            <button
              type="button"
              onClick={() => setIsNewFolderModalSelected(true)}
            >
              <img src={thinPlusIcon} alt="Sinal de mais" />
              Nova pasta
            </button>
          </TopContainer>

          {startupFolders === null || isLoadingFiledVacancyCandidateCreation ? (
            <Loader
              style={{
                minHeight: 0,
                padding: 58.5,
                background: 'transparent',
                marginTop: 100,
              }}
            />
          ) : (
            <FoldersContainer>
              {startupFolders.length > 0 && (
                <>
                  <CheckboxesContainer>
                    {startupFolders.map(({ id, name }) => (
                      <CheckboxInput
                        key={id}
                        label={name}
                        handleCheckboxUpdate={checked =>
                          handleCheckBoxUpdate(checked, id)
                        }
                      />
                    ))}
                  </CheckboxesContainer>

                  <OptionButton
                    type="button"
                    onClick={handleFileResumeButtonClick}
                  >
                    Arquivar currículo
                  </OptionButton>
                </>
              )}
            </FoldersContainer>
          )}
        </>
      )}
    </Container>
  );
};
