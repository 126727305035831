import { TestInitialScreen } from '../../TestInitialScreen';

export const PortugueseInitialScreen: React.FC = () => (
  <TestInitialScreen
    title="Teste de Português"
    mainText="O Teste de Português visa avaliar sua capacidade de entender diferentes tipos de textos e interpretar as mensagens escritas; refletir sobre o que leu em diferentes canais de comunicação e formatos de textos; e de se comunicar de maneira clara e de fácil compreensão."
    instructions={[
      'O teste é composto por 12 perguntas. Para cada uma delas você deve marcar corretamente apenas uma opção.',
      '30 minutos. Faça o teste sem interrupções ou pausas.',
      'Não é possível refazer esse teste depois de concluído. O resultado desta avaliação será disponibilizado para as empresas quando você se candidatar para uma vaga.',
    ]}
  />
);
