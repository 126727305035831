import { ErrorDefaut, GrayLine, PrimaryColor } from 'assets/colors/palette';
import { InputHTMLAttributes, useCallback, useRef } from 'react';
import { ReactInputState } from 'types/reactInputState';
import { InputErrorMessageStyle, InputNameStyle, InputStyle } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  states: ReactInputState;
  notHasError?: boolean;
  isOptional?: boolean;
  notScrollToInput?: boolean;
}

export const Input: React.FC<InputProps> = ({
  name,
  states: {
    mainState: { setFunction: mainFunction, value: mainValue },
    errorMessageState: { setFunction: errorFunction, value: errorValue },
  },
  disabled,
  style,
  notHasError,
  isOptional,
  notScrollToInput,
  ...props
}) => {
  const defaultStyles = { width: style?.width ?? 400, maxWidth: '100%' };
  const inputContainerRef = useRef<HTMLDivElement>(null);

  const handleOnFocus = useCallback(() => {
    if (notScrollToInput) return;

    const { current } = inputContainerRef;
    if (!current) return;

    current.scrollIntoView();
  }, [notScrollToInput]);

  const handleOnChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      mainFunction(value);
      if (errorValue) errorFunction('');
    },
    [errorFunction, errorValue, mainFunction],
  );

  return (
    <div ref={inputContainerRef} className="input" style={defaultStyles}>
      <InputNameStyle disabled={disabled}>
        {name} {!!name && isOptional && <span>(Opcional)</span>}
      </InputNameStyle>
      <InputStyle
        className="input"
        borderColor={errorValue ? ErrorDefaut : GrayLine}
        hoverAndFocusColor={errorValue ? ErrorDefaut : PrimaryColor}
        autoComplete="off"
        onFocus={handleOnFocus}
        onChange={handleOnChange}
        value={mainValue as string}
        disabled={disabled}
        style={style}
        {...props}
      />

      {!notHasError && (
        <InputErrorMessageStyle style={defaultStyles} className="error-message">
          {errorValue}
        </InputErrorMessageStyle>
      )}
    </div>
  );
};
