import styled from 'styled-components';
import {
  BackgroundColor,
  HighEmphasis,
  MediumEmphasis,
  OutlineColor,
  PrimaryColor,
} from 'assets/colors/palette';
import { Medium16Styles, Regular16Styles } from 'assets/fonts/fontStyles';

export const Container = styled.div``;

export const SkillInputContainer = styled.div`
  width: 500px;
  min-height: 144px;
  padding: 16px;

  display: flex;
  flex-direction: column;

  gap: 10px;
  background: ${BackgroundColor};
  border: 1px solid ${OutlineColor};
  border-radius: 8px;
`;

export const Skill = styled.div`
  width: fit-content;
  padding: 12px 19px;
  min-height: 48px;
  height: max-content;
  background: ${BackgroundColor};
  border: 1px solid ${PrimaryColor};
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  span {
    ${Medium16Styles}
    color: ${PrimaryColor};
  }

  button {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;

    &:hover {
      transform: rotate(90deg);
    }

    img {
      width: 16px;
      height: 16px;
    }
  }
`;

export const SkillTextArea = styled.textarea`
  ${Regular16Styles}
  line-height: 28px;

  display: block;
  width: 100%;
  height: auto;
  background: ${BackgroundColor};
  color: ${HighEmphasis};

  resize: none;
  outline: none;
  overflow: hidden;

  &::placeholder {
    color: ${OutlineColor};
  }
`;

export const TextAreaContainer = styled.div`
  ${Regular16Styles}
  line-height: 28px;

  display: grid;
  min-height: 54px;

  &::after {
    content: attr(data-replicated-value) ' ';
    white-space: pre-wrap;
    word-wrap: break-word;
    visibility: hidden;

    max-width: 100%;
    outline: none;
  }

  & > ${SkillTextArea}, &::after {
    grid-area: 1 / 1 / 2 / 2;
  }
`;

export const SkillTextAreaDescription = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;

  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.75px;

  .input-description {
    color: ${MediumEmphasis};
  }

  .characters-counter {
    color: ${HighEmphasis};
  }
`;
