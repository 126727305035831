import { BackgroundColor, GrayLine, HighEmphasis } from 'assets/colors/palette';
import { SecondaryButton } from 'components/Buttons';
import styled from 'styled-components';

export const HeaderMainContainer = styled.div`
  height: 64px;
  width: 100%;
  background: ${BackgroundColor};
  border-bottom: solid 1px ${GrayLine};

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeaderSubContainer = styled.div`
  width: 1248px;
  height: 100%;
  margin-inline: auto;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .buttons-container {
    display: flex;
    align-items: center;
    gap: 20px;

    ${SecondaryButton} {
      background: transparent;
      background-clip: initial;
      -webkit-background-clip: initial;
      -webkit-text-fill-color: ${HighEmphasis};
      display: flex;
      align-items: center;
      height: 48px;
      gap: 8px;
    }
  }

  @media (max-width: 1100px) {
    width: 90vw;
  }
`;
