/* eslint-disable react-hooks/rules-of-hooks */

import { Redirect, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { Loader } from 'components/Loader';
import { IVacancyResponse, VacancyStatus } from 'types/vacancy';
import { findOneVacancyById } from 'services/entitiesServices/vacancyServices';
import { routeBar, routeVacancyUnavailable } from 'routes/routesAddresses';
import { CandidatesFreeRoutesHeader } from 'components/CandidatesFreeRoutesHeader';
import { useAuth } from 'hooks/auth';
import { UserAccountType } from 'types/user';
import { routeVacancyApply } from 'routes/candidatesRoutes/candidatesRoutesAddresses';
import { PrimaryButton } from 'components/Buttons';
import { Modal } from 'components/Modal';
import {
  createVacancyCandidate,
  findOneVacancyCandidateByCandidateAndVacancyIds,
} from 'services/entitiesServices/vacancyCandidateServices';
import { useCandidate } from 'hooks/candidate';
import { VacancyHighlightContainer } from 'components/VacancyHighlightContainer';
import { Container, ApplyFooter } from './styles';
import { ApplyModalWithAccount } from './ApplyModalWithAccount';
import { ApplyModalWithoutAccount } from './ApplyModalWithoutAccount';

export const VacancyApply: React.FC = () => {
  const { vacancyId } = useParams() as { vacancyId: string };
  if (!vacancyId) return <Redirect to={routeBar} />;

  const {
    location: { pathname },
  } = window;
  const isDashboardRoute = pathname.startsWith('/dashboard');

  const { user } = useAuth();
  if (user?.accountType === UserAccountType.CANDIDATE && !isDashboardRoute) {
    return <Redirect to={`${routeVacancyApply}/${vacancyId}`} />;
  }

  const { candidate } = useCandidate();
  const [alreadyAppliedToThisVacancy, setAlreadyAppliedToThisVacancy] =
    useState(false);

  const [applyModalIsVisible, setApplyModalIsVisible] = useState(false);
  const [vacancy, setVacancy] = useState<IVacancyResponse>(
    {} as IVacancyResponse,
  );
  const [isLoadingVacancy, setIsLoadingVacancy] = useState(true);
  const [isLoadingCandidacy, setIsLoadingCandidacy] = useState(false);

  useEffect(() => {
    findOneVacancyById(Number(vacancyId) - 1000)
      .then(findedVacancy => {
        if (!findedVacancy) return;

        setVacancy(findedVacancy);
        document.title = `${findedVacancy.startup.name} | ${findedVacancy.name}`;
      })
      .finally(() => setIsLoadingVacancy(false));
  }, [vacancyId]);

  useEffect(() => {
    if (!candidate || !vacancy.id) return;

    findOneVacancyCandidateByCandidateAndVacancyIds(
      candidate.id,
      vacancy.id,
    ).then(
      findedVacancyCandidate =>
        !!findedVacancyCandidate && setAlreadyAppliedToThisVacancy(true),
    );
  }, [candidate, vacancy.id]);

  const handleApplyToVacancy = useCallback(async () => {
    window.scrollTo({ top: 0, left: 0 });

    setIsLoadingCandidacy(true);
    await createVacancyCandidate({ vacancyId: Number(vacancy.id) });
  }, [vacancy.id]);

  const handleCloseModal = useCallback(() => {
    setApplyModalIsVisible(false);
    setIsLoadingCandidacy(false);
  }, []);

  if (!isLoadingVacancy) {
    if (!vacancy.id) return <Redirect to={routeBar} />;
    if (vacancy.vacancyStatus !== VacancyStatus.ACTIVE) {
      return <Redirect to={routeVacancyUnavailable} />;
    }
  }

  return (
    <>
      {!isDashboardRoute && <CandidatesFreeRoutesHeader />}

      {isLoadingVacancy ? (
        <Loader />
      ) : (
        <Container>
          <Modal
            isVisible={applyModalIsVisible}
            updateData={isLoadingCandidacy}
            notBlockScroll={isDashboardRoute}
          >
            {isDashboardRoute ? (
              <ApplyModalWithAccount
                vacancyStartupName={vacancy.startup.name}
                handleCloseModal={handleCloseModal}
                isLoadingCandidacy={isLoadingCandidacy}
                handleApplyToVacancy={handleApplyToVacancy}
              />
            ) : (
              <ApplyModalWithoutAccount handleCloseModal={handleCloseModal} />
            )}
          </Modal>

          <VacancyHighlightContainer vacancy={vacancy} style={{ padding: 0 }} />
        </Container>
      )}

      <ApplyFooter>
        <PrimaryButton
          type="button"
          disabled={isLoadingVacancy || alreadyAppliedToThisVacancy}
          onClick={() => setApplyModalIsVisible(true)}
        >
          {alreadyAppliedToThisVacancy
            ? 'Você já se candidatou nesta vaga'
            : 'Candidatar-se para a vaga'}
        </PrimaryButton>
      </ApplyFooter>
    </>
  );
};
