import simpleArrowRight from 'assets/img/simpleArrowRight.svg';

import {
  PrimaryButton,
  SecondaryButton,
  SimpleButton,
} from 'components/Buttons';
import { useWindowWidth } from 'hooks/windowWidth';
import { NextButtonContent } from './NextButtonContent';
import { Container, SubContainer } from './styles';

interface FormWithStepsFooterProps {
  selectedStepIndex: number;
  stepsLength: number;
  lastStepAdvanceButtonText: string;
  isLoadingSubmit: boolean;
  handleUpdateSelectedStep: (
    updateType: 'throwback' | 'advance',
    selectedStepIndex: number,
  ) => void;
}

export const FormWithStepsFooter: React.FC<FormWithStepsFooterProps> = ({
  selectedStepIndex,
  stepsLength,
  lastStepAdvanceButtonText,
  handleUpdateSelectedStep,
  isLoadingSubmit,
}) => {
  const { isMobileScreen } = useWindowWidth();

  return (
    <Container barPercentage={(selectedStepIndex / (stepsLength - 1)) * 100}>
      <SubContainer>
        {isMobileScreen ? (
          <SecondaryButton
            type="button"
            onClick={() =>
              handleUpdateSelectedStep('throwback', selectedStepIndex)
            }
          >
            <img src={simpleArrowRight} alt="Seta para esquerda" />
          </SecondaryButton>
        ) : (
          <SimpleButton
            onClick={() =>
              handleUpdateSelectedStep('throwback', selectedStepIndex)
            }
          >
            {selectedStepIndex === 0 ? 'Cancelar' : 'Anterior'}
          </SimpleButton>
        )}

        <span>
          {selectedStepIndex + 1} de {stepsLength}
        </span>

        <PrimaryButton
          type="button"
          onClick={() => handleUpdateSelectedStep('advance', selectedStepIndex)}
          disabled={isLoadingSubmit}
        >
          <NextButtonContent
            isNotLastStep={selectedStepIndex < stepsLength - 1}
            isLoadingSubmit={isLoadingSubmit}
            lastStepAdvanceButtonText={lastStepAdvanceButtonText}
          />
        </PrimaryButton>
      </SubContainer>
    </Container>
  );
};
