import { OptionTypeBase } from 'react-select';

export const closingReasonOptions: OptionTypeBase[] = [
  {
    id: 1,
    label: 'Escolha do(s) novo(s) colaborador(es) concluída',
  },
  {
    id: 2,
    label: 'Aumento de quadro ou substituição adiada',
  },
  {
    id: 3,
    label: 'Cancelamento solicitado pelo gestor responsável',
  },
  {
    id: 4,
    label: 'Movimentação interna',
  },
  {
    id: 5,
    label: 'Reestruturação organizacional',
  },
  {
    id: 6,
    label: 'Revisão orçamentária',
  },
];
