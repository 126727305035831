import { CheckboxInput } from 'components/Input/CheckboxInput';
import { useCallback } from 'react';
import { FilterColumnOption } from '..';
import { Container, ColumnTitle, CheckboxesContainer } from './styles';

interface FilterColumnProps {
  columnTitle: string;
  filterColumnOptions: FilterColumnOption[];
  filteredOptions: (number | string)[];
  updateFilterOptionValue: (id: number | string, toRemove: boolean) => void;
}

export const FilterColumn: React.FC<FilterColumnProps> = ({
  columnTitle,
  filterColumnOptions,
  filteredOptions,
  updateFilterOptionValue,
}) => {
  const optionIsChecked = useCallback(
    (optionId: number | string) =>
      filteredOptions.findIndex(id => id === optionId) !== -1,
    [filteredOptions],
  );

  return (
    <Container>
      <ColumnTitle>{columnTitle}</ColumnTitle>

      <CheckboxesContainer>
        {filterColumnOptions.map(({ id, label }) => (
          <CheckboxInput
            key={id}
            label={label}
            checked={optionIsChecked(id)}
            handleCheckboxUpdate={checked =>
              updateFilterOptionValue(id, !checked)
            }
          />
        ))}
      </CheckboxesContainer>
    </Container>
  );
};
