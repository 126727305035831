import * as yup from 'yup';

import { StartupImagesStepData } from '.';

export const validateStartupImagesData = async (
  startupImagesData: StartupImagesStepData,
): Promise<void> => {
  const schema = yup.object().shape({
    images: yup
      .array(
        yup.object().shape({
          image: yup.mixed().optional(),
        }),
      )
      .optional(),
  });

  await schema.validate(startupImagesData, { abortEarly: false });
};
