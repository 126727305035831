import { InputNameStyle } from 'components/Input/styles';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  ${InputNameStyle} {
    position: absolute;
    margin-bottom: 0;
    top: 0;
    left: 0;
  }
`;
