import { LanguageLevel } from 'types/languageEntity';
import { OptionTypeBaseWithEnumValue } from './OptionTypeBaseWithEnumValue';

export const languageLevelOptions: OptionTypeBaseWithEnumValue<LanguageLevel>[] =
  [
    {
      id: 1,
      label: 'Iniciante',
      enumValue: LanguageLevel.BEGINNER,
    },
    {
      id: 2,
      label: 'Básico',
      enumValue: LanguageLevel.BASIC,
    },
    {
      id: 3,
      label: 'Intermediário',
      enumValue: LanguageLevel.INTERMEDIARY,
    },
    {
      id: 4,
      label: 'Avançado',
      enumValue: LanguageLevel.ADVANCED,
    },
    {
      id: 5,
      label: 'Proficiente',
      enumValue: LanguageLevel.PROFICIENT,
    },
  ];
