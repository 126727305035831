import styled, { css } from 'styled-components';

import { Depth16, Depth4 } from 'assets/colors/boxShadows';
import {
  BackgroundColor,
  HighEmphasis,
  OutlineColor,
} from 'assets/colors/palette';
import { Medium16Styles, Regular24Styles } from 'assets/fonts/fontStyles';

export const Container = styled.div``;

interface FilterOptionsContainerProps {
  isVisible: boolean;
}

export const FilterOptionsContainer = styled.div<FilterOptionsContainerProps>`
  position: absolute;
  top: 80px;
  right: 0;

  width: 634px;
  height: 439px;

  background: ${BackgroundColor};
  border: 1px solid ${OutlineColor};
  box-shadow: ${Depth16};

  padding: 20px;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  box-shadow: ${Depth4};
  transition: 0.5s;
  z-index: 3;

  ${({ isVisible }) =>
    isVisible
      ? css`
          visibility: visible;
          opacity: 1;
        `
      : css`
          visibility: hidden;
          opacity: 0;
        `}
`;

export const FilterOptionsTopContainer = styled.div`
  display: flex;
  justify-content: space-between;

  span {
    ${Regular24Styles}
    font-weight: 700;
    color: ${HighEmphasis};
  }

  button {
    background-color: transparent;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const FilterColumnsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
`;

export const FilterButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FilterOptionButton = styled.button`
  ${Medium16Styles}

  padding: 16px 32px;
  height: 48px;
  border: 1px solid ${OutlineColor};
  border-radius: 100px;

  color: ${HighEmphasis};
  background-color: ${BackgroundColor};

  &:disabled {
    color: ${OutlineColor};
    cursor: auto;
  }
`;

export const FilterButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  background: ${BackgroundColor};
  height: 48px;
  padding: 8px 16px;
  border-radius: 8px;
`;
