import { Input } from 'components/Input';
import { getInputStateValue } from 'utils';
import { useCallback, useEffect, useState } from 'react';
import { Select } from 'components/Select';
import { jobTypeOptions } from 'utils/typeOptions';
import { CheckboxInput } from 'components/Input/CheckboxInput';
import { TextAreaInput } from 'components/Input/TextAreaInput';
import { IFormStepProps } from 'types/IFormStepProps';
import { formStepInputStyles } from 'assets/styles/formWithStepsStyles/formWithStepsInputStyles';
import { EntityWithIdDTO } from 'types';
import {
  StepSubTitle,
  StepTitle,
  TitleContainer,
} from 'assets/styles/formWithStepsStyles/formWithStepsTitlesStyles';
import { FormWithStepsContainer } from 'assets/styles/formWithStepsStyles/formWithStepsContainerStyles';
import { useInputStates } from 'utils/customHooks';
import { useFormError } from 'errors/useFormError';
import { InputsContainer } from './styles';

export { validateVacancyDefinitionData } from './validateVacancyDefinitionData';

export interface VacancyDefinitionStepData {
  name: string;
  jobType: EntityWithIdDTO;
  immediateHiring: boolean;
  needExperience: boolean;
  forStudents: boolean;
  disabledPeople: boolean;
  description: string;
}

export const VacancyDefinitionStep: React.FC<
  IFormStepProps<VacancyDefinitionStepData>
> = ({
  initialData = {} as VacancyDefinitionStepData,
  setStepDataFunction,
  errors,
}) => {
  const {
    name,
    jobType,
    description,
    immediateHiring: initialImmediateHiring,
    needExperience: initialNeedExperience,
    disabledPeople: initialDisabledPeople,
    forStudents: initialForStudents,
  } = initialData;
  const { handleFormError } = useFormError();

  const nameStates = useInputStates('name', name);
  const jobTypeStates = useInputStates('jobType', jobType?.id);
  const descriptionStates = useInputStates('description', description);

  const [immediateHiring, setImmediateHiring] = useState(
    !!initialImmediateHiring,
  );
  const [needExperience, setNeedExperience] = useState(!!initialNeedExperience);
  const [disabledPeople, setDisabledPeople] = useState(!!initialDisabledPeople);
  const [forStudents, setForStudents] = useState(!!initialForStudents);

  const getUpdatedStepData = useCallback(() => {
    const updatedStatesObject = {
      name: getInputStateValue(nameStates),
      jobType: {
        id: Number(getInputStateValue(jobTypeStates)),
      },
      description: getInputStateValue(descriptionStates),
      immediateHiring,
      disabledPeople,
      forStudents,
      needExperience,
    };

    return updatedStatesObject as VacancyDefinitionStepData;
  }, [
    descriptionStates,
    disabledPeople,
    forStudents,
    immediateHiring,
    needExperience,
    jobTypeStates,
    nameStates,
  ]);

  useEffect(() => {
    if (!errors) return;

    const stepStates = [nameStates, jobTypeStates, descriptionStates];
    handleFormError(errors, stepStates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    if (!setStepDataFunction) return;

    const updatedData = getUpdatedStepData();
    setStepDataFunction(updatedData);
  }, [getUpdatedStepData, setStepDataFunction]);

  return (
    <FormWithStepsContainer>
      <TitleContainer>
        <StepTitle>Definindo a vaga</StepTitle>
        <StepSubTitle>
          Faça uma descrição geral da vaga adicionando informações como nome do
          cargo, tipo de trabalho e atividades a serem executadas
        </StepSubTitle>
      </TitleContainer>

      <InputsContainer>
        <Input
          name="Nome do cargo"
          placeholder="por exemplo, UX Desinger"
          states={nameStates}
          style={formStepInputStyles}
        />

        <Select
          name="Tipo de trabalho"
          placeholder="selecionar tipo de trabalho"
          states={jobTypeStates}
          selectOptions={jobTypeOptions}
          style={formStepInputStyles}
          startWithoutValue
        />

        <CheckboxInput
          style={{ fontSize: 16 }}
          label="Contratação imediata"
          defaultChecked={immediateHiring}
          handleCheckboxUpdate={checked => setImmediateHiring(checked)}
        />
        <CheckboxInput
          style={{ fontSize: 16 }}
          label="Requer experiência profissional na função"
          defaultChecked={needExperience}
          handleCheckboxUpdate={checked => setNeedExperience(checked)}
        />
        <CheckboxInput
          style={{ fontSize: 16 }}
          label="Para pessoas com deficiência (PCD)"
          defaultChecked={disabledPeople}
          handleCheckboxUpdate={checked => setDisabledPeople(checked)}
        />
        <CheckboxInput
          style={{ fontSize: 16 }}
          label="Para estudantes/universitários"
          defaultChecked={forStudents}
          handleCheckboxUpdate={checked => setForStudents(checked)}
        />

        <TextAreaInput
          name="Descrição das atividades"
          placeholder="adicionar atividade de trabalho"
          states={descriptionStates}
          style={formStepInputStyles}
          descriptionText="Adicione uma descrição de 100 a 4000 caracteres"
          charactersLimitLength={4000}
        />
      </InputsContainer>
    </FormWithStepsContainer>
  );
};
