// eslint-disable-next-line no-shadow
export enum CandidateEvaluation {
  NEW_CANDIDATE = 'NEW_CANDIDATE',
  NOT_EVALUATED = 'NOT_EVALUATED',
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  FAVORITE = 'FAVORITE',
  LOVED = 'LOVED',
  OUT_OF_EVALUATION = 'OUT_OF_EVALUATION',
}
