import styled, { css } from 'styled-components';

import { Depth16 } from 'assets/colors/boxShadows';
import { BackgroundColor, OutlineColor } from 'assets/colors/palette';
import { Medium16Styles } from 'assets/fonts/fontStyles';

interface OptionsContainerProps {
  isVisible: boolean;
}

export const Container = styled.div`
  position: relative;

  & > button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;

    width: 24px;
    height: 24px;
  }
`;

export const OptionsContainer = styled.div<OptionsContainerProps>`
  position: absolute;
  right: -5vw;

  width: 100vw;
  z-index: 2;
  background-color: ${BackgroundColor};

  display: flex;
  flex-direction: column;

  padding: 0 20px 32px;
  border-radius: 0 0 8px 8px;
  box-shadow: ${Depth16};
  transition: 0.4s;

  button {
    width: 100%;
  }

  ${({ isVisible }) =>
    isVisible
      ? css`
          top: 44px;
          visibility: visible;
          opacity: 1;
        `
      : css`
          top: 40px;
          visibility: hidden;
          opacity: 0;
        `}
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 24px;
  padding-block: 32px;

  & + & {
    border-top: 1px solid ${OutlineColor};
  }
`;

export const MobileButton = styled.button`
  ${Medium16Styles}

  display: flex;
  align-items: center;
  background-color: transparent;
  gap: 16px;

  i {
    width: 32px;
    height: 32px;
    background-color: #f0f0f0;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    img {
      width: 20px;
      height: 20px;
      filter: brightness(0);
    }
  }
`;
