import { IUserResponse } from 'types/user';
import { getRouteWithSubDomainBasedOnUserAccountType } from './getRouteWithSubDomainBasedOnUserAccountType';

export const redirectToConfirmAuthPageBasedOnUserAccountType = (
  authenticationToken: string,
  keepConnected: boolean,
  { accountType, id }: IUserResponse,
): void => {
  window.location.replace(
    `${getRouteWithSubDomainBasedOnUserAccountType(
      accountType,
    )}/confirmAuth/${id}/${authenticationToken}/${keepConnected}`,
  );
};
