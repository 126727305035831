import linkedinIcon from 'assets/img/linkedin.svg';
import facebookIcon from 'assets/img/facebook.svg';
import instagramIcon from 'assets/img/instagram.svg';

import { useEffect, useMemo } from 'react';
import { IFormStepProps } from 'types/IFormStepProps';
import { useHistory } from 'react-router-dom';
import { convertCityAndStateFromObjectToString } from 'utils/conversion/convertCityAndStateFromObjectToString';
import {
  StepSubTitle,
  StepTitle,
  TitleContainer,
} from 'assets/styles/formWithStepsStyles/formWithStepsTitlesStyles';
import { businessSectorOptions } from 'utils/typeOptions/businessSectorOptions';
import { businessModelOptions } from 'utils/typeOptions/businessModelOptions';
import {
  Container,
  GalleryImages,
  SocialNetwork,
  SocialNetworksContainer,
  StartupContainer,
  StartupData,
  StartupDataLine,
  StartupLogo,
  StartupName,
} from './styles';
import { StartupAllStepsData } from '..';

export const FinishStep: React.FC<IFormStepProps<StartupAllStepsData>> = ({
  initialData = {} as StartupAllStepsData,
}) => {
  const {
    location: { pathname },
  } = useHistory();
  const isStartupCreation = pathname.endsWith('createStartup');

  const {
    presentationStep,
    locationStep: { startupLocations },
    businessSectorsStep: { businessSectors },
    businessModelStep: {
      businessModel: { id: businessModelId },
    },
    socialNetworksStep: { website, facebook, instagram, linkedin },
    startupImagesStep,
  } = initialData;

  const parsedLogo = useMemo(() => {
    const { logo } = presentationStep;

    if (typeof logo === 'string') return logo;
    return URL.createObjectURL(logo);
  }, [presentationStep]);

  const imagesUrl = useMemo(
    () =>
      (startupImagesStep.images ?? []).map(({ image }) =>
        typeof image === 'string' ? image : URL.createObjectURL(image),
      ),
    [startupImagesStep.images],
  );

  useEffect(() => {
    return () => {
      imagesUrl.map(
        imageUrl =>
          typeof imageUrl !== 'string' && URL.revokeObjectURL(imageUrl),
      );

      if (typeof presentationStep.logo !== 'string') {
        URL.revokeObjectURL(parsedLogo);
      }
    };
  }, [imagesUrl, parsedLogo, presentationStep.logo]);

  return (
    <Container>
      <TitleContainer>
        <StepTitle>{`${
          isStartupCreation ? 'Publicar' : 'Atualizar'
        } página de carreiras`}</StepTitle>
        <StepSubTitle>
          Seu perfil está pronto! Agora sua empresa tem uma página de carreiras
          e você já pode criar sua primeira vaga de trabalho.
        </StepSubTitle>
      </TitleContainer>

      <StartupContainer>
        <StartupLogo src={parsedLogo} alt="Logo da Startup" />
        <StartupName>{presentationStep.name}</StartupName>

        <SocialNetworksContainer>
          {linkedin && (
            <SocialNetwork href={linkedin} target="_blank">
              <img src={linkedinIcon} alt="Linkedin" />
            </SocialNetwork>
          )}

          {facebook && (
            <SocialNetwork href={facebook} target="_blank">
              <img src={facebookIcon} alt="Facebook" />
            </SocialNetwork>
          )}

          {instagram && (
            <SocialNetwork href={instagram} target="_blank">
              <img src={instagramIcon} alt="Instagram" />
            </SocialNetwork>
          )}
        </SocialNetworksContainer>

        <StartupData>
          {startupLocations.length > 0 && (
            <StartupDataLine>
              <span className="line-title">Localização</span>
              <div>
                {startupLocations.map(location => (
                  <p key={location.city.id} className="line-content">
                    {convertCityAndStateFromObjectToString(location)}
                  </p>
                ))}
              </div>
            </StartupDataLine>
          )}

          {website && (
            <StartupDataLine highlightedContent>
              <span className="line-title">Site</span>
              <span className="line-content">
                {website.split('https://')[1]}
              </span>
            </StartupDataLine>
          )}

          <StartupDataLine>
            <span className="line-title">Fundação</span>
            <span className="line-content">
              {presentationStep.foundationYear}
            </span>
          </StartupDataLine>

          <StartupDataLine>
            <span className="line-title">Funcionário</span>
            <span className="line-content">
              {presentationStep.employeesQuantity}
            </span>
          </StartupDataLine>

          <StartupDataLine>
            <span className="line-title">Setor de atuação</span>

            <div>
              {businessSectors.map(({ id }) => (
                <p key={id} className="line-content">
                  {businessSectorOptions[id - 1].sector}
                </p>
              ))}
            </div>
          </StartupDataLine>

          <StartupDataLine>
            <span className="line-title">Modelo de negócios</span>
            <span className="line-content">
              {businessModelOptions[businessModelId - 1].model}
            </span>
          </StartupDataLine>
        </StartupData>

        <p>{presentationStep.about}</p>

        <GalleryImages>
          {imagesUrl.map(imageUrl => (
            <img key={imageUrl} src={imageUrl} alt="Foto da galeria" />
          ))}
        </GalleryImages>
      </StartupContainer>
    </Container>
  );
};
