import { TextGradientPrimaryBackground } from 'assets/colors/background';
import { HighEmphasis } from 'assets/colors/palette';
import {
  Bold16Styles,
  Bold32Styles,
  Medium14Styles,
  Regular16Styles,
} from 'assets/fonts/fontStyles';
import styled from 'styled-components';

export const Container = styled.div`
  width: 752px;
  padding-block: 70px;
  margin-inline: auto;
`;

export const TitleContainer = styled.div`
  text-align: center;
  margin-bottom: 120px;

  h1 {
    ${Bold32Styles}
    line-height: 34px;
  }

  h4 {
    ${Bold16Styles}
    margin-block: 14px 10px;
  }

  span {
    ${Medium14Styles}
    line-height: 24px;
  }
`;

export const ContentParagraph = styled.div`
  ${Regular16Styles}
  color: ${HighEmphasis};
  line-height: 32px;

  margin-block: 30px;

  ol {
    list-style: none;

    & > li {
      margin-bottom: 28px;
    }
  }

  ul {
    padding-left: 26px;
    list-style-type: disc;
  }
`;

export const ContentSection = styled.section``;

export const ContentSectionTitle = styled.span`
  ${Bold16Styles}
  ${TextGradientPrimaryBackground}
  line-height: 32px;
`;
