import immediateHiringIcon from 'assets/img/immediateHiring.svg';
import needExperienceIcon from 'assets/img/needExperience.svg';
import disabledPeopleIcon from 'assets/img/disabledPeople.svg';
import forStudentsIcon from 'assets/img/forStudents.svg';
import localTwo from 'assets/img/localTwo.svg';
import cooperativeHandshake from 'assets/img/cooperativeHandshake.svg';
import time from 'assets/img/time.svg';
import dollar from 'assets/img/dollar.svg';
import hexagonStrip from 'assets/img/hexagonStrip.svg';

import { useMemo } from 'react';
import {
  jobTypeOptions,
  paymentTypeOptions,
  workloadTypeOptions,
} from 'utils/typeOptions';
import { IFormStepProps } from 'types/IFormStepProps';
import { useHistory } from 'react-router-dom';
import { verifyIfVacancyHasRequirements } from 'utils/verifyIfVacancyHasRequirements';
import { useStartup } from 'hooks/startup';
import { convertCityAndStateFromObjectToString } from 'utils/conversion/convertCityAndStateFromObjectToString';
import {
  StepSubTitle,
  StepTitle,
  TitleContainer,
} from 'assets/styles/formWithStepsStyles/formWithStepsTitlesStyles';
import { ILocation } from 'types/location';
import { convertLanguageFromObjectToString } from 'utils/conversion/convertLanguageFromObjectToString';
import { VacancyAllStepsData } from '..';
import {
  Container,
  Requirement,
  RequirementContainer,
  RequirementWithDescription,
  VacancyAspects,
  VacancyContainer,
  VacancyData,
  VacancyDataLine,
  VacancyName,
  VacancyTopic,
} from './styles';

export const FinishStep: React.FC<IFormStepProps<VacancyAllStepsData>> = ({
  initialData = {} as VacancyAllStepsData,
}) => {
  const { startup } = useStartup();
  const { startupLocations } = startup!;

  const {
    location: { pathname },
  } = useHistory();
  const isVacancyCreation = pathname.endsWith('createVacancy');

  const {
    vacancyDefinitionStep,
    workloadStep,
    remunerationStep,
    competenciesStep: { competencies },
    skillsStep: { skills },
    languagesStep: { languages },
    certificationsStep: { certifications },
    otherRequirementsStep: { otherRequirements },
  } = initialData;

  const parsedCityAndState = useMemo(() => {
    if (workloadStep.vacancyLocation === null) return null;

    const findedStartupLocation = startupLocations.find(
      ({ id }) => id === workloadStep.vacancyLocation?.id,
    ) as ILocation;

    return convertCityAndStateFromObjectToString(
      findedStartupLocation,
      ', ',
      true,
    );
  }, [startupLocations, workloadStep.vacancyLocation]);

  return (
    <Container>
      <TitleContainer>
        <StepTitle>{`Revisar e ${
          isVacancyCreation ? 'publicar' : 'atualizar'
        } vaga`}</StepTitle>
        <StepSubTitle>
          {[
            `Revise todas as informações da vaga para que ela seja ${
              isVacancyCreation ? 'publicada' : 'atualizada'
            } corretamente.`,
            isVacancyCreation
              ? 'Mas, caso precise, você pode editar posteriormente'
              : '',
          ].join(' ')}
        </StepSubTitle>
      </TitleContainer>

      <VacancyContainer>
        <VacancyName>{vacancyDefinitionStep.name}</VacancyName>

        <VacancyAspects>
          {vacancyDefinitionStep.immediateHiring && (
            <div>
              <img src={immediateHiringIcon} alt="Contratação imediata" />
              <small>Contratação imediata</small>
            </div>
          )}
          {vacancyDefinitionStep.needExperience && (
            <div>
              <img src={needExperienceIcon} alt="Requer experiência" />
              <small>Requer experiência</small>
            </div>
          )}
          {vacancyDefinitionStep.disabledPeople && (
            <div>
              <img src={disabledPeopleIcon} alt="Pessoas com deficiência" />
              <small>PCD</small>
            </div>
          )}
          {vacancyDefinitionStep.forStudents && (
            <div>
              <img src={forStudentsIcon} alt="Para estudantes" />
              <small>Para estudantes</small>
            </div>
          )}
        </VacancyAspects>

        <VacancyData>
          {parsedCityAndState && (
            <VacancyDataLine>
              <img src={localTwo} alt="Localização" />
              <p>{parsedCityAndState}</p>
            </VacancyDataLine>
          )}

          <VacancyDataLine>
            <img src={cooperativeHandshake} alt="Aperto de mãos" />
            <p>{jobTypeOptions[vacancyDefinitionStep.jobType.id - 1].label}</p>
          </VacancyDataLine>

          <VacancyDataLine>
            <img src={time} alt="Relógio" />
            <p>{workloadTypeOptions[workloadStep.workloadType.id - 1].label}</p>
            {workloadStep.workSchedule && <p>{workloadStep.workSchedule}</p>}
          </VacancyDataLine>

          <VacancyDataLine>
            <img src={dollar} alt="Dólar" />
            <p>
              {remunerationStep.salaryToNegotiate
                ? 'Salário a negociar'
                : `${remunerationStep.salary} ${
                    paymentTypeOptions[remunerationStep.paymentType.id - 1]
                      .label
                  }`}
            </p>
          </VacancyDataLine>

          {remunerationStep.benefits && (
            <VacancyDataLine>
              <img src={hexagonStrip} alt="Hexágono" />
              <p>{remunerationStep.benefits}</p>
            </VacancyDataLine>
          )}
        </VacancyData>

        <VacancyTopic>
          <h3 className="topic-title">Descrição das atividades</h3>
          <p className="content">{vacancyDefinitionStep.description}</p>
        </VacancyTopic>

        {verifyIfVacancyHasRequirements({
          otherRequirements,
          competencies,
          certifications,
          languages,
          skills,
        }) && (
          <VacancyTopic style={{ marginBottom: -20 }}>
            <h3 className="topic-title">Requisitos</h3>
            <p className="content">{otherRequirements}</p>
          </VacancyTopic>
        )}

        {competencies && competencies.length > 0 && (
          <RequirementContainer>
            <span>Competências técnicas</span>

            <div>
              {competencies.map(({ name }) => (
                <Requirement key={name}>{name}</Requirement>
              ))}
            </div>
          </RequirementContainer>
        )}

        {skills && skills.length > 0 && (
          <RequirementContainer>
            <span>Habilidades comportamentais</span>

            <div>
              {skills.map(({ name, description }) => (
                <RequirementWithDescription key={name}>
                  <Requirement>{name}</Requirement>
                  <p>{description}</p>
                </RequirementWithDescription>
              ))}
            </div>
          </RequirementContainer>
        )}

        {languages && languages.length > 0 && (
          <RequirementContainer>
            <span>Idiomas</span>

            <div>
              {languages.map(({ name, level }) => (
                <Requirement key={name}>
                  {convertLanguageFromObjectToString({ name, level })}
                </Requirement>
              ))}
            </div>
          </RequirementContainer>
        )}

        {certifications && certifications.length > 0 && (
          <RequirementContainer>
            <span>Certificações</span>

            <div>
              {certifications.map(({ name }) => (
                <Requirement key={name}>{name}</Requirement>
              ))}
            </div>
          </RequirementContainer>
        )}
      </VacancyContainer>
    </Container>
  );
};
