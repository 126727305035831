import { OptionTypeBase } from 'react-select';

export const businessSectorOptions: OptionTypeBase[] = [
  {
    id: 1,
    label: 'Alimentos & Bebidas',
    sector: 'Alimentos & Bebidas',
  },
  {
    id: 2,
    label: 'Agricultura',
    sector: 'Agricultura',
  },
  {
    id: 3,
    label: 'Bens de consumo',
    sector: 'Bens de consumo',
  },
  {
    id: 4,
    label: 'Biotecnologia',
    sector: 'Biotecnologia',
  },
  {
    id: 5,
    label: 'Blockchain',
    sector: 'Blockchain',
  },
  {
    id: 6,
    label: 'Ciência & Engenharia',
    sector: 'Ciência & Engenharia',
  },
  {
    id: 7,
    label: 'Desportivo',
    sector: 'Desportivo',
  },
  {
    id: 8,
    label: 'eCommerce',
    sector: 'eCommerce',
  },
  {
    id: 9,
    label: 'Educação',
    sector: 'Educação',
  },
  {
    id: 10,
    label: 'Emprego & Recrutamento',
    sector: 'Emprego & Recrutamento',
  },
  {
    id: 11,
    label: 'Energia & Greentech',
    sector: 'Energia & Greentech',
  },
  {
    id: 12,
    label: 'Fabricação',
    sector: 'Fabricação',
  },
  {
    id: 13,
    label: 'Fintech',
    sector: 'Fintech',
  },
  {
    id: 14,
    label: 'Hospedagem',
    sector: 'Hospedagem',
  },
  {
    id: 15,
    label: 'Jogos',
    sector: 'Jogos',
  },
  {
    id: 16,
    label: 'Jurídico',
    sector: 'Jurídico',
  },
  {
    id: 17,
    label: 'Marketplace',
    sector: 'Marketplace',
  },
  {
    id: 18,
    label: 'Midia & Entretenimento',
    sector: 'Midia & Entretenimento',
  },
  {
    id: 19,
    label: 'Moda & Vida',
    sector: 'Moda & Vida',
  },
  {
    id: 20,
    label: 'Música & Áudio',
    sector: 'Música & Áudio',
  },
  {
    id: 21,
    label: 'Publicidade',
    sector: 'Publicidade',
  },
  {
    id: 22,
    label: 'Robótica',
    sector: 'Robótica',
  },
  {
    id: 23,
    label: 'SaaS',
    sector: 'SaaS',
  },
  {
    id: 24,
    label: 'Saúde',
    sector: 'Saúde',
  },
  {
    id: 25,
    label: 'Serviço',
    sector: 'Serviço',
  },
  {
    id: 26,
    label: 'Telecomunicações',
    sector: 'Telecomunicações',
  },
  {
    id: 27,
    label: 'TI & Software',
    sector: 'TI & Software',
  },
  {
    id: 28,
    label: 'Transportes',
    sector: 'Transportes',
  },
  {
    id: 29,
    label: 'Varejo',
    sector: 'Varejo',
  },
  {
    id: 30,
    label: 'Vendas & Marketing',
    sector: 'Vendas & Marketing',
  },
  {
    id: 31,
    label: 'Viagem & Turismo',
    sector: 'Viagem & Turismo',
  },
  {
    id: 32,
    label: 'Outros',
    sector: 'Outros',
  },
];
