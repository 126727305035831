import { ICity, IState } from 'types/location';

interface ObjectWithCityAndState {
  city: ICity;
  state: IState;
}

export const convertCityAndStateFromObjectToString = (
  {
    city: { name: cityName },
    state: { name: stateName, initials },
  }: ObjectWithCityAndState,
  separator = ', ',
  useStateInitials = false,
): string =>
  `${cityName}${separator}${useStateInitials ? initials : stateName}`;
