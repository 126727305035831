import { HighEmphasis } from 'assets/colors/palette';
import styled from 'styled-components';

export const Container = styled.div`
  color: ${HighEmphasis};
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.75px;

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 8px 16px 8px 8px;
  }

  .column-title {
    font-weight: 700;
  }

  .startups-column {
    min-width: 17vw;
    max-width: 17vw;
  }

  .employer-column {
    min-width: 14.5vw;
    max-width: 14.5vw;
  }

  .location-column {
    min-width: 16vw;
    max-width: 16vw;
  }

  .email-column {
    min-width: 20vw;
    max-width: 20vw;
    padding: 8px;
  }

  .options-column {
    width: 100%;
    padding: 16px 40px 16px 0px;
  }
`;
