import { ProfessionalProfileType } from 'types/candidate';
import { ITestQuestion } from '..';

export const PROFESSIONAL_PROFILE_QUESTIONS: ITestQuestion<ProfessionalProfileType>[] =
  [
    {
      question: <p>Eu sou...</p>,
      answers: [
        {
          text: 'Idealista, criativo e visionário',
          answerType: ProfessionalProfileType.IDEALIZER,
        },
        {
          text: 'Divertido, espiritual e benéfico',
          answerType: ProfessionalProfileType.COMMUNICATOR,
        },
        {
          text: 'Confiável, meticuloso e previsível',
          answerType: ProfessionalProfileType.ANALYST,
        },
        {
          text: 'Focado, determinado e persistente',
          answerType: ProfessionalProfileType.EXECUTOR,
        },
      ],
    },
    {
      question: <p>Eu gosto de...</p>,
      answers: [
        {
          text: 'Ser piloto',
          answerType: ProfessionalProfileType.EXECUTOR,
        },
        {
          text: 'Conversar com os passageiros',
          answerType: ProfessionalProfileType.COMMUNICATOR,
        },
        {
          text: 'Planejar a viagem',
          answerType: ProfessionalProfileType.ANALYST,
        },
        {
          text: 'Explorar novas rotas',
          answerType: ProfessionalProfileType.IDEALIZER,
        },
      ],
    },
    {
      question: <p>Se você quiser se dar bem comigo...</p>,
      answers: [
        {
          text: 'Me dê liberdade',
          answerType: ProfessionalProfileType.IDEALIZER,
        },
        {
          text: 'Me deixe saber sua expectativa',
          answerType: ProfessionalProfileType.ANALYST,
        },
        {
          text: 'Lidere, siga ou saia do caminho',
          answerType: ProfessionalProfileType.EXECUTOR,
        },
        {
          text: 'Seja amigável, carinhoso e compreensivo',
          answerType: ProfessionalProfileType.COMMUNICATOR,
        },
      ],
    },
    {
      question: <p>Para conseguir obter bons resultados é preciso...</p>,
      answers: [
        {
          text: 'Ter incertezas',
          answerType: ProfessionalProfileType.IDEALIZER,
        },
        {
          text: 'Controlar o essencial',
          answerType: ProfessionalProfileType.ANALYST,
        },
        {
          text: 'Diversão e celebração',
          answerType: ProfessionalProfileType.COMMUNICATOR,
        },
        {
          text: 'Planejar e obter recursos',
          answerType: ProfessionalProfileType.EXECUTOR,
        },
      ],
    },
    {
      question: <p>Eu me divirto quando...</p>,
      answers: [
        {
          text: 'Estou me exercitando',
          answerType: ProfessionalProfileType.EXECUTOR,
        },
        {
          text: 'Tenho novidades',
          answerType: ProfessionalProfileType.IDEALIZER,
        },
        {
          text: 'Estou com os outros',
          answerType: ProfessionalProfileType.COMMUNICATOR,
        },
        {
          text: 'Determino as regras',
          answerType: ProfessionalProfileType.ANALYST,
        },
      ],
    },
    {
      question: <p>Eu penso que...</p>,
      answers: [
        {
          text: 'Unidos venceremos, divididos perderemos',
          answerType: ProfessionalProfileType.COMMUNICATOR,
        },
        {
          text: 'O ataque é melhor que a defesa',
          answerType: ProfessionalProfileType.EXECUTOR,
        },
        {
          text: 'É bom ser manso, mas andar com um porrete',
          answerType: ProfessionalProfileType.IDEALIZER,
        },
        {
          text: 'Um homem prevenido vale por dois',
          answerType: ProfessionalProfileType.ANALYST,
        },
      ],
    },
    {
      question: <p>Minha preocupação é...</p>,
      answers: [
        {
          text: 'Gerar a ideia global',
          answerType: ProfessionalProfileType.IDEALIZER,
        },
        {
          text: 'Fazer com que as pessoas gostem',
          answerType: ProfessionalProfileType.COMMUNICATOR,
        },
        {
          text: 'Fazer com que funcione',
          answerType: ProfessionalProfileType.ANALYST,
        },
        {
          text: 'Fazer com que aconteça',
          answerType: ProfessionalProfileType.EXECUTOR,
        },
      ],
    },
    {
      question: <p>Eu prefiro...</p>,
      answers: [
        {
          text: 'Perguntas a respostas',
          answerType: ProfessionalProfileType.IDEALIZER,
        },
        {
          text: 'Ter todos os detalhes',
          answerType: ProfessionalProfileType.ANALYST,
        },
        {
          text: 'Vantagens a meu favor',
          answerType: ProfessionalProfileType.EXECUTOR,
        },
        {
          text: 'Que todos tenham a chance de serem ouvidos',
          answerType: ProfessionalProfileType.COMMUNICATOR,
        },
      ],
    },
    {
      question: <p>Eu gosto de...</p>,
      answers: [
        {
          text: 'Fazer progresso',
          answerType: ProfessionalProfileType.EXECUTOR,
        },
        {
          text: 'Construir memórias',
          answerType: ProfessionalProfileType.IDEALIZER,
        },
        {
          text: 'Fazer sentido',
          answerType: ProfessionalProfileType.ANALYST,
        },
        {
          text: 'Tornar as pessoas confortáveis',
          answerType: ProfessionalProfileType.COMMUNICATOR,
        },
      ],
    },
    {
      question: <p>Eu gosto de chegar...</p>,
      answers: [
        {
          text: 'Na frente',
          answerType: ProfessionalProfileType.EXECUTOR,
        },
        {
          text: 'Junto',
          answerType: ProfessionalProfileType.COMMUNICATOR,
        },
        {
          text: 'Na hora',
          answerType: ProfessionalProfileType.ANALYST,
        },
        {
          text: 'Em outro lugar',
          answerType: ProfessionalProfileType.IDEALIZER,
        },
      ],
    },
    {
      question: <p>Um ótimo dia para mim é quando...</p>,
      answers: [
        {
          text: 'Consigo fazer muitas coisas',
          answerType: ProfessionalProfileType.EXECUTOR,
        },
        {
          text: 'Me divirto com meus amigos',
          answerType: ProfessionalProfileType.COMMUNICATOR,
        },
        {
          text: 'Tudo segue conforme planejado',
          answerType: ProfessionalProfileType.ANALYST,
        },
        {
          text: 'Desfruto de coisas novas e estimulantes',
          answerType: ProfessionalProfileType.IDEALIZER,
        },
      ],
    },
    {
      question: <p>Eu vejo a morte como...</p>,
      answers: [
        {
          text: 'Uma grande aventura misteriosa',
          answerType: ProfessionalProfileType.IDEALIZER,
        },
        {
          text: 'Oportunidadepara rever os falecidos',
          answerType: ProfessionalProfileType.COMMUNICATOR,
        },
        {
          text: 'Um modo de receber recompensas',
          answerType: ProfessionalProfileType.ANALYST,
        },
        {
          text: 'Algo que sempre chega muito cedo',
          answerType: ProfessionalProfileType.EXECUTOR,
        },
      ],
    },
    {
      question: <p>Minha filosofia de vida é...</p>,
      answers: [
        {
          text: 'Há ganhadores e perdedores, e eu acredito ser um ganhador',
          answerType: ProfessionalProfileType.EXECUTOR,
        },
        {
          text: 'Para eu ganhar, ninguém precisa perder',
          answerType: ProfessionalProfileType.COMMUNICATOR,
        },
        {
          text: 'Para ganhar é preciso seguir as regras',
          answerType: ProfessionalProfileType.ANALYST,
        },
        {
          text: 'Para ganhar, é necessário inventar novas regras',
          answerType: ProfessionalProfileType.IDEALIZER,
        },
      ],
    },
    {
      question: <p>Eu sempre gostei de...</p>,
      answers: [
        {
          text: 'Explorar',
          answerType: ProfessionalProfileType.IDEALIZER,
        },
        {
          text: 'Evitar surpresas',
          answerType: ProfessionalProfileType.ANALYST,
        },
        {
          text: 'Focalizar a meta',
          answerType: ProfessionalProfileType.EXECUTOR,
        },
        {
          text: 'Realizar uma abordagem natural',
          answerType: ProfessionalProfileType.COMMUNICATOR,
        },
      ],
    },
    {
      question: <p>Eu gosto de mudanças se...</p>,
      answers: [
        {
          text: 'Me der uma vantagem competitiva',
          answerType: ProfessionalProfileType.EXECUTOR,
        },
        {
          text: 'For divertido e puder ser compartilhado',
          answerType: ProfessionalProfileType.COMMUNICATOR,
        },
        {
          text: 'Me der mais liberdade e variedade',
          answerType: ProfessionalProfileType.IDEALIZER,
        },
        {
          text: 'Melhorar ou me der mais controle',
          answerType: ProfessionalProfileType.ANALYST,
        },
      ],
    },
    {
      question: <p>Não existe nada de errado em...</p>,
      answers: [
        {
          text: 'Se colocar na frente',
          answerType: ProfessionalProfileType.EXECUTOR,
        },
        {
          text: 'Colocar os outros na frente',
          answerType: ProfessionalProfileType.COMMUNICATOR,
        },
        {
          text: 'Mudar de ideia',
          answerType: ProfessionalProfileType.IDEALIZER,
        },
        {
          text: 'Ser consistente',
          answerType: ProfessionalProfileType.ANALYST,
        },
      ],
    },
    {
      question: <p>Eu gosto de buscar conselhos de...</p>,
      answers: [
        {
          text: 'Pessoas bem-sucedidas',
          answerType: ProfessionalProfileType.EXECUTOR,
        },
        {
          text: 'Anciões e conselheiros',
          answerType: ProfessionalProfileType.COMMUNICATOR,
        },
        {
          text: 'Autoridades no assunto',
          answerType: ProfessionalProfileType.ANALYST,
        },
        {
          text: 'Lugares, os mais estranhos',
          answerType: ProfessionalProfileType.IDEALIZER,
        },
      ],
    },
    {
      question: <p>Meu lema é...</p>,
      answers: [
        {
          text: 'Fazer o que precisa ser feito',
          answerType: ProfessionalProfileType.IDEALIZER,
        },
        {
          text: 'Fazer bem feito',
          answerType: ProfessionalProfileType.ANALYST,
        },
        {
          text: 'Fazer junto com o grupo',
          answerType: ProfessionalProfileType.COMMUNICATOR,
        },
        {
          text: 'Simplesmente fazer',
          answerType: ProfessionalProfileType.EXECUTOR,
        },
      ],
    },
    {
      question: <p>Eu gosto de...</p>,
      answers: [
        {
          text: 'Complexidade, mesmo se confuso',
          answerType: ProfessionalProfileType.IDEALIZER,
        },
        {
          text: 'Ordem e sistematização',
          answerType: ProfessionalProfileType.ANALYST,
        },
        {
          text: 'Calor humano e animação',
          answerType: ProfessionalProfileType.COMMUNICATOR,
        },
        {
          text: 'Coisas claras e simples',
          answerType: ProfessionalProfileType.EXECUTOR,
        },
      ],
    },
    {
      question: <p>Tempo para mim é...</p>,
      answers: [
        {
          text: 'Algo que detesto desperdiçar',
          answerType: ProfessionalProfileType.EXECUTOR,
        },
        {
          text: 'Um grande ciclo',
          answerType: ProfessionalProfileType.COMMUNICATOR,
        },
        {
          text: 'Uma flecha que leva ao inevitável',
          answerType: ProfessionalProfileType.ANALYST,
        },
        {
          text: 'Irrelevante',
          answerType: ProfessionalProfileType.IDEALIZER,
        },
      ],
    },
    {
      question: <p>Se eu fosse bilionário...</p>,
      answers: [
        {
          text: 'Faria doações para muitas entidades',
          answerType: ProfessionalProfileType.COMMUNICATOR,
        },
        {
          text: 'Criaria uma poupança avantajada',
          answerType: ProfessionalProfileType.ANALYST,
        },
        {
          text: 'Faria o que desse na cabeça',
          answerType: ProfessionalProfileType.IDEALIZER,
        },
        {
          text: 'Me exibiria bastante para algumas pessoas',
          answerType: ProfessionalProfileType.EXECUTOR,
        },
      ],
    },
    {
      question: <p>Eu acredito que...</p>,
      answers: [
        {
          text: 'O destino é mais importante que a jornada',
          answerType: ProfessionalProfileType.EXECUTOR,
        },
        {
          text: 'A jornada é mais importante que o destino',
          answerType: ProfessionalProfileType.COMMUNICATOR,
        },
        {
          text: 'Um centavo economizado é um centavo ganho',
          answerType: ProfessionalProfileType.ANALYST,
        },
        {
          text: 'Bastam um navio e uma estrela para navegar',
          answerType: ProfessionalProfileType.IDEALIZER,
        },
      ],
    },
    {
      question: <p>Eu acredito também que...</p>,
      answers: [
        {
          text: 'Aquele que hesita está perdido',
          answerType: ProfessionalProfileType.EXECUTOR,
        },
        {
          text: 'De grão em grão a galinha enche o papo',
          answerType: ProfessionalProfileType.ANALYST,
        },
        {
          text: 'O que vai, volta',
          answerType: ProfessionalProfileType.COMMUNICATOR,
        },
        {
          text: 'Um sorriso ou uma careta é o mesmo para quem é cego',
          answerType: ProfessionalProfileType.IDEALIZER,
        },
      ],
    },
    {
      question: <p>Eu acredito ainda que...</p>,
      answers: [
        {
          text: 'É melhor prudência do que arrependimento',
          answerType: ProfessionalProfileType.ANALYST,
        },
        {
          text: 'A autoridade deve ser desafiada',
          answerType: ProfessionalProfileType.IDEALIZER,
        },
        {
          text: 'Ganhar é fundamental',
          answerType: ProfessionalProfileType.EXECUTOR,
        },
        {
          text: 'O coletivo é mais importante do que o individual',
          answerType: ProfessionalProfileType.COMMUNICATOR,
        },
      ],
    },
    {
      question: <p>Eu penso que...</p>,
      answers: [
        {
          text: 'Não é fácil ficar encurralado',
          answerType: ProfessionalProfileType.IDEALIZER,
        },
        {
          text: 'É preferível olhar, antes de pular',
          answerType: ProfessionalProfileType.ANALYST,
        },
        {
          text: 'Duas cabeças pensam melhor do que uma',
          answerType: ProfessionalProfileType.COMMUNICATOR,
        },
        {
          text: 'Se você não tem condições de competir, não compita',
          answerType: ProfessionalProfileType.EXECUTOR,
        },
      ],
    },
  ];
