import { FormWithStepsContainer } from 'assets/styles/formWithStepsStyles/formWithStepsContainerStyles';
import {
  StepSubTitle,
  StepTitle,
  TitleContainer,
} from 'assets/styles/formWithStepsStyles/formWithStepsTitlesStyles';
import { ERROR_MODAL_CONFIGURATION, useAlertModal } from 'hooks/alertModal';
import { useCallback, useEffect, useState } from 'react';
import { EntityWithIdDTO, IFormStepProps } from 'types';
import { businessSectorOptions } from 'utils/typeOptions/businessSectorOptions';
import { BusinessSector, BusinessSectorsContainer } from './styles';

export { validateBusinessSectorsData } from './validateBusinessSectorsData';

export interface BusinessSectorsStepData {
  businessSectors: EntityWithIdDTO[];
}

export const BusinessSectorsStep: React.FC<
  IFormStepProps<BusinessSectorsStepData>
> = ({ initialData = {}, setStepDataFunction, errors }) => {
  const { businessSectors: initialBusinessSectors } = initialData;
  const { showModal } = useAlertModal();

  const [businessSectors, setBusinessSectors] = useState<EntityWithIdDTO[]>(
    initialBusinessSectors ?? [],
  );

  useEffect(() => {
    if (!errors) return;

    const { message } = errors;
    showModal({ ...ERROR_MODAL_CONFIGURATION, message });
  }, [errors, showModal]);

  useEffect(() => {
    if (!setStepDataFunction) return;

    setStepDataFunction({
      businessSectors,
    });
  }, [businessSectors, setStepDataFunction]);

  const verifyIfSectorIsSelected = useCallback(
    (sectorId: number) => {
      return !!businessSectors.find(({ id }) => id === sectorId);
    },
    [businessSectors],
  );

  const handleBusinessSectorClick = useCallback(
    (businessSectorId: number) => {
      if (verifyIfSectorIsSelected(businessSectorId)) {
        setBusinessSectors(previousBusinessSectors =>
          previousBusinessSectors.filter(({ id }) => id !== businessSectorId),
        );
        return;
      }

      setBusinessSectors(previousBusinessSectors => [
        ...previousBusinessSectors,
        { id: businessSectorId },
      ]);
    },
    [verifyIfSectorIsSelected],
  );

  return (
    <FormWithStepsContainer>
      <TitleContainer>
        <StepTitle>Setor de atuação</StepTitle>
        <StepSubTitle>
          Em qual setor a sua empresa atua? Informe ao menos uma opção.
        </StepSubTitle>
      </TitleContainer>

      <BusinessSectorsContainer>
        {businessSectorOptions.map(({ id, sector }) => (
          <BusinessSector
            key={id}
            type="button"
            isSelected={verifyIfSectorIsSelected(id)}
            onClick={() => handleBusinessSectorClick(id)}
          >
            {sector}
          </BusinessSector>
        ))}
      </BusinessSectorsContainer>
    </FormWithStepsContainer>
  );
};
