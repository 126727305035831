import { yupRequiredStringField } from 'utils/validation';
import * as yup from 'yup';
import { SocialNetworksStepData } from '.';

export const validateSocialNetworksData = async (
  socialNetworksData: SocialNetworksStepData,
): Promise<void> => {
  const yupUrlValidation = yup
    .string()
    .url('URL inválida')
    .when({
      is: (value: string) => value.length > 0,
      then: yupRequiredStringField,
    });

  const schema = yup.object().shape({
    website: yupUrlValidation,
    linkedin: yupUrlValidation,
    instagram: yupUrlValidation,
    facebook: yupUrlValidation,
  });

  await schema.validate(socialNetworksData, { abortEarly: false });
};
