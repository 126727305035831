import simpleArrowRight from 'assets/img/simpleArrowRight.svg';
import { DotFlashing } from 'components/DotFlashing';

import { useWindowWidth } from 'hooks/windowWidth';
import { RightArrowImage } from './styles';

interface NextButtonContentProps {
  isNotLastStep: boolean;
  isLoadingSubmit: boolean;
  lastStepAdvanceButtonText: string;
}

export const NextButtonContent: React.FC<NextButtonContentProps> = ({
  isNotLastStep,
  isLoadingSubmit,
  lastStepAdvanceButtonText,
}) => {
  const { isMobileScreen } = useWindowWidth();

  if (isMobileScreen) return <RightArrowImage src={simpleArrowRight} />;
  if (isNotLastStep) return <>Próximo</>;
  if (!isLoadingSubmit) return <>{lastStepAdvanceButtonText}</>;

  return (
    <>
      Carregando
      <DotFlashing />
    </>
  );
};
