import polygon from 'assets/img/polygon.svg';
import { getResultScreenPropertiesBasedOnTestResult } from './getResultScreenPropertiesBasedOnTestResult';

import {
  Container,
  Feature,
  FeatureCharacteristic,
  HighlightPolygon,
  ProfileFeaturesContainer,
  ProfileHighlight,
} from './styles';

export const ProfessionalProfileResultScreen: React.FC<{
  testResult: string;
}> = ({ testResult }) => {
  const { icon, description, resultFeatures } =
    getResultScreenPropertiesBasedOnTestResult(testResult);

  return (
    <Container>
      <ProfileHighlight>
        <HighlightPolygon src={polygon} alt="Polígono" />

        <img src={icon} alt="Ícone do perfil" width={48} height={48} />
        <h2>{testResult}</h2>
      </ProfileHighlight>

      {description}

      <ProfileFeaturesContainer>
        {resultFeatures.map(feature => (
          <Feature key={feature.characteristics[0]}>
            {feature.name}

            <div>
              {feature.characteristics.map(characteristic => (
                <FeatureCharacteristic key={characteristic}>
                  {characteristic}
                </FeatureCharacteristic>
              ))}
            </div>
          </Feature>
        ))}
      </ProfileFeaturesContainer>
    </Container>
  );
};
