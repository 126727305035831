import closeIcon from 'assets/img/close.svg';

import { IVacancyResponse } from 'types/vacancy';
import { useEffect, useState } from 'react';
import { Loader } from 'components/Loader';
import { findOneVacancyById } from 'services/entitiesServices/vacancyServices';
import { ERROR_MODAL_CONFIGURATION, useAlertModal } from 'hooks/alertModal';
import { Container, ModalHeader } from './styles';
import { VacancyHighlightContainer } from '../../../../../../components/VacancyHighlightContainer';

interface HighlightVacancyModalProps {
  vacancyId: number | null;
  handleCloseModal: () => void;
}

export const HighlightVacancyModal: React.FC<HighlightVacancyModalProps> = ({
  vacancyId,
  handleCloseModal,
}) => {
  const { showModal } = useAlertModal();

  const [vacancy, setVacancy] = useState<IVacancyResponse>(
    {} as IVacancyResponse,
  );
  const [isLoadingVacancy, setIsLoadingVacancy] = useState(true);

  useEffect(() => {
    if (!vacancyId) return;

    findOneVacancyById(vacancyId)
      .then(findedVacancy => findedVacancy && setVacancy(findedVacancy))
      .catch(() => {
        showModal({
          ...ERROR_MODAL_CONFIGURATION,
          message: 'Erro ao carregar informações da vaga',
        });
        handleCloseModal();
      })
      .finally(() => setIsLoadingVacancy(false));
  }, [handleCloseModal, showModal, vacancyId]);

  return (
    <Container>
      <ModalHeader>
        <span>Descrição da vaga</span>
        <button type="button" onClick={handleCloseModal}>
          <img src={closeIcon} alt="X" />
        </button>
      </ModalHeader>

      {isLoadingVacancy ? (
        <Loader style={{ borderRadius: 8 }} />
      ) : (
        <VacancyHighlightContainer vacancy={vacancy} />
      )}
    </Container>
  );
};
