import {
  IVacancyCandidateBasicData,
  IVacancyCandidateWithCandidateData,
  IVacancyCandidateWithReducedRelatedData,
  IVacancyCandidateWithVacancyData,
} from 'types/vacancyCandidate';
import { IUpdateVacancyCandidateData } from 'types/vacancyCandidate/IUpdateVacancyCandidateData';
import { api } from '../api';

const servicesPrefix = 'vacancyCandidate';

export const createVacancyCandidate = async (createVacancyCandidateData: {
  vacancyId: number;
}): Promise<IVacancyCandidateBasicData> => {
  const { data } = await api.post<IVacancyCandidateBasicData>(
    `${servicesPrefix}`,
    createVacancyCandidateData,
  );

  return data;
};

export const findOneVacancyCandidateById = async (
  vacancyCandidateId: number,
): Promise<IVacancyCandidateWithReducedRelatedData | undefined> => {
  const { data } = await api.get<
    IVacancyCandidateWithReducedRelatedData | undefined
  >(`${servicesPrefix}/findOneById/${vacancyCandidateId}`);

  return data;
};

export const findOneVacancyCandidateByCandidateAndVacancyIds = async (
  candidateId: number,
  vacancyId: number,
): Promise<IVacancyCandidateBasicData | undefined> => {
  const { data } = await api.get<IVacancyCandidateBasicData | undefined>(
    `${servicesPrefix}/findOneByCandidateAndVacancyIds/${candidateId}/${vacancyId}`,
  );

  return data;
};

export const findAllCandidatesByVacancyId = async (
  vacancyId: number,
): Promise<IVacancyCandidateWithCandidateData[]> => {
  const { data } = await api.get<IVacancyCandidateWithCandidateData[]>(
    `${servicesPrefix}/findAllCandidatesByVacancyId/${vacancyId}`,
  );

  return data;
};

export const findAllVacanciesByCandidateId = async (
  candidateId: number,
): Promise<IVacancyCandidateWithVacancyData[]> => {
  const { data } = await api.get<IVacancyCandidateWithVacancyData[]>(
    `${servicesPrefix}/findAllVacanciesByCandidateId/${candidateId}`,
  );

  return data;
};

export const updateVacancyCandidate = async (
  vacancyCandidateId: number,
  updatedCandidateData: IUpdateVacancyCandidateData,
): Promise<IVacancyCandidateBasicData> => {
  const { data } = await api.put<IVacancyCandidateBasicData>(
    `${servicesPrefix}/${vacancyCandidateId}`,
    updatedCandidateData,
  );

  return data;
};

export const removeVacancyCandidateFromEvaluation = async (
  vacancyCandidateId: number,
): Promise<void> => {
  await api.delete(
    `${servicesPrefix}/removeFromEvaluation/${vacancyCandidateId}`,
  );
};

export const deleteVacancyCandidate = async (
  vacancyCandidateId: number,
): Promise<void> => {
  await api.delete(`${servicesPrefix}/delete/${vacancyCandidateId}`);
};
