import styled from 'styled-components';

import { TextGradientPrimaryBackground } from 'assets/colors/background';
import {
  BackgroundColor,
  GrayTitleActive,
  HighEmphasis,
  MediumEmphasis,
  OutlineColor,
  PrimaryColor,
} from 'assets/colors/palette';
import {
  Bold14Styles,
  Bold18Styles,
  Bold32Styles,
  Medium16Styles,
  Regular14Styles,
  Regular24Styles,
} from 'assets/fonts/fontStyles';

interface ExperienceDataLineProps {
  lowerColor?: boolean;
}

export const Container = styled.div`
  width: 500px;
  padding: 30px 24px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;

  border: 1px solid ${OutlineColor};
  border-radius: 8px;

  & > p {
    ${Regular14Styles}
    line-height: 28px;
    color: ${HighEmphasis};

    max-width: 100%;
    word-wrap: break-word;
    white-space: pre-line;
    margin-top: 18px;
  }

  & > i {
    width: 120px;
    height: 120px;
    font-size: 68px;
  }

  @media (max-width: 1100px) {
    width: 82.2vw;
    padding: 30px 12px;
    gap: 26px;

    & > p {
      font-size: 12px;
    }

    & > i {
      width: 84px;
      height: 84px;
      font-size: 42px;
    }
  }
`;

export const CandidateIdentification = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 4px;

  h2 {
    ${Bold32Styles}
    line-height: 48px;
    color: ${GrayTitleActive};
  }

  span {
    ${Medium16Styles}
    color: ${MediumEmphasis};
  }

  @media (max-width: 1100px) {
    gap: 0px;
    margin-block: -16px -12px;

    h2 {
      font-size: 24px;
    }

    span {
      font-size: 14px;
    }
  }
`;

export const SocialNetworksContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;
  margin-bottom: 30px;

  &:empty {
    margin-bottom: 10px;
  }

  @media (max-width: 1100px) {
    margin-top: 14px;
    margin-bottom: 18px;
  }
`;

export const SocialNetwork = styled.a`
  width: 48px;
  height: 48px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${BackgroundColor};
  border: 1px solid ${OutlineColor};
  border-radius: 100px;
  transition: 0.2s;

  img {
    filter: brightness(10%);
  }

  &:hover {
    background: rgba(220, 220, 220, 0.2);
  }
`;

export const CandidateData = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  margin-bottom: 18px;

  @media (max-width: 1100px) {
    margin-bottom: 16px;
    gap: 16px;
  }
`;

export const CandidateDataLine = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  ${Regular14Styles}
  line-height: 24px;

  .line-title {
    color: ${MediumEmphasis};
  }

  .line-content {
    font-weight: 700;
    text-align: end;
    color: ${HighEmphasis};
  }

  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const CandidateTopic = styled.h3`
  ${Bold18Styles}
  color: ${HighEmphasis};

  width: 100%;
  margin-block: 30px 6px;

  @media (max-width: 1100px) {
    margin-block: 22px 6px;
  }
`;

export const CandidateExperience = styled.div`
  width: 100%;

  & + & {
    border-top: 1px solid rgba(217, 217, 217, 1);
    margin-top: -8px;
    padding-top: 24px;
  }
`;

export const ExperienceName = styled.span`
  ${Bold14Styles}
  ${TextGradientPrimaryBackground}
`;

export const ExperienceDataLine = styled.div<ExperienceDataLineProps>`
  ${Regular14Styles}
  line-height: 24px;

  display: flex;
  flex-flow: wrap;
  overflow-x: hidden;
  color: ${({ lowerColor }) => (lowerColor ? MediumEmphasis : HighEmphasis)};

  span {
    position: relative;
  }

  span:first-child {
    margin-right: 30px;
  }

  span + span:after {
    content: '';
    position: absolute;
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background-color: ${({ lowerColor }) =>
      lowerColor ? MediumEmphasis : HighEmphasis};
    opacity: 1;
    left: -16px;
    bottom: 44%;
  }

  @media (max-width: 1100px) {
    font-size: 12px;
  }
`;

export const ExperienceDescription = styled.p`
  ${Regular24Styles}
  font-size: 12px;
  color: ${HighEmphasis};

  margin-top: 16px;
  word-wrap: break-word;
  white-space: pre-line;
  max-width: 452px;
`;

export const CapabilitiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 28px;

  & + & {
    margin-top: 4px;
  }

  div {
    display: flex;
    gap: 22px;
    flex-flow: wrap;

    p {
      ${Regular14Styles}
      line-height: 28px;
      color: ${HighEmphasis};
      margin-bottom: 8px;
    }
  }

  @media (max-width: 1100px) {
    & + & {
      margin-top: 0;
    }
  }
`;

export const Capability = styled.div`
  width: fit-content;
  padding: 12px;
  min-height: 48px;
  height: max-content;
  background: ${BackgroundColor};
  border: 1px solid ${OutlineColor};
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  ${Medium16Styles}
  color: ${PrimaryColor};

  @media (max-width: 1100px) {
    font-size: 14px;
  }
`;
