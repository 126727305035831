import infoIcon from 'assets/img/info.svg';
import { useMemo } from 'react';

import {
  CandidatesTestsAverageType,
  CandidateTest,
  ICandidateResponse,
} from 'types/candidate';
import { translateCandidateTest } from 'utils/conversion/translateCandidateTest';
import { DefaultTestResult } from './DefaultTestResult';
import { ProfessionalProfileResult } from './ProfessionalProfileResult';
import {
  Container,
  InfoContainer,
  NoTestsContainer,
  TestContainer,
  TopContainer,
} from './styles';

interface SelectedCandidateTestsProps {
  candidate: ICandidateResponse;
  candidatesTestsAverage: CandidatesTestsAverageType;
}

const TESTS_DESCRIPTIONS = {
  [CandidateTest.PROFESSIONAL_PROFILE]:
    'O Teste de Perfil Profissional permite identificar qual o perfil de comportamento, a partir da análise das competências pessoais, os valores que motivam e as tendências de atitudes dos candidatos frente a diferentes situações no ambiente de trabalho e no dia a dia.',
  [CandidateTest.LOGICAL_REASONING]:
    'O Teste de Raciocínio Lógico visa avaliar o potencial dos candidatos na resolução de problemas que envolvem raciocínio lógico e quantitativo.',
  [CandidateTest.PORTUGUESE]:
    'O Teste de Português visa avaliar a capacidade dos candidatos de entender diferentes tipos de textos e interpretar as mensagens escritas; refletir sobre o que leu em diferentes canais de comunicação e formatos de textos; e de se comunicar de maneira clara e de fácil compreensão.',
};

export const SelectedCandidateTests: React.FC<SelectedCandidateTestsProps> = ({
  candidate: {
    user: { name, lastName },
    testEvaluations,
  },
  candidatesTestsAverage,
}) => {
  const parsedCandidateEvaluations = useMemo(() => {
    const parsedTests = {} as { [key in CandidateTest]: string };
    testEvaluations.forEach(({ candidateTest, evaluation }) => {
      parsedTests[candidateTest] = evaluation;
    });

    return parsedTests;
  }, [testEvaluations]);

  return (
    <Container>
      {testEvaluations.length === 0 ? (
        <NoTestsContainer>
          As avaliações ainda não foram realizadas.
        </NoTestsContainer>
      ) : (
        Object.entries(TESTS_DESCRIPTIONS).map(
          ([testType, description]) =>
            parsedCandidateEvaluations[testType as CandidateTest] && (
              <TestContainer key={testType}>
                <TopContainer>
                  <span>
                    Teste de {translateCandidateTest(testType as CandidateTest)}
                  </span>

                  <InfoContainer>
                    <img src={infoIcon} alt="Informação sobre o teste" />
                    <div>{description}</div>
                  </InfoContainer>
                </TopContainer>

                {testType === CandidateTest.PROFESSIONAL_PROFILE ? (
                  <ProfessionalProfileResult
                    testResult={
                      parsedCandidateEvaluations[testType as CandidateTest]
                    }
                  />
                ) : (
                  <DefaultTestResult
                    averagePunctuation={
                      candidatesTestsAverage[testType as CandidateTest]
                    }
                    candidateName={`${name} ${lastName}`}
                    candidatePunctuation={Number(
                      parsedCandidateEvaluations[testType as CandidateTest],
                    )}
                  />
                )}
              </TestContainer>
            ),
        )
      )}
    </Container>
  );
};
