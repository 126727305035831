import { IErrors } from 'types/IErrors';

const getArrayIndexAndPropertyFromKey = (
  key: string,
): { index: number; property: string } => {
  const [keyStart, property] = key.split('].');
  const index = Number(keyStart.charAt(keyStart.length - 1));

  return { index, property };
};

export const convertParsedValidationErrorsToArrayOfErrors = (
  errors: IErrors,
): IErrors[] => {
  const convertedErrors: IErrors[] = [];

  Object.entries(errors).forEach(([key, value]) => {
    const { index, property } = getArrayIndexAndPropertyFromKey(key);

    if (convertedErrors[index] === undefined) {
      convertedErrors[index] = {} as IErrors;
    }

    convertedErrors[index][property] = value;
  });

  return convertedErrors;
};
