import styled, { css } from 'styled-components';
import {
  BackgroundColor,
  OutlineColor,
  PrimaryColor,
} from 'assets/colors/palette';
import { Bold14Styles, Regular14Styles } from 'assets/fonts/fontStyles';
import { TextGradientPrimaryBackground } from 'assets/colors/background';

interface ProfessionalProfileProps {
  isSelected: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  p {
    ${Regular14Styles}
    line-height: 24px;
  }
`;

export const ProfessionalProfilesContainer = styled.div`
  display: flex;
  flex-flow: wrap;
  gap: 17px;
`;

export const ProfessionalProfile = styled.button<ProfessionalProfileProps>`
  display: flex;
  align-items: center;
  gap: 8px;

  width: 212px;
  height: 84px;
  padding: 8px 12px;

  background-color: ${BackgroundColor};
  border: 1px solid ${OutlineColor};
  border-radius: 8px;
  transition: 0.2s;

  span {
    ${Bold14Styles}
  }

  &:hover {
    border-color: ${PrimaryColor};
  }

  ${({ isSelected }) =>
    isSelected
      ? css`
          span {
            ${TextGradientPrimaryBackground}
          }
        `
      : css`
          .profile-icon {
            filter: brightness(0);
          }

          .polygon {
            filter: brightness(0) invert(0.85);
          }
        `}
`;

export const IconContainer = styled.div`
  width: 68px;
  height: 68px;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  .polygon {
    width: 68px;
    height: 68px;
    position: absolute;

    top: 0;
    left: 0;
  }

  .profile-icon {
    width: 24px;
    height: 24px;

    z-index: 1;
  }
`;
