import { Depth16 } from 'assets/colors/boxShadows';
import { BackgroundColor, OutlineColor } from 'assets/colors/palette';
import styled, { css } from 'styled-components';

interface FolderOptionsProps {
  isVisible: boolean;
}

export const Container = styled.div`
  position: relative;

  .action-button {
    position: absolute;
    bottom: 20px;
    right: 20px;

    width: 32px;
    height: 32px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: ${BackgroundColor};
    border: 1px solid ${OutlineColor};
    border-radius: 8px;
    transition: 0.2s;

    &:hover {
      background: rgba(220, 220, 220, 0.2);
    }
  }
`;

export const FolderOptions = styled.div<FolderOptionsProps>`
  position: absolute;
  width: 296px;
  height: 150px;
  z-index: 1;

  background-color: ${BackgroundColor};
  border: 1px solid ${OutlineColor};
  border-radius: 8px;
  box-shadow: ${Depth16};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  top: -64px;
  right: -270px;
  transition: 0.4s;

  ${({ isVisible }) =>
    isVisible
      ? css`
          visibility: visible;
          opacity: 1;
        `
      : css`
          visibility: hidden;
          opacity: 0;
        `}
`;
