import { HighEmphasis, MediumEmphasis } from 'assets/colors/palette';
import { Medium16Styles, Regular16Styles } from 'assets/fonts/fontStyles';
import { Checkmark } from 'components/Input/CheckboxInput/styles';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-top: -24px;

  display: flex;
  flex-direction: column;
  gap: 26px;
`;

export const CheckboxesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  padding-top: 3px;
  height: 198px;
  max-height: 198px;
  overflow: auto;

  label {
    ${Regular16Styles}
    color: ${HighEmphasis};

    ${Checkmark} {
      border-radius: 3px;
      top: -1px;
    }
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(217, 217, 217, 0.2);
  }

  &::-webkit-scrollbar-thumb {
    background: ${MediumEmphasis};
    border-radius: 100px;
  }
`;

export const ColumnTitle = styled.span`
  ${Medium16Styles}
  color: ${HighEmphasis};
`;
