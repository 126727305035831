import noFoldersIcon from 'assets/img/noVacanciesIcon.svg';
import folderIcon from 'assets/img/folder.svg';
import deleteIcon from 'assets/img/delete.svg';

import { PlusButton } from 'components/Buttons';
import { useCallback, useEffect, useState } from 'react';
import { useStartup } from 'hooks/startup';
import { Loader } from 'components/Loader';
import { useHistory } from 'react-router-dom';
import { routeStartupFolderCandidates } from 'routes/startupsRoutes/startupsRoutesAddresses';

import { formatDateToBrazilianString } from 'utils/conversion/formatDateToBrazilianString';
import { UserIcon } from 'components/UserIcon';
import { Modal } from 'components/Modal';
import { useInputStates } from 'utils/customHooks';
import {
  createStartupCandidatesFolder,
  deleteStartupCandidatesFolder,
  findAllStartupCandidatesFolderByStartupIdWithFiledCandidates,
  updateStartupCandidatesFolder,
} from 'services/entitiesServices/startupCandidatesFolderServices';
import { IStartupCandidatesFolderExhibitionData } from 'types/startupCandidatesFolder/IStartupCandidatesFolderExhibitionData';
import {
  ERROR_MODAL_CONFIGURATION,
  SUCCESS_MODAL_CONFIGURATION,
  useAlertModal,
} from 'hooks/alertModal';
import { DeleteModal } from 'components/Modal/DeleteModal';
import { HighRed } from 'assets/colors/palette';
import { useAuth } from 'hooks/auth';
import {
  Container,
  Header,
  CompanyMessageContainer,
  Main,
  FoldersContainer,
  FolderContainer,
  Folder,
  NoFoldersContainer,
  FolderTopContainer,
  FolderIcon,
  FolderDescription,
  CandidatesContainer,
  ExtraCandidatesQuantity,
  FolderModalContainer,
} from './styles';
import { OpenFolderOptionsButton } from './OpenFolderOptionsButton';
import { FolderModal } from '../../FolderModal';

interface SelectedFolderProps {
  name: string;
  id: number;
}

type IFolderModal = 'newFolder' | 'renameFolder' | undefined;

export const TalentBankTab: React.FC = () => {
  const history = useHistory();
  const { startup, updateIsShowingSubscribeModal } = useStartup();
  const { user } = useAuth();
  const { id: startupId } = startup!;
  const { showModal } = useAlertModal();

  const [selectedFolderModal, setSelectedFolderModal] =
    useState<IFolderModal>(undefined);

  const folderNameStates = useInputStates('folderName');
  const [isDeleteFolderModalVisible, setIsDeleteFolderModalVisible] =
    useState(false);

  const [selectedFolder, setSelectedFolder] = useState<
    SelectedFolderProps | undefined
  >(undefined);

  const [isLoadingFolderOperation, setIsLoadingFolderOperation] =
    useState(false);
  const [isLoadingFolders, setIsLoadingFolders] = useState(true);
  const [startupFolders, setStartupFolders] = useState<
    IStartupCandidatesFolderExhibitionData[]
  >([]);

  useEffect(() => {
    findAllStartupCandidatesFolderByStartupIdWithFiledCandidates(
      startupId,
    ).then(findedFolders => {
      setStartupFolders(findedFolders);
      setIsLoadingFolders(false);
    });
  }, [startupId]);

  useEffect(() => {
    if (selectedFolderModal !== 'renameFolder' || !selectedFolder) {
      return;
    }

    folderNameStates.mainState.setFunction(selectedFolder?.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFolderModal, selectedFolder]);

  const getExtraCandidatesQuantity = ({
    filedCandidatesTotalQuantity: candidatesQuantity,
    filedStartupCandidates: { length },
  }: IStartupCandidatesFolderExhibitionData) => {
    if (length === 0) return 'Sem currículos';

    const parsedQuantity = candidatesQuantity - length;
    if (parsedQuantity > 0) return `+${parsedQuantity}`;

    return '';
  };

  const handleCreateStartupFolder = useCallback(async () => {
    const folderName = folderNameStates.mainState.value as string;
    const createdFolder = await createStartupCandidatesFolder({
      name: folderName,
    });

    setStartupFolders(previousFolders => [
      ...previousFolders,
      {
        ...createdFolder,
        filedStartupCandidates: [],
        filedCandidatesTotalQuantity: 0,
      },
    ]);

    showModal({
      ...SUCCESS_MODAL_CONFIGURATION,
      message: `A pasta ${folderName} foi criada com sucesso`,
    });
  }, [folderNameStates.mainState.value, showModal]);

  const handleRenameStartupFolder = useCallback(async () => {
    if (selectedFolder === undefined) return;

    const updatedFolderName = folderNameStates.mainState.value as string;
    await updateStartupCandidatesFolder(selectedFolder.id, {
      name: updatedFolderName,
    });

    setStartupFolders(previousFolders =>
      previousFolders.map(previousFolder => {
        const isDifferentId = previousFolder.id !== selectedFolder.id;

        if (isDifferentId) return previousFolder;
        return { ...previousFolder, name: updatedFolderName };
      }),
    );
  }, [folderNameStates.mainState.value, selectedFolder]);

  const handleExecuteFolderOperation = useCallback(async () => {
    if (selectedFolderModal === undefined) return;

    try {
      setIsLoadingFolderOperation(true);

      await (selectedFolderModal === 'newFolder'
        ? handleCreateStartupFolder
        : handleRenameStartupFolder)();

      setSelectedFolderModal(undefined);
      folderNameStates.mainState.setFunction('');
    } catch {
      showModal({
        ...ERROR_MODAL_CONFIGURATION,
        message: `Erro ao ${
          selectedFolderModal === 'newFolder' ? 'criar a' : 'renomear'
        } pasta, por favor, tente novamente mais tarde`,
      });
    } finally {
      setIsLoadingFolderOperation(false);
    }
  }, [
    folderNameStates.mainState,
    handleCreateStartupFolder,
    handleRenameStartupFolder,
    selectedFolderModal,
    showModal,
  ]);

  const handleDeleteStartupFolder = useCallback(async () => {
    if (selectedFolder === undefined) return;

    try {
      setIsLoadingFolderOperation(true);
      await deleteStartupCandidatesFolder(selectedFolder.id);

      setStartupFolders(previousFolders =>
        previousFolders.filter(({ id }) => id !== selectedFolder.id),
      );

      showModal({
        message: `A pasta ${selectedFolder.name} foi excluída`,
        backgroundColor: HighRed,
        forceIconWhiteColor: true,
        icon: deleteIcon,
      });
      setIsDeleteFolderModalVisible(false);
    } catch {
      showModal({
        ...ERROR_MODAL_CONFIGURATION,
        message:
          'Erro ao excluir a pasta, por favor, tente novamente mais tarde',
      });
    } finally {
      setIsLoadingFolderOperation(false);
    }
  }, [selectedFolder, showModal]);

  const handleCreateStartupFolderButtonClick = useCallback(() => {
    if (user?.subscriber) setSelectedFolderModal('newFolder');
    else updateIsShowingSubscribeModal(true);
  }, [updateIsShowingSubscribeModal, user?.subscriber]);

  return (
    <Container>
      <Modal isVisible={isDeleteFolderModalVisible}>
        <DeleteModal
          modalTitle="Você tem certeza que deseja excluir essa pasta?"
          deleteButtonText="Excluir pasta"
          hideDeleteModal={() => setIsDeleteFolderModalVisible(false)}
          isLoadingDelete={isLoadingFolderOperation}
          handleDelete={handleDeleteStartupFolder}
        />
      </Modal>

      <Modal isVisible={selectedFolderModal !== undefined}>
        <FolderModalContainer>
          <FolderModal
            folderNameStates={folderNameStates}
            handleCancelOperation={() => {
              setSelectedFolderModal(undefined);
              folderNameStates.mainState.setFunction('');
            }}
            handleExecuteFolderOperation={handleExecuteFolderOperation}
            isLoading={isLoadingFolderOperation}
            isRenameModal={selectedFolderModal === 'renameFolder'}
          />
        </FolderModalContainer>
      </Modal>

      <Header>
        <CompanyMessageContainer>
          <h3>Banco de talentos</h3>
          <span>
            Gerencie seus currículos arquivados de forma rápida e fácil
          </span>
        </CompanyMessageContainer>

        <PlusButton
          onClick={handleCreateStartupFolderButtonClick}
          colorStyle="fill"
        >
          Criar nova pasta
        </PlusButton>
      </Header>

      {isLoadingFolders ? (
        <Loader />
      ) : (
        <Main>
          {startupFolders.length === 0 ? (
            <NoFoldersContainer>
              <img src={noFoldersIcon} alt="" />
              <p>
                Você não tem nenhuma pasta cadastrada. Clique em “Criar nova
                pasta” e aproveite todos os recursos que a IN6 pode oferecer!
              </p>
            </NoFoldersContainer>
          ) : (
            <FoldersContainer>
              {startupFolders.map(startupFolder => (
                <FolderContainer key={startupFolder.id}>
                  <Folder
                    type="button"
                    onClick={() =>
                      history.push(routeStartupFolderCandidates, {
                        startupFolder: {
                          id: startupFolder.id,
                          name: startupFolder.name,
                          createdAt: startupFolder.createdAt,
                          updatedAt: startupFolder.updatedAt,
                        },
                      })
                    }
                  >
                    <FolderTopContainer>
                      <FolderIcon>
                        <img src={folderIcon} alt="Pasta" />
                      </FolderIcon>

                      <FolderDescription>
                        <span className="folder-name">
                          {startupFolder.name}
                        </span>
                        <span className="folder-creation-date">
                          Criado em{' '}
                          {formatDateToBrazilianString(
                            startupFolder.createdAt,
                            '',
                            true,
                          )}
                        </span>
                      </FolderDescription>
                    </FolderTopContainer>

                    <small>Currículos arquivados</small>

                    <CandidatesContainer>
                      {[0, 1, 2, 3].map(
                        index =>
                          startupFolder.filedStartupCandidates[index] && (
                            <UserIcon
                              key={
                                startupFolder.filedStartupCandidates[index].id
                              }
                              userName={
                                startupFolder.filedStartupCandidates[index].name
                              }
                              image={
                                startupFolder.filedStartupCandidates[index]
                                  .photo
                              }
                            />
                          ),
                      )}

                      <ExtraCandidatesQuantity>
                        {getExtraCandidatesQuantity(startupFolder)}
                      </ExtraCandidatesQuantity>
                    </CandidatesContainer>
                  </Folder>

                  <OpenFolderOptionsButton
                    handleOpenRenameFolderModal={() => {
                      setSelectedFolderModal('renameFolder');
                      setSelectedFolder(startupFolder);
                    }}
                    handleOpenDeleteFolderModal={() => {
                      setIsDeleteFolderModalVisible(true);
                      setSelectedFolder(startupFolder);
                    }}
                  />
                </FolderContainer>
              ))}
            </FoldersContainer>
          )}
        </Main>
      )}
    </Container>
  );
};
