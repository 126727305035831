import { LevelOfSchooling } from 'types';
import { levelOfSchoolingOptions } from 'utils/typeOptions/levelOfSchoolingOptions';

export const convertLevelOfSchoolingToPortuguese = (
  levelOfSchooling: LevelOfSchooling,
): string => {
  const { label } = levelOfSchoolingOptions.find(
    ({ enumValue }) => enumValue === levelOfSchooling,
  ) ?? { label: 'Analfabeto' };

  return label;
};
