import { OptionButton, SecondaryButton } from 'components/Buttons';
import { Input } from 'components/Input';
import { Loader } from 'components/Loader';
import { ReactInputState } from 'types/reactInputState';
import { ButtonsContainer, CreationContainer, TopContainer } from './styles';

interface FolderModalProps {
  isLoading: boolean;
  folderNameStates: ReactInputState;
  handleCancelOperation: () => void;
  handleExecuteFolderOperation: () => Promise<void>;
  isRenameModal?: boolean;
}

export const FolderModal: React.FC<FolderModalProps> = ({
  isLoading,
  folderNameStates,
  handleCancelOperation,
  handleExecuteFolderOperation,
  isRenameModal,
}) => {
  return (
    <>
      <TopContainer>
        <span>{isRenameModal ? 'Renomear pasta' : 'Nova pasta'}</span>
      </TopContainer>

      {isLoading ? (
        <Loader
          style={{
            minHeight: 0,
            padding: 32,
            background: 'transparent',
            marginTop: 100,
          }}
        />
      ) : (
        <CreationContainer>
          <Input
            style={{ width: 398, height: 48 }}
            placeholder="inserir título da pasta"
            states={folderNameStates}
            notScrollToInput
            notHasError
          />

          <ButtonsContainer>
            <OptionButton type="button" onClick={handleCancelOperation}>
              Cancelar
            </OptionButton>

            <SecondaryButton
              type="button"
              onClick={handleExecuteFolderOperation}
              disabled={
                (folderNameStates.mainState.value as string).length === 0
              }
            >
              {isRenameModal ? 'Salvar' : 'Criar'}
            </SecondaryButton>
          </ButtonsContainer>
        </CreationContainer>
      )}
    </>
  );
};
