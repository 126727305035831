import styled, { css } from 'styled-components';
import {
  BackgroundColor,
  HighEmphasis,
  OutlineColor,
} from 'assets/colors/palette';
import { DashboardHeaderOptionsBox } from 'components/DashboardHeader/DashboardHeaderOptionsBox/styles';
import { Medium14Styles } from 'assets/fonts/fontStyles';

interface OpenOptionsButtonProps {
  isOptionsVisible: boolean;
}

export const ImageContainer = styled.aside`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  background: #f0f0f0;
  border-radius: 50%;
  transition: 0.2s;
`;

export const Container = styled.div`
  padding-left: 14px;
  max-width: 416px;
  height: 48px;

  display: flex;
  align-items: center;
  gap: 14px;

  border: 1px solid ${OutlineColor};
  position: relative;
  border-radius: 100px;

  ${DashboardHeaderOptionsBox} button:hover {
    ${ImageContainer} {
      background-color: #fce8e5;

      img {
        filter: invert(56%) sepia(67%) saturate(7443%) hue-rotate(1deg)
          brightness(102%) contrast(105%);
      }
    }
  }

  .candidate-data {
    margin-right: -1px;
    padding: 0px 16px;
    height: 48px;
    border: 1px solid ${OutlineColor};
    background: ${BackgroundColor};
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    transition: 0.2s;

    #candidate-name {
      ${Medium14Styles}
      color: ${HighEmphasis};
      text-align: start;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  @media (max-width: 1100px) {
    max-width: 100%;
  }
`;

export const OpenOptionsButton = styled.button<OpenOptionsButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  transition: 0.3s;

  img {
    transform: rotate(90deg);
  }

  ${({ isOptionsVisible }) =>
    isOptionsVisible &&
    css`
      background-color: rgba(238, 47, 35, 0.15);

      img {
        filter: invert(43%) sepia(50%) saturate(7497%) hue-rotate(3deg)
          brightness(104%) contrast(102%);
      }
    `}
`;
