import * as yup from 'yup';
import companyIconBlack from 'assets/img/companyIconBlack.svg';
import candidateIcon from 'assets/img/candidateIcon.svg';
import in6Logo from 'assets/img/in6Logo.svg';

import { PrimaryButton } from 'components/Buttons';
import { Input } from 'components/Input';
import { AcceptTerms } from 'components/AcceptTerms';
import { useCallback, useEffect, useState } from 'react';
import { generateFormObjectFromStates } from 'utils';
import { yupRequiredStringField } from 'utils/validation';
import { IUserRequest, UserAccountType } from 'types/user';
import {
  authenticateUser,
  createUser,
} from 'services/entitiesServices/userServices';
import { useHistory, useLocation } from 'react-router-dom';
import { useInputStates } from 'utils/customHooks';
import { Header } from 'components/Header';
import { routeBar, routeLogin } from 'routes/routesAddresses';
import { redirectToConfirmAuthPageBasedOnUserAccountType } from 'utils/redirectToConfirmAuthPageBasedOnUserAccountType';
import { useFormError } from 'errors/useFormError';
import { IServerError } from 'types';
import { ERROR_MODAL_CONFIGURATION, useAlertModal } from 'hooks/alertModal';
import { DotFlashing } from 'components/DotFlashing';
import { useWindowWidth } from 'hooks/windowWidth';
import {
  Container,
  TitleContainer,
  AccountTypeContainer,
  NameInputs,
  AccountTypeButtonsContainer,
  AccountTypeButton,
  SubTitle,
  Title,
} from './styles';

export const SignUpPage: React.FC = () => {
  const history = useHistory();
  const { isMobileScreen } = useWindowWidth();
  const { showModal } = useAlertModal();
  const { handleFormError } = useFormError();
  const { state } = useLocation() as {
    state: { candidateAccountTypeSelected?: boolean } | undefined;
  };

  const [selectedAccountType, setSelectedAccountType] =
    useState<UserAccountType>(
      state ? UserAccountType.CANDIDATE : UserAccountType.EMPLOYER,
    );
  const nameStates = useInputStates('name');
  const lastNameStates = useInputStates('lastName');
  const emailStates = useInputStates('email');
  const passwordStates = useInputStates('password');
  const [isLoadingSignUp, setIsLoadingSignUp] = useState(false);

  useEffect(() => {
    const formStates = [
      nameStates,
      lastNameStates,
      emailStates,
      passwordStates,
    ];

    formStates.forEach(formState =>
      formState.errorMessageState.setFunction(''),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccountType]);

  const handleSubmit = useCallback(async () => {
    setIsLoadingSignUp(true);
    const formStates = [
      nameStates,
      lastNameStates,
      emailStates,
      passwordStates,
    ];
    const formObject = generateFormObjectFromStates(formStates);

    try {
      const schema = yup.object().shape({
        name: yupRequiredStringField,
        lastName: yupRequiredStringField,
        email: yupRequiredStringField.email('Formato de e-mail inválido'),
        password: yupRequiredStringField.min(
          8,
          'A senha precisa ter, no mínimo, 8 caracteres',
        ),
      });

      await schema.validate(formObject, { abortEarly: false });
      await createUser({
        ...formObject,
        accountType: selectedAccountType,
      } as IUserRequest);

      const { token, user } = await authenticateUser({
        email: formObject.email as string,
        password: formObject.password as string,
      });

      redirectToConfirmAuthPageBasedOnUserAccountType(token, false, user);
    } catch (error) {
      setIsLoadingSignUp(false);

      if (error instanceof yup.ValidationError) {
        handleFormError(error as Error | yup.ValidationError, formStates);
        return;
      }

      const {
        response: {
          data: { status },
        },
      } = error as IServerError;

      if (status === 400) {
        showModal({
          ...ERROR_MODAL_CONFIGURATION,
          message:
            'O e-mail inserido já está sendo utilizado por outro usuário',
        });
      }
    }
  }, [
    emailStates,
    handleFormError,
    lastNameStates,
    nameStates,
    passwordStates,
    selectedAccountType,
    showModal,
  ]);

  return (
    <>
      <Header logo={in6Logo} redirectRoute={routeBar} />

      <Container>
        <TitleContainer>
          <Title>Criar uma conta</Title>
          <SubTitle>
            Já tem uma conta IN6?{' '}
            <button type="button" onClick={() => history.push(routeLogin)}>
              Entre agora
            </button>
          </SubTitle>
        </TitleContainer>

        <AccountTypeContainer>
          <span>Escolha qual tipo de conta deseja criar</span>

          <AccountTypeButtonsContainer>
            <AccountTypeButton
              type="button"
              isSelected={selectedAccountType === UserAccountType.EMPLOYER}
              onClick={() => setSelectedAccountType(UserAccountType.EMPLOYER)}
            >
              <img src={companyIconBlack} alt="Ícone de empresa" />
              <span>Empresa</span>
            </AccountTypeButton>

            <AccountTypeButton
              type="button"
              isSelected={selectedAccountType === UserAccountType.CANDIDATE}
              onClick={() => setSelectedAccountType(UserAccountType.CANDIDATE)}
            >
              <img src={candidateIcon} alt="Ícone de candidato" />
              <span>Candidato</span>
            </AccountTypeButton>
          </AccountTypeButtonsContainer>
        </AccountTypeContainer>

        <NameInputs>
          <Input
            name="Nome"
            placeholder="inserir nome"
            states={nameStates}
            style={{ width: isMobileScreen ? '42vw' : 188 }}
          />

          <Input
            name="Sobrenome"
            placeholder="inserir sobrenome"
            states={lastNameStates}
            style={{ width: isMobileScreen ? '42vw' : 188 }}
          />
        </NameInputs>

        <Input
          name={`Email${
            selectedAccountType === UserAccountType.EMPLOYER
              ? ' corporativo'
              : ''
          }`}
          placeholder="inserir email"
          states={emailStates}
        />

        <Input
          name="Senha"
          placeholder="inserir senha"
          type="password"
          states={passwordStates}
        />

        <PrimaryButton
          type="submit"
          onClick={handleSubmit}
          disabled={isLoadingSignUp}
        >
          {isLoadingSignUp ? (
            <>
              Carregando
              <DotFlashing />
            </>
          ) : (
            'Criar conta'
          )}
        </PrimaryButton>
        <AcceptTerms confirmButtonName="Criar conta" />
      </Container>
    </>
  );
};
