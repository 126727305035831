import { routeDashboard } from 'routes/routesAddresses';

export const routeCreateStartup = `${routeDashboard}/createStartup`;
export const routeEditStartup = `${routeDashboard}/editStartup`;

export const routeTalentBank = `${routeDashboard}/talentBank`;
export const routeStartupFolderCandidates = `${routeTalentBank}/folderCandidates`;

export const routeStartupVacancies = `${routeDashboard}/vacancies`;
export const routeCreateVacancy = `${routeStartupVacancies}/createVacancy`;
export const routeEditVacancy = `${routeStartupVacancies}/editVacancy`;
export const routeVacancyDetails = `${routeStartupVacancies}/vacancyDetails`;
