import { PrimaryButton } from 'components/Buttons';
import { format } from 'date-fns/esm';
import { useCandidate } from 'hooks/candidate';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  routePerformTest,
  routeTestResults,
} from 'routes/candidatesRoutes/candidatesRoutesAddresses';
import { CandidateTest } from 'types/candidate';
import { translateCandidateTest } from 'utils/conversion/translateCandidateTest';
import { Container, Header, TestInformation, TestsContainer } from './styles';

type TestsCreationDate = { [key in CandidateTest]: Date };

const TESTS_DESCRIPTIONS = {
  [CandidateTest.PROFESSIONAL_PROFILE]: (
    <p>
      Avalia os diferentes fatores que
      <br /> determinam seu comportamento
    </p>
  ),
  [CandidateTest.LOGICAL_REASONING]: (
    <p>
      Avalia a aptidão para compreensão de informações e resolução de problemas
    </p>
  ),
  [CandidateTest.PORTUGUESE]: (
    <p>
      Avalia a capacidade de leitura
      <br /> e interpretação de textos
    </p>
  ),
};

export const CandidateTestsTab: React.FC = () => {
  const history = useHistory();
  const { candidate } = useCandidate();

  const testsCreationDate: TestsCreationDate = useMemo(() => {
    const parsedTests: TestsCreationDate = {} as TestsCreationDate;
    if (candidate === undefined) return parsedTests;

    candidate.testEvaluations.forEach(({ candidateTest, createdAt }) => {
      parsedTests[candidateTest] = new Date(createdAt);
    });

    return parsedTests;
  }, [candidate]);

  if (candidate === undefined) return null;
  return (
    <Container>
      <Header>
        <h3>Minhas avaliações</h3>
        <span>Visualize os testes que avaliam seu perfil profissional</span>
      </Header>

      <TestsContainer>
        {Object.entries(TESTS_DESCRIPTIONS).map(([key, description]) => (
          <TestInformation key={key}>
            <h2>Teste de {translateCandidateTest(key as CandidateTest)}</h2>
            {description}

            {testsCreationDate[key as CandidateTest] ? (
              <>
                <span className="test-date">
                  Realizado em{' '}
                  {format(
                    testsCreationDate[key as CandidateTest],
                    'dd/MM/yyyy',
                  )}
                </span>

                <PrimaryButton
                  type="button"
                  onClick={() =>
                    history.push(routeTestResults, { testType: key })
                  }
                >
                  Ver resultado
                </PrimaryButton>
              </>
            ) : (
              <PrimaryButton
                type="button"
                onClick={() =>
                  history.push(routePerformTest, { testType: key })
                }
              >
                Iniciar teste
              </PrimaryButton>
            )}
          </TestInformation>
        ))}
      </TestsContainer>
    </Container>
  );
};
