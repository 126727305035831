import attentionIcon from 'assets/img/attention.svg';
import in6Logo from 'assets/img/in6Logo.svg';
import whiteArrowRight from 'assets/img/whiteArrowRight.svg';

import { PrimaryButton } from 'components/Buttons';
import { Header } from 'components/Header';
import { COMPANY_NAME } from 'constants/company';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { routeBar } from 'routes/routesAddresses';
import { MainContainer, SubContainer, Text, Title } from './styles';

export const VacancyUnavailablePage: React.FC = () => {
  document.title = `Vaga Indisponível - ${COMPANY_NAME}`;
  const history = useHistory();

  useEffect(() => {
    return () => {
      document.title = COMPANY_NAME;
    };
  }, [history]);

  return (
    <>
      <Header logo={in6Logo} redirectRoute={routeBar} />

      <MainContainer>
        <SubContainer>
          <img src={attentionIcon} alt="Exclamação" />

          <Title>Vaga indisponível</Title>

          <Text>
            No momento, não é possível se candidatar para esta vaga. A empresa
            suspendeu o recebimento de novos currículos ou a vaga já foi
            encerrada.
          </Text>

          <PrimaryButton type="button" onClick={() => history.push(routeBar)}>
            <span>Buscar por novas vagas</span>
            <img src={whiteArrowRight} alt="Seta para a direita" />
          </PrimaryButton>
        </SubContainer>
      </MainContainer>
    </>
  );
};
