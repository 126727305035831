import garbageChart from 'assets/img/testsImages/garbageChart.svg';
import shoppingTable from 'assets/img/testsImages/shoppingTable.svg';
import setDiagram from 'assets/img/testsImages/setDiagram.svg';

import { ITestQuestion } from '..';

export const LOGICAL_REASONING_QUESTIONS: ITestQuestion<undefined>[] = [
  {
    question: (
      <>
        <p>
          Uma empresa de transportes recentemente adquiriu um novo caminhão e
          está analisando sua autonomia no consumo de combustível. Nos testes
          esse caminhão percorreu 7 km com um litro de diesel comum. Já com
          diesel aditivado este mesmo veículo percorreu 10,5 km com um litro.
        </p>
        <br />
        <p>
          Sabe-se que o preço do litro de diesel comum é de R$ 2,80 e o preço
          por litro de diesel aditivado é de R$ 3,10. Comparando-se a despesa
          com combustível que esse novo caminhão consumirá em um percurso de 525
          km, a economia, em reais, ao ser utilizado o diesel aditivado em
          relação ao uso do diesel comum é, aproximadamente, igual a:
        </p>
      </>
    ),
    answers: [
      {
        text: 'R$ 55,00',
      },
      {
        text: 'R$ 63,00',
      },
      {
        text: 'R$ 48,00',
      },
      {
        text: 'R$ 45,00',
      },
    ],
    correctAnswerIndex: 0,
  },
  {
    question: (
      <p>
        A palavra que completa o grupo formado por maçã, mamão, manga, maracujá,
        melancia é:
      </p>
    ),
    answers: [
      {
        text: 'Jabuticaba',
      },
      {
        text: 'Ameixa',
      },
      {
        text: 'Melão',
      },
      {
        text: 'Abacate',
      },
    ],
    correctAnswerIndex: 2,
  },
  {
    question: (
      <p>
        Na sequência de formação lógica{' '}
        <strong>18; 22; 21; 25; 24; 28; 27; 31; 30; 34; . . .</strong>, a soma
        dos números maiores que 40 e menores que 50 é igual a:
      </p>
    ),
    answers: [
      {
        text: '563',
      },
      {
        text: '132',
      },
      {
        text: '352',
      },
      {
        text: '273',
      },
    ],
    correctAnswerIndex: 3,
  },
  {
    question: (
      <>
        <p>
          O total de lixo produzido por semana e a parte desse total que não é
          reciclada em três comunidades A, B e C são dados no gráfico a seguir.
          É correto concluir a partir desses dados que:
        </p>
        <img
          src={garbageChart}
          draggable={false}
          alt="Gráfico quantidades de lixo"
        />
      </>
    ),
    answers: [
      {
        text: 'A comunidade B é a que recicla a maior quantidade de lixo.',
      },
      {
        text: 'Mais da metade do lixo gerado nas três comunidades é reciclado.',
      },
      {
        text: 'A comunidade que tem a menor porcentagem de lixo reciclado em relação ao lixo que produz é a A.',
      },
      {
        text: 'Mais da metade do lixo reciclado é produzido pelas comunidades A e B juntas.',
      },
    ],
    correctAnswerIndex: 2,
  },
  {
    question: (
      <p>
        Para montar 800 caixas com produtos, uma fábrica utiliza 15 funcionários
        que trabalham 6 horas por dia. Esse trabalho é realizado em 32 dias.
        Para atender um pedido de 2.000 caixas com produtos, iguais às
        anteriores, a fábrica recrutou mais 5 funcionários, de mesma
        produtividade, além dos 15 funcionários já alocados para a função. O
        número de horas de trabalho por dia foi aumentado para 8 horas. Nessas
        condições, o número de dias necessários para montagem dessas 2.000
        caixas é igual a:
      </p>
    ),
    answers: [
      {
        text: '25',
      },
      {
        text: '60',
      },
      {
        text: '38',
      },
      {
        text: '45',
      },
    ],
    correctAnswerIndex: 3,
  },
  {
    question: (
      <p>
        Em uma loja de bijuterias, todos os produtos são vendidos por um dentre
        os seguintes preços: R$ 5,00, R$ 7,00 ou R$ 10,00. Ana Paula gastou R$
        65,00 nessa loja, tendo adquirido pelo menos um produto de cada preço.
        Considerando apenas essas informações, o número mínimo e o número máximo
        de produtos que Márcia pode ter comprado são, respectivamente, iguais a:
      </p>
    ),
    answers: [
      {
        text: '8 e 10',
      },
      {
        text: '9 e 10',
      },
      {
        text: '8 e 11',
      },
      {
        text: '9 e 11',
      },
    ],
    correctAnswerIndex: 1,
  },
  {
    question: (
      <p>
        Seis pessoas, dentre as quais está Artur, estão aguardando em uma fila
        para serem atendidas pelo caixa de uma loja. Nesta fila, Breno está à
        frente de Nida, que se encontra imediatamente atrás de Paula. Isabele
        não é a primeira da fila, mas está mais próxima do primeiro lugar do que
        do último. Sabendo que Lília será atendida antes do que Breno e que
        Breno não é o quarto da fila, pode-se concluir que a pessoa que ocupa a
        quarta posição da fila:
      </p>
    ),
    answers: [
      {
        text: 'Certamente é Nida',
      },
      {
        text: 'Pode ser Paula ou Artur',
      },
      {
        text: 'Certamente é Artur',
      },
      {
        text: 'Pode ser Paula ou Breno',
      },
    ],
    correctAnswerIndex: 0,
  },
  {
    question: (
      <p>
        Qual das afirmações abaixo é a negação lógica da proposição “Todas as
        reclamações serão anotadas e nós tomaremos as providências necessárias”?
      </p>
    ),
    answers: [
      {
        text: 'Algumas reclamações não serão anotadas e nós não tomaremos as providências necessárias.',
      },
      {
        text: 'Algumas reclamações serão anotadas e nós não tomaremos as providências necessárias.',
      },
      {
        text: 'Todas as reclamações serão anotadas e nós não tomaremos as providências necessárias.',
      },
      {
        text: 'Algumas reclamações não serão anotadas ou nós não tomaremos as providências necessárias.',
      },
    ],
    correctAnswerIndex: 3,
  },
  {
    question: (
      <p>
        Examinando o padrão da série: <strong>33, 31, 27, 25, 21</strong>... que
        número virá a seguir?
      </p>
    ),
    answers: [
      {
        text: '24',
      },
      {
        text: '19',
      },
      {
        text: '17',
      },
      {
        text: '20',
      },
    ],
    correctAnswerIndex: 1,
  },
  {
    question: (
      <>
        <p>
          A tabela a seguir mostra as condições de pagamento praticadas por uma
          loja. Para qualquer valor de compra, o cliente pode optar ainda pelo
          pagamento em 10 vezes, sendo cobrado nesse caso um acréscimo de 20%
          sobre o valor total. Se um cliente fizer uma compra no valor de R$
          420,00 nesta loja, então ele poderá optar entre as seguintes formas de
          pagamento:
        </p>
        <img src={shoppingTable} draggable={false} alt="Tabela de compras" />
      </>
    ),
    answers: [
      {
        text: '3 parcelas de R$ 150,00 ou 10 parcelas de R$ 50,40',
      },
      {
        text: '4 parcelas de R$ 105,00 ou 10 parcelas de R$ 42,00',
      },
      {
        text: '4 parcelas de R$ 105,00 ou 10 parcelas de R$ 50,40',
      },
      {
        text: '5 parcelas de R$ 84,00 ou 10 parcelas de R$ 42,00',
      },
    ],
    correctAnswerIndex: 2,
  },
  {
    question: (
      <>
        <p>De acordo com o diagrama abaixo não é correto afirmar que:</p>
        <img src={setDiagram} draggable={false} alt="Diagrama de conjuntos" />
      </>
    ),
    answers: [
      {
        text: 'Nem todo C é A',
      },
      {
        text: 'Todo C é B',
      },
      {
        text: 'Há B que não é C.',
      },
      {
        text: 'Pode haver A que não é B.',
      },
    ],
    correctAnswerIndex: 0,
  },
  {
    question: (
      <>
        <p>Considere a seguinte sequência de declarações, todas verdadeiras:</p>
        <br />
        <p>
          l. Se o tempo estiver bom, então Isabele e Lília irão ao shopping.
          <br />
          II. Se Isabele e Lília forem juntas ao shopping, então Artur ficará
          sozinho.
          <br />
          III. Se Artur ficar sozinho, então a festa será na casa dele.
        </p>
        <br />
        <p>
          Sabe-se que a festa não foi na casa de Artur. Nessas condições, dentre
          as declarações a seguir, todas relacionadas às três anteriores, a
          única que não é necessariamente verdadeira é:
        </p>
      </>
    ),
    answers: [
      {
        text: 'Artur não ficou sozinho.',
      },
      {
        text: 'Isabele não foi ao shopping.',
      },
      {
        text: 'O tempo não estava bom.',
      },
      {
        text: 'Havia alguém com Artur ou o tempo não estava bom',
      },
    ],
    correctAnswerIndex: 1,
  },
];
