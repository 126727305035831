/* eslint-disable react-hooks/rules-of-hooks */
import { Loader } from 'components/Loader';
import {
  ERROR_MODAL_CONFIGURATION,
  SUCCESS_MODAL_CONFIGURATION,
  useAlertModal,
} from 'hooks/alertModal';
import { useAuth } from 'hooks/auth';
import { useEffect } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { routeBar, routeLogin } from 'routes/routesAddresses';
import { disableWantsEmailNotificationsViaUserToken } from 'services/entitiesServices/userServices';

export const DisableEmailNotifications: React.FC = () => {
  const history = useHistory();
  const { showModal } = useAlertModal();
  const { user, updateUserWantsEmailNotifications } = useAuth();

  const params = useParams() as { userToken: string } | undefined;
  if (!params) return <Redirect to={routeBar} />;

  useEffect(() => {
    disableWantsEmailNotificationsViaUserToken(params.userToken)
      .then(() => {
        if (user !== undefined) updateUserWantsEmailNotifications(false, true);

        showModal({
          ...SUCCESS_MODAL_CONFIGURATION,
          message: 'Notificações via email desativadas com sucesso',
        });
      })
      .catch(() =>
        showModal({
          ...ERROR_MODAL_CONFIGURATION,
          message:
            'Erro ao desativar notificações, por favor, tente novamente mais tarde',
        }),
      )
      .finally(() => history.push(routeLogin));
  }, [
    history,
    params.userToken,
    showModal,
    updateUserWantsEmailNotifications,
    user,
  ]);

  return <Loader />;
};
