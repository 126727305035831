/* eslint-disable import/no-duplicates */
import { format } from 'date-fns';
import brazilianLocale from 'date-fns/locale/pt-BR';

export const formatDateToBrazilianString = (
  dateToConvert: Date,
  dateConnective = '',
  reducedMonthName = false,
): string => {
  const dateFormat = `dd MMM${reducedMonthName ? '.' : 'M'} yyyy`;
  const formattedDate = format(new Date(dateToConvert), dateFormat, {
    locale: brazilianLocale,
  });
  if (dateConnective.length === 0) return formattedDate;

  const parsedConnective = ` ${dateConnective} `;
  return formattedDate.replaceAll(' ', parsedConnective);
};
