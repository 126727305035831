import {
  BackgroundColor,
  ErrorDefaut,
  GrayLine,
  HighEmphasis,
  LowOrange,
  MediumEmphasis,
  OutlineColor,
  PrimaryColor,
} from 'assets/colors/palette';
import { SelectHTMLAttributes, useEffect, useRef } from 'react';
import ReactSelect, { OptionTypeBase, StylesConfig } from 'react-select';
import { ReactInputState } from 'types/reactInputState';
import {
  InputErrorMessageStyle,
  InputNameStyle,
} from 'components/Input/styles';
import { Depth8 } from 'assets/colors/boxShadows';
import { Container } from './styles';

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  states: ReactInputState;
  selectOptions: OptionTypeBase[];
  onChange?: () => void;
  loadingIndicatorDisabled?: boolean;
  notHasError?: boolean;
  startWithoutValue?: boolean;
}

export const Select: React.FC<SelectProps> = ({
  name,
  states: {
    mainState: { setFunction: mainFunction, value: mainValue },
    errorMessageState: { setFunction: errorFunction, value: errorValue },
  },
  selectOptions,
  onChange,
  placeholder,
  disabled,
  loadingIndicatorDisabled,
  notHasError,
  startWithoutValue,
  style: receivedStyles,
}) => {
  const selectRef = useRef<HTMLDivElement>(null);

  const borderColor = errorValue ? ErrorDefaut : GrayLine;
  const hoverAndFocusColor = errorValue ? ErrorDefaut : PrimaryColor;

  const selectStyles: StylesConfig<OptionTypeBase, false> = {
    menuList: styles => ({
      ...styles,
      '::-webkit-scrollbar': {
        width: 4,
      },
      '::-webkit-scrollbar-track': {
        background: 'rgba(217, 217, 217, 0.2)',
      },
      '::-webkit-scrollbar-thumb': {
        background: MediumEmphasis,
        borderRadius: 100,
      },
      boxShadow: Depth8,
      borderRadius: 8,
    }),
    control: ({ isDisabled, ...styles }) => ({
      ...styles,
      height: 40,
      borderRadius: 8,
      boxShadow: 'none',
      border: `solid 1px ${borderColor}`,
      '&:hover': {
        border: `solid 2px ${hoverAndFocusColor}`,
      },
      letterSpacing: 0.75,
      fontSize: 14,
      backgroundColor: isDisabled ? '#f2f2f2' : BackgroundColor,
      ...receivedStyles,
    }),
    option: (styles, { isFocused }) => ({
      ...styles,
      color: HighEmphasis,
      backgroundColor: isFocused ? OutlineColor : undefined,
      '&:active': {
        background: LowOrange,
      },
    }),
  };

  useEffect(() => {
    if (startWithoutValue) return;

    if (selectOptions.length && !mainValue) mainFunction(selectOptions[0].id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainFunction, selectOptions, startWithoutValue]);

  return (
    <Container
      ref={selectRef}
      className="select"
      style={{ paddingTop: name ? 30 : 0 }}
    >
      <InputNameStyle disabled={disabled}>{name}</InputNameStyle>

      <ReactSelect
        components={loadingIndicatorDisabled ? { LoadingIndicator: null } : {}}
        value={
          !mainValue
            ? mainValue
            : selectOptions.find(option => option.id === Number(mainValue))
        }
        styles={selectStyles}
        options={selectOptions}
        placeholder={placeholder}
        isDisabled={disabled}
        isLoading={disabled}
        onFocus={() => {
          const { current } = selectRef;
          if (!current) return;

          current.scrollIntoView();
        }}
        onChange={async event => {
          mainFunction(event?.id.toString());

          if (onChange) onChange();
          if (errorValue) errorFunction('');
        }}
      />

      {!notHasError && (
        <InputErrorMessageStyle
          style={{
            width: receivedStyles?.width ?? 400,
            maxWidth: '100%',
            marginLeft: receivedStyles?.marginLeft ?? 0,
          }}
        >
          {errorValue}
        </InputErrorMessageStyle>
      )}
    </Container>
  );
};
