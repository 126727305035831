import in6Logo from 'assets/img/in6Logo.svg';
import whiteArrowRight from 'assets/img/whiteArrowRight.svg';

import { PrimaryButton } from 'components/Buttons';
import { Header } from 'components/Header';
import { Loader } from 'components/Loader';
import { useAuth } from 'hooks/auth';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { routeBar, routeLogin } from 'routes/routesAddresses';
import { sendActivationEmail } from 'services/activateAccountServices';
import { EmailSentPageParams } from 'utils/customHooks/redirects/useRedirectToEmailSentPage';
import { Container, NumberIcon } from './styles';

export const EmailSentPage: React.FC = () => {
  const {
    state: { title, email, description, lastStepMessage, emailType },
  } = useLocation() as { state: EmailSentPageParams };

  const history = useHistory();
  const { signOut } = useAuth();
  const [isSendingEmail, setIsSendingEmail] = useState(false);

  const selectedSendEmailFunction = useMemo(() => {
    const defaultTestFunction = async () => null;

    switch (emailType) {
      case 'activation':
        return sendActivationEmail;
      case 'forgotPassword':
        return defaultTestFunction;
      default:
        return async () => null;
    }
  }, [emailType]);

  useEffect(() => {
    selectedSendEmailFunction(email).then(() => setIsSendingEmail(false));
  }, [email, selectedSendEmailFunction]);

  return (
    <>
      <Header redirectRoute={routeBar} logo={in6Logo} />

      {isSendingEmail ? (
        <Loader />
      ) : (
        <Container>
          <h2>{title}</h2>
          <p className="description">{description}</p>

          <section>
            <NumberIcon>1</NumberIcon>
            <p>Acesse o email</p>
            <p className="email">{email}</p>
          </section>

          <section>
            <NumberIcon>2</NumberIcon>
            <p>Abra a nossa mensagem de verificação</p>
          </section>

          <section>
            <NumberIcon>3</NumberIcon>
            <p>{lastStepMessage}</p>
          </section>

          <PrimaryButton
            type="button"
            onClick={() => {
              signOut();
              history.push(routeLogin);
              history.go(0);
            }}
          >
            <span>Voltar para a página de acesso</span>
            <img src={whiteArrowRight} alt="Seta para direita" />
          </PrimaryButton>
        </Container>
      )}
    </>
  );
};
