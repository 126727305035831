import { HighEmphasis, OutlineColor } from 'assets/colors/palette';
import { Medium16Styles } from 'assets/fonts/fontStyles';
import styled from 'styled-components';

export const Container = styled.div`
  width: 180px;
  height: 180px;
  background-color: ${OutlineColor};
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  input[type='file'] {
    display: none;
  }
`;

export const PreviewImage = styled.img`
  width: 180px;
  height: 180px;
  border-radius: 8px;
`;

export const NoImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;

  ${Medium16Styles}
  color: ${HighEmphasis};
  text-align: center;
`;
