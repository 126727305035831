import styled from 'styled-components';
import {
  BackgroundColor,
  MediumEmphasis,
  OutlineColor,
} from 'assets/colors/palette';
import { Medium16Styles, Regular24Styles } from 'assets/fonts/fontStyles';

export const Container = styled.div`
  width: 441px;
  height: 317px;
  padding: 16px;
  position: relative;

  background-color: ${BackgroundColor};
  border: 1px solid ${OutlineColor};
  border-radius: 8px;
  overflow-y: scroll;

  display: flex;
  flex-flow: wrap;
  align-content: flex-start;
  cursor: text;
  gap: 10px;

  &::-webkit-scrollbar {
    display: none;
  }

  .placeholder {
    ${Regular24Styles}
    font-size: 12px;
    color: ${MediumEmphasis};

    position: absolute;
    left: 16px;
    top: 16px;
    transition: 0.2s;
  }

  input {
    min-width: 16px;
    width: 16px;
    height: 36px;
  }

  input,
  span {
    ${Medium16Styles}
    line-height: 36px;
    max-width: 100%;
    outline: 0;
  }
`;

export const CriteriaContainer = styled.div`
  max-width: 100%;
  max-height: 36px;
  padding: 12px 16px;
  gap: 12px;

  background-color: ${BackgroundColor};
  border: 1px solid ${OutlineColor};
  border-radius: 8px;

  &,
  button {
    display: flex;
    align-items: center;
  }

  button {
    justify-content: center;
    background-color: transparent;

    img {
      width: 16px;
      height: 16px;
    }
  }
`;
