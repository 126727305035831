import { SecondaryButton } from 'components/Buttons';
import { Loader } from 'components/Loader';
import { ERROR_MODAL_CONFIGURATION, useAlertModal } from 'hooks/alertModal';
import { useCallback, useState } from 'react';
import { CancelButton, Container, TopContainer } from './styles';

interface GiveUpModalProps {
  handleGiveUpVacancy: () => Promise<void>;
  handleCloseModal: () => void;
}

export const GiveUpModal: React.FC<GiveUpModalProps> = ({
  handleGiveUpVacancy,
  handleCloseModal,
}) => {
  const { showModal } = useAlertModal();
  const [isLoadingGiveUp, setIsLoadingGiveUp] = useState(false);

  const handleGiveUpButtonClick = useCallback(async () => {
    setIsLoadingGiveUp(true);

    try {
      await handleGiveUpVacancy();
      handleCloseModal();
    } catch {
      showModal({
        ...ERROR_MODAL_CONFIGURATION,
        message:
          'Ocorreu uma falha ao desistir da vaga, por favor, tente novamente mais tarde',
      });
    } finally {
      setIsLoadingGiveUp(false);
    }
  }, [handleCloseModal, handleGiveUpVacancy, showModal]);

  return (
    <Container>
      <TopContainer>Desistir da vaga</TopContainer>

      {isLoadingGiveUp ? (
        <Loader style={{ minHeight: 0, height: 'auto', marginTop: 140 }} />
      ) : (
        <>
          <p>
            Essa ação não poderá ser desfeita. Se você deseja confirmar sua
            desistência da vaga, clique em desistir.
          </p>

          <SecondaryButton type="button" onClick={handleGiveUpButtonClick}>
            Desistir
          </SecondaryButton>
          <CancelButton type="button" onClick={handleCloseModal}>
            Cancelar
          </CancelButton>
        </>
      )}
    </Container>
  );
};
