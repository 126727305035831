import { ITestQuestion } from '..';

const lineOfUnderlines = (
  <span style={{ textDecoration: 'underline' }}>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span>
);

export const PORTUGUESE_QUESTIONS: ITestQuestion<undefined>[] = [
  {
    question: (
      <>
        <p>
          Assinale a alternativa em que os verbos entre parênteses completam o
          texto abaixo:
        </p>
        <br />
        <ol style={{ listStyle: 'inside', listStyleType: 'upper-roman' }}>
          <li>
            Tanto a Lua como Vênus {lineOfUnderlines} a semana mais propícia a
            negociações. (deixar)
          </li>
          <li>
            Calma e tranquilidade {lineOfUnderlines} em seus relacionamentos.
            (ajudar)
          </li>
          <li>
            Discussões, contratempos financeiros, problemas sentimentais, nada o{' '}
            {lineOfUnderlines} nesta semana. (atrapalhar)
          </li>
        </ol>
      </>
    ),
    answers: [
      {
        text: 'Deixarão, ajudarão, atrapalharão',
      },
      {
        text: 'Deixará, ajudarão, atrapalharão',
      },
      {
        text: 'Deixarão, ajudará, atrapalhará',
      },
      {
        text: 'Deixarão, ajudarão, atrapalhará',
      },
    ],
    correctAnswerIndex: 3,
  },
  {
    question: (
      <>
        <section>
          “Um grupo de cinco pessoas armadas assaltou na noite desta
          quarta-feira (21) um posto de distribuição em Santo André (Grande São
          Paulo) e levou 135 mil figurinhas do álbum da Copa do Mundo. De acordo
          com a empresa Treelog, a ação começou por volta das 23h30 e durou
          cerca de 30 minutos. Ao todo, 30 funcionários foram rendidos no posto
          da empresa no ABC, na avenida Artur de Queirós, região da Casa Branca.
          Ninguém ficou ferido. Os assaltantes levaram 135 caixas contendo 1.000
          figurinhas cada, divididas em 200 envelopes. Cada envelope com cinco
          figurinhas é vendido nas bancas por R$ 0,75, portanto o prejuízo pode
          ficar em R$ 20.250. Segundo o Sindicato de Bancas de Revistas e
          Jornais de São Paulo, o volume seria suficiente para abastecer 50
          pontos de venda. (...)”
        </section>
        <br />
        <p>De acordo com texto é correto afirma que</p>
      </>
    ),
    answers: [
      {
        text: 'Os assaltantes ficaram feridos',
      },
      {
        text: 'Ao todo 30 funcionários foram rendidos',
      },
      {
        text: 'O posto da empresa fica em São Bernardo do Campo',
      },
      {
        text: 'A ação começou na guarita do guarda da empresa',
      },
    ],
    correctAnswerIndex: 1,
  },
  {
    question: (
      <>
        <section>
          “Teerã - Irã e o Organismo Internacional para a Energia Atômica (AIEA
          ) puseram fim neste domingo às negociações nucleares com um novo
          acordo de cooperação de sete pontos. Após um dia e meio de conversas,
          a AIEA e a Organização de Energia Atômica do Irã (OEAI) emitiram neste
          domingo um comunicado no qual afirmam que Teerã cumpriu os seis
          compromissos adquiridos em novembro e concordam em cooperar em outros
          sete aspectos, informou a agência de notícias estatal iraniana ‘Irna’,
          que não detalha o conteúdo do acordo.
          <br />
          <br />
          “Foram realizados nos dois últimos dias sessões técnicas relacionadas
          à cooperação estipulada por ambas as partes”, disse o embaixador
          permanente do Irã perante a AIEA, Reza Najafi, que liderava a equipe
          de negociação do país (EXAME, 2014).”
        </section>
        <br />
        <p>
          Um dos objetivos do Irã com a mudança de postura sobre seu programa
          nuclear e a forma de tratamento privilegiada nas negociações entre o
          Irã e o Ocidente são:
        </p>
      </>
    ),
    answers: [
      {
        text: 'Atender o desejo da população descontente – ênfase no embate religioso',
      },
      {
        text: 'Provar que o programa não tem finalidade bélica - ênfase na dimensão histórica',
      },
      {
        text: 'Provocar a perda de prestígio dos aiatolás – ênfase no confronto militar',
      },
      {
        text: 'Afrouxar as sanções que asfixiam a economia iraniana – ênfase na perspectiva diplomática',
      },
    ],
    correctAnswerIndex: 3,
  },
  {
    question: (
      <p>
        O geógrafo João Rua, analisando o processo de expansão do sistema
        capitalista no mundo, afirma que{' '}
        <i>
          “o homem tem sido visto como produtor, criador, transformador; a
          natureza, como domínio a ser conquistado, explorado, submetido ao
          ritmo da produção econômica, cada vez mais, em escala industrial (RUA,
          J., 2007)”
        </i>
        .
        <br />
        <br />
        Dentro da lógica capitalista de produção, segundo o autor, a natureza
        passa a ser percebida como
      </p>
    ),
    answers: [
      {
        text: 'Mero recurso a ser transformado em riqueza.',
      },
      {
        text: 'Parte de uma realidade que, apesar dos avanços técnicos e científicos, integra homem e natureza de forma harmônica.',
      },
      {
        text: 'Fonte de recursos que liberta o homem em relação ao meio, reduzindo a crise ambiental que ameaça o planeta.',
      },
      {
        text: 'Um espaço submetido à dinâmica natural, autônomo em relação à dinâmica das sociedades.',
      },
    ],
    correctAnswerIndex: 0,
  },
  {
    question: (
      <p>
        O ano de 2013 ficou marcado pela onda de manifestações, revelando não
        somente a insatisfação com o aumento do preço de passagens do transporte
        público e a precariedade dos serviços oferecidos, mas a problemática da
        mobilidade urbana em todo o país.
        <br />
        <br />
        Uma causa direta das situações enfrentadas pelo usuário, como
        congestionamentos, poluição, acidentes e ônibus lotados, que afetam a
        qualidade de vida da população urbana, é a
      </p>
    ),
    answers: [
      {
        text: 'Expansão do transporte ferroviário de passageiros em todo o país.',
      },
      {
        text: 'Expansão significativa da intermodalidade em todo o país.',
      },
      {
        text: 'Ênfase dada, pelas políticas públicas municipais, ao transporte rodoviário, em detrimento de outros modais.',
      },
      {
        text: 'Incapacidade gestora do poder público estadual, que tem a responsabilidade em administrar os transportes públicos.',
      },
    ],
    correctAnswerIndex: 2,
  },
  {
    question: (
      <>
        <section>
          “O Brasil é um dos poucos países no mundo com a possibilidade de
          ampliar áreas com a agropecuária. De fato, um estudo da ONU mostra que
          o país será o grande responsável por produzir os alimentos necessários
          para atender os mais de 9 bilhões de pessoas que habitarão o planeta
          em 2050. De acordo com pesquisadores da Embrapa, a região possui
          potencial e áreas para ampliação sustentável da agricultura. Portanto,
          a responsabilidade do agricultor brasileiro é muito grande.
          <br />
          <br />
          A região amazônica se mostra promissora para a agricultura, pois ela é
          rica em um insumo fundamental, a água. Estados como Rondônia e Acre
          têm municípios que recebem até 2.800 milímetros de chuvas por ano. E
          isso proporciona a qualidade e a possibilidade de semear mais de uma
          cultura por ano. Entretanto, as críticas internacionais, quanto ao uso
          e à ampliação da agricultura na região amazônica, são um limitante
          para a exploração dessas áreas.
          <br />
          <br />
          Para cada nova área aberta para a agricultura, parte deveria ser
          obrigatoriamente destinada à preservação ambiental, segundo as
          exigências dos países que compram nossos produtos agrícolas.”
        </section>
        <br />
        <p>
          De acordo com o texto, para atender às exigências internacionais, o
          país deve
        </p>
      </>
    ),
    answers: [
      {
        text: 'Conscientizar os agricultores da necessidade de ampliar seus negócios.',
      },
      {
        text: 'Diversificar os tipos de culturas que exigem a utilização de muita água.',
      },
      {
        text: 'Garantir a destinação de terras a atividades de preservação ambiental.',
      },
      {
        text: 'Liberar as áreas de cultivo de produtos agrícolas na região amazônica.',
      },
    ],
    correctAnswerIndex: 2,
  },
  {
    question: (
      <>
        <section>
          “Aipim, castelinha, macaxeira, maniva, maniveira. As designações da
          Manihot utilissima podem variar de região, no Brasil, mas uma delas
          deve ser levada em conta em todo o território nacional: pão-de-pobre -
          e por motivos óbvios.
          <br />
          <br />
          Rica em fécula, a mandioca — uma planta rústica e nativa da Amazônia
          disseminada no mundo inteiro, especialmente pelos colonizadores
          portugueses — é a base de sustento de muitos brasileiros e o único
          alimento disponível para mais de 600 milhões de pessoas em vários
          pontos do planeta, e em particular em algumas regiões da África.”
        </section>
        <br />
        <p>
          De acordo com o texto, há no Brasil uma variedade de nomes para a
          Manihot utilissima, nome científico da mandioca. Esse fenômeno revela
          que
        </p>
      </>
    ),
    answers: [
      {
        text: 'Existem variedades regionais para nomear uma mesma espécie de planta.',
      },
      {
        text: 'Mandioca é nome específico para a espécie existente na região amazônica.',
      },
      {
        text: '"Pão-de-pobre" é designação específica para a planta da região amazônica.',
      },
      {
        text: 'Os nomes designam espécies diferentes da planta, conforme a região.',
      },
    ],
    correctAnswerIndex: 0,
  },
  {
    question: (
      <>
        <section>
          “A definição de queimadura é bem ampla, porém, basicamente, é a lesão
          causada pela ação direta ou indireta produzida pela transferência de
          calor para o corpo. A sua manifestação varia desde bolhas (flictenas)
          até formas mais graves, capazes de desencadear respostas sistêmicas
          proporcionais à gravidade da lesão e sua respectiva extensão. Muitas
          vezes, os primeiros socorros prestados à vítima, ao invés de ajudar,
          acabam agravando ainda mais a situação do paciente.”
        </section>
        <br />
        <p>
          Ao se deparar com um indivíduo que sofreu queimadura com formação de
          flictena, o procedimento de primeiros socorros que deve ser realizado
          antes de encaminhar o paciente ao hospital é
        </p>
      </>
    ),
    answers: [
      {
        text: 'Colocar gelo sobre a flictena para amenizar o ardor.',
      },
      {
        text: 'Utilizar manteiga para evitar o rompimento da flictena.',
      },
      {
        text: 'Perfurar a flictena para que a água acumulada seja liberada.',
      },
      {
        text: 'Cobrir a flictena com gazes molhadas para evitar a desidratação.',
      },
    ],
    correctAnswerIndex: 3,
  },
  {
    question: (
      <p>
        Na frase{' '}
        <i>
          “Não há mais nada que possamos fazer em relação ao tratamento de
          Maria...”
        </i>
        , o emprego das reticências é explicado por:
      </p>
    ),
    answers: [
      {
        text: 'Introduzir itens de uma série.',
      },
      {
        text: 'Indicar a suspensão de um pensamento.',
      },
      {
        text: 'Mostrar o caráter pessimista da Língua Portuguesa.',
      },
      {
        text: 'Suceder dois elementos que indicam negação.',
      },
    ],
    correctAnswerIndex: 1,
  },
  {
    question: (
      <p>
        Analise esta sentença: <i>“Eu não acredito! Você veio...”</i>{' '}
        Respectivamente, quais são os efeitos causados pelo emprego do ponto de
        exclamação e das reticências?
      </p>
    ),
    answers: [
      {
        text: 'Questionamento – dúvida.',
      },
      {
        text: 'Suspensão – medo.',
      },
      {
        text: 'Espanto – suspensão.',
      },
      {
        text: 'Dúvida – espanto.',
      },
    ],
    correctAnswerIndex: 2,
  },
  {
    question: (
      <>
        <section>
          “Uma operação do Ibama para combater a pesca ilegal na divisa entre os
          Estados do Pará, Maranhão e Tocantins incinerou 110 quilômetros de
          redes usadas por pescadores durante o período em que os peixes se
          reproduzem. Embora tenha um impacto temporário na atividade econômica
          da região. a medida visa preservá-la ao longo prazo, evitando o risco
          de extinção dos animais. Cerca de 15 toneladas de peixes foram
          apreendidas e doadas para instituições de caridade.”
        </section>
        <br />
        <p>A notícia, do ponto de vista de seus elementos constitutivos,</p>
      </>
    ),
    answers: [
      {
        text: 'Apresenta argumentos contrários à pesca ilegal.',
      },
      {
        text: 'Tem um título que resume o conteúdo do texto.',
      },
      {
        text: 'Informa sobre uma ação, a finalidade que a motivou e o resultado dessa ação.',
      },
      {
        text: 'Dirige-se aos órgãos governamentais dos estados envolvidos na referida operação do Ibama.',
      },
    ],
    correctAnswerIndex: 2,
  },
  {
    question: (
      <>
        <section>
          “Na sociologia e na literatura, o brasileiro foi por vezes tratado
          como cordial e hospitaleiro, mas não é isso o que acontece nas redes
          sociais: a democracia racial apregoada por Gilberto Freyre passa ao
          largo do que acontece diariamente nas comunidades virtuais do país.
          Levantamento inédito realizado pelo projeto Comunica que Muda […]
          mostra em números a intolerância do internauta tupiniquim. Entre abril
          e junho, um algoritmo vasculhou plataformas […] atrás de mensagens e
          textos sobre temas sensíveis, como racismo, posicionamento político e
          homofobia. Foram identificadas 393 284 menções, sendo 84% delas com
          abordagem negativa, de exposição do preconceito e da discriminação.”
        </section>
        <br />
        <p>
          Ao abordar a postura do internauta brasileiro mapeada por meio de uma
          pesquisa em plataformas virtuais, o texto
        </p>
      </>
    ),
    answers: [
      {
        text: 'Minimiza o alcance da comunicação digital.',
      },
      {
        text: 'Refuta ideias preconcebidas sobre o brasileiro.',
      },
      {
        text: 'Relativiza responsabilidades sobre a noção de respeito.',
      },
      {
        text: 'Exemplifica conceitos contidos na literatura e na sociologia.',
      },
    ],
    correctAnswerIndex: 1,
  },
];
