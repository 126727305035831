/* eslint-disable react-hooks/rules-of-hooks */
import { Loader } from 'components/Loader';
import { Modal } from 'components/Modal';
import { ERROR_MODAL_CONFIGURATION, useAlertModal } from 'hooks/alertModal';
import { useCandidate } from 'hooks/candidate';
import { useEffect, useMemo, useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import {
  routeCandidateTests,
  routePerformTest,
} from 'routes/candidatesRoutes/candidatesRoutesAddresses';
import { routeDashboard } from 'routes/routesAddresses';
import { createCandidateTestEvaluation } from 'services/entitiesServices/candidateTestEvaluationServices';
import { CandidateTest } from 'types/candidate';
import { translateCandidateTest } from 'utils/conversion/translateCandidateTest';
import { TESTS_PROPERTIES } from '../TestsProperties';
import { InconclusiveResultsModal } from './InconclusiveResultsModal';
import { Container, Footer, Title } from './styles';

interface LocationType {
  state:
    | { testType: CandidateTest; testResult: string | undefined }
    | undefined;
}

export const TestResultsPage: React.FC = () => {
  const { state } = useLocation() as LocationType;
  if (!state) return <Redirect to={routeDashboard} />;

  const history = useHistory();
  const { candidate, updateCandidateState } = useCandidate();
  const { showModal } = useAlertModal();

  const [isSendingResults, setIsSendingResults] = useState(true);
  const { testType, testResult } = state;
  const ResultsComponent = TESTS_PROPERTIES[testType].ResultScreen;

  const candidatePreviousResult = useMemo(() => {
    const { testEvaluations } = candidate!;
    const findedTest = testEvaluations.find(
      ({ candidateTest }) => candidateTest === testType,
    );

    return findedTest?.evaluation;
  }, [candidate, testType]);

  useEffect(() => {
    if (testResult === undefined || candidatePreviousResult !== undefined) {
      setIsSendingResults(false);
      return;
    }

    createCandidateTestEvaluation({
      candidateTest: testType,
      evaluation: testResult,
    })
      .then(createdEvaluation => {
        const { testEvaluations } = candidate!;
        updateCandidateState({
          ...candidate!,
          testEvaluations: [...testEvaluations, createdEvaluation],
        });

        setIsSendingResults(false);
      })
      .catch(() => {
        history.push(routeCandidateTests);
        showModal({
          ...ERROR_MODAL_CONFIGURATION,
          message:
            'Erro ao registrar resultados do teste, por favor, tente refazê-lo',
        });
      });
  }, [
    candidate,
    candidatePreviousResult,
    history,
    showModal,
    testResult,
    testType,
    updateCandidateState,
  ]);

  if (testResult === undefined && candidatePreviousResult === undefined) {
    return (
      <Modal isVisible>
        <InconclusiveResultsModal
          handleRedoTest={() => history.push(routePerformTest, { testType })}
          handleDoTestLater={() => history.push(routeCandidateTests)}
        />
      </Modal>
    );
  }

  if (isSendingResults) return <Loader />;
  return (
    <Container>
      <Title>
        <p>Conheça os resultados do seu</p>
        <h2>Teste de {translateCandidateTest(testType)}</h2>
      </Title>

      <ResultsComponent testResult={(testResult || candidatePreviousResult)!} />

      <Footer>
        <span>Sobre as avaliações desenvolvidas pela IN6</span>
        <p>
          O resultado desta avaliação é disponibilizado automaticamente para as
          empresas quando você se candidata para uma vaga. Esse resultado
          isolado não significa e nem garante sua aprovação ou reprovação nos
          processos seletivos. Tal decisão está atrelada a uma análise combinada
          com outras variáveis definidas pelas empresas. Nosso propósito aqui é
          contribuir com seu autoconhecimento e desenvolvimento profissional.
        </p>
      </Footer>
    </Container>
  );
};
