import { Depth16 } from 'assets/colors/boxShadows';
import {
  BackgroundColor,
  HighEmphasis,
  OutlineColor,
} from 'assets/colors/palette';
import { Regular14Styles } from 'assets/fonts/fontStyles';
import styled from 'styled-components';

export const Container = styled.div`
  width: 296px;
  height: 229px;
  box-shadow: ${Depth16};

  background-color: ${BackgroundColor};
  border: 1px solid ${OutlineColor};
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  p {
    ${Regular14Styles}
    color: ${HighEmphasis};
    line-height: 24px;

    width: 227px;
    margin-bottom: 8px;
    text-align: center;
  }
`;
