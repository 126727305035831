import deleteIcon from 'assets/img/delete.svg';

import { FormWithStepsContainer } from 'assets/styles/formWithStepsStyles/formWithStepsContainerStyles';
import {
  StepSubTitle,
  StepTitle,
  TitleContainer,
} from 'assets/styles/formWithStepsStyles/formWithStepsTitlesStyles';
import { ImageInput } from 'components/Input/ImageInput';
import { useCallback, useEffect, useState } from 'react';
import { IFormStepProps, OmitId } from 'types';
import { IStartupImage } from 'types/startup';
import { useInputStates } from 'utils/customHooks';

import {
  StepDataInformation,
  Image,
  GalleryContainer,
  ImageContainer,
} from './styles';

export { validateStartupImagesData } from './validateStartupImagesData';

export interface StartupImagesStepData {
  images?: OmitId<IStartupImage>[];
}

export const StartupImagesStep: React.FC<
  IFormStepProps<StartupImagesStepData>
> = ({ initialData = {} as StartupImagesStepData, setStepDataFunction }) => {
  const { images: initialImages } = initialData;

  const newImageStates = useInputStates('newImage');
  const [images, setImages] = useState<OmitId<IStartupImage>[]>(
    initialImages ?? [],
  );

  const handleAddImage = useCallback((image: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(image);

    reader.onload = () => {
      const parsedImage = reader.result as string;
      setImages(previousImages => [...previousImages, { image: parsedImage }]);
    };
  }, []);

  const handleExcludeImage = useCallback((image, imageIndex) => {
    URL.revokeObjectURL(image);

    setImages(previousImages =>
      previousImages.filter((_, index) => index !== imageIndex),
    );
  }, []);

  useEffect(() => {
    if (setStepDataFunction) {
      setStepDataFunction({
        images,
      });
    }
  }, [images, setStepDataFunction]);

  return (
    <FormWithStepsContainer>
      <TitleContainer>
        <StepTitle>Fotos da galeria</StepTitle>
        <StepSubTitle>
          Passe uma ótima primeira impressão da sua empresa com fotos de alta
          qualidade da equipe, cultura e espaços de escritório.
        </StepSubTitle>
      </TitleContainer>

      <StepDataInformation>
        <p id="images-counter">{images?.length ?? 0}/4 fotos adicionadas</p>
        <p id="description">
          Destaque-se com imagens de qualidade de pelo menos 700x700px
        </p>
      </StepDataInformation>

      <GalleryContainer>
        {images.map(({ image }, index) => (
          <ImageContainer key={image}>
            <button
              type="button"
              onClick={() => handleExcludeImage(image, index)}
            >
              <img src={deleteIcon} alt="Deletar" />
            </button>
            <Image src={image} alt="Foto da galeria" />
          </ImageContainer>
        ))}

        {images.length < 4 && (
          <ImageInput
            style={{ width: 238, height: 238 }}
            states={newImageStates}
            minimumImageDimensions={{ width: 700, height: 700 }}
            onChange={({ target: { files } }) =>
              files !== null && handleAddImage(files[0])
            }
          />
        )}
      </GalleryContainer>
    </FormWithStepsContainer>
  );
};
