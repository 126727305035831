import {
  HighBlue,
  HighGreen,
  HighRed,
  LowBlue,
  LowGreen,
  LowRed,
} from 'assets/colors/palette';
import { VacancyStatus } from 'types/vacancy';

export const VACANCY_STATUS_COLORS = {
  [VacancyStatus.ACTIVE]: {
    low: LowGreen,
    high: HighGreen,
    filter:
      'invert(38%) sepia(48%) saturate(602%) hue-rotate(95deg) brightness(91%) contrast(89%)',
  },
  [VacancyStatus.SUSPENDED]: {
    low: LowRed,
    high: HighRed,
    filter:
      'invert(20%) sepia(100%) saturate(6954%) hue-rotate(345deg) brightness(85%) contrast(109%)',
  },
  [VacancyStatus.CLOSED]: {
    low: LowBlue,
    high: HighBlue,
    filter:
      'invert(18%) sepia(42%) saturate(2965%) hue-rotate(209deg) brightness(86%) contrast(88%)',
  },
};
