import { ICandidateResponseForFindAll } from 'types/candidate';
import { IAdminOperationDataDTO } from 'types/IAdminOperationDataDTO';
import { IStartupResponseForFindAll } from 'types/startup';
import { api } from '../api';

const servicesPrefix = 'admin';

export const adminFindAllStartups = async (): Promise<
  IStartupResponseForFindAll[]
> => {
  const { data } = await api.get<IStartupResponseForFindAll[]>(
    `${servicesPrefix}/findAllStartups`,
  );

  return data;
};

export const adminFindAllCandidates = async (): Promise<
  ICandidateResponseForFindAll[]
> => {
  const { data } = await api.get<ICandidateResponseForFindAll[]>(
    `${servicesPrefix}/findAllCandidates`,
  );

  return data;
};

export const toggleIsSubscriberFromUser = async (
  userToChangeId: number,
  updatedIsSubscriber: boolean,
): Promise<void> => {
  await api.patch(
    `${servicesPrefix}/toggleIsSubscriberFromUser/${userToChangeId}/${updatedIsSubscriber}`,
  );
};

export const validateAdmin = async (): Promise<void> => {
  await api.patch(`${servicesPrefix}/validateAdmin`);
};

export const adminDeleteUser = async (
  adminOperationData: IAdminOperationDataDTO,
): Promise<void> => {
  await api.delete(`${servicesPrefix}/deleteUser`, {
    data: adminOperationData,
  });
};
