interface ResultType {
  description: JSX.Element;
}

const POSSIBLE_RESULTS = {
  lessThan40: {
    description: (
      <p>
        Você é capaz de resolver apenas problemas simples de baixa complexidade
        algébrica-numérica ou de tratamentos de informações, cuja solução
        dependa de associações simples de variáveis, quantidades ou objetos.
        Apresenta dificuldade em realizar operações aritméticas, cálculo de
        proporções ou porcentagens, na leitura de dados expressos em gráficos ou
        tabelas.
        <br />
        <br />
        Consegue resolver apenas problemas mais simples envolvendo análise,
        síntese ou inferência. Possui dificuldades nas situações que envolvam
        representar o problema por meio de esquemas, diagramas ou variáveis, o
        que torna as respectivas resoluções, ainda que corretas em alguns casos,
        bastante demoradas.
      </p>
    ),
  },
  lessThan70: {
    description: (
      <p>
        Você consegue aplicar o raciocínio matemático elementar, por meio de
        representações numéricas, geométricas ou gráficas, na resolução de
        problemas cotidianos. Identifica as operações requeridas em problemas
        que envolvam associação entre valores de diferentes variáveis, inclusive
        quando representadas em gráficos e tabelas. Pode ter dificuldade em
        realizar modelagens que envolvam representações algébricas mais
        sofisticadas, projeções ou comparações feitas com base em dados
        expressos em gráficos e tabelas.
        <br />
        <br />É proficiente na resolução de problemas cotidianos envolvendo a
        divisão do problema em partes, associação de informações de naturezas
        diferentes e o reconhecimento de padrões. É capaz de elaborar
        rapidamente representações dos problemas propostos, por meio de
        diferentes tipos de modelagens. Porém, você pode ter dificuldade em
        perceber as aplicações das habilidades de análise, síntese ou inferência
        em situações mais abstratas ou que fogem ao senso comum.
      </p>
    ),
  },
  higherThan70: {
    description: (
      <p>
        Você demonstra habilidades de raciocínio quantitativo avançado, sendo
        ágil na realização de operações numéricas. Abstrai com facilidade
        modelos que representam algebricamente diferentes variáveis. Lê e
        interpreta dados expressos em gráficos e tabelas de maneira intuitiva,
        sendo capaz de identificar padrões e relações entre variáveis, para
        realizar comparações e projeções.
        <br />
        <br />
        Consegue resolver praticamente todos os tipos de problema envolvendo
        raciocínio lógico, realizando rapidamente as representações necessárias,
        percebendo diferentes caminhos para uma solução e identificando
        eventuais inconsistências na resolução que está criando, para
        corrigi-las.
      </p>
    ),
  },
};

export const getResultScreenPropertiesBasedOnTestResult = (
  testResult: string | undefined,
): ResultType => {
  if (Number(testResult) < 40) return POSSIBLE_RESULTS.lessThan40;
  if (Number(testResult) < 70) return POSSIBLE_RESULTS.lessThan70;
  return POSSIBLE_RESULTS.higherThan70;
};
