import { ProfessionalProfileType } from 'types/candidate';

export const convertTestResultToProfessionalProfileType = (
  testResult: string,
): ProfessionalProfileType => {
  switch (testResult) {
    case 'Comunicador':
      return ProfessionalProfileType.COMMUNICATOR;
    case 'Executor':
      return ProfessionalProfileType.EXECUTOR;
    case 'Idealizador':
      return ProfessionalProfileType.IDEALIZER;
    case 'Analista':
      return ProfessionalProfileType.ANALYST;
    default:
      return ProfessionalProfileType.COMMUNICATOR;
  }
};
