import wavingHand from 'assets/img/wavingHand.svg';
import search from 'assets/img/search.svg';
import noVacanciesIconExpanded from 'assets/img/noVacanciesIconExpanded.svg';

import { PlusButton } from 'components/Buttons';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useStartup } from 'hooks/startup';
import { Loader } from 'components/Loader';
import { useHistory } from 'react-router-dom';
import { routeCreateVacancy } from 'routes/startupsRoutes/startupsRoutesAddresses';

import {
  IVacancyDTOForMultipleVacanciesWithoutStartup,
  VacancyStatus,
} from 'types/vacancy';
import {
  deleteVacancy,
  findAllVacanciesByStartupId,
} from 'services/entitiesServices/vacancyServices';
import { Modal } from 'components/Modal';
import { ShareVacancyModal } from 'pages/Dashboards/StartupsDashboard/ShareVacancyModal';
import { useAuth } from 'hooks/auth';
import { authenticateUser } from 'services/entitiesServices/userServices';
import {
  Container,
  Header,
  CompanyMessageContainer,
  VacanciesActionsContainer,
  SearchVacanciesInput,
  Main,
  NoVacanciesContainer,
  VacancyStatusSelection,
  StatusOption,
  VacanciesContainer,
} from './styles';
import { DeleteVacancyModal } from '../VacancyDetailsTab/DeleteVacancyModal';
import { VacancyContainer } from './VacancyContainer';

interface StatusVacancies {
  [VacancyStatus.ACTIVE]: IVacancyDTOForMultipleVacanciesWithoutStartup[];
  [VacancyStatus.SUSPENDED]: IVacancyDTOForMultipleVacanciesWithoutStartup[];
  [VacancyStatus.CLOSED]: IVacancyDTOForMultipleVacanciesWithoutStartup[];
}

const STATUS_OPTIONS = {
  [VacancyStatus.ACTIVE]: 'ativas',
  [VacancyStatus.SUSPENDED]: 'suspensas',
  [VacancyStatus.CLOSED]: 'encerradas',
};

export const StartupVacanciesTab: React.FC = () => {
  const history = useHistory();
  const { startup, updateIsShowingSubscribeModal } = useStartup();
  const { id: startupId, name: startupName } = startup!;

  const { user } = useAuth();
  const searchVacanciesInputRef = useRef<HTMLInputElement>(null);

  const [selectedVacancyStatus, setSelectedVacancyStatus] =
    useState<VacancyStatus>(VacancyStatus.ACTIVE);
  const [isLoadingVacancies, setIsLoadingVacancies] = useState(true);
  const [searchedVacancyText, setSearchedVacancyText] = useState('');

  const [startupVacancies, setStartupVacancies] =
    useState<StatusVacancies | null>(null);
  const [searchedVacanciesIndexes, setSearchedVacanciesIndexes] = useState<
    number[]
  >([]);

  const [idOfVacancyVisibleOnShareModal, setIdOfVacancyVisibleOnShareModal] =
    useState<number | null>(null);
  const [idOfVacancyVisibleOnDeleteModal, setIdOfVacancyVisibleOnDeleteModal] =
    useState<number | null>(null);

  useEffect(() => {
    findAllVacanciesByStartupId(startupId).then(findedVacancies => {
      const vacanciesByStatus: StatusVacancies = {
        [VacancyStatus.ACTIVE]: [],
        [VacancyStatus.SUSPENDED]: [],
        [VacancyStatus.CLOSED]: [],
      };
      findedVacancies.forEach(vacancy =>
        vacanciesByStatus[vacancy.vacancyStatus].push(vacancy),
      );

      setStartupVacancies(vacanciesByStatus);
      setIsLoadingVacancies(false);
    });
  }, [startupId]);

  useEffect(() => {
    if (startupVacancies === null) return;

    const searchedIndexes: number[] = [];
    startupVacancies[selectedVacancyStatus].forEach(({ name }, index) => {
      if (!name.toLowerCase().includes(searchedVacancyText)) return;
      searchedIndexes.push(index);
    });

    setSearchedVacanciesIndexes(searchedIndexes);
  }, [searchedVacancyText, selectedVacancyStatus, startupVacancies]);

  const handleDeleteVacancy = useCallback(
    async (password: string) => {
      await authenticateUser({
        email: user?.email ?? '',
        password,
      });
      await deleteVacancy(Number(idOfVacancyVisibleOnDeleteModal));

      setStartupVacancies(previousVacancies => {
        if (previousVacancies === null) return null;

        const parsedVacancies = { ...previousVacancies };
        const previousStatusVacancies =
          previousVacancies[selectedVacancyStatus];

        parsedVacancies[selectedVacancyStatus] = previousStatusVacancies.filter(
          ({ id }) => id !== idOfVacancyVisibleOnDeleteModal,
        );

        return parsedVacancies;
      });
    },
    [user?.email, idOfVacancyVisibleOnDeleteModal, selectedVacancyStatus],
  );

  const handleOnClickCreateVacancy = useCallback(() => {
    if (user?.subscriber || !startup?.hasCreatedFirstVacancy) {
      history.push(routeCreateVacancy);
    } else updateIsShowingSubscribeModal(true);
  }, [
    history,
    startup?.hasCreatedFirstVacancy,
    updateIsShowingSubscribeModal,
    user?.subscriber,
  ]);

  return (
    <Container>
      <Modal isVisible={idOfVacancyVisibleOnDeleteModal !== null}>
        <DeleteVacancyModal
          handleDeleteVacancy={handleDeleteVacancy}
          handleCloseModal={() => setIdOfVacancyVisibleOnDeleteModal(null)}
        />
      </Modal>

      <Modal isVisible={idOfVacancyVisibleOnShareModal !== null}>
        <ShareVacancyModal
          parsedVacancyId={1000 + Number(idOfVacancyVisibleOnShareModal)}
          handleCloseModal={() => setIdOfVacancyVisibleOnShareModal(null)}
        />
      </Modal>

      <Header>
        <CompanyMessageContainer>
          <div className="welcome-message">
            <h3>Olá, {startupName}</h3>
            <img src={wavingHand} alt="Mão acenando" />
          </div>

          <span>Crie e gerencie as vagas da sua empresa</span>
        </CompanyMessageContainer>

        <VacanciesActionsContainer>
          <SearchVacanciesInput
            disabled={startupVacancies === null}
            onClick={() =>
              startupVacancies !== null &&
              searchVacanciesInputRef.current?.focus()
            }
          >
            <input
              ref={searchVacanciesInputRef}
              type="text"
              placeholder="Encontrar vaga"
              disabled={startupVacancies === null}
              onChange={({ target: { value } }) =>
                setSearchedVacancyText(value.toLowerCase())
              }
            />
            <img src={search} alt="Lupa" />
          </SearchVacanciesInput>

          <PlusButton onClick={handleOnClickCreateVacancy} colorStyle="fill">
            Criar vaga
          </PlusButton>
        </VacanciesActionsContainer>
      </Header>

      {isLoadingVacancies ? (
        <Loader />
      ) : (
        <Main>
          {startupVacancies === null ? (
            <NoVacanciesContainer>
              <img src={noVacanciesIconExpanded} alt="Sem vagas" />
              <p>
                Você ainda não criou nenhuma vaga. <br />É hora de testar! Crie
                sua primeira vaga grátis.
              </p>
            </NoVacanciesContainer>
          ) : (
            <>
              <VacancyStatusSelection>
                {Object.entries(STATUS_OPTIONS).map(
                  ([statusValue, statusText]) => (
                    <StatusOption
                      key={statusValue}
                      type="button"
                      isSelected={statusValue === selectedVacancyStatus}
                      onClick={() =>
                        setSelectedVacancyStatus(statusValue as VacancyStatus)
                      }
                    >
                      <span>Vagas {statusText}</span>
                      <div className="vacancies-quantity">
                        {startupVacancies[statusValue as VacancyStatus].length}
                      </div>
                    </StatusOption>
                  ),
                )}
              </VacancyStatusSelection>

              <VacanciesContainer>
                {searchedVacanciesIndexes.map(vacancyIndex => (
                  <VacancyContainer
                    key={vacancyIndex}
                    handleDeleteVacancy={setIdOfVacancyVisibleOnDeleteModal}
                    handleShareVacancy={setIdOfVacancyVisibleOnShareModal}
                    vacancy={
                      startupVacancies[selectedVacancyStatus][vacancyIndex]
                    }
                  />
                ))}
              </VacanciesContainer>
            </>
          )}
        </Main>
      )}
    </Container>
  );
};
