import messageIcon from 'assets/img/message.svg';
import rocketIcon from 'assets/img/rocket.svg';
import brainIcon from 'assets/img/brain.svg';
import chartIcon from 'assets/img/chart.svg';
import polygon from 'assets/img/polygon.svg';

import { GetCriteriaComponentProps } from 'types/criteria';
import { ProfessionalProfileType } from 'types/candidate';
import { translateProfessionalProfileType } from 'utils/conversion';
import { useCallback, useMemo } from 'react';
import {
  Container,
  IconContainer,
  ProfessionalProfile,
  ProfessionalProfilesContainer,
} from './styles';

const PROFILES_ICONS = {
  [ProfessionalProfileType.COMMUNICATOR]: messageIcon,
  [ProfessionalProfileType.IDEALIZER]: brainIcon,
  [ProfessionalProfileType.EXECUTOR]: rocketIcon,
  [ProfessionalProfileType.ANALYST]: chartIcon,
};

export const GetProfessionalProfileCriteria: React.FC<
  GetCriteriaComponentProps
> = ({ previousCriteria, handleUpdateCriteria, children }) => {
  const parsedPreviousCriteria = previousCriteria as ProfessionalProfileType[];

  const profileIsSelectedObject = useMemo(() => {
    const selectedProfiles = {
      [ProfessionalProfileType.COMMUNICATOR]: false,
      [ProfessionalProfileType.IDEALIZER]: false,
      [ProfessionalProfileType.EXECUTOR]: false,
      [ProfessionalProfileType.ANALYST]: false,
    };

    Object.keys(PROFILES_ICONS).forEach(profileType => {
      const profileIsOnPreviousCriteria =
        parsedPreviousCriteria.findIndex(
          criteria => criteria === profileType,
        ) !== -1;

      if (profileIsOnPreviousCriteria) {
        selectedProfiles[profileType as ProfessionalProfileType] = true;
      }
    });

    return selectedProfiles;
  }, [parsedPreviousCriteria]);

  const handleUpdateProfileIsSelected = useCallback(
    (professionalProfile: ProfessionalProfileType) => {
      const profileWasSelected = profileIsSelectedObject[professionalProfile];

      if (!profileWasSelected) {
        handleUpdateCriteria([...parsedPreviousCriteria, professionalProfile]);
        return;
      }

      handleUpdateCriteria(
        parsedPreviousCriteria.filter(
          criteria => criteria !== professionalProfile,
        ),
      );
    },
    [handleUpdateCriteria, parsedPreviousCriteria, profileIsSelectedObject],
  );

  return (
    <Container>
      <p className="placeholder">{children}</p>

      <ProfessionalProfilesContainer>
        {Object.entries(PROFILES_ICONS).map(([professionalProfile, icon]) => (
          <ProfessionalProfile
            key={professionalProfile}
            onClick={() =>
              handleUpdateProfileIsSelected(
                professionalProfile as ProfessionalProfileType,
              )
            }
            isSelected={
              profileIsSelectedObject[
                professionalProfile as ProfessionalProfileType
              ]
            }
          >
            <IconContainer>
              <img className="polygon" src={polygon} alt="Polígono" />
              <img
                className="profile-icon"
                src={icon}
                alt={translateProfessionalProfileType(
                  professionalProfile as ProfessionalProfileType,
                )}
              />
            </IconContainer>

            <span>
              {translateProfessionalProfileType(
                professionalProfile as ProfessionalProfileType,
              )}
            </span>
          </ProfessionalProfile>
        ))}
      </ProfessionalProfilesContainer>
    </Container>
  );
};
