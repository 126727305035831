/* eslint-disable react-hooks/rules-of-hooks */
import editIcon from 'assets/img/editIcon.svg';
import redirectIcon from 'assets/img/redirectIcon.svg';
import shareIcon from 'assets/img/shareIcon.svg';
import deleteIcon from 'assets/img/delete.svg';

import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import { IVacancyResponse, VacancyStatus } from 'types/vacancy';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Depth4 } from 'assets/colors/boxShadows';
import { Modal } from 'components/Modal';
import {
  deleteVacancy,
  findOneVacancyById,
  updateVacancy,
} from 'services/entitiesServices/vacancyServices';
import { routeEditVacancy } from 'routes/startupsRoutes/startupsRoutesAddresses';
import { authenticateUser } from 'services/entitiesServices/userServices';
import { useAuth } from 'hooks/auth';
import { candidatesUrlFrontend } from 'services/baseUrl';
import { routeDashboard } from 'routes/routesAddresses';
import { Loader } from 'components/Loader';
import {
  Header,
  AccessContainer,
  AccessButton,
  VacancyName,
  VacancyActionsContainer,
  SelectedSubtabContainer,
} from './styles';
import { VacancyInformation } from './VacancyInformation';
import { VacancyCandidatesManagement } from './VacancyCandidatesManagement';
import { DeleteVacancyModal } from './DeleteVacancyModal';
import { ShareVacancyModal } from '../../ShareVacancyModal';

type VisibleModal = null | 'share' | 'delete';

const VACANCY_DETAILS_SUBTABS = {
  vacancyInformation: {
    name: 'Resumo da vaga',
    Component: VacancyInformation,
  },
  vacancyCandidatesManagement: {
    name: 'Gerenciamento dos candidatos',
    Component: VacancyCandidatesManagement,
  },
};

type VacancyDetailsSubtabsType = keyof typeof VACANCY_DETAILS_SUBTABS;

export const VacancyDetailsTab: React.FC = () => {
  const { state } = useLocation() as {
    state: { vacancyId: number } | undefined;
  };
  if (!state) return <Redirect to={routeDashboard} />;

  const history = useHistory();
  const { user } = useAuth();
  const headerRef = useRef<HTMLHeadElement>(null);

  const [vacancy, setVacancy] = useState<IVacancyResponse>(
    {} as IVacancyResponse,
  );
  const [visibleModal, setVisibleModal] = useState<VisibleModal>(null);
  const [selectedSubtab, setSelectedSubtab] =
    useState<VacancyDetailsSubtabsType>('vacancyInformation');

  useEffect(() => {
    findOneVacancyById(state.vacancyId)
      .then(findedVacancy => {
        if (!findedVacancy) history.push(routeDashboard);
        else setVacancy(findedVacancy);
      })
      .catch(() => history.push(routeDashboard));
  }, [history, state.vacancyId]);

  useLayoutEffect(() => {
    const toggleHeaderPositionAndShadow = () => {
      const { current } = headerRef;
      if (!current) return;

      const boxShadow = current.style.getPropertyValue('box-shadow');
      const offsetTop = window.scrollY;

      if (!boxShadow && offsetTop > 64) {
        current.style.setProperty('box-shadow', Depth4);
        current.style.setProperty('position', 'sticky');
      } else if (boxShadow && offsetTop <= 64) {
        current.style.removeProperty('box-shadow');
        current.style.setProperty('position', 'static');
      }
    };

    window.addEventListener('scroll', toggleHeaderPositionAndShadow);
    return () =>
      window.removeEventListener('scroll', toggleHeaderPositionAndShadow);
  }, []);

  const handleUpdateVacancyStatus = useCallback(
    async (vacancyStatus: VacancyStatus, closingReason?: string) => {
      await updateVacancy(vacancy.id, {
        vacancyStatus,
        closingReason,
      });
      setVacancy(previousValue => ({
        ...previousValue,
        vacancyStatus,
        closingReason,
      }));
    },
    [vacancy],
  );

  const handleDeleteVacancy = useCallback(
    async (password: string) => {
      await authenticateUser({
        email: user?.email ?? '',
        password,
      });

      await deleteVacancy(vacancy.id);
    },
    [user?.email, vacancy.id],
  );

  if (!vacancy.id) return <Loader />;
  return (
    <>
      <Modal isVisible={!!visibleModal}>
        {visibleModal === 'delete' && (
          <DeleteVacancyModal
            handleDeleteVacancy={handleDeleteVacancy}
            handleCloseModal={() => setVisibleModal(null)}
          />
        )}

        {visibleModal === 'share' && (
          <ShareVacancyModal
            parsedVacancyId={1000 + vacancy.id}
            handleCloseModal={() => setVisibleModal(null)}
          />
        )}
      </Modal>

      <Header ref={headerRef}>
        <div>
          <AccessContainer>
            <VacancyName>{vacancy.name}</VacancyName>

            {Object.entries(VACANCY_DETAILS_SUBTABS).map(([key, { name }]) => (
              <AccessButton
                key={key}
                isOfSelectedTab={key === selectedSubtab}
                type="button"
                onClick={() =>
                  setSelectedSubtab(key as VacancyDetailsSubtabsType)
                }
              >
                {name}
              </AccessButton>
            ))}
          </AccessContainer>

          <VacancyActionsContainer>
            <Link
              to={{ pathname: routeEditVacancy, state: { vacancy } }}
              className="action-button"
            >
              <img src={editIcon} alt="Editar" />
            </Link>

            <a
              href={`${candidatesUrlFrontend}/${1000 + vacancy.id}`}
              target="_blank"
              rel="noreferrer"
              className="action-button"
            >
              <img src={redirectIcon} alt="Redirecionar" />
            </a>

            <button
              type="button"
              onClick={() => setVisibleModal('share')}
              className="action-button"
            >
              <img src={shareIcon} alt="Compartilhar" />
            </button>

            <button
              type="button"
              onClick={() => setVisibleModal('delete')}
              className="action-button"
            >
              <img src={deleteIcon} alt="Deletar" />
            </button>
          </VacancyActionsContainer>
        </div>
      </Header>

      <SelectedSubtabContainer>
        {selectedSubtab === 'vacancyInformation' ? (
          <VacancyInformation
            vacancy={vacancy}
            handleUpdateVacancyStatus={handleUpdateVacancyStatus}
          />
        ) : (
          <VacancyCandidatesManagement vacancy={vacancy} />
        )}
      </SelectedSubtabContainer>
    </>
  );
};
