import { Loader } from 'components/Loader';
import { useAuth } from 'hooks/auth';
import React from 'react';
import {
  Route as ReactRoute,
  RouteProps as ReactRouteProps,
  Redirect,
} from 'react-router-dom';
import { UserAccountType } from 'types/user';
import { routeDashboard } from './routesAddresses';

interface RouteProps extends ReactRouteProps {
  isPrivate?: boolean;
  isForAuthentication?: boolean;
  component: React.ComponentType;
}

export const Route: React.FC<RouteProps> = ({
  component: Component,
  isPrivate = false,
  isForAuthentication = false,
  ...props
}) => {
  const { user, isLoadingUser } = useAuth();

  const userIsValid = !!user;
  const isFreeRoute = !isPrivate && !isForAuthentication;

  if (isLoadingUser) return <Loader />;
  return (
    <ReactRoute
      {...props}
      render={({ location }) => {
        const state = (location.state as { from: Location }) || undefined;
        const userIsAdmin = user?.accountType === UserAccountType.ADMIN;

        if (
          userIsAdmin ||
          userIsValid === isPrivate ||
          (isFreeRoute && !state?.from)
        ) {
          return <Component />;
        }

        return (
          <Redirect
            to={{
              pathname: isPrivate ? '/' : routeDashboard,
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};
