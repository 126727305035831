import {
  IAuthenticateUserRequest,
  IAuthenticateUserResponse,
  IUpdateUserRequest,
  IUserRequest,
  IUserResponse,
} from 'types/user';
import { api } from '../api';

const servicesPrefix = 'user';

export const createUser = async (
  createUserData: IUserRequest,
): Promise<IUserResponse> => {
  const { data } = await api.post<IUserResponse>(
    `${servicesPrefix}`,
    createUserData,
  );

  return data;
};

export const authenticateUser = async (
  authenticateUserData: IAuthenticateUserRequest,
): Promise<IAuthenticateUserResponse> => {
  const { data } = await api.post(
    `${servicesPrefix}/authenticate`,
    authenticateUserData,
  );

  return data;
};

export const sendRecoverPasswordEmail = async (
  userEmail: string,
): Promise<void> => {
  await api.post(`${servicesPrefix}/sendRecoverPasswordEmail/${userEmail}`);
};

export const recoverPassword = async (recoverPasswordData: {
  token: string;
  newPassword: string;
}): Promise<void> => {
  await api.post(`${servicesPrefix}/recoverPassword`, recoverPasswordData);
};

export const renewToken = async (): Promise<{ token: string }> => {
  const { data } = await api.patch(`${servicesPrefix}/renewToken`);
  return data;
};

export const updateWantsEmailNotifications = async (
  wantsEmailNotifications: boolean,
): Promise<void> => {
  await api.patch(
    `${servicesPrefix}/updateWantsEmailNotifications/${wantsEmailNotifications}`,
  );
};

export const disableWantsEmailNotificationsViaUserToken = async (
  userToken: string,
): Promise<void> => {
  await api.patch(
    `${servicesPrefix}/disableWantsEmailNotificationsViaUserToken/${userToken}`,
  );
};

export const findOneUserById = async (
  userId: number,
): Promise<IUserResponse | undefined> => {
  const { data } = await api.get<IUserResponse | undefined>(
    `${servicesPrefix}/findOneById/${userId}`,
  );

  return data;
};

export const updateUser = async (
  updatedUser: IUpdateUserRequest,
): Promise<IUserResponse> => {
  const { data } = await api.put<IUserResponse>(
    `${servicesPrefix}`,
    updatedUser,
  );

  return data;
};

export const findUpdatedUserById = async (
  userId: number,
  clientUpdatedAt: Date,
): Promise<IUserResponse | undefined> => {
  const { data, status } = await api.get<IUserResponse>(
    `${servicesPrefix}/findUpdatedUserById/${userId}/${clientUpdatedAt}`,
  );

  return status === 204 ? undefined : data;
};

export const deleteUser = async (): Promise<void> => {
  await api.delete(`${servicesPrefix}`);
};
