import { ExpirationPage } from 'pages/ExpirationPage';
import { ForgotPassword } from 'pages/ForgotPassword';
import { ActivationPage } from 'pages/ActivationPage';
import { FeedVacancies } from 'pages/FeedVacancies';
import { HomePage } from 'pages/HomePage';
import { VacancyApply } from 'pages/VacancyApply';
import { ScrollToTop } from 'components/ScrollToTop';

import { Switch } from 'react-router-dom';
import { StartupCareer } from 'pages/StartupCareer';
import { TermsOfUse } from 'pages/TermsOfUse';
import { PrivacyPolicy } from 'pages/PrivacyPolicy';
import { EmailSentPage } from 'pages/EmailSentPage';
import { SignUpPage } from 'pages/SignUpPage';
import { LoginPage } from 'pages/LoginPage';
import { ConfirmAuth } from 'pages/ConfirmAuth';
import { RecoverPassword } from 'pages/RecoverPassword';
import { DisableEmailNotifications } from 'pages/DisableEmailNotifications';
import { AdminDashboard } from 'pages/AdminDashboard';
import { HelpCenter } from 'pages/HelpCenter';
import { VacancyUnavailablePage } from 'pages/VacancyUnavailablePage';
import {
  routeActivation,
  routeBar,
  routeExpiration,
  routeForgotPassword,
  routeTermsOfUse,
  routePrivacyPolicy,
  routeEmailSent,
  routeSignUp,
  routeLogin,
  routeDashboard,
  routeConfirmAuth,
  routeRecoverPassword,
  routeDisableEmailNotifications,
  routeAdminLogin,
  routeAdminDashboard,
  routeHelpCenter,
  routeVacancyUnavailable,
} from './routesAddresses';
import { StartupsRoutes } from './startupsRoutes';
import { Route } from './Route';
import { CandidatesRoutes } from './candidatesRoutes';

export const Routes: React.FC = () => {
  const {
    location: { hostname },
  } = window;
  const [firstLocationSubdomain] = hostname.split('.');
  const isCandidatesRoutes = firstLocationSubdomain === 'candidatos';

  return (
    <>
      <ScrollToTop />

      <Switch>
        <Route
          isForAuthentication
          exact
          path={routeSignUp}
          component={SignUpPage}
        />
        <Route
          isForAuthentication
          exact
          path={[routeLogin, routeAdminLogin]}
          component={LoginPage}
        />
        <Route
          isForAuthentication
          exact
          path={routeForgotPassword}
          component={ForgotPassword}
        />
        <Route
          isForAuthentication
          exact
          path={routeRecoverPassword}
          component={RecoverPassword}
        />
        <Route
          exact
          path={routeConfirmAuth}
          component={ConfirmAuth}
          isForAuthentication
        />
        <Route exact path={routeEmailSent} component={EmailSentPage} />
        <Route path={routeActivation} component={ActivationPage} />
        <Route exact path={routeExpiration} component={ExpirationPage} />
        <Route
          exact
          path={routeVacancyUnavailable}
          component={VacancyUnavailablePage}
        />
        <Route exact path={routeTermsOfUse} component={TermsOfUse} />
        <Route exact path={routePrivacyPolicy} component={PrivacyPolicy} />
        <Route exact path={routeHelpCenter} component={HelpCenter} />
        <Route
          exact
          path={routeDisableEmailNotifications}
          component={DisableEmailNotifications}
        />
        <Route path={routeAdminDashboard} component={AdminDashboard} />

        {isCandidatesRoutes
          ? [
              <Route
                key={routeBar}
                exact
                path={routeBar}
                component={FeedVacancies}
              />,
              <Route
                key={routeDashboard}
                path={routeDashboard}
                component={CandidatesRoutes}
                isPrivate
              />,
              <Route
                exact
                key={`${routeBar}:vacancyId`}
                path={`${routeBar}:vacancyId`}
                component={VacancyApply}
              />,
            ]
          : [
              <Route
                exact
                key={routeBar}
                path={routeBar}
                component={HomePage}
              />,
              <Route
                key={routeDashboard}
                path={routeDashboard}
                component={StartupsRoutes}
                isPrivate
              />,
              <Route
                exact
                key={`${routeBar}:startupName`}
                path={`${routeBar}:startupName`}
                component={StartupCareer}
              />,
            ]}
      </Switch>
    </>
  );
};
