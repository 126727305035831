import closeIcon from 'assets/img/close.svg';

import { HTML5Backend } from 'react-dnd-html5-backend';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { EntityWithNameDTO } from 'types/EntityWithNameDTO';
import {
  STEP_COLORS,
  useVacanciesCustomSteps,
  VacancyStepWithColor,
} from 'hooks/vacanciesCustomSteps';
import { DeleteModal } from 'components/Modal/DeleteModal';
import { ERROR_MODAL_CONFIGURATION, useAlertModal } from 'hooks/alertModal';
import { Modal } from 'components/Modal';
import { DraggableStep } from './DraggableStep';
import {
  Container,
  OpenCustomStepsContainer,
  StepOptions,
  SearchStepsInput,
  StepsContainer,
  SearchStepsInputContainer,
  DeleteCandidateStepContainer,
  CreateStepButton,
  StepName,
} from './styles';

interface CandidateStepColumnDataProps {
  vacancyId: number;
  candidateStep: EntityWithNameDTO | null;
  vacancyCustomSteps: VacancyStepWithColor[];
  handleCreateCustomStep: (
    stepName: string,
    newStepColor: string,
  ) => Promise<void>;
  handleDeleteCustomStep: (vacancyCustomStepId: number) => Promise<void>;
  handleUpdateCandidateStep: (
    updatedStep: EntityWithNameDTO | null,
  ) => Promise<void>;
}

export const CandidateStepColumnData: React.FC<
  CandidateStepColumnDataProps
> = ({
  vacancyId,
  candidateStep,
  vacancyCustomSteps,
  handleCreateCustomStep,
  handleDeleteCustomStep,
  handleUpdateCandidateStep,
}) => {
  const { showModal } = useAlertModal();
  const { vacanciesCustomSteps } = useVacanciesCustomSteps();
  const stepOptionsRef = useRef<HTMLDivElement>(null);

  const [stepOptionsIsVisible, setStepOptionsIsVisible] = useState(false);
  const [searchedText, setSearchedText] = useState('');

  const [selectedCustomStepId, setSelectedCustomStepId] = useState<
    number | null
  >(null);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isLoadingStepDelete, setIsLoadingStepDelete] = useState(false);

  useEffect(() => {
    setSearchedText('');
  }, [candidateStep]);

  const candidateStepColor = useMemo(() => {
    return vacancyCustomSteps.find(({ id }) => id === candidateStep?.id)?.color;
  }, [candidateStep?.id, vacancyCustomSteps]);

  const searchedSteps = useMemo(() => {
    return vacancyCustomSteps.filter(({ name }) =>
      name.toLowerCase().includes(searchedText.toLowerCase()),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vacanciesCustomSteps, vacancyCustomSteps, searchedText]);

  const colorToCreateNewStep = useMemo(
    () =>
      vacancyCustomSteps.length >= 8
        ? undefined
        : STEP_COLORS[vacancyCustomSteps.length],
    [vacancyCustomSteps.length],
  );

  const handleCreateCustomStepButtonClick = useCallback(async () => {
    if (searchedText.length === 0) {
      showModal({ message: 'Insira um nome para poder criar a etapa!' });
      return;
    }

    await handleCreateCustomStep(searchedText, colorToCreateNewStep!);
    setSearchedText('');
  }, [searchedText, handleCreateCustomStep, colorToCreateNewStep, showModal]);

  const showDeleteModalForCustomStep = useCallback(
    (vacancyCustomStepId: number) => {
      setSelectedCustomStepId(vacancyCustomStepId);
      setIsDeleteModalVisible(true);
    },
    [],
  );

  const handleDeleteCustomStepButtonClick = useCallback(async () => {
    if (selectedCustomStepId === null) return;

    try {
      setIsLoadingStepDelete(true);
      await handleDeleteCustomStep(selectedCustomStepId);
    } catch (err) {
      showModal({
        ...ERROR_MODAL_CONFIGURATION,
        message:
          'Falha ao excluir a etapa, por favor, tente novamente mais tarde',
      });
    } finally {
      setIsLoadingStepDelete(false);
      setIsDeleteModalVisible(false);
    }
  }, [handleDeleteCustomStep, selectedCustomStepId, showModal]);

  const handleShowStepOptions = useCallback(() => {
    const { current } = stepOptionsRef;
    if (!current) return;

    setStepOptionsIsVisible(true);
    if (current.style.getPropertyValue('bottom') === 'auto') return;

    const { innerHeight } = window;
    const { bottom: optionsBottomDistance } = current.getBoundingClientRect();
    const { scrollHeight } = current;

    const heightDifferenceAboutWindow =
      innerHeight + 74 - (optionsBottomDistance + scrollHeight);

    if (heightDifferenceAboutWindow >= 0)
      current.style.setProperty('top', '6px');
    else {
      current.style.setProperty('top', `${6 + heightDifferenceAboutWindow}px`);
    }

    current.style.setProperty('bottom', 'auto');
  }, []);

  return (
    <Container>
      <Modal isVisible={isDeleteModalVisible}>
        <DeleteModal
          isLoadingDelete={isLoadingStepDelete}
          modalTitle="Você tem certeza que deseja excluir essa etapa?"
          deleteButtonText="Excluir etapa"
          hideDeleteModal={() => setIsDeleteModalVisible(false)}
          handleDelete={handleDeleteCustomStepButtonClick}
        />
      </Modal>

      <StepOptions
        ref={stepOptionsRef}
        isVisible={stepOptionsIsVisible}
        onMouseLeave={() => setStepOptionsIsVisible(false)}
      >
        <SearchStepsInputContainer>
          {candidateStep ? (
            <DeleteCandidateStepContainer backgroundColor={candidateStepColor}>
              <span>{candidateStep.name}</span>
              <button
                type="button"
                onClick={() => handleUpdateCandidateStep(null)}
              >
                <img src={closeIcon} alt="X" />
              </button>
            </DeleteCandidateStepContainer>
          ) : (
            <SearchStepsInput
              placeholder="Pesquise por uma opção..."
              value={searchedText}
              maxLength={21}
              onChange={({ target: { value } }) => setSearchedText(value)}
            />
          )}
        </SearchStepsInputContainer>

        <StepsContainer>
          <p>Selecione ou crie uma etapa</p>

          {searchedSteps.length === 0 && colorToCreateNewStep ? (
            <CreateStepButton
              type="button"
              onClick={handleCreateCustomStepButtonClick}
            >
              <span>Criar</span>

              <StepName backgroundColor={colorToCreateNewStep}>
                {searchedText}
              </StepName>
            </CreateStepButton>
          ) : (
            <DndProvider backend={HTML5Backend}>
              {searchedSteps.map((vacancyStep, index) =>
                vacancyStep.color === candidateStepColor ? null : (
                  <DraggableStep
                    key={vacancyStep.id}
                    vacancyId={vacancyId}
                    step={vacancyStep}
                    index={index}
                    handleUpdateCandidateStep={handleUpdateCandidateStep}
                    showDeleteModalForCustomStep={showDeleteModalForCustomStep}
                  />
                ),
              )}
            </DndProvider>
          )}
        </StepsContainer>
      </StepOptions>

      <OpenCustomStepsContainer
        type="button"
        backgroundColor={candidateStepColor}
        onClick={handleShowStepOptions}
      >
        {candidateStep === null ? 'Selecionar' : candidateStep.name}
      </OpenCustomStepsContainer>
    </Container>
  );
};
