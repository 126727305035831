import openOptions from 'assets/img/openOptions.svg';
import { OptionButton } from 'components/Buttons';

import { Modal } from 'components/Modal';
import { DeleteModal } from 'components/Modal/DeleteModal';
import { ERROR_MODAL_CONFIGURATION, useAlertModal } from 'hooks/alertModal';
import { useCallback, useRef, useState } from 'react';
import { Container, OptionsContainer, OpenOptionsButton } from './styles';

interface OptionsColumnDataProps {
  handleDeleteCandidate: () => Promise<void>;
}

export const OptionsColumnData: React.FC<OptionsColumnDataProps> = ({
  handleDeleteCandidate,
}) => {
  const { showModal } = useAlertModal();
  const optionsContainerRef = useRef<HTMLDivElement>(null);
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isLoadingCandidateDelete, setIsLoadingCandidateDelete] =
    useState(false);

  const handleDeleteCandidateButtonClick = useCallback(async () => {
    try {
      setIsLoadingCandidateDelete(true);
      await handleDeleteCandidate();
    } catch (err) {
      showModal({
        ...ERROR_MODAL_CONFIGURATION,
        message:
          'Erro ao excluir o candidato, por favor, tente novamente mais tarde',
      });

      setIsLoadingCandidateDelete(false);
      setIsDeleteModalVisible(false);
    }
  }, [handleDeleteCandidate, showModal]);

  const handleShowOptions = useCallback(() => {
    const { current } = optionsContainerRef;
    if (!current) return;

    setIsOptionsVisible(value => !value);
    if (current.style.getPropertyValue('bottom') === 'auto') return;

    const { innerHeight } = window;
    const { bottom: optionsBottomDistance } = current.getBoundingClientRect();
    const { scrollHeight } = current;

    const heightDifferenceAboutWindow =
      innerHeight - (optionsBottomDistance + scrollHeight + 16);

    if (heightDifferenceAboutWindow >= 0)
      current.style.setProperty('top', '70px');
    else {
      current.style.setProperty('top', `${70 + heightDifferenceAboutWindow}px`);
    }

    current.style.setProperty('bottom', 'auto');
  }, []);

  return (
    <Container>
      <Modal isVisible={isDeleteModalVisible}>
        <DeleteModal
          isLoadingDelete={isLoadingCandidateDelete}
          modalTitle="Você tem certeza que deseja excluir esse currículo?"
          deleteButtonText="Excluir currículo"
          hideDeleteModal={() => setIsDeleteModalVisible(false)}
          handleDelete={handleDeleteCandidateButtonClick}
        />
      </Modal>

      <OptionsContainer isVisible={isOptionsVisible} ref={optionsContainerRef}>
        <OptionButton
          type="button"
          onClick={() => setIsDeleteModalVisible(true)}
          isDeleteButton
        >
          Excluir
        </OptionButton>
      </OptionsContainer>

      <OpenOptionsButton
        type="button"
        onClick={handleShowOptions}
        onBlur={() => setIsOptionsVisible(false)}
      >
        <img src={openOptions} alt="Três pontos verticais" />
      </OpenOptionsButton>
    </Container>
  );
};
