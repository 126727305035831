import { FormWithStepsContainer } from 'assets/styles/formWithStepsStyles/formWithStepsContainerStyles';
import { formStepInputStyles } from 'assets/styles/formWithStepsStyles/formWithStepsInputStyles';
import {
  StepSubTitle,
  StepTitle,
  TitleContainer,
} from 'assets/styles/formWithStepsStyles/formWithStepsTitlesStyles';
import { Input } from 'components/Input';
import { useFormError } from 'errors/useFormError';
import { useCallback, useEffect } from 'react';
import { IFormStepProps, OmitId } from 'types';
import { IStartupSocialNetworks } from 'types/startup';
import { getInputStateValue } from 'utils';
import { parseUrl } from 'utils/conversion/parseUrl';
import { useInputStates } from 'utils/customHooks';
import { InputsContainer } from './styles';

export { validateSocialNetworksData } from './validateSocialNetworksData';
export type SocialNetworksStepData = Partial<OmitId<IStartupSocialNetworks>>;

export const SocialNetworksStep: React.FC<
  IFormStepProps<SocialNetworksStepData>
> = ({
  initialData = {} as SocialNetworksStepData,
  setStepDataFunction,
  errors,
}) => {
  const { website, linkedin, instagram, facebook } = initialData;
  const { handleFormError } = useFormError();

  const websiteStates = useInputStates('website', website ?? '');
  const linkedinStates = useInputStates('linkedin', linkedin ?? '');
  const instagramStates = useInputStates('instagram', instagram ?? '');
  const facebookStates = useInputStates('facebook', facebook ?? '');

  const getUpdatedStepData = useCallback(() => {
    const updatedStatesObject = {
      website: getInputStateValue(websiteStates),
      linkedin: getInputStateValue(linkedinStates),
      instagram: getInputStateValue(instagramStates),
      facebook: getInputStateValue(facebookStates),
    };

    return updatedStatesObject as SocialNetworksStepData;
  }, [facebookStates, instagramStates, linkedinStates, websiteStates]);

  useEffect(() => {
    if (!errors) return;

    const stepStates = [
      websiteStates,
      linkedinStates,
      instagramStates,
      facebookStates,
    ];
    handleFormError(errors, stepStates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    if (!setStepDataFunction) return;

    const updatedData = getUpdatedStepData();
    setStepDataFunction(updatedData);
  }, [getUpdatedStepData, setStepDataFunction]);

  return (
    <FormWithStepsContainer>
      <TitleContainer>
        <StepTitle>Site e redes sociais</StepTitle>
        <StepSubTitle>
          Ter um site próprio e perfil nas redes socais ajuda os candidatos a
          conhecerem um pouco mais sobre a empresa e a sua equipe.
        </StepSubTitle>
      </TitleContainer>

      <InputsContainer>
        <Input
          name="Site"
          placeholder="https://"
          states={websiteStates}
          style={formStepInputStyles}
          onBlur={() =>
            websiteStates.mainState.setFunction(
              parseUrl(websiteStates.mainState.value as string),
            )
          }
        />

        <Input
          name="LinkedIn"
          placeholder="https://"
          states={linkedinStates}
          style={formStepInputStyles}
          onBlur={() =>
            linkedinStates.mainState.setFunction(
              parseUrl(linkedinStates.mainState.value as string),
            )
          }
        />

        <Input
          name="Instagram"
          placeholder="https://"
          states={instagramStates}
          style={formStepInputStyles}
          onBlur={() =>
            instagramStates.mainState.setFunction(
              parseUrl(instagramStates.mainState.value as string),
            )
          }
        />

        <Input
          name="Facebook"
          placeholder="https://"
          states={facebookStates}
          style={formStepInputStyles}
          onBlur={() =>
            facebookStates.mainState.setFunction(
              parseUrl(facebookStates.mainState.value as string),
            )
          }
        />
      </InputsContainer>
    </FormWithStepsContainer>
  );
};
