import facebookIcon from 'assets/img/facebook.svg';
import whatsappIcon from 'assets/img/whatsapp.svg';
import twitterIcon from 'assets/img/twitter.svg';
import linkedinIcon from 'assets/img/linkedin.svg';
import closeIcon from 'assets/img/close.svg';
import copyIcon from 'assets/img/copy.svg';

import { candidatesUrlFrontend } from 'services/baseUrl';
import { useCallback, useMemo } from 'react';
import { shareOnSocialMedia } from 'services/socialMediaShareServices';
import { SocialMedia } from 'types/SocialMedia';
import { useAlertModal } from 'hooks/alertModal';
import {
  Container,
  TopContainer,
  SocialNetworksContainer,
  SocialNetwork,
  LinkContainer,
  ShareContainer,
} from './styles';

interface ShareVacancyModalProps {
  parsedVacancyId: number;
  handleCloseModal: () => void;
}

const SOCIAL_MEDIAS_ICONS = {
  Facebook: facebookIcon,
  Whatsapp: whatsappIcon,
  Twitter: twitterIcon,
  Linkedin: linkedinIcon,
};

export const ShareVacancyModal: React.FC<ShareVacancyModalProps> = ({
  parsedVacancyId,
  handleCloseModal,
}) => {
  const { showModal } = useAlertModal();

  const vacancyLink = useMemo(
    () => `${candidatesUrlFrontend}/${parsedVacancyId}`,
    [parsedVacancyId],
  );

  const handleCopyVacancyLinkToClipboard = useCallback(async () => {
    await navigator.clipboard.writeText(vacancyLink);

    showModal({
      message: 'Link copiado para a área de transferência',
    });
  }, [showModal, vacancyLink]);

  return (
    <Container>
      <TopContainer>
        <span>Compartilhar</span>

        <button type="button" onClick={handleCloseModal}>
          <img src={closeIcon} alt="Fechar" />
        </button>
      </TopContainer>

      <ShareContainer>
        <p>Compartilhe nas redes sociais:</p>

        <SocialNetworksContainer>
          {Object.entries(SOCIAL_MEDIAS_ICONS).map(([key, icon]) => (
            <SocialNetwork
              key={key}
              onClick={() =>
                shareOnSocialMedia(key as SocialMedia, vacancyLink)
              }
            >
              <img src={icon} alt={`Compartilhar no ${key}`} />
            </SocialNetwork>
          ))}
        </SocialNetworksContainer>

        <span>Copiar link</span>
        <LinkContainer>
          <span>{vacancyLink}</span>

          <button type="button" onClick={handleCopyVacancyLinkToClipboard}>
            <img src={copyIcon} alt="Copiar Link" />
          </button>
        </LinkContainer>
      </ShareContainer>
    </Container>
  );
};
