import downloadIcon from 'assets/img/download.svg';

import { Loader } from 'components/Loader';
import { Modal } from 'components/Modal';
import { useCallback, useEffect, useState } from 'react';
import { baseURLFrontend } from 'services/baseUrl';
import {
  adminDeleteUser,
  validateAdmin,
} from 'services/entitiesServices/adminServices';
import { CandidatesTab } from './CandidatesTab';
import { DeleteUserModal } from './DeleteUserModal';
import { StartupsTab } from './StartupsTab';
import {
  Container,
  TopContainer,
  TabSelectionContainer,
  TabButton,
} from './styles';

export type HandleGetCsvRowsFunctionType = null | (() => string[][]);

export const AdminDashboard: React.FC = () => {
  const [handleGetCsvRows, setHandleGetCsvRows] =
    useState<HandleGetCsvRowsFunctionType>(null);
  const [selectedUserId, setSelectedUserId] = useState<number>(-1);
  const [deletedUserId, setDeletedUserId] = useState<number | null>(null);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [selectedTab, setSelectedTab] = useState<'startups' | 'candidates'>(
    'startups',
  );
  const [selectedTabRowsQuantity, setSelectedTabRowsQuantity] = useState<
    number | null
  >(null);
  const [isLoadingAdminValidation, setIsLoadingAdminValidation] =
    useState(true);

  useEffect(() => {
    setSelectedTabRowsQuantity(null);
  }, [selectedTab]);

  useEffect(() => {
    validateAdmin()
      .then(() => setIsLoadingAdminValidation(false))
      .catch(() => window.location.replace(baseURLFrontend));
  }, []);

  useEffect(() => {
    setDeletedUserId(null);
  }, [selectedTab]);

  const handleShowDeleteModalForUser = useCallback((userId: number) => {
    setSelectedUserId(userId);
    setIsDeleteModalVisible(true);
  }, []);

  const handleDeleteUser = useCallback(
    async (password: string) => {
      await adminDeleteUser({ password, userToChangeId: selectedUserId });

      setSelectedUserId(-1);
      setDeletedUserId(selectedUserId);
    },
    [selectedUserId],
  );

  const handleDownloadCsvFile = useCallback(() => {
    if (handleGetCsvRows === null) return;
    const csvRows = handleGetCsvRows();

    const csvContent = `data:text/csv;charset=utf-8,${csvRows
      .map(row => row.join(','))
      .join('\n')}`;
    const encodedUri = encodeURI(csvContent);

    const downloadLink = document.createElement('a');
    downloadLink.href = encodedUri;
    downloadLink.download = 'data-in6.csv';
    downloadLink.click();
  }, [handleGetCsvRows]);

  if (isLoadingAdminValidation) return <Loader />;
  return (
    <Container>
      <Modal isVisible={isDeleteModalVisible}>
        <DeleteUserModal
          handleDeleteUser={handleDeleteUser}
          handleCloseModal={() => setIsDeleteModalVisible(false)}
        />
      </Modal>

      <TopContainer>
        <h1>Painel de gerenciamento</h1>

        <button type="button" onClick={handleDownloadCsvFile}>
          <img src={downloadIcon} alt="Baixar dados" />
          <span>Download CSV</span>
        </button>
      </TopContainer>

      <TabSelectionContainer>
        <TabButton
          type="button"
          isSelected={selectedTab === 'startups'}
          onClick={() => setSelectedTab('startups')}
        >
          Empresas
          {selectedTabRowsQuantity !== null && selectedTab === 'startups'
            ? ` (${selectedTabRowsQuantity})`
            : ''}
        </TabButton>
        <TabButton
          type="button"
          isSelected={selectedTab === 'candidates'}
          onClick={() => setSelectedTab('candidates')}
        >
          Candidatos
          {selectedTabRowsQuantity !== null && selectedTab === 'candidates'
            ? ` (${selectedTabRowsQuantity})`
            : ''}
        </TabButton>
      </TabSelectionContainer>

      {selectedTab === 'startups' ? (
        <StartupsTab
          handleShowDeleteUserModal={handleShowDeleteModalForUser}
          deletedUserId={deletedUserId}
          setHandleGetCsvRows={updatedHandleGetCsvRows =>
            setHandleGetCsvRows(() => updatedHandleGetCsvRows)
          }
          setRowsQuantity={rowsQuantity =>
            setSelectedTabRowsQuantity(rowsQuantity)
          }
        />
      ) : (
        <CandidatesTab
          handleShowDeleteUserModal={handleShowDeleteModalForUser}
          deletedUserId={deletedUserId}
          setHandleGetCsvRows={updatedHandleGetCsvRows =>
            setHandleGetCsvRows(() => updatedHandleGetCsvRows)
          }
          setRowsQuantity={rowsQuantity =>
            setSelectedTabRowsQuantity(rowsQuantity)
          }
        />
      )}
    </Container>
  );
};
