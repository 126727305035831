import closeIcon from 'assets/img/close.svg';
import { PrimaryButton, SecondaryButton } from 'components/Buttons';
import { useHistory } from 'react-router-dom';
import { routeLogin, routeSignUp } from 'routes/routesAddresses';
import {
  Container,
  CloseButton,
  ModalSide,
  Divider,
  TopContainer,
} from './styles';

interface ApplyModalWithoutAccountProps {
  handleCloseModal: () => void;
}

export const ApplyModalWithoutAccount: React.FC<
  ApplyModalWithoutAccountProps
> = ({ handleCloseModal }) => {
  const history = useHistory();

  return (
    <Container>
      <TopContainer>
        <span>Candidatar-se para a vaga</span>

        <CloseButton type="button" onClick={handleCloseModal}>
          <img src={closeIcon} alt="Fechar modal" />
        </CloseButton>
      </TopContainer>

      <ModalSide>
        <h3>Antes de continuar</h3>

        <p>
          Acesse sua conta para continuar com a candidatura nesta vaga de
          trabalho
        </p>

        <SecondaryButton type="button" onClick={() => history.push(routeLogin)}>
          Acessar minhar conta
        </SecondaryButton>
      </ModalSide>

      <Divider>ou</Divider>

      <ModalSide>
        <p>
          Se você ainda não tem uma conta, vamos começar cadastrando seu
          currículo
        </p>

        <PrimaryButton
          type="button"
          onClick={() =>
            history.push(routeSignUp, { candidateAccountTypeSelected: true })
          }
        >
          Cadastrar meu currículo
        </PrimaryButton>
      </ModalSide>
    </Container>
  );
};
