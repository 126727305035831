import * as yup from 'yup';
import { BusinessModelStepData } from '.';

export const validateBusinessModelData = async (
  businessModelData: BusinessModelStepData,
): Promise<void> => {
  const schema = yup.object().shape({
    businessModel: yup.object().shape({
      id: yup.number().required('Necessário informar o modelo de negócio'),
    }),
  });

  await schema.validate(businessModelData, { abortEarly: false });
};
