/* eslint-disable react-hooks/rules-of-hooks */
import { PrimaryButton } from 'components/Buttons';
import { Header } from 'components/Header';
import { Input } from 'components/Input';
import { COMPANY_NAME } from 'constants/company';
import { useFormError } from 'errors/useFormError';
import { useCallback, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { routeBar, routeLogin } from 'routes/routesAddresses';
import { recoverPassword } from 'services/entitiesServices/userServices';
import { generateFormObjectFromStates } from 'utils';
import { useInputStates } from 'utils/customHooks';
import { yupRequiredStringField } from 'utils/validation';

import * as yup from 'yup';
import in6Logo from 'assets/img/in6Logo.svg';

import { DotFlashing } from 'components/DotFlashing';
import { SUCCESS_MODAL_CONFIGURATION, useAlertModal } from 'hooks/alertModal';
import { Container, Title } from './styles';

export const RecoverPassword: React.FC = () => {
  document.title = `Recuperar Senha - ${COMPANY_NAME}`;
  const history = useHistory();
  const { handleFormError } = useFormError();
  const { showModal } = useAlertModal();

  const params = useParams() as { recoverToken: string } | undefined;
  if (!params) return <Redirect to={routeBar} />;

  const [isLoadingRecover, setIsLoadingRecover] = useState(false);
  const newPasswordStates = useInputStates('newPassword');
  const confirmPasswordStates = useInputStates('confirmPassword');

  const handleSubmit = useCallback(async () => {
    setIsLoadingRecover(true);
    const formStates = [newPasswordStates, confirmPasswordStates];
    const formObject = generateFormObjectFromStates(formStates);

    try {
      const schema = yup.object().shape({
        newPassword: yupRequiredStringField.min(
          8,
          'A senha precisa ter, no mínimo, 8 caracteres',
        ),
        confirmPassword: yupRequiredStringField.oneOf(
          [yup.ref('newPassword')],
          'As senhas inseridas não são iguais',
        ),
      });

      await schema.validate(formObject, { abortEarly: false });
      await recoverPassword({
        newPassword: formObject.newPassword as string,
        token: params.recoverToken,
      });

      history.push(routeLogin);
      showModal({
        ...SUCCESS_MODAL_CONFIGURATION,
        message: 'Senha alterada com sucesso',
      });
    } catch (error) {
      setIsLoadingRecover(false);
      handleFormError(error as Error | yup.ValidationError, formStates);
    }
  }, [
    confirmPasswordStates,
    handleFormError,
    history,
    newPasswordStates,
    params.recoverToken,
    showModal,
  ]);

  return (
    <>
      <Header logo={in6Logo} redirectRoute={routeBar} />

      <Container>
        <Title>Criar nova senha</Title>

        <Input
          name="Nova senha"
          placeholder="digite a nova senha"
          states={newPasswordStates}
          type="password"
        />
        <Input
          name="Confirmação da nova senha"
          placeholder="digite a confirmação da senha"
          states={confirmPasswordStates}
          type="password"
        />

        <PrimaryButton
          type="submit"
          onClick={handleSubmit}
          disabled={isLoadingRecover}
        >
          {isLoadingRecover ? (
            <>
              Carregando
              <DotFlashing />
            </>
          ) : (
            'Confirmar nova senha'
          )}
        </PrimaryButton>
      </Container>
    </>
  );
};
