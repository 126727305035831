import { Depth16 } from 'assets/colors/boxShadows';
import { BackgroundColor, OutlineColor } from 'assets/colors/palette';
import styled, { css } from 'styled-components';

interface EvaluationOptionsContainerProps {
  isVisible: boolean;
}

export const Container = styled.div`
  position: absolute;
  bottom: 0;
  height: 100%;
  left: 0;

  &,
  div,
  button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const OpenEvaluationOptionsButton = styled.button`
  width: 48px;
  height: 48px;
  background-color: transparent;
`;

export const EvaluationOptionsContainer = styled.div<EvaluationOptionsContainerProps>`
  position: absolute;
  gap: 18px;

  width: 200px;
  height: 48px;
  padding: 12px 24px;
  left: 0;
  z-index: 2;

  background-color: ${BackgroundColor};
  box-shadow: ${Depth16};
  border: 1px solid ${OutlineColor};
  border-radius: 8px;
  transition: 0.3s;

  ${({ isVisible }) =>
    isVisible
      ? css`
          top: -36px;
          opacity: 1;
          visibility: visible;
        `
      : css`
          top: -32px;
          visibility: hidden;
          opacity: 0;
        `}
`;

export const EvaluationOption = styled.button`
  background: transparent;
  border-radius: 50%;
  padding: 4px;

  img {
    width: 20px;
    height: 20px;
    transition: 0.2s;
  }

  &:hover {
    img {
      margin-top: -8px;

      width: 28px;
      height: 28px;
    }
  }
`;
