import styled from 'styled-components';
import { BackgroundColor, GrayLine } from 'assets/colors/palette';

export const MainContainer = styled.header`
  height: 64px;
  width: 100%;
  background: ${BackgroundColor};
  border-bottom: solid 1px ${GrayLine};
  z-index: 1;

  .subContainer {
    width: 1200px;
    height: 100%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    button {
      border: 0;
      outline: 0;
      background: transparent;
      margin-top: 16px;
      cursor: pointer;
    }
  }

  @media (max-width: 1100px) {
    .subContainer {
      width: 100vw;
    }
  }
`;
