import { CandidatesTestsAverageType, CandidateTest } from 'types/candidate';
import { IFiledStartupCandidateResponseWithoutFolder } from 'types/filedStartupCandidate';
import { IVacancyCandidateWithCandidateData } from 'types/vacancyCandidate';

export const calculateCandidatesTestsAverage = (
  candidates: (
    | IVacancyCandidateWithCandidateData
    | IFiledStartupCandidateResponseWithoutFolder
  )[],
): CandidatesTestsAverageType => {
  const testsAverage = {} as CandidatesTestsAverageType;
  const validCandidatesQuantityOnEachTest = {} as CandidatesTestsAverageType;

  Object.keys(CandidateTest).forEach(test => {
    if (test === CandidateTest.PROFESSIONAL_PROFILE) return;

    const parsedTest = test as CandidateTest;
    testsAverage[parsedTest] = 0;
    validCandidatesQuantityOnEachTest[parsedTest] = 0;
  });

  candidates.forEach(({ candidate: { testEvaluations } }) =>
    testEvaluations.forEach(({ candidateTest, evaluation }) => {
      if (candidateTest === CandidateTest.PROFESSIONAL_PROFILE) return;

      testsAverage[candidateTest] += Number(evaluation);
      validCandidatesQuantityOnEachTest[candidateTest] += 1;
    }),
  );

  Object.keys(CandidateTest).forEach(test => {
    if (test === CandidateTest.PROFESSIONAL_PROFILE) return;

    const parsedTest = test as CandidateTest;
    testsAverage[parsedTest] /= validCandidatesQuantityOnEachTest[parsedTest];
    testsAverage[parsedTest] = Math.ceil(testsAverage[parsedTest]);
  });

  return testsAverage;
};
