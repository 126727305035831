import {
  BackgroundColor,
  OutlineColor,
  PrimaryColor,
} from 'assets/colors/palette';
import { Medium16Styles } from 'assets/fonts/fontStyles';
import styled from 'styled-components';

export const Container = styled.div`
  width: 454px;

  background-color: ${BackgroundColor};
  border-radius: 8px;
  border-color: ${OutlineColor};
`;

export const ActionTitle = styled.div`
  ${Medium16Styles}
  line-height: 48px;

  width: 100%;
  padding: 6px 20px;

  border-bottom: 1px solid ${OutlineColor};
`;

export const ActionDetails = styled.div`
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.75px;
  font-weight: 400;

  padding: 24px 20px;
  background-color: rgb(220, 220, 220, 0.25);

  div {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 12px;
  }

  div,
  span {
    font-weight: 700;
  }
`;

export const BottomContainer = styled.div`
  padding: 32px 20px 20px;
`;

export const ActionButtons = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    ${Medium16Styles}

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${BackgroundColor};
    height: 48px;
    padding: 16px 32px;

    border: 1px solid ${OutlineColor};
    border-radius: 100px;
    transition: 0.2s;

    &:disabled {
      color: ${OutlineColor};
    }

    &:not(:disabled):hover {
      border-color: ${PrimaryColor};
    }
  }
`;
