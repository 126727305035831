import in6Logo from 'assets/img/in6Logo.svg';

import { Header } from 'components/Header';
import { routeBar } from 'routes/routesAddresses';
import { Container, TitleContainer } from './styles';

export const TermsOfUse: React.FC = () => {
  return (
    <>
      <Header logo={in6Logo} redirectRoute={routeBar} />

      <Container>
        <TitleContainer>
          <h1>Termos de uso</h1>
          <h4>
            Saiba como nós coletamos, usamos e compartilhamos seus dados
            pessoais.
          </h4>
          <span>Atualizado em X de X de 2022</span>
        </TitleContainer>
      </Container>
    </>
  );
};
