import searchIcon from 'assets/img/search.svg';

import { Loader } from 'components/Loader';

import { useCallback, useEffect, useRef, useState } from 'react';
import { adminFindAllStartups } from 'services/entitiesServices/adminServices';
import { convertCityAndStateFromObjectToString } from 'utils/conversion/convertCityAndStateFromObjectToString';
import { IStartupResponseForFindAll } from 'types/startup';
import {
  ColumnNamesContainer,
  ContentTable,
  SearchBar,
  SearchContainer,
  SearchedContentContainer,
} from '../styles';
import { StartupRow } from './StartupRow';
import { Container } from './styles';
import { HandleGetCsvRowsFunctionType } from '..';

interface StartupsTabProps {
  handleShowDeleteUserModal: (userId: number) => void;
  deletedUserId: number | null;
  setHandleGetCsvRows: (
    updatedHandleGetCsvRows: HandleGetCsvRowsFunctionType,
  ) => void;
  setRowsQuantity: (rowsQuantity: number) => void;
}

export const StartupsTab: React.FC<StartupsTabProps> = ({
  handleShowDeleteUserModal,
  deletedUserId,
  setHandleGetCsvRows,
  setRowsQuantity,
}) => {
  const searchInputRef = useRef<HTMLInputElement>(null);

  const [searchedText, setSearchedText] = useState('');
  const [isLoadingStartups, setIsLoadingStartups] = useState(true);
  const [startups, setStartups] = useState<IStartupResponseForFindAll[]>([]);
  const [searchedStartupsIndexes, setSearchedStartupsIndexes] = useState<
    number[]
  >([]);

  useEffect(() => {
    adminFindAllStartups()
      .then(findedStartups => setStartups(findedStartups))
      .finally(() => setIsLoadingStartups(false));
  }, []);

  useEffect(() => {
    if (deletedUserId === null) return;

    setStartups(previousStartups =>
      previousStartups.filter(({ employer: { id } }) => id !== deletedUserId),
    );
  }, [deletedUserId]);

  const startupMeetsSearchText = useCallback(
    ({
      name: startupName,
      employer: { name, lastName },
      startupLocations,
    }: IStartupResponseForFindAll) => {
      if (startupName.toLowerCase().includes(searchedText)) return true;

      const parsedEmployerName = `${name} ${lastName}`.toLowerCase();
      if (parsedEmployerName.includes(searchedText)) return true;

      return startupLocations.some(
        ({ city: { name: cityName }, state: { name: stateName } }) =>
          cityName.toLowerCase().includes(searchedText) ||
          stateName.toLowerCase().includes(searchedText),
      );
    },
    [searchedText],
  );

  useEffect(() => {
    if (startups.length === 0) return;

    const filteredStartupsIndexes = startups
      .map((startup, index) => {
        if (startupMeetsSearchText(startup)) return index;
        return null;
      })
      .filter(indexValue => indexValue !== null) as number[];

    setRowsQuantity(filteredStartupsIndexes.length);
    setSearchedStartupsIndexes(filteredStartupsIndexes);
  }, [setRowsQuantity, startupMeetsSearchText, startups]);

  const handleGetSearchedStartupsCsvRows = useCallback(() => {
    const firstRow = ['EMPRESA', 'RESPONSÁVEL', 'CIDADE', 'EMAIL'];

    const startupsRows = searchedStartupsIndexes.map(index => {
      const {
        name: startupName,
        startupLocations: [{ city, state }],
        employer: { name, lastName, email },
      } = startups[index];

      const startupCsvContent = [];
      startupCsvContent.push(
        startupName,
        `${name} ${lastName}`,
        convertCityAndStateFromObjectToString({ city, state }, ' - ', true),
        email,
      );

      return startupCsvContent;
    });

    return [firstRow, ...startupsRows];
  }, [startups, searchedStartupsIndexes]);

  useEffect(() => {
    if (startups.length === 0) setHandleGetCsvRows(null);
    else setHandleGetCsvRows(handleGetSearchedStartupsCsvRows);
  }, [startups.length, handleGetSearchedStartupsCsvRows, setHandleGetCsvRows]);

  return (
    <Container>
      <SearchContainer>
        <SearchBar onClick={() => searchInputRef.current?.focus()}>
          <img src={searchIcon} alt="Lupa de busca" />
          <input
            ref={searchInputRef}
            type="text"
            placeholder="Pesquise pelas empresas"
            onChange={({ target: { value } }) =>
              setSearchedText(value.toLowerCase())
            }
          />
        </SearchBar>
      </SearchContainer>

      {isLoadingStartups ? (
        <Loader />
      ) : (
        <ContentTable>
          <ColumnNamesContainer>
            <span className="column-title startups-column">EMPRESA</span>
            <span className="column-title employer-column">RESPONSÁVEL</span>
            <span className="column-title location-column">CIDADE</span>
            <span className="column-title email-column">EMAIL</span>
            <span className="column-title options-column" />
          </ColumnNamesContainer>

          <SearchedContentContainer>
            {searchedStartupsIndexes.map(index =>
              startups[index] === undefined ? null : (
                <StartupRow
                  key={startups[index].id}
                  startup={startups[index]}
                  handleShowDeleteUserModal={handleShowDeleteUserModal}
                />
              ),
            )}
          </SearchedContentContainer>
        </ContentTable>
      )}
    </Container>
  );
};
