import banner1 from 'assets/img/banner1.svg';
import step1 from 'assets/img/step1.svg';
import step2 from 'assets/img/step2.svg';
import step3 from 'assets/img/step3.svg';
import step4 from 'assets/img/step4.svg';
import resource1 from 'assets/img/resource1.svg';
import resource2 from 'assets/img/resource2.svg';
import resource3 from 'assets/img/resource3.svg';
import resource4 from 'assets/img/resource4.svg';
import searchExample from 'assets/img/searchExample.svg';
import in6Logo from 'assets/img/in6Logo.svg';
import ufersaLogo from 'assets/img/ufersaLogo.svg';
import iagramLogo from 'assets/img/iagramLogo.svg';
import instagram from 'assets/img/instagram.svg';
import facebook from 'assets/img/facebook.svg';
import tiktok from 'assets/img/tiktok.svg';
import twitter from 'assets/img/twitter.svg';
import linkedin from 'assets/img/linkedin.svg';
import loginIcon from 'assets/img/login.svg';
import blockContent1 from 'assets/img/blockContent1.svg';
import blockContent2 from 'assets/img/blockContent2.svg';
import blockContent3 from 'assets/img/blockContent3.svg';
import blockContent4 from 'assets/img/blockContent4.svg';

import { COMPANY_NAME } from 'constants/company';
import { PrimaryButton, SecondaryButton } from 'components/Buttons';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppLogo } from 'components/AppLogo';
import { candidatesUrlFrontend } from 'services/baseUrl';
import { routeLogin, routeSignUp } from 'routes/routesAddresses';
import {
  HeaderMainContainer,
  RedirectButton,
  SectionMainContainer,
  Section1,
  Section2,
  Section3,
  Section4,
  Section5,
  CompanyFooterMainContainer,
} from './styles';

type RedirectButtonType =
  | 'how-to-use'
  | 'resources'
  | 'in6-vacancies'
  | undefined;

export const HomePage: React.FC = () => {
  document.title = COMPANY_NAME;

  const history = useHistory();
  const howToUseSection = useRef<HTMLDivElement>(null);
  const resourcesSection = useRef<HTMLDivElement>(null);
  const in6VacanciesSection = useRef<HTMLDivElement>(null);
  const candidatesToolsSection = useRef<HTMLDivElement>(null);

  const [activeButton, setActiveButton] =
    useState<RedirectButtonType>(undefined);

  const sectionsArray = useMemo(
    () => [
      howToUseSection,
      resourcesSection,
      in6VacanciesSection,
      candidatesToolsSection,
    ],
    [
      howToUseSection,
      resourcesSection,
      in6VacanciesSection,
      candidatesToolsSection,
    ],
  );

  const getTopDistance = useCallback(
    (element: HTMLDivElement) => element.offsetTop - 110,
    [],
  );

  const getUpdatedActiveButton = useCallback((): RedirectButtonType => {
    const { scrollY } = window;

    if (scrollY < getTopDistance(sectionsArray[0].current as HTMLDivElement)) {
      setActiveButton(undefined);
      return undefined;
    }

    for (let ind = 0; ind < sectionsArray.length; ind++) {
      const nextElement = sectionsArray[ind + 1];
      if (!nextElement) return 'in6-vacancies';

      const currentElement = sectionsArray[ind].current as HTMLDivElement;
      const currentNextElement = nextElement.current as HTMLDivElement;
      if (
        scrollY >= getTopDistance(currentElement) &&
        scrollY < getTopDistance(currentNextElement)
      ) {
        return currentElement.getAttribute('id') as RedirectButtonType;
      }
    }

    return undefined;
  }, [getTopDistance, sectionsArray]);

  const handleHighlightOnScroll = useCallback(() => {
    const updatedValue = getUpdatedActiveButton();
    if (updatedValue && updatedValue !== activeButton) {
      setActiveButton(updatedValue);
    }
  }, [activeButton, getUpdatedActiveButton]);

  useEffect(() => {
    if (sectionsArray[0])
      document.addEventListener('scroll', handleHighlightOnScroll);
    return () => {
      document.removeEventListener('scroll', handleHighlightOnScroll);
    };
  }, [handleHighlightOnScroll, sectionsArray]);

  return (
    <>
      <HeaderMainContainer>
        <div className="subContainer">
          <nav>
            <img src={in6Logo} alt="Logo IN6" />

            <RedirectButton
              isActive={activeButton === 'how-to-use'}
              href="#how-to-use"
            >
              Como usar
            </RedirectButton>
            <RedirectButton
              isActive={activeButton === 'resources'}
              href="#resources"
            >
              Recursos
            </RedirectButton>
            <RedirectButton
              isActive={activeButton === 'in6-vacancies'}
              href="#in6-vacancies"
            >
              IN6 Para candidatos
            </RedirectButton>
          </nav>

          <div>
            <PrimaryButton
              type="button"
              onClick={() => history.push(routeSignUp)}
            >
              Criar conta gratuita
            </PrimaryButton>

            <SecondaryButton
              type="button"
              onClick={() => history.push(routeLogin)}
            >
              Entrar
              <img src={loginIcon} alt="Fazer Login" />
            </SecondaryButton>
          </div>
        </div>
      </HeaderMainContainer>

      <SectionMainContainer>
        <div className="subContainer">
          <Section1>
            <p className="title">
              Otimize o Recrutamento & Seleção da sua empresa
            </p>

            <p className="description">
              Chega de fazer todos os processos de recrutamento e seleção
              manualmente. Na IN6 você consegue contratar mais rápido com
              recursos que permitem encontrar com mais facilidade o candidato
              qualificado para a vaga.
            </p>

            <div>
              <PrimaryButton
                type="button"
                onClick={() => history.push(routeSignUp)}
              >
                Crie agora uma vaga grátis
              </PrimaryButton>
              <a href={candidatesUrlFrontend}>Ver vagas abertas</a>
            </div>
          </Section1>

          <img src={banner1} alt="banner1" width={1250} />

          <Section2 id="how-to-use" ref={howToUseSection}>
            <p className="description">
              Mais rápido e mais fácil do que qualquer outro serviço.
            </p>

            <p className="title">
              Automatize tarefas e <br /> foque no que importa
            </p>

            <img src={step1} alt="step1" />
            <img src={step2} alt="step2" />
            <img src={step3} alt="step2" />
            <img src={step4} alt="step4" />
          </Section2>

          <Section3 id="resources" ref={resourcesSection}>
            <p className="description">Seu recrutamento em outro nível.</p>

            <p className="title">
              Grandes recursos <br />
              para sua empresa
            </p>

            <div className="resource1">
              <img src={resource1} alt="resource1" />

              <div className="content">
                <p className="resource1Title">
                  O início da sua próxima contratação
                </p>

                <p className="resource1Description">
                  Crie suas vagas em menos de 10 minutos! Nossa plataforma de
                  R&S proporciona maior agilidade e organização dos processos de
                  recrutamento e seleção da sua empresa.
                </p>
              </div>
            </div>

            <div className="resource2">
              <img src={resource2} alt="resource2" />

              <div className="content">
                <p className="resource2Title">Divulgue nas redes sociais</p>

                <p className="resource2Description">
                  O RH pode perder muito tempo com trabalhos repetitivos.
                  Automação inteligente de posts para redes sociais reduzem esse
                  tempo para que o seu time possa agregar valor no trabalho que
                  interessa.
                </p>
              </div>
            </div>

            <div className="resource3">
              <img src={resource3} alt="resource3" />

              <div className="content">
                <p className="resource3Title">Receba currículos</p>

                <div className="resource3Description">
                  Aumente a sua base de currículos. Não precisa mais lidar com
                  toda a papelada dos currículos. Crie sua vaga e diponibilize o
                  link de inscrição para os candidatos. Tenha sua própria base
                  de talentos na{' '}
                  <span className="custom">IN6 Para candidatos</span>.
                </div>
              </div>
            </div>

            <div className="resource4">
              <img src={resource4} alt="resource4" />

              <div className="content">
                <p className="resource4Title">Pesquise, avalie e converse </p>

                <p className="resource4Description">
                  Utilize a barra de pesquisa para localizar de forma rápida os
                  currículos cadastrados, agilizando o processo de análise.
                  Marque os candidatos que você gostou e converse diretamente
                  com eles utilizando o botão nativo para o WhatsApp.
                </p>
              </div>
            </div>
          </Section3>

          <Section4 id="in6-vacancies" ref={in6VacanciesSection}>
            <AppLogo logoType="candidatos" />

            <p className="title">
              Encontre o trabalho <br />
              que você precisa
            </p>

            <p className="content">
              Aqui os candidatos podem se candidatar em qualquer vaga de forma
              livre, sem precisar fazer nenhum tipo de cadastro e de graça.
              Encontrou uma vaga, preencheu as informações solicitadas, já está
              concorrendo ao trabalho.
            </p>

            <a href={candidatesUrlFrontend}>Ver vagas abertas</a>
            <img src={searchExample} alt="Exemplo de pesquisa" />
          </Section4>

          <Section5 id="candidates-tools" ref={candidatesToolsSection}>
            <p className="description">Vantagens para os candidatos</p>

            <p className="title">
              Ferramentas que <br />
              ajudam os candidatos
            </p>

            <div>
              <img src={blockContent1} alt="" />
              <img src={blockContent2} alt="" />
            </div>
            <div>
              <img src={blockContent3} alt="" />
              <img src={blockContent4} alt="" />
            </div>
          </Section5>
        </div>

        <CompanyFooterMainContainer>
          <div className="logos-line">
            <img src={in6Logo} alt="Logo IN6" width={58} />

            <div className="other-logos">
              <p>Apoio & Incubação</p>

              <div className="ufersa-logos">
                <img src={ufersaLogo} alt="Logo UFERSA" />
                <canvas />
                <img src={iagramLogo} alt="Logo iagram" />
              </div>
            </div>
          </div>

          <div className="contact">
            <div className="social-medias">
              <a href="https://www.instagram.com/in6.com.br/" target="blank">
                <img src={instagram} alt="Instagram IN6" />
              </a>
              <a href="." target="blank">
                <img src={facebook} alt="Facebook IN6" />
              </a>
              <a href="." target="blank">
                <img src={tiktok} alt="Tiktok IN6" />
              </a>
              <a href="." target="blank">
                <img src={twitter} alt="Twitter IN6" />
              </a>
              <a href="https://www.linkedin.com/company/in6/" target="blank">
                <img src={linkedin} alt="Linkedin IN6" />
              </a>
            </div>

            <p>contato@in6.com.br</p>
          </div>
        </CompanyFooterMainContainer>
      </SectionMainContainer>
    </>
  );
};
