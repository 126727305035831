import styled, { css } from 'styled-components';
import {
  BackgroundColor,
  GradientPrimary,
  HighEmphasis,
  OutlineColor,
  PrimaryColor,
} from 'assets/colors/palette';
import { Regular14Styles } from 'assets/fonts/fontStyles';
import { Link } from 'react-router-dom';

interface TabButtonProps {
  $isSelectedTab: boolean;
}

export const HeaderContainer = styled.header`
  width: 100%;
  height: 64px;
  border-bottom: solid 1px ${OutlineColor};
  background: ${BackgroundColor};

  display: flex;
  justify-content: center;
  z-index: 2;
`;

export const HeaderSubContainer = styled.div`
  width: 1248px;
  height: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1100px) {
    width: 90vw;
  }
`;

export const TabButton = styled(Link)<TabButtonProps>`
  ${Regular14Styles}

  background: ${HighEmphasis};
  height: 16px;

  ${({ $isSelectedTab }) =>
    $isSelectedTab
      ? css`
          background: ${GradientPrimary};
          font-weight: 700;
        `
      : css`
          &:hover {
            transition: 0.2s;
            background: ${PrimaryColor};
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        `}

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const NavigationContainer = styled.nav`
  display: flex;
  align-items: center;
  gap: 30px;

  button:first-child {
    margin-right: 10px;
  }
`;
