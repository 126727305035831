import { FormWithStepsContainer } from 'assets/styles/formWithStepsStyles/formWithStepsContainerStyles';
import { formStepInputStyles } from 'assets/styles/formWithStepsStyles/formWithStepsInputStyles';
import {
  StepSubTitle,
  StepTitle,
  TitleContainer,
} from 'assets/styles/formWithStepsStyles/formWithStepsTitlesStyles';
import { SecondaryButton } from 'components/Buttons';
import { Input } from 'components/Input';
import { ImageInput } from 'components/Input/ImageInput';
import { InputErrorMessageStyle } from 'components/Input/styles';
import { Select } from 'components/Select';
import { useFormError } from 'errors/useFormError';
import { useAuth } from 'hooks/auth';
import { useWindowWidth } from 'hooks/windowWidth';
import { InputsContainer } from 'pages/Dashboards/StartupsDashboard/Tabs/VacancyFormWithStepsTab/Steps/VacancyDefinitionStep/styles';
import { useCallback, useEffect, useRef, useState } from 'react';
import { OptionTypeBase } from 'react-select';
import { mask } from 'remask';
import { findAllCitiesByStateId } from 'services/entitiesServices/cityServices';
import { findAllStates } from 'services/entitiesServices/stateServices';
import { IFormStepProps, phonePattern } from 'types';
import { ICity, IState } from 'types/location';
import { getInputStateValue } from 'utils';
import { convertLocationToOptionTypeBase } from 'utils/conversion/convertLocationToOptionTypeBase';
import { parseUrl } from 'utils/conversion/parseUrl';
import { useInputStates } from 'utils/customHooks';
import { emptyReactInputState } from 'utils/emptyReactInputState';
import { generateBrazilianMonthOptions } from 'utils/typeOptions/generateBrazilianMonthOptions';
import { generateYearOptions } from 'utils/typeOptions/generateYearOptions';
import {
  MultiInputContainer,
  ImageDescriptionContainer,
  ImageInputContainer,
} from './styles';

export { validatePersonalInformationData } from './validatePersonalInformationData';

export interface PersonalInformationStepData {
  birthDate: string;
  profession: string;
  state: IState;
  city: ICity;
  phoneNumber: string;
  linkedin?: string;
  photo?: string;
}

const BRAZILIAN_MONTHS_OPTIONS = generateBrazilianMonthOptions();
const BIRTH_YEAR_OPTIONS = generateYearOptions();

export const PersonalInformationStep: React.FC<
  IFormStepProps<PersonalInformationStepData>
> = ({
  initialData = {} as PersonalInformationStepData,
  setStepDataFunction,
  errors,
}) => {
  const { birthDate, city, phoneNumber, profession, state, linkedin, photo } =
    initialData;
  const { user } = useAuth();
  const { isMobileScreen } = useWindowWidth();
  const { handleFormError } = useFormError();

  const linkedinStates = useInputStates('linkedin', linkedin);
  const phoneNumberStates = useInputStates('phoneNumber', phoneNumber);
  const professionStates = useInputStates('profession', profession);

  const [birthMonth, birthYear] = (birthDate ?? '').split('/');
  const birthMonthStates = useInputStates('birthMonth', birthMonth);
  const birthYearStates = useInputStates('birthYear', birthYear);

  const stateStates = useInputStates('state', state?.id);
  const cityStates = useInputStates('city', city?.id);

  const photoStates = useInputStates('photo', photo ?? '');
  const photoInputRef = useRef<HTMLInputElement>(null);

  const [states, setStates] = useState<OptionTypeBase[]>([]);
  const [isLoadingStates, setIsLoadingStates] = useState(true);

  const [cities, setCities] = useState<OptionTypeBase[]>([]);
  const [isLoadingCities, setIsLoadingCities] = useState(true);

  const getUpdatedStepData = useCallback(() => {
    const parsedBirthMonth = getInputStateValue(birthMonthStates);
    const parsedBirthYear = getInputStateValue(birthYearStates);
    const parsedBirthDate = `${parsedBirthMonth}/${parsedBirthYear}`;

    const cityId = Number(getInputStateValue(cityStates));
    const { label: cityName } = cities.find(({ id }) => id === cityId) ?? {};
    const parsedCity = {
      id: cityId,
      name: cityName,
    };

    const stateId = Number(getInputStateValue(stateStates));
    const { label: stateName } = states.find(({ id }) => id === stateId) ?? {};
    const parsedState = {
      id: stateId,
      name: stateName,
      initials: stateName,
    };

    const updatedStatesObject = {
      linkedin: getInputStateValue(linkedinStates),
      phoneNumber: getInputStateValue(phoneNumberStates),
      profession: getInputStateValue(professionStates),
      birthMonth: parsedBirthMonth,
      birthYear: parsedBirthYear,
      birthDate: parsedBirthDate,
      photo: getInputStateValue(photoStates),
      state: parsedState,
      city: parsedCity,
    };

    return updatedStatesObject as PersonalInformationStepData;
  }, [
    birthMonthStates,
    birthYearStates,
    cities,
    cityStates,
    linkedinStates,
    phoneNumberStates,
    photoStates,
    professionStates,
    stateStates,
    states,
  ]);

  useEffect(() => {
    if (!errors) return;

    const stepStates = [
      birthMonthStates,
      birthYearStates,
      cityStates,
      linkedinStates,
      phoneNumberStates,
      photoStates,
      professionStates,
      stateStates,
    ];
    handleFormError(errors, stepStates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    if (!setStepDataFunction) return;

    const updatedData = getUpdatedStepData();
    setStepDataFunction(updatedData);
  }, [getUpdatedStepData, setStepDataFunction]);

  useEffect(() => {
    findAllStates().then(findedStates => {
      setStates(
        findedStates.map(findedState =>
          convertLocationToOptionTypeBase(findedState, true),
        ),
      );
      setIsLoadingStates(false);
    });
  }, []);

  useEffect(() => {
    const stateId = Number(stateStates.mainState.value as string);
    if (stateId === 0) {
      cityStates.mainState.setFunction('');
      return;
    }

    setIsLoadingCities(true);
    findAllCitiesByStateId(stateId).then(findedCities => {
      setCities(
        findedCities.map(findedCity =>
          convertLocationToOptionTypeBase(findedCity),
        ),
      );

      if (!city) cityStates.mainState.setFunction('');
      cityStates.errorMessageState.setFunction('');
      setIsLoadingCities(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cityStates.errorMessageState.setFunction,
    cityStates.mainState.setFunction,
    stateStates.mainState.value,
  ]);

  const handleImageContainerButtonClick = useCallback(() => {
    if (!photoStates.mainState.value) photoInputRef.current?.click();
    else photoStates.mainState.setFunction('');
  }, [photoStates.mainState]);

  return (
    <FormWithStepsContainer>
      <TitleContainer>
        <StepTitle>Informações pessoais</StepTitle>
        <StepSubTitle>
          Começamos com informações que identifiquem quem você é, qual a sua
          profissão e os canais de comunicação
        </StepSubTitle>
      </TitleContainer>

      <InputsContainer>
        <ImageInputContainer>
          <ImageInput
            minimumImageDimensions={{ width: 100, height: 100 }}
            states={photoStates}
            alternativeRef={photoInputRef}
          />

          <ImageDescriptionContainer>
            <p id="description-title">
              Foto <span>(Opcional)</span>
            </p>

            {!isMobileScreen && (
              <>
                <span id="description-content">
                  Escolha uma imagem quadrada com uma cor de fundo sólida de
                  pelo menos 100x100px
                </span>
                <SecondaryButton
                  style={{ width: 'fit-content', height: 48 }}
                  onClick={handleImageContainerButtonClick}
                >
                  {!photoStates.mainState.value ? 'Adicionar foto' : 'Remover'}
                </SecondaryButton>
              </>
            )}
          </ImageDescriptionContainer>
        </ImageInputContainer>

        <InputErrorMessageStyle className="error-message">
          {photoStates.errorMessageState.value}
        </InputErrorMessageStyle>

        <Input
          name="Primeiro nome"
          placeholder={user?.name}
          states={emptyReactInputState}
          style={formStepInputStyles}
          disabled
        />

        <Input
          name="Sobrenome"
          placeholder={user?.lastName}
          states={emptyReactInputState}
          style={formStepInputStyles}
          disabled
        />

        <MultiInputContainer>
          <Select
            name="Data de nascimento"
            placeholder="mês"
            states={birthMonthStates}
            selectOptions={BRAZILIAN_MONTHS_OPTIONS}
            style={{
              ...formStepInputStyles,
              width: isMobileScreen ? '43.5vw' : 238,
            }}
            startWithoutValue
          />

          <Select
            placeholder="ano"
            states={birthYearStates}
            selectOptions={BIRTH_YEAR_OPTIONS}
            style={{
              ...formStepInputStyles,
              width: isMobileScreen ? '43.5vw' : 238,
            }}
            startWithoutValue
          />
        </MultiInputContainer>

        <Input
          name="Profissão"
          placeholder="inserir profissão"
          isOptional
          states={professionStates}
          style={formStepInputStyles}
        />

        <MultiInputContainer>
          <Select
            name="Endereço onde reside"
            placeholder="selecionar"
            states={stateStates}
            disabled={isLoadingStates}
            selectOptions={states}
            style={{
              ...formStepInputStyles,
              width: isMobileScreen ? '24vw' : 167,
            }}
            startWithoutValue
          />

          <Select
            placeholder="selecionar"
            disabled={isLoadingCities}
            states={cityStates}
            selectOptions={cities}
            loadingIndicatorDisabled
            style={{
              ...formStepInputStyles,
              width: isMobileScreen ? '63vw' : 309,
            }}
            startWithoutValue
          />
        </MultiInputContainer>

        <Input
          name="Telefone/WhatsApp"
          placeholder="(00)9.0000-0000"
          states={phoneNumberStates}
          style={formStepInputStyles}
          maxLength={15}
          onBlur={() =>
            phoneNumberStates.mainState.setFunction(
              mask(phoneNumberStates.mainState.value as string, phonePattern),
            )
          }
        />

        <Input
          name="Perfil LinkedIn"
          placeholder="https://"
          isOptional
          states={linkedinStates}
          style={formStepInputStyles}
          onBlur={() =>
            linkedinStates.mainState.setFunction(
              parseUrl(linkedinStates.mainState.value as string),
            )
          }
        />
      </InputsContainer>
    </FormWithStepsContainer>
  );
};
