import * as yup from 'yup';
import {
  yupRequiredStringField,
  yupRequiredNumberField,
} from 'utils/validation';

import { PresentationStepData } from '.';

export const validatePresentationData = async (
  presentationData: PresentationStepData,
): Promise<void> => {
  const schema = yup.object().shape({
    logo: yup.lazy(value =>
      (typeof value === 'string' ? yup.string() : yup.mixed()).required(
        'Logo obrigatória',
      ),
    ),
    name: yupRequiredStringField,
    cnpj: yupRequiredStringField.length(18, 'CNPJ inválido'),
    foundationYear: yupRequiredNumberField.test(
      'length',
      'O ano de fundação precisa conter 4 dígitos',
      value => value?.toString().length === 4,
    ),
    employeesQuantity: yupRequiredStringField,
    about: yupRequiredStringField.min(100, 'Mínimo de 100 caracteres'),
  });

  await schema.validate(presentationData, { abortEarly: false });
};
