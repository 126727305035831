import in6Logo from 'assets/img/in6Logo.svg';

import { Header } from 'components/Header';
import { routeBar } from 'routes/routesAddresses';
import { Question } from './Question';
import { Container, QuestionsContainer, TopContainer } from './styles';

export interface IQuestion {
  title: string;
  answer: string;
}

const QUESTIONS_CONTENT: IQuestion[] = [
  {
    title: 'O que é a IN6?',
    answer:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum cursus pulvinar cursus. Suspendisse cursus dictum dolor nec pellentesque. In hac habitasse platea dictumst. Duis eu dictum purus. Integer dapibus arcu at dolor suscipit, et fermentum mi tempus. Proin a metus pretium, iaculis tellus a, commodo metus. Maecenas consectetur facilisis ex vel luctus. Sed gravida vitae tellus consequat malesuada. Nam ornare felis in lorem viverra, sed sollicitudin libero vulputate.',
  },
  {
    title: 'Como usar a plataforma IN6 Empresas?',
    answer:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum cursus pulvinar cursus. Suspendisse cursus dictum dolor nec pellentesque. In hac habitasse platea dictumst. Duis eu dictum purus. Integer dapibus arcu at dolor suscipit, et fermentum mi tempus. Proin a metus pretium, iaculis tellus a, commodo metus. Maecenas consectetur facilisis ex vel luctus. Sed gravida vitae tellus consequat malesuada. Nam ornare felis in lorem viverra, sed sollicitudin libero vulputate.',
  },
  {
    title: 'Quanto custa para usar?',
    answer:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum cursus pulvinar cursus. Suspendisse cursus dictum dolor nec pellentesque. In hac habitasse platea dictumst. Duis eu dictum purus. Integer dapibus arcu at dolor suscipit, et fermentum mi tempus. Proin a metus pretium, iaculis tellus a, commodo metus. Maecenas consectetur facilisis ex vel luctus. Sed gravida vitae tellus consequat malesuada. Nam ornare felis in lorem viverra, sed sollicitudin libero vulputate.',
  },
  {
    title: 'Quantas vagas posso criar?',
    answer:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum cursus pulvinar cursus. Suspendisse cursus dictum dolor nec pellentesque. In hac habitasse platea dictumst. Duis eu dictum purus. Integer dapibus arcu at dolor suscipit, et fermentum mi tempus. Proin a metus pretium, iaculis tellus a, commodo metus. Maecenas consectetur facilisis ex vel luctus. Sed gravida vitae tellus consequat malesuada. Nam ornare felis in lorem viverra, sed sollicitudin libero vulputate.',
  },
  {
    title: 'Como receberei os currículos?',
    answer:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum cursus pulvinar cursus. Suspendisse cursus dictum dolor nec pellentesque. In hac habitasse platea dictumst. Duis eu dictum purus. Integer dapibus arcu at dolor suscipit, et fermentum mi tempus. Proin a metus pretium, iaculis tellus a, commodo metus. Maecenas consectetur facilisis ex vel luctus. Sed gravida vitae tellus consequat malesuada. Nam ornare felis in lorem viverra, sed sollicitudin libero vulputate.',
  },
  {
    title: 'O que é o período de inscrição?',
    answer:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum cursus pulvinar cursus. Suspendisse cursus dictum dolor nec pellentesque. In hac habitasse platea dictumst. Duis eu dictum purus. Integer dapibus arcu at dolor suscipit, et fermentum mi tempus. Proin a metus pretium, iaculis tellus a, commodo metus. Maecenas consectetur facilisis ex vel luctus. Sed gravida vitae tellus consequat malesuada. Nam ornare felis in lorem viverra, sed sollicitudin libero vulputate.',
  },
  {
    title: 'Como irei visualizar os currículos recebidos?',
    answer:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum cursus pulvinar cursus. Suspendisse cursus dictum dolor nec pellentesque. In hac habitasse platea dictumst. Duis eu dictum purus. Integer dapibus arcu at dolor suscipit, et fermentum mi tempus. Proin a metus pretium, iaculis tellus a, commodo metus. Maecenas consectetur facilisis ex vel luctus. Sed gravida vitae tellus consequat malesuada. Nam ornare felis in lorem viverra, sed sollicitudin libero vulputate.',
  },
];

export const HelpCenter: React.FC = () => {
  return (
    <Container>
      <Header logo={in6Logo} redirectRoute={routeBar} />

      <TopContainer>
        <h1>Central de ajuda</h1>
        <p>Está com dúvidas? Aqui você encontra suas respostas.</p>
      </TopContainer>

      <QuestionsContainer>
        {QUESTIONS_CONTENT.map(question => (
          <Question key={question.title} question={question} />
        ))}
      </QuestionsContainer>
    </Container>
  );
};
