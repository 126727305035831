interface IConvertedText {
  updatedText: string;
  selectionExtraUpdate: number;
}

const ENTER_CHAR_CODE = 10;
const CIRCLE_SYMBOL_CHAR_CODE = 8226;

type TypeOfChange = 'none' | 'toList' | 'newListItem';

const getParsedTextAndSelectionExtraUpdateWhenBackspacePressed = (
  text: string,
  inputedCharCode: number,
  inputPosition: number,
): IConvertedText => {
  if (inputedCharCode !== CIRCLE_SYMBOL_CHAR_CODE) {
    return { updatedText: text, selectionExtraUpdate: 0 };
  }

  const initialText = text.substring(0, inputPosition);
  const endText = text.substring(inputPosition + 1, text.length);
  return { updatedText: initialText + endText, selectionExtraUpdate: -1 };
};

const verifyIfSubTextIsList = (
  subText: string,
  withoutInitialEnter: boolean,
): boolean => {
  if (withoutInitialEnter && subText === '- ') return true;

  const startsWithEnter = subText.charCodeAt(0) === ENTER_CHAR_CODE;
  if (startsWithEnter && subText.substring(1, 3) === '- ') return true;

  return false;
};

const hasListItemBeforeInputedEnter = (
  text: string,
  inputPosition: number,
): boolean => {
  let ind = inputPosition;
  while (!!text.charCodeAt(ind) && text.charCodeAt(ind) !== ENTER_CHAR_CODE) {
    ind--;
  }

  return text.charCodeAt(ind + 1) === CIRCLE_SYMBOL_CHAR_CODE;
};

const getTypeOfChangeThatTextWillNeed = (
  text: string,
  listEndPoint: number,
  inputedCharCode: number,
): TypeOfChange => {
  const subTextToVerify = text.substring(listEndPoint - 3, listEndPoint);
  const subTextIsList = verifyIfSubTextIsList(
    subTextToVerify,
    listEndPoint === 2,
  );

  const needsToAddNewListItem =
    inputedCharCode === ENTER_CHAR_CODE &&
    hasListItemBeforeInputedEnter(text, listEndPoint - 2);

  if (!subTextIsList && !needsToAddNewListItem) return 'none';
  return needsToAddNewListItem ? 'newListItem' : 'toList';
};

const getParsedTextAndSelectionExtraUpdateBasedOnTypeOfChange = (
  text: string,
  typeOfChangeNeeded: TypeOfChange,
  listEndPoint: number,
): IConvertedText => {
  const needsToAddNewListItem = typeOfChangeNeeded === 'newListItem';

  const endPointOfInitialText = listEndPoint - (needsToAddNewListItem ? 1 : 2);
  const initialText = text.substring(0, endPointOfInitialText);
  const listContent = `${needsToAddNewListItem ? '\n' : ''}• `;
  const endText = text.substring(listEndPoint, text.length);

  return {
    updatedText: `${initialText}${listContent}${endText}`,
    selectionExtraUpdate: needsToAddNewListItem ? 2 : 0,
  };
};

export const convertTextWithHyphenListToCircleList = (
  text: string,
  listEndPoint: number,
  backspacePressed: boolean,
): IConvertedText => {
  const inputedCharCode = text.charCodeAt(listEndPoint - 1);

  if (backspacePressed) {
    return getParsedTextAndSelectionExtraUpdateWhenBackspacePressed(
      text,
      inputedCharCode,
      listEndPoint - 1,
    );
  }

  const typeOfChangeNeeded = getTypeOfChangeThatTextWillNeed(
    text,
    listEndPoint,
    inputedCharCode,
  );
  if (typeOfChangeNeeded === 'none') {
    return {
      updatedText: text,
      selectionExtraUpdate: 0,
    };
  }

  return getParsedTextAndSelectionExtraUpdateBasedOnTypeOfChange(
    text,
    typeOfChangeNeeded,
    listEndPoint,
  );
};
