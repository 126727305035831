import { TestPunctuationHighlight } from 'components/TestPunctuationHighlight';
import { getResultScreenPropertiesBasedOnTestResult } from './getResultScreenPropertiesBasedOnTestResult';

import { Container } from './styles';

export const LogicalReasoningResultScreen: React.FC<{
  testResult: string;
}> = ({ testResult }) => {
  const { description } =
    getResultScreenPropertiesBasedOnTestResult(testResult);

  return (
    <Container>
      <TestPunctuationHighlight punctuation={Number(testResult)} />
      {description}
    </Container>
  );
};
