import { localStorageKeys } from 'constants/localStorageKeys';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { EntityWithNameDTO } from 'types/EntityWithNameDTO';

export interface VacancyStepWithColor extends EntityWithNameDTO {
  color: string;
}

interface VacancyCustomSteps {
  vacancyId: number;
  customSteps: VacancyStepWithColor[];
}

interface IVacanciesCustomStepContext {
  vacanciesCustomSteps: VacancyCustomSteps[];
  changeVacancyCustomStepPosition: (
    idOfVacancyToChange: number,
    fromIndex: number,
    toIndex: number,
  ) => void;
  deleteCustomStepOfVacancy: (
    vacancyId: number,
    vacancyCustomStepId: number,
  ) => void;
  saveCustomStepsOfVacancy: (vacancyCustomSteps: VacancyCustomSteps) => void;
  removeCustomStepsOfVacancy: (idOfVacancyToRemove: number) => void;
  clearAllVacanciesCustomSteps: () => void;
}

type CustomStepsLocalStatus = 'isUpdated' | 'outOfDate' | 'isNotPresent';

export const STEP_COLORS = [
  '#FFE2DD',
  '#F5E0E9',
  '#E8DEEE',
  '#D3E5EF',
  '#DBEDDB',
  '#FDECC8',
  '#FADEC9',
  '#EEE0DA',
];

const vacanciesCustomStepsContext = createContext<IVacanciesCustomStepContext>(
  {} as IVacanciesCustomStepContext,
);

export const VacanciesCustomStepsContext: React.FC = ({ children }) => {
  const [vacanciesCustomSteps, setVacanciesCustomSteps] = useState<
    VacancyCustomSteps[]
  >([]);

  useEffect(() => {
    const localStorageVacanciesCustomSteps = localStorage.getItem(
      localStorageKeys.vacanciesCustomSteps,
    );
    if (!localStorageVacanciesCustomSteps) return;

    const parsedLocalStorageVacanciesCustomSteps = JSON.parse(
      localStorageVacanciesCustomSteps,
    ) as VacancyCustomSteps[];
    setVacanciesCustomSteps(parsedLocalStorageVacanciesCustomSteps);
  }, []);

  const updateLocalStorage = (updatedCustomSteps: VacancyCustomSteps[]) => {
    localStorage.setItem(
      localStorageKeys.vacanciesCustomSteps,
      JSON.stringify(updatedCustomSteps),
    );
  };

  const changeVacancyCustomStepPosition = useCallback(
    (idOfVacancyToChange: number, fromIndex: number, toIndex: number) =>
      setVacanciesCustomSteps(previousCustomSteps => {
        const findedVacancyCustomSteps = previousCustomSteps.find(
          ({ vacancyId }) => vacancyId === idOfVacancyToChange,
        );
        if (!findedVacancyCustomSteps) return previousCustomSteps;

        const { customSteps } = findedVacancyCustomSteps;
        const [customStepToBeMoved] = customSteps.splice(fromIndex, 1);
        customSteps.splice(toIndex, 0, customStepToBeMoved);

        const updatedVacanciesCustomSteps = previousCustomSteps.map(
          previousCustomStep =>
            previousCustomStep.vacancyId === idOfVacancyToChange
              ? { vacancyId: idOfVacancyToChange, customSteps }
              : previousCustomStep,
        );
        updateLocalStorage(updatedVacanciesCustomSteps);
        return updatedVacanciesCustomSteps;
      }),
    [],
  );

  const vacancyCustomStepsAreOutDated = useCallback(
    (
      { customSteps: customStepsToVerify }: VacancyCustomSteps,
      { customSteps: customStepsToCompare }: VacancyCustomSteps,
    ): boolean => {
      const hasDifferentLengths =
        customStepsToVerify.length !== customStepsToCompare.length;
      if (hasDifferentLengths) return true;

      const isOutDated = !customStepsToCompare.every(
        ({ id: searchId, name: searchName }) =>
          customStepsToVerify.findIndex(
            ({ id, name }) => id === searchId && name === searchName,
          ) !== -1,
      );

      return isOutDated;
    },
    [],
  );

  const getStatusOfVacancyCustomSteps = useCallback(
    (vacancyCustomSteps: VacancyCustomSteps): CustomStepsLocalStatus => {
      const findedLocalVacancyCustomSteps = vacanciesCustomSteps.find(
        ({ vacancyId }) => vacancyId === vacancyCustomSteps.vacancyId,
      );
      if (findedLocalVacancyCustomSteps === undefined) return 'isNotPresent';

      const localCustomStepsAreOutDated = vacancyCustomStepsAreOutDated(
        findedLocalVacancyCustomSteps,
        vacancyCustomSteps,
      );

      return localCustomStepsAreOutDated ? 'outOfDate' : 'isUpdated';
    },
    [vacanciesCustomSteps, vacancyCustomStepsAreOutDated],
  );

  const saveCustomStepsOfVacancy = useCallback(
    (vacancyCustomSteps: VacancyCustomSteps) => {
      const vacancyCustomStepsStatus =
        getStatusOfVacancyCustomSteps(vacancyCustomSteps);
      if (vacancyCustomStepsStatus === 'isUpdated') return;

      setVacanciesCustomSteps(previousCustomSteps => {
        if (vacancyCustomStepsStatus === 'isNotPresent') {
          updateLocalStorage([...previousCustomSteps, vacancyCustomSteps]);
          return [...previousCustomSteps, vacancyCustomSteps];
        }

        const parsedVacanciesSteps = previousCustomSteps.map(
          iterationVacancySteps =>
            iterationVacancySteps.vacancyId === vacancyCustomSteps.vacancyId
              ? vacancyCustomSteps
              : iterationVacancySteps,
        );

        updateLocalStorage(parsedVacanciesSteps);
        return parsedVacanciesSteps;
      });
    },
    [getStatusOfVacancyCustomSteps],
  );

  const removeCustomStepsOfVacancy = useCallback(
    (idOfVacancyToRemove: number) => {
      setVacanciesCustomSteps(previousCustomSteps => {
        const parsedCustomSteps = previousCustomSteps.filter(
          ({ vacancyId }) => vacancyId !== idOfVacancyToRemove,
        );

        updateLocalStorage(parsedCustomSteps);
        return parsedCustomSteps;
      });
    },
    [],
  );

  const deleteCustomStepOfVacancy = useCallback(
    (vacancyId: number, vacancyCustomStepId: number) => {
      setVacanciesCustomSteps(previousCustomSteps => {
        const parsedVacanciesSteps = previousCustomSteps.map(
          iterationVacancySteps => {
            if (iterationVacancySteps.vacancyId !== vacancyId) {
              return iterationVacancySteps;
            }

            const parsedCustomSteps = iterationVacancySteps.customSteps.filter(
              ({ id }) => id !== vacancyCustomStepId,
            );

            return { vacancyId, customSteps: parsedCustomSteps };
          },
        );

        updateLocalStorage(parsedVacanciesSteps);
        return parsedVacanciesSteps;
      });
    },
    [],
  );

  const clearAllVacanciesCustomSteps = useCallback(() => {
    localStorage.removeItem(localStorageKeys.vacanciesCustomSteps);
    setVacanciesCustomSteps([]);
  }, []);

  return (
    <vacanciesCustomStepsContext.Provider
      value={{
        vacanciesCustomSteps,
        changeVacancyCustomStepPosition,
        saveCustomStepsOfVacancy,
        deleteCustomStepOfVacancy,
        removeCustomStepsOfVacancy,
        clearAllVacanciesCustomSteps,
      }}
    >
      {children}
    </vacanciesCustomStepsContext.Provider>
  );
};

export const useVacanciesCustomSteps = (): IVacanciesCustomStepContext =>
  useContext(vacanciesCustomStepsContext);
