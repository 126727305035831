import { SecondaryButton } from 'components/Buttons';
import { Container, Header, MiddleContainer } from './styles';

interface TimeExpiredModalProps {
  redirectToResultsPage: () => void;
}

export const TimeExpiredModal: React.FC<TimeExpiredModalProps> = ({
  redirectToResultsPage,
}) => {
  return (
    <Container>
      <Header>Tempo finalizado</Header>

      <MiddleContainer>
        <p>
          O tempo disponível para realizar o teste acabou e não será possível
          responder as questões que faltam.
        </p>

        <SecondaryButton type="button" onClick={redirectToResultsPage}>
          Ver resultado
        </SecondaryButton>
      </MiddleContainer>
    </Container>
  );
};
