import * as yup from 'yup';
import { yupRequiredStringField } from 'utils/validation';

import { yupObjectWithId } from 'utils/validation/yupObjectWithId';
import { PersonalInformationStepData } from '.';

export const validatePersonalInformationData = async (
  personalInformationData: PersonalInformationStepData,
): Promise<void> => {
  const schema = yup.object().shape({
    birthMonth: yupRequiredStringField,
    birthYear: yupRequiredStringField,
    profession: yup.string().optional(),
    state: yupObjectWithId,
    city: yupObjectWithId,
    phoneNumber: yupRequiredStringField.length(15, 'Número incompleto'),
    linkedin: yup.string().url('URL inválida').optional(),
    photo: yup.mixed(),
  });

  await schema.validate(personalInformationData, { abortEarly: false });
};
