import deleteIcon from 'assets/img/delete.svg';

import { HighRed } from 'assets/colors/palette';
import { OptionButton, SecondaryButton } from 'components/Buttons';
import { Input } from 'components/Input';
import { Loader } from 'components/Loader';
import { ERROR_MODAL_CONFIGURATION, useAlertModal } from 'hooks/alertModal';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useInputStates } from 'utils/customHooks';
import { routeDashboard } from 'routes/routesAddresses';
import {
  ButtonsContainer,
  Container,
  CreationContainer,
  TopContainer,
} from './styles';

interface DeleteVacancyModalProps {
  handleCloseModal: () => void;
  handleDeleteVacancy: (password: string) => Promise<void>;
}

export const DeleteVacancyModal: React.FC<DeleteVacancyModalProps> = ({
  handleCloseModal,
  handleDeleteVacancy,
}) => {
  const history = useHistory();
  const { showModal } = useAlertModal();

  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const passwordStates = useInputStates('password');

  const cleanPasswordAndCloseModal = useCallback(() => {
    passwordStates.mainState.setFunction('');
    handleCloseModal();
  }, [handleCloseModal, passwordStates.mainState]);

  const handleConfirmDelete = useCallback(async () => {
    setIsLoadingDelete(true);
    const {
      mainState: { value: password },
    } = passwordStates;

    try {
      await handleDeleteVacancy(password as string);

      showModal({
        message: `A vaga foi excluída com sucesso`,
        backgroundColor: HighRed,
        icon: deleteIcon,
        forceIconWhiteColor: true,
      });
      history.push(routeDashboard);
    } catch {
      setIsLoadingDelete(false);
      cleanPasswordAndCloseModal();

      showModal({
        ...ERROR_MODAL_CONFIGURATION,
        message: 'Erro ao excluir vaga, por favor, tente novamente mais tarde',
      });
    }
  }, [
    cleanPasswordAndCloseModal,
    handleDeleteVacancy,
    history,
    passwordStates,
    showModal,
  ]);

  return (
    <Container>
      <TopContainer>
        <span>Excluir vaga</span>
      </TopContainer>

      {isLoadingDelete ? (
        <Loader
          style={{
            minHeight: 0,
            padding: 32,
            background: 'transparent',
            marginTop: 100,
          }}
        />
      ) : (
        <CreationContainer>
          <p>
            Ao confirmar a exclusão da vaga não será possível desfazer esta
            ação.
          </p>

          <Input
            style={{ width: 398, height: 48 }}
            placeholder="inserir senha"
            type="password"
            states={passwordStates}
            notScrollToInput
            notHasError
          />

          <ButtonsContainer>
            <OptionButton type="button" onClick={cleanPasswordAndCloseModal}>
              Cancelar
            </OptionButton>

            <SecondaryButton
              type="button"
              onClick={handleConfirmDelete}
              disabled={(passwordStates.mainState.value as string).length === 0}
            >
              Excluir vaga
            </SecondaryButton>
          </ButtonsContainer>
        </CreationContainer>
      )}
    </Container>
  );
};
