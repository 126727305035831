import React, { createContext, useCallback, useContext, useState } from 'react';
import { ICandidateResponse } from 'types/candidate/ICandidateResponse';

type CandidateWithoutUser = Omit<ICandidateResponse, 'user'>;

interface ICandidateContext {
  candidate?: CandidateWithoutUser;
  updateCandidateState: (candidate: CandidateWithoutUser) => void;
  clearCandidateState: () => void;
}

const candidateContext = createContext<ICandidateContext>(
  {} as ICandidateContext,
);

export const CandidateContext: React.FC = ({ children }) => {
  const [candidate, setCandidate] = useState<CandidateWithoutUser | undefined>(
    undefined,
  );

  const updateCandidateState = useCallback(
    (updatedCandidate: CandidateWithoutUser) => setCandidate(updatedCandidate),
    [],
  );
  const clearCandidateState = useCallback(() => setCandidate(undefined), []);

  return (
    <candidateContext.Provider
      value={{ candidate, updateCandidateState, clearCandidateState }}
    >
      {children}
    </candidateContext.Provider>
  );
};

export const useCandidate = (): ICandidateContext =>
  useContext(candidateContext);
