import accountConfigIconWhite from 'assets/img/accountConfigIconWhite.svg';
import companyIconBlack from 'assets/img/companyIconBlack.svg';
import exitIcon from 'assets/img/exitIcon.svg';
import helpCenterIconWhite from 'assets/img/helpCenterIconWhite.svg';
import simpleArrowRight from 'assets/img/simpleArrowRight.svg';

import { useAuth } from 'hooks/auth';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { DashboardHeaderOptionsBox } from 'components/DashboardHeader/DashboardHeaderOptionsBox/styles';
import { routeEditStartup } from 'routes/startupsRoutes/startupsRoutesAddresses';
import { useStartup } from 'hooks/startup';
import {
  routeAccountConfiguration,
  routeHelpCenter,
  routePrivacyPolicy,
  routeTermsOfUse,
} from 'routes/routesAddresses';
import { UserIcon } from 'components/UserIcon';
import { baseURLFrontend } from 'services/baseUrl';
import { Container, ImageContainer, OpenOptionsButton } from './styles';

const ACTUAL_YEAR = new Date().getFullYear();

export const StartupButtons: React.FC = () => {
  const history = useHistory();

  const { signOut } = useAuth();
  const { startup } = useStartup();

  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  if (startup === undefined) return null;

  const { name: startupName, logo } = startup;
  return (
    <Container>
      <OpenOptionsButton
        type="button"
        isOptionsVisible={isOptionsVisible}
        onClick={() => setIsOptionsVisible(value => !value)}
      >
        <img src={simpleArrowRight} alt="Seta para baixo" />
      </OpenOptionsButton>

      <div className="company-data">
        <UserIcon
          userName={startupName}
          image={logo}
          style={{ backgroundColor: 'transparent' }}
        />
        <span id="company-name">{startupName}</span>
      </div>

      <DashboardHeaderOptionsBox
        isVisible={isOptionsVisible}
        onMouseLeave={() => setIsOptionsVisible(false)}
      >
        <button type="button" onClick={() => history.push(routeEditStartup)}>
          <ImageContainer>
            <img src={companyIconBlack} alt="Empresa" />
          </ImageContainer>
          Editar perfil
        </button>

        <button
          type="button"
          onClick={() => history.push(routeAccountConfiguration)}
        >
          <ImageContainer>
            <img src={accountConfigIconWhite} alt="Configurações da conta" />
          </ImageContainer>
          Configurações da conta
        </button>

        <button type="button" onClick={() => history.push(routeHelpCenter)}>
          <ImageContainer>
            <img src={helpCenterIconWhite} alt="Central de ajuda" />
          </ImageContainer>
          Central de ajuda
        </button>

        <button type="button" onClick={signOut}>
          <ImageContainer>
            <img src={exitIcon} alt="Sair" />
          </ImageContainer>
          Sair
        </button>

        <div id="about-container">
          <span>
            <a
              href={baseURLFrontend + routePrivacyPolicy}
              target="_blank"
              rel="noreferrer"
            >
              Privacidade
            </a>
            <a
              href={baseURLFrontend + routeTermsOfUse}
              target="_blank"
              rel="noreferrer"
            >
              Termos de uso
            </a>
            <a href=".">Sobre IN6 © {ACTUAL_YEAR}</a>
          </span>
        </div>
      </DashboardHeaderOptionsBox>
    </Container>
  );
};
