import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  align-items: center;

  .options-column {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 36px;

    button {
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        filter: brightness(0%);
      }
    }
  }
`;
