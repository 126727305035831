import { LevelOfSchooling } from 'types';
import { OptionTypeBaseWithEnumValue } from './OptionTypeBaseWithEnumValue';

export const levelOfSchoolingOptions: OptionTypeBaseWithEnumValue<LevelOfSchooling>[] =
  [
    {
      id: 1,
      label: 'Analfabeto',
      enumValue: LevelOfSchooling.ILLITERATE,
    },
    {
      id: 2,
      label: 'Fundamental Incompleto',
      enumValue: LevelOfSchooling.INCOMPLETE_FUNDAMENTAL,
    },
    {
      id: 3,
      label: 'Fundamental Completo',
      enumValue: LevelOfSchooling.COMPLETE_FUNDAMENTAL,
    },
    {
      id: 4,
      label: 'Médio Incompleto',
      enumValue: LevelOfSchooling.INCOMPLETE_HIGH_SCHOOL,
    },
    {
      id: 5,
      label: 'Médio Completo',
      enumValue: LevelOfSchooling.COMPLETE_HIGH_SCHOOL,
    },
    {
      id: 6,
      label: 'Superior Incompleto',
      enumValue: LevelOfSchooling.INCOMPLETE_HIGHER_EDUCATION,
    },
    {
      id: 7,
      label: 'Superior Completo',
      enumValue: LevelOfSchooling.COMPLETE_HIGHER_EDUCATION,
    },
    {
      id: 8,
      label: 'Pós Gradução',
      enumValue: LevelOfSchooling.POSTGRADUATE,
    },
    {
      id: 9,
      label: 'Mestrado',
      enumValue: LevelOfSchooling.MASTER_DEGREE,
    },
    {
      id: 10,
      label: 'Doutorado',
      enumValue: LevelOfSchooling.DOCTORATE_DEGREE,
    },
  ];
