import { TextGradientPrimaryBackground } from 'assets/colors/background';
import {
  BackgroundColor,
  GrayBackground,
  HighEmphasis,
  MediumEmphasis,
  OutlineColor,
  PrimaryColor,
} from 'assets/colors/palette';
import {
  Bold32Styles,
  Medium14Styles,
  Medium16Styles,
  Regular14Styles,
} from 'assets/fonts/fontStyles';
import styled, { css } from 'styled-components';

interface StartupDataLineProps {
  highlightedContent?: boolean;
}

interface GalleryPassButtonProps {
  side: 'left' | 'right';
}

interface AboutTextProps {
  isShowingExpandedText: boolean;
}

export const Container = styled.div`
  min-height: 100vh;
`;

export const StartupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 22px;
  padding-top: 60px;

  #startup-name {
    font-weight: 700;
    font-size: 48px;
    line-height: 48px;
    letter-spacing: 1px;
    color: ${HighEmphasis};
  }

  #open-vacancies-quantity {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 32px;

    border: 1px solid ${OutlineColor};
    border-radius: 24px;

    ${Medium16Styles}
    ${TextGradientPrimaryBackground}
  }

  & > img {
    width: 120px;
    height: 120px;

    border-radius: 50%;
  }
`;

export const AboutContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  min-width: 1248px;
  margin: 40px auto 64px;
`;

export const AboutText = styled.div<AboutTextProps>`
  ${Regular14Styles}
  line-height: 28px;
  color: ${HighEmphasis};

  min-width: 775px;
  max-width: 775px;

  display: flex;
  flex-direction: column;
  position: relative;
  gap: 12px;

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: ${({ isShowingExpandedText }) =>
      isShowingExpandedText ? 'none' : 10};
    -webkit-box-orient: vertical;

    word-wrap: break-word;
    white-space: pre-line;

    z-index: 3;
  }

  &:after {
    content: '';
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 110px;
    height: 292px;
    background-color: ${BackgroundColor};
  }

  button {
    ${Medium16Styles}
    ${TextGradientPrimaryBackground}

    z-index: 1;
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 6px;
  }
`;

export const StartupData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 10px;

  width: 357px;
`;

export const StartupDataLine = styled.div<StartupDataLineProps>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  ${Regular14Styles}
  line-height: 24px;

  .line-title {
    color: ${MediumEmphasis};
  }

  .line-content {
    font-weight: 700;
    text-decoration: none;
    text-align: end;
    color: ${({ highlightedContent }) =>
      highlightedContent ? PrimaryColor : HighEmphasis};
  }
`;

export const SocialNetworksContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;
  margin-top: 6px;
`;

export const SocialNetwork = styled.a`
  width: 48px;
  height: 48px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${BackgroundColor};
  border: 1px solid ${OutlineColor};
  border-radius: 100px;
  transition: 0.2s;

  img {
    filter: brightness(10%);
  }

  &:hover {
    background: rgba(220, 220, 220, 0.2);
  }
`;

export const GalleryImages = styled.div`
  position: relative;
  max-width: 100%;
  padding-bottom: 68px;

  #images-container {
    display: flex;
    scroll-behavior: smooth;
    gap: 26px;

    overflow-x: hidden;

    img {
      min-width: 600px;
      max-width: 600px;

      min-height: 400px;
      max-height: 400px;
    }
  }
`;

export const GalleryPassButton = styled.button<GalleryPassButtonProps>`
  position: absolute;
  top: 178px;

  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 84px;
  max-width: 84px;

  min-height: 44px;
  max-height: 44px;

  background-color: ${BackgroundColor};
  border-radius: 100px;

  img {
    filter: brightness(10%);
    width: 20px;
    height: 20px;
  }

  ${({ side }) =>
    side === 'left'
      ? css`
          left: 20px;
          img {
            transform: rotate(180deg);
          }
        `
      : css`
          right: 20px;
        `}
`;

export const VacanciesContainer = styled.div`
  width: 1248px;

  display: flex;
  flex-direction: column;
  gap: 22px;

  h2 {
    ${Bold32Styles}
    color: ${HighEmphasis};
    margin-bottom: 40px;
  }
`;

export const Vacancy = styled.a`
  width: 100%;
  height: 64px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 26px;

  text-decoration: none;
  color: ${HighEmphasis};
  border: 1px solid ${OutlineColor};
  border-radius: 8px;
  transition: 0.2s;

  &:hover {
    border-color: ${PrimaryColor};
  }

  .vacancy-name {
    font-size: 18px;
    line-height: 18px;
    font-weight: 500;
    letter-spacing: 0.75px;
  }

  .vacancy-informations {
    display: flex;
    align-items: center;
  }

  li {
    ${Regular14Styles}
    line-height: 24px;
    color: ${HighEmphasis};

    list-style: none;
    margin-left: 32px;
    position: relative;

    & + li:after {
      content: '';
      position: absolute;
      width: 2.8px;
      height: 2.8px;
      border-radius: 50%;
      background-color: ${HighEmphasis};
      opacity: 1;
      left: -16px;
      bottom: 40%;
    }
  }
`;

export const StartupCareerFooter = styled.div`
  margin-top: 160px;
  background-color: ${GrayBackground};
  bottom: 0;
  width: 100%;
  height: 68px;

  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${OutlineColor};

  ${Medium14Styles}
  color: ${HighEmphasis};
  line-height: 24px;

  #sub-container {
    width: 1248px;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 12px;
`;

export const RedirectContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;
`;
