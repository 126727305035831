import {
  BackgroundColor,
  HighEmphasis,
  OutlineColor,
} from 'assets/colors/palette';
import {
  Bold32Styles,
  Medium16Styles,
  Regular14Styles,
} from 'assets/fonts/fontStyles';
import { PrimaryButton } from 'components/Buttons';
import styled from 'styled-components';

export const Container = styled.div`
  width: 540px;
  border-radius: 8px;
  background-color: ${BackgroundColor};
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 28px;

  & > h3,
  & > p,
  ${PrimaryButton} {
    width: 500px;
  }

  & > h3 {
    ${Bold32Styles}
    color: ${HighEmphasis};
    font-size: 24px;
    margin-bottom: 10px;
  }

  & > p {
    ${Regular14Styles}
    color: ${HighEmphasis};
    line-height: 24px;
    margin-block: 16px 24px;
  }

  @media (max-width: 1100px) {
    width: 86.5vw;

    & > h3,
    & > p,
    ${PrimaryButton} {
      width: 95%;
    }

    & > p {
      font-size: 12px;
      margin-block: 16px 20px;
    }
  }
`;

export const TopContainer = styled.div`
  width: 100%;
  height: 60px;

  border-bottom: 1px solid ${OutlineColor};
  padding: 6px 18px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  span,
  button {
    ${Medium16Styles}
    color: ${HighEmphasis};
  }

  button {
    display: flex;
    align-items: center;
    background-color: transparent;
    gap: 6px;

    img {
      width: 25px;
      height: 25px;
    }
  }
`;

export const CloseButton = styled.button`
  background-color: transparent;
`;
