import { IFormStepProps } from 'types/IFormStepProps';
import {
  StepSubTitle,
  StepTitle,
  TitleContainer,
} from 'assets/styles/formWithStepsStyles/formWithStepsTitlesStyles';
import { useMemo } from 'react';
import { CandidateResume } from 'components/CandidateResume';
import { useAuth } from 'hooks/auth';
import { useWindowWidth } from 'hooks/windowWidth';
import { Container } from './styles';
import { CandidateAllStepsData } from '..';

export const FinishStep: React.FC<IFormStepProps<CandidateAllStepsData>> = ({
  initialData = {} as CandidateAllStepsData,
}) => {
  const { user } = useAuth();
  const { isMobileScreen } = useWindowWidth();

  const {
    personalInformationStep,
    professionalExperiencesStep: {
      professionalExperiences,
      hasProfessionalExperience,
    },
    academicEducationsStep: { academicEducations },
    certificationsStep: { certifications },
    competenciesStep: { competencies },
    languagesStep: { languages },
    skillsStep: { skills },
  } = initialData;

  const parsedPhoto = useMemo(() => {
    const { photo } = personalInformationStep;
    if (!photo) return null;

    if (typeof photo === 'string') return photo;
    return URL.createObjectURL(photo);
  }, [personalInformationStep]);

  return (
    <Container>
      <TitleContainer>
        <StepTitle>Revisar currículo</StepTitle>
        <StepSubTitle>
          Revise todas as informações do seu currículo antes de se candidatar
          nas vagas. Mas, caso precise, você poderá editar posteriormente
        </StepSubTitle>
      </TitleContainer>

      <CandidateResume
        userData={user!}
        style={{ width: isMobileScreen ? '90vw' : 500 }}
        candidateData={{
          ...personalInformationStep,
          photo: parsedPhoto,
          hasProfessionalExperience,
          professionalExperiences,
          academicEducations,
          certifications,
          competencies,
          languages,
          skills,
        }}
      />
    </Container>
  );
};
