import { ITestProperties, PunctuationObject } from '..';

import { PortugueseInitialScreen } from './PortugueseInitialScreen';
import { PortugueseResultScreen } from './PortugueseResultScreen';
import { PORTUGUESE_QUESTIONS } from './questions';

const generatePunctuationObject = (): PunctuationObject => ({
  correctAnswers: 0,
});

const updatePunctuationObject = (
  punctuationObject: PunctuationObject,
  _: undefined,
  isCorrectAnswer?: boolean,
): PunctuationObject => {
  if (!isCorrectAnswer) return punctuationObject;
  return { correctAnswers: punctuationObject.correctAnswers + 1 };
};

const generateTestResult = (punctuationObject: PunctuationObject): string => {
  const eachQuestionPunctuation = 100 / PORTUGUESE_QUESTIONS.length;
  const totalPunctuation =
    punctuationObject.correctAnswers * eachQuestionPunctuation;

  return String(Math.ceil(totalPunctuation));
};

export const PortugueseProperties: ITestProperties<undefined> = {
  timeInMinutes: 30,
  questions: PORTUGUESE_QUESTIONS,
  generatePunctuationObject,
  updatePunctuationObject,
  generateTestResult,
  InitialScreen: PortugueseInitialScreen,
  ResultScreen: PortugueseResultScreen,
};
