import { MediumEmphasis } from 'assets/colors/palette';
import { Regular14Styles, Regular24Styles } from 'assets/fonts/fontStyles';
import styled from 'styled-components';

export const Container = styled.div`
  width: 532px;
  margin-inline: auto;
  padding-block: 62px 64px;

  @media (max-width: 1100px) {
    width: 90vw;
  }
`;

export const Title = styled.div`
  text-align: center;
  padding-bottom: 48px;

  p {
    ${Regular14Styles}
    margin-bottom: 20px;
  }

  h2 {
    ${Regular24Styles}
  }

  @media (max-width: 1100px) {
    h2 {
      font-size: 20px;
    }
  }
`;

export const Footer = styled.footer`
  ${Regular14Styles}
  line-height: 24px;
  color: ${MediumEmphasis};
  padding-top: 152px;

  span {
    font-weight: 700;
  }

  @media (max-width: 1100px) {
    padding-top: 96px;
    font-size: 12px;
  }
`;
