import deleteIcon from 'assets/img/delete.svg';
import redirectIcon from 'assets/img/redirectIcon.svg';

import { Switch } from 'components/Switch';
import { ERROR_MODAL_CONFIGURATION, useAlertModal } from 'hooks/alertModal';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { toggleIsSubscriberFromUser } from 'services/entitiesServices/adminServices';

import { IStartupResponseForFindAll } from 'types/startup';
import { convertNameToSnakeCase } from 'utils/conversion';
import { convertCityAndStateFromObjectToString } from 'utils/conversion/convertCityAndStateFromObjectToString';
import { Container, SubscriptionStatus } from './styles';

interface StartupRowProps {
  startup: IStartupResponseForFindAll;
  handleShowDeleteUserModal: (userId: number) => void;
}

export const StartupRow: React.FC<StartupRowProps> = ({
  startup: { name: startupName, employer, startupLocations },
  handleShowDeleteUserModal,
}) => {
  const { showModal } = useAlertModal();
  const [isSubscriber, setIsSubscriber] = useState(employer.subscriber);
  const [isLoadingIsSubscriberChange, setIsLoadingIsSubscriberChange] =
    useState(false);

  const handleIsSubscriberSwitchChange = useCallback(
    async (updatedIsSubscriber: boolean) => {
      setIsLoadingIsSubscriberChange(true);

      try {
        await toggleIsSubscriberFromUser(employer.id, updatedIsSubscriber);
        setIsSubscriber(updatedIsSubscriber);
      } catch {
        showModal({
          ...ERROR_MODAL_CONFIGURATION,
          message:
            'Falha ao atualizar usuário, por favor, tente novamente mais tarde',
        });
      } finally {
        setIsLoadingIsSubscriberChange(false);
      }
    },
    [showModal, employer.id],
  );

  return (
    <Container>
      <span className="startups-column">{startupName}</span>
      <span className="employer-column">{`${employer.name} ${employer.lastName}`}</span>
      <span className="location-column">
        {convertCityAndStateFromObjectToString(startupLocations[0], ', ', true)}

        {startupLocations.length > 1 && (
          <small>+{startupLocations.length - 1}</small>
        )}
      </span>
      <span className="email-column">{employer.email}</span>
      <div className="options-column">
        <section>
          <button
            type="button"
            onClick={() => handleShowDeleteUserModal(employer.id)}
          >
            <img src={deleteIcon} alt="Excluir candidato" />
          </button>

          <Link to={`/${convertNameToSnakeCase(startupName)}`} target="_blank">
            <img src={redirectIcon} alt="Visualizar carreira" />
          </Link>
        </section>

        <section>
          <SubscriptionStatus isSubscriber={isSubscriber}>
            {isSubscriber ? 'Assinante' : 'Não assinante'}
          </SubscriptionStatus>
          <Switch
            state={[isSubscriber, setIsSubscriber]}
            onChange={handleIsSubscriberSwitchChange}
            disabled={isLoadingIsSubscriberChange}
          />
        </section>
      </div>
    </Container>
  );
};
