import { IStartupCandidatesFolderBasicData } from 'types/startupCandidatesFolder';
import { IStartupCandidatesFolderExhibitionData } from 'types/startupCandidatesFolder/IStartupCandidatesFolderExhibitionData';
import { IStartupCandidatesFolderRequest } from 'types/startupCandidatesFolder/IStartupCandidatesFolderRequest';
import { IStartupCandidatesFolderResponse } from 'types/startupCandidatesFolder/IStartupCandidatesFolderResponse';
import { api } from '../api';

const servicesPrefix = 'startupCandidatesFolder';

export const createStartupCandidatesFolder = async (
  createStartupFolderData: IStartupCandidatesFolderRequest,
): Promise<IStartupCandidatesFolderBasicData> => {
  const { data } = await api.post<IStartupCandidatesFolderBasicData>(
    `${servicesPrefix}`,
    createStartupFolderData,
  );

  return data;
};

export const findOneStartupCandidatesFolderById = async (
  startupCandidatesFolderId: number,
): Promise<IStartupCandidatesFolderResponse | undefined> => {
  const { data } = await api.get<IStartupCandidatesFolderResponse | undefined>(
    `${servicesPrefix}/findOneById/${startupCandidatesFolderId}`,
  );

  return data;
};

export const findAllStartupCandidatesFoldersByStartupId = async (
  startupId: number,
): Promise<IStartupCandidatesFolderBasicData[]> => {
  const { data } = await api.get<IStartupCandidatesFolderBasicData[]>(
    `${servicesPrefix}/findAllByStartupId/${startupId}`,
  );

  return data;
};

export const findAllStartupCandidatesFolderByStartupIdWithFiledCandidates =
  async (
    startupId: number,
  ): Promise<IStartupCandidatesFolderExhibitionData[]> => {
    const { data } = await api.get<IStartupCandidatesFolderExhibitionData[]>(
      `${servicesPrefix}/findAllByStartupIdWithFiledCandidates/${startupId}`,
    );

    return data;
  };

export const updateStartupCandidatesFolder = async (
  startupCandidatesFolderId: number,
  updateStartupCandidatesFolderData: IStartupCandidatesFolderRequest,
): Promise<IStartupCandidatesFolderBasicData> => {
  const { data } = await api.put<IStartupCandidatesFolderBasicData>(
    `${servicesPrefix}/${startupCandidatesFolderId}`,
    updateStartupCandidatesFolderData,
  );

  return data;
};

export const deleteStartupCandidatesFolder = async (
  startupCandidatesFolderId: number,
): Promise<void> => {
  await api.delete(`${servicesPrefix}/${startupCandidatesFolderId}`);
};
