import { LowEmphasis } from 'assets/colors/palette';
import styled from 'styled-components';

export const Stage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  padding-inline: 21px;

  @keyframes dotFlashing {
    0% {
      background-color: ${LowEmphasis};
    }
    50%,
    100% {
      background-color: #cccccc;
    }
  }

  .dot-flashing {
    position: relative;
    width: 6px;
    height: 6px;
    border-radius: 5px;
    background-color: ${LowEmphasis};
    color: ${LowEmphasis};
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: 0.5s;

    &::before,
    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
    }

    &::before {
      left: -15px;
      width: 6px;
      height: 6px;
      border-radius: 5px;
      background-color: ${LowEmphasis};
      color: ${LowEmphasis};
      animation: dotFlashing 1s infinite alternate;
      animation-delay: 0s;
    }

    &::after {
      left: 15px;
      width: 6px;
      height: 6px;
      border-radius: 5px;
      background-color: ${LowEmphasis};
      color: ${LowEmphasis};
      animation: dotFlashing 1s infinite alternate;
      animation-delay: 1s;
    }
  }
`;
