import { Depth4 } from 'assets/colors/boxShadows';
import {
  BackgroundColor,
  MediumEmphasis,
  OutlineColor,
} from 'assets/colors/palette';
import {
  Bold14Styles,
  Medium14Styles,
  Regular14Styles,
} from 'assets/fonts/fontStyles';
import styled, { css } from 'styled-components';

interface ContainerProps {
  vacancyIsClosed: boolean;
}

interface CandidateStatusProps {
  colorCss: string;
  backgroundCss: string;
}

export const Container = styled.button<ContainerProps>`
  width: 324px;
  height: 374px;
  text-align: start;

  background-color: ${BackgroundColor};
  border: 1px solid ${OutlineColor};
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  padding-bottom: 72px;
  transition: 0.2s;

  &:hover {
    box-shadow: ${Depth4};
  }

  @media (max-width: 1100px) {
    width: 90vw;
  }
`;

export const TopContainer = styled.div`
  width: 100%;
  height: 81px;
  padding: 20px;

  display: flex;
  align-items: center;
  gap: 12px;
  border-bottom: 1px solid ${OutlineColor};

  span {
    ${Bold14Styles}
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
`;

export const VacancyInformations = styled.div`
  padding: 12px 20px;
  height: 220px;
`;

export const VacancyName = styled.p`
  ${Bold14Styles}
  line-height: 20px;
  margin-bottom: 4px;
`;

export const VacancyDetail = styled.p`
  ${Medium14Styles}
  line-height: 24px;
  color: ${MediumEmphasis};
`;

export const CandidatedStep = styled.div`
  ${Regular14Styles}

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 12px;
  height: calc(100% - 72px);

  .step-name {
    display: flex;
    align-items: center;

    padding: 16px;
    width: max-content;
    height: 46px;

    border: 1px solid ${OutlineColor};
    border-radius: 100px;
  }
`;

export const BottomContainer = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  position: absolute;

  width: 100%;
  padding: 12px 20px;
  height: 72px;
  bottom: 0;
  left: 0;

  border: 1px solid ${OutlineColor};
  border-radius: 0 0 8px 8px;

  span {
    ${Bold14Styles}
  }

  ${({ vacancyIsClosed }) =>
    vacancyIsClosed
      ? css`
          border: none;
          background-color: rgba(220, 220, 220, 0.25);
          justify-content: center;
          gap: 8px;
        `
      : css`
          background-color: ${BackgroundColor};
          justify-content: space-between;
        `}
`;

export const CandidateStatus = styled.div<CandidateStatusProps>`
  height: 48px;
  padding: 16px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;

  ${({ backgroundCss, colorCss }) => css`
    ${backgroundCss}

    span {
      ${Bold14Styles}
      ${colorCss}
    }
  `}
`;
