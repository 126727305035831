import {
  IUpdateVacancyRequest,
  IVacancyDTOForMultipleVacancies,
  IVacancyRequest,
  IVacancyResponse,
} from 'types/vacancy';
import { IVacancyBasicData } from 'types/vacancy/IVacancyBasicData';
import { api } from '../api';

const servicesPrefix = 'vacancy';

export const createVacancy = async (
  createVacancyData: IVacancyRequest,
): Promise<IVacancyBasicData> => {
  const { data } = await api.post<IVacancyBasicData>(
    `${servicesPrefix}`,
    createVacancyData,
  );

  return data;
};

export const findAllVacancies = async (): Promise<IVacancyBasicData[]> => {
  const { data } = await api.get<IVacancyBasicData[]>('vacancy/findAll');
  return data;
};

export const findAllAvailableVacancies = async (): Promise<
  IVacancyDTOForMultipleVacancies[]
> => {
  const { data } = await api.get<IVacancyDTOForMultipleVacancies[]>(
    'vacancy/findAllAvailable',
  );
  return data;
};

export const findOneVacancyById = async (
  vacancyId: number,
): Promise<IVacancyResponse | undefined> => {
  const { data } = await api.get<IVacancyResponse | undefined>(
    `${servicesPrefix}/findOneById/${vacancyId}`,
  );

  return data;
};

export const findAllVacanciesByStartupId = async (
  startupId: number,
): Promise<Omit<IVacancyDTOForMultipleVacancies, 'startup'>[]> => {
  const { data } = await api.get<
    Omit<IVacancyDTOForMultipleVacancies, 'startup'>[]
  >(`${servicesPrefix}/findAllByStartupId/${startupId}`);

  return data;
};

export const findAllAvailableVacanciesByStartupId = async (
  startupId: number,
): Promise<Omit<IVacancyDTOForMultipleVacancies, 'startup'>[]> => {
  const { data } = await api.get<
    Omit<IVacancyDTOForMultipleVacancies, 'startup'>[]
  >(`${servicesPrefix}/findAllAvailableByStartupId/${startupId}`);

  return data;
};

export const updateVacancy = async (
  vacancyId: number,
  updatedVacancy: IUpdateVacancyRequest,
): Promise<IVacancyBasicData> => {
  const { data } = await api.put<IVacancyBasicData>(
    `${servicesPrefix}/${vacancyId}`,
    updatedVacancy,
  );

  return data;
};

export const deleteVacancy = async (vacancyId: number): Promise<void> => {
  await api.delete(`${servicesPrefix}/${vacancyId}`);
};
