import { CategoriesPropsType } from 'types/criteria';
import { DefaultGetCriteria } from './DefaultGetCriteria';
import { GetProfessionalProfileCriteria } from './GetProfessionalProfileCriteria';
import { GetTestsCriteria } from './GetTestsCriteria';

export const CATEGORIES_PROPS: CategoriesPropsType = {
  locations: {
    exhibitionName: 'Localização',
    GetCriteriaComponent: DefaultGetCriteria,
    placeholder: (
      <>
        Você tem preferência por candidatos de quais cidades?
        <br />
        (por exemplo: Natal, Fortaleza, Ceará).
      </>
    ),
  },
  professionalExperiences: {
    exhibitionName: 'Experiência/Atribuições',
    GetCriteriaComponent: DefaultGetCriteria,
    placeholder: (
      <>
        Você tem preferência por candidatos que já trabalharam em quais cargos
        anteriormente? (por exemplo: vendedor externo).
        <br />
        <br />
        Além disso, quais as atribuições que os candidatos já desempenharam são
        ideais para esta vaga? (por exemplo: reunião com clientes).
      </>
    ),
  },
  academicEducations: {
    exhibitionName: 'Formação',
    GetCriteriaComponent: DefaultGetCriteria,
    placeholder: (
      <>
        Você tem preferência por candidatos que tenham qual nível de
        escolaridade? (por exemplo: médio completo, superior incompleto).
        <br />
        <br />
        Além disso, qual curso é recomendável o candidato ter para trabalhar
        nessa vaga? (por exemplo: administração, economia).
      </>
    ),
  },
  competencies: {
    exhibitionName: 'Competências técnicas',
    GetCriteriaComponent: DefaultGetCriteria,
    placeholder: (
      <>
        Você tem preferência por candidatos que tenham quais
        <br />
        competências técnicas em softwares ou equipamentos?
        <br />
        (por exemplo: Excel).
      </>
    ),
  },
  skills: {
    exhibitionName: 'Habilidades pessoais',
    GetCriteriaComponent: DefaultGetCriteria,
    placeholder: (
      <>
        Você tem preferência por candidatos que tenham quais
        <br />
        habilidades interpessoais? (por exemplo: proativo, dinâmico).
      </>
    ),
  },
  languages: {
    exhibitionName: 'Idiomas',
    GetCriteriaComponent: DefaultGetCriteria,
    placeholder: (
      <>
        Você tem preferência por candidatos que tenham conhecimento
        <br />
        ou domínio de quais idiomas? (por exemplo: inglês).
      </>
    ),
  },
  certifications: {
    exhibitionName: 'Certificações',
    GetCriteriaComponent: DefaultGetCriteria,
    placeholder: (
      <>
        Você tem preferência por candidatos que tenham quais
        <br />
        certificações, licenças ou cursos? (por exemplo: CNH Tipo B;
        <br />
        CPA 20).
      </>
    ),
  },
  professionalProfiles: {
    exhibitionName: 'Perfil profissional',
    GetCriteriaComponent: GetProfessionalProfileCriteria,
    placeholder: (
      <>
        Escolha abaixo qual o perfil profissional dos candidatos
        <br />é mais recomendado para esta vaga.
      </>
    ),
  },
  tests: {
    exhibitionName: 'Testes',
    GetCriteriaComponent: GetTestsCriteria,
    placeholder: (
      <>
        Defina a pontuação de corte da pontuação dos candidatos
        <br />
        nas avaliações de português e raciocínio lógico.
      </>
    ),
  },
};
