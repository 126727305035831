import in6Logo from 'assets/img/in6Logo.svg';
import * as yup from 'yup';

import { Header } from 'components/Header';
import { COMPANY_NAME } from 'constants/company';
import { routeBar } from 'routes/routesAddresses';
import { PrimaryButton } from 'components/Buttons';
import { Input } from 'components/Input';
import { generateFormObjectFromStates } from 'utils';
import { useCallback } from 'react';
import { yupRequiredStringField } from 'utils/validation';
import { useInputStates } from 'utils/customHooks';
import { useRedirectToEmailSentPage } from 'utils/customHooks/redirects';
import { useFormError } from 'errors/useFormError';
import { sendRecoverPasswordEmail } from 'services/entitiesServices/userServices';
import { IServerError } from 'types';
import { ERROR_MODAL_CONFIGURATION, useAlertModal } from 'hooks/alertModal';
import { Title, Description, Container } from './styles';

export const ForgotPassword: React.FC = () => {
  document.title = `Recuperar Senha - ${COMPANY_NAME}`;
  const { handleFormError } = useFormError();
  const { showModal } = useAlertModal();

  const emailStates = useInputStates('email');
  const redirectToEmailSentPage = useRedirectToEmailSentPage({
    title: 'Recuperação de senha',
    email: emailStates.mainState.value as string,
    description:
      'Um link para redefinir sua senha foi enviado ' +
      'para seu email. Siga as instruções abaixo.',
    lastStepMessage: 'Clique no link para criar uma nova senha',
    emailType: 'forgotPassword',
  });

  const handleSubmit = useCallback(async () => {
    const formStates = [emailStates];
    const formObject = generateFormObjectFromStates(formStates);

    try {
      const schema = yup.object().shape({
        email: yupRequiredStringField.email('Formato de e-mail inválido'),
      });

      await schema.validate(formObject, { abortEarly: false });
      await sendRecoverPasswordEmail(formObject.email as string);

      redirectToEmailSentPage();
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        handleFormError(error as Error | yup.ValidationError, formStates);
        return;
      }

      const {
        response: {
          data: { status },
        },
      } = error as IServerError;

      if (status === 404) {
        showModal({
          ...ERROR_MODAL_CONFIGURATION,
          message: 'Nenhuma conta está cadastrada com esse email',
        });
      }
    }
  }, [emailStates, handleFormError, redirectToEmailSentPage, showModal]);

  return (
    <>
      <Header logo={in6Logo} redirectRoute={routeBar} />

      <Container>
        <Title>Esqueceu a senha?</Title>

        <Description>
          Insira o endereço de email associado à sua conta que lhe enviaremos um
          link de redefinição de senha
        </Description>

        <Input name="Email" placeholder="inserir email" states={emailStates} />

        <PrimaryButton type="submit" onClick={handleSubmit}>
          Enviar email de redefinição de senha
        </PrimaryButton>
      </Container>
    </>
  );
};
