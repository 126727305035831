import {
  ICandidateAcademicEducation,
  ICandidateProfessionalExperience,
  ICandidateResponse,
} from 'types/candidate';
import { EntityWithNameDTO } from 'types/EntityWithNameDTO';

const simpleStringsMeetsSearchText = (
  searchedText: string,
  contentToCompare: string[],
) => {
  const contentThatMeetsSearchTextIndex = contentToCompare.findIndex(content =>
    content.toLowerCase().includes(searchedText),
  );

  return contentThatMeetsSearchTextIndex !== -1;
};

const entitiesWithNameMeetsSearchText = (
  searchedText: string,
  contentToCompare: EntityWithNameDTO[],
) => {
  const contentThatMeetsSearchTextIndex = contentToCompare.findIndex(
    ({ name }) => name.toLowerCase().includes(searchedText),
  );

  return contentThatMeetsSearchTextIndex !== -1;
};

const arrayOfEntitiesWithNameMeetsSearchText = (
  searchedText: string,
  contentToCompare: EntityWithNameDTO[][],
) => {
  const contentThatMeetsSearchTextIndex = contentToCompare.findIndex(content =>
    entitiesWithNameMeetsSearchText(searchedText, content),
  );

  return contentThatMeetsSearchTextIndex !== -1;
};

const professionalExperiencesMeetsSearchText = (
  searchedText: string,
  professionalExperiences: ICandidateProfessionalExperience[],
) => {
  const professionalExperienceThatMeetsSearchTextIndex =
    professionalExperiences.findIndex(
      ({ companyName, roleName }) =>
        roleName.toLowerCase().includes(searchedText) ||
        companyName.toLowerCase().includes(searchedText),
    );

  return professionalExperienceThatMeetsSearchTextIndex !== -1;
};

const academicEducationsMeetsSearchText = (
  searchedText: string,
  academicEducations: ICandidateAcademicEducation[],
) => {
  const academicEducationThatMeetsSearchTextIndex =
    academicEducations.findIndex(
      ({ title, institutionName }) =>
        title.toLowerCase().includes(searchedText) ||
        institutionName.toLowerCase().includes(searchedText),
    );

  return academicEducationThatMeetsSearchTextIndex !== -1;
};

export const candidateMeetsSearchText = (
  searchedText: string,
  {
    user: { name, lastName, email },
    profession,
    certifications,
    competencies,
    languages,
    skills,
    professionalExperiences,
    academicEducations,
  }: ICandidateResponse,
): boolean => {
  const simpleStrings = [`${name} ${lastName}`, profession, email];
  if (simpleStringsMeetsSearchText(searchedText, simpleStrings)) return true;

  const arrayOfEntitiesWithName = [
    certifications,
    competencies,
    languages,
    skills,
  ];
  if (
    arrayOfEntitiesWithNameMeetsSearchText(
      searchedText,
      arrayOfEntitiesWithName,
    )
  ) {
    return true;
  }

  if (
    professionalExperiencesMeetsSearchText(
      searchedText,
      professionalExperiences,
    )
  ) {
    return true;
  }

  if (academicEducationsMeetsSearchText(searchedText, academicEducations)) {
    return true;
  }

  return false;
};
