import simpleArrowRight from 'assets/img/simpleArrowRight.svg';
import { useState } from 'react';

import { VacancyStatus } from 'types/vacancy';
import { VACANCY_STATUS_COLORS } from 'utils/typeOptions';
import { STATUS_TEXTS } from '..';
import {
  CurrentStatusButton,
  StatusOptionsContainer,
  StatusOption,
} from './styles';

interface VacancyCurrentStatusButtonProps {
  vacancyStatus: VacancyStatus;
  handleChangeVacancyStatus: (updatedStatus: VacancyStatus) => void;
}

export const VacancyCurrentStatusButton: React.FC<
  VacancyCurrentStatusButtonProps
> = ({ vacancyStatus, handleChangeVacancyStatus }) => {
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);

  return (
    <div style={{ position: 'relative' }}>
      <CurrentStatusButton
        type="button"
        style={{ backgroundColor: VACANCY_STATUS_COLORS[vacancyStatus].high }}
        disabled={vacancyStatus === VacancyStatus.CLOSED}
        onBlur={() => setIsOptionsVisible(false)}
        onClick={() => setIsOptionsVisible(value => !value)}
      >
        <span>Vaga {STATUS_TEXTS[vacancyStatus].substantive}</span>
        <img src={simpleArrowRight} alt="Seta para baixo" />
      </CurrentStatusButton>

      <StatusOptionsContainer isOptionsVisible={isOptionsVisible}>
        {Object.entries(STATUS_TEXTS).map(
          ([key, { verb }]) =>
            key !== vacancyStatus && (
              <StatusOption
                key={key}
                type="button"
                hoverColor={VACANCY_STATUS_COLORS[key as VacancyStatus].high}
                onClick={() => handleChangeVacancyStatus(key as VacancyStatus)}
              >
                {verb}
              </StatusOption>
            ),
        )}
      </StatusOptionsContainer>
    </div>
  );
};
