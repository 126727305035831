import { MediumEmphasis } from 'assets/colors/palette';
import { Regular24Styles } from 'assets/fonts/fontStyles';
import styled from 'styled-components';

export const Container = styled.div`
  a {
    ${Regular24Styles}
    font-size: 12px;
    color: ${MediumEmphasis};
    background-color: transparent;
    text-decoration: none;

    width: max-content;
    position: relative;

    & + a {
      margin-left: 20px;

      &:after {
        content: '';
        position: absolute;
        width: 2px;
        height: 2px;

        border-radius: 50%;
        background-color: ${MediumEmphasis};

        top: 6px;
        left: -10px;
      }
    }
  }
`;
