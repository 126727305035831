import {
  yupRequiredNumberField,
  yupRequiredStringField,
} from 'utils/validation';
import * as yup from 'yup';
import { AcademicEducationsStepData } from '.';

export const validateAcademicEducationsData = async (
  academicEducationsData: AcademicEducationsStepData,
): Promise<void> => {
  const yupYearField = yupRequiredNumberField.test(
    'length',
    'O ano de fundação precisa conter 4 dígitos',
    value => value?.toString().length === 4,
  );

  const yupProfessionalExperienceObjectShape = yup.object().shape({
    title: yup.string().optional(),
    institutionName: yupRequiredStringField,
    levelOfSchooling: yupRequiredStringField,
    startYear: yupYearField,
    endYear: yupYearField.min(
      yup.ref('startYear'),
      'O ano de término não pode ser menor que o de início',
    ),
  });

  const schema = yup.object().shape({
    academicEducations: yup
      .array(yupProfessionalExperienceObjectShape)
      .required(),
  });

  await schema.validate(academicEducationsData, { abortEarly: false });
};
