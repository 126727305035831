import { TextGradientPrimaryBackground } from 'assets/colors/background';
import { BackgroundColor, HighGreen, HighRed } from 'assets/colors/palette';
import closeIcon from 'assets/img/closeOne.svg';

import { SimpleButton } from 'components/Buttons';
import { useMemo } from 'react';
import { CandidateApproval } from 'types';
import { CandidateEvaluation } from 'types/CandidateEvaluation';
import { VacancyStatus } from 'types/vacancy';
import { IVacancyCandidateWithVacancyData } from 'types/vacancyCandidate';
import { convertCityAndStateFromObjectToString } from 'utils/conversion/convertCityAndStateFromObjectToString';
import {
  Container,
  TopContainer,
  VacancyInformations,
  VacancyName,
  VacancyDetail,
  CandidatedStep,
  BottomContainer,
  CandidateStatus,
} from './styles';

interface VacancyContainerProps {
  candidatedVacancy: IVacancyCandidateWithVacancyData;
  showGiveUpModal: () => void;
  showHighlightModal: () => void;
}

export const VacancyContainer: React.FC<VacancyContainerProps> = ({
  candidatedVacancy: {
    vacancy,
    candidateStep,
    candidateEvaluation,
    candidateApproval,
  },
  showGiveUpModal,
  showHighlightModal,
}) => {
  const vacancyIsClosed = vacancy.vacancyStatus === VacancyStatus.CLOSED;

  const { backgroundCss, colorCss, statusText } = useMemo(() => {
    if (
      candidateEvaluation === CandidateEvaluation.OUT_OF_EVALUATION ||
      candidateApproval === CandidateApproval.DISAPPROVED
    ) {
      return {
        statusText: 'Reprovado',
        colorCss: `color: ${BackgroundColor};`,
        backgroundCss: `background-color: ${HighRed};`,
      };
    }

    if (candidateApproval === CandidateApproval.APPROVED) {
      return {
        statusText: 'Aprovado',
        colorCss: `color: ${BackgroundColor};`,
        backgroundCss: `background-color: ${HighGreen};`,
      };
    }

    return {
      statusText: 'Participando',
      colorCss: TextGradientPrimaryBackground,
      backgroundCss: `background: rgba(224, 0, 37, 0.1);`,
    };
  }, [candidateApproval, candidateEvaluation]);

  return (
    <div style={{ position: 'relative' }}>
      <Container
        type="button"
        vacancyIsClosed={vacancyIsClosed}
        onClick={showHighlightModal}
      >
        <TopContainer>
          <img src={vacancy.startup.logo} alt="Logo da startup" />
          <span>{vacancy.startup.name}</span>
        </TopContainer>

        <VacancyInformations>
          <VacancyName>{vacancy.name}</VacancyName>

          <VacancyDetail>
            {vacancy.vacancyLocation
              ? convertCityAndStateFromObjectToString(
                  vacancy.vacancyLocation,
                  ', ',
                  true,
                )
              : 'Remoto'}
          </VacancyDetail>
          <VacancyDetail>{vacancy.jobType.type}</VacancyDetail>

          {candidateStep && (
            <CandidatedStep>
              <p>Sua etapa:</p>
              <div className="step-name">{candidateStep.name}</div>
            </CandidatedStep>
          )}
        </VacancyInformations>
      </Container>

      <BottomContainer vacancyIsClosed={vacancyIsClosed}>
        {vacancyIsClosed ? (
          <>
            <img src={closeIcon} alt="X" />
            <span>Esta vaga foi encerrada</span>
          </>
        ) : (
          <>
            {candidateEvaluation !== CandidateEvaluation.OUT_OF_EVALUATION && (
              <SimpleButton type="button" onClick={showGiveUpModal}>
                Desistir
              </SimpleButton>
            )}

            <div />

            <CandidateStatus backgroundCss={backgroundCss} colorCss={colorCss}>
              <span>{statusText}</span>
            </CandidateStatus>
          </>
        )}
      </BottomContainer>
    </div>
  );
};
