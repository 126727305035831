import { LevelOfSchooling } from 'types';
import { levelOfSchoolingOptions } from './typeOptions/levelOfSchoolingOptions';

export const getLevelOfSchoolingOptionsIdFromLevelOfSchoolingEnum = (
  searchLevelOfSchooling: LevelOfSchooling,
): number => {
  const findedIndex = levelOfSchoolingOptions.findIndex(
    ({ enumValue }) => enumValue === searchLevelOfSchooling,
  );

  return findedIndex + 1;
};
