import deleteIcon from 'assets/img/delete.svg';

import { HighRed } from 'assets/colors/palette';
import { OptionButton, SecondaryButton } from 'components/Buttons';
import { Input } from 'components/Input';
import { Loader } from 'components/Loader';
import { ERROR_MODAL_CONFIGURATION, useAlertModal } from 'hooks/alertModal';
import { useCallback, useState } from 'react';
import { useInputStates } from 'utils/customHooks';
import {
  ButtonsContainer,
  Container,
  CreationContainer,
  TopContainer,
} from './styles';

interface DeleteUserModalProps {
  handleCloseModal: () => void;
  handleDeleteUser: (password: string) => Promise<void>;
}

export const DeleteUserModal: React.FC<DeleteUserModalProps> = ({
  handleCloseModal,
  handleDeleteUser,
}) => {
  const { showModal } = useAlertModal();

  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const passwordStates = useInputStates('password');

  const cleanPasswordAndCloseModal = useCallback(() => {
    passwordStates.mainState.setFunction('');
    handleCloseModal();
  }, [handleCloseModal, passwordStates.mainState]);

  const handleConfirmDelete = useCallback(async () => {
    setIsLoadingDelete(true);
    const {
      mainState: { value: password },
    } = passwordStates;

    try {
      await handleDeleteUser(password as string);

      showModal({
        message: `O usuário foi excluído com sucesso`,
        backgroundColor: HighRed,
        icon: deleteIcon,
        forceIconWhiteColor: true,
      });
    } catch {
      showModal({
        ...ERROR_MODAL_CONFIGURATION,
        message:
          'Erro ao excluir usuário, por favor, tente novamente mais tarde',
      });
    } finally {
      setIsLoadingDelete(false);
      cleanPasswordAndCloseModal();
    }
  }, [cleanPasswordAndCloseModal, handleDeleteUser, passwordStates, showModal]);

  return (
    <Container>
      <TopContainer>
        <span>Excluir usuário</span>
      </TopContainer>

      {isLoadingDelete ? (
        <Loader
          style={{
            minHeight: 0,
            padding: 32,
            background: 'transparent',
            marginTop: 100,
          }}
        />
      ) : (
        <CreationContainer>
          <p>
            Ao confirmar a exclusão do usuário não será possível desfazer esta
            ação.
          </p>

          <Input
            style={{ width: 398, height: 48 }}
            placeholder="inserir senha"
            type="password"
            states={passwordStates}
            notScrollToInput
            notHasError
          />

          <ButtonsContainer>
            <OptionButton type="button" onClick={cleanPasswordAndCloseModal}>
              Cancelar
            </OptionButton>

            <SecondaryButton
              type="button"
              onClick={handleConfirmDelete}
              disabled={(passwordStates.mainState.value as string).length === 0}
            >
              Excluir usuário
            </SecondaryButton>
          </ButtonsContainer>
        </CreationContainer>
      )}
    </Container>
  );
};
