import { TextGradientPrimaryBackground } from 'assets/colors/background';
import { BackgroundColor, OutlineColor } from 'assets/colors/palette';
import {
  Bold18Styles,
  Regular14Styles,
  Regular24Styles,
} from 'assets/fonts/fontStyles';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    ${Regular14Styles}
    line-height: 24px;
    width: 533px;
  }

  @media (max-width: 1100px) {
    p {
      width: 100%;
    }
  }
`;

export const ProfileHighlight = styled.div`
  position: relative;
  height: 178px;
  margin-bottom: 48px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 14px;

  h2 {
    ${Bold18Styles}
    color: ${BackgroundColor};
    text-align: center;

    margin-bottom: 8px;
  }
`;

export const HighlightPolygon = styled.img`
  position: absolute;
  top: 0;
  z-index: -1;
`;

export const ProfileFeaturesContainer = styled.div`
  padding-top: 48px;

  display: flex;
  flex-flow: wrap;
  justify-content: center;
  gap: 32px;

  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const Feature = styled.section`
  width: 250px;
  height: 280px;
  padding: 20px;

  background-color: ${BackgroundColor};
  border: 1px solid ${OutlineColor};
  border-radius: 8px;

  h3 {
    ${TextGradientPrimaryBackground}
    ${Regular24Styles}

    font-size: 18px;
    margin-bottom: 24px;
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;

export const FeatureCharacteristic = styled.div`
  ${Regular14Styles}
  line-height: 24px;

  width: max-content;
  height: 36px;
  padding: 8px 16px;

  background-color: rgba(220, 220, 220, 0.25);
  border-radius: 100px;

  display: flex;
  align-items: center;
  justify-content: center;
`;
