import { useHistory } from 'react-router-dom';
import { routeEmailSent } from 'routes/routesAddresses';

export type EmailType = 'activation' | 'forgotPassword' | 'none';

export interface EmailSentPageParams {
  title: string;
  email: string;
  description: string;
  lastStepMessage: string;
  emailType: EmailType;
}

export const useRedirectToEmailSentPage = (
  params: EmailSentPageParams,
): (() => void) => {
  const history = useHistory();
  return () => history.push(routeEmailSent, params);
};
