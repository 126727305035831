import { isAfter } from 'date-fns';
import { ICandidateResponse } from 'types/candidate';

export const getCandidateLastUpdatedAt = (
  candidate: ICandidateResponse | undefined,
): Date => {
  if (candidate === undefined) return new Date();

  const parsedCandidateUpdatedAt = new Date(candidate.updatedAt);
  const parsedUserUpdatedAt = new Date(candidate.user.updatedAt);

  const candidateUpdatedAtIsAfter = isAfter(
    parsedCandidateUpdatedAt,
    parsedUserUpdatedAt,
  );

  return candidateUpdatedAtIsAfter
    ? parsedCandidateUpdatedAt
    : parsedUserUpdatedAt;
};
