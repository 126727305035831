interface ResultType {
  description: JSX.Element;
}

const POSSIBLE_RESULTS = {
  lessThan40: {
    description: (
      <p>
        Você demonstra letramento em leitura limitado, apresentando dificuldades
        com a compreensão de textos utilizados no dia a dia, bem como de seguir
        princípios básicos de ortografia e gramática.
      </p>
    ),
  },
  lessThan70: {
    description: (
      <p>
        Você demonstra letramento em leitura suficiente para interpretar textos
        utilizados no dia a dia, além de compreender e seguir noções básicas de
        ortografia e gramática.
      </p>
    ),
  },
  higherThan70: {
    description: (
      <p>
        Você demonstra letramento em leitura muito bem desenvolvido para
        interpretar diferentes tipos de texto do dia a dia, com facilidade e
        rapidez, demonstrando entendimento de ortografia e gramática
        consistentes para favorecer compreensões mais profundas.
      </p>
    ),
  },
};

export const getResultScreenPropertiesBasedOnTestResult = (
  testResult: string | undefined,
): ResultType => {
  if (Number(testResult) < 40) return POSSIBLE_RESULTS.lessThan40;
  if (Number(testResult) < 70) return POSSIBLE_RESULTS.lessThan70;
  return POSSIBLE_RESULTS.higherThan70;
};
