import candidateIcon from 'assets/img/candidateIcon.svg';

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getAverageColorFromImage } from 'utils';
import { PrimaryColor } from 'assets/colors/palette';

import { SecondaryButton, SimpleButton } from 'components/Buttons';
import { useStartup } from 'hooks/startup';

import html2canvas from 'html2canvas';
import { Loader } from 'components/Loader';
import {
  getLowAndHighColorsFromRGB,
  convertNameToSnakeCase,
} from 'utils/conversion';

import { verifyIfVacancyHasRequirements } from 'utils/verifyIfVacancyHasRequirements';
import { IVacancyResponse, VacancyStatus } from 'types/vacancy';
import { convertCityAndStateFromObjectToString } from 'utils/conversion/convertCityAndStateFromObjectToString';
import { convertLanguageFromObjectToString } from 'utils/conversion/convertLanguageFromObjectToString';
import { useAlertModal } from 'hooks/alertModal';
import { VACANCY_STATUS_COLORS } from 'utils/typeOptions/vacancyStatusColors';
import { Modal } from 'components/Modal';
import { authenticateUser } from 'services/entitiesServices/userServices';
import { useAuth } from 'hooks/auth';
import {
  VacancyStatusBar,
  MainContainer,
  VacancyDataContainer,
  VacancyDataContainerTitle,
  DataLine,
  LineTitle,
  RequirementsListContainer,
  Requirement,
  VacancyPostContainer,
  VacancySpecificationsContainer,
  VacancySpecification,
  LinkContainer,
  VacancyPost,
  VacancyPostDescription,
  PostActionButtonsContainer,
  RequirementDescription,
  CandidatesQuantityContainer,
  VacancyStatusInformation,
} from './styles';
import { VacancyPeriod } from './VacancyPeriod';
import { VacancyCurrentStatusButton } from './VacancyCurrentStatusButton';
import { ChangeVacancyStatusModal } from './ChangeVacancyStatusModal';

interface ColorType {
  low: string;
  high: string;
}

interface VacancyInformationProps {
  vacancy: IVacancyResponse;
  handleUpdateVacancyStatus: (
    vacancyStatus: VacancyStatus,
    closingReason?: string,
  ) => Promise<void>;
}

const VACANCY_SPECIFICATIONS = {
  immediateHiring: 'Contratação imediata',
  needExperience: 'Requer experiência',
  disabledPeople: 'Para PcD',
  forStudents: 'Para estudantes',
};

const VACANCY_POST_COLORS = {
  default: {
    low: 'rgb(255, 75, 0, 0.1)',
    high: PrimaryColor,
  },
  disabled: {
    low: 'rgba(0, 0, 0, 0.1)',
    high: '#000000',
  },
};

export const STATUS_TEXTS = {
  [VacancyStatus.ACTIVE]: {
    substantive: 'ativa',
    verb: 'Ativar',
  },
  [VacancyStatus.SUSPENDED]: {
    substantive: 'suspensa',
    verb: 'Suspender',
  },
  [VacancyStatus.CLOSED]: {
    substantive: 'encerrada',
    verb: 'Encerrar',
  },
};

export const VacancyInformation: React.FC<VacancyInformationProps> = ({
  vacancy,
  handleUpdateVacancyStatus,
}) => {
  const { showModal } = useAlertModal();
  const { startup } = useStartup();
  const { user } = useAuth();
  const { name: startupName, logo: startupLogo } = startup!;

  const vacancyPostRef = useRef<HTMLDivElement>(null);
  const [statusToUpdateVacancy, setStatusToUpdateVacancy] =
    useState<VacancyStatus | null>(null);
  const [isLoadingVacancyPostColors, setIsLoadingVacancyPostColors] =
    useState(true);
  const [vacancyPostColors, setVacancyPostColors] = useState<ColorType>(
    VACANCY_POST_COLORS[
      vacancy.vacancyStatus !== VacancyStatus.ACTIVE ? 'disabled' : 'default'
    ],
  );

  useEffect(() => {
    if (vacancy.vacancyStatus !== VacancyStatus.ACTIVE) {
      setIsLoadingVacancyPostColors(false);
      return;
    }

    const logo = new Image();
    logo.src = startupLogo;

    logo.onerror = () => setIsLoadingVacancyPostColors(false);
    logo.onload = () => {
      const averageLogoColor = getAverageColorFromImage(logo);

      setVacancyPostColors(getLowAndHighColorsFromRGB(averageLogoColor));
      setIsLoadingVacancyPostColors(false);
    };
  }, [startupLogo, vacancy.vacancyStatus]);

  const handleUpdateStatus = useCallback(
    async (
      updatedStatus: VacancyStatus,
      userPassword: string,
      closingReason?: string,
    ) => {
      await authenticateUser({
        email: String(user?.email),
        password: userPassword,
      });

      await handleUpdateVacancyStatus(updatedStatus, closingReason);
    },
    [handleUpdateVacancyStatus, user?.email],
  );

  const postDescription = useMemo(() => {
    const textParts = [];
    textParts.push(
      `Venha trabalhar com a gente! A ${startupName} está com vaga aberta `,
    );
    textParts.push(
      `para trabalhar como ${vacancy.name}.\nPara se candidatar e saber mais `,
    );
    textParts.push(
      `informações sobre esta vaga acesse: www.in6.com.br/${convertNameToSnakeCase(
        startupName,
      )}`,
    );
    textParts.push(
      '\nConhece alguém que seria perfeito para o trabalho? Não deixe ',
    );
    textParts.push(
      'de encaminhar esse post ou marcar a pessoa nos comentários!',
    );

    return textParts.join('');
  }, [startupName, vacancy.name]);

  const handleCopyPostText = useCallback(async () => {
    await navigator.clipboard.writeText(postDescription);

    showModal({
      message: 'Texto copiado para a área de transferência',
    });
  }, [postDescription, showModal]);

  const handleDownloadPostImage = useCallback(async () => {
    const { current } = vacancyPostRef;
    if (!current) return;

    const borderRadiusValue = current.style.getPropertyValue('border-radius');

    current.style.setProperty('border-radius', '0px');
    const canvas = await html2canvas(current, { scale: 3 });
    current.style.setProperty('border-radius', borderRadiusValue);

    const link = document.createElement('a');
    link.download = `${startupName}_${vacancy.name}-post.png`.replaceAll(
      ' ',
      '_',
    );
    link.href = canvas.toDataURL('image/png', 1);
    link.click();
  }, [startupName, vacancy.name]);

  return (
    <>
      <Modal isVisible={statusToUpdateVacancy !== null}>
        <ChangeVacancyStatusModal
          handleCloseModal={() => setStatusToUpdateVacancy(null)}
          handleUpdateStatus={handleUpdateStatus}
          updatedStatus={statusToUpdateVacancy}
        />
      </Modal>

      <VacancyStatusBar colors={VACANCY_STATUS_COLORS[vacancy.vacancyStatus]}>
        <VacancyStatusInformation>
          <VacancyCurrentStatusButton
            vacancyStatus={vacancy.vacancyStatus}
            handleChangeVacancyStatus={updatedStatus =>
              setStatusToUpdateVacancy(updatedStatus)
            }
          />

          <VacancyPeriod vacancy={vacancy} />
        </VacancyStatusInformation>

        <CandidatesQuantityContainer>
          <img src={candidateIcon} alt="Candidato" />
          <span>
            {vacancy.candidatesQuantity} candidato
            {vacancy.candidatesQuantity !== 1 ? 's' : ''}
          </span>
        </CandidatesQuantityContainer>
      </VacancyStatusBar>

      <MainContainer>
        <VacancyDataContainer>
          <VacancyDataContainerTitle>
            Informações gerais
          </VacancyDataContainerTitle>

          <DataLine>
            <LineTitle>Tipo do cargo</LineTitle>
            <p>{vacancy.jobType.type}</p>
          </DataLine>

          {vacancy.vacancyLocation && (
            <DataLine>
              <LineTitle>Localização do trabalho</LineTitle>
              <p>
                {convertCityAndStateFromObjectToString(vacancy.vacancyLocation)}
              </p>
            </DataLine>
          )}

          <DataLine>
            <LineTitle>Contratação Imediata</LineTitle>
            <p>{vacancy.immediateHiring ? 'Sim' : 'Não'}</p>
          </DataLine>

          <DataLine>
            <LineTitle>Requer experiência</LineTitle>
            <p>{vacancy.needExperience ? 'Sim' : 'Não'}</p>
          </DataLine>

          <DataLine>
            <LineTitle>Para Pessoas com deficiência</LineTitle>
            <p>{vacancy.disabledPeople ? 'Sim' : 'Não'}</p>
          </DataLine>

          <DataLine>
            <LineTitle>Para estudantes</LineTitle>
            <p>{vacancy.forStudents ? 'Sim' : 'Não'}</p>
          </DataLine>
        </VacancyDataContainer>

        <VacancyDataContainer>
          <VacancyDataContainerTitle>
            Descrição da vaga
          </VacancyDataContainerTitle>
          <p>{vacancy.description}</p>
        </VacancyDataContainer>

        <VacancyDataContainer>
          <VacancyDataContainerTitle>
            Jornada de trabalho
          </VacancyDataContainerTitle>

          <DataLine>
            <LineTitle>Tipo de carga horária</LineTitle>
            <p>{vacancy.workloadType.type}</p>
          </DataLine>

          {vacancy.workSchedule && (
            <DataLine>
              <LineTitle>Horário de trabalho</LineTitle>
              <p>{vacancy.workSchedule}</p>
            </DataLine>
          )}
        </VacancyDataContainer>

        <VacancyDataContainer>
          <VacancyDataContainerTitle>Remuneração</VacancyDataContainerTitle>

          {!vacancy.salaryToNegotiate && (
            <DataLine>
              <LineTitle>Salário</LineTitle>
              <p>
                {vacancy.salary} {vacancy.paymentType?.type}
              </p>
            </DataLine>
          )}

          <DataLine>
            <LineTitle>Salário a negociar</LineTitle>
            <p>{vacancy.salaryToNegotiate ? 'Sim' : 'Não'}</p>
          </DataLine>

          {vacancy.benefits && (
            <DataLine>
              <LineTitle>Benefícios para os funcionários</LineTitle>
              <p style={{ maxWidth: 458 }}>{vacancy.benefits}</p>
            </DataLine>
          )}
        </VacancyDataContainer>

        {verifyIfVacancyHasRequirements(vacancy) && (
          <VacancyDataContainer>
            <VacancyDataContainerTitle>Requisitos</VacancyDataContainerTitle>

            {vacancy.otherRequirements && (
              <DataLine>
                <p style={{ padding: '12px 0' }}>{vacancy.otherRequirements}</p>
              </DataLine>
            )}

            {vacancy.competencies.length > 0 && (
              <DataLine>
                <LineTitle>Competências técnicas</LineTitle>
                <RequirementsListContainer>
                  {vacancy.competencies.map(({ id, name }) => (
                    <Requirement key={id}>
                      <span>{name}</span>
                    </Requirement>
                  ))}
                </RequirementsListContainer>
              </DataLine>
            )}

            {vacancy.skills.length > 0 && (
              <DataLine>
                <LineTitle>Habilidades interpessoais</LineTitle>
                <RequirementsListContainer>
                  {vacancy.skills.map(({ id, name, description }) => (
                    <Requirement key={id}>
                      <span>{name}</span>

                      <RequirementDescription>
                        {description}
                      </RequirementDescription>
                    </Requirement>
                  ))}
                </RequirementsListContainer>
              </DataLine>
            )}

            {vacancy.languages.length > 0 && (
              <DataLine>
                <LineTitle>Idiomas</LineTitle>
                <RequirementsListContainer>
                  {vacancy.languages.map(language => (
                    <Requirement key={language.id}>
                      <span>{convertLanguageFromObjectToString(language)}</span>
                    </Requirement>
                  ))}
                </RequirementsListContainer>
              </DataLine>
            )}

            {vacancy.certifications.length > 0 && (
              <DataLine>
                <LineTitle>Certificações</LineTitle>
                <RequirementsListContainer>
                  {vacancy.certifications.map(({ id, name }) => (
                    <Requirement key={id}>
                      <span>{name}</span>
                    </Requirement>
                  ))}
                </RequirementsListContainer>
              </DataLine>
            )}
          </VacancyDataContainer>
        )}
      </MainContainer>

      <VacancyPostContainer
        disabled={vacancy.vacancyStatus !== VacancyStatus.ACTIVE}
      >
        {isLoadingVacancyPostColors ? (
          <Loader style={{ width: 360, height: 360, minHeight: 360 }} />
        ) : (
          <VacancyPost ref={vacancyPostRef} colors={vacancyPostColors}>
            <span>{startupName}</span>
            <h1>{vacancy.name}</h1>

            <small className="vacancy-data">
              {vacancy.workloadType.type} - {vacancy.jobType.type}
            </small>
            <small className="vacancy-data">
              {vacancy.vacancyLocation &&
                convertCityAndStateFromObjectToString(
                  vacancy.vacancyLocation,
                  '/',
                )}
            </small>

            <VacancySpecificationsContainer>
              {Object.entries(VACANCY_SPECIFICATIONS).map(
                ([key, name]) =>
                  vacancy[key as keyof IVacancyResponse] && (
                    <VacancySpecification key={key}>
                      {name}
                    </VacancySpecification>
                  ),
              )}
            </VacancySpecificationsContainer>

            <LinkContainer>
              <p>Candidate-se nesta vaga agora! Link na descrição.</p>

              <p>
                Ou encontre a vaga em{' '}
                <small id="vacancy-link">www.in6.com.br</small>
              </p>
            </LinkContainer>
          </VacancyPost>
        )}

        <VacancyPostDescription
          dangerouslySetInnerHTML={{
            __html: postDescription.replace('\n', '<br/>'),
          }}
        />

        <PostActionButtonsContainer>
          <SecondaryButton
            onClick={handleDownloadPostImage}
            disabled={vacancy.vacancyStatus !== VacancyStatus.ACTIVE}
          >
            Baixar post
          </SecondaryButton>
          <SimpleButton
            onClick={handleCopyPostText}
            disabled={vacancy.vacancyStatus !== VacancyStatus.ACTIVE}
          >
            Copiar legenda
          </SimpleButton>
        </PostActionButtonsContainer>
      </VacancyPostContainer>
    </>
  );
};
