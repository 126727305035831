import immediateHiringIcon from 'assets/img/immediateHiring.svg';
import needExperienceIcon from 'assets/img/needExperience.svg';
import disabledPeopleIcon from 'assets/img/disabledPeople.svg';
import forStudentsIcon from 'assets/img/forStudents.svg';
import localTwo from 'assets/img/localTwo.svg';
import cooperativeHandshake from 'assets/img/cooperativeHandshake.svg';
import time from 'assets/img/time.svg';
import dollar from 'assets/img/dollar.svg';
import hexagonStrip from 'assets/img/hexagonStrip.svg';

import { IVacancyResponse } from 'types/vacancy';
import { getVacancyDateStatusString } from 'utils/conversion/getVacancyDateStatusString';
import { convertCityAndStateFromObjectToString } from 'utils/conversion/convertCityAndStateFromObjectToString';
import { getParsedVacancySalaryInformation } from 'utils/conversion/getParsedVacancySalaryInformation';
import { HTMLAttributes, useEffect, useState } from 'react';
import { findOneVacancyById } from 'services/entitiesServices/vacancyServices';
import { ERROR_MODAL_CONFIGURATION, useAlertModal } from 'hooks/alertModal';
import { Loader } from 'components/Loader';
import { convertLanguageFromObjectToString } from 'utils/conversion/convertLanguageFromObjectToString';
import { verifyIfVacancyHasRequirements } from 'utils/verifyIfVacancyHasRequirements';
import {
  Container,
  Requirement,
  RequirementContainer,
  RequirementWithDescription,
  TitleContainer,
  VacancyAspects,
  VacancyData,
  VacancyDataLine,
  VacancyTopContainer,
  VacancyTopic,
} from './styles';

interface VacancyHighlightContainerProps
  extends HTMLAttributes<HTMLDivElement> {
  vacancy?: IVacancyResponse;
  vacancyIdToSerch?: number;
}

export const VacancyHighlightContainer: React.FC<
  VacancyHighlightContainerProps
> = ({ vacancy, vacancyIdToSerch, ...props }) => {
  const { showModal } = useAlertModal();

  const [parsedVacancy, setParsedVacancy] = useState<
    IVacancyResponse | undefined
  >(vacancy);

  useEffect(() => {
    if (parsedVacancy !== undefined || !vacancyIdToSerch) return;

    findOneVacancyById(vacancyIdToSerch)
      .then(findedVacancy => findedVacancy && setParsedVacancy(findedVacancy))
      .catch(() => {
        showModal({
          ...ERROR_MODAL_CONFIGURATION,
          message: 'Erro ao carregar informações da vaga',
        });
      });
  }, [parsedVacancy, showModal, vacancyIdToSerch]);

  if (parsedVacancy === undefined) return <Loader />;
  return (
    <Container {...props}>
      <VacancyTopContainer>
        <img src={parsedVacancy.startup.logo} alt="Logo da Empresa" />

        <aside>
          <p>{getVacancyDateStatusString(parsedVacancy)}</p>
          <p>Cód. {1000 + parsedVacancy.id}</p>
        </aside>
      </VacancyTopContainer>

      <TitleContainer>
        <h2 className="vacancyTitle">{parsedVacancy.name}</h2>
        <h3 className="companyName">{parsedVacancy.startup.name}</h3>
      </TitleContainer>

      <VacancyAspects>
        {parsedVacancy.immediateHiring && (
          <div>
            <img src={immediateHiringIcon} alt="Contratação imediata" />
            <small>Contratação imediata</small>
          </div>
        )}
        {parsedVacancy.needExperience && (
          <div>
            <img src={needExperienceIcon} alt="Requer experiência" />
            <small>Requer experiência</small>
          </div>
        )}
        {parsedVacancy.disabledPeople && (
          <div>
            <img src={disabledPeopleIcon} alt="Pessoas com deficiência" />
            <small>PcD</small>
          </div>
        )}
        {parsedVacancy.forStudents && (
          <div>
            <img src={forStudentsIcon} alt="Para estudantes" />
            <small>Para estudantes</small>
          </div>
        )}
      </VacancyAspects>

      <VacancyData>
        {parsedVacancy.vacancyLocation && (
          <VacancyDataLine>
            <img src={localTwo} alt="Localização" />
            <p>
              {convertCityAndStateFromObjectToString(
                parsedVacancy.vacancyLocation,
              )}
            </p>
          </VacancyDataLine>
        )}

        <VacancyDataLine>
          <img src={cooperativeHandshake} alt="Aperto de mãos" />
          <p>{parsedVacancy.jobType.type}</p>
        </VacancyDataLine>

        <VacancyDataLine>
          <img src={time} alt="Relógio" />

          <div>
            <p>{parsedVacancy.workloadType.type}</p>
            <p>{parsedVacancy.workSchedule}</p>
          </div>
        </VacancyDataLine>

        <VacancyDataLine>
          <img src={dollar} alt="Dólar" />
          <p>{getParsedVacancySalaryInformation(parsedVacancy)}</p>
        </VacancyDataLine>

        {parsedVacancy.benefits && (
          <VacancyDataLine>
            <img src={hexagonStrip} alt="Hexágono" />
            <p>{parsedVacancy.benefits}</p>
          </VacancyDataLine>
        )}
      </VacancyData>

      <VacancyTopic>
        <h3 className="topic-title">Descrição da vaga</h3>
        <p className="content">{parsedVacancy.description}</p>
      </VacancyTopic>

      {verifyIfVacancyHasRequirements(parsedVacancy) && (
        <VacancyTopic style={{ marginBottom: 10 }}>
          <h3 className="topic-title">Requisitos</h3>
          <p className="content">{parsedVacancy.otherRequirements}</p>
        </VacancyTopic>
      )}

      {parsedVacancy.competencies.length > 0 && (
        <RequirementContainer>
          <span>Competências técnicas</span>

          <div>
            {parsedVacancy.competencies.map(({ id, name }) => (
              <Requirement key={id}>{name}</Requirement>
            ))}
          </div>
        </RequirementContainer>
      )}

      {parsedVacancy.skills.length > 0 && (
        <RequirementContainer>
          <span>Habilidades comportamentais</span>

          <div>
            {parsedVacancy.skills.map(({ name, description }) => (
              <RequirementWithDescription key={name}>
                <Requirement>{name}</Requirement>
                <p>{description}</p>
              </RequirementWithDescription>
            ))}
          </div>
        </RequirementContainer>
      )}

      {parsedVacancy.languages.length > 0 && (
        <RequirementContainer>
          <span>Idiomas</span>

          <div>
            {parsedVacancy.languages.map(language => (
              <Requirement key={language.id}>
                {convertLanguageFromObjectToString(language)}
              </Requirement>
            ))}
          </div>
        </RequirementContainer>
      )}

      {parsedVacancy.certifications.length > 0 && (
        <RequirementContainer>
          <span>Certificações</span>

          <div>
            {parsedVacancy.certifications.map(({ id, name }) => (
              <Requirement key={id}>{name}</Requirement>
            ))}
          </div>
        </RequirementContainer>
      )}

      <VacancyTopic>
        <h3 className="topic-title">Sobre a {parsedVacancy.startup.name}</h3>
        <p className="content">{parsedVacancy.startup.about}</p>
      </VacancyTopic>
    </Container>
  );
};
