import React, { createContext, useCallback, useContext, useState } from 'react';
import { IStartupResponse } from 'types/startup';

interface IStartupContext {
  startup?: IStartupResponse;
  isShowingSubscribeModal: boolean;
  updateIsShowingSubscribeModal: (
    updatedIsShowingSubscribeModal: boolean,
  ) => void;
  updateStartupState: (startup: IStartupResponse) => void;
  clearStartupState: () => void;
}

const startupContext = createContext<IStartupContext>({} as IStartupContext);

export const StartupContext: React.FC = ({ children }) => {
  const [isShowingSubscribeModal, setIsShowingSubscribeModal] = useState(false);
  const [startup, setStartup] = useState<IStartupResponse | undefined>(
    undefined,
  );

  const updateStartupState = useCallback(
    (updatedStartup: IStartupResponse) => setStartup(updatedStartup),
    [],
  );
  const clearStartupState = useCallback(() => setStartup(undefined), []);

  const updateIsShowingSubscribeModal = useCallback(
    updatedIsShowingSubscribeModal =>
      setIsShowingSubscribeModal(updatedIsShowingSubscribeModal),
    [],
  );

  return (
    <startupContext.Provider
      value={{
        startup,
        updateStartupState,
        clearStartupState,
        isShowingSubscribeModal,
        updateIsShowingSubscribeModal,
      }}
    >
      {children}
    </startupContext.Provider>
  );
};

export const useStartup = (): IStartupContext => useContext(startupContext);
