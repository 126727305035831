import {
  BackgroundColor,
  GrayTitleActive,
  HighEmphasis,
  MediumEmphasis,
  OutlineColor,
  PrimaryColor,
} from 'assets/colors/palette';
import { Bold32Styles, Regular14Styles } from 'assets/fonts/fontStyles';
import styled from 'styled-components';

interface StartupDataLineProps {
  highlightedContent?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding-bottom: 50px;
  gap: 60px;
`;

export const StartupContainer = styled.div`
  width: 500px;
  padding: 30px 24px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;

  border: 1px solid ${OutlineColor};
  border-radius: 8px;

  & > p {
    ${Regular14Styles}
    line-height: 28px;
    color: ${HighEmphasis};

    max-width: 100%;
    word-wrap: break-word;
    white-space: pre-line;
    margin-top: 18px;
  }
`;

export const StartupLogo = styled.img`
  width: 120px;
  height: 120px;

  border-radius: 120px;
`;

export const StartupName = styled.div`
  ${Bold32Styles}
  line-height: 48px;
  color: ${GrayTitleActive};

  word-wrap: break-word;
  max-width: 490px;
`;

export const SocialNetworksContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;
  margin-bottom: 30px;

  &:empty {
    margin-bottom: 10px;
  }
`;

export const SocialNetwork = styled.a`
  width: 48px;
  height: 48px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${BackgroundColor};
  border: 1px solid ${OutlineColor};
  border-radius: 100px;
  transition: 0.2s;

  img {
    filter: brightness(10%);
  }

  &:hover {
    background: rgba(220, 220, 220, 0.2);
  }
`;

export const StartupData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 10px;

  width: 100%;
`;

export const StartupDataLine = styled.div<StartupDataLineProps>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  ${Regular14Styles}
  line-height: 24px;

  .line-title {
    color: ${MediumEmphasis};
  }

  .line-content {
    font-weight: 700;
    text-align: end;
    color: ${({ highlightedContent }) =>
      highlightedContent ? PrimaryColor : HighEmphasis};
  }
`;

export const GalleryImages = styled.div`
  display: flex;
  flex-flow: wrap;
  margin-top: 14px;
  gap: 22px;

  img {
    max-width: 100%;
    min-width: calc(50% - 11px);
  }
`;
