import { OutlineColor } from 'assets/colors/palette';
import { Regular16Styles, Regular24Styles } from 'assets/fonts/fontStyles';
import styled from 'styled-components';

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 12px;

  label {
    ${Regular16Styles}
    padding-left: 30px;

    span {
      top: -2px;
      width: 20px;
      height: 20px;
      border: 2px solid ${OutlineColor};

      &::after {
        left: 6px;
        bottom: 5px;
      }
    }
  }

  @media (max-width: 1100px) {
    align-items: center;
  }
`;

export const CheckBoxContainer = styled.div`
  width: 500px;
  height: 80px;
  border: 1px solid ${OutlineColor};
  border-radius: 8px;

  display: flex;
  align-items: center;
  padding-left: 4%;
  margin-bottom: 20px;

  @media (max-width: 1100px) {
    width: 100%;

    label {
      ${Regular24Styles}
      padding-left: 36px;
      font-size: 14px;
      width: 70%;

      span {
        top: 25%;
      }
    }
  }
`;
