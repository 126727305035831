import { HighEmphasis, MediumEmphasis } from 'assets/colors/palette';
import {
  Bold18Styles,
  Regular14Styles,
  Regular24Styles,
} from 'assets/fonts/fontStyles';
import styled from 'styled-components';

export const TitleContainer = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 1100px) {
    width: 90vw;
  }
`;

export const StepTitle = styled.h3`
  ${Regular24Styles}
  color: ${HighEmphasis};

  @media (max-width: 1100px) {
    ${Bold18Styles}
  }
`;

export const StepSubTitle = styled.p`
  ${Regular14Styles}
  line-height: 28px;
  color: ${MediumEmphasis};

  @media (max-width: 1100px) {
    font-size: 12px;
    line-height: 20px;
  }
`;
