import optionsIcon from 'assets/img/options.svg';
import closeIcon from 'assets/img/close.svg';

import { PrimaryButton, SecondaryButton } from 'components/Buttons';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { routeLogin, routeSignUp } from 'routes/routesAddresses';

import { AboutButtons } from 'components/AboutButtons';
import { Container, OptionsContainer } from './styles';

export const MobileOptionsButton: React.FC = () => {
  const history = useHistory();
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);

  return (
    <Container>
      <button
        type="button"
        onClick={() => setIsOptionsVisible(previousValue => !previousValue)}
      >
        <img
          src={isOptionsVisible ? closeIcon : optionsIcon}
          alt={isOptionsVisible ? 'Fechar' : 'Opções'}
        />
      </button>

      <OptionsContainer isVisible={isOptionsVisible}>
        <PrimaryButton
          type="button"
          onClick={() =>
            history.push(routeSignUp, {
              candidateAccountTypeSelected: true,
            })
          }
        >
          Cadastrar seu currículo
        </PrimaryButton>

        <SecondaryButton type="button" onClick={() => history.push(routeLogin)}>
          Entrar
        </SecondaryButton>

        <AboutButtons style={{ width: 261, marginTop: 16 }} />
      </OptionsContainer>
    </Container>
  );
};
