import { HighEmphasis } from 'assets/colors/palette';
import {
  Bold32Styles,
  Regular16Styles,
  Regular24Styles,
} from 'assets/fonts/fontStyles';
import styled from 'styled-components';

export const QuestionsContainer = styled.div`
  gap: 20px;
  padding-bottom: 311px;

  @media (max-width: 1100px) {
    padding-bottom: 7vh;
  }
`;

export const Container = styled.div`
  &,
  ${QuestionsContainer} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const TopContainer = styled.div`
  padding-block: 64px 96px;
  text-align: center;
  color: ${HighEmphasis};

  h1 {
    ${Bold32Styles}
    margin-bottom: 10px;
  }

  p {
    ${Regular16Styles}
  }

  @media (max-width: 1100px) {
    padding-block: 48px 64px;

    h1 {
      font-size: 24px;
    }

    p {
      ${Regular24Styles}
      width: 262px;
      font-size: 14px;
    }
  }
`;
