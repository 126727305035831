import infoIcon from 'assets/img/info.svg';
import timeIcon from 'assets/img/time.svg';
import attentionIcon from 'assets/img/attention.svg';

import { useMemo } from 'react';
import { InstructionContainer, MainContainer, Text, Title } from './styles';

interface TestInitialScreenProps {
  title: string;
  mainText: string;
  instructions: string[];
}

export const TestInitialScreen: React.FC<TestInitialScreenProps> = ({
  title,
  mainText,
  instructions,
}) => {
  const parsedInstructions = useMemo(() => {
    const iconsArray = [
      { name: 'Instruções', icon: infoIcon },
      { name: 'Tempo de realização', icon: timeIcon },
      { name: 'Importante!', icon: attentionIcon },
    ];

    return instructions.map((instruction, index) => ({
      instruction,
      ...iconsArray[index],
    }));
  }, [instructions]);

  return (
    <MainContainer>
      <Title>{title}</Title>
      <Text>{mainText}</Text>

      {parsedInstructions.map(({ name, icon, instruction }) => (
        <InstructionContainer key={name}>
          <div>
            <img src={icon} alt={name} />
            <span>{name}</span>
          </div>

          <Text>{instruction}</Text>
        </InstructionContainer>
      ))}
    </MainContainer>
  );
};
