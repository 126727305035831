import searchIcon from 'assets/img/search.svg';
import simpleArrowRight from 'assets/img/simpleArrowRight.svg';
import closeIcon from 'assets/img/close.svg';
import editIcon from 'assets/img/editIcon.svg';

import { CandidateResume } from 'components/CandidateResume';
import { Loader } from 'components/Loader';
import { useAuth } from 'hooks/auth';
import { useCandidate } from 'hooks/candidate';
import { useCallback, useEffect, useState } from 'react';
import {
  routeEditCandidateResume,
  routeFindVacancies,
} from 'routes/candidatesRoutes/candidatesRoutesAddresses';
import {
  findAllVacanciesByCandidateId,
  removeVacancyCandidateFromEvaluation,
} from 'services/entitiesServices/vacancyCandidateServices';
import { PrimaryButton, SecondaryButton } from 'components/Buttons';
import { useHistory } from 'react-router-dom';
import { useWindowWidth } from 'hooks/windowWidth';
import { useInputStates } from 'utils/customHooks';
import { OptionTypeBase } from 'react-select';
import { IVacancyCandidateWithVacancyData } from 'types/vacancyCandidate';
import { Modal } from 'components/Modal';
import { CandidateEvaluation } from 'types/CandidateEvaluation';
import { Select } from 'components/Select';
import {
  CandidatedVacancies,
  CandidateResumeContainer,
  Container,
  Header,
  Main,
  MobileButtons,
  MobileResumeHeader,
  MobileVacancyHighlightContainer,
  NoVacanciesContainer,
  OpenResumeButton,
  VacanciesContainer,
  WelcomeMessage,
} from './styles';
import { VacancyContainer } from './VacancyContainer';
import { GiveUpModal } from './GiveUpModal';
import { HighlightVacancyModal } from './HighlightVacancyModal';
import { VacancyHighlightContainer } from '../../../../../components/VacancyHighlightContainer';

export const CandidatedVacanciesTab: React.FC = () => {
  const { candidate } = useCandidate();
  const { id: candidateId } = candidate!;

  const { user } = useAuth();
  const { isMobileScreen } = useWindowWidth();
  const history = useHistory();

  const [idOfVacancyHighlighted, setIdOfVacancyHighlighted] = useState<
    number | null
  >(null);
  const [
    idOfCandidatedVacancyToGiveUpOnModal,
    setIdOfCandidatedVacancyToGiveUpOnModal,
  ] = useState<number | null>(null);
  const [
    isCandidateResumeVisibleOnMobile,
    setIsCandidateResumeVisibleOnMobile,
  ] = useState(false);
  const [isLoadingVacancies, setIsLoadingVacancies] = useState(true);

  const startupOptionStates = useInputStates('startupOption', '1');
  const [vacanciesStartupsOptions, setVacanciesStartupsOptions] = useState<
    OptionTypeBase[]
  >([]);

  const [selectedVacanciesIndexes, setSelectedVacanciesIndexes] = useState<
    number[]
  >([]);
  const [candidatedVacancies, setCandidatedVacancies] = useState<
    IVacancyCandidateWithVacancyData[]
  >([]);

  useEffect(() => {
    findAllVacanciesByCandidateId(candidateId).then(findedCandidacies => {
      setCandidatedVacancies(findedCandidacies);
      setIsLoadingVacancies(false);
    });
  }, [candidateId]);

  useEffect(() => {
    if (candidatedVacancies.length === 0) return;

    const candidatedVacanciesWithoutStartupRepetition =
      candidatedVacancies.filter((candidatedVacancy, index, arr) => {
        const {
          vacancy: {
            startup: { id: startupId },
          },
        } = candidatedVacancy;

        const findedIndex = arr.findIndex(
          ({
            vacancy: {
              startup: { id: iterationId },
            },
          }) => iterationId === startupId,
        );

        return index === findedIndex;
      });

    const startupsOptions = candidatedVacanciesWithoutStartupRepetition.map(
      candidatedVacancy => {
        const {
          vacancy: {
            startup: { id, name },
          },
        } = candidatedVacancy;

        return { id: id + 2, label: name };
      },
    );

    setVacanciesStartupsOptions([
      { id: 1, label: 'Todas as empresas' },
      ...startupsOptions,
    ]);
  }, [candidatedVacancies]);

  useEffect(() => {
    const selectedStartupOption = startupOptionStates.mainState.value;

    if (
      vacanciesStartupsOptions.length === 0 ||
      selectedStartupOption === '1'
    ) {
      setSelectedVacanciesIndexes(
        Array(candidatedVacancies.length)
          .fill(undefined)
          .map((_, index) => index),
      );
      return;
    }

    const vacanciesIndexes: number[] = [];
    candidatedVacancies.forEach((candidatedVacancy, index) => {
      const {
        vacancy: {
          startup: { id },
        },
      } = candidatedVacancy;

      if (id + 2 !== Number(selectedStartupOption)) return;
      vacanciesIndexes.push(index);
    });

    setSelectedVacanciesIndexes(vacanciesIndexes);
  }, [
    candidatedVacancies,
    startupOptionStates.mainState.value,
    vacanciesStartupsOptions.length,
  ]);

  const handleGiveUpVacancy = useCallback(async () => {
    if (idOfCandidatedVacancyToGiveUpOnModal === null) return;

    await removeVacancyCandidateFromEvaluation(
      idOfCandidatedVacancyToGiveUpOnModal,
    );

    setCandidatedVacancies(previousCandidatedVacancies =>
      previousCandidatedVacancies.map(candidatedVacancy => {
        if (candidatedVacancy.id !== idOfCandidatedVacancyToGiveUpOnModal) {
          return candidatedVacancy;
        }

        return {
          ...candidatedVacancy,
          candidateEvaluation: CandidateEvaluation.OUT_OF_EVALUATION,
        };
      }),
    );
  }, [idOfCandidatedVacancyToGiveUpOnModal]);

  if (candidate === undefined) return null;
  return (
    <Container>
      <Modal isVisible={idOfCandidatedVacancyToGiveUpOnModal !== null}>
        <GiveUpModal
          handleCloseModal={() => setIdOfCandidatedVacancyToGiveUpOnModal(null)}
          handleGiveUpVacancy={handleGiveUpVacancy}
        />
      </Modal>

      {isMobileScreen ? (
        <MobileVacancyHighlightContainer
          isVisibleOnMobile={idOfVacancyHighlighted !== null}
        >
          <MobileResumeHeader>
            <span>Descrição da vaga</span>

            <button
              type="button"
              onClick={() => setIdOfVacancyHighlighted(null)}
            >
              <img src={closeIcon} alt="Ícone de X" />
            </button>
          </MobileResumeHeader>

          <VacancyHighlightContainer
            vacancyIdToSerch={idOfVacancyHighlighted ?? undefined}
          />
        </MobileVacancyHighlightContainer>
      ) : (
        <Modal notBlockScroll isVisible={idOfVacancyHighlighted !== null}>
          <HighlightVacancyModal
            vacancyId={idOfVacancyHighlighted}
            handleCloseModal={() => setIdOfVacancyHighlighted(null)}
          />
        </Modal>
      )}

      <CandidatedVacancies>
        <Header>
          <h3>Olá, {`${user!.name} ${user!.lastName}`}</h3>
          <span>Visualize as vagas que você se candidatou</span>

          {isMobileScreen && (
            <MobileButtons>
              <OpenResumeButton
                type="button"
                onClick={() =>
                  setIsCandidateResumeVisibleOnMobile(value => !value)
                }
              >
                <span>Meu currículo</span>
                <img src={simpleArrowRight} alt="Seta para direita" />
              </OpenResumeButton>

              <PrimaryButton
                type="button"
                onClick={() => history.push(routeFindVacancies)}
              >
                <img src={searchIcon} alt="Lupa" />
              </PrimaryButton>
            </MobileButtons>
          )}
        </Header>

        {isLoadingVacancies ? (
          <Loader />
        ) : (
          <Main>
            {candidatedVacancies.length === 0 ? (
              <NoVacanciesContainer>
                <WelcomeMessage>
                  Seja bem-vindo(a) a{isMobileScreen && <br />} área do
                  candidato da IN6
                </WelcomeMessage>

                <p>
                  Na área do candidato você irá acompanhar o andamento de todos
                  os processos seletivos que estiver participando.
                  {!isMobileScreen ? <br /> : ' '}
                  Mas antes, busque oportunidades em nosso mural de vagas!
                </p>

                <PrimaryButton
                  type="button"
                  onClick={() => history.push(routeFindVacancies)}
                >
                  <img src={searchIcon} alt="Lupa" />
                  <span>Mural de vagas</span>
                </PrimaryButton>
              </NoVacanciesContainer>
            ) : (
              <>
                <Select
                  selectOptions={vacanciesStartupsOptions}
                  states={startupOptionStates}
                  style={{ fontSize: 16, height: 48 }}
                  notHasError
                />

                <VacanciesContainer>
                  {selectedVacanciesIndexes.map(
                    index =>
                      candidatedVacancies[index] && (
                        <VacancyContainer
                          key={candidatedVacancies[index].id}
                          candidatedVacancy={candidatedVacancies[index]}
                          showGiveUpModal={() =>
                            setIdOfCandidatedVacancyToGiveUpOnModal(
                              candidatedVacancies[index].id,
                            )
                          }
                          showHighlightModal={() =>
                            setIdOfVacancyHighlighted(
                              candidatedVacancies[index].vacancyId,
                            )
                          }
                        />
                      ),
                  )}
                </VacanciesContainer>
              </>
            )}
          </Main>
        )}
      </CandidatedVacancies>

      <CandidateResumeContainer
        isVisibleOnMobile={isCandidateResumeVisibleOnMobile}
      >
        {isMobileScreen && (
          <MobileResumeHeader>
            <span>Meu currículo</span>

            <button
              type="button"
              onClick={() => setIsCandidateResumeVisibleOnMobile(false)}
            >
              <img src={closeIcon} alt="Ícone de X" />
            </button>
          </MobileResumeHeader>
        )}

        <CandidateResume
          ComponentToReplaceSocialNetworks={
            <SecondaryButton
              type="button"
              onClick={() => history.push(routeEditCandidateResume)}
            >
              <img src={editIcon} alt="Atualizar currículo" />
              <span>Atualizar meu currículo</span>
            </SecondaryButton>
          }
          candidateData={candidate}
          userData={user!}
          style={{
            border: 'none',
            width: isMobileScreen ? '100%' : 420,
            paddingLeft: 0,
            paddingRight: isMobileScreen ? 0 : 20,
            paddingBlock: 30,
          }}
        />
      </CandidateResumeContainer>
    </Container>
  );
};
