import { ICity, ILocation } from 'types/location';
import { api } from '../api';

const servicesPrefix = 'city';

export const findAllCitiesByStateId = async (
  stateId: number,
): Promise<ICity[]> => {
  const { data } = await api.get<ICity[]>(
    `${servicesPrefix}/findAllByStateId/${stateId}`,
  );

  return data;
};

export const findCityByIdWithStateData = async (
  cityId: number,
): Promise<ILocation | undefined> => {
  const { data } = await api.get<ILocation | undefined>(
    `${servicesPrefix}/findByIdWithStateData/${cityId}`,
  );

  return data;
};
