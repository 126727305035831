import { ProfessionalProfileType } from 'types/candidate';
import { translateProfessionalProfileType } from 'utils/conversion';
import { PunctuationObject, ITestProperties } from '..';
import { ProfessionalProfileResultScreen } from './ProfessionalProfileResultScreen';
import { ProfessionalProfileInitialScreen } from './ProfessionalProfileInitialScreen';
import { PROFESSIONAL_PROFILE_QUESTIONS } from './questions';

const generatePunctuationObject = (): PunctuationObject => ({
  [ProfessionalProfileType.ANALYST]: 0,
  [ProfessionalProfileType.COMMUNICATOR]: 0,
  [ProfessionalProfileType.EXECUTOR]: 0,
  [ProfessionalProfileType.IDEALIZER]: 0,
});

const updatePunctuationObject = (
  punctuationObject: PunctuationObject,
  answerType?: ProfessionalProfileType,
  _?: boolean,
): PunctuationObject => {
  if (!answerType) return punctuationObject;

  const parsedObject = { ...punctuationObject };
  parsedObject[answerType]++;

  return parsedObject;
};

const generateTestResult = (
  punctuationObject: PunctuationObject,
): string | undefined => {
  const objectKeys = Object.keys(punctuationObject);
  const keyWithHigherValue = objectKeys.reduce((first, second) =>
    punctuationObject[first] > punctuationObject[second] ? first : second,
  );

  const indexOfAnotherKeyWithHigherValue = objectKeys.findIndex(
    key =>
      key !== keyWithHigherValue &&
      punctuationObject[key] === punctuationObject[keyWithHigherValue],
  );
  if (indexOfAnotherKeyWithHigherValue !== -1) return undefined;

  return translateProfessionalProfileType(
    keyWithHigherValue as ProfessionalProfileType,
  );
};

export const ProfessionalProfileProperties: ITestProperties<ProfessionalProfileType> =
  {
    questions: PROFESSIONAL_PROFILE_QUESTIONS,
    generatePunctuationObject,
    updatePunctuationObject,
    generateTestResult,
    InitialScreen: ProfessionalProfileInitialScreen,
    ResultScreen: ProfessionalProfileResultScreen,
  };
