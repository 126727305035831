import { BackgroundColor, MediumEmphasis } from 'assets/colors/palette';
import { Bold14Styles, Regular14Styles } from 'assets/fonts/fontStyles';
import styled from 'styled-components';

export const StepDataInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: ${MediumEmphasis};

  #images-counter {
    ${Bold14Styles}
  }

  #description {
    ${Regular14Styles}
    font-size: 12px;
  }
`;

export const GalleryContainer = styled.div`
  display: flex;
  flex-flow: wrap;
  width: 500px;
  gap: 24px;
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 238px;
  height: 238px;

  button {
    top: 16px;
    right: 16px;
    position: absolute;

    padding: 12px 32px;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: ${BackgroundColor};
    border-radius: 100px;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 8px;
`;
