import { CandidateEvaluation } from 'types/CandidateEvaluation';
import {
  IFiledStartupCandidateBasicData,
  IFiledStartupCandidateRequest,
  IFiledStartupCandidateResponse,
  IFiledStartupCandidateResponseWithoutFolder,
} from 'types/filedStartupCandidate';
import { IFiledStartupCandidateRequestWithCandidateDirectly } from 'types/filedStartupCandidate/IFiledStartupCandidateRequestWithCandidateDirectly';
import { api } from '../api';

const servicesPrefix = 'filedStartupCandidate';

export const createFiledStartupCandidate = async (
  createFiledStartupCandidateData: IFiledStartupCandidateRequest,
): Promise<IFiledStartupCandidateBasicData> => {
  const { data } = await api.post<IFiledStartupCandidateBasicData>(
    `${servicesPrefix}`,
    createFiledStartupCandidateData,
  );

  return data;
};

export const createFiledStartupCandidateWithCandidateDirectly = async (
  createFiledStartupCandidateData: IFiledStartupCandidateRequestWithCandidateDirectly,
): Promise<IFiledStartupCandidateBasicData> => {
  const { data } = await api.post<IFiledStartupCandidateBasicData>(
    `${servicesPrefix}/createWithCandidateDirectly`,
    createFiledStartupCandidateData,
  );

  return data;
};

export const findOneFiledStartupCandidateById = async (
  filedStartupCandidateId: number,
): Promise<IFiledStartupCandidateResponse | undefined> => {
  const { data } = await api.get<IFiledStartupCandidateResponse | undefined>(
    `${servicesPrefix}/findOneById/${filedStartupCandidateId}`,
  );

  return data;
};

export const findAllFiledStartupCandidatesByStartupCandidatesFolderId = async (
  startupCandidatesFolderId: number,
): Promise<IFiledStartupCandidateResponseWithoutFolder[]> => {
  const url = `${servicesPrefix}/findAllByStartupCandidatesFolderId/${startupCandidatesFolderId}`;
  const { data } = await api.get<IFiledStartupCandidateResponseWithoutFolder[]>(
    url,
  );

  return data;
};

export const updateFiledStartupCandidate = async (
  filedStartupCandidateId: number,
  updatedCandidateData: { candidateEvaluation: CandidateEvaluation },
): Promise<IFiledStartupCandidateBasicData> => {
  const { data } = await api.put<IFiledStartupCandidateBasicData>(
    `${servicesPrefix}/${filedStartupCandidateId}`,
    updatedCandidateData,
  );

  return data;
};

export const deleteFiledStartupCandidate = async (
  filedStartupCandidateId: number,
): Promise<void> => {
  await api.delete(`${servicesPrefix}/${filedStartupCandidateId}`);
};
