import { OmitId } from 'types';
import { OmitCreatedAndUpdatedAt } from 'types/OmitCreatedAndUpdatedAt';
import { IVacancyCustomStep } from 'types/vacancy';
import { api } from '../api';

const servicesPrefix = 'vacancyCustomStep';

export const createVacancyCustomStep = async (
  crateCustomStepData: OmitId<OmitCreatedAndUpdatedAt<IVacancyCustomStep>>,
): Promise<IVacancyCustomStep> => {
  const { data } = await api.post<IVacancyCustomStep>(
    `${servicesPrefix}`,
    crateCustomStepData,
  );

  return data;
};

export const findOneVacancyCustomStepById = async (
  vacancyCustomStepId: number,
): Promise<IVacancyCustomStep | undefined> => {
  const { data } = await api.get<IVacancyCustomStep | undefined>(
    `${servicesPrefix}/findOneById/${vacancyCustomStepId}`,
  );

  return data;
};

export const findAllCustomStepsByVacancyId = async (
  vacancyCustomStepId: number,
): Promise<IVacancyCustomStep[]> => {
  const { data } = await api.get<IVacancyCustomStep[]>(
    `${servicesPrefix}/findAllByVacancyId/${vacancyCustomStepId}`,
  );

  return data;
};

export const deleteVacancyCustomStep = async (
  vacancyCustomStepId: number,
): Promise<void> => {
  await api.delete(`${servicesPrefix}/${vacancyCustomStepId}`);
};
