import * as yup from 'yup';
import in6Logo from 'assets/img/in6Logo.svg';

import { PrimaryButton } from 'components/Buttons';
import { Input } from 'components/Input';
import { CheckboxInput } from 'components/Input/CheckboxInput';
import { useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  routeAdminLogin,
  routeBar,
  routeForgotPassword,
  routeSignUp,
} from 'routes/routesAddresses';
import { generateFormObjectFromStates } from 'utils';
import { yupRequiredStringField } from 'utils/validation';
import { IAuthenticateUserRequest, UserAccountType } from 'types/user';
import { useInputStates } from 'utils/customHooks';
import { Header } from 'components/Header';
import { authenticateUser } from 'services/entitiesServices/userServices';
import { redirectToConfirmAuthPageBasedOnUserAccountType } from 'utils/redirectToConfirmAuthPageBasedOnUserAccountType';
import { ERROR_MODAL_CONFIGURATION, useAlertModal } from 'hooks/alertModal';
import { useFormError } from 'errors/useFormError';
import { IServerError } from 'types';
import { DotFlashing } from 'components/DotFlashing';
import { useWindowWidth } from 'hooks/windowWidth';
import { Container, SubTitle, Title, TitleContainer } from './styles';

export const LoginPage: React.FC = () => {
  const history = useHistory();
  const { isMobileScreen } = useWindowWidth();
  const isAdminRoute = history.location.pathname.endsWith(routeAdminLogin);

  const { showModal } = useAlertModal();
  const { handleFormError } = useFormError();

  const emailStates = useInputStates('email');
  const passwordStates = useInputStates('password');
  const [keepConnected, setKeepConnected] = useState(false);
  const [isLoadingLogin, setIsLoadingLogin] = useState(false);

  const handleSubmit = useCallback(async () => {
    setIsLoadingLogin(true);
    const formStates = [emailStates, passwordStates];
    const formObject = generateFormObjectFromStates(formStates);
    const adminErrorMessage = 'Não é possível fazer esse login aqui.';

    try {
      const schema = yup.object().shape({
        email: yupRequiredStringField.email('Formato de e-mail inválido'),
        password: yupRequiredStringField,
      });

      await schema.validate(formObject, { abortEarly: false });
      const { token, user } = await authenticateUser({
        ...formObject,
        keepConnected,
      } as IAuthenticateUserRequest);
      if (!isAdminRoute && user.accountType === UserAccountType.ADMIN) {
        throw new Error(adminErrorMessage);
      }

      redirectToConfirmAuthPageBasedOnUserAccountType(
        token,
        keepConnected,
        user,
      );
    } catch (error) {
      setIsLoadingLogin(false);

      if (error instanceof yup.ValidationError) {
        handleFormError(error as Error | yup.ValidationError, formStates);
        return;
      }

      if ((error as Error).message === adminErrorMessage) {
        showModal({
          ...ERROR_MODAL_CONFIGURATION,
          message: adminErrorMessage,
        });

        emailStates.mainState.setFunction('');
        passwordStates.mainState.setFunction('');
        return;
      }

      const {
        response: {
          data: { status },
        },
      } = error as IServerError;

      if (status === 400) {
        showModal({
          ...ERROR_MODAL_CONFIGURATION,
          message: 'Email e/ou senha inválidos',
        });

        emailStates.errorMessageState.setFunction('Campo inválido');
        passwordStates.errorMessageState.setFunction('Campo inválido');
        return;
      }

      if (status === 403) {
        showModal({
          ...ERROR_MODAL_CONFIGURATION,
          message: 'A conta informada ainda não efetuou a ativação',
        });
      }
    }
  }, [
    emailStates,
    handleFormError,
    isAdminRoute,
    keepConnected,
    passwordStates,
    showModal,
  ]);

  return (
    <>
      <Header logo={in6Logo} redirectRoute={routeBar} />

      <Container>
        <TitleContainer>
          <Title>Acesse sua conta</Title>
          <SubTitle>
            Ainda não possui uma conta?{' '}
            <button type="button" onClick={() => history.push(routeSignUp)}>
              Crie agora
            </button>
          </SubTitle>
        </TitleContainer>

        <Input name="Email" placeholder="inserir email" states={emailStates} />

        <Input
          name="Senha"
          placeholder="inserir senha"
          type="password"
          states={passwordStates}
        />

        <div id="keepConnectedAndForgotPassword">
          <CheckboxInput
            handleCheckboxUpdate={checked => setKeepConnected(checked)}
            label="Manter conectado"
          />

          {!isMobileScreen && (
            <Link to={routeForgotPassword}>Esqueceu a senha?</Link>
          )}
        </div>

        <PrimaryButton
          type="submit"
          onClick={handleSubmit}
          disabled={isLoadingLogin}
        >
          {isLoadingLogin ? (
            <>
              Carregando
              <DotFlashing />
            </>
          ) : (
            'Entrar agora'
          )}
        </PrimaryButton>

        {isMobileScreen && (
          <Link to={routeForgotPassword}>Esqueceu a senha?</Link>
        )}
      </Container>
    </>
  );
};
