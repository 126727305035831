import * as yup from 'yup';

import { yupObjectWithId } from 'utils/validation/yupObjectWithId';
import { LocationStepData } from '.';

export const validateLocationData = async (
  locationData: LocationStepData,
): Promise<void> => {
  const schema = yup.object().shape({
    startupLocations: yup
      .array(
        yup.object().shape({
          state: yupObjectWithId,
          city: yupObjectWithId,
        }),
      )
      .min(1, 'Necessário informar ao menos um endereço'),
  });

  await schema.validate(locationData, { abortEarly: false });
};
