import { formStepInputStyles } from 'assets/styles/formWithStepsStyles/formWithStepsInputStyles';

import { PlusButton } from 'components/Buttons';
import { Input } from 'components/Input';
import { useCallback, useEffect, useState } from 'react';
import { IFormStepProps } from 'types/IFormStepProps';
import { IVacancySkill } from 'types/vacancy/IVacancySkill';
import { useInputStates } from 'utils/customHooks';

import {
  StepSubTitle,
  StepTitle,
  TitleContainer,
} from 'assets/styles/formWithStepsStyles/formWithStepsTitlesStyles';
import { FormWithStepsContainer } from 'assets/styles/formWithStepsStyles/formWithStepsContainerStyles';
import { OmitId } from 'types';
import { stringsAreEqual } from 'utils';
import { InputsContainer, SkillsContainer } from './styles';
import { SkillContainer } from './SkillContainer';

export { validateSkillsData } from './validateSkillsData';

export interface SkillsStepData {
  skills: OmitId<IVacancySkill>[];
}

export const SkillsStep: React.FC<IFormStepProps<SkillsStepData>> = ({
  initialData = {} as SkillsStepData,
  setStepDataFunction,
}) => {
  const { skills: initialSkills } = initialData;

  const skillInputState = useInputStates('skillInput');
  const [skills, setSkills] = useState<OmitId<IVacancySkill>[]>(
    initialSkills ?? [],
  );

  const findSkillIndexByName = useCallback(
    (skillName: string) =>
      skills.findIndex(({ name }) => stringsAreEqual(name, skillName)),
    [skills],
  );

  const handleAddSkill = useCallback(() => {
    const { value, setFunction } = skillInputState.mainState;
    if ((value as string).length === 0) return;

    if (findSkillIndexByName(value as string) !== -1)
      skillInputState.errorMessageState.setFunction('Habilidade já adicionada');
    else {
      setSkills(previousSkills => [
        ...previousSkills,
        {
          name: value as string,
          description: '',
        },
      ]);
    }

    setFunction('');
  }, [
    findSkillIndexByName,
    skillInputState.errorMessageState,
    skillInputState.mainState,
  ]);

  const handleExcludeSkill = useCallback((skillName: string) => {
    setSkills(previousSkills =>
      previousSkills.filter(
        comparativeSkill => comparativeSkill.name !== skillName,
      ),
    );
  }, []);

  const handleChangeSkillDescription = useCallback(
    (skillName: string, updatedDescription: string) => {
      setSkills(previousSkills =>
        previousSkills.map(previousSkill => {
          if (previousSkill.name !== skillName) return previousSkill;

          return {
            name: skillName,
            description: updatedDescription,
          };
        }),
      );
    },
    [],
  );

  useEffect(() => {
    if (setStepDataFunction) setStepDataFunction({ skills });
  }, [skills, setStepDataFunction]);

  return (
    <FormWithStepsContainer>
      <TitleContainer>
        <StepTitle>Habilidades comportamentais</StepTitle>
        <StepSubTitle>
          Escolha até 4 habilidades interpessoais que os seus candidatos
          precisam ter para trabalhar nesta vaga
        </StepSubTitle>
      </TitleContainer>

      <InputsContainer>
        <span>{skills?.length ?? 0}/4 habilidades selecionadas</span>

        <Input
          disabled={skills?.length === 4}
          states={skillInputState}
          placeholder="por exemplo, Planejamento"
          style={formStepInputStyles}
        />

        <PlusButton
          onClick={handleAddSkill}
          disabled={!skillInputState.mainState.value || skills?.length === 4}
          colorStyle="outline"
        >
          Adicionar
        </PlusButton>
      </InputsContainer>

      <SkillsContainer>
        {skills.map(skill => (
          <SkillContainer
            key={skill.name}
            skill={skill}
            handleExcludeSkill={() => handleExcludeSkill(skill.name)}
            handleChangeSkillDescription={updatedDescription =>
              handleChangeSkillDescription(skill.name, updatedDescription)
            }
          />
        ))}
      </SkillsContainer>
    </FormWithStepsContainer>
  );
};
