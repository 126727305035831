import {
  IVacancyDTOForMultipleVacanciesWithoutStartup,
  IVacancyResponse,
  VacancyStatus,
} from 'types/vacancy';
import { getVacancyDateGapFromActualDate } from './getVacancyDateGapFromActualDate';

export const getVacancyDateStatusString = ({
  vacancyStatus,
  createdAt,
  closeDate,
}:
  | IVacancyResponse
  | IVacancyDTOForMultipleVacanciesWithoutStartup): string => {
  const isClosed = vacancyStatus === VacancyStatus.CLOSED;

  const initialTextPart = isClosed ? 'Encerrada' : 'Publicada';
  const finalTextPart = getVacancyDateGapFromActualDate(
    isClosed ? (closeDate as Date) : createdAt,
  );

  return `${initialTextPart} ${finalTextPart}`;
};
