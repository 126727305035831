import { MediumEmphasis } from 'assets/colors/palette';
import { Bold14Styles } from 'assets/fonts/fontStyles';
import styled from 'styled-components';

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 4px;

  & > span {
    ${Bold14Styles}
    color: ${MediumEmphasis};

    text-align: start;
    width: 100%;
    margin-bottom: 40px;
  }
`;

export const SkillsContainer = styled.div`
  width: 500px;
  padding-bottom: 60px;

  display: flex;
  flex-flow: wrap;
  gap: 46px;
`;
