import {
  Bold16Styles,
  Bold32Styles,
  Medium14Styles,
} from 'assets/fonts/fontStyles';
import styled from 'styled-components';

export const Container = styled.div`
  width: 752px;
  padding-block: 70px;
  margin-inline: auto;
`;

export const TitleContainer = styled.div`
  text-align: center;
  margin-bottom: 120px;

  h1 {
    ${Bold32Styles}
    line-height: 34px;
  }

  h4 {
    ${Bold16Styles}
    margin-block: 14px 10px;
  }

  span {
    ${Medium14Styles}
    line-height: 24px;
  }
`;
