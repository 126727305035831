import styled from 'styled-components';
import { HighEmphasis } from 'assets/colors/palette';
import { Bold32Styles } from 'assets/fonts/fontStyles';
import { PrimaryButton } from 'components/Buttons';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-inline: auto;

  width: 1200px;
  margin-top: 12%;

  ${PrimaryButton} {
    width: 400px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    & > div {
      margin-top: 4px;
    }
  }
`;

export const Title = styled.div`
  ${Bold32Styles};
  line-height: 34px;
  color: ${HighEmphasis};
  margin-bottom: 30px;
  text-align: center;
`;
