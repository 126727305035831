import thinPlus from 'assets/img/thinPlus.svg';
import { Loader } from 'components/Loader';
import { ERROR_MODAL_CONFIGURATION, useAlertModal } from 'hooks/alertModal';
import {
  HTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ReactInputState } from 'types/reactInputState';
import { Container, PreviewImage, NoImageContainer } from './styles';

interface Dimensions {
  width: number;
  height: number;
}

interface ImageInputProps extends HTMLAttributes<HTMLDivElement> {
  states: ReactInputState;
  minimumImageDimensions: Dimensions;
  alternativeRef?: React.RefObject<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
}

export const ImageInput: React.FC<ImageInputProps> = ({
  states: {
    mainState: { setFunction: mainFunction, value: mainValue },
    errorMessageState: { setFunction: errorFunction },
  },
  alternativeRef,
  onChange,
  minimumImageDimensions,
  style,
  ...props
}) => {
  const { showModal } = useAlertModal();

  const inputRef = useRef<HTMLInputElement>(null);
  const imageType = 'image/png, image/gif, image/jpeg';
  const imageInputRef = alternativeRef ?? inputRef;
  const [isLoadingImage, setIsLoadingImage] = useState(false);

  const resetImageInput = useCallback(() => {
    const { current } = imageInputRef;
    if (current) current.value = '';
  }, [imageInputRef]);

  const handleOnChangeImage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      errorFunction('');
      const {
        target: { files },
      } = event;
      if (files === null) return;

      setIsLoadingImage(true);
      const [file] = files;
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        const image = new Image();
        image.src = reader.result as string;

        image.onload = () => {
          setIsLoadingImage(false);
          const { width, height } = image;
          const { width: minimumWidth, height: minimumHeight } =
            minimumImageDimensions;

          if (width < minimumWidth || height < minimumHeight) {
            URL.revokeObjectURL(image.src);

            showModal({
              ...ERROR_MODAL_CONFIGURATION,
              marginTop: -80,
              message:
                `Imagem enviada não cumpre as dimensões mínimas ` +
                `de ${minimumWidth}x${minimumHeight}px`,
            });
            return;
          }

          if (onChange) {
            onChange(event);
            resetImageInput();
          } else mainFunction(image.src);
        };
      };
    },
    [
      errorFunction,
      mainFunction,
      minimumImageDimensions,
      onChange,
      resetImageInput,
      showModal,
    ],
  );

  useEffect(() => {
    if (!mainValue) resetImageInput();
  }, [mainValue, resetImageInput]);

  return (
    <Container
      style={{ ...style, cursor: isLoadingImage ? 'auto' : 'pointer' }}
      onClick={() => imageInputRef.current?.click()}
      {...props}
    >
      {isLoadingImage ? (
        <Loader
          style={{
            width: 100,
            height: 100,
            minHeight: 0,
            background: 'transparent',
            marginTop: 100,
          }}
        />
      ) : (
        <>
          <input
            type="file"
            ref={imageInputRef}
            onChange={handleOnChangeImage}
            accept={imageType}
          />

          {mainValue ? (
            <PreviewImage src={mainValue} alt="Imagem recebida" />
          ) : (
            <NoImageContainer>
              <img src={thinPlus} alt="Símbolo de adição" />
              <span>Adicionar foto</span>
            </NoImageContainer>
          )}
        </>
      )}
    </Container>
  );
};
