import searchIcon from 'assets/img/search.svg';
import closeOne from 'assets/img/closeOne.svg';
import localTwo from 'assets/img/localTwo.svg';
import immediateHiringIcon from 'assets/img/immediateHiring.svg';
import needExperienceIcon from 'assets/img/needExperience.svg';
import disabledPeopleIcon from 'assets/img/disabledPeople.svg';
import forStudentsIcon from 'assets/img/forStudents.svg';
import deleteTwo from 'assets/img/deleteTwo.svg';
import filterOptionsIcon from 'assets/img/filterOptions.svg';
import closeIcon from 'assets/img/close.svg';

import { Switch } from 'components/Switch';
import { Loader } from 'components/Loader';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { COMPANY_NAME } from 'constants/company';
import { PrimaryButton } from 'components/Buttons';
import { findAllAvailableVacancies } from 'services/entitiesServices/vacancyServices';
import { jobTypeOptions } from 'utils/typeOptions';
import {
  convertNameToSnakeCase,
  convertStringSalaryToNumber,
  formatNumberToBRCurrency,
} from 'utils/conversion';

import { getParsedVacancySalaryInformation } from 'utils/conversion/getParsedVacancySalaryInformation';
import { IVacancyDTOForMultipleVacancies } from 'types/vacancy';
import { convertCityAndStateFromObjectToString } from 'utils/conversion/convertCityAndStateFromObjectToString';
import { useInputStates } from 'utils/customHooks';
import { Select } from 'components/Select';
import { businessSectorOptions } from 'utils/typeOptions/businessSectorOptions';
import { StartupExhibitionDataWithVacanciesQuantityDTO } from 'types/startup/StartupExhibitionDataWithVacanciesQuantityDTO';
import { getTenStartupsWithMostVacancies } from 'services/entitiesServices/startupServices';
import { baseURLFrontend } from 'services/baseUrl';
import { CandidatesFreeRoutesHeader } from 'components/CandidatesFreeRoutesHeader';
import { routeVacancyApply } from 'routes/candidatesRoutes/candidatesRoutesAddresses';
import { useWindowWidth } from 'hooks/windowWidth';
import {
  VacancyJobTypesContainer,
  VacancyJobButton,
  SearchVacancyContainer,
  SearchContainer,
  SearchInput,
  MainContainer,
  FiltersContainer,
  SalaryFilter,
  OtherFilters,
  VacanciesContainer,
  ClearSearchResultsButton,
  Vacancy,
  VacancyTopData,
  VacancyBottomData,
  VacancyAspects,
  VacancyExtraAspects,
  CompaniesHiringContainer,
  CompanyHiring,
  OpenMobileFilterOptionsButton,
} from './styles';

interface PageTitles {
  mainTitle: string;
  subTitle: string;
}

const parsedjobTypeOptions = [
  'Todas',
  ...jobTypeOptions.map(({ label }) => label),
];

export const FeedVacancies: React.FC = () => {
  document.title = `${COMPANY_NAME} Vagas`;
  const {
    location: { pathname },
  } = window;
  const isDashboardRoute = pathname !== '/';
  const { isMobileScreen } = useWindowWidth();

  const [isFiltersVisibleOnMobile, setIsFiltersVisibleOnMobile] =
    useState(false);
  const [searchedName, setSearchedName] = useState('');
  const searchNameInput = useRef<HTMLInputElement>(null);

  const [searchedCity, setSearchedCity] = useState('');
  const searchCityInput = useRef<HTMLInputElement>(null);

  const [findedVacancies, setFindedVacancies] = useState<
    IVacancyDTOForMultipleVacancies[]
  >([]);
  const [searchedVacancies, setSearchedVacancies] = useState<
    IVacancyDTOForMultipleVacancies[]
  >([]);
  const [filteredVacancies, setFilteredVacancies] = useState<
    IVacancyDTOForMultipleVacancies[]
  >([]);

  const businessSectorStates = useInputStates('businessSector');
  const [selectedJobTypeOption, setSelectedJobTypeOption] = useState(0);
  const [salaryFilterValue, setSalaryFilterValue] = useState(0);
  const [userHasSearched, setUserHasSearched] = useState(false);

  const [isLoadingVacancies, setIsLoadingVacancies] = useState(true);
  const [
    isLoadingStartupsWithMostVacancies,
    setIsLoadingStartupsWithMostVacancies,
  ] = useState(true);
  const [startupsWithMostVacancies, setStartupsWithMostVacancies] = useState<
    StartupExhibitionDataWithVacanciesQuantityDTO[]
  >([]);

  const [onlyRemoteVacancies, setOnlyRemoteVacancies] = useState(false);
  const [immediateHiring, setImmediateHiring] = useState(false);
  const [needExperience, setNeedExperience] = useState(false);
  const [disabledPeople, setDisabledPeople] = useState(false);
  const [forStudents, setForStudents] = useState(false);

  const [{ mainTitle, subTitle }, setPageTitles] = useState<PageTitles>({
    mainTitle: 'Encontre um novo emprego',
    subTitle: 'Procure vagas nas empresas que usam a IN6',
  });

  useEffect(() => {
    findAllAvailableVacancies().then(vacancies => {
      setFindedVacancies(vacancies);
      setSearchedVacancies(vacancies);
      setIsLoadingVacancies(false);
    });
  }, []);

  useEffect(() => {
    getTenStartupsWithMostVacancies().then(startups => {
      setStartupsWithMostVacancies(startups);
      setIsLoadingStartupsWithMostVacancies(false);
    });
  }, []);

  useEffect(() => {
    if (!userHasSearched) {
      setPageTitles({
        mainTitle: 'Encontre um novo emprego',
        subTitle: 'Procure vagas nas empresas que usam a IN6',
      });

      return;
    }

    const { length } = searchedVacancies;

    let parsedVacancyText = 'vaga';
    let parsedAvailabilityText = 'disponíve';
    if (length === 1) parsedAvailabilityText += 'l';
    else {
      parsedVacancyText += 's';
      parsedAvailabilityText += 'is';
    }

    if (!searchedName) {
      setPageTitles({
        mainTitle: searchedCity,
        subTitle:
          `Há ${length} ${parsedVacancyText} ` +
          `${parsedAvailabilityText} em ${searchedCity}`,
      });

      return;
    }

    const pageSubTitle = [
      `Temos ${length} ${parsedVacancyText} de`,
      `${searchedName} ${parsedAvailabilityText} para`,
      `${searchedCity || 'você'}`,
    ];

    setPageTitles({
      mainTitle: searchedName,
      subTitle: pageSubTitle.join(' '),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userHasSearched, searchedVacancies]);

  const verifySearchFilters = useCallback(
    (vacancy: IVacancyDTOForMultipleVacancies): boolean => {
      if (onlyRemoteVacancies) {
        const vacancyIsRemote = vacancy.workloadType.type === 'Remoto';
        if (!vacancyIsRemote) return false;
      }

      if (salaryFilterValue) {
        const { salary, salaryToNegotiate } = vacancy;

        const verifySalary =
          salaryToNegotiate ||
          convertStringSalaryToNumber(salary ?? '0') >= salaryFilterValue;
        if (!verifySalary) return false;
      }

      const businessSectorValue = Number(businessSectorStates.mainState.value);
      if (businessSectorValue) {
        const verifyBusinessSectorType = vacancy.startup.businessSectors.find(
          ({ id }) => id === businessSectorValue,
        );

        if (!verifyBusinessSectorType) return false;
      }

      const filtersArray = [
        ['immediateHiring', immediateHiring],
        ['needExperience', needExperience],
        ['disabledPeople', disabledPeople],
        ['forStudents', forStudents],
      ];

      const verifyFilters = filtersArray.findIndex(
        ([filterName, value]) =>
          value &&
          !vacancy[filterName as keyof IVacancyDTOForMultipleVacancies],
      );

      return verifyFilters === -1;
    },
    [
      businessSectorStates.mainState.value,
      disabledPeople,
      forStudents,
      immediateHiring,
      needExperience,
      onlyRemoteVacancies,
      salaryFilterValue,
    ],
  );

  const verifySearchFields = useCallback(
    ({
      name: vacancyName,
      startup: { name: startupName },
      id,
      vacancyLocation,
    }: IVacancyDTOForMultipleVacancies): boolean => {
      if (searchedName) {
        const lowerName = searchedName.toLowerCase();
        const lowerVacancyName = vacancyName.toLowerCase();
        const companyName = startupName.toLowerCase();
        const parsedVacancyId = id + 1000;

        const verifyName =
          lowerVacancyName.includes(lowerName) ||
          companyName?.includes(lowerName) ||
          parsedVacancyId === Number(searchedName);
        if (!verifyName) return false;
      }

      const cityName = vacancyLocation?.city.name;
      if (cityName) {
        const parsedCityName = cityName.toLowerCase();
        const verifyCity = parsedCityName.includes(searchedCity.toLowerCase());
        if (!verifyCity) return false;
      }

      return true;
    },
    [searchedName, searchedCity],
  );

  const getJobTypeVacancies = useCallback(() => {
    const getFilteredVacanciesByJobType = () => {
      return findedVacancies.filter(
        vacancy =>
          vacancy.jobType.type === parsedjobTypeOptions[selectedJobTypeOption],
      );
    };

    return selectedJobTypeOption === 0
      ? findedVacancies
      : getFilteredVacanciesByJobType();
  }, [findedVacancies, selectedJobTypeOption]);

  const handleSearchVacancies = useCallback(
    (fieldSearch?: boolean) => {
      const jobTypeVacancies = getJobTypeVacancies();

      setSearchedVacancies(
        jobTypeVacancies.filter(vacancy => verifySearchFields(vacancy)),
      );
      if (fieldSearch !== undefined) setUserHasSearched(fieldSearch);
    },
    [getJobTypeVacancies, verifySearchFields],
  );

  useEffect(() => {
    handleSearchVacancies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getJobTypeVacancies, selectedJobTypeOption]);

  useEffect(() => {
    setFilteredVacancies(
      searchedVacancies.filter(vacancy => verifySearchFilters(vacancy)),
    );
  }, [searchedVacancies, verifySearchFilters]);

  return (
    <>
      {!isDashboardRoute && <CandidatesFreeRoutesHeader />}

      <SearchVacancyContainer>
        <canvas />

        <SearchContainer>
          <SearchInput onClick={() => searchNameInput.current?.focus()}>
            <img
              src={searchIcon}
              alt="Lupa de pesquisa"
              style={{ filter: 'brightness(0.6)' }}
            />
            <input
              ref={searchNameInput}
              placeholder="Nome da vaga ou empresa"
              type="text"
              onKeyPress={({ code }) =>
                code === 'Enter' &&
                handleSearchVacancies(!!searchedName || !!searchedCity)
              }
              value={searchedName}
              onChange={({ target: { value } }) => setSearchedName(value)}
            />

            {searchedName && (
              <button type="button" onClick={() => setSearchedName('')}>
                <img src={closeOne} alt="Apagar nome" />
              </button>
            )}
          </SearchInput>

          <SearchInput onClick={() => searchCityInput.current?.focus()}>
            <img src={localTwo} alt="Localização" />
            <input
              ref={searchCityInput}
              placeholder="Nome da cidade"
              type="text"
              onKeyPress={({ code }) =>
                code === 'Enter' &&
                handleSearchVacancies(!!searchedName || !!searchedCity)
              }
              value={searchedCity}
              onChange={({ target: { value } }) => setSearchedCity(value)}
            />

            {searchedCity && (
              <button type="button" onClick={() => setSearchedCity('')}>
                <img src={closeOne} alt="Apagar Localização" />
              </button>
            )}
          </SearchInput>

          <div style={{ position: 'relative', height: 64 }}>
            <PrimaryButton
              type="button"
              onClick={() =>
                handleSearchVacancies(!!searchedName || !!searchedCity)
              }
            >
              <span>Buscar vaga</span>
            </PrimaryButton>

            {isMobileScreen && (
              <OpenMobileFilterOptionsButton
                type="button"
                onClick={() => setIsFiltersVisibleOnMobile(value => !value)}
              >
                <img src={filterOptionsIcon} alt="Abrir opções de filtos" />
              </OpenMobileFilterOptionsButton>
            )}
          </div>
        </SearchContainer>

        {!isMobileScreen && (
          <div className="title">
            <h1>{mainTitle}</h1>
            <p>{subTitle}</p>
          </div>
        )}
      </SearchVacancyContainer>

      <VacancyJobTypesContainer>
        {parsedjobTypeOptions.map((jobTypeOption, index) => (
          <VacancyJobButton
            type="button"
            key={jobTypeOption}
            isActivated={index === selectedJobTypeOption}
            onClick={() => setSelectedJobTypeOption(index)}
          >
            {jobTypeOption}
          </VacancyJobButton>
        ))}
      </VacancyJobTypesContainer>

      <MainContainer>
        <FiltersContainer isFiltersVisibleOnMobile={isFiltersVisibleOnMobile}>
          <div className="container-name">
            <span>Pesquisa avançada</span>
            {isMobileScreen && (
              <button
                type="button"
                onClick={() => setIsFiltersVisibleOnMobile(false)}
              >
                <img src={closeIcon} alt="Fechar pesquisa" />
              </button>
            )}
          </div>

          <Switch
            name="Apenas remoto"
            state={[onlyRemoteVacancies, setOnlyRemoteVacancies]}
          />

          <SalaryFilter barPercentage={salaryFilterValue / 200}>
            <p>Remuneração mínima</p>
            <div className="value-container">
              <p>R$ {formatNumberToBRCurrency(salaryFilterValue)}</p>
            </div>
            <input
              type="range"
              step={500}
              min="0"
              max="20000"
              value={salaryFilterValue}
              onChange={({ target: { value } }) =>
                setSalaryFilterValue(Number(value))
              }
            />
          </SalaryFilter>

          <Select
            name="Setor"
            placeholder="selecionar setor"
            selectOptions={[
              { id: 0, label: 'Todos' },
              ...businessSectorOptions,
            ]}
            states={businessSectorStates}
            startWithoutValue
            notHasError
          />

          <OtherFilters>
            <Switch
              name="Contratação imediata"
              state={[immediateHiring, setImmediateHiring]}
            />
            <Switch
              name="Requer experiência"
              state={[needExperience, setNeedExperience]}
            />
            <Switch
              name="Para PcD"
              state={[disabledPeople, setDisabledPeople]}
            />
            <Switch
              name="Para estudantes"
              state={[forStudents, setForStudents]}
            />
          </OtherFilters>
        </FiltersContainer>

        <VacanciesContainer>
          {userHasSearched && (
            <ClearSearchResultsButton
              type="button"
              onClick={() => {
                setSearchedName('');
                setSearchedCity('');
                setUserHasSearched(false);

                if (selectedJobTypeOption === 0) {
                  setSearchedVacancies(findedVacancies);
                } else setSelectedJobTypeOption(0);
              }}
            >
              <img src={deleteTwo} alt="Limpar pesquisa" />
              <p>Limpar resultados da pesquisa</p>
            </ClearSearchResultsButton>
          )}

          {isLoadingVacancies ? (
            <Loader />
          ) : (
            filteredVacancies.map(vacancy => (
              <Vacancy
                key={vacancy.id}
                to={`${isDashboardRoute ? routeVacancyApply : ''}/${
                  1000 + vacancy.id
                }`}
                target="_blank"
              >
                <VacancyTopData>
                  <div className="company-data">
                    <img src={vacancy.startup.logo} alt="Logo da Startup" />
                    <p>{vacancy.startup.name}</p>
                  </div>

                  <VacancyExtraAspects>
                    {vacancy.immediateHiring && (
                      <i title="Contratação imediata">
                        <img
                          src={immediateHiringIcon}
                          alt="Contratação imediata"
                        />
                      </i>
                    )}
                    {vacancy.needExperience && (
                      <i title="Requer experiência">
                        <img
                          src={needExperienceIcon}
                          alt="Requer experiência"
                        />
                      </i>
                    )}
                    {vacancy.disabledPeople && (
                      <i title="Para pessoas com deficiência">
                        <img
                          src={disabledPeopleIcon}
                          alt="Para pessoas com deficiência"
                        />
                      </i>
                    )}
                    {vacancy.forStudents && (
                      <i title="Para estudantes">
                        <img src={forStudentsIcon} alt="Para estudantes" />
                      </i>
                    )}
                  </VacancyExtraAspects>
                </VacancyTopData>

                <VacancyBottomData>
                  <p className="vacancy-title">{vacancy.name}</p>

                  <VacancyAspects>
                    <p>
                      {vacancy.vacancyLocation
                        ? convertCityAndStateFromObjectToString(
                            vacancy.vacancyLocation,
                            ', ',
                            true,
                          )
                        : 'Remoto'}
                    </p>
                    <p>{vacancy.jobType.type}</p>
                    <p>{getParsedVacancySalaryInformation(vacancy)}</p>
                  </VacancyAspects>
                </VacancyBottomData>
              </Vacancy>
            ))
          )}
        </VacanciesContainer>

        <CompaniesHiringContainer>
          {!isLoadingStartupsWithMostVacancies &&
            startupsWithMostVacancies.length > 0 && (
              <>
                <p>Empresas contratando</p>

                {startupsWithMostVacancies.map(
                  ({ name, logo, vacanciesQuantity }) => (
                    <CompanyHiring
                      key={name}
                      href={`${baseURLFrontend}/${convertNameToSnakeCase(
                        name,
                      )}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="company-data">
                        <img src={logo} alt="Logo" />
                        <span>{name}</span>
                      </div>

                      <div className="vacancies-quantity">
                        {vacanciesQuantity}
                      </div>
                    </CompanyHiring>
                  ),
                )}
              </>
            )}
        </CompaniesHiringContainer>
      </MainContainer>
    </>
  );
};
