import deleteIcon from 'assets/img/delete.svg';
import redirectIcon from 'assets/img/redirectIcon.svg';

import { ICandidateResponseForFindAll } from 'types/candidate';
import { convertCityAndStateFromObjectToString } from 'utils/conversion/convertCityAndStateFromObjectToString';
import { Container } from './styles';

interface CandidateRowProps {
  candidate: ICandidateResponseForFindAll;
  handleShowDeleteUserModal: (userId: number) => void;
  handleShowResumeModal: (candidateId: number) => Promise<void>;
}

export const CandidateRow: React.FC<CandidateRowProps> = ({
  candidate,
  handleShowDeleteUserModal,
  handleShowResumeModal,
}) => {
  return (
    <Container>
      <span className="candidates-column">
        {`${candidate.user.name} ${candidate.user.lastName}`}
      </span>
      <span className="profession-column">{candidate.profession}</span>
      <span className="location-column">
        {convertCityAndStateFromObjectToString(candidate, ', ', true)}
      </span>
      <span className="email-column">{candidate.user.email}</span>
      <span className="phone-column">{candidate.phoneNumber}</span>
      <div className="options-column">
        <button
          type="button"
          onClick={() => handleShowDeleteUserModal(candidate.user.id)}
        >
          <img src={deleteIcon} alt="Excluir candidato" />
        </button>

        <button
          type="button"
          onClick={() => handleShowResumeModal(candidate.id)}
        >
          <img src={redirectIcon} alt="Visualizar currículo" />
        </button>
      </div>
    </Container>
  );
};
