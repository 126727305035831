import openOptions from 'assets/img/openOptions.svg';
import { OptionButton } from 'components/Buttons';
import { useState } from 'react';
import { Container, FolderOptions } from './styles';

interface OpenFolderOptionsButtonProps {
  handleOpenRenameFolderModal: () => void;
  handleOpenDeleteFolderModal: () => void;
}

export const OpenFolderOptionsButton: React.FC<
  OpenFolderOptionsButtonProps
> = ({ handleOpenRenameFolderModal, handleOpenDeleteFolderModal }) => {
  const [isFolderOptionsVisible, setIsFolderOptionsVisible] = useState(false);

  return (
    <Container>
      <button
        type="button"
        className="action-button"
        onClick={() =>
          setIsFolderOptionsVisible(previousValue => !previousValue)
        }
        onBlur={() => setIsFolderOptionsVisible(false)}
      >
        <img src={openOptions} alt="Três pontos" />
      </button>

      <FolderOptions isVisible={isFolderOptionsVisible}>
        <OptionButton type="button" onClick={handleOpenRenameFolderModal}>
          Renomear pasta
        </OptionButton>
        <OptionButton
          type="button"
          isDeleteButton
          onClick={handleOpenDeleteFolderModal}
        >
          Excluir
        </OptionButton>
      </FolderOptions>
    </Container>
  );
};
