import { formStepInputStyles } from 'assets/styles/formWithStepsStyles/formWithStepsInputStyles';
import { FormWithStepsSubForm } from 'components/FormWithSteps/FormWithStepsSubForm';
import { Input } from 'components/Input';
import { Select } from 'components/Select';
import { useFormError } from 'errors/useFormError';
import { useWindowWidth } from 'hooks/windowWidth';
import { useCallback, useEffect } from 'react';
import { mask } from 'remask';
import { OmitId } from 'types';
import { ICandidateAcademicEducation } from 'types/candidate/ICandidateAcademicEducation';
import { IErrors } from 'types/IErrors';
import { yearPattern } from 'types/maskPatterns';
import { getInputStateValue } from 'utils';
import { useInputStates } from 'utils/customHooks';
import { getLevelOfSchoolingOptionsIdFromLevelOfSchoolingEnum } from 'utils/getLevelOfSchoolingOptionsIdFromLevelOfSchoolingEnum';
import { levelOfSchoolingOptions } from 'utils/typeOptions/levelOfSchoolingOptions';

interface AcademicEducationSubFormProps {
  initialData: OmitId<ICandidateAcademicEducation>;
  index: number;
  handleUpdateSubFormData: (
    updatedAcademicEducation: OmitId<ICandidateAcademicEducation>,
  ) => void;
  handleDeleteSubFormData: () => void;
  errors?: IErrors;
  disabled?: boolean;
}

export const AcademicEducationSubForm: React.FC<
  AcademicEducationSubFormProps
> = ({
  initialData,
  index,
  handleDeleteSubFormData,
  handleUpdateSubFormData,
  errors,
}) => {
  const { title, institutionName, levelOfSchooling, startYear, endYear } =
    initialData;
  const { handleFormError } = useFormError();
  const { isMobileScreen } = useWindowWidth();

  const titleStates = useInputStates('title', title);
  const institutionNameStates = useInputStates(
    'institutionName',
    institutionName,
  );
  const levelOfSchoolingStates = useInputStates(
    'levelOfSchooling',
    levelOfSchooling
      ? getLevelOfSchoolingOptionsIdFromLevelOfSchoolingEnum(levelOfSchooling)
      : '',
  );
  const startYearStates = useInputStates('startYear', startYear || '');
  const endYearStates = useInputStates('endYear', endYear || '');

  const getUpdatedSubFormData = useCallback(() => {
    const parsedStartYear = Number(getInputStateValue(startYearStates));
    const parsedEndYear = Number(getInputStateValue(endYearStates));
    const parsedLevelOfSchooling = Number(
      getInputStateValue(levelOfSchoolingStates),
    );

    const updatedStatesObject = {
      title: getInputStateValue(titleStates),
      institutionName: getInputStateValue(institutionNameStates),
      levelOfSchooling: parsedLevelOfSchooling
        ? levelOfSchoolingOptions[parsedLevelOfSchooling - 1].enumValue
        : '',
      startYear: parsedStartYear,
      endYear: parsedEndYear,
    };

    return updatedStatesObject as OmitId<ICandidateAcademicEducation>;
  }, [
    endYearStates,
    institutionNameStates,
    levelOfSchoolingStates,
    startYearStates,
    titleStates,
  ]);

  useEffect(() => {
    const updatedData = getUpdatedSubFormData();
    handleUpdateSubFormData(updatedData);
  }, [getUpdatedSubFormData, handleUpdateSubFormData]);

  useEffect(() => {
    if (!errors) return;

    const subFormStates = [
      endYearStates,
      institutionNameStates,
      levelOfSchoolingStates,
      startYearStates,
      titleStates,
    ];
    handleFormError(errors, subFormStates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return (
    <FormWithStepsSubForm
      name={`Formação acadêmica ${index + 1}`}
      handleDeleteSubFormData={handleDeleteSubFormData}
    >
      <Select
        name="Nível de escolaridade"
        placeholder="selecionar"
        states={levelOfSchoolingStates}
        selectOptions={levelOfSchoolingOptions}
        style={formStepInputStyles}
        startWithoutValue
      />

      <Input
        name="Título de formação"
        placeholder="ex. Administração"
        states={titleStates}
        style={formStepInputStyles}
        isOptional
      />

      <Input
        name="Nome da instituição"
        placeholder="ex. Universidade Federal Rural do..."
        states={institutionNameStates}
        style={formStepInputStyles}
      />

      <Input
        name="Ano de início dos estudos"
        placeholder="ex. 2017"
        states={startYearStates}
        style={formStepInputStyles}
        minLength={4}
        maxLength={4}
        onChange={({ target: { value } }) => {
          startYearStates.mainState.setFunction(mask(value, yearPattern));
        }}
      />

      <Input
        name={`Ano de término${
          !isMobileScreen ? ' (ou prevista)' : ''
        } dos estudos`}
        placeholder="ex. 2017"
        states={endYearStates}
        style={formStepInputStyles}
        minLength={4}
        maxLength={4}
        onChange={({ target: { value } }) => {
          endYearStates.mainState.setFunction(mask(value, yearPattern));
        }}
      />
    </FormWithStepsSubForm>
  );
};
