import {
  BackgroundColor,
  GradientPrimary,
  HighEmphasis,
  OutlineColor,
  PrimaryColor,
} from 'assets/colors/palette';
import {
  Bold14Styles,
  Bold32Styles,
  Regular14Styles,
  Regular16Styles,
} from 'assets/fonts/fontStyles';
import styled, { css } from 'styled-components';

interface TabButtonProps {
  isSelected: boolean;
}

export const Container = styled.div`
  padding: 64px 40px 0;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;

  h1 {
    ${Bold32Styles}
    color: #191D23;
  }

  button {
    background: transparent;
    width: 251px;
    height: 44px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    border: 1.5px solid ${OutlineColor};
    border-radius: 4px;

    ${Bold14Styles}
    color: ${HighEmphasis};
    transition: 0.2s;

    &:hover {
      border-color: ${PrimaryColor};
    }
  }
`;

export const TabSelectionContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TabButton = styled.button<TabButtonProps>`
  ${Regular14Styles}
  background-color: transparent;
  color: #191d23;
  text-align: center;
  position: relative;

  width: 161px;
  height: 48px;
  transition: 0.2s;

  &:after {
    content: '';
    width: 100%;
    height: 2px;

    position: absolute;
    background: ${GradientPrimary};
    opacity: 0;
    bottom: 0;
    left: 0;
    transition: 0.2s;
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      font-weight: 700;

      &:after {
        opacity: 1;
      }
    `}
`;

export const SearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;
  margin-bottom: 16px;

  width: 100%;
  padding: 8px 12px;
  background: rgba(220, 220, 220, 0.2);
  border-radius: 8px;

  &:after {
    content: '';
    width: 100%;
    height: 2px;
    position: absolute;

    top: -4px;
    left: 0;
    background-color: #e7eaee;
  }
`;

export const SearchBar = styled.div`
  width: 425px;
  height: 48px;
  padding-inline: 12px;

  background: ${BackgroundColor};
  border: 1px solid ${OutlineColor};
  border-radius: 8px;
  cursor: text;

  display: flex;
  align-items: center;
  gap: 12px;

  input {
    ${Regular16Styles}
    color: ${HighEmphasis};
    height: 100%;
    border-radius: 8px;

    flex: 1;
    outline: 0;

    &::placeholder {
      color: ${OutlineColor};
    }
  }

  img {
    width: 20px;
    height: 20px;

    opacity: 0.2;
    filter: invert(76%) sepia(11%) saturate(0%) hue-rotate(186deg)
      brightness(95%) contrast(87%);
  }
`;

export const ContentTable = styled.div`
  position: relative;

  section {
    width: 100%;
    height: 64px;
    background: ${BackgroundColor};
    border-bottom: 1px solid ${OutlineColor};
  }
`;

export const ColumnNamesContainer = styled.section`
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 1;
`;

export const SearchedContentContainer = styled.div`
  padding-block: 64px;
`;
