import styled from 'styled-components';

import {
  BackgroundColor,
  GradientPrimary,
  OutlineColor,
} from 'assets/colors/palette';
import { Regular14Styles } from 'assets/fonts/fontStyles';

interface PunctuationContainerProps {
  barPercentage: number;
  barColor?: string;
}

export const Container = styled.div`
  width: 100%;
  padding-inline: 20px;
`;

export const PunctuationContainer = styled.div<PunctuationContainerProps>`
  margin-bottom: 32px;

  section {
    ${Regular14Styles}

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .punctuation-bar {
    margin-top: 8px;
    height: 4px;

    border-radius: 8px;
    background-color: ${OutlineColor};
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;

      width: ${({ barPercentage }) => barPercentage}%;
      height: 100%;
      border-radius: 8px;

      background: ${({ barColor }) => barColor || GradientPrimary};
    }
  }
`;

export const AverageEvaluation = styled.div`
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.75px;
  font-weight: 500;

  background-color: ${BackgroundColor};
  border: 1px solid ${OutlineColor};
  border-radius: 100px;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 40px;
  margin-bottom: 20px;
`;
