import { GrayBackground } from 'assets/colors/palette';
import { Bold32Styles, Regular24Styles } from 'assets/fonts/fontStyles';
import { PrimaryButton } from 'components/Buttons';
import styled from 'styled-components';

export const MainContainer = styled.div`
  height: calc(100vh - 130px);
  width: 100%;
  background: ${GrayBackground};
`;

export const SubContainer = styled.div`
  height: calc(100% - 64px);
  margin-inline: auto;
  text-align: center;
  width: 422px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${PrimaryButton} {
    display: flex;
    align-items: center;
    justify-content: space-between;

    gap: 8px;
    padding-inline: 32px;
    height: 48px;
  }
`;

export const Title = styled.p`
  ${Bold32Styles}
  font-size: 24px;
  margin-block: 48px 16px;
`;

export const Text = styled.p`
  ${Regular24Styles}
  font-size: 14px;
  margin-bottom: 48px;
`;
