import styled from 'styled-components';
import {
  BackgroundColor,
  GrayTitleActive,
  HighEmphasis,
  LowEmphasis,
  MediumEmphasis,
  OutlineColor,
  PrimaryColor,
} from 'assets/colors/palette';
import { TextGradientPrimaryBackground } from 'assets/colors/background';
import {
  Bold18Styles,
  Medium14Styles,
  Medium16Styles,
  Regular14Styles,
} from 'assets/fonts/fontStyles';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 30px;

  @media (max-width: 1100px) {
    width: 90vw;
    margin-inline: auto;
    padding: 32px 0;
  }
`;

export const VacancyTopContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  img {
    width: 96px;
    height: 96px;
    border-radius: 48px;
  }

  aside p {
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.75px;
    text-align: right;
    color: ${LowEmphasis};
  }

  @media (max-width: 1100px) {
    img {
      width: 64px;
      height: 64px;
    }

    aside p {
      font-size: 12px;
    }
  }
`;

export const TitleContainer = styled.div`
  letter-spacing: 0.75px;
  margin-block: -8px;

  .vacancyTitle {
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
    color: ${GrayTitleActive};
    margin-bottom: 8px;
  }

  .companyName {
    font-size: 18px;
    line-height: 34px;
    color: ${PrimaryColor};
    font-weight: 400;
  }

  @media (max-width: 1100px) {
    .vacancyTitle {
      font-size: 18px;
      margin-bottom: 0;
    }

    .companyName {
      font-size: 14px;
    }
  }
`;

export const VacancyAspects = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 16px;
  flex-flow: wrap;

  div {
    display: flex;
    align-items: center;
    min-width: max-content;
    gap: 8px;

    border: 1px solid ${OutlineColor};
    border-radius: 8px;
    padding: 11px 8px;

    small {
      ${TextGradientPrimaryBackground}
      ${Medium14Styles}
      line-height: 24px;
    }
  }
`;

export const VacancyData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 10px;
`;

export const VacancyDataLine = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;

  img {
    margin-top: 4px;
  }

  div {
    display: flex;
    gap: 16px;
  }

  p {
    font-size: 14px;
    line-height: 32px;
    letter-spacing: 0.75px;
    color: ${HighEmphasis};
    position: relative;
    word-wrap: break-word;
    white-space: pre-line;

    & + p {
      margin-left: 4px;
    }

    &:empty {
      display: none;
    }

    & + p:after {
      content: '';
      position: absolute;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: ${HighEmphasis};
      left: -12px;
      bottom: 42%;
    }
  }

  @media (max-width: 1100px) {
    div {
      flex-direction: column;
      gap: 0;

      p:first-child {
        width: max-content;

        &::after {
          content: '';
          position: absolute;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: ${HighEmphasis};
          right: -12px;
          bottom: 42%;
        }
      }
    }

    p + p {
      margin-left: 0;

      &:after {
        display: none;
      }
    }
  }
`;

export const VacancyTopic = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  letter-spacing: 0.75px;

  &:not(:last-child) {
    margin-bottom: 30px;
  }

  .topic-title {
    ${Bold18Styles}
  }

  .content {
    font-size: 14px;
    line-height: 32px;
    max-width: 744px;
    word-wrap: break-word;
    white-space: pre-line;

    &:empty {
      display: none;
    }
  }
`;

export const RequirementWithDescription = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin-top: -8px;
    word-wrap: break-word;
    width: 100%;
  }
`;

export const RequirementContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  margin-bottom: 30px;

  div {
    display: flex;
    gap: 22px;
    flex-flow: wrap;

    p {
      ${Regular14Styles}
      line-height: 28px;
      margin-bottom: 8px;
    }
  }

  span {
    ${Medium16Styles}
    color: ${MediumEmphasis}
  }
`;

export const Requirement = styled.div`
  width: fit-content;
  padding: 12px;
  min-height: 48px;
  height: max-content;
  background: ${BackgroundColor};
  border: 1px solid ${OutlineColor};
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  ${Medium16Styles}
  color: ${PrimaryColor};
`;
