import { BackgroundColor, OutlineColor } from 'assets/colors/palette';
import {
  Bold14Styles,
  Medium16Styles,
  Regular14Styles,
} from 'assets/fonts/fontStyles';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  p {
    ${Regular14Styles}
    line-height: 24px;
  }
`;

export const TestsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const TestContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 441px;
  height: 72px;
  padding-inline: 12px;

  background-color: ${BackgroundColor};
  border: 1px solid ${OutlineColor};
  border-radius: 8px;

  & > span {
    ${Bold14Styles}
    line-height: 24px;
    width: 136px;
  }
`;

export const PunctuationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  height: 100%;
  padding-left: 35px;
  border-left: 1px solid #d9d9d9;

  span {
    ${Regular14Styles}
    line-height: 24px;
  }
`;

export const PunctuationInput = styled.input`
  ${Medium16Styles}

  width: 85px;
  height: 48px;
  outline: 0;

  border: 1px solid ${OutlineColor};
  border-radius: 100px;
  text-align: center;

  &::placeholder {
    color: ${OutlineColor};
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
`;
