import { DashboardHeader } from 'components/DashboardHeader';
import { useMemo } from 'react';
import {
  routeCandidatedVacancies,
  routeCandidateTests,
  routeFindVacancies,
} from 'routes/candidatesRoutes/candidatesRoutesAddresses';
import { useWindowWidth } from 'hooks/windowWidth';
import { CandidateButtons } from './CandidateButtons';
import { CandidateMobileButtons } from './CandidateMobileButtons';

export const CandidatesDashboardHeader: React.FC = () => {
  const { isMobileScreen } = useWindowWidth();

  const CANDIDATES_HEADER_TABS = useMemo(
    () => [
      {
        name: 'Minhas vagas',
        path: routeCandidatedVacancies,
        toBlankPage: false,
      },
      {
        name: 'Mural de vagas',
        path: routeFindVacancies,
        toBlankPage: false,
      },
      {
        name: 'Avaliações',
        path: routeCandidateTests,
        toBlankPage: false,
      },
    ],
    [],
  );

  return (
    <DashboardHeader dashboardType="candidates" tabs={CANDIDATES_HEADER_TABS}>
      {isMobileScreen ? <CandidateMobileButtons /> : <CandidateButtons />}
    </DashboardHeader>
  );
};
