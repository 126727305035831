import { HTMLAttributes } from 'react';
import {
  routeBar,
  routePrivacyPolicy,
  routeTermsOfUse,
} from 'routes/routesAddresses';
import { Container } from './styles';

type AboutButtonsProps = HTMLAttributes<HTMLDivElement>;

const ACTUAL_YEAR = new Date().getFullYear();

export const AboutButtons: React.FC<AboutButtonsProps> = props => {
  return (
    <Container {...props}>
      <a href={routePrivacyPolicy} target="_blank" rel="noreferrer">
        Privacidade
      </a>
      <a href={routeTermsOfUse} target="_blank" rel="noreferrer">
        Termos de uso
      </a>
      <a href={routeBar} target="_blank" rel="noreferrer">
        Sobre IN6 © {ACTUAL_YEAR}. Todos os direitos reservados.
      </a>
    </Container>
  );
};
