import filterIcon from 'assets/img/filter.svg';
import closeIcon from 'assets/img/close.svg';

import { useCallback, useMemo, useState } from 'react';
import { translateCandidateEvaluation } from 'utils/conversion';
import { convertCityAndStateFromObjectToString } from 'utils/conversion/convertCityAndStateFromObjectToString';
import { CandidateEvaluation } from 'types/CandidateEvaluation';
import { IFiledStartupCandidateResponseWithoutFolder } from 'types/filedStartupCandidate';
import { IStartupCandidate } from 'types';
import { IVacancyCandidateWithCandidateData } from 'types/vacancyCandidate';
import { FilterColumn } from './FilterColumn';

import {
  Container,
  FilterButton,
  FilterButtonsContainer,
  FilterColumnsContainer,
  FilterOptionButton,
  FilterOptionsContainer,
  FilterOptionsTopContainer,
} from './styles';

export type CandidateMeetsResearchFiltersType = (
  candidate: IStartupCandidate,
) => boolean;

export const DEFAULT_CANDIDATE_MEETS_RESEARCH_FILTERS: CandidateMeetsResearchFiltersType =
  () => true;

interface OpenFilterOptionsButtonProps {
  startupCandidates: (
    | IVacancyCandidateWithCandidateData
    | IFiledStartupCandidateResponseWithoutFolder
  )[];
  updateCandidateMeetsResearchFiltersFunction: (
    candidateMeetsResearchFilters: CandidateMeetsResearchFiltersType,
  ) => void;
}

export interface FilterColumnOption {
  id: number | string;
  label: string;
}

const CANDIDATE_EVALUATIONS_OPTIONS: FilterColumnOption[] = [
  {
    id: CandidateEvaluation.NOT_EVALUATED,
    label: translateCandidateEvaluation(CandidateEvaluation.NOT_EVALUATED),
  },
  {
    id: CandidateEvaluation.POSITIVE,
    label: translateCandidateEvaluation(CandidateEvaluation.POSITIVE),
  },
  {
    id: CandidateEvaluation.NEGATIVE,
    label: translateCandidateEvaluation(CandidateEvaluation.NEGATIVE),
  },
  {
    id: CandidateEvaluation.FAVORITE,
    label: translateCandidateEvaluation(CandidateEvaluation.FAVORITE),
  },
  {
    id: CandidateEvaluation.LOVED,
    label: translateCandidateEvaluation(CandidateEvaluation.LOVED),
  },
];

export const OpenFilterOptionsButton: React.FC<
  OpenFilterOptionsButtonProps
> = ({ startupCandidates, updateCandidateMeetsResearchFiltersFunction }) => {
  const [isFilterOptionsVisible, setIsFilterOptionsVisible] = useState(false);

  const [filteredEvaluations, setFilteredEvaluations] = useState<
    CandidateEvaluation[]
  >([]);
  const [filteredLocations, setFilteredLocations] = useState<number[]>([]);

  const VACANCY_CANDIDATE_LOCATIONS_OPTIONS = useMemo(() => {
    const candidatesLocations = startupCandidates.map(
      ({ candidate: { city, state } }) => ({ city, state }),
    );

    const locationsWithoutRepetitions = candidatesLocations.filter(
      ({ city: { id: cityId } }, index, arr) =>
        index ===
        arr.findIndex(
          ({ city: { id: iterationId } }) => iterationId === cityId,
        ),
    );

    const parsedLocations = locationsWithoutRepetitions.map(
      ({ city, state }) => ({
        id: city.id,
        label: convertCityAndStateFromObjectToString(
          { city, state },
          ', ',
          true,
        ),
      }),
    );

    return parsedLocations;
  }, [startupCandidates]);

  const handleOnClickApplyFilters = useCallback(() => {
    setIsFilterOptionsVisible(false);

    const notHaveEvaluationFilters = filteredEvaluations.length === 0;
    const notHaveLocationFilters = filteredLocations.length === 0;

    if (notHaveEvaluationFilters && notHaveLocationFilters) {
      updateCandidateMeetsResearchFiltersFunction(
        DEFAULT_CANDIDATE_MEETS_RESEARCH_FILTERS,
      );
      return;
    }

    const candidateMeetsResearchFiltersFunction = ({
      candidateEvaluation,
      candidate: {
        city: { id: cityId },
      },
    }: IStartupCandidate): boolean => {
      const parsedCandidateEvaluation =
        candidateEvaluation === CandidateEvaluation.NEW_CANDIDATE
          ? CandidateEvaluation.NOT_EVALUATED
          : candidateEvaluation;

      const candidateMeetsEvaluationFilter =
        notHaveEvaluationFilters ||
        filteredEvaluations.includes(parsedCandidateEvaluation);
      if (!candidateMeetsEvaluationFilter) return false;

      const candidateMeetsLocationFilter =
        notHaveLocationFilters || filteredLocations.includes(cityId);
      if (!candidateMeetsLocationFilter) return false;

      return true;
    };

    updateCandidateMeetsResearchFiltersFunction(
      candidateMeetsResearchFiltersFunction,
    );
  }, [
    filteredEvaluations,
    filteredLocations,
    updateCandidateMeetsResearchFiltersFunction,
  ]);

  const updateFilteredEvaluations = useCallback(
    (evaluationId: string | number, toRemove: boolean) => {
      const parsedEvaluationId =
        CandidateEvaluation[evaluationId as CandidateEvaluation];

      setFilteredEvaluations(previousEvaluationsIds => {
        if (!toRemove) return [...previousEvaluationsIds, parsedEvaluationId];
        return previousEvaluationsIds.filter(id => id !== parsedEvaluationId);
      });
    },
    [],
  );

  const updateFilteredLocations = useCallback(
    (cityId: string | number, toRemove: boolean) => {
      const parsedLocationId = Number(cityId);

      setFilteredLocations(previousLocationsIds => {
        if (!toRemove) return [...previousLocationsIds, parsedLocationId];
        return previousLocationsIds.filter(id => id !== parsedLocationId);
      });
    },
    [],
  );

  const clearFilters = useCallback(() => {
    setFilteredEvaluations([]);
    setFilteredLocations([]);

    updateCandidateMeetsResearchFiltersFunction(
      DEFAULT_CANDIDATE_MEETS_RESEARCH_FILTERS,
    );
  }, [updateCandidateMeetsResearchFiltersFunction]);

  const quantityOfFiltersApplied =
    filteredEvaluations.length + filteredLocations.length;

  return (
    <Container>
      <FilterOptionsContainer
        isVisible={isFilterOptionsVisible}
        onClick={event => event.stopPropagation()}
      >
        <FilterOptionsTopContainer>
          <span>Filtros</span>

          <button
            type="button"
            onClick={() => setIsFilterOptionsVisible(false)}
          >
            <img src={closeIcon} alt="Ícone de X" />
          </button>
        </FilterOptionsTopContainer>

        <FilterColumnsContainer>
          <FilterColumn
            columnTitle="Avaliação"
            updateFilterOptionValue={updateFilteredEvaluations}
            filterColumnOptions={CANDIDATE_EVALUATIONS_OPTIONS}
            filteredOptions={filteredEvaluations}
          />
          <FilterColumn
            columnTitle="Localização"
            updateFilterOptionValue={updateFilteredLocations}
            filterColumnOptions={VACANCY_CANDIDATE_LOCATIONS_OPTIONS}
            filteredOptions={filteredLocations}
          />
        </FilterColumnsContainer>

        <FilterButtonsContainer>
          <FilterOptionButton
            type="button"
            disabled={quantityOfFiltersApplied === 0}
            onClick={clearFilters}
          >
            Limpar filtros
          </FilterOptionButton>

          <FilterOptionButton
            type="button"
            style={{ width: 389 }}
            onClick={handleOnClickApplyFilters}
          >
            Aplicar ({quantityOfFiltersApplied}) filtros de pesquisa
          </FilterOptionButton>
        </FilterButtonsContainer>
      </FilterOptionsContainer>

      <FilterButton
        type="button"
        onClick={() => setIsFilterOptionsVisible(value => !value)}
      >
        <img src={filterIcon} alt="Funil" />
      </FilterButton>
    </Container>
  );
};
