import { Medium14Styles } from 'assets/fonts/fontStyles';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  span {
    ${Medium14Styles};
    line-height: 24px;
  }
`;
