import linkedinIcon from 'assets/img/linkedin.svg';
import whatsappIcon from 'assets/img/whatsapp.svg';
import emailIcon from 'assets/img/emailBlock.svg';

import React, { HTMLAttributes, ReactNode, useMemo } from 'react';
import { ICandidateRequest } from 'types/candidate';
import { calculateAgeUsingBirthMonthAndYear } from 'utils/calculateAgeUsingBirthMonthAndYear';
import { convertCityAndStateFromObjectToString } from 'utils/conversion/convertCityAndStateFromObjectToString';
import { convertLevelOfSchoolingToPortuguese } from 'utils/conversion/convertLevelOfSchoolingToPortuguese';
import { convertLanguageFromObjectToString } from 'utils/conversion/convertLanguageFromObjectToString';
import { IUserResponse } from 'types/user';
import { UserIcon } from 'components/UserIcon';
import { HighEmphasis } from 'assets/colors/palette';
import { jobTypeOptions } from 'utils/typeOptions';
import {
  CandidateData,
  CandidateDataLine,
  CandidateExperience,
  CandidateIdentification,
  CandidateTopic,
  CapabilitiesContainer,
  Capability,
  Container,
  ExperienceDataLine,
  ExperienceDescription,
  ExperienceName,
  SocialNetwork,
  SocialNetworksContainer,
} from './styles';

interface CandidateResumeProps extends HTMLAttributes<HTMLDivElement> {
  userData: IUserResponse;
  candidateData: ICandidateRequest;
  ComponentToReplaceSocialNetworks?: ReactNode;
  ComponentToGoOnContainerStart?: ReactNode;
}

export const CandidateResume: React.FC<CandidateResumeProps> = ({
  userData,
  candidateData,
  ComponentToReplaceSocialNetworks,
  ComponentToGoOnContainerStart,
  ...props
}) => {
  const {
    linkedin,
    birthDate,
    city,
    phoneNumber,
    photo,
    profession,
    state,
    professionalExperiences,
    academicEducations,
    certifications,
    competencies,
    languages,
    skills,
  } = candidateData;

  const candidateAge = useMemo(() => {
    const [birthMonth, birthYear] = birthDate.split('/');
    return calculateAgeUsingBirthMonthAndYear(
      Number(birthMonth),
      Number(birthYear),
    );
  }, [birthDate]);

  const getParsedDateFromProfessionalExperienceData = (
    actualJob: boolean,
    startDate: string,
    endDate: string,
  ) => {
    const [startMonth, startYear] = startDate.split('/');

    const parsedStartMonthName = new Date(0, Number(startMonth) - 1)
      .toLocaleString('pt-BR', {
        month: 'short',
      })
      .substring(0, 3);
    const parsedStartDate = `${parsedStartMonthName} ${startYear}`;

    if (actualJob) return `${parsedStartDate} - o momento`;

    const [endMonth, endYear] = endDate.split('/');
    const parsedEndMonthName = new Date(0, Number(endMonth) - 1)
      .toLocaleString('pt-BR', {
        month: 'short',
      })
      .substring(0, 3);
    const parsedEndDate = `${parsedEndMonthName} ${endYear}`;

    return `${parsedStartDate} - ${parsedEndDate}`;
  };

  const getComparsionEndDateForProfessionalExperience = (
    actualJob: boolean,
    endDate: string,
  ) => {
    if (actualJob) {
      const actualDate = new Date();

      return {
        comparsionYear: actualDate.getFullYear(),
        comparsionMonth: actualDate.getMonth() + 1,
      };
    }

    const [endMonth, endYear] = endDate.split('/');
    return {
      comparsionYear: Number(endYear),
      comparsionMonth: Number(endMonth),
    };
  };

  const getParsedDifferenceBetweenStartAndEndDateFromProfessionalExperience = (
    actualJob: boolean,
    startDate: string,
    endDate: string,
  ) => {
    const [startMonth, startYear] = startDate.split('/');
    const { comparsionMonth, comparsionYear } =
      getComparsionEndDateForProfessionalExperience(actualJob, endDate);

    const yearDifference = Math.abs(comparsionYear - Number(startYear));
    const monthDifference = Math.abs(comparsionMonth - Number(startMonth) + 1);

    const parsedYearDifference =
      yearDifference === 0
        ? ''
        : `${yearDifference} ano${yearDifference > 1 ? 's' : ''}`;
    const parsedMonthDifference = !monthDifference
      ? ''
      : `${monthDifference} ${monthDifference === 1 ? 'mês' : 'meses'}`;

    return `${parsedYearDifference} ${parsedMonthDifference}`;
  };

  return (
    <Container {...props}>
      {ComponentToGoOnContainerStart}

      <UserIcon
        userName={userData.name}
        image={photo}
        style={{
          backgroundColor: photo === null ? HighEmphasis : 'transparent',
        }}
      />
      <CandidateIdentification>
        <h2>{`${userData.name} ${userData.lastName}`}</h2>
        <span>{profession}</span>
      </CandidateIdentification>

      {ComponentToReplaceSocialNetworks ?? (
        <SocialNetworksContainer>
          {linkedin && (
            <SocialNetwork href={linkedin} target="_blank">
              <img src={linkedinIcon} alt="Linkedin" />
            </SocialNetwork>
          )}

          <SocialNetwork
            href={`https://web.whatsapp.com/send?phone=+55${phoneNumber}`}
            target="_blank"
          >
            <img src={whatsappIcon} alt="Whatsapp" />
          </SocialNetwork>

          <SocialNetwork href={`mailto:${userData.email}`} target="_blank">
            <img src={emailIcon} alt="Email" />
          </SocialNetwork>
        </SocialNetworksContainer>
      )}

      <CandidateData>
        <CandidateDataLine>
          <span className="line-title">Idade</span>
          <div>
            <p className="line-content">{candidateAge} anos</p>
          </div>
        </CandidateDataLine>

        <CandidateDataLine>
          <span className="line-title">Endereço</span>
          <div>
            <p className="line-content">
              {convertCityAndStateFromObjectToString(
                {
                  city,
                  state,
                },
                ', ',
                true,
              )}
            </p>
          </div>
        </CandidateDataLine>

        <CandidateDataLine>
          <span className="line-title">Telefone/WhatsApp</span>
          <div>
            <p className="line-content">{phoneNumber}</p>
          </div>
        </CandidateDataLine>

        <CandidateDataLine>
          <span className="line-title">Email</span>
          <div>
            <p className="line-content">{userData.email}</p>
          </div>
        </CandidateDataLine>
      </CandidateData>

      {professionalExperiences.length > 0 && (
        <>
          <CandidateTopic>Experiência profissional</CandidateTopic>

          {professionalExperiences.map(
            ({
              roleName,
              actualJob,
              assignmentsDescription,
              city: professionalExperienceCity,
              companyName,
              endDate,
              jobType,
              startDate,
              state: professionalExperienceState,
            }) => (
              <CandidateExperience key={roleName + companyName}>
                <ExperienceName>{roleName}</ExperienceName>

                <ExperienceDataLine>
                  <span>{companyName}</span>
                  <span>{jobTypeOptions[jobType.id - 1].label}</span>
                </ExperienceDataLine>

                <ExperienceDataLine lowerColor>
                  <span>
                    {getParsedDateFromProfessionalExperienceData(
                      actualJob,
                      startDate,
                      endDate,
                    )}
                  </span>
                  <span>
                    {getParsedDifferenceBetweenStartAndEndDateFromProfessionalExperience(
                      actualJob,
                      startDate,
                      endDate,
                    )}
                  </span>
                </ExperienceDataLine>

                <ExperienceDataLine lowerColor>
                  {convertCityAndStateFromObjectToString({
                    city: professionalExperienceCity,
                    state: professionalExperienceState,
                  })}
                </ExperienceDataLine>

                <ExperienceDescription>
                  {assignmentsDescription}
                </ExperienceDescription>
              </CandidateExperience>
            ),
          )}
        </>
      )}

      {academicEducations.length > 0 && (
        <>
          <CandidateTopic>Formação Acadêmica</CandidateTopic>

          {academicEducations.map(
            ({
              endYear,
              institutionName,
              levelOfSchooling,
              startYear,
              title,
            }) => (
              <CandidateExperience key={title + institutionName}>
                <ExperienceName>
                  {`${convertLevelOfSchoolingToPortuguese(levelOfSchooling)}${
                    title ? `, ${title}` : ''
                  }`}
                </ExperienceName>
                <ExperienceDataLine>{institutionName}</ExperienceDataLine>

                <ExperienceDataLine lowerColor>
                  {`${startYear} - ${endYear}`}
                </ExperienceDataLine>
              </CandidateExperience>
            ),
          )}
        </>
      )}

      {competencies && competencies.length > 0 && (
        <CapabilitiesContainer>
          <CandidateTopic>Competências técnicas</CandidateTopic>

          <div>
            {competencies.map(({ name }) => (
              <Capability key={name}>{name}</Capability>
            ))}
          </div>
        </CapabilitiesContainer>
      )}

      {skills && skills.length > 0 && (
        <CapabilitiesContainer>
          <CandidateTopic>Habilidades comportamentais</CandidateTopic>

          <div>
            {skills.map(({ name }) => (
              <Capability key={name}>{name}</Capability>
            ))}
          </div>
        </CapabilitiesContainer>
      )}

      {languages && languages.length > 0 && (
        <CapabilitiesContainer>
          <CandidateTopic>Idiomas</CandidateTopic>

          <div>
            {languages.map(({ name, level }) => (
              <Capability key={name}>
                {convertLanguageFromObjectToString({ name, level })}
              </Capability>
            ))}
          </div>
        </CapabilitiesContainer>
      )}

      {certifications && certifications.length > 0 && (
        <CapabilitiesContainer>
          <CandidateTopic>Certificações</CandidateTopic>

          <div>
            {certifications.map(({ name }) => (
              <Capability key={name}>{name}</Capability>
            ))}
          </div>
        </CapabilitiesContainer>
      )}
    </Container>
  );
};
