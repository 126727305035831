import styled from 'styled-components';

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 12px;

  label + div {
    margin-top: 34px;
  }
`;
