import {
  HighEmphasis,
  MediumEmphasis,
  OutlineColor,
} from 'assets/colors/palette';
import { Regular24Styles } from 'assets/fonts/fontStyles';
import styled from 'styled-components';

interface ContainerProps {
  isAnswerVisible: boolean;
}

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;

  h2 {
    color: ${HighEmphasis};
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
  }
`;

export const Container = styled.div<ContainerProps>`
  width: 1152px;
  overflow: hidden;
  border-bottom: 1px solid ${OutlineColor};

  h2,
  p {
    ${Regular24Styles}
  }

  p {
    font-size: 14px;
    color: ${MediumEmphasis};

    margin-top: -8px;
    padding-bottom: 13px;
    display: ${({ isAnswerVisible }) => (isAnswerVisible ? 'block' : 'none')};
  }

  ${TitleContainer} img {
    transition: 0.5s;
    transform: ${({ isAnswerVisible }) =>
      `rotate(${isAnswerVisible ? '-' : ''}90deg)`};
  }

  @media (max-width: 1100px) {
    width: 90vw;

    h2 {
      font-size: 14px;
    }

    p {
      font-size: 12px;
      padding-bottom: 20px;
    }
  }
`;
