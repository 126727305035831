export const routeBar = '/';

export const routeTermsOfUse = '/termsOfUse';
export const routePrivacyPolicy = '/privacyPolicy';
export const routeHelpCenter = '/helpCenter';

export const routeSignUp = '/signUp';
export const routeLogin = '/login';
export const routeAdminLogin =
  '/adminLogin/ba2da8b82e0d4024d0fc83ade9889ff19b07219e';

export const routeConfirmAuth =
  '/confirmAuth/:userId/:authenticationToken/:keepConnected';
export const routeDisableEmailNotifications =
  '/disableEmailNotifications/:userToken';
export const routeDashboard = '/dashboard';
export const routeAdminDashboard =
  '/adminDashboard/435051c03bf823d5d836093f7403133f2b07fb87';

export const routeExpiration = '/expiration';
export const routeVacancyUnavailable = '/vacancyUnavailable';
export const routeForgotPassword = '/forgotPassword';
export const routeRecoverPassword = '/recoverPassword/:recoverToken';
export const routeActivation = '/activation/:activationToken';
export const routeEmailSent = '/emailSent';

export const routeAccountConfiguration = `${routeDashboard}/accountConfiguration`;
