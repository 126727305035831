import closeIcon from 'assets/img/close.svg';
import { formStepInputStyles } from 'assets/styles/formWithStepsStyles/formWithStepsInputStyles';

import { PlusButton } from 'components/Buttons';
import { Input } from 'components/Input';
import { useCallback, useEffect, useState } from 'react';
import { IFormStepProps } from 'types/IFormStepProps';
import { useInputStates } from 'utils/customHooks';

import {
  StepSubTitle,
  StepTitle,
  TitleContainer,
} from 'assets/styles/formWithStepsStyles/formWithStepsTitlesStyles';
import { FormWithStepsContainer } from 'assets/styles/formWithStepsStyles/formWithStepsContainerStyles';
import { EntityWithNameDTO } from 'types/EntityWithNameDTO';
import { OmitId } from 'types';
import { stringsAreEqual } from 'utils';
import { OptionalStepMobileWarning } from 'components/FormWithSteps/OptionalStepMobileWarning';
import { Skill, SkillsContainer, InputsContainer } from './styles';

export { validateSkillsData } from './validateSkillsData';

export interface SkillsStepData {
  skills: OmitId<EntityWithNameDTO>[];
}

export const SkillsStep: React.FC<IFormStepProps<SkillsStepData>> = ({
  initialData = {} as SkillsStepData,
  setStepDataFunction,
}) => {
  const { skills: initialSkills } = initialData;

  const skillInputStates = useInputStates('skillInput');
  const [skills, setSkills] = useState<OmitId<EntityWithNameDTO>[]>(
    initialSkills ?? [],
  );

  const handleAddSkill = useCallback(() => {
    const { value, setFunction } = skillInputStates.mainState;
    if ((value as string).length === 0) return;

    const findedSkill = skills.find(({ name }) =>
      stringsAreEqual(name, value as string),
    );

    if (findedSkill) {
      skillInputStates.errorMessageState.setFunction(
        'Habilidade já adicionada',
      );
    } else {
      setSkills(previousSkills => [
        ...previousSkills,
        {
          name: value as string,
        },
      ]);
    }

    setFunction('');
  }, [skillInputStates.mainState, skillInputStates.errorMessageState, skills]);

  const handleExcludeSkill = useCallback((skill: string) => {
    setSkills(previousSkills =>
      previousSkills.filter(
        comparativeSkill => comparativeSkill.name !== skill,
      ),
    );
  }, []);

  useEffect(() => {
    if (setStepDataFunction) {
      setStepDataFunction({
        skills,
      });
    }
  }, [skills, setStepDataFunction]);

  return (
    <FormWithStepsContainer>
      <TitleContainer>
        <OptionalStepMobileWarning />
        <StepTitle>Habilidades comportamentais</StepTitle>
        <StepSubTitle>
          Escolha até 8 habilidades interpessoais relacionadas com sua
          personalidade e podem ser valorizadas pelas empresas.
        </StepSubTitle>
      </TitleContainer>

      <InputsContainer>
        <span>{skills?.length ?? 0}/8 habilidades selecionadas</span>

        <Input
          disabled={skills?.length === 8}
          states={skillInputStates}
          placeholder="por exemplo, Planejamento"
          style={formStepInputStyles}
        />

        <PlusButton
          onClick={handleAddSkill}
          disabled={!skillInputStates.mainState.value || skills?.length === 8}
          colorStyle="outline"
        >
          Adicionar
        </PlusButton>
      </InputsContainer>

      <SkillsContainer>
        {skills.map(({ name }) => (
          <Skill key={name}>
            <span>{name}</span>

            <button type="button" onClick={() => handleExcludeSkill(name)}>
              <img src={closeIcon} alt="Excluir habilidade" />
            </button>
          </Skill>
        ))}
      </SkillsContainer>
    </FormWithStepsContainer>
  );
};
