// eslint-disable-next-line no-shadow
export enum LevelOfSchooling {
  ILLITERATE = 'ILLITERATE',
  INCOMPLETE_FUNDAMENTAL = 'INCOMPLETE_FUNDAMENTAL',
  COMPLETE_FUNDAMENTAL = 'COMPLETE_FUNDAMENTAL',
  INCOMPLETE_HIGH_SCHOOL = 'INCOMPLETE_HIGH_SCHOOL',
  COMPLETE_HIGH_SCHOOL = 'COMPLETE_HIGH_SCHOOL',
  INCOMPLETE_HIGHER_EDUCATION = 'INCOMPLETE_HIGHER_EDUCATION',
  COMPLETE_HIGHER_EDUCATION = 'COMPLETE_HIGHER_EDUCATION',
  POSTGRADUATE = 'POSTGRADUATE',
  MASTER_DEGREE = 'MASTER_DEGREE',
  DOCTORATE_DEGREE = 'DOCTORATE_DEGREE',
}
