import { baseURLFrontend, candidatesUrlFrontend } from 'services/baseUrl';
import { UserAccountType } from 'types/user';

export const getRouteWithSubDomainBasedOnUserAccountType = (
  accountType: UserAccountType,
): string => {
  switch (accountType) {
    case UserAccountType.EMPLOYER:
      return baseURLFrontend;
    case UserAccountType.CANDIDATE:
      return candidatesUrlFrontend;
    default:
      return baseURLFrontend;
  }
};
