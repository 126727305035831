import { EntityWithNameDTO } from 'types';
import {
  CandidateTest,
  ICandidateAcademicEducation,
  ICandidateProfessionalExperience,
  ICandidateResponse,
} from 'types/candidate';
import { CategoriesCriteria } from 'types/criteria';
import { IVacancyCandidateWithCandidateData } from 'types/vacancyCandidate';
import { convertTestResultToProfessionalProfileType } from './conversion';
import { getCriteriaQuantity } from './getCriteriaQuantity';
import { normalizeString } from './normalizeString';

type CandidateParsedTestsType = { [key in CandidateTest]: string | undefined };
type CategoriesAndCandidateKeyType = keyof CategoriesCriteria &
  keyof ICandidateResponse;

const CATEGORIES_AND_CANDIDATE_SAME_KEYS: CategoriesAndCandidateKeyType[] = [
  'certifications',
  'skills',
  'competencies',
  'languages',
  'academicEducations',
  'professionalExperiences',
];

const verifyHowManyStringsOfAnArrayAreIncludedOnAnother = (
  baseArray: string[],
  arrayToVerifyInclusion: string[],
): number => {
  if (baseArray.length === 0 || arrayToVerifyInclusion.length === 0) return 0;

  let quantityOfStringsIncluded = 0;
  const parsedArrayToVerify = arrayToVerifyInclusion.map(normalizeString);

  baseArray.forEach(baseString => {
    if (parsedArrayToVerify.length === 0) return;
    const parsedBaseString = normalizeString(baseString);

    const findedIndex = parsedArrayToVerify.findIndex(stringToVerify => {
      const [stringToVerifyEqual, stringToVerifyIncluded] =
        stringToVerify.split('included');

      return (
        stringToVerifyEqual === parsedBaseString ||
        (stringToVerifyIncluded ?? '').includes(parsedBaseString)
      );
    });
    if (findedIndex === -1) return;

    parsedArrayToVerify.splice(findedIndex, 1);
    quantityOfStringsIncluded++;
  });

  return quantityOfStringsIncluded;
};

const extractNamesFromEntityWithNameDTOArray = (
  entities: EntityWithNameDTO[],
): string[] => entities.map(({ name }) => name);

const extractTitlesFromAcademicEducations = (
  academicEducations: ICandidateAcademicEducation[],
): string[] => academicEducations.map(({ title }) => title);

const extractRoleNameAndDescriptionFromProfessionalExperiences = (
  professionalExperiences: ICandidateProfessionalExperience[],
) =>
  professionalExperiences.map(
    ({ roleName, assignmentsDescription }) =>
      `${roleName}included${assignmentsDescription}`,
  );

const getExtractedCandidateDataBasedOnObjectKey = (
  {
    academicEducations,
    professionalExperiences,
    profession,
    ...candidate
  }: ICandidateResponse,
  objectKey: CategoriesAndCandidateKeyType,
): string[] => {
  if (objectKey === 'academicEducations') {
    return extractTitlesFromAcademicEducations(academicEducations);
  }

  if (objectKey === 'professionalExperiences') {
    const professionalExperiencesData =
      extractRoleNameAndDescriptionFromProfessionalExperiences(
        professionalExperiences,
      );
    if (profession.length > 0) professionalExperiencesData.push(profession);

    return professionalExperiencesData;
  }

  return extractNamesFromEntityWithNameDTOArray(candidate[objectKey]);
};

const getCandidatePunctuationAndCriteriaQuantityAboutStringsCriteria = (
  categoriesCriteria: CategoriesCriteria,
  candidate: ICandidateResponse,
): { candidatePunctuation: number; criteriaQuantity: number } => {
  const {
    city: { name: cityName },
    state: { name: stateName },
  } = candidate;

  let criteriaQuantity = categoriesCriteria.locations.length;
  let candidatePunctuation = verifyHowManyStringsOfAnArrayAreIncludedOnAnother(
    categoriesCriteria.locations,
    [cityName, stateName],
  );

  CATEGORIES_AND_CANDIDATE_SAME_KEYS.forEach(key => {
    const categoryArray = categoriesCriteria[key];
    const candidateArray = getExtractedCandidateDataBasedOnObjectKey(
      candidate,
      key,
    );

    criteriaQuantity += categoryArray.length;
    candidatePunctuation += verifyHowManyStringsOfAnArrayAreIncludedOnAnother(
      categoryArray,
      candidateArray,
    );
  });

  return { candidatePunctuation, criteriaQuantity };
};

const getCandidateParsedTests = ({
  testEvaluations,
}: ICandidateResponse): CandidateParsedTestsType => {
  const parsedTests: CandidateParsedTestsType = {
    LOGICAL_REASONING: undefined,
    PORTUGUESE: undefined,
    PROFESSIONAL_PROFILE: undefined,
  };

  testEvaluations.forEach(({ candidateTest, evaluation }) => {
    parsedTests[candidateTest] = evaluation;
  });

  return parsedTests;
};

const getCandidatePunctuationAndCriteriaQuantityAboutProfessionalProfileAndTestsCriteria =
  (
    categoriesCriteria: CategoriesCriteria,
    candidate: ICandidateResponse,
  ): { candidatePunctuation: number; criteriaQuantity: number } => {
    const {
      professionalProfiles,
      tests: {
        minimumPortuguesePunctuation,
        minimumLogicalReasoningPunctuation,
      },
    } = categoriesCriteria;

    let candidatePunctuation = 0;
    const { LOGICAL_REASONING, PORTUGUESE, PROFESSIONAL_PROFILE } =
      getCandidateParsedTests(candidate);

    const hasProfessionalProfilesCriteria = professionalProfiles.length !== 0;
    if (hasProfessionalProfilesCriteria && PROFESSIONAL_PROFILE) {
      const findedIndex = professionalProfiles.findIndex(
        profile =>
          profile ===
          convertTestResultToProfessionalProfileType(PROFESSIONAL_PROFILE),
      );

      candidatePunctuation += Number(findedIndex !== -1);
    }

    const hasPortugueseTestCriteria = minimumPortuguesePunctuation !== 0;
    if (hasPortugueseTestCriteria && PORTUGUESE) {
      candidatePunctuation += Number(
        Number(PORTUGUESE) >= minimumPortuguesePunctuation,
      );
    }

    const hasLogicalReasoningTestCriteria =
      minimumLogicalReasoningPunctuation !== 0;
    if (hasLogicalReasoningTestCriteria && LOGICAL_REASONING) {
      candidatePunctuation += Number(
        Number(LOGICAL_REASONING) >= minimumLogicalReasoningPunctuation,
      );
    }

    const criteriaQuantity =
      Number(hasProfessionalProfilesCriteria) +
      getCriteriaQuantity(categoriesCriteria, 'tests');

    return { candidatePunctuation, criteriaQuantity };
  };

export const calculateVacancyCandidateConnectionPunctuation = (
  categoriesCriteria: CategoriesCriteria,
  { candidate }: IVacancyCandidateWithCandidateData,
): number => {
  const stringsResults =
    getCandidatePunctuationAndCriteriaQuantityAboutStringsCriteria(
      categoriesCriteria,
      candidate,
    );

  const professionalProfileAndTestsResults =
    getCandidatePunctuationAndCriteriaQuantityAboutProfessionalProfileAndTestsCriteria(
      categoriesCriteria,
      candidate,
    );

  const candidatePunctuation =
    stringsResults.candidatePunctuation +
    professionalProfileAndTestsResults.candidatePunctuation;

  const criteriaQuantity =
    stringsResults.criteriaQuantity +
    professionalProfileAndTestsResults.criteriaQuantity;

  return Math.round((candidatePunctuation / criteriaQuantity) * 100);
};
