import { Depth16 } from 'assets/colors/boxShadows';
import {
  BackgroundColor,
  HighEmphasis,
  MediumEmphasis,
  OutlineColor,
} from 'assets/colors/palette';
import { Regular14Styles } from 'assets/fonts/fontStyles';
import styled, { css } from 'styled-components';

export interface StepExhibitionProps {
  backgroundColor?: string;
}

interface StepOptionsProps {
  isVisible: boolean;
}

export const StepOptions = styled.div<StepOptionsProps>`
  width: 296px;
  background: ${BackgroundColor};
  box-shadow: ${Depth16};
  transition: 0.3s;

  position: absolute;
  border: 1px solid ${OutlineColor};
  border-radius: 8px;
  z-index: 2;
  left: 0;
  bottom: 0;

  ${({ isVisible }) =>
    isVisible
      ? css`
          opacity: 1;
          visibility: visible;
        `
      : css`
          visibility: hidden;
          opacity: 0;
          overflow: hidden;
        `}
`;

export const SearchStepsInputContainer = styled.div`
  width: 100%;
  padding: 20px;
  border-radius: 8px;
`;

export const DeleteCandidateStepContainer = styled.div<StepExhibitionProps>`
  ${Regular14Styles}

  width: max-content;
  height: 24px;
  padding: 2px 10px;
  border-radius: 4px;

  color: ${({ backgroundColor }) =>
    backgroundColor ? HighEmphasis : MediumEmphasis};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ?? 'rgba(220, 220, 220, 0.2)'};

  &,
  button {
    display: flex;
    align-items: center;
  }

  button {
    background-color: transparent;
    outline: 0;

    img {
      margin-left: 6px;
      width: 12px;
      height: 12px;
    }
  }
`;

export const SearchStepsInput = styled.input`
  ${Regular14Styles}

  width: 100%;
  outline: 0;

  &::placeholder {
    color: ${OutlineColor};
  }
`;

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  border-top: 1px solid ${OutlineColor};
  padding-bottom: 16px;

  p {
    ${Regular14Styles}
    color: ${MediumEmphasis};

    width: 258px;
    padding-block: 20px;
  }
`;

export const StepName = styled.div<StepExhibitionProps>`
  ${Regular14Styles}

  display: flex;
  align-items: center;
  justify-content: center;

  height: 24px;
  padding: 2px 10px;
  border-radius: 4px;

  color: ${({ backgroundColor }) =>
    backgroundColor ? HighEmphasis : MediumEmphasis};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ?? 'rgba(220, 220, 220, 0.2)'};
`;

export const CreateStepButton = styled.button`
  width: 276px;
  padding: 6px 9px;
  border-radius: 4px;
  background-color: transparent;

  display: flex;
  margin-inline: auto;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  transition: 0.2s;

  &:hover {
    background-color: rgba(217, 217, 217, 0.2);
  }

  span {
    ${Regular14Styles}
    color: ${MediumEmphasis};
  }
`;

export const OpenCustomStepsContainer = styled.button<StepExhibitionProps>`
  ${Regular14Styles}

  height: 24px;
  padding: 2px 10px;
  border-radius: 4px;

  color: ${({ backgroundColor }) =>
    backgroundColor ? HighEmphasis : MediumEmphasis};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ?? 'rgba(220, 220, 220, 0.2)'};
`;

export const Container = styled.div`
  position: absolute;
  bottom: 0;
  height: 100%;
  left: 408px;

  display: flex;
  align-items: center;
  justify-content: center;
`;
