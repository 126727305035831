import { ReactInputState } from 'types/reactInputState';

const emptyState = {
  setFunction: (): null => null,
  value: '',
};

export const emptyReactInputState: ReactInputState = {
  name: '',
  errorMessageState: emptyState,
  mainState: emptyState,
};
