import optionsIcon from 'assets/img/options.svg';
import closeIcon from 'assets/img/close.svg';
import accountConfigIconWhite from 'assets/img/accountConfigIconWhite.svg';
import candidateIcon from 'assets/img/candidateIcon.svg';
import exitIcon from 'assets/img/exitIcon.svg';
import helpCenterIconWhite from 'assets/img/helpCenterIconWhite.svg';
import searchIcon from 'assets/img/search.svg';
import applicationTwo from 'assets/img/applicationTwo.svg';
import checkOneBlack from 'assets/img/checkOneBlack.svg';

import { AboutButtons } from 'components/AboutButtons';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import {
  routeCandidatedVacancies,
  routeFindVacancies,
  routeCandidateTests,
  routeEditCandidateResume,
} from 'routes/candidatesRoutes/candidatesRoutesAddresses';
import {
  routeAccountConfiguration,
  routeHelpCenter,
} from 'routes/routesAddresses';
import { useAuth } from 'hooks/auth';
import {
  ButtonsContainer,
  Container,
  MobileButton,
  OptionsContainer,
} from './styles';

const MOBILE_BUTTONS = [
  [
    {
      name: 'Minhas vagas',
      icon: applicationTwo,
      route: routeCandidatedVacancies,
    },
    {
      name: 'Mural de vagas',
      icon: searchIcon,
      route: routeFindVacancies,
    },
    {
      name: 'Avaliações',
      icon: checkOneBlack,
      route: routeCandidateTests,
    },
  ],
  [
    {
      name: 'Meu perfil',
      icon: candidateIcon,
      route: routeEditCandidateResume,
    },
    {
      name: 'Configurações',
      icon: accountConfigIconWhite,
      route: routeAccountConfiguration,
    },
    {
      name: 'Central de ajuda',
      icon: helpCenterIconWhite,
      route: routeHelpCenter,
    },
    {
      name: 'Sair',
      icon: exitIcon,
      route: '',
    },
  ],
];

export const CandidateMobileButtons: React.FC = () => {
  const history = useHistory();
  const { signOut } = useAuth();
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);

  return (
    <Container>
      <button
        type="button"
        onClick={() => setIsOptionsVisible(previousValue => !previousValue)}
      >
        <img
          src={isOptionsVisible ? closeIcon : optionsIcon}
          alt={isOptionsVisible ? 'Fechar' : 'Opções'}
        />
      </button>

      <OptionsContainer isVisible={isOptionsVisible}>
        {MOBILE_BUTTONS.map(buttons => (
          <ButtonsContainer key={buttons[0].name}>
            {buttons.map(({ name, icon, route }) => (
              <MobileButton
                key={name}
                type="button"
                onClick={() => {
                  setIsOptionsVisible(false);

                  if (name === 'Sair') signOut();
                  else history.push(route);
                }}
              >
                <i>
                  <img src={icon} alt={name} />
                </i>
                <span>{name}</span>
              </MobileButton>
            ))}
          </ButtonsContainer>
        ))}

        <AboutButtons style={{ width: 261 }} />
      </OptionsContainer>
    </Container>
  );
};
