import { FormWithStepsContainer } from 'assets/styles/formWithStepsStyles/formWithStepsContainerStyles';
import {
  StepSubTitle,
  StepTitle,
  TitleContainer,
} from 'assets/styles/formWithStepsStyles/formWithStepsTitlesStyles';
import { PlusButton } from 'components/Buttons';
import { CheckboxInput } from 'components/Input/CheckboxInput';
import { convertParsedValidationErrorsToArrayOfErrors } from 'errors/convertParsedValidationErrorsToArrayOfErrors';
import { getValidationErrors } from 'errors/getValidationErrors';
import { useWindowWidth } from 'hooks/windowWidth';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { OptionTypeBase } from 'react-select';
import { findAllStates } from 'services/entitiesServices/stateServices';
import { IFormStepProps } from 'types';
import { ICandidateProfessionalExperienceRequest } from 'types/candidate';
import { convertLocationToOptionTypeBase } from 'utils/conversion/convertLocationToOptionTypeBase';
import { generateBrazilianMonthOptions } from 'utils/typeOptions/generateBrazilianMonthOptions';
import { generateYearOptions } from 'utils/typeOptions/generateYearOptions';
import { ValidationError } from 'yup';
import { ProfessionalExperienceSubForm } from './ProfessionalExperienceSubForm';
import { CheckBoxContainer, InputsContainer } from './styles';

export { validateProfessionalExperiencesData } from './validateProfessionalExperiencesData';

export interface ProfessionalExperiencesStepData {
  hasProfessionalExperience: boolean;
  professionalExperiences: ICandidateProfessionalExperienceRequest[];
}

const BRAZILIAN_MONTHS_OPTIONS = generateBrazilianMonthOptions();
const YEAR_OPTIONS = generateYearOptions(true);

export const ProfessionalExperiencesStep: React.FC<
  IFormStepProps<ProfessionalExperiencesStepData>
> = ({
  initialData = {} as ProfessionalExperiencesStepData,
  setStepDataFunction,
  errors,
}) => {
  const {
    hasProfessionalExperience: initialHasProfessionalExperience,
    professionalExperiences: initialProfessionalExperiences,
  } = initialData;
  const { isMobileScreen } = useWindowWidth();

  const [hasProfessionalExperience, setHasProfessionalExperience] = useState(
    initialHasProfessionalExperience ?? true,
  );
  const [professionalExperiences, setProfessionalExperiences] = useState<
    ICandidateProfessionalExperienceRequest[]
  >(
    initialProfessionalExperiences ?? [
      {} as ICandidateProfessionalExperienceRequest,
    ],
  );
  const [states, setStates] = useState<OptionTypeBase[]>([]);

  const professionalExperiencesErrors = useMemo(() => {
    if (!errors) return [];
    if (!(errors instanceof ValidationError)) return [];

    return convertParsedValidationErrorsToArrayOfErrors(
      getValidationErrors(errors),
    );
  }, [errors]);

  useEffect(() => {
    findAllStates().then(findedStates => {
      setStates(
        findedStates.map(findedState =>
          convertLocationToOptionTypeBase(findedState, true),
        ),
      );
    });
  }, []);

  useEffect(() => {
    if (setStepDataFunction) {
      const parsedProfessionalExperiences = hasProfessionalExperience
        ? professionalExperiences
        : [];

      setStepDataFunction({
        hasProfessionalExperience,
        professionalExperiences: parsedProfessionalExperiences,
      });
    }
  }, [hasProfessionalExperience, professionalExperiences, setStepDataFunction]);

  const handleCreateProfessionalExperience = useCallback(() => {
    setProfessionalExperiences(previousProfessionalExperiences => [
      ...previousProfessionalExperiences,
      {} as ICandidateProfessionalExperienceRequest,
    ]);
  }, []);

  const handleUpdateProfessionalExperience = useCallback(
    (
      updatedProfessionalExperience: ICandidateProfessionalExperienceRequest,
      index: number,
    ) => {
      setProfessionalExperiences(previousProfessionalExperiences =>
        previousProfessionalExperiences.map(
          (previousProfessionalExperience, ind) =>
            ind === index
              ? updatedProfessionalExperience
              : previousProfessionalExperience,
        ),
      );
    },
    [],
  );

  const handleDeleteProfessionalExperience = useCallback(
    professionalExperienceIndex => {
      setProfessionalExperiences(previousProfessionalExperiences =>
        previousProfessionalExperiences.filter(
          (_, index) => index !== professionalExperienceIndex,
        ),
      );
    },
    [],
  );

  const ProfessionalExperiencesComponents = useMemo(() => {
    if (!hasProfessionalExperience) return [];

    return professionalExperiences.map((professionalExperience, index) => (
      <ProfessionalExperienceSubForm
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        index={index}
        statesOptions={states}
        handleDeleteSubFormData={() =>
          handleDeleteProfessionalExperience(index)
        }
        handleUpdateSubFormData={updatedProfessionalExperience =>
          handleUpdateProfessionalExperience(
            updatedProfessionalExperience,
            index,
          )
        }
        initialData={professionalExperience}
        monthOptions={BRAZILIAN_MONTHS_OPTIONS}
        yearOptions={YEAR_OPTIONS}
        errors={professionalExperiencesErrors[index]}
      />
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    handleDeleteProfessionalExperience,
    handleUpdateProfessionalExperience,
    professionalExperiencesErrors,
    hasProfessionalExperience,
    states,
    professionalExperiences.length,
  ]);

  return (
    <FormWithStepsContainer>
      <TitleContainer>
        <StepTitle>Experiência profissional</StepTitle>
        <StepSubTitle>
          Esta é a seção mais importante do seu currículo. Suas experências
          profissionais serão avaliadas pelos recrutadores da vaga.
        </StepSubTitle>
      </TitleContainer>

      <InputsContainer>
        <CheckBoxContainer>
          <CheckboxInput
            defaultChecked={!hasProfessionalExperience}
            handleCheckboxUpdate={checked =>
              setHasProfessionalExperience(!checked)
            }
            label="Ainda não tive experiências profissionais"
          />
        </CheckBoxContainer>

        {ProfessionalExperiencesComponents.map(
          ProfessionalExperienceComponent => ProfessionalExperienceComponent,
        )}

        {hasProfessionalExperience && (
          <PlusButton
            colorStyle="outline"
            onClick={handleCreateProfessionalExperience}
            style={{ marginTop: 12, width: isMobileScreen ? '90vw' : 'auto' }}
          >
            Adicionar outra experiência
          </PlusButton>
        )}
      </InputsContainer>
    </FormWithStepsContainer>
  );
};
