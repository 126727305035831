import closeIcon from 'assets/img/close.svg';
import { formStepInputStyles } from 'assets/styles/formWithStepsStyles/formWithStepsInputStyles';

import { PlusButton } from 'components/Buttons';
import { Input } from 'components/Input';
import { Select } from 'components/Select';
import { useCallback, useEffect, useState } from 'react';
import { IFormStepProps } from 'types/IFormStepProps';
import { useInputStates } from 'utils/customHooks';

import { convertLanguageFromObjectToString } from 'utils/conversion/convertLanguageFromObjectToString';
import {
  StepSubTitle,
  StepTitle,
  TitleContainer,
} from 'assets/styles/formWithStepsStyles/formWithStepsTitlesStyles';
import { FormWithStepsContainer } from 'assets/styles/formWithStepsStyles/formWithStepsContainerStyles';
import { OmitId } from 'types';
import { ILanguage } from 'types/languageEntity';
import { languageLevelOptions } from 'utils/typeOptions/languageLevelOptions';
import { getInputStateValue, stringsAreEqual } from 'utils';
import {
  InputsContainer,
  Language,
  LanguagesContainer,
  LanguageInputContainer,
} from './styles';

export { validateLanguagesData } from './validateLanguagesData';

export interface LanguagesStepData {
  languages: OmitId<ILanguage>[];
}

export const LanguagesStep: React.FC<IFormStepProps<LanguagesStepData>> = ({
  initialData = {} as LanguagesStepData,
  setStepDataFunction,
}) => {
  const { languages: initialLanguages } = initialData;

  const languageInputStates = useInputStates('languageInput');
  const levelInputStates = useInputStates('levelInput');
  const [languages, setLanguages] = useState<OmitId<ILanguage>[]>(
    initialLanguages ?? [],
  );

  const findLanguageIndexByName = useCallback(
    (languageName: string) =>
      languages.findIndex(({ name }) => stringsAreEqual(name, languageName)),
    [languages],
  );

  const handleAddLanguage = useCallback(() => {
    const { value: languageValue, setFunction } = languageInputStates.mainState;
    if ((languageValue as string).length === 0) return;
    const levelValue = getInputStateValue(levelInputStates);

    if (findLanguageIndexByName(languageValue as string) !== -1) {
      languageInputStates.errorMessageState.setFunction('Idioma já adicionado');
    } else {
      setLanguages(previousLanguages => [
        ...previousLanguages,
        {
          name: languageValue as string,
          level: languageLevelOptions[Number(levelValue) - 1].enumValue,
        },
      ]);
    }

    setFunction('');
  }, [
    findLanguageIndexByName,
    languageInputStates.errorMessageState,
    languageInputStates.mainState,
    levelInputStates,
  ]);

  const handleExcludeLanguage = useCallback((languageName: string) => {
    setLanguages(previousLanguages =>
      previousLanguages.filter(
        comparativeLanguage => comparativeLanguage.name !== languageName,
      ),
    );
  }, []);

  useEffect(() => {
    if (setStepDataFunction) {
      setStepDataFunction({
        languages,
      });
    }
  }, [languages, setStepDataFunction]);

  return (
    <FormWithStepsContainer>
      <TitleContainer>
        <StepTitle>Idiomas</StepTitle>
        <StepSubTitle>
          Adicione até 4 idiomas que os candidatos precisam dominar para
          trabalhar nesta vaga
        </StepSubTitle>
      </TitleContainer>

      <InputsContainer>
        <span>{languages?.length ?? 0}/4 idiomas selecionados</span>

        <LanguageInputContainer>
          <Input
            name="Idioma"
            disabled={languages?.length === 4}
            states={languageInputStates}
            placeholder="inserir idioma"
            style={{ ...formStepInputStyles, width: 274 }}
          />

          <Select
            name="Nível"
            placeholder="nível"
            disabled={languages?.length === 4}
            states={levelInputStates}
            selectOptions={languageLevelOptions}
            loadingIndicatorDisabled
            style={{ ...formStepInputStyles, width: 202 }}
          />
        </LanguageInputContainer>

        <PlusButton
          onClick={handleAddLanguage}
          disabled={
            !languageInputStates.mainState.value || languages?.length === 4
          }
          colorStyle="outline"
        >
          Adicionar
        </PlusButton>
      </InputsContainer>

      <LanguagesContainer>
        {languages.map(({ name, level }) => (
          <Language key={name}>
            <span>{convertLanguageFromObjectToString({ name, level })}</span>

            <button type="button" onClick={() => handleExcludeLanguage(name)}>
              <img src={closeIcon} alt="Excluir idioma" />
            </button>
          </Language>
        ))}
      </LanguagesContainer>
    </FormWithStepsContainer>
  );
};
