import { UserIcon } from 'components/UserIcon';
import { format } from 'date-fns';
import { CandidateEvaluation } from 'types/CandidateEvaluation';
import { IFiledStartupCandidateResponseWithoutFolder } from 'types/filedStartupCandidate';
import { IVacancyCandidateWithCandidateData } from 'types/vacancyCandidate';
import { CandidateButton, CandidateNameAndIcon, Container } from './styles';

interface SearchedCandidateProps {
  startupCandidate:
    | IVacancyCandidateWithCandidateData
    | IFiledStartupCandidateResponseWithoutFolder;
  candidateIndex: number;
  selectedCandidateIndex: number | null;
  handleSelectCandidate: (candidateIndex: number) => Promise<void>;
}

export const SearchedCandidate: React.FC<SearchedCandidateProps> = ({
  startupCandidate,
  candidateIndex,
  selectedCandidateIndex,
  handleSelectCandidate,
  children,
}) => {
  const {
    id,
    candidateEvaluation,
    createdAt,
    candidate: {
      photo,
      user: { name: candidateName, lastName: candidateLastName },
    },
  } = startupCandidate;

  return (
    <Container key={id}>
      <CandidateButton
        type="button"
        onClick={() => handleSelectCandidate(candidateIndex)}
        isSelected={candidateIndex === selectedCandidateIndex}
      >
        <CandidateNameAndIcon className="candidates-column">
          <UserIcon image={photo} userName={candidateName} />

          <div>
            <p>{`${candidateName} ${candidateLastName}`}</p>
            <small>
              Inscrito em {format(new Date(createdAt), 'dd/MM/yyyy')}
            </small>
          </div>
        </CandidateNameAndIcon>

        {candidateEvaluation === CandidateEvaluation.NEW_CANDIDATE && (
          <span className="new-candidate-indicator">NOVO</span>
        )}
      </CandidateButton>

      {children}
    </Container>
  );
};
