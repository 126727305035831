import { CandidateTest, ICandidateTestEvaluation } from 'types/candidate';
import { api } from '../api';

const servicesPrefix = 'candidateTestEvaluation';

export const createCandidateTestEvaluation =
  async (createCandidateTestEvaluationData: {
    candidateTest: CandidateTest;
    evaluation: string;
  }): Promise<ICandidateTestEvaluation> => {
    const { data } = await api.post<ICandidateTestEvaluation>(
      `${servicesPrefix}`,
      createCandidateTestEvaluationData,
    );

    return data;
  };

export const findAllTestEvaluationsByCandidate = async (
  candidateId: number,
): Promise<ICandidateTestEvaluation[]> => {
  const { data } = await api.get<ICandidateTestEvaluation[]>(
    `${servicesPrefix}/findAllByCandidateId/${candidateId}`,
  );

  return data;
};
