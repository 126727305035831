import { OptionTypeBase } from 'react-select';

export const businessModelOptions: OptionTypeBase[] = [
  {
    id: 1,
    model: 'B2B',
  },
  {
    id: 2,
    model: 'B2C',
  },
  {
    id: 3,
    model: 'B2B2C',
  },
  {
    id: 4,
    model: 'B2G',
  },
  {
    id: 5,
    model: 'Economia colaborativa',
  },
  {
    id: 6,
    model: 'Franchising',
  },
  {
    id: 7,
    model: 'Freemium',
  },
  {
    id: 8,
    model: 'Adware',
  },
];
