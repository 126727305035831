import in6Logo from 'assets/img/in6Logo.svg';
import whiteArrowRight from 'assets/img/whiteArrowRight.svg';
import emailSuccessfullyIcon from 'assets/img/emailSuccessfully.svg';

import { PrimaryButton } from 'components/Buttons';
import { Loader } from 'components/Loader';
import { Header } from 'components/Header';
import { activateAccount } from 'services/activateAccountServices';
import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { COMPANY_NAME } from 'constants/company';
import { routeBar, routeLogin } from 'routes/routesAddresses';
import { ERROR_MODAL_CONFIGURATION, useAlertModal } from 'hooks/alertModal';
import { useAuth } from 'hooks/auth';
import { MainContainer, SubContainer, Title, Text } from './styles';

export const ActivationPage: React.FC = () => {
  document.title = `Ativar Conta - ${COMPANY_NAME}`;
  const history = useHistory();
  const { signOut } = useAuth();
  const { showModal } = useAlertModal();
  const { activationToken } = useParams() as { activationToken: string };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    activateAccount(activationToken)
      .then(() => {
        setIsLoading(false);
        signOut();
      })
      .catch(() => {
        showModal({
          ...ERROR_MODAL_CONFIGURATION,
          message: 'Algo deu errado, por favor, recarregue a página',
        });

        history.push(routeBar);
      });
  }, [activationToken, history, showModal, signOut]);

  if (isLoading) return <Loader />;
  return (
    <>
      <Header logo={in6Logo} redirectRoute={routeBar} />

      <MainContainer>
        <SubContainer>
          <img src={emailSuccessfullyIcon} alt="Conta verificada" />

          <Title>Conta verificada.</Title>

          <Text>
            Obrigado por confirmar seu endereço de email. Clique no botão abaixo
            para continuar, utilize seu email e senha para acessar sua conta.
          </Text>

          <PrimaryButton
            type="button"
            className="primaryButton"
            onClick={() => history.push(routeLogin)}
          >
            <span>Acessar minha conta</span>
            <img src={whiteArrowRight} alt="Seta para a direita" />
          </PrimaryButton>
        </SubContainer>
      </MainContainer>
    </>
  );
};
