import timeIcon from 'assets/img/time.svg';

import { format } from 'date-fns';
import { useMemo } from 'react';
import { IVacancyResponse, VacancyStatus } from 'types/vacancy';
import { getVacancyDateGapFromActualDate } from 'utils/conversion/getVacancyDateGapFromActualDate';
import { Container } from './styles';

interface VacancyPeriodProps {
  vacancy: IVacancyResponse;
}

export const VacancyPeriod: React.FC<VacancyPeriodProps> = ({
  vacancy: { createdAt, suspensionDate, closeDate, vacancyStatus },
}) => {
  const otherDateString = useMemo(() => {
    if (vacancyStatus === VacancyStatus.ACTIVE) return '';

    const dateToShow = (suspensionDate ?? closeDate) as Date;
    const spanInitialText = `${
      suspensionDate !== null ? 'Suspensa' : 'Encerrada'
    } em`;

    const formattedDate = format(new Date(dateToShow), 'dd/MM/yyyy');
    const actualDateGap = getVacancyDateGapFromActualDate(createdAt);

    return `${spanInitialText} ${formattedDate} (${actualDateGap})`;
  }, [closeDate, createdAt, suspensionDate, vacancyStatus]);

  return (
    <Container>
      <img src={timeIcon} alt="Relógio" />
      <span>
        Publicada em {format(new Date(createdAt), 'dd/MM/yyyy')} (
        {getVacancyDateGapFromActualDate(createdAt)})
      </span>

      {otherDateString && (
        <>
          <span>|</span>
          <span>{otherDateString}</span>
        </>
      )}
    </Container>
  );
};
