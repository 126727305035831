import { SecondaryButton } from 'components/Buttons';
import { CloseButton, Container, Header, MiddleContainer } from './styles';

interface QuitTestModalProps {
  handleQuitTest: () => void;
  handleCloseModal: () => void;
}

export const QuitTestModal: React.FC<QuitTestModalProps> = ({
  handleQuitTest,
  handleCloseModal,
}) => {
  return (
    <Container>
      <Header>Sair do teste</Header>

      <MiddleContainer>
        <p>
          Deseja realmente sair do teste? Seu progresso será perdido e será
          preciso refazer as questões novamente.
        </p>

        <SecondaryButton type="button" onClick={handleQuitTest}>
          Sair do teste
        </SecondaryButton>

        <CloseButton type="button" onClick={handleCloseModal}>
          Cancelar
        </CloseButton>
      </MiddleContainer>
    </Container>
  );
};
