import openOptions from 'assets/img/openOptions.svg';
import closeOne from 'assets/img/closeOne.svg';
import checkOneBlack from 'assets/img/checkOneBlack.svg';
import folderOpen from 'assets/img/folderOpen.svg';
import deleteIcon from 'assets/img/delete.svg';

import { Modal } from 'components/Modal';
import { DeleteModal } from 'components/Modal/DeleteModal';
import { ERROR_MODAL_CONFIGURATION, useAlertModal } from 'hooks/alertModal';
import { useAuth } from 'hooks/auth';
import { useStartup } from 'hooks/startup';
import { useCallback, useState } from 'react';
import { IStartupCandidatesFolderBasicData } from 'types/startupCandidatesFolder/IStartupCandidatesFolderBasicData';
import { FileResumeModal } from './FileResumeModal';
import {
  Container,
  OptionsContainer,
  OpenOptionsButton,
  OptionsSection,
} from './styles';

interface OptionsColumnDataProps {
  startupFolders: IStartupCandidatesFolderBasicData[] | null;
  handleCreateStartupFolder: (folderName: string) => Promise<void>;
  handleDeleteCandidate: () => Promise<void>;
  handleCreateFiledStartupCandidate: (
    startupFoldersIds: number[],
  ) => Promise<void>;
}

export const OptionsColumnData: React.FC<OptionsColumnDataProps> = ({
  handleDeleteCandidate,
  handleCreateStartupFolder,
  handleCreateFiledStartupCandidate,
  startupFolders,
}) => {
  const { showModal } = useAlertModal();
  const { updateIsShowingSubscribeModal } = useStartup();
  const { user } = useAuth();

  const [isOptionsVisible, setIsOptionsVisible] = useState(false);

  const [isFileResumeModalVisible, setIsFileResumeModalVisible] =
    useState(false);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isLoadingCandidateDelete, setIsLoadingCandidateDelete] =
    useState(false);

  const handleDeleteCandidateButtonClick = useCallback(async () => {
    try {
      setIsLoadingCandidateDelete(true);
      await handleDeleteCandidate();
    } catch (err) {
      showModal({
        ...ERROR_MODAL_CONFIGURATION,
        message:
          'Erro ao excluir o candidato, por favor, tente novamente mais tarde',
      });

      setIsLoadingCandidateDelete(false);
      setIsDeleteModalVisible(false);
    }
  }, [handleDeleteCandidate, showModal]);

  const handleFileResumeButtonClick = useCallback(() => {
    if (user?.subscriber) setIsFileResumeModalVisible(true);
    else updateIsShowingSubscribeModal(true);
  }, [updateIsShowingSubscribeModal, user?.subscriber]);

  return (
    <Container>
      <Modal isVisible={isDeleteModalVisible}>
        <DeleteModal
          isLoadingDelete={isLoadingCandidateDelete}
          modalTitle="Você tem certeza que deseja excluir esse currículo?"
          deleteButtonText="Excluir currículo"
          hideDeleteModal={() => setIsDeleteModalVisible(false)}
          handleDelete={handleDeleteCandidateButtonClick}
        />
      </Modal>

      <Modal
        isVisible={isFileResumeModalVisible}
        onClick={() => setIsFileResumeModalVisible(false)}
        onKeyDown={({ key }) =>
          key === 'Escape' && setIsFileResumeModalVisible(false)
        }
      >
        <FileResumeModal
          startupFolders={startupFolders}
          handleCreateStartupFolder={handleCreateStartupFolder}
          handleCreateFiledStartupCandidate={handleCreateFiledStartupCandidate}
        />
      </Modal>

      <OptionsContainer isOptionsVisible={isOptionsVisible}>
        <OptionsSection>
          <h4>ALTERAR STATUS</h4>

          <button type="button">
            <img src={closeOne} alt="Reprovar" />
            <span>Reprovar</span>
          </button>

          <button type="button">
            <img src={checkOneBlack} alt="Aprovar" />
            <span>Aprovar</span>
          </button>
        </OptionsSection>

        <OptionsSection>
          <h4>AÇÕES</h4>

          <button type="button" onClick={handleFileResumeButtonClick}>
            <img src={folderOpen} alt="Arquivar" />
            <span>Arquivar</span>
          </button>

          <button type="button" onClick={() => setIsDeleteModalVisible(true)}>
            <img src={deleteIcon} alt="Excluir" />
            <span>Excluir</span>
          </button>
        </OptionsSection>
      </OptionsContainer>

      <OpenOptionsButton
        type="button"
        onClick={() => setIsOptionsVisible(value => !value)}
        onBlur={() => setIsOptionsVisible(false)}
        isOptionsVisible={isOptionsVisible}
      >
        <img src={openOptions} alt="Três pontos verticais" />
      </OpenOptionsButton>
    </Container>
  );
};
