import closeIcon from 'assets/img/close.svg';
import { FormWithStepsContainer } from 'assets/styles/formWithStepsStyles/formWithStepsContainerStyles';
import { formStepInputStyles } from 'assets/styles/formWithStepsStyles/formWithStepsInputStyles';
import {
  StepSubTitle,
  StepTitle,
  TitleContainer,
} from 'assets/styles/formWithStepsStyles/formWithStepsTitlesStyles';
import { PlusButton } from 'components/Buttons';
import { Select } from 'components/Select';
import { useCallback, useEffect, useState } from 'react';
import { OptionTypeBase } from 'react-select';
import {
  findAllCitiesByStateId,
  findCityByIdWithStateData,
} from 'services/entitiesServices/cityServices';
import { findAllStates } from 'services/entitiesServices/stateServices';
import { IFormStepProps, OmitId } from 'types';
import { ILocation } from 'types/location';
import { useInputStates } from 'utils/customHooks';

import { convertCityAndStateFromObjectToString } from 'utils/conversion/convertCityAndStateFromObjectToString';
import { convertLocationToOptionTypeBase } from 'utils/conversion/convertLocationToOptionTypeBase';
import { ERROR_MODAL_CONFIGURATION, useAlertModal } from 'hooks/alertModal';
import {
  InputsContainer,
  Location,
  LocationInputContainer,
  LocationsContainer,
} from './styles';

export { validateLocationData } from './validateLocationData';

type LocationResponseWithoutId = OmitId<ILocation>;

export interface LocationStepData {
  startupLocations: LocationResponseWithoutId[];
}

export const LocationStep: React.FC<IFormStepProps<LocationStepData>> = ({
  initialData = {} as LocationStepData,
  setStepDataFunction,
  errors,
}) => {
  const { startupLocations: initialLocations } = initialData;
  const { showModal } = useAlertModal();

  const stateInputStates = useInputStates('stateInput');
  const cityInputStates = useInputStates('cityInput');

  const [startupLocations, setStartupLocations] = useState<
    LocationResponseWithoutId[]
  >(initialLocations ?? []);

  const [states, setStates] = useState<OptionTypeBase[]>([]);
  const [isLoadingStates, setIsLoadingStates] = useState(true);

  const [cities, setCities] = useState<OptionTypeBase[]>([]);
  const [isLoadingCities, setIsLoadingCities] = useState(true);

  useEffect(() => {
    const locationsPromises = startupLocations.map(({ city: { id: cityId } }) =>
      findCityByIdWithStateData(cityId),
    );

    Promise.all(locationsPromises).then(parsedLocations =>
      setStartupLocations(parsedLocations as ILocation[]),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (setStepDataFunction) {
      setStepDataFunction({
        startupLocations,
      });
    }
  }, [setStepDataFunction, startupLocations]);

  useEffect(() => {
    if (!errors) return;

    const { message } = errors;
    showModal({ ...ERROR_MODAL_CONFIGURATION, message });
  }, [errors, showModal]);

  useEffect(() => {
    findAllStates().then(findedStates => {
      setStates(
        findedStates.map(state => convertLocationToOptionTypeBase(state, true)),
      );
      setIsLoadingStates(false);
    });
  }, []);

  useEffect(() => {
    const stateId = Number(stateInputStates.mainState.value as string);
    if (stateId === 0) {
      cityInputStates.mainState.setFunction('');
      return;
    }

    setIsLoadingCities(true);
    findAllCitiesByStateId(stateId).then(findedCities => {
      setCities(
        findedCities.map(city => convertLocationToOptionTypeBase(city)),
      );

      cityInputStates.mainState.setFunction('');
      cityInputStates.errorMessageState.setFunction('');
      setIsLoadingCities(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cityInputStates.errorMessageState.setFunction,
    cityInputStates.mainState.setFunction,
    stateInputStates.mainState.value,
  ]);

  const findLocationIndexByCityId = useCallback(
    (cityId: number) =>
      startupLocations.findIndex(({ city: { id } }) => id === cityId),
    [startupLocations],
  );

  const handleAddLocation = useCallback(async () => {
    const cityId = Number(cityInputStates.mainState.value as string);

    if (findLocationIndexByCityId(cityId) !== -1) {
      cityInputStates.errorMessageState.setFunction(
        'Localização já adicionada',
      );
      return;
    }

    const stateId = Number(stateInputStates.mainState.value as string);
    const { label: cityName } = cities.find(({ id }) => id === cityId)!;
    const { label: stateName } = states.find(({ id }) => id === stateId)!;

    const newLocation: LocationResponseWithoutId = {
      city: {
        id: cityId,
        name: cityName,
      },
      state: {
        id: stateId,
        name: stateName,
        initials: stateName,
      },
    };

    setStartupLocations(previousLocations => [
      ...previousLocations,
      newLocation,
    ]);
    stateInputStates.mainState.setFunction('');
  }, [
    cities,
    cityInputStates.errorMessageState,
    cityInputStates.mainState.value,
    findLocationIndexByCityId,
    stateInputStates.mainState,
    states,
  ]);

  const handleExcludeLocation = useCallback((cityId: number) => {
    setStartupLocations(previousLocations =>
      previousLocations.filter(({ city: { id } }) => id !== cityId),
    );
  }, []);

  return (
    <FormWithStepsContainer>
      <TitleContainer>
        <StepTitle>Onde sua empresa funciona</StepTitle>
        <StepSubTitle>
          Inclua o endereço de funcionamento de sua empresa. Se vocês atuam em
          mais de uma cidade, pode adicioná-las também.
        </StepSubTitle>
      </TitleContainer>

      <InputsContainer>
        <LocationInputContainer>
          <Select
            name="Estado"
            placeholder="selecionar"
            states={stateInputStates}
            disabled={isLoadingStates}
            selectOptions={states}
            style={{ ...formStepInputStyles, width: 167 }}
            startWithoutValue
          />

          <Select
            name="Cidade"
            placeholder="selecionar"
            disabled={isLoadingCities}
            states={cityInputStates}
            selectOptions={cities}
            loadingIndicatorDisabled
            style={{ ...formStepInputStyles, width: 309 }}
            startWithoutValue
          />
        </LocationInputContainer>

        <PlusButton
          onClick={handleAddLocation}
          colorStyle="outline"
          disabled={
            !stateInputStates.mainState.value ||
            !cityInputStates.mainState.value
          }
        >
          Adicionar endereço
        </PlusButton>
      </InputsContainer>

      <LocationsContainer>
        {startupLocations.map(({ city, state }) => (
          <Location key={city.id}>
            <span>
              {convertCityAndStateFromObjectToString(
                { city, state },
                ', ',
                true,
              )}
            </span>

            <button
              type="button"
              onClick={() => handleExcludeLocation(city.id)}
            >
              <img src={closeIcon} alt="Excluir localização" />
            </button>
          </Location>
        ))}
      </LocationsContainer>
    </FormWithStepsContainer>
  );
};
