import in6Logo from 'assets/img/in6Logo.svg';
import expirationIcon from 'assets/img/expirationIcon.svg';
import whiteArrowRight from 'assets/img/whiteArrowRight.svg';

import { PrimaryButton } from 'components/Buttons';

import { useHistory } from 'react-router-dom';
import { routeBar, routeDashboard, routeLogin } from 'routes/routesAddresses';

import { Header } from 'components/Header';
import { COMPANY_NAME } from 'constants/company';
import { useEffect } from 'react';
import { localStorageKeys } from 'constants/localStorageKeys';
import { MainContainer, SubContainer, Text, Title } from './styles';

export const ExpirationPage: React.FC = () => {
  document.title = `Sessão Expirada - ${COMPANY_NAME}`;
  const history = useHistory();

  useEffect(() => {
    const existsUserOnLocalStorage = !!localStorage.getItem(
      localStorageKeys.user,
    );
    if (existsUserOnLocalStorage) history.push(routeDashboard);

    return () => {
      document.title = COMPANY_NAME;
    };
  }, [history]);

  return (
    <>
      <Header logo={in6Logo} redirectRoute={routeBar} />

      <MainContainer>
        <SubContainer>
          <img src={expirationIcon} alt="Sessão expirada" />

          <Title>Poxa, sua sessão expirou.</Title>

          <Text>
            Você passou muito tempo inativo e, por questões de segurança,
            tivemos que encerrar sua sessão. <br />
            Para continuar usando, é só entrar na sua conta novamente.
          </Text>

          <PrimaryButton type="button" onClick={() => history.push(routeLogin)}>
            <span>Acessar minha conta</span>
            <img src={whiteArrowRight} alt="Seta para a direita" />
          </PrimaryButton>
        </SubContainer>
      </MainContainer>
    </>
  );
};
