import { TextGradientPrimaryBackground } from 'assets/colors/background';
import {
  BackgroundColor,
  MediumEmphasis,
  OutlineColor,
  PrimaryColor,
} from 'assets/colors/palette';
import {
  Bold18Styles,
  Medium16Styles,
  Regular14Styles,
  Regular16Styles,
} from 'assets/fonts/fontStyles';
import { PrimaryButton } from 'components/Buttons';
import styled, { css } from 'styled-components';

interface AnswerProps {
  isSelected: boolean;
}

export const Container = styled.div`
  position: relative;
  min-height: calc(100vh - 64px);
  padding-bottom: 133px;

  @media (max-width: 1100px) {
    padding-bottom: 158px;
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 1248px;
  height: 64px;
  margin-bottom: 32px;
  margin-inline: auto;

  h2 {
    ${Bold18Styles}
  }

  div {
    ${Regular14Styles}

    display: flex;
    align-items: center;
    gap: 12px;
  }

  @media (max-width: 1100px) {
    width: 90vw;
    gap: 26px;

    flex-direction: column;
    align-items: flex-start;
    margin-block: 23px 56px;

    div {
      gap: 17px;
    }
  }
`;

export const TestRemainingTime = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  width: 132px;
  height: 48px;
  padding: 8px 24px;

  background-color: ${BackgroundColor};
  border: 1px solid ${OutlineColor};
  border-radius: 100px;

  span {
    ${Medium16Styles}
  }
`;

export const QuestionContainer = styled.main`
  width: 532px;
  margin-inline: auto;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  span {
    ${Regular14Styles}
    color: ${MediumEmphasis};
  }

  .question {
    ${Medium16Styles}
    line-height: 28px;
    margin-top: 12px;

    img {
      margin-top: 48px;
    }

    section {
      ${Regular14Styles}
      line-height: 28px;

      padding: 8px 16px;
      background: rgba(220, 220, 220, 0.25);
      border-radius: 8px;
      font-style: italic;
    }
  }

  @media (max-width: 1100px) {
    width: 90vw;

    .question img {
      max-width: 100%;
    }
  }
`;

export const AnswersContainer = styled.div`
  width: 532px;
  margin-inline: auto;
  margin-top: 48px;

  display: flex;
  flex-direction: column;
  gap: 24px;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media (max-width: 1100px) {
    width: 90vw;
  }
`;

export const Answer = styled.button<AnswerProps>`
  width: 100%;
  min-height: 48px;

  display: flex;
  align-items: center;
  gap: 16px;

  background-color: ${BackgroundColor};
  border: 1px solid ${OutlineColor};
  border-radius: 8px;

  padding: 12px 20px;

  span {
    ${Regular14Styles}
    line-height: 20px;
    text-align: start;
  }

  div {
    min-width: 20px;
    min-height: 20px;

    max-width: 20px;
    max-height: 20px;

    position: relative;
    border: 2px solid ${OutlineColor};
    border-radius: 50%;

    &:after {
      content: '';
      position: absolute;
      transition: 0.2s;
      opacity: 0;

      left: 5px;
      bottom: 4px;
      width: 4px;
      height: 8px;

      border: solid ${PrimaryColor};
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      &,
      div {
        border-color: ${PrimaryColor};
      }

      span {
        ${TextGradientPrimaryBackground}
        font-weight: 700;
      }

      div:after {
        opacity: 1;
      }
    `}
`;

export const Footer = styled.footer`
  width: 100%;
  height: 100px;

  position: fixed;
  left: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 96px;

  background-color: ${BackgroundColor};
  border-top: 1px solid ${OutlineColor};

  .test-step {
    ${Regular16Styles}

    span {
      color: ${MediumEmphasis};
    }
  }

  @media (max-width: 1100px) {
    padding-inline: 18px;

    ${PrimaryButton} {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;

      img {
        filter: brightness(0) invert(1);
      }
    }
  }
`;

export const MobileExitButton = styled.button`
  ${Medium16Styles}

  width: max-content;
  padding-inline: 11px;
  height: 44px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${BackgroundColor};
  border: 1px solid ${OutlineColor};
  border-radius: 100px;

  span {
    padding: 12px;
  }

  img {
    width: 20px;
    height: 20px;
  }
`;
