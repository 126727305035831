import { Depth16 } from 'assets/colors/boxShadows';
import {
  BackgroundColor,
  LowEmphasis,
  OutlineColor,
} from 'assets/colors/palette';
import { Bold14Styles, Medium16Styles } from 'assets/fonts/fontStyles';
import styled, { css } from 'styled-components';

interface OptionsProps {
  isOptionsVisible: boolean;
}

export const Container = styled.div`
  position: absolute;
  right: 8px;
  bottom: 0;
  height: 100%;

  display: flex;
  align-items: center;
`;

export const OptionsSection = styled.section`
  width: 100%;
  min-height: 50%;
  padding-block: 12px;

  display: flex;
  flex-direction: column;
  gap: 4px;

  & + & {
    border-top: 1px solid ${OutlineColor};
  }

  h4 {
    ${Bold14Styles}
    font-size: 10px;
    color: ${LowEmphasis};
    margin-left: 13px;
  }

  button {
    display: flex;
    align-items: center;
    gap: 12px;
    padding-inline: 13px;

    width: 100%;
    min-height: 36px;
    max-height: 36px;
    background-color: transparent;
    transition: 0.2s;

    span {
      ${Medium16Styles}
    }

    img {
      width: 20px;
      height: 20px;
    }

    &:hover {
      background-color: rgba(220, 220, 220, 0.2);
    }
  }
`;

export const OptionsContainer = styled.div<OptionsProps>`
  position: absolute;
  width: 164px;
  height: 237px;

  background-color: ${BackgroundColor};
  border: 1px solid ${OutlineColor};
  border-radius: 8px;
  box-shadow: ${Depth16};

  right: 48px;
  top: -86px;
  z-index: 2;
  transition: 0.4s;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  ${({ isOptionsVisible }) =>
    isOptionsVisible
      ? css`
          opacity: 1;
          visibility: visible;
        `
      : css`
          visibility: hidden;
          opacity: 0;
          overflow: hidden;
        `}
`;

export const OpenOptionsButton = styled.button<OptionsProps>`
  background: ${({ isOptionsVisible }) =>
    isOptionsVisible ? 'rgba(220,220,220,0.2)' : 'transparent'};
  outline: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;

  width: 48px;
  height: 48px;
  border: 1px solid ${OutlineColor};
  border-radius: 50%;

  &:hover {
    background-color: rgba(220, 220, 220, 0.2);
  }
`;
