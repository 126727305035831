import fileSuccessIcon from 'assets/img/fileSuccess.svg';
import arrowRight from 'assets/img/arrowRight.svg';

import { SecondaryButton } from 'components/Buttons';
import { useHistory } from 'react-router-dom';
import { routeFindVacancies } from 'routes/candidatesRoutes/candidatesRoutesAddresses';
import { Container, TextContainer } from './styles';

export const SuccessApplyModal: React.FC = () => {
  const history = useHistory();

  return (
    <Container>
      <img src={fileSuccessIcon} alt="Símbolo de sucesso" />

      <TextContainer>
        <h3>Currículo enviado!</h3>
        <p>
          Sua inscrição foi confirmada e a empresa já recebeu o seu currículo.
        </p>
        <p>
          Todas as alterações que você fizer em seu currículo a empresa terá
          acesso, portanto você pode revisá-lo sempre que precisar.
        </p>
      </TextContainer>

      <SecondaryButton
        type="button"
        onClick={() => history.push(routeFindVacancies)}
      >
        Ver outras vagas
        <img src={arrowRight} alt="Seta para direita" />
      </SecondaryButton>
    </Container>
  );
};
