import { OptionTypeBase } from 'react-select';

const getParsedMonthName = (monthName: string): string => {
  return monthName.charAt(0).toUpperCase() + monthName.slice(1);
};

export const generateBrazilianMonthOptions = (
  onlyUntilCurrentMonth?: boolean,
): OptionTypeBase[] => {
  const currentMonth = new Date().getMonth() + 1;
  const MONTH_LIMIT = onlyUntilCurrentMonth ? currentMonth : 12;

  const monthsArray = Array.from({ length: MONTH_LIMIT }, (_, i) => {
    return new Date(0, i).toLocaleString('pt-BR', { month: 'long' });
  });

  return monthsArray.map((month, index) => ({
    id: index + 1,
    label: getParsedMonthName(month),
  }));
};
