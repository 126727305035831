import {
  yupRequiredNumberField,
  yupRequiredStringField,
} from 'utils/validation';
import { yupObjectWithId } from 'utils/validation/yupObjectWithId';
import * as yup from 'yup';
import { ProfessionalExperiencesStepData } from '.';

export const validateProfessionalExperiencesData = async (
  professionalExperiencesData: ProfessionalExperiencesStepData,
): Promise<void> => {
  const yupDateValidation = yupRequiredNumberField.moreThan(
    0,
    'Campo obrigatório',
  );

  const endMonthValidation = yup.number().when('actualJob', {
    is: false,
    then: yupRequiredNumberField.test(
      'higherThanStartMonthIfEqualYears',
      'A data de término precisa ser após a de início',
      (
        value: number | undefined,
        { parent }: { parent: { [key: string]: number } },
      ) => {
        const startYearValue = parent.startYear;
        const endYearValue = parent.endYear;
        if (startYearValue !== endYearValue) return true;

        const startMonthValue = parent.startMonth;
        return Number(value) >= startMonthValue;
      },
    ),
    otherwise: yup.number().optional(),
  });

  const yupProfessionalExperienceObjectShape = yup.object().shape({
    roleName: yupRequiredStringField,
    companyName: yupRequiredStringField,
    startMonth: yupDateValidation,
    startYear: yupDateValidation,
    actualJob: yup.boolean(),
    assignmentsDescription: yupRequiredStringField.min(
      50,
      'Mínimo de 50 caracteres',
    ),
    jobType: yupObjectWithId,
    city: yupObjectWithId,
    state: yupObjectWithId,
    endMonth: endMonthValidation,
    endYear: yup.number().when('actualJob', {
      is: false,
      then: yupRequiredNumberField.min(
        yup.ref('startYear'),
        'O ano de término precisa ser maior ou igual ao de início',
      ),
      otherwise: yup.number().optional(),
    }),
  });

  const schema = yup.object().shape({
    hasProfessionalExperience: yup.boolean(),
    professionalExperiences: yup
      .array()
      .optional()
      .when('hasProfessionalExperience', {
        is: true,
        then: yup.array(yupProfessionalExperienceObjectShape).required(),
      }),
  });

  await schema.validate(professionalExperiencesData, { abortEarly: false });
};
