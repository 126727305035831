/* eslint-disable react-hooks/rules-of-hooks */
import searchIcon from 'assets/img/search.svg';
import idCardIcon from 'assets/img/idCard.svg';
import deleteIcon from 'assets/img/delete.svg';

import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { CandidateResume } from 'components/CandidateResume';
import { formatDateToBrazilianString } from 'utils/conversion/formatDateToBrazilianString';
import { Loader } from 'components/Loader';
import { CandidatesTestsAverageType } from 'types/candidate';
import { ERROR_MODAL_CONFIGURATION, useAlertModal } from 'hooks/alertModal';
import { HighRed } from 'assets/colors/palette';
import { CandidateEvaluation } from 'types/CandidateEvaluation';
import { IStartupCandidatesFolderBasicData } from 'types/startupCandidatesFolder/IStartupCandidatesFolderBasicData';
import {
  deleteFiledStartupCandidate,
  findAllFiledStartupCandidatesByStartupCandidatesFolderId,
  updateFiledStartupCandidate,
} from 'services/entitiesServices/filedStartupCandidateServices';
import {
  CandidateMeetsResearchFiltersType,
  DEFAULT_CANDIDATE_MEETS_RESEARCH_FILTERS,
  OpenFilterOptionsButton,
} from 'pages/Dashboards/StartupsDashboard/StartupCandidatesSearchComponents/OpenFilterOptionsButton';
import { SearchedCandidate } from 'pages/Dashboards/StartupsDashboard/StartupCandidatesSearchComponents/SearchedCandidate';
import { CandidateEvaluationContainer } from 'pages/Dashboards/StartupsDashboard/StartupCandidatesSearchComponents/CandidateEvaluationContainer';
import { IFiledStartupCandidateResponseWithoutFolder } from 'types/filedStartupCandidate';
import { Redirect, useLocation } from 'react-router-dom';
import { routeTalentBank } from 'routes/startupsRoutes/startupsRoutesAddresses';
import { Depth4 } from 'assets/colors/boxShadows';
import {
  calculateCandidatesTestsAverage,
  getCandidateLastUpdatedAt,
} from 'utils';
import { candidateMeetsSearchText } from 'utils/candidateMeetsSearchText';
import {
  CandidatesSearch,
  SearchContainer,
  SearchBar,
  ColumnNamesContainer,
  SelectedCandidate,
  CandidatesTable,
  NoSelectedCandidateContainer,
  NoCandidatesContainer,
  Container,
  Header,
  SearchedCandidatesContainer,
  CandidateResumeLastUpdateDate,
  SelectedCandidateTabs,
  CandidateTabButton,
} from './styles';
import { OptionsColumnData } from './OptionsColumnData';
import { SelectedCandidateTests } from '../../SelectedCandidateTests';

type LocationType = {
  state: { startupFolder: IStartupCandidatesFolderBasicData } | undefined;
};

export const StartupFolderCandidatesTab: React.FC = () => {
  const { state } = useLocation() as LocationType;
  if (!state) return <Redirect to={routeTalentBank} />;

  const { startupFolder } = state;
  const { showModal } = useAlertModal();

  const headerRef = useRef<HTMLHeadElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const [searchedText, setSearchedText] = useState('');
  const [candidateMeetsResearchFilters, setCandidateMeetsResearchFilters] =
    useState<CandidateMeetsResearchFiltersType>(
      () => DEFAULT_CANDIDATE_MEETS_RESEARCH_FILTERS,
    );

  const [isLoadingFolderCandidates, setIsLoadingFolderCandidates] =
    useState(true);
  const [folderCandidates, setFolderCandidates] = useState<
    IFiledStartupCandidateResponseWithoutFolder[]
  >([]);

  const [searchedFolderCandidatesIndexes, setSearchedFolderCandidatesIndexes] =
    useState<number[]>([]);
  const [selectedCandidateIndex, setSelectedCandidateIndex] = useState<
    number | null
  >(null);
  const [selectedCandidateTab, setSelectedCandidateTab] = useState<
    'resume' | 'tests'
  >('resume');

  useEffect(() => {
    findAllFiledStartupCandidatesByStartupCandidatesFolderId(
      startupFolder.id,
    ).then(findedCandidates => {
      setFolderCandidates(findedCandidates);
      setSearchedFolderCandidatesIndexes(
        Array(findedCandidates.length)
          .fill(undefined)
          .map((_, index) => index),
      );

      setIsLoadingFolderCandidates(false);
    });
  }, [startupFolder.id]);

  useEffect(() => {
    setSelectedCandidateIndex(null);
  }, [searchedFolderCandidatesIndexes.length]);

  useEffect(() => {
    setSelectedCandidateTab('resume');
  }, [selectedCandidateIndex]);

  useEffect(() => {
    const searchedCandidatesIndexes: number[] = [];

    folderCandidates.forEach((folderCandidate, index) => {
      if (!candidateMeetsResearchFilters(folderCandidate)) return;
      if (!candidateMeetsSearchText(searchedText, folderCandidate.candidate)) {
        return;
      }

      searchedCandidatesIndexes.push(index);
    });

    setSearchedFolderCandidatesIndexes(searchedCandidatesIndexes);
  }, [candidateMeetsResearchFilters, folderCandidates, searchedText]);

  const selectedCandidateLastUpdatedAt = useMemo(
    () =>
      getCandidateLastUpdatedAt(
        selectedCandidateIndex === null
          ? undefined
          : folderCandidates[selectedCandidateIndex]?.candidate,
      ),
    [folderCandidates, selectedCandidateIndex],
  );

  const candidatesTestsAverage: CandidatesTestsAverageType = useMemo(
    () => calculateCandidatesTestsAverage(folderCandidates),
    [folderCandidates],
  );

  const handleUpdateCandidate = useCallback(
    async (
      filedStartupCandidateId: number,
      updatedCandidateData: { candidateEvaluation: CandidateEvaluation },
    ) => {
      try {
        await updateFiledStartupCandidate(
          filedStartupCandidateId,
          updatedCandidateData,
        );

        setFolderCandidates(previousCandidates =>
          previousCandidates.map(previousCandidate =>
            previousCandidate.id === filedStartupCandidateId
              ? { ...previousCandidate, ...updatedCandidateData }
              : previousCandidate,
          ),
        );
      } catch (err) {
        showModal({
          ...ERROR_MODAL_CONFIGURATION,
          marginLeft: -448,
          message:
            'Erro ao atualizar o candidato, por favor, tente novamente mais tarde',
        });
      }
    },
    [showModal],
  );

  const handleSelectCandidate = useCallback(
    async (candidateIndex: number) => {
      const parsedIndex = searchedFolderCandidatesIndexes[candidateIndex];
      const { id: candidateId, candidateEvaluation } =
        folderCandidates[parsedIndex];

      if (candidateEvaluation === CandidateEvaluation.NEW_CANDIDATE) {
        handleUpdateCandidate(candidateId, {
          candidateEvaluation: CandidateEvaluation.NOT_EVALUATED,
        });
      }

      setSelectedCandidateIndex(parsedIndex);
    },
    [handleUpdateCandidate, searchedFolderCandidatesIndexes, folderCandidates],
  );

  const handleDeleteCandidate = useCallback(
    async ({
      id: filedStartupCandidateId,
      candidate: {
        user: { name, lastName },
      },
    }: IFiledStartupCandidateResponseWithoutFolder) => {
      await deleteFiledStartupCandidate(filedStartupCandidateId);

      showModal({
        message: `O currículo de ${`${name} ${lastName}`} foi excluído`,
        backgroundColor: HighRed,
        icon: deleteIcon,
        marginLeft: -448,
        forceIconWhiteColor: true,
      });

      setFolderCandidates(previousCandidates =>
        previousCandidates.filter(({ id }) => id !== filedStartupCandidateId),
      );
      setSelectedCandidateIndex(null);
    },
    [showModal],
  );

  useLayoutEffect(() => {
    const toggleHeaderShadow = () => {
      const { current } = headerRef;
      if (!current) return;

      const boxShadow = current.style.getPropertyValue('box-shadow');
      const offsetTop = window.scrollY;

      if (!boxShadow && offsetTop > 64) {
        current.style.setProperty('box-shadow', Depth4);
        current.style.setProperty('position', 'sticky');
      } else if (boxShadow && offsetTop <= 64) {
        current.style.removeProperty('box-shadow');
        current.style.setProperty('position', 'static');
      }
    };

    window.addEventListener('scroll', toggleHeaderShadow);
    return () => window.removeEventListener('scroll', toggleHeaderShadow);
  }, []);

  return (
    <>
      <Header ref={headerRef}>
        <div>{startupFolder.name}</div>
      </Header>

      <Container>
        <CandidatesSearch>
          <SearchContainer>
            <SearchBar onClick={() => searchInputRef.current?.focus()}>
              <img src={searchIcon} alt="Lupa de busca" />
              <input
                ref={searchInputRef}
                type="text"
                placeholder="Pesquise por nomes ou palavras-chaves"
                onChange={({ target: { value } }) =>
                  setSearchedText(value.toLowerCase())
                }
              />
            </SearchBar>

            <OpenFilterOptionsButton
              startupCandidates={folderCandidates}
              updateCandidateMeetsResearchFiltersFunction={updatedCandidateMeetsResearchFilters =>
                setCandidateMeetsResearchFilters(
                  () => updatedCandidateMeetsResearchFilters,
                )
              }
            />
          </SearchContainer>

          {searchedFolderCandidatesIndexes.length === 0 ? (
            <>
              {isLoadingFolderCandidates ? (
                <Loader style={{ marginTop: -100 }} />
              ) : (
                <NoCandidatesContainer>
                  <span>
                    {folderCandidates.length === 0
                      ? 'Ainda não há candidatos nesta pasta'
                      : 'Nenhum candidato encontrado para a pesquisa informada'}
                  </span>
                </NoCandidatesContainer>
              )}
            </>
          ) : (
            <>
              <CandidatesTable>
                <ColumnNamesContainer>
                  <span>Candidatos</span>
                </ColumnNamesContainer>

                <div className="sticky-div" />

                <SearchedCandidatesContainer>
                  {searchedFolderCandidatesIndexes.map(index =>
                    folderCandidates[index] === undefined ? null : (
                      <SearchedCandidate
                        key={folderCandidates[index].id}
                        startupCandidate={folderCandidates[index]}
                        selectedCandidateIndex={selectedCandidateIndex}
                        candidateIndex={index}
                        handleSelectCandidate={handleSelectCandidate}
                      >
                        <CandidateEvaluationContainer
                          candidateEvaluation={
                            folderCandidates[index].candidateEvaluation
                          }
                          handleUpdateCandidateEvaluation={updatedEvaluation =>
                            handleUpdateCandidate(folderCandidates[index].id, {
                              candidateEvaluation: updatedEvaluation,
                            })
                          }
                        />

                        <OptionsColumnData
                          handleDeleteCandidate={() =>
                            handleDeleteCandidate(folderCandidates[index])
                          }
                        />
                      </SearchedCandidate>
                    ),
                  )}
                </SearchedCandidatesContainer>
              </CandidatesTable>
            </>
          )}
        </CandidatesSearch>

        <SelectedCandidate>
          {selectedCandidateIndex !== null &&
          folderCandidates[selectedCandidateIndex] !== undefined ? (
            <>
              <SelectedCandidateTabs>
                <CandidateTabButton
                  type="button"
                  isSelected={selectedCandidateTab === 'resume'}
                  onClick={() => setSelectedCandidateTab('resume')}
                >
                  <span>Currículo</span>
                </CandidateTabButton>

                <CandidateTabButton
                  type="button"
                  isSelected={selectedCandidateTab === 'tests'}
                  onClick={() => setSelectedCandidateTab('tests')}
                >
                  <span>Avaliações</span>
                </CandidateTabButton>
              </SelectedCandidateTabs>

              {selectedCandidateTab === 'resume' ? (
                <CandidateResume
                  candidateData={
                    folderCandidates[selectedCandidateIndex].candidate
                  }
                  userData={
                    folderCandidates[selectedCandidateIndex].candidate.user
                  }
                  style={{
                    width: 440,
                    border: 'none',
                    paddingBlock: 24,
                    paddingInline: 17,
                  }}
                  ComponentToGoOnContainerStart={
                    <CandidateResumeLastUpdateDate>
                      <p>
                        Currículo atualizado em{' '}
                        {formatDateToBrazilianString(
                          selectedCandidateLastUpdatedAt,
                          'de',
                        )}
                      </p>
                    </CandidateResumeLastUpdateDate>
                  }
                />
              ) : (
                <SelectedCandidateTests
                  candidate={folderCandidates[selectedCandidateIndex].candidate}
                  candidatesTestsAverage={candidatesTestsAverage}
                />
              )}
            </>
          ) : (
            <NoSelectedCandidateContainer>
              <img src={idCardIcon} alt="Card de usuário" />
              <p>Escolha algum candidato para ter acesso ao currículo</p>
            </NoSelectedCandidateContainer>
          )}
        </SelectedCandidate>
      </Container>
    </>
  );
};
