import { BackgroundColor, HighEmphasis } from 'assets/colors/palette';
import styled from 'styled-components';

export const Container = styled.div`
  color: ${HighEmphasis};
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.75px;

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 8px 16px 8px 8px;
  }

  .column-title {
    font-weight: 700;
  }

  .candidates-column {
    min-width: 14vw;
    max-width: 14vw;
  }

  .profession-column {
    min-width: 14.5vw;
    max-width: 14.5vw;
  }

  .location-column {
    min-width: 15vw;
    max-width: 15vw;
  }

  .email-column {
    min-width: 17vw;
    max-width: 17vw;
    padding: 8px;
  }

  .phone-column {
    min-width: 14vw;
    max-width: 14vw;
  }

  .options-column {
    width: 100%;
    padding: 16px 40px;
  }
`;

export const ModalContainer = styled.div`
  width: 540px;
  border-radius: 8px;
  background-color: ${BackgroundColor};
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 14px 20px 28px;
`;

export const CloseButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 100%;
  padding-bottom: 14px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
  }
`;
