import { CandidateTest } from 'types/candidate';

export const translateCandidateTest = (
  candidateTest: CandidateTest,
): string => {
  switch (candidateTest) {
    case CandidateTest.PROFESSIONAL_PROFILE:
      return 'Perfil Profissional';
    case CandidateTest.LOGICAL_REASONING:
      return 'Raciocínio Lógico';
    case CandidateTest.PORTUGUESE:
      return 'Português';
    default:
      return '';
  }
};
