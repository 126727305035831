export const calculateAgeUsingBirthMonthAndYear = (
  birthMonth: number,
  birthYear: number,
): number => {
  const actualDate = new Date();
  const CURRENT_YEAR = actualDate.getFullYear();
  const CURRENT_MONTH = actualDate.getMonth() + 1;

  const currentMonthIsBeforeBirthMonth = birthMonth > CURRENT_MONTH;
  return CURRENT_YEAR - birthYear - Number(currentMonthIsBeforeBirthMonth);
};
