import { TestInitialScreen } from '../../TestInitialScreen';

export const ProfessionalProfileInitialScreen: React.FC = () => (
  <TestInitialScreen
    title="Teste de Perfil Profissional"
    mainText="O Teste de Perfil Profissional permite identificar qual o seu perfil de comportamento, a partir da análise de suas competências pessoais, os valores que o motivam e suas tendências de atitudes frente a diferentes situações no ambiente de trabalho e no dia a dia."
    instructions={[
      'O teste é composto por 25 perguntas. Para cada uma delas, escolha uma das alternativas cuja resposta você mais se identifique. Não há alternativa correta.',
      'O tempo é livre. Faça o teste sem interrupções ou pausas.',
      'Não é possível refazer esse teste depois de concluído. O resultado desta avaliação será disponibilizado para as empresas quando você se candidatar para uma vaga.',
    ]}
  />
);
