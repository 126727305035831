import styled from 'styled-components';

export const Container = styled.div`
  width: 1248px;
  margin-inline: auto;

  @media (max-width: 1100px) {
    width: 100%;
  }
`;
