/* eslint-disable react/no-unescaped-entities */
import messageIcon from 'assets/img/message.svg';
import rocketIcon from 'assets/img/rocket.svg';
import brainIcon from 'assets/img/brain.svg';
import chartIcon from 'assets/img/chart.svg';

import { ProfessionalProfileType } from 'types/candidate';
import { convertTestResultToProfessionalProfileType } from 'utils/conversion';

interface ResultType {
  icon: string;
  description: JSX.Element;
  resultFeatures: { name: JSX.Element; characteristics: string[] }[];
}

const POSSIBLE_RESULTS: { [key in ProfessionalProfileType]: ResultType } = {
  [ProfessionalProfileType.COMMUNICATOR]: {
    icon: messageIcon,
    description: (
      <p>
        Esse tipo de profissional tem como principal característica o "fazer
        junto". Pessoas Comunicadoras são sensíveis e têm um bom relacionamento
        no ambiente empresarial, mantendo a comunicação harmoniosa. Para isso,
        podem esconder conflitos, colocar a felicidade acima dos resultados e
        manipular através dos sentimentos.
        <br />
        <br />
        Possuem grande aceitação social e facilidade de construir o consenso,
        provocando o reconhecimento da equipe, além de inspirar autoridade no
        trabalho em equipe.
        <br />
        <br />
        Um ponto a ser melhorado é a dificuldade em lidar com regras e processos
        muito fechados. E só se sentem seguros quando são notados, aceitos no
        grupo e têm seu trabalho reconhecido por gestores e colegas.
      </p>
    ),
    resultFeatures: [
      {
        name: (
          <h3>
            Comportamentos
            <br />
            predominantes
          </h3>
        ),
        characteristics: [
          'Delega autoridade',
          'Harmonioso',
          'Colaborativo',
          'Senso de time',
        ],
      },
      {
        name: (
          <h3>
            Fatores de
            <br />
            motivação
          </h3>
        ),
        characteristics: [
          'Supervisão flexível',
          'Reconhecimento',
          'Aceitação social',
          'Trabalho em grupo',
        ],
      },
      {
        name: (
          <h3>
            Pontos
            <br />
            fortes
          </h3>
        ),
        characteristics: ['Comunicação aberta', 'Promove a harmonia'],
      },
      {
        name: (
          <h3>
            Pontos
            <br />
            de melhoria
          </h3>
        ),
        characteristics: ['Esconde conflitos', 'Manipulação emocional'],
      },
    ],
  },
  [ProfessionalProfileType.EXECUTOR]: {
    icon: rocketIcon,
    description: (
      <p>
        Esse tipo de profissional tem como principal característica o "fazer
        rápido". Possuem altíssimo foco nos resultados, com senso de urgência
        apurado e prezam pela execução e iniciativa. Assim, não veem problemas
        em desempenhar várias atividades simultaneamente. Toda tarefa dada a um
        Executor será entregue com rapidez e qualidade, o que torna esse tipo de
        talento extremamente necessário em qualquer organização.
        <br />
        <br />
        Executores são motivados à ação, impulsivos e práticos. Não gostam de
        delegar poder. Por terem o ímpeto de entregar resultados rapidamente,
        também acabam escolhendo atalhos que não seriam os mais indicados em
        algumas situações. Suas relações de trabalho com colegas podem ser
        conflituosas e preferem resolver seus problemas sozinhos para não ter
        que repetir tarefas.
      </p>
    ),
    resultFeatures: [
      {
        name: (
          <h3>
            Comportamentos
            <br />
            predominantes
          </h3>
        ),
        characteristics: [
          'Iniciativa',
          'Senso de urgência',
          'Autossuficiente',
          'Busca vencer desafios',
        ],
      },
      {
        name: (
          <h3>
            Fatores de
            <br />
            motivação
          </h3>
        ),
        characteristics: [
          'Competição individual',
          'Autonomia',
          'Variedade de atividades',
          'Independência',
        ],
      },
      {
        name: (
          <h3>
            Pontos
            <br />
            fortes
          </h3>
        ),
        characteristics: [
          'Motivação pessoal',
          'Faz acontecer',
          'Elimina burocracias',
        ],
      },
      {
        name: (
          <h3>
            Pontos
            <br />
            de melhoria
          </h3>
        ),
        characteristics: [
          'Difícil relacionamento',
          'Saber delegar poder',
          'Faz do modo mais fácil',
        ],
      },
    ],
  },
  [ProfessionalProfileType.IDEALIZER]: {
    icon: brainIcon,
    description: (
      <p>
        Esse tipo de profissional tem como principal característica o "fazer
        diferente". Pessoas com esse perfil enxergam o mercado e o negócio como
        um todo, são criativas e usam a sua intuição com frequência para
        antecipar o futuro, provocar mudanças radicais e inspirar ideias. Por
        estarem sempre um passo à frente, são os primeiros a trazer soluções
        inovadoras. Portanto, são fundamentais para manter uma empresa relevante
        e competitiva no mercado.
        <br />
        <br />
        Essas características fazem com que sejam impacientes e rebeldes,
        defendendo sempre o “novo pelo novo” sem prestar atenção no presente. Se
        sentem motivados pela ausência de controles rígidos e ambiente de
        trabalho descentralizado. São informais, flexíveis e prezam pela
        liberdade de expressão e para fazer exceções. Estão sempre aguardando
        uma oportunidade para delegar tarefas.
      </p>
    ),
    resultFeatures: [
      {
        name: (
          <h3>
            Comportamentos
            <br />
            predominantes
          </h3>
        ),
        characteristics: [
          'Criativo',
          'Intuitivo',
          'Flexível',
          'Foco no futuro',
        ],
      },
      {
        name: (
          <h3>
            Fatores de
            <br />
            motivação
          </h3>
        ),
        characteristics: [
          'Delegar tarefas',
          'Autonomia',
          'Ausência de controles',
        ],
      },
      {
        name: (
          <h3>
            Pontos
            <br />
            fortes
          </h3>
        ),
        characteristics: [
          'Antecipar futuro',
          'Criatividade',
          'Promover mudanças',
        ],
      },
      {
        name: (
          <h3>
            Pontos
            <br />
            de melhoria
          </h3>
        ),
        characteristics: ['Impaciência', 'Rebeldia', 'Falta de atenção'],
      },
    ],
  },
  [ProfessionalProfileType.ANALYST]: {
    icon: chartIcon,
    description: (
      <p>
        Esse tipo de profissional tem como principal característica o "fazer
        certo". Pessoas com esse perfil são detalhistas e organizadas,
        executando suas tarefas de maneira metódica e organizada. São pontuais,
        responsáveis e prezam pela consistência, conformidade e qualidade. Se
        dão melhor em empresas com processos e regras bem definidas, que
        combinam mais com essa personalidade. Por isso, têm dificuldades em se
        adaptar a mudanças por pensar demasiadamente nos detalhes.
        <br />
        <br />
        Costumam ser leais, conseguem viver o presente, projetar o futuro e
        aprender com o passado. Buscam o equilíbrio e valorizam suas
        responsabilidades acima de tudo. Esses indivíduos têm a certeza e
        compreensão exata de quais são as regras, buscam o conhecimento
        específico do trabalho. Seu objetivo é ver o trabalho entregue, mas
        evitam assumir riscos durante o processo.
      </p>
    ),
    resultFeatures: [
      {
        name: (
          <h3>
            Comportamentos
            <br />
            predominantes
          </h3>
        ),
        characteristics: [
          'Organizado',
          'Detalhista',
          'Busca conhecimento',
          'Conservador',
        ],
      },
      {
        name: (
          <h3>
            Fatores de
            <br />
            motivação
          </h3>
        ),
        characteristics: [
          'Regras bem definidas',
          'Ausência de riscos',
          'Concluir o trabalho',
          'Ser especialista',
        ],
      },
      {
        name: (
          <h3>
            Pontos
            <br />
            fortes
          </h3>
        ),
        characteristics: [
          'Responsabilidade',
          'Pensar a longo prazo',
          'Preza pela qualidade',
          'Lealdade',
        ],
      },
      {
        name: (
          <h3>
            Pontos
            <br />
            de melhoria
          </h3>
        ),
        characteristics: [
          'Ser adaptável',
          'Resistir ao progresso',
          'Sistemático em excesso',
        ],
      },
    ],
  },
};

export const getResultScreenPropertiesBasedOnTestResult = (
  testResult: string,
): ResultType =>
  POSSIBLE_RESULTS[convertTestResultToProfessionalProfileType(testResult)];
