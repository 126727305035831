import { SocialMedia } from 'types/SocialMedia';

const socialMediasAPIs = {
  Facebook: 'https://www.facebook.com/sharer/sharer.php?u=',
  Whatsapp: 'https://web.whatsapp.com/send?text=',
  Twitter: 'https://twitter.com/intent/tweet?text=',
  Linkedin: 'https://www.linkedin.com/sharing/share-offsite/?url=',
};

export const shareOnSocialMedia = (
  socialMedia: SocialMedia,
  urlToShare: string,
): void => {
  window.open(`${socialMediasAPIs[socialMedia]}${urlToShare}`, '_blank');
};
