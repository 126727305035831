import * as yup from 'yup';

import { yupObjectWithId } from 'utils/validation/yupObjectWithId';
import { BusinessSectorsStepData } from '.';

export const validateBusinessSectorsData = async (
  businessSectorsData: BusinessSectorsStepData,
): Promise<void> => {
  const validationMessage = 'Necessário informar ao menos um setor de atuação';

  const schema = yup.object().shape({
    businessSectors: yup
      .array(yupObjectWithId)
      .required(validationMessage)
      .min(1, validationMessage),
  });

  await schema.validate(businessSectorsData, { abortEarly: false });
};
