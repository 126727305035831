import { formStepInputStyles } from 'assets/styles/formWithStepsStyles/formWithStepsInputStyles';
import { FormWithStepsSubForm } from 'components/FormWithSteps/FormWithStepsSubForm';
import { Input } from 'components/Input';
import { CheckboxInput } from 'components/Input/CheckboxInput';
import { TextAreaInput } from 'components/Input/TextAreaInput';
import { Select } from 'components/Select';
import { useFormError } from 'errors/useFormError';
import { useWindowWidth } from 'hooks/windowWidth';
import { useCallback, useEffect, useState } from 'react';
import { OptionTypeBase } from 'react-select';
import { findAllCitiesByStateId } from 'services/entitiesServices/cityServices';
import { ICandidateProfessionalExperienceRequest } from 'types/candidate';
import { IErrors } from 'types/IErrors';
import { getInputStateValue } from 'utils';
import { convertLocationToOptionTypeBase } from 'utils/conversion/convertLocationToOptionTypeBase';
import { useInputStates } from 'utils/customHooks';
import { jobTypeOptions } from 'utils/typeOptions';
import { generateBrazilianMonthOptions } from 'utils/typeOptions/generateBrazilianMonthOptions';
import { MultiInputContainer } from './styles';

interface ProfessionalExperienceSubFormProps {
  initialData: ICandidateProfessionalExperienceRequest;
  index: number;
  handleUpdateSubFormData: (
    updatedProfessionalExperience: ICandidateProfessionalExperienceRequest,
  ) => void;
  handleDeleteSubFormData: () => void;
  statesOptions: OptionTypeBase[];
  monthOptions: OptionTypeBase[];
  yearOptions: OptionTypeBase[];
  errors?: IErrors;
  disabled?: boolean;
}

const CURRENT_YEAR = new Date().getFullYear();

export const ProfessionalExperienceSubForm: React.FC<
  ProfessionalExperienceSubFormProps
> = ({
  initialData,
  index,
  handleDeleteSubFormData,
  handleUpdateSubFormData,
  statesOptions,
  monthOptions,
  yearOptions,
  errors,
}) => {
  const {
    actualJob,
    assignmentsDescription,
    city,
    companyName,
    endDate,
    roleName,
    startDate,
    state,
    jobType,
  } = initialData;
  const { handleFormError } = useFormError();
  const { isMobileScreen } = useWindowWidth();

  const roleNameStates = useInputStates('roleName', roleName);
  const jobTypeStates = useInputStates('jobType', jobType?.id ?? '');
  const companyNameStates = useInputStates('companyName', companyName);

  const stateStates = useInputStates('state', state?.id);
  const cityStates = useInputStates('city', city?.id);

  const [startMonth, startYear] = (startDate ?? '').split('/');
  const startMonthStates = useInputStates('startMonth', startMonth);
  const startYearStates = useInputStates('startYear', startYear);

  const [isActualJob, setIsActualJob] = useState(!!actualJob);

  const [endMonth, endYear] = (endDate ?? '').split('/');
  const endMonthStates = useInputStates('endMonth', endMonth);
  const endYearStates = useInputStates('endYear', endYear);

  const assignmentsDescriptionStates = useInputStates(
    'assignmentsDescription',
    assignmentsDescription,
  );

  const [cities, setCities] = useState<OptionTypeBase[]>([]);
  const [isLoadingCities, setIsLoadingCities] = useState(true);

  useEffect(() => {
    const stateId = Number(stateStates.mainState.value as string);
    if (stateId === 0) {
      cityStates.mainState.setFunction('');
      return;
    }

    setIsLoadingCities(true);
    findAllCitiesByStateId(stateId).then(findedCities => {
      setCities(
        findedCities.map(findedCity =>
          convertLocationToOptionTypeBase(findedCity),
        ),
      );

      if (!city) cityStates.mainState.setFunction('');
      cityStates.errorMessageState.setFunction('');
      setIsLoadingCities(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cityStates.errorMessageState.setFunction,
    cityStates.mainState.setFunction,
    stateStates.mainState.value,
  ]);

  useEffect(() => {
    if (!isActualJob) return;

    endMonthStates.mainState.setFunction('');
    endMonthStates.errorMessageState.setFunction('');

    endYearStates.mainState.setFunction('');
    endYearStates.errorMessageState.setFunction('');
  }, [
    endMonthStates.errorMessageState,
    endMonthStates.mainState,
    endYearStates.errorMessageState,
    endYearStates.mainState,
    isActualJob,
  ]);

  useEffect(() => {
    endMonthStates.errorMessageState.setFunction('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    endMonthStates.errorMessageState.setFunction,
    endYearStates.mainState.value,
  ]);

  const getMonthOptionsBasedOnYear = useCallback(
    (year: string) => {
      if (Number(year) < CURRENT_YEAR) return monthOptions;
      return generateBrazilianMonthOptions(true);
    },
    [monthOptions],
  );

  const getUpdatedSubFormData = useCallback(() => {
    const parsedStartMonth = Number(getInputStateValue(startMonthStates));
    const parsedStartYear = Number(getInputStateValue(startYearStates));
    const parsedStartDate = `${parsedStartMonth}/${parsedStartYear}`;

    const parsedEndMonth = Number(getInputStateValue(endMonthStates));
    const parsedEndYear = Number(getInputStateValue(endYearStates));
    const parsedEndDate = `${parsedEndMonth}/${parsedEndYear}`;
    const jobTypeId = Number(getInputStateValue(jobTypeStates));

    const cityId = Number(getInputStateValue(cityStates));
    const { label: cityName } = cities.find(({ id }) => id === cityId) ?? {};
    const parsedCity = {
      id: cityId,
      name: cityName,
    };

    const stateId = Number(getInputStateValue(stateStates));
    const { label: stateName } =
      statesOptions.find(({ id }) => id === stateId) ?? {};
    const parsedState = {
      id: stateId,
      name: stateName,
      initials: stateName,
    };

    const updatedStatesObject = {
      roleName: getInputStateValue(roleNameStates),
      jobType: {
        id: jobTypeId,
      },
      companyName: getInputStateValue(companyNameStates),
      state: parsedState,
      city: parsedCity,
      startMonth: parsedStartMonth,
      startYear: parsedStartYear,
      startDate: parsedStartDate,
      endMonth: parsedEndMonth,
      endYear: parsedEndYear,
      endDate: parsedEndDate,
      actualJob: isActualJob,
      assignmentsDescription: getInputStateValue(assignmentsDescriptionStates),
    };

    return updatedStatesObject as ICandidateProfessionalExperienceRequest;
  }, [
    assignmentsDescriptionStates,
    cities,
    cityStates,
    companyNameStates,
    endMonthStates,
    endYearStates,
    isActualJob,
    jobTypeStates,
    roleNameStates,
    startMonthStates,
    startYearStates,
    stateStates,
    statesOptions,
  ]);

  useEffect(() => {
    const updatedData = getUpdatedSubFormData();
    handleUpdateSubFormData(updatedData);
  }, [getUpdatedSubFormData, handleUpdateSubFormData]);

  useEffect(() => {
    if (!errors) return;

    const subFormStates = [
      assignmentsDescriptionStates,
      cityStates,
      companyNameStates,
      endMonthStates,
      endYearStates,
      jobTypeStates,
      roleNameStates,
      startMonthStates,
      startYearStates,
      stateStates,
    ];

    handleFormError(errors, subFormStates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return (
    <FormWithStepsSubForm
      name={`Experiência profissional ${index + 1}`}
      handleDeleteSubFormData={handleDeleteSubFormData}
    >
      <Input
        name="Nome da função"
        placeholder="ex. Vendedora"
        states={roleNameStates}
        style={formStepInputStyles}
      />

      <Select
        name="Tipo de emprego"
        placeholder="selecionar"
        states={jobTypeStates}
        selectOptions={jobTypeOptions}
        style={formStepInputStyles}
        startWithoutValue
      />

      <Input
        name="Nome da empresa"
        placeholder="ex. Riachuelo"
        states={companyNameStates}
        style={formStepInputStyles}
      />

      <MultiInputContainer isBlockOnMobile>
        <Select
          name="Endereço de trabalho"
          placeholder="selecionar"
          states={stateStates}
          disabled={statesOptions.length === 0}
          selectOptions={statesOptions}
          style={{
            ...formStepInputStyles,
            width: isMobileScreen ? '100%' : 153,
          }}
          startWithoutValue
        />

        <Select
          placeholder="selecionar"
          disabled={isLoadingCities}
          states={cityStates}
          selectOptions={cities}
          loadingIndicatorDisabled
          style={{
            ...formStepInputStyles,
            width: isMobileScreen ? '100%' : 284,
          }}
          startWithoutValue
        />
      </MultiInputContainer>

      <MultiInputContainer>
        <Select
          name="Data de início do trabalho"
          placeholder="mês"
          states={startMonthStates}
          selectOptions={getMonthOptionsBasedOnYear(
            (startYearStates.mainState.value as string) ?? '',
          )}
          style={{
            ...formStepInputStyles,
            width: isMobileScreen ? '38vw' : 218,
          }}
          startWithoutValue
        />

        <Select
          placeholder="ano"
          states={startYearStates}
          selectOptions={yearOptions}
          style={{
            ...formStepInputStyles,
            width: isMobileScreen ? '38vw' : 218,
          }}
          startWithoutValue
        />
      </MultiInputContainer>

      <CheckboxInput
        label="Este é meu trabalho atual"
        defaultChecked={isActualJob}
        handleCheckboxUpdate={checked => setIsActualJob(checked)}
        style={{ marginTop: 20, marginBottom: 40 }}
      />

      <MultiInputContainer>
        <Select
          name="Data de término do trabalho"
          placeholder="mês"
          states={endMonthStates}
          selectOptions={getMonthOptionsBasedOnYear(
            (endYearStates.mainState.value as string) ?? '',
          )}
          style={{
            ...formStepInputStyles,
            width: isMobileScreen ? '38vw' : 218,
          }}
          disabled={isActualJob}
          startWithoutValue
          loadingIndicatorDisabled
        />

        <Select
          placeholder="ano"
          states={endYearStates}
          selectOptions={yearOptions}
          style={{
            ...formStepInputStyles,
            width: isMobileScreen ? '38vw' : 218,
          }}
          disabled={isActualJob}
          startWithoutValue
          loadingIndicatorDisabled
        />
      </MultiInputContainer>

      <TextAreaInput
        name="Descreva suas atribuições"
        placeholder="inserir descrição das atribuições da função"
        states={assignmentsDescriptionStates}
        style={{ ...formStepInputStyles, minHeight: 200 }}
        descriptionText="Adicione uma descrição de 50 a 1000 caracteres"
        charactersLimitLength={1000}
      />
    </FormWithStepsSubForm>
  );
};
