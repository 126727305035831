import { AppLogo } from 'components/AppLogo';
import { useWindowWidth } from 'hooks/windowWidth';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { routeDashboard } from 'routes/routesAddresses';
import { AppLogoType } from 'types/AppLogoType';
import {
  HeaderContainer,
  HeaderSubContainer,
  NavigationContainer,
  TabButton,
} from './styles';

type DashboardType = 'startups' | 'candidates';

interface DashboardHeaderTab {
  name: string;
  path: string;
  toBlankPage: boolean;
}

interface DashboardHeaderProps {
  tabs: DashboardHeaderTab[];
  dashboardType: DashboardType;
}

export const DashboardHeader: React.FC<DashboardHeaderProps> = ({
  tabs,
  dashboardType,
  children,
}) => {
  const history = useHistory();
  const { isMobileScreen } = useWindowWidth();
  const {
    location: { pathname },
  } = history;

  const lastPathOfPathname = useMemo(() => {
    const splittedPathName = pathname.split('/');
    return splittedPathName[splittedPathName.length - 1];
  }, [pathname]);

  const { logoType, redirectRoute } = (() => {
    switch (dashboardType) {
      case 'candidates':
        return { redirectRoute: routeDashboard, logoType: 'candidatos' };
      case 'startups':
        return { redirectRoute: routeDashboard, logoType: 'empresas' };
      default:
        return { redirectRoute: '', logoType: 'empresas' };
    }
  })();

  return (
    <HeaderContainer
      style={{
        position:
          lastPathOfPathname.startsWith('create') ||
          lastPathOfPathname.startsWith('edit')
            ? 'fixed'
            : 'relative',
      }}
    >
      <HeaderSubContainer>
        <NavigationContainer>
          <button type="button" onClick={() => history.push(redirectRoute)}>
            <AppLogo logoType={logoType as AppLogoType} />
          </button>

          {!isMobileScreen &&
            tabs.map(({ name, path, toBlankPage }) => (
              <TabButton
                key={name}
                to={path}
                target={toBlankPage ? '_blank' : '_self'}
                $isSelectedTab={path === pathname}
              >
                {name}
              </TabButton>
            ))}
        </NavigationContainer>

        <>{children}</>
      </HeaderSubContainer>
    </HeaderContainer>
  );
};
