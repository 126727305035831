import in6Logo from 'assets/img/in6Logo.svg';

import { Header } from 'components/Header';
import { routeBar } from 'routes/routesAddresses';
import {
  Container,
  TitleContainer,
  ContentParagraph,
  ContentSection,
  ContentSectionTitle,
} from './styles';

export const PrivacyPolicy: React.FC = () => {
  return (
    <>
      <Header logo={in6Logo} redirectRoute={routeBar} />

      <Container>
        <TitleContainer>
          <h1>Política de privacidade</h1>
          <h4>
            Saiba como nós coletamos, usamos e compartilhamos seus dados
            pessoais.
          </h4>
          <span>Atualizado em 22 de julho de 2022</span>
        </TitleContainer>

        <ContentParagraph>
          A Política de Privacidade da IN6 descreve como coletamos, usamos e
          compartilhamos seus dados pessoais. Reforçamos nosso compromisso com a
          proteção e segurança dos dados de todos os usuários que utilizam nossa
          plataforma, garantindo que sua interação com este serviço ocorra em
          consonância com a Lei Geral de Proteção de Dados Pessoais (LGPD) e
          demais diplomas legais aplicáveis.
        </ContentParagraph>

        <ContentParagraph>
          Assim, esta Política de Privacidade, em conjunto aos Termos de Uso,
          descreve o compromisso da IN6 na forma de utilização e tratamento dos
          dados pessoais dos usuários, seja através do site www.in6.com.br,
          aplicativos/software para múltiplas plataformas ou no compartilhamento
          das informações pessoais com outros possíveis agentes de tratamento.
        </ContentParagraph>

        <ContentParagraph>
          Recomendamos que, antes da utilização de qualquer serviço que compõe a
          plataforma da IN6, você esteja ciente e em acordo com nossas práticas
          de privacidade. Em caso de dúvidas, entre em contato conosco.
        </ContentParagraph>

        <ContentSection>
          <ContentSectionTitle>
            1. Responsabilidade pelos dados
          </ContentSectionTitle>

          <ContentParagraph>
            O Responsável pelo tratamento dos dados coletados através deste
            website/app, é a IN6 TECNOLOGIA E RECRUTAMENTO cujos dados são os
            seguintes:
            <ul>
              <li>
                Nome: IN6 TECNOLOGIA E RECRUTAMENTO (doravante,
                &apos;&apos;IN6&apos;&apos;).
              </li>
              <li>
                Encarregado de Proteção de Dados (DPO): Luiz Artur Fernandes de
                Macedo.
              </li>
              <li>Dados de registro: CNPJ/MF sob o nº 45.648.976/0001-03.</li>
              <li>
                Endereço: Rua Antônio Alcivan Alves da Silva, 552 Complemento:
                Ap 105 Bloco 1 Bairro: Planalto Treze de Maio CEP: 59631-485
                Município: Mossoró Estado: Rio Grande do Norte.
              </li>
            </ul>
          </ContentParagraph>
        </ContentSection>

        <ContentSection>
          <ContentSectionTitle>2. Dados coletados</ContentSectionTitle>

          <ContentParagraph>
            A Lei Geral de Proteção de Dados (LGPD) define dados pessoais como
            informações que podem identificar uma pessoa que esteja viva, de
            forma direta ou não, como por exemplo: nome, CPF/CNPJ, data de
            nascimento, entre outros.
          </ContentParagraph>

          <ContentParagraph>
            Nesse âmbito, a IN6 poderá coletar seus dados pessoais, a fim de
            fornecer-lhe os melhores serviços possíveis. Em particular, a IN6
            pode coletar o seguinte:
            <ul>
              <li>
                Informações pessoais: nome, sobrenome (se pessoa física), nome
                fantasia, CNPJ (se pessoa jurídica), endereço e localização.
              </li>
              <li>
                Informações profissionais: profissão, experiência, competências,
                habilidades, interesses, dados acadêmicos e área de atuação.
              </li>
              <li>Informações para contato: e-mail e telefone/WhatsApp.</li>
              <li>
                Informações de navegação: inclui-se dados de tráfego, endereço
                IP, dados de localização, duração de atividade, sistema
                operacional e navegador da web, bem como recursos que você
                acessa.
              </li>
            </ul>
          </ContentParagraph>

          <ContentParagraph>
            É importante que, ao fornecer os dados pessoais, você ou pessoa
            autorizada, insira apenas informações verdadeiras e atualizadas. A
            responsabilidade, em qualquer caso, civil e/ou criminal, é sua
            quanto à veracidade, exatidão e autenticidade das informações
            incluídas. Também coletamos e armazenamos automaticamente
            informações sobre a sua atividade e navegação no site, mediante a
            utilização de cookies (veja mais detalhes no item 9 abaixo).
          </ContentParagraph>

          <ContentParagraph>
            A IN6 não coleta dados pessoais nem permite a utilização do website
            e dos serviços por menores de 14 anos. Para acessar ou utilizar o
            nosso site é necessário ser maior de 18 anos de idade. Se tiver mais
            de 14, mas for menor de 18 anos, a utilização do nosso website
            deverá obedecer ao que aduz o art. 14 da LGPD, devendo o menor estar
            sob a supervisão dos seus pais ou tutores ou de outro adulto
            responsável. Se tivermos conhecimento de que um menor nos forneceu
            informações sem a autorização dos pais ou tutores, eliminaremos tais
            informações.
          </ContentParagraph>
        </ContentSection>

        <ContentSection>
          <ContentSectionTitle>3. Finalidade e legitimação</ContentSectionTitle>

          <ContentParagraph>
            A IN6 trata os Dados Pessoais do Candidato e das Empresas com um
            objetivo específico. São tratados apenas os dados que são
            pertinentes para cumprir esse objetivo. Neste sentido, os Dados
            Pessoais serão tratados para os seguintes propósitos:
          </ContentParagraph>

          <ContentParagraph>
            <ol>
              <li>
                3.1. Prestação adequada dos Serviços, que são:
                <ul>
                  <li>
                    Criar Currículo na função específica para isso do
                    website/app da IN6;
                  </li>
                  <li>
                    Permitir o contato com empresas ou recrutadores de emprego;
                  </li>
                  <li>
                    Gerir a contratação de produtos e/ou serviços que você
                    realizou através do website, tais como os serviços da conta
                    impulsionada;
                  </li>
                  <li>
                    Gestão dos nossos serviços, e outras tarefas comerciais como
                    avisos técnicos, atualizações, alertas de segurança, e
                    mensagens de apoio, entre outros.
                  </li>
                </ul>
              </li>

              <li>
                3.2. Envio de comunicações comerciais, boletins informativos,
                pesquisas, convites para webinars, entre outros, para lhe
                informar sobre os nossos produtos, serviços e eventos.
              </li>

              <li>
                3.3. Enviar newsletters e promoções com publicidade de outros
                websites, de outros produtos ou serviços de empresas
                terceiras/parceiros colaboradores, vinculadas ou não aos
                serviços deste sítio web. Nesse sentido, poderemos enviar
                publicidade sobre os setores Financeiro, Editorial, Educacional,
                Automotivo, Telecomunicações, Informática, Tecnologia, Lar,
                Beleza, Imobiliário, Venda à distância, Varejo, Alimentação,
                Colecionáveis, Música, Passatempos, Lazer, Viagens, Seguros,
                Energia e Água, ONG e formação entre outros.
              </li>

              <li>
                3.4. Melhorar nossos serviços, estudando o comportamento do
                usuário através de cookies para adaptar os serviços às suas
                necessidades e gostos. O Candidato pode gerenciar seus cookies
                seguindo as instruções indicadas em nosso item 9.
              </li>

              <li>
                3.5. Prevenção de abuso e fraude no uso de nossos Serviços.
              </li>

              <li>
                3.6. Cessão de dados a organismos e autoridades públicas, sempre
                e quando sejam requeridos de conformidade com as disposições
                legais e regulamentares.
              </li>
            </ol>
          </ContentParagraph>

          <ContentParagraph>
            Poderemos utilizar seus dados em conexão com nossos serviços para
            outras finalidades lícitas e mediante sua autorização prévia ou para
            cumprir obrigações acessórias ao que já nos comprometemos, inclusive
            por meio dos Termos de Uso. Como exemplo, dentre outros, quando você
            nos autorizar, poderemos prestar suporte específico.
          </ContentParagraph>
        </ContentSection>

        <ContentSection>
          <ContentSectionTitle>
            4. Do tratamento e compartilhamento de dados pessoais
          </ContentSectionTitle>

          <ContentParagraph>
            A IN6 será a controladora dos seus dados pessoais, quando inseridos
            através do site: www.in6.com.br, dentro da função “criar currículo”
            ou captados por um de seus sistemas, com exceção do nosso serviço
            pago de impulsionamento de conta, o qual não teremos acesso aos
            dados de pagamento, já que serão armazenados e ficarão sob a guarda
            da plataforma de pagamento terceirizada. É nossa responsabilidade
            eleger adequadamente as bases legais condizentes com as finalidades
            previstas na presente política, bem como atender de forma direta às
            suas solicitações quanto aos direitos previstos na Lei Geral de
            Proteção de Dados.
          </ContentParagraph>

          <ContentParagraph>
            Seus dados poderão ser compartilhados com novos controladores e
            empresas autorizadas pela IN6 a utilizarem seus sistemas ou
            acessarem seus bancos de dados, sempre e especificamente no contexto
            de recrutamento e seleção ou para comunicação, somente pela IN6, de
            oferta de produtos e serviços que possam ser de interesse de
            candidatos.
          </ContentParagraph>

          <ContentParagraph>
            O resultado final para contratação é de responsabilidade das
            empresas autorizadas. A IN6 não tem ingerência sobre a condução e
            decisão dos processos seletivos ou sobre como novos controladores de
            dados utilizarão referidas informações para além de seus sistemas e
            plataformas.
          </ContentParagraph>

          <ContentParagraph>
            Nós também podemos compartilhar seus dados pessoais com outras
            empresas que contribuam para melhorar ou facilitar as operações
            através do site, como por exemplo: ferramentas de gerenciamento de
            bancos de dados, gerenciamento de e-mails, fluxos internos e de
            atendimento aos usuários, dentre outros. A IN6 se compromete a
            contratar apenas fornecedores que cumpram a legislação protetiva de
            dados, mediante a assinatura de acordos ou convênios com cláusulas
            específicas para isso.
          </ContentParagraph>

          <ContentParagraph>
            Em casos em que haja a necessidade de transferência internacional de
            dados pessoais para outros países, a IN6 se compromete a contratar
            apenas fornecedores e clientes que adotem medidas de segurança e
            boas práticas compatíveis com o nível de proteção estabelecido pela
            legislação brasileira, conforme regulamentação pela Autoridade
            Nacional de Dados Pessoais (ANPD).
          </ContentParagraph>

          <ContentParagraph>
            Na falta de regulamentação específica, nos comprometemos a trabalhar
            somente com empresas aderentes às demais normas de proteção de
            dados, como a GDPR europeia ou CCPA americana; ou com empresas cujos
            contratos contenham cláusulas específicas para as transferências com
            a IN6; bem como a restringir a transferência apenas ao estritamente
            necessário para cumprimento das obrigações assumidas com você.
          </ContentParagraph>
        </ContentSection>

        <ContentSection>
          <ContentSectionTitle>5. Proteção de dados</ContentSectionTitle>

          <ContentParagraph>
            Para proteger os dados pessoais coletados e garantir a segurança dos
            nossos sistemas, utilizamos tecnologias e procedimentos adequados de
            acordo com o nível de risco e o serviço fornecido, em conformidade
            com as previsões legais, requisitos regulatórios, mudanças de
            tecnologia, dentre outros fatores relevantes que possam influenciar
            a proteção de dados.
          </ContentParagraph>

          <ContentParagraph>
            Em razão da própria natureza da Internet, existe o risco de que
            terceiros mal-intencionados acessem indevidamente as informações
            armazenadas nos nossos sistemas. Caso isso ocorra, nos
            responsabilizamos nos limites previstos na legislação vigente
            aplicável, e os usuários serão informados acerca do problema e das
            providências adotadas pela empresa, além de orientações para
            garantir a segurança da conta.
          </ContentParagraph>

          <ContentParagraph>
            É proibido o uso de qualquer dispositivo, software ou outro recurso
            que venha a interferir nas atividades e operações da IN6, tanto pelo
            site quanto por outros sistemas ou bancos de dados. Caso seja
            identificada qualquer intromissão, tentativa, ou atividade que viole
            ou contrarie as leis de direito de propriedade intelectual e/ou as
            disposições estipuladas nesta Política e/ou leis vigentes
            aplicáveis, incluída a LGPD, o responsável estará sujeito às sanções
            aplicáveis, estejam elas em lei ou previstas neste documento. O
            responsável deverá, também, indenizar por eventuais danos causados.
          </ContentParagraph>
        </ContentSection>

        <ContentSection>
          <ContentSectionTitle>
            6. Prazo de conservação dos dados
          </ContentSectionTitle>

          <ContentParagraph>
            Manteremos os Dados Pessoais do Candidato preservados durante o
            tempo que seu acesso à plataforma permanecer ativo ou enquanto for
            necessário fornecer-lhe nossos Serviços. Em caso de inatividade,
            isto é, ausência de login ou acesso na plataforma por mais de 1 (um)
            ano, ou encerramento da conta, as informações serão preservadas por
            no máximo 5 (cinco) anos e, posteriormente, excluídas. Caso o
            usuário deseje, poderá entrar em contato conosco para solicitar a
            exclusão imediata dos seus dados.
          </ContentParagraph>
        </ContentSection>

        <ContentSection>
          <ContentSectionTitle>
            7. Acerca dos serviços pagos
          </ContentSectionTitle>

          <ContentParagraph>
            Através do nosso website você pode acessar a websites de terceiros
            ou plataformas de pagamento para efetuar pagamentos por serviços
            prestados. A IN6 não tem acesso aos dados bancários que o candidato
            fornece a terceiros.
          </ContentParagraph>
        </ContentSection>

        <ContentSection>
          <ContentSectionTitle>
            8. Direitos previstos na LGPD
          </ContentSectionTitle>

          <ContentParagraph>
            Em relação aos Dados Pessoais que o IN6 tem recolhido com a
            prestação de seus Serviços, o usuário tem os seguintes direitos:
            <ul>
              <li>
                Direito de confirmação: você pode confirmar se o IN6 realiza o
                tratamento de algum dado pessoal seu.
              </li>
              <li>
                Direito de anonimização, bloqueio ou eliminação: você poderá
                solicitar a anonimização, bloqueio ou eliminação de dados
                desnecessários, excessivos ou tratados em desconformidade com as
                finalidades previstas nesta Política, observando-se, para tanto,
                demais normas do ordenamento jurídico. Nesses casos, se houver
                anonimização ou bloqueio de dados que prejudiquem a manutenção
                ativa do seu currículo, sua participação em processos seletivos
                será prejudicada ou impossibilitada.
              </li>
              <li>
                Direito de informação: você poderá solicitar informações sobre
                com quais tipos de empresas compartilhamos os seus dados, bem
                como ser informado das consequências caso opte por não consentir
                na coleta e tratamento de seus dados pessoais, quando
                necessário.
              </li>
              <li>
                Direito de recusar marketing: a qualquer momento, você poderá
                solicitar o cancelamento de recebimento de materiais pelo
                próprio link disponibilizado no e-mail marketing enviado.
              </li>
              <li>
                Direito de portabilidade de informações: Você poderá exportar
                todas as informações da funcionalidade “criar currículo” em
                formato PDF.
              </li>
              <li>
                Direito de oposição aos tratamentos futuros para as demais bases
                legais. Você poderá solicitar a exclusão dos seus dados a
                qualquer tempo. Mas é importante que você saiba que alguns dados
                poderão ser mantidos para finalidades legais e que a exclusão
                poderá não surtir efeitos para as empresas controladoras que
                obtiveram acesso ao seu currículo através do IN6 antes do seu
                pedido.
              </li>
            </ul>
          </ContentParagraph>

          <ContentParagraph>
            Deve-se levar em conta que os direitos acima mencionados estão
            sujeitos a certas limitações, conforme estabelecido pela legislação
            aplicável. Os pedidos individuais serão tratados dentro do prazo
            estipulado pelos regulamentos relevantes, e começa a partir do
            momento em que o IN6 confirma a recepção de seu pedido.
          </ContentParagraph>

          <ContentParagraph>
            Se você deseja fazer uso dos direitos indicados, você deve contatar
            a IN6 pelo e-mail: contato@in6.com.br , com o assunto: SOLICITAÇÃO
            DE ESCLARECIMENTO.
          </ContentParagraph>

          <ContentParagraph>
            A IN6 irá gerir o pedido de acordo com a legislação aplicável. Caso
            o Candidato solicite informações sobre seus Dados Pessoais ou
            requeira o exercício dos direitos indicados na legislação aplicável,
            a IN6 poderá, antes de responder a qualquer solicitação, pedir que
            se forneça algumas informações para confirmar sua identidade,
            inclusive para fins de segurança e validação da solicitação.
          </ContentParagraph>

          <ContentParagraph>
            Em relação aos Dados Pessoais fornecidos às empresas ou
            recrutadores, o candidato deve exercer seus direitos contra eles.
          </ContentParagraph>
        </ContentSection>

        <ContentSection>
          <ContentSectionTitle>9. Cookies</ContentSectionTitle>

          <ContentParagraph>
            O nosso site utiliza cookies e outras tecnologias semelhantes para
            armazenar e gerenciar as suas preferências de navegação, habilitar
            conteúdos e coletar dados de análise e utilização do site. O uso
            dessas tecnologias é comum em sites e plataformas em geral,
            consistindo em um pequeno arquivo de texto, colocando seu
            dispositivo ou navegador, que permite a sua identificação enquanto
            usuário e o dispositivo utilizado, bem como para coletar as
            informações de navegação.
          </ContentParagraph>

          <ContentParagraph>
            Os cookies que utilizamos podem desempenhar diferentes funções.
            Alguns são necessários e essenciais para a navegação e utilização
            dos recursos da IN6. Outros coletam informações sobre como você
            utiliza o site e servem para melhorar o desempenho e a experiência
            de navegação. Por fim, os cookies funcionais relembram suas escolhas
            e preferências, personalizando a sua experiência no site.
          </ContentParagraph>

          <ContentParagraph>
            Existe ainda a opção de desabilitar os cookies através das
            configurações de seu navegador, instalando plug-ins disponíveis no
            mercado, ou ainda fazendo uso de outras tecnologias que entenda
            serem necessárias.
          </ContentParagraph>
        </ContentSection>

        <ContentSection>
          <ContentSectionTitle>
            10. Exclusão do recebimento de comunicações comerciais e alertas
          </ContentSectionTitle>

          <ContentParagraph>
            Para deixar de receber alertas ou comunicações comerciais, o
            candidato deve fazer o pedido clicando no link correspondente na
            parte inferior do e-mail recebido. Em alguns casos será necessário
            indicar o motivo da exclusão, clicando na opção aplicável. Da mesma
            forma, o pedido da exclusão deve ser feito para cada categoria de
            correio eletrônico que receber.
          </ContentParagraph>
        </ContentSection>

        <ContentSection>
          <ContentSectionTitle>
            11. Requerimento de autoridades
          </ContentSectionTitle>

          <ContentParagraph>
            A IN6 possui o compromisso de cooperar com as autoridades
            competentes e terceiros para garantir o cumprimento das leis,
            inclusive em matéria de proteção de direitos de propriedade
            industrial e intelectual, prevenção de fraudes, proteção de dados
            pessoais, dentre outros. Nesse contexto, apenas revelaremos seus
            dados pessoais mediante requerimentos de autoridades judiciais ou
            governamentais competentes, no âmbito de investigações e processos
            conduzidos por estas, desde que não exista vedação legal
            estabelecendo o sigilo.
          </ContentParagraph>
        </ContentSection>

        <ContentSection>
          <ContentSectionTitle>12. Atualizações</ContentSectionTitle>

          <ContentParagraph>
            O IN6 pode atualizar esta Política de Privacidade publicando uma
            nova versão no website ou app. Se for feita alguma modificação que
            possa afetar os direitos dos usuários, aumentando suas obrigações, a
            IN6 irá notificá-lo por e-mail ou por um aviso na website.
          </ContentParagraph>

          <ContentParagraph>
            O usuário deve revisar periodicamente a Política de Privacidade para
            ser atualizado de todas as novidades. A utilização contínua dos
            nossos Serviços após a publicação ou, conforme o caso, após o envio
            de aviso sobre alterações na presente Política de Privacidade,
            implica que o tratamento dos seus Dados Pessoais acontecerá em
            conformidade com a Política de Privacidade atualizada. Se não
            concordar com alguma das alterações, o candidato pode entrar em
            contato com o portal web de acordo com as disposições do Contrato,
            ou excluir a sua conta, conforme o caso.
          </ContentParagraph>
        </ContentSection>

        <ContentSection>
          <ContentSectionTitle>13. Disposições gerais</ContentSectionTitle>

          <ContentParagraph>
            Para o exercício dos direitos indicados e para qualquer consulta
            sobre esta Política de Privacidade pode-se contatar com o IN6
            através do e-mail contato@in6.com.br.
          </ContentParagraph>

          <ContentParagraph>
            A IN6 poderá, antes de responder a qualquer solicitação, pedir que o
            candidato forneça algumas informações para confirmar sua identidade,
            inclusive para fins de segurança e validação da solicitação: nome
            completo, endereço de e-mail usado para a website e as razões da
            solicitação. Será também necessário acompanhar a documentação
            necessária para apoiar o pedido e para processar a solicitação.
          </ContentParagraph>

          <ContentParagraph>
            No momento do acesso a plataforma, você deverá ler, compreender e
            aceitar esta Política, conforme opção específica disponibilizada no
            formulário. Porém, esta Política tem natureza de contrato de adesão
            e passa por revisões periódicas, sem que seja necessária a sua
            notificação prévia. Por isso, é importante que você consulte o
            documento para saber se continua concordando com seus termos antes
            de seguir com a navegação.
          </ContentParagraph>

          <ContentParagraph>
            A Política de Privacidade da IN6 está em conformidade e deverá ser
            interpretada com base nas leis vigentes na República Federativa do
            Brasil. Para dirimir eventuais dúvidas ou questões relativas a ela,
            as partes elegem o Foro da Comarca de Mossoró/RN.
          </ContentParagraph>
        </ContentSection>
      </Container>
    </>
  );
};
