import closeIcon from 'assets/img/close.svg';

import { useCallback, useEffect, useRef, useState } from 'react';
import { OmitId } from 'types';
import { IVacancySkill } from 'types/vacancy';
import {
  Container,
  Skill,
  SkillInputContainer,
  SkillTextArea,
  SkillTextAreaDescription,
  TextAreaContainer,
} from './styles';

interface SkillProps {
  skill: OmitId<IVacancySkill>;
  handleExcludeSkill: () => void;
  handleChangeSkillDescription: (updatedDescription: string) => void;
}

export const SkillContainer: React.FC<SkillProps> = ({
  skill: { name, description },
  handleExcludeSkill,
  handleChangeSkillDescription,
}) => {
  const textAreaContainerRef = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [previousTextAreaHeight, setPreviousTextAreaHeight] = useState(54);

  const handleScrollOnTextChange = useCallback(
    (updatedContent: string) => {
      const { current: textAreaContainerCurrent } = textAreaContainerRef;
      if (!textAreaContainerCurrent) return;
      textAreaContainerCurrent.dataset.replicatedValue = updatedContent;

      const { current: textAreaCurrent } = textAreaRef;
      if (!textAreaCurrent) return;

      const { scrollHeight } = textAreaCurrent;
      if (scrollHeight > previousTextAreaHeight) {
        textAreaCurrent.scrollIntoView();
      }

      setPreviousTextAreaHeight(scrollHeight);
    },
    [previousTextAreaHeight],
  );

  useEffect(() => {
    handleScrollOnTextChange(description);
  }, [description, handleScrollOnTextChange]);

  return (
    <Container>
      <SkillInputContainer>
        <Skill>
          <span>{name}</span>

          <button type="button" onClick={handleExcludeSkill}>
            <img src={closeIcon} alt="Excluir competência" />
          </button>
        </Skill>

        <TextAreaContainer ref={textAreaContainerRef}>
          <SkillTextArea
            ref={textAreaRef}
            value={description}
            placeholder="Diga como essa habilidade se relaciona com a vaga"
            autoComplete="off"
            onChange={({ target: { value } }) =>
              handleChangeSkillDescription(value)
            }
            maxLength={500}
          />
        </TextAreaContainer>
      </SkillInputContainer>

      <SkillTextAreaDescription>
        <span className="input-description">
          Adicione uma descrição de até 500 caracteres
        </span>
        <span className="characters-counter">{`${description.length} / 500`}</span>
      </SkillTextAreaDescription>
    </Container>
  );
};
