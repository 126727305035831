import { StartupEmployeesQuantity } from 'types/startup';
import { OptionTypeBaseWithEnumValue } from './OptionTypeBaseWithEnumValue';

export const employeesQuantityOptions: OptionTypeBaseWithEnumValue<StartupEmployeesQuantity>[] =
  [
    { id: 1, label: '1-10', enumValue: StartupEmployeesQuantity.Q_1TO10 },
    { id: 2, label: '11-50', enumValue: StartupEmployeesQuantity.Q_11TO50 },
    { id: 3, label: '51-100', enumValue: StartupEmployeesQuantity.Q_51TO100 },
    { id: 4, label: '101-200', enumValue: StartupEmployeesQuantity.Q_101TO200 },
    { id: 5, label: '200+', enumValue: StartupEmployeesQuantity.Q_200PLUS },
  ];
