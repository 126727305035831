import { Depth16 } from 'assets/colors/boxShadows';
import { BackgroundColor } from 'assets/colors/palette';
import styled, { css } from 'styled-components';

interface OptionsContainerProps {
  isVisible: boolean;
}

export const Container = styled.div`
  position: relative;

  & > button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;

    width: 24px;
    height: 24px;
  }
`;

export const OptionsContainer = styled.div<OptionsContainerProps>`
  position: absolute;
  right: -5vw;

  width: 100vw;
  z-index: 2;
  background-color: ${BackgroundColor};

  display: flex;
  flex-direction: column;
  gap: 16px;

  padding: 32px 20px;
  border-radius: 0 0 8px 8px;
  box-shadow: ${Depth16};
  transition: 0.4s;

  button {
    width: 100%;
  }

  ${({ isVisible }) =>
    isVisible
      ? css`
          top: 44px;
          visibility: visible;
          opacity: 1;
        `
      : css`
          top: 40px;
          visibility: hidden;
          opacity: 0;
        `}
`;
