import styled, { css } from 'styled-components';
import {
  MediumEmphasis,
  OutlineColor,
  BackgroundColor,
  HighEmphasis,
  LowEmphasis,
} from 'assets/colors/palette';
import {
  Bold14Styles,
  Bold18Styles,
  Regular14Styles,
  Regular16Styles,
} from 'assets/fonts/fontStyles';
import { TextGradientPrimaryBackground } from 'assets/colors/background';

interface CandidateTabButtonProps {
  isSelected: boolean;
}

export const Container = styled.div`
  width: 1248px;
  margin-inline: auto;
  padding-top: 60px;

  display: flex;
  justify-content: space-between;
`;

export const Header = styled.header`
  width: 100%;
  height: 64px;
  background: ${BackgroundColor};
  z-index: 2;

  display: flex;
  justify-content: center;
  align-items: center;

  ${Bold18Styles}
  color: ${HighEmphasis};

  & > div {
    width: 1248px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const CandidatesSearch = styled.div`
  width: 744px;
`;

export const SearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;
  margin-bottom: 18px;

  width: 100%;
  padding: 8px 12px;
  background: rgba(220, 220, 220, 0.2);
  border-radius: 8px;
`;

export const SearchBar = styled.div`
  width: 425px;
  height: 48px;
  padding-inline: 12px;

  background: ${BackgroundColor};
  border: 1px solid ${OutlineColor};
  border-radius: 8px;
  cursor: text;

  display: flex;
  align-items: center;
  gap: 12px;

  input {
    ${Regular16Styles}
    color: ${HighEmphasis};
    height: 100%;
    border-radius: 8px;

    flex: 1;
    outline: 0;

    &::placeholder {
      color: ${OutlineColor};
    }
  }

  img {
    width: 20px;
    height: 20px;

    opacity: 0.2;
    filter: invert(76%) sepia(11%) saturate(0%) hue-rotate(186deg)
      brightness(95%) contrast(87%);
  }
`;

export const NoCandidatesContainer = styled.div`
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.75px;
  font-weight: 500;
  color: ${HighEmphasis};

  padding: 8px 16px;
  width: fit-content;
  height: 40px;

  border: 1px solid ${OutlineColor};
  border-radius: 100px;
`;

export const CandidatesTable = styled.div`
  margin-top: 32px;
  position: relative;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  section {
    width: 100%;
    height: 64px;
  }

  .sticky-div {
    position: sticky;
    z-index: 1;
    left: 0;
    top: 0;
    margin-top: -64px;

    width: 100%;
    height: 64px;
    background-color: ${BackgroundColor};
  }
`;

export const ColumnNamesContainer = styled.section`
  display: flex;
  align-items: center;
  gap: 34px;

  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(220, 220, 220, 0.2);

  padding-left: 64px;
  border-radius: 8px 8px 0px 0px;
  border: 1px solid ${OutlineColor};

  * {
    ${Bold14Styles}
    color: ${MediumEmphasis};
    background-color: transparent;
  }
`;

export const SearchedCandidatesContainer = styled.div`
  height: calc(100vh - 266px);
  max-height: calc(100vh - 266px);
`;

export const NoSelectedCandidateContainer = styled.div`
  height: 70%;
  justify-content: center;
  gap: 8px;

  p {
    ${Regular14Styles}
    line-height: 24px;
    color: ${LowEmphasis};

    width: 225px;
    text-align: center;
  }
`;

export const SelectedCandidate = styled.div`
  width: 446px;
  height: calc(100vh - 108px);
  max-height: calc(100vh - 108px);
  top: 124px;

  background: ${BackgroundColor};
  border: 1px solid ${OutlineColor};
  border-radius: 8px;

  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(217, 217, 217, 0.2);
  }

  &::-webkit-scrollbar-thumb {
    background: ${MediumEmphasis};
    border-radius: 100px;
  }

  &,
  ${NoSelectedCandidateContainer} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const SelectedCandidateTabs = styled.div`
  display: flex;
  width: 406px;

  margin: 20px auto 12px;
  justify-content: space-between;
  background-color: rgba(220, 220, 220, 0.2);
  border-radius: 8px;
`;

export const CandidateTabButton = styled.button<CandidateTabButtonProps>`
  width: 50%;
  height: 48px;
  border-radius: 8px;
  transition: 0.2s;

  span {
    ${Regular14Styles}
  }

  ${({ isSelected }) =>
    isSelected
      ? css`
          background-color: #fce6e9;

          span {
            ${TextGradientPrimaryBackground}
            font-weight: 700;
          }
        `
      : css`
          background-color: transparent;

          span {
            color: ${MediumEmphasis};
          }
        `}
`;

export const CandidateResumeLastUpdateDate = styled.div`
  width: 400px;
  height: 40px;

  border: 1px solid ${OutlineColor};
  border-radius: 100px;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;

  p {
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.75px;
    font-weight: 500;
    color: ${HighEmphasis};
  }
`;
