import { MediumEmphasis } from 'assets/colors/palette';
import { Regular14Styles, Regular24Styles } from 'assets/fonts/fontStyles';
import { PrimaryButton } from 'components/Buttons';
import styled from 'styled-components';

export const Container = styled.div`
  width: 1248px;
  margin-inline: auto;

  @media (max-width: 1100px) {
    width: 90vw;
  }
`;

export const TestsContainer = styled.div`
  display: flex;
  flex-flow: wrap;
  gap: 33px;

  @media (max-width: 1100px) {
    gap: 32px;
    padding-bottom: 50px;
  }
`;

export const TestInformation = styled.div`
  width: 394px;
  height: 300px;
  padding: 32px 32px 39px;

  background-color: rgba(220, 220, 220, 0.25);
  border-radius: 8px;
  position: relative;

  h2 {
    ${Regular24Styles}
    line-height: 32px;
    width: 207px;

    margin-bottom: 16px;
  }

  span,
  p {
    ${Regular14Styles}
    color: ${MediumEmphasis};
  }

  p {
    line-height: 24px;
    margin-bottom: 15px;
  }

  span {
    font-weight: 700;
  }

  ${PrimaryButton} {
    height: 48px;
    width: max-content;

    position: absolute;
    bottom: 39px;
    left: 32px;
  }

  @media (max-width: 1100px) {
    width: 90vw;
    padding: 32px 24px 39px 32px;
  }
`;

export const Header = styled.header`
  width: 100%;
  height: 128px;

  display: flex;
  flex-direction: column;
  padding-block: 37px;
  margin-bottom: 64px;
  gap: 16px;

  h3 {
    ${Regular24Styles}
  }

  span {
    ${Regular14Styles}
    line-height: 20px;
    color: ${MediumEmphasis};
  }

  @media (max-width: 1100px) {
    width: 208px;
    padding-block: 32px 37px;
    margin-bottom: 20px;
    gap: 12px;

    h3 {
      font-size: 18px;
      line-height: 18px;
    }

    span {
      font-size: 12px;
    }
  }
`;
