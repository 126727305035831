import { object } from 'yup';
import { yupRequiredNumberField } from './yupRequiredFields';

export const yupObjectWithId = object().shape({
  id: yupRequiredNumberField.test(
    'validNumber',
    'Campo obrigatório',
    value => (value ?? 0) > 0,
  ),
});
