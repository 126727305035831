import diamondIcon from 'assets/img/diamond.svg';
import closeIcon from 'assets/img/close.svg';

import { Modal } from 'components/Modal';
import { useStartup } from 'hooks/startup';
import {
  Container,
  SubscribeModal,
  CloseModalButton,
  ModalTitle,
  ModalSubTitle,
  TalkToConsultantContainer,
  ModalBottomText,
  SubscribeIcon,
  HeaderContainer,
} from './styles';

export const SubscribeHeader: React.FC = () => {
  const { isShowingSubscribeModal, updateIsShowingSubscribeModal } =
    useStartup();

  return (
    <Container>
      <Modal isVisible={isShowingSubscribeModal}>
        <SubscribeModal>
          <CloseModalButton
            type="button"
            onClick={() => updateIsShowingSubscribeModal(false)}
          >
            <img src={closeIcon} alt="Ícone de X" />
          </CloseModalButton>

          <SubscribeIcon>
            <img src={diamondIcon} alt="Diamante" />
            Seja assinante
          </SubscribeIcon>

          <ModalTitle>O R&S da sua empresa em um novo patamar</ModalTitle>

          <ModalSubTitle>
            Abandone de vez o recrutamento manual na sua empresa. Tenha uma
            ferramenta completa para receber currículos, avaliar candidatos e
            gerenciar banco de talentos.
          </ModalSubTitle>

          <TalkToConsultantContainer>
            <p>
              Fale agora com um de nossos consultores para tratar de planos e
              preços mais adequados as necessidades da sua empresa.
            </p>

            <a href="web" target="_blank" rel="noreferrer">
              <span>Falar com consultor</span>
            </a>
          </TalkToConsultantContainer>

          <ModalBottomText>
            <p>
              Você será redirecionada para nosso canal no WhatsApp. Um membro da
              equipe IN6 entrará em contato dentro de 24 horas úteis.
            </p>

            <p>
              Atendimento disponível de segunda a sexta-feira, das 8h às 18h.
            </p>
          </ModalBottomText>
        </SubscribeModal>
      </Modal>

      <HeaderContainer>
        <span>
          Tenha acesso ilimitado à plataforma de recrutamento e seleção da IN6.
          Seja agora um assinante!
        </span>

        <button
          type="button"
          onClick={() => updateIsShowingSubscribeModal(true)}
        >
          Saiba mais
        </button>
      </HeaderContainer>
    </Container>
  );
};
