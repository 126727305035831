import closeIcon from 'assets/img/close.svg';
import correctIcon from 'assets/img/correct.svg';

import { HighGreen, HighRed } from 'assets/colors/palette';
import { AlertModal } from 'components/AlertModal';
import React, { createContext, useCallback, useContext, useState } from 'react';

export interface ModalProps {
  isVisible: boolean;
  message: string;
  backgroundColor?: string;
  icon?: string;
  marginLeft?: number;
  marginTop?: number;
  forceIconWhiteColor?: boolean;
}

interface IAlertModalContext {
  showModal: (updatedModalProps: Omit<ModalProps, 'isVisible'>) => void;
}

export const ERROR_MODAL_CONFIGURATION: Omit<
  ModalProps,
  'message' | 'isVisible'
> = {
  backgroundColor: HighRed,
  icon: closeIcon,
  forceIconWhiteColor: true,
};

export const SUCCESS_MODAL_CONFIGURATION: Omit<
  ModalProps,
  'message' | 'isVisible'
> = {
  backgroundColor: HighGreen,
  icon: correctIcon,
  forceIconWhiteColor: false,
};

const DEFAULT_MODAL_PROPS: ModalProps = {
  backgroundColor: '#000000',
  icon: undefined,
  isVisible: false,
  message: '',
  marginTop: 0,
  marginLeft: 0,
  forceIconWhiteColor: false,
};

const alertModalContext = createContext<IAlertModalContext>(
  {} as IAlertModalContext,
);

const AlertModalContext: React.FC = ({ children }) => {
  const [modalProps, setModalProps] = useState<ModalProps>(DEFAULT_MODAL_PROPS);

  const showModal = useCallback(
    (updatedModalProps: Omit<ModalProps, 'isVisible'>) => {
      setModalProps({
        ...DEFAULT_MODAL_PROPS,
        ...updatedModalProps,
        isVisible: true,
      });

      setTimeout(() => {
        setModalProps(props => ({ ...props, isVisible: false }));
      }, 4000);
    },
    [],
  );

  return (
    <alertModalContext.Provider value={{ showModal }}>
      {children}
      <AlertModal modalProps={modalProps} />
    </alertModalContext.Provider>
  );
};

const useAlertModal = (): IAlertModalContext => useContext(alertModalContext);

export { AlertModalContext, useAlertModal };
