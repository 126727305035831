import {
  BackgroundColor,
  HighEmphasis,
  MediumEmphasis,
  OutlineColor,
  PrimaryColor,
} from 'assets/colors/palette';
import {
  Medium14Styles,
  Medium16Styles,
  Regular14Styles,
  Regular24Styles,
} from 'assets/fonts/fontStyles';
import styled, { css } from 'styled-components';

interface SearchVacanciesInputProps {
  disabled: boolean;
}

interface StatusOptionProps {
  isSelected: boolean;
}

export const Container = styled.div`
  width: 1248px;
  margin-inline: auto;
`;

export const Header = styled.header`
  width: 100%;
  height: 128px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CompanyMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;

  .welcome-message {
    display: flex;
    align-items: center;
    gap: 8px;

    h3 {
      ${Regular24Styles}
      color: ${HighEmphasis};
    }
  }

  span {
    ${Regular14Styles}
    color: ${MediumEmphasis};
  }
`;

export const VacanciesActionsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const SearchVacanciesInput = styled.div<SearchVacanciesInputProps>`
  display: flex;
  gap: 12px;
  align-items: center;

  padding-left: 32px;
  width: 244px;
  height: 48px;

  border: 1px solid ${OutlineColor};
  border-radius: 100px;
  background: ${BackgroundColor};
  transition: 0.2s;

  input {
    ${Medium16Styles}
    color: ${MediumEmphasis};

    width: calc(100% - 52px);
    height: 100%;

    border-radius: 100px;
    outline: 0;

    &:disabled {
      background: ${BackgroundColor};
    }

    &::placeholder {
      color: ${OutlineColor};
    }
  }

  img {
    width: 24px;
    height: 24px;

    filter: invert(100%) sepia(0%) saturate(1142%) hue-rotate(319deg)
      brightness(104%) contrast(73%);
    opacity: 0.2;
  }

  ${({ disabled }) =>
    !disabled &&
    css`
      cursor: text;
      &:hover,
      &:focus-within {
        border-color: ${PrimaryColor};
      }
    `}
`;

export const Main = styled.main`
  padding-block: 48px 12%;
`;

export const VacancyStatusSelection = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const StatusOption = styled.button<StatusOptionProps>`
  display: flex;
  align-items: center;
  gap: 10px;

  padding: 8px 16px;
  height: 40px;
  border-radius: 100px;
  border: 1px solid ${HighEmphasis};

  ${Medium14Styles}
  line-height: 24px;
  transition: 0.2s;

  .vacancies-quantity {
    font-size: 12px;
    line-height: 14px;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 8px 12px;
    height: 21px;
    border-radius: 100px;
  }

  ${({ isSelected }) =>
    isSelected
      ? css`
          background-color: ${HighEmphasis};
          color: ${BackgroundColor};

          .vacancies-quantity {
            color: ${HighEmphasis};
            background-color: ${BackgroundColor};
          }
        `
      : css`
          background-color: ${BackgroundColor};
          color: ${HighEmphasis};
          border-color: ${OutlineColor};

          .vacancies-quantity {
            color: ${BackgroundColor};
            background-color: ${HighEmphasis};
          }
        `}
`;

export const VacanciesContainer = styled.div`
  display: flex;
  flex-flow: wrap;
  gap: 30px;

  margin-top: 48px;
  position: relative;
`;

export const NoVacanciesContainer = styled.div`
  width: 100%;
  padding-top: 6.5%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  p {
    ${Regular14Styles}
    line-height: 24px;
    width: 415px;
    margin-top: 40px;
    text-align: center;
    color: ${MediumEmphasis};
  }
`;
