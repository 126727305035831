import { CategoriesCriteria } from 'types/criteria';

export const getCriteriaQuantity = (
  allCriteria: CategoriesCriteria,
  criteriaToGetQuantity: keyof CategoriesCriteria,
): number => {
  const selectedCriteria = allCriteria[criteriaToGetQuantity];
  const isArray = Array.isArray(selectedCriteria);
  if (isArray) return (selectedCriteria as []).length;

  const { minimumPortuguesePunctuation, minimumLogicalReasoningPunctuation } =
    selectedCriteria as {
      minimumPortuguesePunctuation: number;
      minimumLogicalReasoningPunctuation: number;
    };

  return (
    Number(minimumPortuguesePunctuation !== 0) +
    Number(minimumLogicalReasoningPunctuation !== 0)
  );
};
