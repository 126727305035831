import closeIcon from 'assets/img/close.svg';

import { useCallback, useRef, useState } from 'react';
import { GetCriteriaComponentProps } from 'types/criteria';
import { Container, CriteriaContainer } from './styles';

export const DefaultGetCriteria: React.FC<GetCriteriaComponentProps> = ({
  previousCriteria,
  handleUpdateCriteria,
  children,
}) => {
  const parsedPreviousCriteria = previousCriteria as string[];

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const placeholderRef = useRef<HTMLParagraphElement>(null);

  const [newCriteria, setNewCriteria] = useState('');

  const handleOnChange = useCallback((updatedValue: string) => {
    const { current } = inputRef;
    if (!current) return;

    const temporaryElement = document.createElement('span');
    temporaryElement.style.setProperty('width', 'max-content');
    temporaryElement.style.setProperty('opacity', '0');
    temporaryElement.style.setProperty('visibility', 'hidden');
    temporaryElement.style.setProperty('padding-inline', '16px 44px');
    temporaryElement.innerText = updatedValue;

    containerRef.current?.appendChild(temporaryElement);
    const { clientWidth } = temporaryElement;
    containerRef.current?.removeChild(temporaryElement);

    if (clientWidth < 407) {
      current.style.width = `${clientWidth}px`;
      setNewCriteria(updatedValue);
    }
  }, []);

  const handleAddNewCriteria = useCallback(() => {
    if (!newCriteria) return;

    handleUpdateCriteria([...parsedPreviousCriteria, newCriteria]);
    setNewCriteria('');
  }, [handleUpdateCriteria, newCriteria, parsedPreviousCriteria]);

  const handleRemoveCriteria = useCallback(
    (criteriaToRemove: string) => {
      handleUpdateCriteria(
        parsedPreviousCriteria.filter(
          criteria => criteria !== criteriaToRemove,
        ),
      );
    },
    [handleUpdateCriteria, parsedPreviousCriteria],
  );

  return (
    <Container ref={containerRef} onClick={() => inputRef.current?.focus()}>
      {parsedPreviousCriteria.map(criteria => (
        <CriteriaContainer key={criteria}>
          <span>{criteria}</span>

          <button
            type="button"
            onClick={event => {
              event.stopPropagation();
              handleRemoveCriteria(criteria);
            }}
          >
            <img src={closeIcon} alt="Remover critério" />
          </button>
        </CriteriaContainer>
      ))}

      <input
        ref={inputRef}
        value={newCriteria}
        type="text"
        onChange={({ target: { value } }) => handleOnChange(value)}
        onKeyUp={({ code }) => code === 'Enter' && handleAddNewCriteria()}
        onFocus={() =>
          placeholderRef.current?.style.setProperty('opacity', '0')
        }
        onBlur={() => placeholderRef.current?.style.setProperty('opacity', '1')}
      />

      {newCriteria.length === 0 && parsedPreviousCriteria.length === 0 && (
        <p ref={placeholderRef} className="placeholder">
          {children}
        </p>
      )}
    </Container>
  );
};
