import styled, { css } from 'styled-components';

import {
  BackgroundColor,
  HighEmphasis,
  MediumEmphasis,
  OutlineColor,
} from 'assets/colors/palette';
import { Medium16Styles, Regular14Styles } from 'assets/fonts/fontStyles';
import { Depth16 } from 'assets/colors/boxShadows';
import { StepExhibitionProps } from '../styles';

interface ContainerProps {
  stepIsDragging: boolean;
}

interface StepOptionsContainerProps {
  isVisible: boolean;
}

export const StepName = styled.div<StepExhibitionProps>`
  ${Regular14Styles}

  height: 24px;
  padding: 2px 10px;
  border-radius: 4px;

  color: ${({ backgroundColor }) =>
    backgroundColor ? HighEmphasis : MediumEmphasis};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ?? 'rgba(220, 220, 220, 0.2)'};
`;

export const Step = styled.button`
  width: 276px;
  padding: 6px 9px;
  border-radius: 4px;
  background-color: transparent;

  display: flex;
  margin-inline: auto;
  justify-content: space-between;
  align-items: center;
  transition: 0.2s;

  &:hover {
    background-color: rgba(217, 217, 217, 0.2);
  }

  &:disabled {
    cursor: auto;
  }

  ${StepName}, button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const OpenStepOptionsButton = styled.button`
  position: absolute;
  right: 6px;
  top: 6px;
  transform: rotate(90deg);

  background-color: transparent;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div<ContainerProps>`
  position: relative;

  & + & {
    margin-top: 2px;
  }

  ${({ stepIsDragging }) =>
    stepIsDragging &&
    css`
      ${Step}, ${OpenStepOptionsButton} {
        opacity: 0;
      }
    `}
`;

export const StepOptionsContainer = styled.div<StepOptionsContainerProps>`
  display: flex;
  flex-direction: column;

  position: absolute;
  top: -200%;
  left: 44%;
  z-index: 1;

  width: 296px;
  height: 148px;
  background-color: ${BackgroundColor};
  box-shadow: ${Depth16};

  border: 1px solid ${OutlineColor};
  border-radius: 8px;
  transition: 0.3s;

  p {
    ${Regular14Styles}
    color: ${HighEmphasis};

    width: 100%;
    padding: 20px;
    border-bottom: 1px solid ${OutlineColor};
  }

  ${({ isVisible }) =>
    isVisible
      ? css`
          opacity: 1;
          visibility: visible;
        `
      : css`
          visibility: hidden;
          opacity: 0;
        `}
`;

export const LeftSideContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DragButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: grab;

  img {
    margin-left: -10px;
    -webkit-user-drag: none;
  }

  img + img {
    margin-left: -16px;
  }
`;

export const DeleteStepButton = styled.button`
  ${Medium16Styles}

  width: 256px;
  height: 48px;

  color: rgba(224, 0, 37, 1);
  background-color: ${BackgroundColor};
  border: 1px solid rgba(224, 0, 37, 1);
  border-radius: 100px;

  margin: auto;
`;
