import { GrayBackground, GrayPlaceHolder } from 'assets/colors/palette';
import { Medium14Styles } from 'assets/fonts/fontStyles';
import styled from 'styled-components';

export const Container = styled.i`
  ${Medium14Styles}
  line-height: 24px;
  font-style: normal;

  background: ${GrayPlaceHolder};
  color: ${GrayBackground};

  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;

  &,
  img {
    border-radius: 50%;
  }

  img {
    width: 100%;
    height: 100%;
  }
`;
