import styled, { css } from 'styled-components';
import {
  BackgroundColor,
  HighEmphasis,
  OutlineColor,
  PrimaryColor,
} from 'assets/colors/palette';
import {
  Bold32Styles,
  Medium16Styles,
  Regular16Styles,
} from 'assets/fonts/fontStyles';
import { TextGradientPrimaryBackground } from 'assets/colors/background';
import { PrimaryButton } from 'components/Buttons';

interface AccountTypeButtonProps {
  isSelected: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 400px;
  align-items: center;
  gap: 2px;

  min-height: 746px;
  margin-inline: auto;
  padding-block: 3.2%;

  ${PrimaryButton} {
    width: 400px;
    margin-block: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    & > div {
      margin-top: 4px;
    }
  }

  @media (max-width: 1100px) {
    width: 100vw;
    max-width: 400px;
    padding: 48px 18px 3vh;

    input {
      max-width: 100%;
    }

    ${PrimaryButton} {
      width: 100%;
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  gap: 16px;

  margin-bottom: 50px;
  color: ${HighEmphasis};
`;

export const SubTitle = styled.p`
  &,
  button {
    ${Regular16Styles}
  }

  button {
    ${TextGradientPrimaryBackground}
  }

  @media (max-width: 1100px) {
    &,
    button {
      font-size: 14px;
    }
  }
`;

export const Title = styled.h2`
  ${Bold32Styles}
`;

export const AccountTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  width: 100%;
  gap: 24px;

  span {
    ${Medium16Styles}
    color: ${HighEmphasis};
  }
`;

export const AccountTypeButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const AccountTypeButton = styled.button<AccountTypeButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  background: ${BackgroundColor};
  border: 1px solid ${OutlineColor};
  border-radius: 8px;

  width: 193px;
  padding: 20px 16px;
  transition: 0.2s;

  &:hover {
    border-color: ${PrimaryColor};
  }

  span {
    ${Medium16Styles};
    color: ${HighEmphasis};
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      border-color: ${PrimaryColor};
      span {
        ${TextGradientPrimaryBackground};
      }
      img {
        filter: invert(33%) sepia(39%) saturate(3822%) hue-rotate(0deg)
          brightness(104%) contrast(108%);
      }
    `}

  @media(max-width: 1100px) {
    width: 43.5vw;
    max-width: 170px;
  }
`;

export const NameInputs = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1100px) {
    div,
    input {
      max-width: 170px !important;
    }
  }
`;
