import { BackgroundColor, GrayBody } from 'assets/colors/palette';
import { Bold32Styles, Regular14Styles } from 'assets/fonts/fontStyles';
import { SecondaryButton } from 'components/Buttons';
import styled from 'styled-components';

export const Container = styled.div`
  width: 540px;
  height: 495px;
  border-radius: 8px;
  background-color: ${BackgroundColor};

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  & > img {
    margin-block: 12% 4%;
  }

  ${SecondaryButton} {
    width: 233px;
    height: 48px;
    padding-block: 0;
    padding-inline: 28px 18px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-block: 2% auto;

    img {
      width: 24px;
      height: 24px;
    }
  }

  @media (max-width: 1100px) {
    width: 86.5vw;

    ${SecondaryButton} {
      margin-top: 3vh;
    }
  }
`;

export const TextContainer = styled.div`
  width: 345px;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 16px;
  color: ${GrayBody};
  gap: 16px;

  h3 {
    ${Bold32Styles}
    font-size: 24px;
    letter-spacing: 0.75px;
    margin-bottom: 2%;
  }

  p {
    ${Regular14Styles}
    line-height: 24px;
    margin-bottom: 2%;
  }

  @media (max-width: 1100px) {
    width: 87%;
  }
`;
