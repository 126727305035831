import { BackgroundColor, OutlineColor } from 'assets/colors/palette';
import { Medium16Styles } from 'assets/fonts/fontStyles';
import styled from 'styled-components';

export const Container = styled.div`
  width: 808px;
  background-color: ${BackgroundColor};
  border-radius: 8px;

  @media (max-width: 1100px) {
    width: 90vw;
  }
`;

export const ModalHeader = styled.div`
  justify-content: space-between;
  height: 60px;
  padding: 6px 20px;
  border-bottom: 1px solid ${OutlineColor};

  &,
  button {
    display: flex;
    align-items: center;
  }

  span {
    ${Medium16Styles}
  }

  button {
    background-color: transparent;
    justify-content: center;
  }
`;
