import accountConfigIconWhite from 'assets/img/accountConfigIconWhite.svg';
import candidateIcon from 'assets/img/candidateIcon.svg';
import exitIcon from 'assets/img/exitIcon.svg';
import helpCenterIconWhite from 'assets/img/helpCenterIconWhite.svg';
import simpleArrowRight from 'assets/img/simpleArrowRight.svg';

import { useAuth } from 'hooks/auth';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { DashboardHeaderOptionsBox } from 'components/DashboardHeader/DashboardHeaderOptionsBox/styles';
import {
  routeAccountConfiguration,
  routeBar,
  routeHelpCenter,
  routePrivacyPolicy,
  routeTermsOfUse,
} from 'routes/routesAddresses';
import { UserIcon } from 'components/UserIcon';
import { HighEmphasis } from 'assets/colors/palette';
import { useCandidate } from 'hooks/candidate';
import { routeEditCandidateResume } from 'routes/candidatesRoutes/candidatesRoutesAddresses';
import { Container, ImageContainer, OpenOptionsButton } from './styles';

const ACTUAL_YEAR = new Date().getFullYear();

export const CandidateButtons: React.FC = () => {
  const history = useHistory();

  const { signOut, user } = useAuth();
  const { candidate } = useCandidate();

  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  if (user === undefined || candidate === undefined) return null;

  return (
    <Container>
      <OpenOptionsButton
        type="button"
        isOptionsVisible={isOptionsVisible}
        onClick={() => setIsOptionsVisible(value => !value)}
      >
        <img src={simpleArrowRight} alt="Seta para baixo" />
      </OpenOptionsButton>

      <div className="candidate-data">
        <UserIcon
          userName={user.name}
          image={candidate.photo}
          style={{
            backgroundColor:
              candidate.photo === null ? HighEmphasis : 'transparent',
            fontSize: 16,
          }}
        />
        <span id="candidate-name">{`${user.name} ${user.lastName}`}</span>
      </div>

      <DashboardHeaderOptionsBox
        isVisible={isOptionsVisible}
        onMouseLeave={() => setIsOptionsVisible(false)}
      >
        <button
          type="button"
          onClick={() => history.push(routeEditCandidateResume)}
        >
          <ImageContainer>
            <img src={candidateIcon} alt="Candidato" />
          </ImageContainer>
          Meu perfil
        </button>

        <button
          type="button"
          onClick={() => history.push(routeAccountConfiguration)}
        >
          <ImageContainer>
            <img src={accountConfigIconWhite} alt="Configurações da conta" />
          </ImageContainer>
          Configurações da conta
        </button>

        <button type="button" onClick={() => history.push(routeHelpCenter)}>
          <ImageContainer>
            <img src={helpCenterIconWhite} alt="Central de ajuda" />
          </ImageContainer>
          Central de ajuda
        </button>

        <button type="button" onClick={signOut}>
          <ImageContainer>
            <img src={exitIcon} alt="Sair" />
          </ImageContainer>
          Sair
        </button>

        <div id="about-container">
          <span>
            <a href={routePrivacyPolicy} target="_blank" rel="noreferrer">
              Privacidade
            </a>
            <a href={routeTermsOfUse} target="_blank" rel="noreferrer">
              Termos de uso
            </a>
            <a href={routeBar}>Sobre IN6 © {ACTUAL_YEAR}</a>
          </span>
        </div>
      </DashboardHeaderOptionsBox>
    </Container>
  );
};
