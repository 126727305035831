import { LanguageLevel } from 'types/languageEntity';
import { languageLevelOptions } from 'utils/typeOptions/languageLevelOptions';

export const convertLanguageLevelToPortuguese = (
  languageLevel: LanguageLevel,
): string => {
  const { label } = languageLevelOptions.find(
    ({ enumValue }) => enumValue === languageLevel,
  ) ?? { label: 'Iniciante' };

  return label;
};
