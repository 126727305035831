import { SecondaryButton } from 'components/Buttons';
import { CloseButton, Container, Header, MiddleContainer } from './styles';

interface InconclusiveResultsModalProps {
  handleRedoTest: () => void;
  handleDoTestLater: () => void;
}

export const InconclusiveResultsModal: React.FC<
  InconclusiveResultsModalProps
> = ({ handleRedoTest, handleDoTestLater }) => {
  return (
    <Container>
      <Header>Resultados inconclusivos</Header>

      <MiddleContainer>
        <p>
          Não conseguimos definir qual o seu perfil profissional, pois suas
          respostas levaram a mais de um resultado. Pedimos que você refaça o
          teste, avaliando as opções e escolhendo a alternativa que mais lhe
          representa.
        </p>

        <SecondaryButton type="button" onClick={handleRedoTest}>
          Refazer teste
        </SecondaryButton>

        <CloseButton type="button" onClick={handleDoTestLater}>
          Fazer depois
        </CloseButton>
      </MiddleContainer>
    </Container>
  );
};
