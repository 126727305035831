export const parseUrl = (url: string): string => {
  const httpsPrefix = 'https://';
  if (!url || url === httpsPrefix) return '';

  if (!url.startsWith('http://') && !url.startsWith(httpsPrefix)) {
    return httpsPrefix + url;
  }

  return url;
};
