import { LowEmphasis } from 'assets/colors/palette';
import { Regular16Styles } from 'assets/fonts/fontStyles';
import styled from 'styled-components';

export const Container = styled.small`
  ${Regular16Styles}
  font-size: 12px;
  color: ${LowEmphasis};
  margin-bottom: -8px;
`;
