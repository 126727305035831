import companyIconOrange from 'assets/img/companyIconOrange.svg';
import { SecondaryButton } from 'components/Buttons';
import { Container } from './styles';

interface FormWithStepsSuccessModalProps {
  title: string;
  description: string;
  redirectButtonText: string;
  handleRedirect: () => void;
}

export const FormWithStepsSuccessModal: React.FC<
  FormWithStepsSuccessModalProps
> = ({ title, description, redirectButtonText, handleRedirect }) => {
  return (
    <Container>
      <img src={companyIconOrange} alt="Check" />

      <span id="success-message-title">{title}</span>
      <span id="success-message-description">{description}</span>

      <SecondaryButton type="button" onClick={handleRedirect}>
        {redirectButtonText}
      </SecondaryButton>
    </Container>
  );
};
