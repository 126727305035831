import { HTMLAttributes } from 'react';
import { routePrivacyPolicy, routeTermsOfUse } from 'routes/routesAddresses';
import { baseURLFrontend } from 'services/baseUrl';
import { Container, TermsButton } from './styles';

interface TermsProps extends HTMLAttributes<HTMLParagraphElement> {
  confirmButtonName: string;
}

export const AcceptTerms: React.FC<TermsProps> = ({
  confirmButtonName,
  ...props
}) => (
  <Container {...props}>
    Ao clicar em {confirmButtonName}, eu concordo que li e aceito os{' '}
    <TermsButton
      href={baseURLFrontend + routeTermsOfUse}
      target="_blank"
      rel="noreferrer"
    >
      Termos de uso
    </TermsButton>{' '}
    e a{' '}
    <TermsButton
      href={baseURLFrontend + routePrivacyPolicy}
      target="_blank"
      rel="noreferrer"
    >
      Política de privacidade
    </TermsButton>
    .
  </Container>
);
