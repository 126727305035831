import {
  BackgroundColor,
  MediumEmphasis,
  OutlineColor,
  PrimaryColor,
} from 'assets/colors/palette';
import { Bold14Styles, Medium14Styles } from 'assets/fonts/fontStyles';
import styled from 'styled-components';

interface CandidateButtonProps {
  isSelected: boolean;
}

export const Container = styled.section`
  position: relative;
  background-color: ${BackgroundColor};

  border: 1px solid ${OutlineColor};
  border-top: none;

  &:last-child {
    border-radius: 0 0 8px 8px;
  }
`;

export const CandidateNameAndIcon = styled.div`
  display: flex;
  align-items: center;
  margin-left: 48px;
  gap: 11px;

  div {
    text-align: start;
  }

  p {
    ${Bold14Styles}
    margin-bottom: 4px;
    max-width: 144px;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  small {
    ${Medium14Styles}
    line-height: 16px;
    font-size: 10px;
    color: ${MediumEmphasis};
  }
`;

export const CandidateButton = styled.button<CandidateButtonProps>`
  width: 100%;
  height: 100%;
  background: ${BackgroundColor};
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 60px;

  ${CandidateNameAndIcon} span {
    font-weight: ${({ isSelected }) => (isSelected ? 700 : 400)};
  }

  .new-candidate-indicator {
    ${Bold14Styles}
    font-size: 10px;
    text-align: center;

    color: ${PrimaryColor};
  }
`;
