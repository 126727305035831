import {
  BackgroundColor,
  HighEmphasis,
  LowOrange,
  PrimaryColor,
} from 'assets/colors/palette';
import { Bold14Styles, Regular14Styles } from 'assets/fonts/fontStyles';
import styled, { css } from 'styled-components';

interface SubscriptionStatusProps {
  isSubscriber: boolean;
}

export const Container = styled.section`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .location-column {
    display: flex;
    align-items: center;
    gap: 3px;

    small {
      ${Regular14Styles}
      color: ${PrimaryColor};
    }
  }

  .options-column {
    &,
    section {
      display: flex;
      align-items: center;
    }

    section {
      gap: 36px;
    }

    section:not(:first-child) {
      justify-content: flex-end;
      gap: 48px;
    }

    button {
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        filter: brightness(0%);
      }
    }
  }
`;

export const SubscriptionStatus = styled.p<SubscriptionStatusProps>`
  ${Bold14Styles}
  padding: 4px 8px;
  border-radius: 4px;
  min-width: max-content;

  ${({ isSubscriber }) =>
    isSubscriber
      ? css`
          background-color: ${LowOrange};
          color: ${PrimaryColor};
        `
      : css`
          background-color: ${HighEmphasis};
          color: ${BackgroundColor};
        `}
`;
