import styled from 'styled-components';
import {
  BackgroundColor,
  HighEmphasis,
  OutlineColor,
} from 'assets/colors/palette';
import {
  Bold18Styles,
  Medium16Styles,
  Regular16Styles,
} from 'assets/fonts/fontStyles';
import { TextGradientPrimaryBackground } from 'assets/colors/background';

export const MainContainer = styled.div`
  width: 1248px;
  background: ${BackgroundColor};

  margin-inline: auto;
  padding-bottom: 78px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;

  @media (max-width: 1100px) {
    width: 100%;
    padding-inline: 18px;
    gap: 32px;
    padding-bottom: 7vh;
  }
`;

export const Header = styled.header`
  width: 100%;
  height: 64px;

  display: flex;
  align-items: center;

  ${Bold18Styles}
  color: ${HighEmphasis};
`;

export const ConfigurationSection = styled.section`
  width: 540px;

  background-color: ${BackgroundColor};
  border: 1px solid ${OutlineColor};
  border-radius: 8px;

  button[type='submit'] {
    float: right;
    margin-block: 12px 20px;
  }

  .email {
    ${TextGradientPrimaryBackground}

    font-weight: 700;
    margin-bottom: 32px;
  }

  .notifications-container {
    margin-bottom: 12px;
  }

  @media (max-width: 1100px) {
    width: 100%;

    & + & {
      margin-top: 32px;
    }

    .notifications-container p {
      width: 60%;
    }
  }
`;

export const TitleContainer = styled.div`
  ${Medium16Styles}
  color: ${HighEmphasis};

  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;

  border-bottom: 1px solid ${OutlineColor};
  padding: 6px 20px;
  margin-bottom: 12px;
`;

export const ContentContainer = styled.div`
  padding: 20px;

  p {
    ${Regular16Styles}
    line-height: 28px;
  }
`;
