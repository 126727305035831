/* eslint-disable react-hooks/rules-of-hooks */
import in6Logo from 'assets/img/in6Logo.svg';
import { FormWithSteps } from 'components/FormWithSteps';
import { FormWithStepsSuccessModal } from 'components/FormWithSteps/FormWithStepsSuccessModal';
import { Header } from 'components/Header';
import { Modal } from 'components/Modal';
import { ERROR_MODAL_CONFIGURATION, useAlertModal } from 'hooks/alertModal';
import { useAuth } from 'hooks/auth';
import { useStartup } from 'hooks/startup';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { routeBar, routeDashboard, routeLogin } from 'routes/routesAddresses';
import { routeEditStartup } from 'routes/startupsRoutes/startupsRoutesAddresses';
import {
  createStartup,
  updateStartup,
} from 'services/entitiesServices/startupServices';
import { IServerError } from 'types';
import { IStartupRequest } from 'types/startup';
import { UserAccountType } from 'types/user';
import { useRedirectToEmailSentPageForAccountCreation } from 'utils/customHooks/redirects';
import { ValidationError } from 'yup';
import {
  PresentationStep,
  validatePresentationData,
  LocationStep,
  validateLocationData,
  BusinessSectorsStep,
  validateBusinessSectorsData,
  BusinessModelStep,
  validateBusinessModelData,
  SocialNetworksStep,
  validateSocialNetworksData,
  StartupImagesStep,
  validateStartupImagesData,
  FinishStep,
  StartupAllStepsDataKeyType,
  StartupAllStepsData,
  InitialStartupStepsDataType,
} from './Steps';
import { Container } from './styles';

export const CREATE_STARTUP_STEPS = {
  presentationStep: {
    name: 'Apresentação',
    ComponentFunction: PresentationStep,
    validationFunction: validatePresentationData,
  },
  locationStep: {
    name: 'Localização',
    ComponentFunction: LocationStep,
    validationFunction: validateLocationData,
  },
  businessSectorsStep: {
    name: 'Setor de atuação',
    ComponentFunction: BusinessSectorsStep,
    validationFunction: validateBusinessSectorsData,
  },
  businessModelStep: {
    name: 'Modelo de negócio',
    ComponentFunction: BusinessModelStep,
    validationFunction: validateBusinessModelData,
  },
  socialNetworksStep: {
    name: 'Sites e redes sociais (Opcional)',
    ComponentFunction: SocialNetworksStep,
    validationFunction: validateSocialNetworksData,
  },
  startupImagesStep: {
    name: 'Fotos de galeria (Opcional)',
    ComponentFunction: StartupImagesStep,
    validationFunction: validateStartupImagesData,
  },
  finishStep: {
    name: 'Finalizar',
    ComponentFunction: FinishStep,
  },
};

const STEPS_KEYS = Object.keys(
  CREATE_STARTUP_STEPS,
) as StartupAllStepsDataKeyType[];

export const StartupFormWithStepsTab: React.FC = () => {
  const history = useHistory();
  const { startup: startupToEdit, updateStartupState } = useStartup();
  const isEditRoute = history.location.pathname === routeEditStartup;

  if (isEditRoute && startupToEdit === undefined) {
    return <Redirect to={routeDashboard} />;
  }

  const { showModal } = useAlertModal();
  const { signOut } = useAuth();
  const redirectToEmailSentPage = isEditRoute
    ? null
    : useRedirectToEmailSentPageForAccountCreation(
        'activation',
        UserAccountType.EMPLOYER,
      );

  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [stepsData, setStepsData] = useState<StartupAllStepsData>(
    {} as StartupAllStepsData,
  );
  const [selectedStep, setSelectedStep] =
    useState<StartupAllStepsDataKeyType | null>(null);
  const [isShowingSuccessModal, setIsShowingSuccessModal] = useState(false);
  const [SelectedComponent, setSelectedComponent] =
    useState<ReactElement | null>(null);

  const convertStartupToStepsData = useCallback(() => {
    const {
      name,
      about,
      businessModel,
      businessSectors,
      cnpj,
      employeesQuantity,
      foundationYear,
      images,
      socialNetworks,
      startupLocations,
      logo,
    } = startupToEdit!;

    const presentationStep = {
      name,
      about,
      cnpj,
      employeesQuantity,
      logo,
      foundationYear,
    };
    const locationStep = { startupLocations };
    const businessSectorsStep = { businessSectors };
    const businessModelStep = { businessModel };
    const socialNetworksStep = { ...socialNetworks };
    const startupImagesStep = { images };

    return {
      presentationStep,
      locationStep,
      businessSectorsStep,
      businessModelStep,
      socialNetworksStep,
      startupImagesStep,
    } as StartupAllStepsData;
  }, [startupToEdit]);

  useEffect(() => {
    if (!isEditRoute) {
      setSelectedStep('presentationStep');
      return;
    }

    const parsedStepsData = convertStartupToStepsData();
    setStepsData(parsedStepsData);
    setSelectedStep('finishStep');
  }, [convertStartupToStepsData, isEditRoute]);

  const updateStepData = useCallback(
    stepData => {
      if (selectedStep === null) return;

      setStepsData(previousStepsData => ({
        ...previousStepsData,
        [selectedStep]: stepData,
      }));
    },
    [selectedStep],
  );

  const convertStepsDataToStartup = useCallback(() => {
    const {
      presentationStep,
      locationStep: { startupLocations },
      businessSectorsStep: { businessSectors },
      businessModelStep: { businessModel },
      socialNetworksStep,
      startupImagesStep: { images },
    } = stepsData;

    return {
      ...presentationStep,
      startupLocations,
      businessSectors,
      businessModel,
      socialNetworks: {
        ...socialNetworksStep,
      },
      images,
    } as IStartupRequest;
  }, [stepsData]);

  const handlePublishStartup = useCallback(async () => {
    try {
      const startupFromSteps = convertStepsDataToStartup();
      await createStartup(startupFromSteps);
      if (redirectToEmailSentPage) redirectToEmailSentPage();
    } catch (error) {
      setIsLoadingSubmit(false);

      const {
        response: {
          data: { status },
        },
      } = error as IServerError;

      const message =
        status === 400
          ? 'Já existe uma empresa utilizando alguma das informações fornecidas'
          : 'Erro ao criar a empresa, por favor, tente novamente mais tarde';

      showModal({
        ...ERROR_MODAL_CONFIGURATION,
        message,
      });
    }
  }, [convertStepsDataToStartup, redirectToEmailSentPage, showModal]);

  const handleUpdateStartup = useCallback(async () => {
    try {
      const startupFromSteps = convertStepsDataToStartup();
      const updatedStartup = await updateStartup(startupFromSteps);

      updateStartupState(updatedStartup);
      setIsShowingSuccessModal(true);
    } catch (error) {
      const {
        response: {
          data: { status },
        },
      } = error as IServerError;

      const message =
        status === 400
          ? 'Já existe uma empresa utilizando alguma das informações fornecidas'
          : 'Erro ao atualizar a empresa, por favor, tente novamente mais tarde';

      showModal({
        ...ERROR_MODAL_CONFIGURATION,
        message,
      });
    } finally {
      setIsLoadingSubmit(false);
    }
  }, [convertStepsDataToStartup, showModal, updateStartupState]);

  const handleThrowbackStep = useCallback(
    (selectedStepIndex: number, throwbackStepsQuantity: number) => {
      if (selectedStepIndex !== 0) {
        setSelectedStep(STEPS_KEYS[selectedStepIndex - throwbackStepsQuantity]);
        return;
      }

      if (!isEditRoute) signOut();
      history.push(isEditRoute ? routeDashboard : routeLogin);
    },
    [history, isEditRoute, signOut],
  );

  const handleUpdateSelectedStep = useCallback(
    async (
      updateType: 'throwback' | 'advance',
      selectedStepIndex: number,
      throwbackStepsQuantity = 1,
    ) => {
      if (updateType === 'throwback') {
        handleThrowbackStep(selectedStepIndex, throwbackStepsQuantity);
        return;
      }

      if (selectedStep === 'finishStep') {
        setIsLoadingSubmit(true);
        await (isEditRoute ? handleUpdateStartup : handlePublishStartup)();
        return;
      }
      if (selectedStep === null) return;

      const { validationFunction, ComponentFunction } =
        CREATE_STARTUP_STEPS[selectedStep];
      const parsedStepData = stepsData[
        selectedStep
      ] as InitialStartupStepsDataType;

      try {
        await validationFunction(parsedStepData);
        setSelectedStep(STEPS_KEYS[selectedStepIndex + 1]);
      } catch (error) {
        setSelectedComponent(
          <ComponentFunction
            initialData={parsedStepData}
            setStepDataFunction={updateStepData}
            errors={error as Error | ValidationError}
          />,
        );
      }
    },
    [
      handlePublishStartup,
      handleThrowbackStep,
      handleUpdateStartup,
      isEditRoute,
      selectedStep,
      stepsData,
      updateStepData,
    ],
  );

  useEffect(() => {
    if (selectedStep === null) return;

    const { ComponentFunction } = CREATE_STARTUP_STEPS[selectedStep];
    const isFinishStep = selectedStep === 'finishStep';
    const initialData = isFinishStep ? stepsData : stepsData[selectedStep];

    setSelectedComponent(
      <ComponentFunction
        initialData={initialData as InitialStartupStepsDataType}
        setStepDataFunction={isFinishStep ? undefined : updateStepData}
      />,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStep, updateStepData]);

  return (
    <>
      {!isEditRoute && (
        <Header
          style={{ position: 'fixed' }}
          logo={in6Logo}
          redirectRoute={routeBar}
        />
      )}

      <Container>
        <Modal isVisible={isShowingSuccessModal}>
          <FormWithStepsSuccessModal
            title="Perfil atualizado."
            description="As novas informações já estão disponíveis para os candidatos."
            redirectButtonText="Ver minhas vagas"
            handleRedirect={() => history.push(routeDashboard)}
          />
        </Modal>

        {selectedStep !== null && (
          <FormWithSteps
            STEPS={CREATE_STARTUP_STEPS}
            handleUpdateSelectedStep={handleUpdateSelectedStep}
            headerTitle={
              isEditRoute ? 'Editar empresa' : 'Criar perfil para empresas'
            }
            selectedStep={selectedStep}
            isLoadingSubmit={isLoadingSubmit}
            lastStepAdvanceButtonText={
              isEditRoute ? 'Salvar alterações' : 'Publicar'
            }
          >
            {SelectedComponent}
          </FormWithSteps>
        )}
      </Container>
    </>
  );
};
