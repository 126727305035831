import styled, { css } from 'styled-components';
import {
  BackgroundColor,
  LowEmphasis,
  OutlineColor,
  PrimaryColor,
} from 'assets/colors/palette';
import {
  Bold14Styles,
  Medium16Styles,
  Regular14Styles,
  Regular24Styles,
} from 'assets/fonts/fontStyles';
import { TextGradientPrimaryBackground } from 'assets/colors/background';

interface CategoryProps {
  isSelected: boolean;
}

export const Container = styled.div`
  width: 786px;
  height: 671px;
  background-color: ${BackgroundColor};
  border-radius: 8px;
`;

export const ModalHeader = styled.div`
  height: 60px;
  padding-inline: 20px;
  border-bottom: 1px solid ${OutlineColor};

  display: flex;
  align-items: center;

  span {
    ${Medium16Styles}
  }
`;

export const InformationContainer = styled.div`
  width: 786px;
  height: 152px;
  padding: 24px 20px;

  background-color: rgba(220, 220, 220, 0.25);
  display: flex;
  flex-direction: column;
  gap: 12px;

  div {
    display: flex;
    align-items: center;
    gap: 20px;

    span {
      font-size: 12px;
      line-height: 24px;
      font-weight: 700;
      letter-spacing: 0.75px;
    }
  }

  p {
    ${Regular24Styles}
    font-size: 12px;
    margin-left: 40px;
  }
`;

export const ConfigurationContainer = styled.div`
  display: flex;
  padding: 20px;
  gap: 48px;

  small {
    ${Bold14Styles}
    font-size: 10px;
    color: ${LowEmphasis};
  }

  section {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

export const CategoriesContainer = styled.section`
  min-width: 257px;
`;

export const Category = styled.button<CategoryProps>`
  justify-content: space-between;
  background-color: transparent;

  &,
  div {
    display: flex;
    align-items: center;
  }

  & + & {
    margin-top: -4px;
  }

  span {
    ${Regular14Styles}
    line-height: 18px;
    transition: 0.2s;
  }

  div {
    ${Bold14Styles}
    font-size: 12px;

    height: 21px;
    padding: 8px 12px;
    justify-content: center;

    background-color: ${BackgroundColor};
    border: 1px solid ${OutlineColor};
    border-radius: 100px;
  }

  ${({ isSelected }) =>
    isSelected
      ? css`
          span {
            ${TextGradientPrimaryBackground}
            font-weight: 700;
          }
        `
      : css`
          &:hover span {
            color: ${PrimaryColor};
          }
        `}
`;

export const CriteriaContainer = styled.section``;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 325px 20px;

  button {
    height: 48px;
  }
`;

export const CancelButton = styled.button`
  ${Medium16Styles}
  width: 137px;

  background-color: ${BackgroundColor};
  border: 1px solid ${OutlineColor};
  border-radius: 100px;

  display: flex;
  align-items: center;
  justify-content: center;
`;
