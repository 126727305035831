import infoIcon from 'assets/img/info.svg';
import { Input } from 'components/Input';
import { Select } from 'components/Select';
import { useCallback, useEffect, useMemo, useState } from 'react';

import * as yup from 'yup';
import { VacancyStatus } from 'types/vacancy';
import { useInputStates } from 'utils/customHooks';
import { closingReasonOptions } from 'utils/typeOptions/closingReasonOptions';
import { yupRequiredStringField } from 'utils/validation';
import { generateFormObjectFromStates } from 'utils';
import { useFormError } from 'errors/useFormError';
import { ERROR_MODAL_CONFIGURATION, useAlertModal } from 'hooks/alertModal';
import { Loader } from 'components/Loader';
import { STATUS_TEXTS } from '..';
import {
  Container,
  ActionTitle,
  ActionDetails,
  BottomContainer,
  ActionButtons,
} from './styles';

interface ChangeVacancyStatusModalProps {
  updatedStatus: VacancyStatus | null;
  handleCloseModal: () => void;
  handleUpdateStatus: (
    updatedStatus: VacancyStatus,
    userPassword: string,
    closingReason?: string,
  ) => Promise<void>;
}

const ActiveStatusDetailsComponent = () => (
  <p>
    As vagas ativas são exibidas no mural de vagas e na página de carreira da
    sua empresa, permitindo <span>receber novas candidaturas</span>. Além disso,
    os recursos de divulgação e compartilhamento da vaga também são liberados.
  </p>
);

const SuspendStatusDetailsComponent = () => (
  <p>
    Permite que você <span>suspenda o recebimento de novos currículos</span>{' '}
    nesta vaga por tempo indeterminado. Os candidatos não terão mais acesso à
    vaga no mural de vagas ou na página de carreira da sua empresa. <br />
    <br />
    Você poderá <span>voltar a qualquer momento</span> para o status de{' '}
    <span>vaga ativa</span>, liberando-a novamente para receber novos currículos
    para seu processo de recrutamento.
  </p>
);

const CloseStatusDetailsComponent = () => (
  <p>
    O encerramento da vaga irá{' '}
    <span>
      finalizar{' '}
      <span style={{ textDecoration: 'underline' }}>definitivamente</span> o
      processo seletivo
    </span>{' '}
    em andamento. Com isso, a vaga não ficará mais disponível para novas
    candidaturas e os recursos disponíveis na seção de “gerenciamento dos
    candidatos” serão limitados. <br />
    <br />
    Encerre a vaga apenas com a conclusão do processo admissional, pois caso
    haja desistência será possível dar continuidade ao processo seletivo. Além
    disso,{' '}
    <span>
      os candidatos inscritos serão notificados do encerramento desta vaga.
    </span>
  </p>
);

const STATUS_DETAILS_COMPONENTS = {
  [VacancyStatus.ACTIVE]: ActiveStatusDetailsComponent,
  [VacancyStatus.SUSPENDED]: SuspendStatusDetailsComponent,
  [VacancyStatus.CLOSED]: CloseStatusDetailsComponent,
};

export const ChangeVacancyStatusModal: React.FC<
  ChangeVacancyStatusModalProps
> = ({ updatedStatus, handleCloseModal, handleUpdateStatus }) => {
  const { handleFormError } = useFormError();
  const { showModal } = useAlertModal();

  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const passwordStates = useInputStates('password');
  const closingReasonStates = useInputStates('closingReason');

  const selectedClosingReasonOption: string | undefined = useMemo(() => {
    const closingReasonValue = closingReasonStates.mainState.value;

    if (!closingReasonValue) return undefined;
    return closingReasonOptions[Number(closingReasonValue) - 1].label;
  }, [closingReasonStates.mainState.value]);

  const validateFieldsAndUpdateStatus = useCallback(async () => {
    if (updatedStatus === null) return;

    setIsLoadingUpdate(true);
    const formStates = [passwordStates, closingReasonStates];
    const formObject = generateFormObjectFromStates(formStates);

    try {
      const schema = yup.object().shape({
        password: yupRequiredStringField,
        closingReason:
          updatedStatus === VacancyStatus.CLOSED
            ? yupRequiredStringField
            : yup.string().optional(),
      });

      await schema.validate(formObject, { abortEarly: false });
      await handleUpdateStatus(
        updatedStatus,
        String(formObject.password),
        selectedClosingReasonOption,
      );

      handleCloseModal();
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        handleFormError(error as Error | yup.ValidationError, formStates);
        return;
      }

      showModal({
        ...ERROR_MODAL_CONFIGURATION,
        message:
          'Erro ao atualizar status da vaga, por favor, tente novamente mais tarde',
      });
    } finally {
      setIsLoadingUpdate(false);
    }
  }, [
    closingReasonStates,
    handleCloseModal,
    handleFormError,
    handleUpdateStatus,
    passwordStates,
    selectedClosingReasonOption,
    showModal,
    updatedStatus,
  ]);

  useEffect(() => {
    return () => {
      if (updatedStatus === null) {
        passwordStates.mainState.setFunction('');
        passwordStates.errorMessageState.setFunction('');

        closingReasonStates.mainState.setFunction('');
        closingReasonStates.errorMessageState.setFunction('');
      }
    };
  }, [
    closingReasonStates.errorMessageState,
    closingReasonStates.mainState,
    passwordStates.errorMessageState,
    passwordStates.mainState,
    updatedStatus,
  ]);

  if (updatedStatus === null) return null;
  const { substantive, verb } = STATUS_TEXTS[updatedStatus];

  return (
    <Container>
      <ActionTitle>{verb} vaga</ActionTitle>

      <ActionDetails>
        <div>
          <img src={infoIcon} alt="Info" />
          <span>Status: vaga {substantive}</span>
        </div>

        {STATUS_DETAILS_COMPONENTS[updatedStatus]()}
      </ActionDetails>

      {isLoadingUpdate ? (
        <Loader style={{ minHeight: 50, marginTop: 100, marginBottom: 20 }} />
      ) : (
        <BottomContainer>
          {updatedStatus === VacancyStatus.CLOSED && (
            <Select
              name="Escolha um motivo do encerramento"
              states={closingReasonStates}
              selectOptions={closingReasonOptions}
              style={{ width: '100%', height: 48 }}
              placeholder="selecionar motivo do encerramento"
              startWithoutValue
            />
          )}

          <Input
            name="Para continuar, informe sua senha:"
            states={passwordStates}
            placeholder="inserir senha"
            style={{ width: '100%', height: 48 }}
            type="password"
          />

          <ActionButtons>
            <button type="button" onClick={handleCloseModal}>
              Cancelar
            </button>
            <button
              disabled={
                !passwordStates.mainState.value ||
                (updatedStatus === VacancyStatus.CLOSED &&
                  !closingReasonStates.mainState.value)
              }
              type="button"
              onClick={validateFieldsAndUpdateStatus}
            >
              {verb} vaga
            </button>
          </ActionButtons>
        </BottomContainer>
      )}
    </Container>
  );
};
