import { HTMLAttributes } from 'react';
import { Container } from './styles';

interface UserIconProps extends HTMLAttributes<HTMLElement> {
  userName: string;
  image: string | null;
}

export const UserIcon: React.FC<UserIconProps> = ({
  userName,
  image,
  ...props
}) => {
  return (
    <Container {...props}>
      {image !== null ? (
        <img src={image} alt="Imagem" />
      ) : (
        userName.charAt(0).toUpperCase()
      )}
    </Container>
  );
};
