import styled from 'styled-components';

import { BackgroundColor, OutlineColor } from 'assets/colors/palette';
import { Medium16Styles, Regular14Styles } from 'assets/fonts/fontStyles';

export const Container = styled.div`
  background-color: ${BackgroundColor};
  width: 438px;
  height: 324px;

  border: 1px solid ${OutlineColor};
  border-radius: 8px;
`;

export const Header = styled.header`
  ${Medium16Styles}
  width: 100%;
  height: 60px;

  border-bottom: 1px solid ${OutlineColor};
  padding-inline: 20px;

  display: flex;
  align-items: center;
`;

export const MiddleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 20px;
  gap: 24px;

  p {
    ${Regular14Styles}
    line-height: 24px;
  }

  button {
    width: 100%;
    height: 48px;
  }
`;

export const CloseButton = styled.button`
  ${Medium16Styles}

  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid ${OutlineColor};
  border-radius: 100px;
  margin-top: -12px;
`;
