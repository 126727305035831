import { GrayBody, MediumEmphasis, OutlineColor } from 'assets/colors/palette';
import { Regular16Styles, Regular24Styles } from 'assets/fonts/fontStyles';
import styled from 'styled-components';

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 12px;
`;

export const ImageInputContainer = styled.div`
  display: flex;
  gap: 22px;

  width: 500px;
  padding: 15px;

  border: 1px solid ${OutlineColor};
  border-radius: 8px;
  margin-bottom: -8px;
`;

export const ImageDescriptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  width: 196px;
  height: 180px;

  #description-title {
    ${Regular16Styles}
    color: ${GrayBody};
  }

  #description-content {
    ${Regular24Styles}
    font-size: 12px;
    color: ${MediumEmphasis};
    margin-top: -25px;
  }
`;
