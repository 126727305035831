import { Depth16 } from 'assets/colors/boxShadows';
import {
  BackgroundColor,
  HighEmphasis,
  MediumEmphasis,
  OutlineColor,
  PrimaryColor,
} from 'assets/colors/palette';
import { Medium16Styles, Regular24Styles } from 'assets/fonts/fontStyles';
import styled, { css } from 'styled-components';

interface DashboardHeaderOptionsBoxProps {
  isVisible: boolean;
}

export const DashboardHeaderOptionsBox = styled.div<DashboardHeaderOptionsBoxProps>`
  position: absolute;
  top: 54px;
  left: 25%;
  margin-left: -148px;

  width: 296px;
  height: 312px;

  background-color: ${BackgroundColor};
  border: 1px solid ${OutlineColor};
  border-radius: 8px;
  padding: 20px;
  box-shadow: ${Depth16};

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  z-index: 2;
  transition: 0.5s;

  ${({ isVisible }) =>
    isVisible
      ? css`
          visibility: visible;
          opacity: 1;
        `
      : css`
          visibility: hidden;
          opacity: 0;
        `}

  #about-container {
    display: flex;
    align-items: center;

    span a {
      ${Regular24Styles}
      color: ${MediumEmphasis};
      font-size: 12px;
      text-decoration: none;

      margin-right: 20px;
      position: relative;

      &:not(:last-child)::after {
        content: '';
        position: absolute;
        background-color: ${MediumEmphasis};
        border-radius: 50%;

        width: 2px;
        height: 2px;
        top: 7px;
        right: -11px;
      }

      &:hover {
        color: ${PrimaryColor};
      }
    }
  }

  button {
    ${Medium16Styles}
    background: transparent;
    color: ${HighEmphasis};

    display: flex;
    align-items: center;
    height: 32px;

    gap: 14px;
    transition: 0.2s;

    &:hover {
      color: ${PrimaryColor};
    }
  }
`;
