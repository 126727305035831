import { useAuth } from 'hooks/auth';
import { UserAccountType } from 'types/user';
import {
  EmailSentPageParams,
  EmailType,
  useRedirectToEmailSentPage,
} from './useRedirectToEmailSentPage';

const getInitialTextForEmailSentMessageBasedOnUserAccountType = (
  userAccountType: UserAccountType,
) => {
  switch (userAccountType) {
    case UserAccountType.EMPLOYER:
      return 'cadastrar sua empresa';
    case UserAccountType.CANDIDATE:
      return 'cadastrar-se como candidato';
    case UserAccountType.INVESTOR:
      return 'cadastrar-se como investidor';
    default:
      return 'se cadastrar na IN6';
  }
};

export const useRedirectToEmailSentPageForAccountCreation = (
  emailType: EmailType,
  accountType: UserAccountType,
): (() => void) => {
  const { user } = useAuth();
  const initialMessageText =
    getInitialTextForEmailSentMessageBasedOnUserAccountType(accountType);

  const params: EmailSentPageParams = {
    title: 'Bem-vindo à IN6!',
    email: user?.email as string,
    description:
      `Estamos muito felizes por você ${initialMessageText}. ` +
      `Gostaríamos de realizar uma rápida confirmação, apenas ` +
      `para garantir a validade da sua conta.`,
    lastStepMessage: 'Confirme seu email para ativar a assinatura',
    emailType,
  };

  return useRedirectToEmailSentPage(params);
};
