import styled, { css } from 'styled-components';

import {
  BackgroundColor,
  HighEmphasis,
  OutlineColor,
} from 'assets/colors/palette';
import { Depth8 } from 'assets/colors/boxShadows';
import { Medium16Styles, Regular16Styles } from 'assets/fonts/fontStyles';

interface StatusOptionProps {
  hoverColor: string;
}

interface StatusOptionsContainerProps {
  isOptionsVisible: boolean;
}

export const CurrentStatusButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  width: 200px;
  height: 48px;
  padding-inline: 12px;
  border-radius: 8px;

  span {
    ${Medium16Styles}
    color: ${BackgroundColor};
    text-align: start;

    flex: 1;
    line-height: 48px;
    border-right: 1px solid #e6efea;
  }

  img {
    filter: brightness(0) invert(1);
    transform: rotate(90deg);
  }

  &:disabled {
    width: 153px;
    padding-inline: 12px 0;

    span {
      border: none;
    }

    img {
      display: none;
    }
  }
`;

export const StatusOptionsContainer = styled.div<StatusOptionsContainerProps>`
  position: absolute;
  top: 51px;
  width: 100%;

  background-color: ${BackgroundColor};
  border-radius: 8px;
  box-shadow: ${Depth8};

  visibility: hidden;
  opacity: 0;
  transition: 0.5s;

  ${({ isOptionsVisible }) =>
    isOptionsVisible &&
    css`
      visibility: visible;
      opacity: 1;
    `}
`;

export const StatusOption = styled.button<StatusOptionProps>`
  ${Regular16Styles}
  color: ${HighEmphasis};

  background-color: transparent;
  text-align: start;

  width: 100%;
  height: 48px;
  padding-inline: 12px;
  border-radius: 8px 8px 0 0;
  transition: background 0.3s, color 0.3s;

  &:hover {
    font-weight: 500;
    background-color: ${({ hoverColor }) => hoverColor};
    color: #fff;
  }

  & + & {
    border-radius: 0 0 8px 8px;
    border-top: 1px solid ${OutlineColor};
  }
`;
