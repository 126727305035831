import styled from 'styled-components';
import { GrayBackground, HighEmphasis } from 'assets/colors/palette';
import { PrimaryButton } from 'components/Buttons';
import { Bold32Styles, Regular24Styles } from 'assets/fonts/fontStyles';

export const MainContainer = styled.div`
  height: calc(100vh - 64px);
  width: 100%;
  background: ${GrayBackground};
`;

export const SubContainer = styled.div`
  height: calc(100% - 64px);
  width: 364px;
  margin-inline: auto;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${PrimaryButton} {
    display: flex;
    align-items: center;
    justify-content: space-between;

    gap: 20px;
    padding-inline: 30px;
  }
`;

export const Title = styled.p`
  ${Bold32Styles}
  font-size: 24px;
  margin-block: 50px 18px;
  color: ${HighEmphasis};
`;

export const Text = styled.p`
  ${Regular24Styles}
  font-size: 14px;

  color: ${HighEmphasis};
  margin-bottom: 50px;
`;
