import closeIcon from 'assets/img/close.svg';
import { formStepInputStyles } from 'assets/styles/formWithStepsStyles/formWithStepsInputStyles';

import { PlusButton } from 'components/Buttons';
import { Input } from 'components/Input';
import { Select } from 'components/Select';
import { useCallback, useEffect, useState } from 'react';
import { IFormStepProps } from 'types/IFormStepProps';
import { useInputStates } from 'utils/customHooks';

import { convertLanguageFromObjectToString } from 'utils/conversion/convertLanguageFromObjectToString';
import {
  StepSubTitle,
  StepTitle,
  TitleContainer,
} from 'assets/styles/formWithStepsStyles/formWithStepsTitlesStyles';
import { FormWithStepsContainer } from 'assets/styles/formWithStepsStyles/formWithStepsContainerStyles';
import { OmitId } from 'types';
import { ILanguage } from 'types/languageEntity';
import { getInputStateValue, stringsAreEqual } from 'utils';
import { languageLevelOptions } from 'utils/typeOptions/languageLevelOptions';
import { OptionalStepMobileWarning } from 'components/FormWithSteps/OptionalStepMobileWarning';
import { useWindowWidth } from 'hooks/windowWidth';
import {
  InputsContainer,
  Language,
  LanguagesContainer,
  LanguageInputContainer,
} from './styles';

export { validateLanguagesData } from './validateLanguagesData';

export interface LanguagesStepData {
  languages: OmitId<ILanguage>[];
}

const LANGUAGE_LEVEL_OPTIONS = [
  {
    id: 1,
    label: 'Iniciante',
  },
  {
    id: 2,
    label: 'Básico',
  },
  {
    id: 3,
    label: 'Intermediário',
  },
  {
    id: 4,
    label: 'Avançado',
  },
  {
    id: 5,
    label: 'Proficiente',
  },
];

export const LanguagesStep: React.FC<IFormStepProps<LanguagesStepData>> = ({
  initialData = {} as LanguagesStepData,
  setStepDataFunction,
}) => {
  const { languages: initialLanguages } = initialData;
  const { isMobileScreen } = useWindowWidth();

  const languageInputStates = useInputStates('languageInput');
  const levelInputStates = useInputStates('levelInput');
  const [languages, setLanguages] = useState<OmitId<ILanguage>[]>(
    initialLanguages ?? [],
  );

  const findLanguageIndexByName = useCallback(
    (languageName: string) =>
      languages.findIndex(({ name }) => stringsAreEqual(name, languageName)),
    [languages],
  );

  const handleAddLanguage = useCallback(() => {
    const { value: languageValue, setFunction } = languageInputStates.mainState;
    if ((languageValue as string).length === 0) return;
    const levelValue = getInputStateValue(levelInputStates);

    if (findLanguageIndexByName(languageValue as string) !== -1) {
      languageInputStates.errorMessageState.setFunction('Idioma já adicionado');
    } else {
      setLanguages(previousLanguages => [
        ...previousLanguages,
        {
          name: languageValue as string,
          level: languageLevelOptions[Number(levelValue) - 1].enumValue,
        },
      ]);
    }

    setFunction('');
  }, [
    findLanguageIndexByName,
    languageInputStates.errorMessageState,
    languageInputStates.mainState,
    levelInputStates,
  ]);

  const handleExcludeLanguage = useCallback((languageName: string) => {
    setLanguages(previousLanguages =>
      previousLanguages.filter(
        comparativeLanguage => comparativeLanguage.name !== languageName,
      ),
    );
  }, []);

  useEffect(() => {
    if (setStepDataFunction) {
      setStepDataFunction({
        languages,
      });
    }
  }, [languages, setStepDataFunction]);

  return (
    <FormWithStepsContainer>
      <TitleContainer>
        <OptionalStepMobileWarning />
        <StepTitle>Idiomas</StepTitle>
        <StepSubTitle>
          Adicione os idiomas que você possui domínio. Lembre-se que o
          recrutador pode querer te testar na hora da entrevista.
        </StepSubTitle>
      </TitleContainer>

      <InputsContainer>
        <span>{languages?.length ?? 0}/8 idiomas selecionados</span>

        <LanguageInputContainer>
          <Input
            name="Idioma"
            disabled={languages?.length === 8}
            states={languageInputStates}
            placeholder="inserir idioma"
            style={{
              ...formStepInputStyles,
              width: isMobileScreen ? '100%' : 274,
            }}
          />

          <Select
            name="Nível"
            placeholder="nível"
            disabled={languages?.length === 8}
            states={levelInputStates}
            selectOptions={LANGUAGE_LEVEL_OPTIONS}
            loadingIndicatorDisabled
            style={{
              ...formStepInputStyles,
              width: isMobileScreen ? '100%' : 202,
            }}
          />
        </LanguageInputContainer>

        <PlusButton
          onClick={handleAddLanguage}
          disabled={
            !languageInputStates.mainState.value || languages?.length === 8
          }
          colorStyle="outline"
          style={{
            width: isMobileScreen ? '100%' : 'auto',
          }}
        >
          Adicionar
        </PlusButton>
      </InputsContainer>

      <LanguagesContainer>
        {languages.map(({ name, level }) => (
          <Language key={name}>
            <span>{convertLanguageFromObjectToString({ name, level })}</span>

            <button type="button" onClick={() => handleExcludeLanguage(name)}>
              <img src={closeIcon} alt="Excluir idioma" />
            </button>
          </Language>
        ))}
      </LanguagesContainer>
    </FormWithStepsContainer>
  );
};
