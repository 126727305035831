import styled, { css } from 'styled-components';

interface MultiInputContainerProps {
  isBlockOnMobile?: boolean;
}

export const MultiInputContainer = styled.div<MultiInputContainerProps>`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @media (max-width: 1100px) {
    ${({ isBlockOnMobile }) =>
      isBlockOnMobile &&
      css`
        display: block;
      `}
  }
`;
