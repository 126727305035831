import { TextGradientPrimaryBackground } from 'assets/colors/background';
import {
  BackgroundColor,
  GradientPrimary,
  GrayBody,
  HighEmphasis,
  MediumEmphasis,
  OutlineColor,
} from 'assets/colors/palette';
import {
  Bold14Styles,
  Medium16Styles,
  Regular14Styles,
} from 'assets/fonts/fontStyles';
import { SecondaryButton } from 'components/Buttons';
import styled from 'styled-components';

export const Container = styled.div`
  ${SecondaryButton} {
    ${Bold14Styles}

    display: flex;
    align-items: center;
    gap: 8px;
    height: 48px;
  }
`;

export const HeaderContainer = styled.header`
  width: 100%;
  height: 42px;

  background-color: rgba(220, 220, 220, 0.25);
  gap: 12px;

  &,
  button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  span,
  button {
    ${Regular14Styles}
    color: ${HighEmphasis};
  }

  button {
    border-radius: 100px;
    padding: 12px 16px;
    height: 28px;
    background-color: ${BackgroundColor};
  }
`;

export const SubscribeModal = styled.div`
  width: 632px;
  height: 583px;
  padding: 48px 24px 34px;
  border-radius: 8px;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: ${BackgroundColor};
`;

export const CloseModalButton = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  right: 24px;
  top: 24px;
`;

export const SubscribeIcon = styled.div`
  ${TextGradientPrimaryBackground}
  ${Bold14Styles}
  font-size: 12px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  border-radius: 48px;
  border: 1px solid ${OutlineColor};
  position: relative;

  width: 144px;
  height: 28px;
  margin-bottom: -24px;

  img {
    width: 16px;
    height: 16px;
  }

  &:after {
    content: '';
    opacity: 0;

    width: 100%;
    height: 100%;
    position: absolute;

    top: -1px;
    left: -1px;

    border-radius: 48px;
    border: 1px solid transparent;
    background: ${GradientPrimary} border-box;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`;

export const ModalTitle = styled.h2`
  font-size: 32px;
  line-height: 48px;
  letter-spacing: 1px;
  font-weight: 400;

  width: 409px;
  text-align: center;
  color: ${GrayBody};
`;

export const ModalSubTitle = styled.p`
  ${Regular14Styles}
  line-height: 24px;

  color: ${HighEmphasis};
  width: 450px;
  text-align: center;

  margin-top: -24px;
`;

export const TalkToConsultantContainer = styled.div`
  width: 584px;
  height: 122px;
  padding: 12px 24px;

  background: ${GradientPrimary};
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    ${Bold14Styles}
    line-height: 24px;
    color: ${BackgroundColor};
    width: 260px;
  }

  a {
    width: 227px;
    height: 48px;

    background-color: ${BackgroundColor};
    border-radius: 48px;
    text-decoration: none;

    display: flex;
    align-items: center;
    justify-content: center;

    span {
      ${TextGradientPrimaryBackground}
      ${Medium16Styles}
    }
  }
`;

export const ModalBottomText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 9px;

  p {
    ${Regular14Styles}
    line-height: 24px;
    color: ${MediumEmphasis};

    max-width: 543px;
  }
`;
