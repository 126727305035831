import closeIcon from 'assets/img/close.svg';
import { PrimaryButton } from 'components/Buttons';
import { CandidateResume } from 'components/CandidateResume';
import { Loader } from 'components/Loader';
import { AcceptTerms } from 'components/AcceptTerms';
import { ERROR_MODAL_CONFIGURATION, useAlertModal } from 'hooks/alertModal';
import { useAuth } from 'hooks/auth';
import { useCandidate } from 'hooks/candidate';
import { useCallback, useState } from 'react';
import { SuccessApplyModal } from '../SuccessApplyModal';
import { CloseButton, Container, TopContainer } from './styles';

interface ApplyModalWithAccountProps {
  vacancyStartupName: string;
  isLoadingCandidacy: boolean;
  handleCloseModal: () => void;
  handleApplyToVacancy: () => Promise<void>;
}

export const ApplyModalWithAccount: React.FC<ApplyModalWithAccountProps> = ({
  vacancyStartupName,
  handleCloseModal,
  handleApplyToVacancy,
  isLoadingCandidacy,
}) => {
  const { user } = useAuth();
  const { candidate } = useCandidate();
  const { showModal } = useAlertModal();
  const [successfullyApplied, setSuccessfullyApplied] = useState(false);

  const handleApplyButtonClick = useCallback(async () => {
    try {
      await handleApplyToVacancy();
      setSuccessfullyApplied(true);
    } catch (err) {
      const parsedErrorMessage = (err as Error).message;
      const parsedMessage = parsedErrorMessage.includes('400')
        ? 'Você já está candidatado nesta vaga'
        : 'Problema na candidatura, por favor, tente novamente mais tarde';

      showModal({
        ...ERROR_MODAL_CONFIGURATION,
        message: parsedMessage,
      });
      handleCloseModal();
    }
  }, [handleApplyToVacancy, handleCloseModal, showModal]);

  if (candidate === undefined) return null;
  if (successfullyApplied) return <SuccessApplyModal />;

  return (
    <Container>
      {isLoadingCandidacy ? (
        <Loader />
      ) : (
        <>
          <TopContainer>
            <span>Candidatar-se para a vaga</span>

            <CloseButton type="button" onClick={handleCloseModal}>
              <img src={closeIcon} alt="Fechar modal" />
            </CloseButton>
          </TopContainer>
          <p>
            Seus dados serão compartilhados apenas com a {vacancyStartupName}.
            Revise seu currículo antes de candidatar-se para a vaga.
          </p>

          <CandidateResume candidateData={candidate} userData={user!} />

          <AcceptTerms
            confirmButtonName="Enviar meu currículo"
            style={{ marginTop: 30 }}
          />
          <PrimaryButton type="button" onClick={handleApplyButtonClick}>
            Enviar meu currículo
          </PrimaryButton>
        </>
      )}
    </Container>
  );
};
